<template>
    <div class="BasicAssignmentRuleView">
        <div class="BasicAssignmentRuleView__header">
            <div class="BasicAssignmentRuleView__headerSummary" @click="$emit('openRuleEditor')">
                <EidIcon class="BasicAssignmentRuleView__headerIcon" :icon-src="decisioningCriteriaIcon" alt="" />
                <span class="BasicAssignmentRuleView__headerText">Contains {{ basicRules.length }} Custom Properties</span>
            </div>
            <EidIcon
                v-if="index < 2"
                alt=""
                class="BasicAssignmentRuleView__headerIcon"
                :icon-src="actionIcon"
                @click="onIconClick"
            />
        </div>
        <div class="BasicAssignmentRuleView__rule" @click="$emit('openRuleEditor')">
            <div class="BasicAssignmentRuleView__ruleIndexLetter">
                <span>
                    {{ getLetterIndex(index) }}
                </span>
            </div>
            <div>
                <div
                    v-for="(basicRule, ruleIndex) in basicRules"
                    :key="ruleIndex"
                    class="BasicAssignmentRuleView__expression"
                >
                    <span class="BasicAssignmentRuleView__condition">
                        {{ getConditionString(index, ruleIndex) }}
                    </span>
                    <span class="BasicAssignmentRuleView__customPropertyName">
                        {{ getCustomPropertyName(basicRule.insuredFieldKey) }}
                    </span>
                    <span class="BasicAssignmentRuleView__operator">{{ getOperatorToDisplay(basicRule) }}</span>
                    <span class="BasicAssignmentRuleView__value">{{ getStringValue(basicRule.value) }}</span>
                </div>
            </div>
        </div>
        <div class="BasicAssignmentRuleView__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import { AssignmentRuleValue, BasicAssignmentRule } from '@/modules/decisioning-criteria/types';
    import decisioningCriteriaIcon from '@/assets/icons/nav-icon-decisioning-criteria.svg';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import xMarkIcon from '@/assets/icons/x-mark-icon.svg';
    import { allAssignmentOperators } from '@/modules/decisioning-criteria/utils/assigmentRuleOperators';
    import { getLetterIndex } from '@/modules/decisioning-criteria/utils/getLetterIndex';
    import startCase from 'lodash/startCase';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        TprmSchemaDisplayFormat,
    } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
    import { TprmSchema } from '@evidentid/tprm-portal-lib/models/common/TprmSchema.model';

    @Component({
        components: { RadioOption, EidIcon },
    })
    export default class BasicAssignmentRuleView extends Vue {
        @Prop({ type: Array, default: () => [] })
        private basicRules!: BasicAssignmentRule[];

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Number })
        private index!: number;

        private decisioningCriteriaIcon = decisioningCriteriaIcon;
        private binIcon = binIcon;
        private xMarkIcon = xMarkIcon;

        private getLetterIndex = getLetterIndex;

        private get actionIcon() {
            return this.index > 0 ? this.xMarkIcon : this.binIcon;
        }

        private getCustomPropertyName(customPropertyKey: string): string {
            // [complex ruleField] handling collaterals as special case, revisit to be generic when needed
            const collateralsKey = this.customProperties?.find(isCollateralCustomProperty)?.key || null;
            if (customPropertyKey === `${collateralsKey}[items].category`) {
                return 'Category';
            }
            return this.customProperties.find((customProperty) => customProperty.key === customPropertyKey)?.name
                || startCase(customPropertyKey);
        }

        private getOperatorToDisplay(rule: BasicAssignmentRule): string {
            return allAssignmentOperators.find((operator) => operator.value === rule.operator)?.label || rule.operator;
        }

        private getStringValue(value: AssignmentRuleValue): string {
            if (value === true) {
                return 'Yes';
            } else if (value === false) {
                return 'No';
            } else {
                return value?.toString() || '';
            }
        }

        private getConditionString(assignmentRuleGroupIndex: number, basicRuleIndex: number): string {
            return assignmentRuleGroupIndex > 0 && basicRuleIndex === 0
                ? `Or ${basicRuleIndex > 0 ? 'And' : 'If'}`
                : basicRuleIndex > 0 ? 'And' : 'If';
        }

        private onIconClick(): void {
            this.$emit(this.index > 0 ? 'close' : 'remove');
        }
    }
</script>
