import { Exception, ExceptionLevel } from './Exception.model';

export enum ComplianceStatus {
    new = 'NEW',
    pending = 'PENDING',
    compliant = 'COMPLIANT',
    nonCompliant = 'NON_COMPLIANT',
}

export enum ComplianceStatusLabel {
    new = 'New',
    pending = 'Pending',
    compliant = 'Compliant',
    nonCompliant = 'Non-Compliant',
}

export type ComplianceStatusOverride = Exclude<Exception, 'until'>;

export interface ComplianceStatusMetadata {
    statusOverride?: ComplianceStatusOverride;
    umbrellaApplied?: boolean;
}

export interface ComplianceStatusException {
    category: ExceptionLevel;
    type: string;
    id: string;
}
