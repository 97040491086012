import _Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import noop from 'lodash/noop';
import PortalVue from 'portal-vue';
import ApplicationObserver from '@evidentid/vue-commons/core/ApplicationObserver';
import { ExtractFullActions, ExtractFullMutations, ExtractFullState } from '@evidentid/vue-commons/store';
import { createModalPlugin } from '../../components/Modal';
import { createTooltipPlugin } from '../../components/Tooltip';
import { createProcedurePlugin } from '../../components/ProcedureManager';
import {
    AppEvents,
    Application as ApplicationInterface,
    BaseApplicationOptions,
    BaseApplicationStore,
} from '../types/Application';
import MainComponent from '../components/Main.vue';

type VueConstructor = Exclude<BaseApplicationOptions['Vue'], undefined>;

export class Application<
    S extends BaseApplicationStore,
    O extends {},
    V extends {},
    P extends { [key: string]: object }
> implements ApplicationInterface<S, O, V, P> {
    private listeners: Record<string, ((...args: any) => any)[]> = {};
    private root: Element | null = null;
    private vue: InstanceType<VueConstructor> | null = null;
    private readonly _buildVueContext: (app: Application<S, O, V, P>) => V;

    public readonly options: Required<BaseApplicationOptions> & O;
    public store!: S;
    public router!: _Router;
    public observer!: ApplicationObserver<ExtractFullState<S>, ExtractFullActions<S>, ExtractFullMutations<S>>;

    public constructor(
        options: Required<BaseApplicationOptions> & O,
        buildVueContext: (app: Application<S, O, V, P>) => V
    ) {
        this.options = options;
        this._buildVueContext = buildVueContext;
    }

    protected async initialize({
        initialize,
        createStore,
        createRouter,
        buildSlotComponents,
        buildProceduresMap,
    }: {
        initialize: (app: Application<S, O, V, P>) => Promise<any>;
        createStore: (app: Application<S, O, V, P>) => S;
        createRouter: (app: Application<S, O, V, P>) => _Router;
        buildSlotComponents: () => Record<string, any[]>;
        buildProceduresMap: () => Record<string, any>;
    }) {
        // Install dependencies
        this.options.Vue.use(this.options.Router);
        this.options.Vue.use(this.options.Vuex);

        // Set-up basic modules
        this.options.Vue.use(PortalVue);
        this.options.Vue.use(createModalPlugin());
        this.options.Vue.use(createTooltipPlugin());
        this.options.Vue.use(createProcedurePlugin(buildProceduresMap()));

        // Inject modules
        await initialize(this);

        // Set-up router and store
        this.store = createStore(this);
        this.router = createRouter(this);
        sync(this.store, this.router);

        // Set up application observer
        this.observer = new ApplicationObserver(this);

        // Pass down information about routing
        this.router.afterEach((to, from) => this.emit('routeChange', to, from));

        // Initialize Vue context
        const vueContext = {
            ...this.buildVueContext(),
            $slotComponents: buildSlotComponents(),
        };
        this.options.Vue.mixin({
            beforeCreate() {
                Object.assign(this, vueContext);
            },
        });

        // Pass down information about Vue error
        // FIXME: Clean up error handler after destroy?
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const originalVueErrorHandler = this.options.Vue.config.errorHandler;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.options.Vue.config.errorHandler = (error, ...args) => {
            this.emit('vueError', error);

            if (originalVueErrorHandler) {
                originalVueErrorHandler(error, ...args);
            }
        };
    }

    public executeProcedure<K extends keyof P>(key: K, options: P[K], done: (modified: boolean) => any): void {
        // TODO: gain access to ProcedureManager from Application
        throw new Error('Not implemented yet');
    }

    public buildVueContext(): V {
        return this._buildVueContext(this);
    }

    public mount(element: Element): void {
        if (this.root) {
            this.destroyMountedComponent();
        }

        this.emit('beforeMount', element);
        this.root = element;
        this.vue = new (this.options.Vue as any)({
            router: this.router,
            // @ts-ignore: for some reason it's not detected at this point
            store: this.store,
            render: (h: any) => h(MainComponent),
        }).$mount(element);
        this.emit('afterMount', element);
    }

    public on<K extends keyof AppEvents>(name: K, fn: (...args: Parameters<AppEvents[K]>) => any): () => void {
        const nameStr = name as string;
        const wrappedFn: typeof fn = (...args) => fn(...args);
        if (!this.listeners[nameStr]) {
            this.listeners[nameStr] = [];
        }
        this.listeners[nameStr].push(wrappedFn);
        return () => {
            const index = (this.listeners[nameStr] || []).indexOf(wrappedFn);
            if (index !== -1) {
                this.listeners[nameStr].splice(index, 1);
            }
        };
    }

    public once<K extends keyof AppEvents>(name: K, fn: (...args: Parameters<AppEvents[K]>) => any): () => void {
        const unsubscribe = this.on(name, (...args) => {
            unsubscribe();
            fn(...args);
        });
        return unsubscribe;
    }

    public emit<K extends keyof AppEvents>(name: K, ...args: Parameters<AppEvents[K]>): void {
        const listeners = this.listeners[name as string];
        if (listeners) {
            // Use copy of listeners and ensure that all listeners are fired,
            // otherwise, when some listeners are unsubscribed during emit,
            // some may be omitted.
            for (const listener of listeners.slice()) {
                listener(...args as any);
            }
        }
    }

    public destroy() {
        this.emit('beforeDestroy');

        // Destroy Vue component
        this.destroyMountedComponent();

        // Destroy Vuex subscriptions
        // @ts-ignore: private interface
        this.store._actionSubscribers = [];
        // @ts-ignore: private interface
        this.store._subscribers = [];

        // TODO: unsubscribe from router
        // @ts-ignore: private interface
        this.router.afterHooks = [];
        // @ts-ignore: private interface
        this.router.beforeHooks = [];
        // @ts-ignore: private interface
        this.router.resolveHooks = [];
        // @ts-ignore: private interface
        this.router.history.listeners = [];
        // @ts-ignore: private interface
        this.router.history.errorCbs = [];
        // @ts-ignore: private interface
        this.router.history.readyCbs = [];
        // @ts-ignore: private interface
        this.router.history.readyErrorCbs = [];
        // @ts-ignore: private interface
        this.router.history.cb = noop;

        this.emit('afterDestroy');

        // Destroy own listeners
        this.listeners = {};
    }

    private destroyMountedComponent(): void {
        // Get back previous element
        const element = this.vue?.$el;
        if (element?.parentNode) {
            element.parentNode.replaceChild(this.root!, element);
        }

        // Destroy Vue instance
        this.vue?.$destroy();

        // Destroy observer listeners
        this.observer.removeListeners();

        // Remove references
        this.vue = null;
        this.root = null;
    }
}
