import { createModuleBuilder } from '../../app';
import { PersistingErrorModule } from '../persisting-error';

export function createPersistVueErrorsModule() {
    return createModuleBuilder()
        .demand<PersistingErrorModule>()
        .execute((app) => {
            app.on('vueError', (error) => {
                app.store.actions.error.showError(error);
            });
        })
        .end();
}

export type PersistVueErrorsModule = ReturnType<typeof createPersistVueErrorsModule>;
