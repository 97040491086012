export default function sanitizeLogValue(
    value: any,
    separator = '\n',
    ignoreObject = false
): string | number {
    if (value == null || value === '' || (typeof value === 'number' && isNaN(value))) {
        return '<none>';
    }

    if (typeof value === 'boolean') {
        return value ? 'yes' : 'no';
    }

    if (Array.isArray(value)) {
        return value.length === 0
            ? '<empty>'
            : value.map((element) => sanitizeLogValue(element, separator)).join(separator);
    }

    if (typeof value === 'object' && !ignoreObject) {
        return JSON.stringify(value, (_, v) => sanitizeLogValue(v, separator, true));
    }

    return value;
}
