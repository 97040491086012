<template>
    <div class="DocumentList">
        <a
            v-for="(document, index) in documents"
            :key="index"
            class="DocumentList__element"
            href="javascript:void(0)"
            @click="open(document)"
        >
            <strong class="DocumentList__name">{{ getTitle(document) }}</strong>
            <span class="DocumentList__more">See details</span>
        </a>
    </div>
</template>

<style lang="scss">
    .DocumentList {
        display: flex;
        flex-direction: column;
        font-size: 0.95em;

        &__element {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #ddd;
            padding: 0.8em 1em;
            color: #23313a;
            text-decoration: none;

            &:last-child {
                border-bottom: 1px solid #ddd;
            }

            &:hover {
                background: rgba(#000, 0.03);
            }
        }

        &__name {
            font-weight: 500;
        }

        &__more {
            color: #2ab496;
            font-size: 0.9em;
            white-space: nowrap;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { DocumentDetails } from '@/modules/entity-documents/types';
    import { getRequirementTypeLabelByAttribute } from '@/modules/entity-documents/services/attributeUtilities';

    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component
    export default class DocumentList extends Vue {
        @Prop({ type: Array })
        private documents!: DocumentDetails[];

        @Prop({ type: Array })
        private requirementModels!: TprmRequirementModel[];

        private open(document: DocumentDetails): void {
            this.$emit('open', document);
        }

        private getTitle(document: DocumentDetails): string {
            const requirementTypeLabel = getRequirementTypeLabelByAttribute(document.id, false, this.requirementModels);
            return document.category || requirementTypeLabel || document.id;
        }
    }
</script>
