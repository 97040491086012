<template>
    <div class="CollateralTab">
        <Table class="m-auto">
            <template #head>
                <HeadCell
                    v-for="{ name, label, tooltipData } in columns"
                    :key="name"
                    :data-test-id="`CollateralTab__${name}HeadCell`"
                    class="text-eidDarkBlue font-semibold"
                >
                    <div v-if="label" class="flex">
                        {{ label }}
                        <EidIcon
                            v-if="tooltipData"
                            v-tooltip="tooltipData"
                            class="ml-2 text-eidQualityBlack-50"
                            alt="icon"
                            :icon-src="faQuestionCircle"
                        />
                    </div>
                </HeadCell>
            </template>

            <CollateralTabRow
                v-for="(collateral, index) in collaterals"
                :key="index"
                data-test-id="CollateralTab__collateralTabRow"
                :collateral="collateral"
                :columns="columns"
                :evaluation-results="getRequirementTypeEvaluationResults(collateral)"
                :entity-contact-email="entity.contactEmail"
                @grant-evaluate-entity-exception="grantEvaluateEntityException"
                @remove-exceptions="removeExceptions"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import { customPropertyCollateralColumns } from './config';
    import CollateralTabRow from './components/CollateralTabRow/CollateralTabRow.vue';
    import {
        EvaluationResult,
        ExceptionInput,
        ComplianceStatusOverride,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

    export default Vue.extend({
        name: 'CollateralTab',
        components: {
            Table,
            HeadCell,
            EidIcon,
            CollateralTabRow,
        },
        props: {
            collaterals: {
                type: Array as PropType<CollateralEntity[]>,
                default: () => ([]),
            },
            sortedDetailsList: {
                type: Array as PropType<EntityRequirementDetails[]>,
                required: true,
            },
            entity: {
                type: Object as PropType<Entity>,
                required: true,
            },
            insuredLevelException: {
                type: Object as PropType<ComplianceStatusOverride | null>,
                default: undefined,
            },
        },
        data() {
            return {
                columns: customPropertyCollateralColumns,
                faQuestionCircle,
            };
        },
        computed: {
            evaluationResultsByEvaluatedEntityId(): Record<string, EvaluationResult[]> {
                const evaluationResultsMap: Record<string, EvaluationResult[]> = {};
                for (const { evaluationResults } of this.sortedDetailsList) {
                    evaluationResults.forEach((evaluationResult) => {
                        const { evaluatedEntityId, useDuringComplianceCalculation } = evaluationResult;

                        if (evaluatedEntityId && useDuringComplianceCalculation) {
                            if (!evaluationResultsMap[evaluatedEntityId]) {
                                evaluationResultsMap[evaluatedEntityId] = [];
                            }

                            evaluationResultsMap[evaluatedEntityId].push(evaluationResult);
                        }
                    });
                }
                return evaluationResultsMap;
            },
        },
        methods: {
            getRequirementTypeEvaluationResults(
                collateral: CollateralEntity,
            ): EvaluationResult[] {
                const evaluations = this.evaluationResultsByEvaluatedEntityId[collateral.evaluatedEntityId];
                return evaluations || [];
            },
            grantEvaluateEntityException(exceptions: ExceptionInput[]): void {
                this.$emit('grant-exceptions', exceptions);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
        },
    });
</script>
