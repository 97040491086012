<template>
    <div class="DashboardAlertList">
        <Alert v-for="(config, i) in configs" :key="i" :type="config.type">
            <div>
                <FontAwesomeIcon v-if="getIcon(config.type)" :icon="getIcon(config.type)" />
                {{ config.title }}
            </div>
        </Alert>
    </div>
</template>
<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { faCheckCircle, faExclamationTriangle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig, AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'DashboardAlertList',
        components: {
            Alert,
            FontAwesomeIcon,
        },
        props: {
            configs: {
                type: Array as PropType<AlertConfig[]>,
                default: (): AlertConfig[] => ([]),
            },
        },
        data() {
            return {
                faCheckCircle,
                faExclamationTriangle,
            }
        },
        methods: {
            getIcon(type: AlertType): IconDefinition | null {
                if (type === 'success') {
                    return this.faCheckCircle;
                } else if ([ 'warning', 'highWarning', 'danger' ].includes(type)) {
                    return this.faExclamationTriangle;
                } else {
                    return null;
                }
            },
        },
    });
</script>
