import b64 from 'urlsafe-base64';
import { PartialDeep } from 'type-fest';
import { stringify as buildQuery } from 'query-string';
import { chunk, isNil, mapValues, merge, noop, omit, omitBy } from 'lodash';
import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import url from '@evidentid/universal-framework/url';
import {
    EntityPatchResponse, EntityPostResponse, EntityUpsertResponse, SendDocumentRequestResponse,
    RelyingPartyFilters, RelyingPartyRequestDetails, RelyingPartySearchResult, RelyingPartySettings,
    RelyingPartySignature, AttributeDefinitionList, RpUsedAttributesData,
} from './models';

import {
    CollateralEntity, CollateralMappingOptions, ComplianceStatus, EntityRequirement, EntityRequirementDetails,
    ExceptionInput, RequirementTypesPerCountry, TprmRequirementModel,
} from '../tprm-portal-lib/models/entity-details';

import {
    ActionResolveInput as InsuranceActionResolveInput,
    ActionReview as InsuranceActionReview,
    ActionReviewsQuery as InsuranceActionReviewsQuery,
} from '@evidentid/tprm-portal-lib/models/entity-actions-review';

import { InsuranceApiKey } from '@evidentid/tprm-portal-lib/models/api-settings';

import {
    CustomProperty, Entity, EntityInputApiModel, CustomPropertyValue, EntityStatistics, RawCategorizedEnumLabels,
} from '@evidentid/tprm-portal-lib/models/dashboard';

import {
    DashboardConfiguration,
} from '@evidentid/tprm-portal-lib/models/dashboard-configuration';

import {
    Criterion, CriterionInput, CriterionMessage, CriterionTemplate, EntityRiskProfile, EffectiveRiskProfiles,
    RiskProfile, RiskProfileInput,
} from '@evidentid/tprm-portal-lib/models/decisioning';

import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';

import {
    TprmClientConfig, TprmClientConfigInput, VerificationRequest,
} from '@evidentid/tprm-portal-lib/models/notification-configuration';

export enum PatchOperationType {
    replace = 'REPLACE',
    reset = 'RESET',
    update = 'UPDATE',
    create = 'CREATE',
    delete = 'DELETE',
}

interface TermsAndConditionsStatus {
    signedBy: string;
    signedAt: number;
}

interface InsuranceInsuredsQuery {
    skip?: number;
    limit?: number;
    sort?: string | null;
    active?: boolean;
    paused?: boolean;
    search?: string | null;
    complianceStatus?: string;
    expiresBeforeOrOn?: string;
    expiresAfterOrOn?: string;
    displayName?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    customPropertyFilters?: Record<string, string>;
    collateralFieldFilters?: Record<string, string>;
}

interface InsuranceInsuredsSearchResult {
    insureds: Entity[];
    count: number;
}

interface InsuranceInsuredActionsSearchResult {
    actions: InsuranceActionReview[];
    count: number;
}

interface PatchReset {
    op: PatchOperationType.reset;
}

interface PatchReplace<T> {
    op: PatchOperationType.replace;
    newValue: T;
}

interface PatchUpdate<T> {
    op: PatchOperationType.update;
    oldValue: T;
    newValue: T;
}

interface SpecificDetailsQuery {
    excludePattern?: string;
    includeList?: string;
}

interface ApiTokens {
    accessToken: string | null;
    idToken: string | null;
}

export interface RelyingPartiesQuery {
    filter?: string;
}

export type PatchOperation<T> = PatchUpdate<T> | PatchReplace<T> | PatchReset;

export interface EntityPatch {
    id: string;
    nextExpiration?: PatchOperation<string | null>;
    complianceStatus?: PatchOperation<ComplianceStatus>;
    active?: PatchOperation<boolean>;
    paused?: PatchOperation<boolean>;
    insuredFields?: PatchOperation<CustomPropertyValue>;
}

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    400: createCustomXhrErrorFactory('bad-request', 'Invalid data passed'),
    401: createCustomXhrErrorFactory('unauthorized', 'You are not authorized for selected operation'),
    403: createCustomXhrErrorFactory('forbidden', 'You are not authorized for selected operation'),
    404: createCustomXhrErrorFactory('not-found', 'The requested resource is not found'),
    440: createCustomXhrErrorFactory('session-expired', 'Your session has expired'),
};

class RpWebApiClient {
    protected baseUrl: string;
    protected getTokens: () => Promise<ApiTokens> = () => Promise.resolve({ accessToken: null, idToken: null });

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    public setTokens(fn: (() => Promise<ApiTokens>) | (() => ApiTokens)) {
        this.getTokens = () => Promise.resolve(fn());
    }

    public async getTermsAndConditionsSign(rp: string): Promise<TermsAndConditionsStatus | null> {
        const result = await this.request('GET', url`/relyingParties/${rp}/settings/termsAndConditions`);
        return (result?.tcSigned)
            ? { signedAt: result.tcSigned.timestamp, signedBy: result.tcSigner }
            : null;
    }

    public async signTermsAndConditions(rp: string): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/termsAndConditions`);
    }

    public async getAvailableRelyingParties(options?: RelyingPartiesQuery): Promise<RelyingPartySignature[]> {
        const query = buildQuery({
            filter: options?.filter || undefined,
        });
        const response = await this.request('GET', `/relyingParties?${query}`);
        return response.relyingParties;
    }

    public async getRelyingPartySettings(rp: string): Promise<RelyingPartySettings> {
        return this.request('GET', url`/relyingParties/${rp}/settings`);
    }

    public async getRelyingPartyRequests(rp: string, options: RelyingPartyFilters): Promise<RelyingPartySearchResult> {
        const rpRequestParams = b64.encode(btoa(JSON.stringify({
            ...options,
            dateSortAsc: Boolean(options.dateSortAsc),
        })));
        const requestUrl = url`/relyingParties/${rp}/requests?rpRequestParams=${rpRequestParams}&bust=${Date.now()}`;
        return await this.request<RelyingPartySearchResult>('GET', requestUrl, undefined, undefined, false);
    }

    public async getRelyingPartyRequestDetails(rp: string, id: string): Promise<RelyingPartyRequestDetails> {
        return await this.request<RelyingPartyRequestDetails>('GET', url`/relyingParties/${rp}/requests/${id}`);
    }

    public async getRelyingPartyRequestSpecificDetails(
        rp: string,
        rprId: string,
        options?: SpecificDetailsQuery,
    ): Promise<RelyingPartyRequestDetails> {
        // this endpoint will return request details for the exact rprId, only support request submissions post Jun 2022
        const query = buildQuery({
            includeList: options?.includeList || undefined,
            excludePattern: options?.excludePattern || undefined,
        });
        return await this.request<RelyingPartyRequestDetails>('GET', `${url`/relyingParties/${rp}/requests/${rprId}/specificDetails`}?${query}`);
    }

    public async getAttributeTypes(rp: string): Promise<AttributeDefinitionList> {
        return await this.request('GET', url`/relyingParties/${rp}/attributeTypes`, undefined, undefined, false);
    }

    public async getTprmEntities(rp: string, options: InsuranceInsuredsQuery):
        Promise<InsuranceInsuredsSearchResult> {
        const customPropertyFilters = options.customPropertyFilters || {};
        const collateralFieldFilters = options.collateralFieldFilters || {};
        const qs = buildQuery({
            skip: 0,
            limit: 25,
            active: options.active,
            ...omit(options, 'customPropertyFilters', 'collateralFieldFilters'),
            ...customPropertyFilters,
            ...collateralFieldFilters,
        });
        const requestUrl = `${url`/relyingParties/${rp}/insurance/insureds`}?${qs}`;
        const { navigation, records } = await this.request('GET', requestUrl);
        return { count: navigation.count, insureds: records };
    }

    public async getTprmEntity(rp: string, id: string):
        Promise<Entity> {
        const requestUrl = `${url`/relyingParties/${rp}/insurance/insureds/${id}`}`;
        return this.request('GET', requestUrl);
    }

    public async getTprmCustomProperties(rp: string): Promise<CustomProperty[]> {
        const requestUrl = url`/relyingParties/${rp}/insurance/config/insureds/fields`;
        return await this.request<CustomProperty[]>('GET', requestUrl);
    }

    public async createTprmEntities(rp: string, insureds: EntityInputApiModel[]):
        Promise<EntityPostResponse> {
        return await this.request('POST', url`/relyingParties/${rp}/insurance/insureds`, insureds);
    }

    public async batchCreateTprmEntities(
        rp: string, insureds: EntityInputApiModel[], chunkSize: number,
        updateProgress: Function): Promise<PromiseSettledResult<EntityPostResponse>[]> {
        const insuredChunks = chunk(insureds, chunkSize || insureds.length);
        const promises: Promise<EntityPostResponse>[] = insuredChunks
            .map((insureds) => this.request('POST', url`/relyingParties/${rp}/insurance/insureds`, insureds));
        return this.batchRequests(promises, updateProgress);
    }

    public async upsertTprmEntities(rp: string, insureds: (EntityInputApiModel | Entity)[]):
        Promise<EntityUpsertResponse> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/insureds`, insureds);
    }

    public async batchUpsertTprmEntities(
        rp: string, insureds: (EntityInputApiModel | Entity)[],
        chunkSize: number, updateProgress: Function): Promise<PromiseSettledResult<EntityUpsertResponse>[]> {
        const insuredChunks = chunk(insureds, chunkSize || 0);
        const promises: Promise<EntityUpsertResponse>[] = insuredChunks
            .map((insureds) => this.request('PUT', url`/relyingParties/${rp}/insurance/insureds`, insureds));
        return this.batchRequests(promises, updateProgress);
    }

    public async patchTprmEntity(rp: string, patch: EntityPatch[]):
        Promise<EntityPatchResponse> {
        return await this.request('PATCH', url`/relyingParties/${rp}/insurance/insureds`, patch);
    }

    public async getTprmExport(rp: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/export`);
    }

    public async getTprmEntityExport(rp: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/export`);
    }

    public async getTprmEntityStatistics(rp: string): Promise<EntityStatistics> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/summaries/insureds`);
    }

    public async getTprmDashboardConfiguration(rp: string): Promise<DashboardConfiguration> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/config/dashboard`);
    }

    public async getTprmApiKey(rp: string): Promise<InsuranceApiKey> {
        const data = await this.request('GET', url`/relyingParties/${rp}/settings/apiKey`);
        return { accountName: rp, key: data?.apiKey };
    }

    public async updateTprmDashboardConfiguration(
        rp: string, dashboardConfiguration: DashboardConfiguration): Promise<void> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/config/dashboard`, dashboardConfiguration);
    }

    public async sendEntityDocumentRequest(
        rp: string, insuredIds: string[], chunkSize: number, updateProgress: (progress: number) => void):
        Promise<SendDocumentRequestResponse[]> {
        const insuredChunks = chunk(insuredIds, chunkSize);
        // TODO: Replace it with a backend call.
        const promises: Promise<SendDocumentRequestResponse>[] = insuredChunks
            .map((insureds) => this.request('POST', url`/relyingParties/${rp}/insurance/verifications`, insureds));
        const results = await this.batchRequests(promises, updateProgress);
        return results.map((result) => (result.status === 'fulfilled'
            ? result.value
            : {
                totalCount: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            }));
    }

    public async getRpUsedAttributesData(rp: string): Promise<RpUsedAttributesData> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/attributes`);
    }

    public async getTprmCollaterals(rp: string, insuredId: string): Promise<CollateralEntity[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/collaterals`);
    }

    public async getTprmCollateralMappingOptions(
        rp: string,
        insuredId: string,
    ): Promise<CollateralMappingOptions[]> {
        return await this.request(
            'GET',
            url`/relyingParties/${rp}/insurance/insureds/${insuredId}/collaterals/mappings/options`,
        );
    }

    public async patchTprmCollaterals(
        rp: string, insuredId: string, collateralsToPatch: Partial<CollateralEntity>[],
    ): Promise<void> {
        return await this.request(
            'PATCH',
            url`/relyingParties/${rp}/insurance/insureds/${insuredId}/collaterals`, collateralsToPatch,
        );
    }

    public async getTprmRequirementData(rp: string, insuredId: string): Promise<EntityRequirement[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/coverages`);
    }

    public async getTprmRequirementsDetailsList(rp: string, insuredId: string):
        Promise<EntityRequirementDetails[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/details`);
    }

    public async getTprmRiskProfiles(rp: string):
        Promise<RiskProfile[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups`);
    }

    public async getTprmRiskProfile(rp: string, groupId: string):
        Promise<RiskProfile> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}`);
    }

    public async getTprmEffectiveRiskProfiles(rp: string):
        Promise<EffectiveRiskProfiles[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups/effective`);
    }

    public async getTprmEntityRiskProfiles(rp: string, insuredId: string):
        Promise<EntityRiskProfile[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/groups`);
    }

    public async getTprmCriteria(rp: string, groupId: string):
        Promise<Criterion[]> {
        return await this.request(
            'GET', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`);
    }

    public async getTprmRequirementTypeModels(rp: string): Promise<TprmRequirementModel[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/coverages`);
    }

    public async getTprmCriteriaTemplatesPerCountry(rp: string, countryCode: string):
        Promise<CriterionTemplate[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/${countryCode}/templates/criteria`);
    }

    public async postTprmCriterionForMessage(
        rp: string,
        criterion: Criterion | CriterionInput,
        countryCode: string,
    ): Promise<CriterionMessage> {
        return await this.request('POST', url`/relyingParties/${rp}/models/${countryCode}/templates/criteria/messages`, criterion);
    }

    public async deleteTprmRiskProfile(rp: string, groupId: string): Promise<void> {
        return await this.request('DELETE', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}`);
    }

    public async createTprmRiskProfile(
        rp: string, group: RiskProfileInput): Promise<RiskProfile> {
        return await this.request('POST', url`/relyingParties/${rp}/insurance/decisioning/groups`, group);
    }

    public async updateTprmRiskProfile(
        rp: string, group: RiskProfile): Promise<RiskProfile> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/decisioning/groups/${group.id}`, group);
    }

    public async createTprmCriteria(
        rp: string, groupId: string,
        criteria: CriterionInput[]
    ): Promise<Criterion[]> {
        return await this.request(
            'POST', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`, criteria);
    }

    public async patchTprmCriteria(
        rp: string, groupId: string,
        patches: any[]): Promise<any[]> {
        return await this.request('PATCH',
            url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`, patches);
    }

    public async getTprmSubmissionLink(rp: string, requestId: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/requests/${requestId}/idoWebUrl`);
    }

    public async getTprmVerificationRequests(rp: string, insuredId: string):
        Promise<VerificationRequest[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/requests`);
    }

    public async getTprmVerification(rp: string, verificationId: string): Promise<VerificationRequest> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/verifications/${verificationId}`);
    }

    public async getTprmEntityVerifications(
        rp: string, insuredId: string,
    ): Promise<VerificationRequest[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/verifications`);
    }

    public async getRequirementTypesPerCountry(rp: string):
        Promise<RequirementTypesPerCountry> {
        return await this.request('GET', url`/relyingParties/${rp}/models/countries`);
    }

    public async getInsuranceConfig(rp: string): Promise<TprmClientConfig> {
        return this.request('GET', url`/relyingParties/${rp}/insurance`);
    }

    public async updateInsuranceConfig(rp: string, changes: Partial<TprmClientConfigInput>): Promise<void> {
        const payload = mapValues(changes, (newValue) => ({ op: 'REPLACE', newValue }));
        await this.request('PATCH', url`/relyingParties/${rp}/insurance`, payload);
    }

    public async getTprmRequestsConfig(rp: string): Promise<TprmRequestsConfig> {
        return this.request('GET', url`/relyingParties/${rp}/insurance/config/requests`);
    }

    public async updateTprmRequestsConfig(
        rp: string,
        changes: PartialDeep<TprmRequestsConfig>,
    ): Promise<void> {
        const previousConfig = await this.getTprmRequestsConfig(rp);
        const config = merge(previousConfig, changes);
        await this.request('PUT', url`/relyingParties/${rp}/insurance/config/requests`, config);
    }

    public async getInsuranceInsuredActions(
        rp: string,
        queryOptions: InsuranceActionReviewsQuery): Promise<InsuranceInsuredActionsSearchResult> {
        const filteredQuery = omitBy(queryOptions, isNil);
        const qs = buildQuery({
            limit: filteredQuery?.limit || 50,
            pending: filteredQuery?.pending || false,
            ...filteredQuery,
        });
        const { navigation, records } =
            await this.request('GET', `${url`/relyingParties/${rp}/insurance/actions`}?${qs}`);
        return { count: navigation.count, actions: records };
    }

    public async createActionResolve(
        rp: string,
        actionId: string,
        resolve: InsuranceActionResolveInput): Promise<void> {
        await this.request('POST', url`/relyingParties/${rp}/insurance/actions/${actionId}/resolve`, resolve);
    }

    public async updateSupportContactInfo(rp: string, support: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/supportContactInfo`, support);
    }

    public async updateEmailSettings(rp: string, settings: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/email`, settings);
    }

    public async sendTestEmail(rp: string,
        verificationType: string,
        to: string,
        cc: string[] = [],
    ): Promise<void> {
        return this.request('POST', url`/relyingParties/${rp}/insurance/verifications/emails/test`,
            { verificationType, recipient: to, carbonCopy: cc });
    }

    public async updateBranding(rp: string, settings: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/branding`, settings);
    }

    public async grantException(rp: string, insuredId: string, exceptions: ExceptionInput[]): Promise<void> {
        await this.request('POST', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/overrides`, exceptions);
    }

    public async removeException(rp: string, insuredId: string, exceptionId: string): Promise<void> {
        await this.request('DELETE', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/overrides/${exceptionId}`);
    }

    public async getInsuranceEndorsementCategories(rp: string): Promise<string[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/forms/categories`);
    }

    public async getCategorizedEnumLabels(rp: string): Promise<RawCategorizedEnumLabels> {
        return await this.request('GET', url`/relyingParties/${rp}/models/enums`);
    }

    public async askChatAgent(rp: string, question: string, sessionId: string, userEmail: string): Promise<any> {
        return await this.request(
            'POST',
            url`/relyingParties/${rp}/chatbots/027b0c21-c6d0-440a-89c7-6b5381539021/completions`,
            { question: { content: question } },
            { 'Session-Id': sessionId, 'User-Email': userEmail },
        );
    }

    /**
     * Make a request to RP Web service.
     */
    // TODO: use object param to reduce number of params and utilize benifits of named params
    // eslint-disable-next-line max-params
    private async request<T = any>(
        method: string,
        requestUrl: string,
        data?: any,
        headers?: Record<string, string>,
        contentJson = true,
    ):
        Promise<T> {
        // Retrieve current auth token
        const { accessToken, idToken } = await this.getTokens();

        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            if (accessToken) {
                xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
            }
            if (idToken) {
                xhr.setRequestHeader('idtoken', idToken);
            }
            if (contentJson) {
                xhr.setRequestHeader('content-type', 'application/json');
            }
            if (headers) {
                Object.entries(headers).forEach(([ key, val ]) => xhr.setRequestHeader(key, val));
            }

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const result = parseJsonConditionally(xhr.responseText);
                const statusCode = result?.downstream_error?.status_code || xhr.status;
                const statusErrorFactory = statusErrorFactories[statusCode];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                const finish = statusCode >= 200 && statusCode < 300 ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`RP Web Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }

    private async batchRequests<T = any>(
        promises: Promise<T> [],
        updateProgress: Function): Promise<PromiseSettledResult<T>[]> {
        let requestsFinished = 0;
        const updateFunc = updateProgress || noop;
        const onRequestFinished = () => {
            updateFunc(++requestsFinished / promises.length * 100);
        };
        updateFunc(0);
        for (const promise of promises) {
            promise.finally(onRequestFinished);
        }
        return Promise.allSettled(promises);
    }
}

export default RpWebApiClient;
