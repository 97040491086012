<template>
    <JsonSchemaPhoneForm v-if="form.schema.format === 'phone'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaPhoneForm>
    <JsonSchemaEmailForm v-else-if="form.schema.format === 'email'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaEmailForm>
    <JsonSchemaDateForm v-else-if="form.schema.format === 'date'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaDateForm>
    <JsonSchemaColorForm v-else-if="form.schema.format === 'color'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaColorForm>
    <JsonSchemaImageUrlForm v-else-if="form.schema.format === 'image-url'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaImageUrlForm>
    <JsonSchemaHtmlForm v-else-if="form.schema.format === 'html'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaHtmlForm>
    <JsonSchemaTextareaForm v-else-if="form.schema.multiline" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaTextareaForm>
    <JsonSchemaTextForm v-else v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaTextForm>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';
    import JsonSchemaPhoneForm from './JsonSchemaPhoneForm.vue';
    import JsonSchemaTextForm from './JsonSchemaTextForm.vue';
    import JsonSchemaTextareaForm from './JsonSchemaTextareaForm.vue';
    import JsonSchemaEmailForm from './JsonSchemaEmailForm.vue';
    import JsonSchemaDateForm from './JsonSchemaDateForm.vue';
    import JsonSchemaColorForm from './JsonSchemaColorForm.vue';
    import JsonSchemaImageUrlForm from './JsonSchemaImageUrlForm.vue';
    import JsonSchemaHtmlForm from './JsonSchemaHtmlForm.vue';

    @Component({
        components: {
            JsonSchemaDateForm, JsonSchemaEmailForm, JsonSchemaTextForm, JsonSchemaPhoneForm,
            JsonSchemaColorForm,
            JsonSchemaTextareaForm,
            JsonSchemaImageUrlForm,
            JsonSchemaHtmlForm,
        },
        inheritAttrs: false,
    })
    export default class JsonSchemaStringForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
    }
</script>
