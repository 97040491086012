import _Vue, { PluginObject, VueConstructor } from 'vue';
import ProcedureManager from './ProcedureManager';
import { ProcedureComponentMap } from './types';

function createProcedurePlugin<T extends Record<string, any>>(views: ProcedureComponentMap<T>): PluginObject<_Vue> {
    return {
        install(Vue: VueConstructor): void {
            Vue.mixin({
                beforeCreate() {
                    if (this === this.$root) {
                        Object.defineProperty(this, '$procedures', {
                            value: new ProcedureManager(views),
                        });
                    } else {
                        Object.defineProperty(this, '$procedures', {
                            get() {
                                return this.$root.$procedures;
                            },
                        });
                    }
                },
            });
        },
    };
}

export default createProcedurePlugin;
