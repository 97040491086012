<template>
    <div
        class="Alert"
        :class="{
            [`Alert--${type}`]: true,
            'Alert--rounded': rounded,
        }"
    >
        <h4 v-if="$scopedSlots.title || title">
            <slot name="title">
                {{ title }}
            </slot>
        </h4>
        <slot />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { AlertType } from './types';

    @Component
    export default class Alert extends Vue {
        @Prop({ type: String as PropType<AlertType>, default: 'regular' })
        private type!: AlertType;

        @Prop({ type: String as PropType<string>, default: null })
        private title!: string | null;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private rounded!: boolean;
    }
</script>
