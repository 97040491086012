<template>
    <div class="UserMenu" :class="{ 'UserMenu--opened': opened }">
        <div role="button" class="UserMenu__button" @click="toggle">
            <div class="UserMenu__description">
                <strong>{{ name }}</strong> <FontAwesomeIcon :icon="caret" />
                <small>{{ email }}</small>
            </div>
            <div class="UserMenu__avatar">
                <Avatar :email="email" :name="name" :url="avatarUrl" />
            </div>
        </div>
        <div v-if="opened" class="UserMenu__menu">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
    import { Avatar } from '../Avatar';

    @Component({
        components: { Avatar, FontAwesomeIcon },
    })
    export default class UserMenu extends Vue {
        private opened: boolean = false;
        private unsubscribeBodyListener: (() => void) | null = null;
        private listenerTimeout: any;

        @Prop({ type: String })
        private name!: string;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String, default: null })
        private avatarUrl!: string | null;

        private get caret() {
            return this.opened ? faCaretUp : faCaretDown;
        }

        private toggle() {
            this.opened = !this.opened;
        }

        private close() {
            if (this.opened) {
                this.opened = false;
            }
        }

        @Watch('opened')
        private onOpenChange() {
            if (this.opened) {
                this.listenerTimeout = setTimeout(() => this.addListeners());
            } else {
                this.removeListeners();
            }
        }

        private destroyed() {
            this.removeListeners();
        }

        private addListeners() {
            const listener = this.close.bind(this);
            document.body.addEventListener('click', listener);
            this.unsubscribeBodyListener = () => document.body.removeEventListener('click', listener);
        }

        private removeListeners() {
            clearTimeout(this.listenerTimeout);
            if (this.unsubscribeBodyListener) {
                this.unsubscribeBodyListener();
                this.unsubscribeBodyListener = null;
            }
        }
    }
</script>
