<template>
    <Modal additional-class-name="RiskProfileChangesWarningModal" open @close="$emit('revert')">
        <div class="RiskProfileChangesWarningModal__content">
            <div
                data-test-id="RiskProfileChangesWarningModal__title"
                class="text-eidTrustGreen font-medium text-2xl"
            >
                Would you like to notify entities?
            </div>
            <div data-test-id="RiskProfileChangesWarningModal__context">
                <div class="my-3">
                    To capture new criteria, we need to re-process COI(s). A non-compliant email notification will be
                    sent to all entities to re-submit their COI(s).
                </div>
            </div>
            <div class="font-semibold my-3">
                New Criteria:
            </div>
            <div
                data-test-id="RiskProfileChangesWarningModal__changesContainer"
                class="max-h-64 w-full p-4 bg-eidTechGray-10 border
                border-solid border-eidQualityBlack-10 overflow-auto"
            >
                <div
                    v-for="(changeDetail, i ) in changeDetails"
                    :key="i"
                    data-test-id="RiskProfileChangesWarningModal__changeText"
                    class="my-1"
                >
                    {{ changeDetail }}
                </div>
            </div>
        </div>
        <template #footer>
            <div
                data-test-id="RiskProfileChangesWarningModal__footer"
                class="w-full"
            >
                <div>If you would like to re-process existing COI(s), please contact support.</div>
                <div
                    data-test-id="RiskProfileChangesWarningModal__footerButtons"
                    class="w-full flex justify-between my-5"
                >
                    <Button
                        data-test-id="RiskProfileChangesWarningModal__saveButton"
                        type="secondary"
                        @click="$emit('save')"
                    >
                        Yes, Save & Notify Entities
                    </Button>
                    <Button
                        data-test-id="RiskProfileChangesWarningModal__revertButton"
                        type="primary"
                        @click="revertAndSeeSupport"
                    >
                        No, Revert & Contact Support
                    </Button>
                </div>
                <div
                    data-test-id="RiskProfileChangesWarningModal__supportTextContainer"
                    class="RiskProfileChangesWarningModal__supportTextContainer w-full text-center my-5 pt-5"
                >
                    Why are entities non-compliant and need to be notified?
                    <a
                        data-test-id="RiskProfileChangesWarningModal__learnMoreLink"
                        href="https://evidentidsupport.zendesk.com/hc/en-us/articles/14005740004759"
                        target="_blank"
                        class="no-underline"
                    >
                        Learn More
                    </a>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    export default Vue.extend({
        name: 'RiskProfileChangesWarningModal',
        components: {
            Modal,
            Button,
        },
        props: {
            changeDetails: {
                type: Array as PropType<string[]>,
                default: (): string[] => ([]),
            },
        },
        methods: {
            revertAndSeeSupport(): void {
                this.$emit('revert');
                window.open('https://www.evidentid.com/support/coi-reproccessing/', '_blank');
            },
        },
    });
</script>
