<template>
    <div class="DashboardConfiguration">
        <Page :loading="isLoading" title="Properties Display">
            <ActionBar
                :disabled="isPristine"
                @submit="submit"
            />
            <CustomPropertiesConfiguration
                :custom-properties="filteredCustomProperties"
                :selected-custom-property-ids="selectedCustomPropertyIds"
                @input="updateSelectedCustomProperties"
            />
            <ConfirmNavigationOnDirtyState :dirty="!isPristine" />
        </Page>
    </div>
</template>

<style lang="scss">
$eidTrustGreen: #2bb597;

.DashboardConfiguration {
    .ActionBar {
        margin: -15px -15px 25px;
        padding: 0 20px;
        background-color: transparent;
    }

    .ActionBar .ActionBar__submitButton {
        background-color: $eidTrustGreen;
        color: #fff;
    }
}
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { ConfirmNavigationOnDirtyState } from '@evidentid/dashboard-commons/components/ConfirmNavigationOnDirtyState';
    import ActionBar from '@evidentid/dashboard-commons/components/ActionBar/ActionBar.vue';
    import Page from '@/layouts/Page.vue';
    import CustomPropertiesConfiguration
        from '@/modules/dashboard-configuration/components/custom-properties-configuration/CustomPropertiesConfiguration.vue';
    import { JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        DashboardConfiguration as DashboardConfigurationModel,
    } from '@evidentid/tprm-portal-lib/models/dashboard-configuration/DashboardConfiguration.model';

    @Component({
        components: { CustomPropertiesConfiguration, Page, ActionBar, Button, ConfirmNavigationOnDirtyState },
    })
    export default class DashboardConfiguration extends Vue {
        private lastRpName!: string;
        private selectedCustomPropertyIds: string[] = [];

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            this.loadCustomProperties();
            this.loadDashboardConfiguration();
            if (prevRpName) {
                this.$store.actions.dashboard.clearCustomPropertiesList({ rpName: prevRpName });
                this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: prevRpName });
            }
        }

        @Watch('dashboardConfigurationStatus', { immediate: true })
        private onDashboardConfigurationChange(): void {
            this.selectedCustomPropertyIds = this.dashboardConfigurationStatus.config?.insuredFields || [];
        }

        @Watch('updateDashboardConfigurationStatus')
        private onUpdateConfigurationStatusChange(): void {
            if (this.updateDashboardConfigurationStatus === OperationStatus.success) {
                this.loadDashboardConfiguration();
                this.$store.actions.dashboardConfiguration.clearUpdateDashboardConfigurationStatus(
                    { rpName: this.rpName },
                );
            }
            this.selectedCustomPropertyIds = this.dashboardConfigurationStatus.config?.insuredFields || [];
        }

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get isLoading(): boolean {
            return this.customPropertiesStatus.status === OperationStatus.loading ||
                this.dashboardConfigurationStatus.status === OperationStatus.loading ||
                this.updateDashboardConfigurationStatus === OperationStatus.loading;
        }

        private get customPropertiesStatus(): { status: OperationStatus, list: CustomProperty[] } {
            return this.$store.state.dashboard.customProperties[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get dashboardConfigurationStatus():
            { status: OperationStatus, config: DashboardConfigurationModel } {
            return this.$store.state.dashboardConfiguration.configuration[this.rpName] || {
                status: OperationStatus.uninitialized,
                config: { insuredFields: [] },
            };
        }

        private get updateDashboardConfigurationStatus(): OperationStatus {
            return this.$store.state.dashboardConfiguration.updateStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get isPristine(): boolean {
            return isEqual(
                this.selectedCustomPropertyIds,
                this.dashboardConfigurationStatus.config?.insuredFields || [],
            );
        }

        private get filteredCustomProperties(): CustomProperty[] {
            return this.customPropertiesStatus.list.filter(
                (field) => (field.schema as RegularJsonSchema).type !== JsonSchemaType.array);
        }

        private loadCustomProperties() {
            if (this.customPropertiesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadCustomProperties({ rpName: this.rpName });
            }
        }

        private loadDashboardConfiguration() {
            if (this.dashboardConfigurationStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboardConfiguration.loadDashboardConfiguration({ rpName: this.rpName });
            }
        }

        private updateDashboardConfiguration(config: DashboardConfigurationModel) {
            if (this.updateDashboardConfigurationStatus !== OperationStatus.loading) {
                this.$store.actions.dashboardConfiguration.updateDashboardConfiguration({
                    rpName: this.rpName,
                    config,
                });
            }
        }

        private updateSelectedCustomProperties(selectedFieldIds: string[]): void {
            this.selectedCustomPropertyIds = [ ...selectedFieldIds ];
        }

        private submit() {
            this.updateDashboardConfiguration({ insuredFields: this.selectedCustomPropertyIds.filter(Boolean) });
        }

        private destroyed() {
            this.$store.actions.dashboard.clearCustomPropertiesList({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearUpdateDashboardConfigurationStatus(
                { rpName: this.lastRpName },
            );
        }
    }
</script>
