<template>
    <span class="Badge" :class="type ? `Badge--${type}` : null">
        <span v-if="$scopedSlots.icon || icon" class="Badge__icon">
            <slot name="icon">
                <FontAwesomeIcon v-if="icon" :icon="icon" />
            </slot>
        </span>
        <span class="Badge__label">
            <slot />
        </span>
    </span>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { BadgeType } from './types';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Badge extends Vue {
        @Prop({ type: String, default: 'gray' })
        private type!: BadgeType | null;

        @Prop({ default: null })
        private icon!: IconDefinition | null;
    }
</script>
