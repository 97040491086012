function isTheType<T>(property: unknown, condition: boolean): property is T {
    return condition;
}

export function typeNarrowing<T>(property: unknown, condition: boolean, negativeMsg: string): T {
    if (isTheType<T>(property, condition)) {
        return property;
    }
    throw new Error(negativeMsg);
}
