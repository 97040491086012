import { IamClientMockAdapter } from './adapters/IamClientMockAdapter';
import { IamClientAuth0Adapter, IamClientAuth0AdapterOptions } from './adapters/IamClientAuth0Adapter';

const adapters = {
    auth0: (options: IamClientAuth0AdapterOptions) => new IamClientAuth0Adapter(options),
    mock: (_: {}) => new IamClientMockAdapter(),
};

type A = typeof adapters;

export type IamClientAdapterName = keyof A;
export type IamClientAdapterOptions<K extends IamClientAdapterName> = Parameters<A[K]>[0];

export function isValidAdapter(name: string): name is IamClientAdapterName {
    return Boolean((adapters as any)[name]);
}

export function createAdapter<K extends keyof A>(name: K, options: Parameters<A[K]>[0]): ReturnType<A[K]> {
    if (!adapters[name]) {
        throw new Error(`Unknown IamClient adapter: ${name}`);
    }
    const Adapter = adapters[name];
    return new (Adapter as any)(options);
}
