import { isArray, isComplexObject, isEnum, isObject } from '@evidentid/json-schema/schemaChecks';
import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';

export function isSubstitutableSchemaWithValue(schema: JsonSchemaComplexObject): boolean {
    const customPropertySchema = schema.then.properties?.insuredField;
    if (!customPropertySchema) {
        return false;
    }
    return isEnum(customPropertySchema) && customPropertySchema.enum.length > 0;
}

export function schemaHaveSubstitutableSchemaWithValue(schema: JsonSchema): boolean {
    if (isArray(schema) && isObject(schema.items as JsonSchema)) {
        return schemaHaveSubstitutableSchemaWithValue(schema.items as JsonSchema);
    }
    if (isComplexObject(schema) &&
        Object.keys(schema.properties).some((prop) => prop.includes('BasedOnInsuredField')) &&
        isSubstitutableSchemaWithValue(schema)
    ) {
        return true;
    }

    return (isObject(schema) && Object.values(schema.properties).some(schemaHaveSubstitutableSchemaWithValue));
}
