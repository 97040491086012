<template>
    <div class="JsonSchemaObjectForm">
        <div v-if="hasHeader" class="JsonSchemaObjectForm__header">
            <template v-if="form.schema.title">
                <h1 v-if="depth === 0" class="JsonSchemaObjectForm_title">
                    {{ form.schema.title }}
                </h1>
                <h2 v-else-if="depth === 1" class="JsonSchemaObjectForm_title">
                    {{ form.schema.title }}
                </h2>
                <h3 v-else-if="depth === 2" class="JsonSchemaObjectForm_title">
                    {{ form.schema.title }}
                </h3>
                <h4 v-else class="JsonSchemaObjectForm_title">
                    {{ form.schema.title }}
                </h4>
            </template>
            <p v-if="form.schema.description">
                {{ form.schema.description }}
            </p>
        </div>
        <div
            class="JsonSchemaObjectForm__content"
            :class="{'JsonSchemaObjectForm__content--bordered': depth > 0 && hasHeader}"
        >
            <component
                :is="FormElementComponent"
                v-for="property in properties"
                :id="`${id}_${property.name}`"
                :key="property.form.key"
                :class="`JsonSchemaObjectForm__${property.name}`"
                :data-test-id="`JsonSchemaObjectForm__${property.name}`"
                :depth="depth + 1"
                :form="property.form"
                :required="property.required"
                :disabled="disabled || property.form.schema.readOnly"
                :form-element-component="FormElementComponent"
                :custom-component-input="customComponentInput"
                :value="getPropValue(property)"
                :touched="childTouchState[property.name] || touched"
                :strings="strings"
                :deletable="property.form.type === 'object' && !property.required"
                v-bind="$attrs"
                @input="onInput(property, $event)"
                @change="onChange(property, $event)"
                @touch="updateTouchState(property.name)"
            />
            <div v-if="deletable" class="JsonSchemaObjectForm__deleteIconContainer">
                <div class="JsonSchemaObjectForm__deleteIcon JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
        components: {
            FontAwesomeIcon,
        },
    })
    export default class JsonSchemaObjectForm extends AbstractJsonSchemaForm<JsonFormObject, object> {
        private faTimes = faTimes;
        private childTouchState: Record<string, boolean> = {};

        private get properties() {
            return this.form.getProperties(this.value);
        }

        private get hasHeader() {
            return this.form.schema.title || this.form.schema.description;
        }

        private updateTouchState(propName: string) {
            this.childTouchState[propName] = true;
        }

        private getPropValue(property: JsonFormProperty): unknown {
            return property.form.getValue((this.value as any)?.[property.name] ?? undefined, true);
        }

        private onInput(property: JsonFormProperty, value: any) {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onChange(property: JsonFormProperty, value: any) {
            this.$emit('change', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }
    }
</script>
