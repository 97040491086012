import { Store } from 'vuex';
import { ActionContext, Dispatch } from 'vuex/types';
import mapValues from 'lodash/mapValues';
import getIn from 'lodash/get';
import { isOperationStatus, OperationStatus } from './OperationStatus';

/*
 * This whole file implements nicer support of TypeScript for Vuex stores.
 *
 * ## Examples
 *
 * See: README.md
 *
 * ## Similar alternatives
 *
 * ### vuex-module-decorators
 *
 * - it doesn't have type checking for dispatch/commit helpers
 * - it breaks commit encapsulation, within this module, is accessible from any place (instead of internally)
 * - it doesn't work transparently
 *
 * ### vuex-smart-module
 *
 * - it's not popular
 * - it doesn't have type checking for dispatch/commit helpers
 * - it is not fully transparent (but still better than vuex-module-decorators)
 * - it has problems with circular dependencies and cooperation with multiple modules
 *
 * ### Vue 3 + Vuex 4
 *
 * Waiting for newer Vue(x) releases is probably the best way,
 * however these were planned for 2019, now rescheduled to Q3 2020,
 * and most likely it will be even later (2021?).
 */

// Base definitions

export type MApp = any;
export type MState = object;

// Basic helpers

type Promisify<T> = T extends Promise<any> ? T : Promise<T>;
type PromisifyFn<T> = T extends (...args: infer A) => infer R ? (...args: A) => Promisify<R> : never;
type Fn = (...args: any) => any;
export type FnOnlyObject<T> = { [K in keyof T]: Fn };

// Helpers for joining multiple modules
// https://github.com/microsoft/TypeScript/issues/31192#issuecomment-488391189
type ObjKeyof<T> = T extends object ? keyof T : never;
type KeyofKeyof<T> = ObjKeyof<T> | { [K in keyof T]: ObjKeyof<T[K]> }[keyof T];
type StripNever<T> = Pick<T, { [K in keyof T]: [T[K]] extends [never] ? never : K }[keyof T]>;
type Lookup<T, K> = T extends any ? K extends keyof T ? T[K] : never : never;
type SimpleFlatten<T> = T extends object
    ? StripNever<{
        [K in KeyofKeyof<T>]: (
            Exclude<K extends keyof T ? T[K] : never, object> | { [P in keyof T]: Lookup<T[P], K> }[keyof T]
            );
    }>
    : T;

// Internal types

export type MIMutations<T> = {
    [K in keyof T]: T[K] extends (payload: infer P) => infer R ? (payload: P) => R : never;
};
type MIMutationCommit<T> = T extends (payload: any) => void ? T : never;

type MICommitMap<T extends MIMutations<T>> = {
    [K in keyof T]: MIMutationCommit<T[K]>;
};
export type MICommitFn<T extends MIMutations<T>>
    = <K extends keyof T>(type: K, ...args: T[K] extends Fn ? Parameters<T[K]> : never) => void;
type MICommit<T extends MIMutations<T>> = MICommitMap<T> & MICommitFn<T>;

type MIActionDispatch<T> = T extends (payload: any) => any ? PromisifyFn<T> : never;

type MIDispatchMap<T> = {
    [K in keyof T]: MIActionDispatch<T[K]>;
};
export type MIDispatchFn<T extends FnOnlyObject<T>>
    = <K extends keyof T>(type: K, ...args: Parameters<T[K]>) => Promisify<ReturnType<T[K]>>;
type MIDispatch<T extends FnOnlyObject<T>> = MIDispatchMap<T> & MIDispatchFn<T>;
interface MIActionContext<T extends FnOnlyObject<T>, S extends MState, M extends MIMutations<M>> {
    rootState: any;
    state: Readonly<S>;
    commit: MICommitFn<M>;
    mutations: MICommitMap<M>;
    dispatch: MIDispatchFn<T>;
    actions: MIDispatchMap<T>;
}

// Output types

export type MOMutation<T, S extends MState>
    = T extends (...args: infer Args) => void ? (state: S, ...args: Args) => void : never;
export type MOMutations<T extends MIMutations<T>, S extends MState> = {
    [K in keyof T]: MOMutation<T[K], S>;
};

type MOAction<T, S extends MState>
    = T extends (...args: infer A) => infer R
    ? (context: ActionContext<S, any>, ...args: A) => Promisify<R>
    : never;
export type MOActions<T, S extends MState> = {
    [K in keyof T]: MOAction<T[K], S>;
};

export interface MOModule<T extends FnOnlyObject<T>, S extends MState, M extends MIMutations<M>> {
    state: S;
    mutations: MOMutations<M, S>;
    actions: MOActions<T, S>;
}
type ExtractModuleState<T> = T extends MOModule<any, infer S, any> ? S : never;
type ExtractModuleDispatchMap<T> = T extends MOModule<infer A, any, any> ? MIDispatchMap<A> : never;

// Factory types

interface MFactoryAfterActions<A extends MApp, S extends MState, M extends MIMutations<M>, T extends FnOnlyObject<T>> {
    instantiateState: (app: A) => S;
    instantiateMutations: (app: A) => MOMutations<M, S>;
    instantiateActions: (app: A) => MOActions<T, S>;
    instantiateModule: (app: A) => MOModule<T, S, M>;
    getActions: (context: { dispatch: Dispatch }) => ExtractModuleDispatchMap<MOModule<T, S, M>>;
}

interface MFactoryAfterMutations<A extends MApp, S extends MState, M extends MIMutations<M>> {
    instantiateState: (app: A) => S;
    instantiateMutations: (app: A) => MOMutations<M, S>;
    createActionFactories: <T extends FnOnlyObject<T>>(
        fn: (app: A) => T & ThisType<MIActionContext<T, S, M>>
    ) => MFactoryAfterActions<A, S, M, T>;
}

interface MFactoryAfterState<A extends MApp, S extends MState> {
    instantiateState: (app: A) => S;
    createMutationsFactories: <T extends MIMutations<T>>(
        fn: (app: A) => T & ThisType<S>
    ) => MFactoryAfterMutations<A, S, T>;
}

export type StoreOptions<T extends { modules?: object }> = MOModule<any, any, any> & {
    modules?: T['modules'] extends object ? {
        [K in keyof T['modules']]: MOModule<any, any, any>;
    } : never;
};

type ExtractModulesMapState<T> = T extends object ? {
    [K in keyof T]: ExtractModuleState<T[K]>;
} : {};

type ExtractModulesMapActions<T> = T extends object ? {
    [K in keyof T]: ExtractModuleDispatchMap<T[K]>;
} : {};

type BaseTypedStore<T extends StoreOptions<T>> = Store<T['state'] & (
    T['modules'] extends object ? ExtractModulesMapState<T['modules']> : {}
    )>;
type ModulesMappedActions<T> = T extends { [K in keyof T]: MOModule<any, any, any> }
    ? { [K in keyof T]: T[K] extends MOModule<infer X, any, any> ? X : never }
    : never;
type FlattenedModulesActions<T> = SimpleFlatten<ModulesMappedActions<T>>;
type TypedStoreDispatch<T extends StoreOptions<T>> = T extends MOModule<infer X, any, any>
    ? T['modules'] extends { [K in keyof T['modules']]: MOModule<any, any, any> }
        ? MIDispatchFn<X & FlattenedModulesActions<T['modules']>>
        : MIDispatchFn<X>
    : never;
type ModulesMappedMutations<T> = T extends { [K in keyof T]: MOModule<any, any, any> }
    ? { [K in keyof T]: T[K] extends MOModule<any, any, infer X> ? X : never }
    : never;
type FlattenedModulesMutations<T> = SimpleFlatten<ModulesMappedMutations<T>>;
type JoinMIMutations<T1, T2> = MIMutations<T1 & T2>;
type TypedStoreCommit<T extends StoreOptions<T>> = T extends MOModule<any, any, infer X>
    ? T['modules'] extends { [K in keyof T['modules']]: MOModule<any, any, any> }
        ? JoinMIMutations<X, FlattenedModulesMutations<T['modules']>> extends MIMutations<any>
            ? MICommitFn<MIMutations<X & FlattenedModulesMutations<T['modules']>>>
            : never
        : MICommitFn<X>
    : never;
type AllowNotDefinedString<K> = string extends K ? string | undefined : K;
type MapStatusProperty<T, K extends keyof T> = T[K] extends never
    ? never
    : AllowNotDefinedString<K>;
type ExtractKeysWithStatus<T> = {
    [K in keyof T]: T[K] extends OperationStatus
        ? OperationStatus : T[K] extends { status: OperationStatus } ? OperationStatus : never;
};
interface OperationStatusGetter<T> {
    <K extends keyof ExtractKeysWithStatus<T>>(
        key1: MapStatusProperty<ExtractKeysWithStatus<T>, K>
    ): ExtractKeysWithStatus<T>[K];
    <
        K1 extends keyof T,
        K2 extends keyof ExtractKeysWithStatus<T[K1]>
        >(
        key1: AllowNotDefinedString<K1>,
        key2: MapStatusProperty<ExtractKeysWithStatus<T[K1]>, K2>
    ): ExtractKeysWithStatus<T[K1]>[K2];
    <
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof ExtractKeysWithStatus<T[K1][K2]>
        >(
        key1: AllowNotDefinedString<K1>,
        key2: AllowNotDefinedString<K2>,
        key3: MapStatusProperty<ExtractKeysWithStatus<T[K1][K2]>, K3>
    ): ExtractKeysWithStatus<T[K1][K2]>[K3];
    <
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof ExtractKeysWithStatus<T[K1][K2][K3]>
        >(
        key1: AllowNotDefinedString<K1>,
        key2: AllowNotDefinedString<K2>,
        key3: AllowNotDefinedString<K3>,
        key4: MapStatusProperty<ExtractKeysWithStatus<T[K1][K2][K3]>, K4>
    ): ExtractKeysWithStatus<T[K1][K2][K3]>[K4];
}
type ExtractKeysWithLoadingType<T> = {
    [K in keyof T]: T[K] extends OperationStatus
        ? boolean : T[K] extends { status: OperationStatus } ? boolean : never;
};
interface OperationLoadingGetter<T> {
    <K extends keyof ExtractKeysWithLoadingType<T>>(
        key1: MapStatusProperty<ExtractKeysWithLoadingType<T>, K>
    ): ExtractKeysWithLoadingType<T>[K];
    <
        K1 extends keyof T,
        K2 extends keyof ExtractKeysWithLoadingType<T[K1]>
    >(
        key1: AllowNotDefinedString<K1>,
        key2: MapStatusProperty<ExtractKeysWithLoadingType<T[K1]>, K2>
    ): ExtractKeysWithLoadingType<T[K1]>[K2];
    <
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof ExtractKeysWithLoadingType<T[K1][K2]>
    >(
        key1: AllowNotDefinedString<K1>,
        key2: AllowNotDefinedString<K2>,
        key3: MapStatusProperty<ExtractKeysWithLoadingType<T[K1][K2]>, K3>
    ): ExtractKeysWithLoadingType<T[K1][K2]>[K3];
    <
        K1 extends keyof T,
        K2 extends keyof T[K1],
        K3 extends keyof T[K1][K2],
        K4 extends keyof ExtractKeysWithLoadingType<T[K1][K2][K3]>
    >(
        key1: AllowNotDefinedString<K1>,
        key2: AllowNotDefinedString<K2>,
        key3: AllowNotDefinedString<K3>,
        key4: MapStatusProperty<ExtractKeysWithLoadingType<T[K1][K2][K3]>, K4>
    ): ExtractKeysWithLoadingType<T[K1][K2][K3]>[K4];
}
export interface TypedStore<T extends StoreOptions<T>> extends Omit<BaseTypedStore<T>, 'dispatch' | 'commit'> {
    actions: ExtractModuleDispatchMap<T> & ExtractModulesMapActions<T['modules']>;
    dispatch: TypedStoreDispatch<T>;
    commit: TypedStoreCommit<T>;
    getStatus: OperationStatusGetter<BaseTypedStore<T>['state']>;
    isLoading: OperationLoadingGetter<BaseTypedStore<T>['state']>;
}
export type ExtractStoreOptions<T extends TypedStore<any>> = T extends TypedStore<infer X> ? X : never;
export type ExtractState<T extends TypedStore<any>> = ExtractModuleState<ExtractStoreOptions<T>>;
export type ExtractFullState<T extends TypedStore<any>> = T['state'];
export type ExtractMutations<T extends TypedStore<any>>
    = ExtractStoreOptions<T> extends MOModule<any, ExtractState<T>, infer M> ? M : never;
export type ExtractFullMutations<T extends TypedStore<any>> = T['commit'] extends MICommitFn<infer M> ? M : never;
export type ExtractModuleActions<T extends MOModule<any, any, any>>
    = T extends MOModule<infer A, ExtractModuleState<T>, any> ? A : never;
export type ExtractActions<T extends TypedStore<any>> = ExtractModuleActions<ExtractStoreOptions<T>>;
export type ExtractFullActions<T extends TypedStore<any>> = T['dispatch'] extends MIDispatchFn<infer A> ? A : never;

// Factories

function createOutputActionWrapper<T extends FnOnlyObject<T>, S extends MState, M extends MIMutations<M>>(
    createContext: (context: ActionContext<S, any>) => MIActionContext<T, S, M>
): <F extends Fn>(action: F) => MOAction<F, S> {
    return function createOutputAction(action) {
        return ((originalContext, ...args) => {
            const context = createContext(originalContext);
            return action.apply(context, args);
        }) as MOAction<typeof action, S>;
    };
}

function wrapOutputMutation<T extends Fn, S extends MState>(mutation: T): MOMutation<T, S> {
    return ((state: S, ...args: Parameters<T>) => mutation.apply(state, args)) as MOMutation<T, S>;
}

function createCommitMapFactory<M extends MIMutations<M>>(names: (keyof M)[]) {
    return (commit: MICommitFn<M>): MICommitMap<M> => {
        const result: Partial<MICommitMap<M>> = {};
        for (const name of names) {
            type MFn = M[typeof name] extends Fn ? M[typeof name] : never;
            result[name] = (
                (...args: Parameters<MFn>) => commit(name, ...args)
            ) as MIMutationCommit<M[typeof name]>;
        }
        return result as MICommitMap<M>;
    };
}

function createDispatchMapFactory<T extends FnOnlyObject<T>>(names: (keyof T)[]) {
    return (dispatch: MIDispatchFn<T>): MIDispatchMap<T> => {
        const result: Partial<MIDispatchMap<T>> = {};
        for (const name of names) {
            result[name] = (
                (...args: Parameters<T[typeof name]>) => dispatch(name, ...args)
            ) as MIActionDispatch<T[typeof name]>;
        }
        return result as MIDispatchMap<T>;
    };
}

function createActionContextFactory<T extends FnOnlyObject<T>, S extends MState, M extends MIMutations<M>>(
    mutationNames: (keyof M)[],
    actionNames: (keyof T)[]
) {
    const createDispatchMap = createDispatchMapFactory<T>(actionNames);
    const createCommitMap = createCommitMapFactory<M>(mutationNames);
    return function createContext(originalContext: ActionContext<S, any>): MIActionContext<T, S, M> {
        // Set-up dispatcher
        // @ts-ignore: (?) dispatch doesn't allow passing these arguments
        const dispatchFn: MIDispatchFn<T> = (...args) => originalContext.dispatch(...args);
        const dispatchMap: MIDispatchMap<T> = createDispatchMap(dispatchFn);

        // Set-up commit
        // @ts-ignore: (?) commit doesn't allow passing these arguments
        const commitFn: MICommitFn<M> = (...args) => originalContext.commit(...args);
        const commitMap: MICommitMap<M> = createCommitMap(commitFn);

        return {
            dispatch: dispatchFn,
            mutations: commitMap,
            actions: dispatchMap,
            commit: commitFn,
            state: originalContext.state,
            rootState: originalContext.rootState,
        };
    };
}

export function createState<A extends MApp, S extends MState>(
    instantiateState: (app: A) => S
): MFactoryAfterState<A, S> {
    function createMutationsFactories<M extends MIMutations<M>>(
        createMutations: (app: A) => M
    ): MFactoryAfterMutations<A, S, M> {
        let cachedMutationNames: (keyof M)[] | null = null;

        function instantiateMutations(app: A): MOMutations<M, S> {
            const mutations = createMutations(app);
            if (!cachedMutationNames) {
                cachedMutationNames = Object.keys(mutations) as (keyof M)[];
            }
            // @ts-ignore: FIXME it's a hack to show "Exception" correctly, until TS supports it
            return mapValues(mutations, wrapOutputMutation);
        }

        function getMutationNames(app: A): (keyof M)[] {
            if (!cachedMutationNames) {
                cachedMutationNames = Object.keys(instantiateMutations(app)) as (keyof M)[];
            }
            return cachedMutationNames;
        }

        function createActionFactories<T extends FnOnlyObject<T>>(
            createActions: (app: A) => T
        ): MFactoryAfterActions<A, S, M, T> {
            let actionNames: (keyof T)[] | undefined;

            function instantiateActions(app: A): MOActions<T, S> {
                const actions = createActions(app);
                actionNames = Object.keys(actions) as (keyof T)[];
                const mutationNames = getMutationNames(app);
                const createActionContext = createActionContextFactory<T, S, M>(mutationNames, actionNames);
                const wrapOutputAction = createOutputActionWrapper<T, S, M>(createActionContext);
                return mapValues(actions, wrapOutputAction);
            }

            function instantiateModule(app: A): MOModule<T, S, M> {
                // eslint-disable-next-line no-use-before-define
                return createModule({
                    state: instantiateState(app),
                    mutations: instantiateMutations(app),
                    actions: instantiateActions(app),
                });
            }

            function getActions(context: { dispatch: Dispatch }) {
                if (!actionNames) {
                    throw new Error('Can\'t access actions of the module which haven\'t been instantiated yet.');
                }
                const actionHandlers: Partial<ExtractModuleDispatchMap<MOModule<T, S, M>>> = {};

                for (const name of actionNames) {
                    // @ts-ignore: simplify implementation
                    actionHandlers[name] = (...args) => context.dispatch(name, ...args);
                }

                return actionHandlers as ExtractModuleDispatchMap<MOModule<T, S, M>>;
            }

            return {
                instantiateState,
                instantiateMutations,
                instantiateActions,
                instantiateModule,
                getActions,
            };
        }

        return {
            instantiateState,
            instantiateMutations,
            createActionFactories,
        };
    }

    return {
        instantiateState,
        createMutationsFactories,
    };
}

export function createStateFactory<A extends MApp = void>() {
    return <S extends MState>(fn: (app: A) => S) => createState<A, S>(fn);
}

export function createModule<T extends FnOnlyObject<T>, S extends MState, M extends MIMutations<M>>(
    options: MOModule<T, S, M>
): MOModule<T, S, M> {
    return options;
}

function createActionsDispatchers<T extends MOModule<any, any, any>>(store: Store<any>, actions: any): any {
    type A = T extends MOModule<infer X, any, any> ? X : never;
    return mapValues(actions, <K extends keyof A>(_: any, key: K) => (
        (...args: Parameters<A[K]>) => store.dispatch(key as any, ...args)
    ));
}

export function createOperationStatusGetter(store: any): any {
    return (...path: (string | number)[]) => {
        const value = getIn(store.state, path);
        return isOperationStatus(value?.status)
            ? value.status
            : isOperationStatus(value) ? value : OperationStatus.uninitialized;
    };
}

export function createLoadingStatusGetter(store: any): any {
    const getOperationStatus = createOperationStatusGetter(store);
    return (...path: (string | number)[]) => getOperationStatus(...path) === OperationStatus.loading;
}

export function createStore<T extends StoreOptions<T>>(
    options: T,
    StoreConstructor: typeof Store = Store
): TypedStore<T> {
    // Build initial store
    const store = new StoreConstructor(options) as Omit<TypedStore<T>, 'actions'>;

    // Build actions map
    const actions = Object.assign(
        createActionsDispatchers<T>(store, options.actions),
        options.modules ? mapValues(options.modules, (x) => createActionsDispatchers(store, x.actions)) : {},
    ) as TypedStore<T>['actions'];

    // Build operation status getter
    const getStatus = createOperationStatusGetter(store);
    const isLoading = createLoadingStatusGetter(store);

    // Combine store with actions information
    return Object.assign(store, { actions, getStatus, isLoading }) as TypedStore<T>;
}
