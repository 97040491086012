<template>
    <DashboardConfirmationModal
        :header-icon="faSyncAlt"
        header-text="Please confirm your action!"
        content-text="This action will override the current filters and replace with the quick filter selection"
        yes="Override filter"
        no="Cancel"
        @input="onAction"
    />
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';

    @Component({
        components: { DashboardConfirmationModal, ConfirmationModal, FontAwesomeIcon },
    })

    export default class ConfirmFilterOverrideProcedure extends Vue {
        private faSyncAlt = faSyncAlt;

        private onAction(confirmed: boolean): void {
            confirmed ? this.$emit('finish') : this.$emit('abort');
        }
    }
</script>
