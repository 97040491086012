// @preval
const { rpContractAppendixOne } = require('./rpContractAppendixOne.js');
const { rpContractAppendixTwo } = require('./rpContractAppendixTwo.js');

// For better performance, it's pre-evaluated with babel-plugin-preval.
// To make it working fine, it was simplest to use JS instead of TS.

const crypto = require('crypto');

function cleanCode(html) {
    return html.replace(/\s+/g, ' ').trim();
}

const htmlTermsAndConditions = cleanCode(`
  <article>
    <strong class="fg-brandprimary">Date Last Revised: August 17, 2022</strong>
  
    <p>
     These Subscription Terms and Conditions (these “Terms and Conditions”) are by and between Evident ID, 
     Inc. (“Evident”) and the customer                           identified in the applicable Order Form (“Relying Party”).
     The applicable Order Form and these Terms and Conditions constitute the entire agreement between Evident and Relying Party (the “Agreement”).  
     BY AGREEING TO AN ORDER FORM THAT INCLUDES THESE TERMS AND CONDITIONS BY REFERENCE OR USING THE SUBSCRIPTION SERVICES, 
     RELYING PARTY ACKNOWLEDGES THAT IT HAS REVIEWED AND ACCEPTS THE TERMS AND CONDITIONS.  IF RELYING PARTY DOES NOT AGREE WITH THESE TERMS IN THEIR ENTIRETY, DO NOT ACCESS OR OTHERWISE USE THE SUBSCRIPTION SERVICES.
    </p>
  
    <p>
      1. <u>DEFINITIONS</u>.
    </p>
  
    <p>
      “Aggregated Statistics” means any non-personally identifiable, technical, statistical or analytical data gathered 
      or generated directly by the Subscription Services or by use of the Subscription Services, which Evident collects, 
      gathers and aggregates periodically as part of its services. Aggregated Statistics is de-identified data and 
      information that Evident, its affiliates, licensors, partners and designated agents may use (on a non-attributed basis) for any purpose.
    </p>
  
    <p>
      “API” means the application programming interface accessible by Relying Party from Evident and any information 
      provided by the application programming interface.
    </p>
  
    <p>
     “Authorized End User” means any user of Relying Party who obtains or accesses their Evident ID using the Subscription Services through the Interface.
    </p>
  
    <p>
      “Authorized Relying Party User” means any individual employees, agents, or contractors of Relying Party accessing or 
      using the Subscription Services on behalf of Relying Party under the rights granted to Relying Party pursuant to this Agreement. 
    </p>
  
    <p>
      “Confidential Information” means all written or oral information, disclosed by either party to the other, related to either party or a third party, that has been identified as confidential or that by the nature of the circumstances surrounding disclosure ought reasonably to be treated as confidential, including, without limitation, trade secrets, know-how, technology specifications, Authorized End User, Authorized Relying Party User and customer lists, sales, cost or other unpublished financial information or marketing data.  Confidential Information includes without limitation, Evident’s proprietary processes and information associated with the Subscription Services, the Documentation, the Interface, Relying Party Data, any related data of Evident, the terms and conditions of this Agreement and all Order Forms (including pricing), as well as business and marketing plans, technology and technical information, product plans and designs, and business processes of either party.
    </p>
  
    <p>
     "Data Protection Laws" means all data protection, data privacy, and cybersecurity laws applicable to the respective party in its role in the processing of the data of Authorized End Users under the Agreement, including, where applicable, EU Data Protection Law, FCRA, DPPA and CCPA. “CCPA” means the California Consumer Privacy Act of 2018 and all rules and regulations promulgated pursuant thereto, in each case as retained, amended, extended, re-enacted, implemented or otherwise given effect. "EU Data Protection Law" means all legislative acts concerning data protection, including regulations and directives, adopted by the European Parliament and the Council of the European Union, including GDPR. “GDPR” means Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation). Any reference to the applicable law of the European Economic Area, including the GDPR, that is directly applicable or directly effective in the United Kingdom at any time is a reference to such law as it applies in England and Wales from time to time including as retained, amended, extended, re-enacted or otherwise given effect on or after 11pm on the 31st of January 2020. The terms “data processor” and “data controller” have the meanings given them in GDPR or, where GDPR does not apply, the terms in other applicable Data Protection Laws that reasonably correspond to these terms.
    </p>
  
    <p>
        “Data Services” means the information and data including any analytical analysis provided to Relying Party from the Subscription Services.</p>
    <p>
     “Documentation” means technical, text and/or graphical documentation or manuals, whether in digital or printed
format, that describe the features, functions and operation of the Subscription Services, which materials are designed
to facilitate use of the Subscription Services. 
    </p>
  
    <p>
     Evident ID” means all the data, content and/or information provided, uploaded, and/or posted by or on behalf of
Authorized End Users in connection with such Authorized End User’s authorized use the Subscription Services.
    </p>
  
    <p>
        “Intellectual Property Rights” means all intellectual property rights or similar proprietary rights, including (a) patent
        rights and utility models, (b) copyrights and database rights, (c) trademarks, trade names, domain names and trade
        dress and the goodwill associated therewith, (d) trade secrets, (e) mask works, and (f) industrial design rights; in
        each case, including any registrations of, applications to register, and renewals and extensions of, any of the
        foregoing in any jurisdiction in the world. Intellectual Property Rights also include any and all rights associated with
        particular information that are granted by law and that give the owner, independent of contract, exclusive authority to
        control use or disclosure of the information, including enforceable privacy rights and any rights in databases
        recognized by applicable law.
    </p>
  
    <p>
        “Interface” means the mobile interface or online portal which (i) Authorized End Users enter information for such
        Authorized End User’s Evident ID and (ii) Authorized Relying Party Users enter Relying Party Data. 
    </p>
  
    <p>
     “Order Form” means the ordering documents, registration pages, or purchase orders submitted for access to the
Subscription Services that are executed hereunder by Evident and Relying Party from time to time, including
modifications, supplements and addend thereto.     </p>
  
    <p>
      “Relying Party Data” means all the data, content and/or information provided, uploaded, and/or posted by Relying
Party or an Authorized Relying Party User in connection with Relying Party’s authorized use of the Subscription
Services, which for avoidance of doubt, does not include any Evident ID.
    </p>
    <p>
     “Subscription” means Relying Party’s, its Authorized Relying Party Users’ and its Authorized End Users’ access rights
to the Subscription Services and use of the Interface, which may include support as set forth in and Order Form.
    </p>
  
    <p>
      “Subscription Services” means Evident’s proprietary technology and application software provided by Evident on a
software-as-a-service basis through which Evident processes the Evident ID. 
    </p>
  
    <p>
   “Subscription Term” means the subscription period for Relying Party’s use of the Subscription Services as set forth
in an Order Form. </p>
  
    <p>
     “Supplier” means a third party supplier of information that may be available to Relying Party, an Authorized End User
or an Authorized Relying Party User via the Subscription Services.  </p>
  
    <p>
      2. <u>ORDERING AND PURCHASES</u>.
  
       Subject to the terms and conditions contained in the Agreement, Relying
Party may purchase subscriptions to access and use the Subscription Services pursuant to Order Forms. Relying
Party agrees that its purchases hereunder are neither contingent on the delivery of any future functionality or features
nor dependent on any oral or written public comments made by Evident regarding any future functionality or features.
If there is any inconsistency between an Order Form and these Terms and Conditions, these Terms and Conditions
will control. 
    </p>
  
    <p>
      3. <u>ACCESS AND USE</u>.
    </p>
  
    <p>
      3.1. <u>Provision of Access to the Subscription Services</u>.
  
        Subject to the terms and conditions contained in this
        Agreement, Evident hereby grants Relying Party a non-exclusive, non-transferable right to access the features and
        functions of the Subscription Services during the Subscription Term, solely for use by the number of Authorized End
        Users and Authorized Relying Party Users set forth in the Order Form in connection with the operation of the business
        of Relying Party. Relying Party will undertake reasonable efforts to make all Authorized Relying Party Users aware
        of the provisions of this Agreement as applicable to such Authorized Relying Party User’s use of the Subscription
        Services, and will cause Authorized Relying Party Users to comply with such provisions. The Subscription Services
        are made available to Relying Party solely as hosted by or on behalf of Evident, and nothing in this Agreement will
        be construed to grant Relying Party any right to receive any copy of the Subscription Services or any software. The
        scope of the Subscription is based upon Relying Party’s then current subscription level as further specified in the
        applicable Order Form. The number of Authorized End Users may be set forth in the applicable Order and an
        individual is counted toward such number if such individual has accessed the Subscription Services in the last three
        (3) months. The Data Services provided through the Subscription Service is subject to any specific terms and 
        3
        conditions applicable to the Data Services. If Relying Party is using the Subscription Services for “employment
        purposes” or any other purpose covered by the Fair Credit Reporting Act, 15 U.S.C. § 1681 et. seq. (“FCRA”), the
        terms and conditions of the addendum titled Additional Obligations Regarding the Use of Consumer Reports shall
        also apply. If Relying Party is using the Subscription Services for Motor Vehicle Report information (“MVRs”), the
        terms and conditions of the addendum titled Additional Obligations Regarding the Use of DPPA Regulated
        Information shall also apply. If Relying Party is using the Subscription Services for any service involving pictures of a
        face, facial geometry, or anything else that could be considered biometric information, the terms and conditions of
        the addendum titled Additional Obligations Regarding the Use of Biometric Data shall also apply.
    </p>
  
    <p>
      3.2. <u>API License</u>.
  
     Subject to the terms and conditions contained in this Agreement, Evident hereby grants Relying
Party a non-exclusive, non-transferable right and license to access the API during the Subscription Term for Relying
Party’s internal purposes in connection with use of the Subscription Services as contemplated herein. Such license
is granted subject to any open source license terms presented prior to accessing the API.
    </p>
  
    <p>
      3.3. <u>Documentation License</u>.
  
      Subject to the terms and conditions contained in this Agreement, Evident hereby
grants Relying Party a non-exclusive, non-transferable right and license to download and use the Documentation
during the Subscription Term for Relying Party’s internal purposes in connection with use of the Subscription Services
as contemplated herein. Copies of the Documentation may not be distributed to third parties. Relying Party’s access
and use of the Subscription Services will comply with all other conditions set forth in the Documentation.
    </p>
  
    <p>
      3.4. <u>Suspension</u>.
  
      Notwithstanding anything to the contrary in this Agreement, Evident may suspend Relying
Party’s, any Authorized Relying Party User’s and any Authorized End User’s access to any portion or all of the
Subscription Services if Evident reasonably determines that (i) there is a threat or attack on any portion of the
Subscription Services; (ii) Relying Party’s, any Authorized Relying Party User’s or any Authorized End User’s use of
the Subscription Services disrupts or poses a security risk to the Subscription Services or any other customer or
vendor of Evident; (iii) Relying Party or any Authorized Relying Party Users is/are using the Subscription Services for
activities which are fraudulent, not in compliance with law or illegal; (iv) Relying Party is in breach of any provision or
requirement of the Order Form or these Terms and Conditions, including being overdue on its payment obligations
to Evident; (v) subject to applicable law, Relying Party has ceased to continue its business in the ordinary course,
made an assignment for the benefit of creditors or similar disposition of its assets, or become the subject of any
bankruptcy, reorganization, liquidation, dissolution or similar proceeding, (vi) Relying Party has at any time (i.e.
including during a specific month) exceeded the Credit Limit, or (vii) Evident’s provision of the Subscription Services
to Relying Party, any Authorized Relying Party User or any Authorized End User is prohibited by applicable law or is
no longer possible due to restrictions by a Supplier (each such suspension, a “Service Suspension”). Evident will
make commercially reasonable efforts, circumstances permitting, to provide written notice of any Service Suspension
to Relying Party (including notices sent to Relying Party’s registered email address) and to provide updates regarding
resumption of access to the Subscription Services following any Service Suspension. Evident will use commercially
reasonable efforts to resume providing access to the Subscription Services as soon as reasonably possible after the
event giving rise to the Service Suspension is cured. Evident will have no liability for any damage, liabilities, losses
(including any loss of data or profits) or any other consequences that Relying Party, any Authorized Relying Party
User or any Authorized End User may incur as a result of a Service Suspension. For purposes of subsection (vi)
above, the “Credit Limit” will be fifteen percent (15%) of the anticipated annualized contract value, or such other figure
or percentage as stated on the applicable Order Form. In accordance with these parameters, Evident reserves the
right to invoice and require payment from Relying Party as needed at any time.
    </p>
  
    <p>
      3.5. <u>Aggregated Statistics</u>.
  
      Relying Party acknowledges and agrees that Evident may compile and use deidentified Aggregated Statistics. To the extent necessary, Relying Party hereby grants Evident a royalty-free,
nonexclusive, irrevocable, right and license (with the right to sublicense through multiple tiers) to develop Aggregated
Statistics from data, including Relying Party Data that has been de-identified, gathered or generated directly by the
Subscription Services or by Relying Party’s use of the Subscription Services.
    </p>
  
    <p>
      4. <u>RELYING PARTY RESPONSIBILITIES</u>.
    </p>
  
    <p>
      4.1. <u>Usage Restrictions</u>.
  
    Relying Party will not, and will not permit any Authorized Relying Party User or any
Authorized End Users to, (i) copy or duplicate any portion of the Subscription Services; (ii) decompile, disassemble,
reverse engineer or otherwise attempt to obtain or perceive the source code from which any software component of
the Subscription Services are compiled or interpreted, or apply any other process or procedure to derive the source
code of any software included in the Subscription Services, or attempt to do any of the foregoing, and Relying Party
acknowledges that nothing in this Agreement will be construed to grant Relying Party any right to obtain or use such
source code; (iii) modify, alter, tamper with or repair the Subscription Services, or create any derivative product from
any of the foregoing, or attempt to do any of the foregoing, except with the prior written consent of Evident; (iv) 
4
interfere or attempt to interfere in any manner with the functionality or proper working of the Subscription Services;
(v) remove, obscure, or alter any notice of any intellectual property or proprietary right appearing on or contained
within the Subscription Services; (vi) use the Subscription Services for marketing purposes or resell or broker the
Subscription Services to any third party; (vii) access the Subscription Services from jurisdictions other than those
indicted in the Order Form or (viii) assign, sublicense, sell, resell, lease, rent or otherwise transfer or convey, or
pledge as security or otherwise encumber, Relying Party’s rights under Section 3.1, 3.2 and 3.3 of these Terms and
Conditions. Relying Party will not use the Subscription Services except in compliance with these Terms and
Conditions. Relying Party will ensure that its use of the Subscription Services complies with all applicable laws,
statutes, regulations or rules and will not use or compile any data from the Subscription Services for the purpose of
any illegal activities. 
    </p>
  
    <p>
      4.2. <u>Access, Terms of Use and Privacy Policy</u>.
  
   y. Relying Party will provide reasonable cooperation, assistance,
information and access to Evident as may be necessary to initiate Relying Party’s use of the Subscription
Services. Relying Party will ensure Authorized Relying Party Users know that the Subscription Services will only be
used for business purposes; and Relying Party acknowledges and agrees that it is responsible for the acts or
omissions of any person who accesses the Subscription Services using passwords or access procedures provided
to or created by Relying Party. Evident reserves the right to refuse registration of, or to cancel, login credentials that
violate the terms and conditions set forth in the Agreement. Relying Party acknowledges and agrees that, in addition
to the terms herein, use of the Subscription Services by the Authorized End Users and Authorized Relying Party
Users will be subject to our current Terms of Use (available at https://www.evidentid.com/terms-of-use) and Privacy
Policy (available at https://www.evidentid.com/privacy-policy). In the event of a conflict between our Terms of Use
and this Agreement, this Agreement shall govern.
    </p>
  
    <p>
      4.3. <u>Compliance with Data Protection Laws</u>.
  
    Relying Party agrees that: (i) it will comply with its obligations under
Data Protection Laws in its processing of Relying Party Data and Authorized End User data, including any obligations
to third parties related to such processing; (ii) it has provided all notice and obtained all consents, permissions and
rights necessary under Data Protection Laws for Evident to lawfully process Relying Party Data, Authorized End User
data and Authorized Relying Party User data for the performance of this Agreement, including the delivery of the
Subscription Services; and (iii) it shall ensure its processing instructions are lawful and that the processing of Relying
Party Data, Authorized End User data and Authorized Relying Party User data in accordance with such instructions
will not violate applicable Data Protection Laws. 
    </p>
  
    <p>
      4.4. <u>Relying Party Data</u>.
 Each party shall implement security practices and measures adequate to preserve the
confidentiality and security of data in its possession or under its control as required by Data Protection Laws and, at
a minimum, shall implement and maintain reasonable security practices and procedures to protect Relying Party Data
and Authorized End User data in its possession from unauthorized access, destruction, use, modification, or
disclosure or otherwise. Relying Party acknowledges (i) Evident does not assess the contents of Relying Party Data
and therefore Relying Party is responsible for determining the suitability of Evident's security measures and for
making appropriate use of the Subscription Services to ensure a level of security appropriate to the particular nature
of Relying Party Data and Authorized End User data; and (ii) that Authorized End Users may independently subscribe
to Evident services that authorize Evident to process their personal data, such as Evident IDs, independently from
the Subscription Services. Except as it relates to Relying Party Data, this Agreement does not apply to such
independently-subscribed services or any Authorized End User data that is subject to such independent subscriptions
with Evident.
    </p>
  
    <p>
      5. <u>PRICING AND PAYMENTS</u>.
    </p>
  
    <p>
      5.1. <u>Fees and Invoicing</u>.
  
  Relying Party will pay all fees for the right to use the Subscription Services as set forth
in the Order Form. Unless otherwise provided in the applicable Order Form, all fees will be due on receipt of invoice
issued by Evident. Payment obligations are non-cancelable, and fees paid are non-refundable. Relying Party is
responsible for providing complete, accurate billing and contact information and notifying Evident of any changes to
such information.
    </p>
  
    <p>
      5.2. <u>Credit Card Payments</u>.
  
     If Relying Party provides credit card information to Evident, Relying Party authorizes
Evident to charge such credit card for all fees listed in the Order Form for the initial Subscription Term and any
renewal subscription term(s) as set forth in the Order Form. Such charges shall be made in advance, either annually
or in accordance with any different billing frequency stated in the applicable Order Form. Credit card payments may
be subject to a limit at Evident’s discretion. 
    </p>
  
    <p>
      5.3. <u>Automatic Renewal</u>.
  
     Relying Party understands and agrees that, depending on Relying Party’s choice of
Subscription, Evident will automatically renew Relying Party’s Subscription (each, an “Automatic Renewal”). Unless
otherwise agreed in a new Order Form, each Subscription’s Automatic Renewal will be for the same period of time
as Relying Party’s original Subscription. The pricing during any renewal term may increase by up to 7.5% above the
applicable pricing in the prior Subscription, unless Evident provides Relying Party notice of different pricing at least
sixty (60) days prior to the applicable renewal term. If Relying Party chooses not to renew the current Subscription,
Relying Party must provide written notice thirty (30) days prior to the end of the then-current Subscription Term. 
    </p>
  
    <p>
      5.4. <u>No Refunds</u>.
  
     Any fees paid pursuant to an Order Form will not be refunded, in whole or in part. If Relying
Party provides written notice of cancellation of Relying Party’s Subscription at least thirty (30) days prior to the end
of the then-current Subscription Term, Relying Party will continue to enjoy Relying Party’s subscription benefits until
the expiration of the then-current Subscription Term for which Relying Party has paid, and Relying Party’s
Subscription will expire at the end of the then-current Subscription Term.
    </p>
  
    <p>
      5.5. <u>Taxes</u>.
  
      Relying Party will be responsible for payment of any applicable sales, use and other taxes and all applicable export and import fees, customs duties and similar charges (other than taxes based on Evident’s income),
      and any related penalties and interest for the grant of license rights hereunder, or the delivery of related services.
      Relying Party will make all required payments to Evident free and clear of, and without reduction for, any withholding taxes.
      Any such taxes imposed on payments to Evident will be Relying Party’s sole responsibility, and Relying Party will, upon Evident’s request,
      provide Evident with official receipts issued by the appropriate taxing authorities, or such other evidence as Evident may reasonably request, to establish that such taxes have been paid.
    </p>
  
    <p>
      5.6. <u>Late Payments; Interest</u>.
  
    Any portion of any amount payable hereunder that is not paid when due will accrue
interest at one and one-half percent (1.5%) per month or the maximum rate permitted by applicable law, whichever
is less, from the due date until paid. Relying Party will reimburse Evident for its costs of collection, including attorney
fees, necessitated by Relying Party’s failure to pay amounts due in a timely manner.
  </p>
  
    <p>
      6. <u>CONFIDENTIALITY</u>.
    </p>
  
    <p>
      6.1. <u>Ownership of Confidential Information</u>.
  
      The parties acknowledge that during the performance of this
Agreement, each party will have access to certain of the other party’s Confidential Information or Confidential
Information of third parties that the disclosing party is required to maintain as confidential. Both parties agree that all
items of Confidential Information are proprietary to the disclosing party or such third party, as applicable, and will
remain the sole property of the disclosing party or such third party.
    </p>
  
    <p>
      6.2. <u>Mutual Confidentiality Obligations</u>.
 Each party agrees as follows: (ii) to use Confidential Information disclosed
by the other party only for the purposes described herein; (iii) that such party will not reproduce Confidential
Information disclosed by the other party, and will hold in confidence and protect such Confidential Information from
dissemination to, and use by, any third party; (iv) that neither party will create any derivative work from Confidential
Information disclosed to such party by the other party; (v) to restrict access to the Confidential Information disclosed
by the other party to such of its personnel, agents, and/or consultants, if any, who have a need to have access and
who have been advised of and have agreed in writing to treat such information in accordance with the terms of this
Agreement; and (vi) to return or destroy, pursuant to Section 10.3, all Confidential Information disclosed by the other
party that is in its possession upon termination or expiration of this Agreement.
 </p>
  
    <p>
      6.3. <u>Confidentiality Exceptions</u>.
  
     Notwithstanding the foregoing, the provisions of Sections 6.1 and 6.2 will not
apply to Confidential Information that (i) is publicly available or in the public domain at the time disclosed; (ii) is or
becomes publicly available or enters the public domain through no fault of the recipient; (iii) is rightfully communicated
to the recipient by persons not bound by confidentiality obligations with respect thereto; (iv) is already in the recipient’s
possession free of any confidentiality obligations with respect thereto at the time of disclosure; (v) is independently
developed by the recipient; or (vi) is approved for release or disclosure by the disclosing party without restriction.
Notwithstanding the foregoing, each party may disclose Confidential Information to the limited extent required (x) in
order to comply with the order of a court or other governmental body, provided that the party making the disclosure
pursuant to the order will first have given written notice to the other party and made a reasonable effort to obtain a
protective order; (y) or as otherwise necessary to comply with applicable law, or (z) to establish a party’s rights under
this Agreement, including to make such court filings as it may be required to do.
    </p>
  
    <p>
      7. <u>PROPRIETARY RIGHTS</u>.
    </p>
  
    <p>
      7.1. <u>Evident Ownership</u>.
  
    Except for the rights expressly granted by Evident to Relying Party under this
Agreement, as between the parties Evident owns and retains all right, title and interest in and to the Subscription 
6
Services, the Documentation, the Aggregated Statistics and all of Evident’s content, including without limitation,
information, text, graphics, links, documents, data, materials, tools, icons, audio, visual, software, symbols, and
characters incorporated into and available through the Subscription Services, and all Intellectual Property Rights
therein (collectively, the “Evident IP”). Except as otherwise set forth in these Terms and Conditions, nothing contained
in this Agreement will be construed as conferring upon Relying Party, by implication, operation of law or otherwise,
any other license or other right in or to the Evident IP. Relying Party will not take any action inconsistent with Evident’s
ownership of the Evident IP.
   </p>
  
    <p>
      7.2. <u>Relying Party Ownership</u>.
  
       As between the parties, Relying Party owns and retains all right, title and interest
in and to the Relying Party Data and all Intellectual Property Rights therein (collectively, the “Relying Party IP”).
Except as otherwise set forth in these Terms and Conditions, nothing will be construed as conferring upon Evident,
by implication, operation of law or otherwise, any other license or other right in or to the Relying Party IP. Evident
will not take any action inconsistent with Relying Party’s ownership of the Relying Party IP. Relying Party
acknowledges that Authorized End Users have an ownership interest in their respective Evident ID and may use their
Evident ID at their discretion.
    </p>
  
    <p>
      7.3. <u>Feedback</u>.
  
      If Relying Party, Authorized Relying Party Users or Authorized End Users send or transmit any
communications, comments, questions, suggestions, or related materials to Evident, whether by letter, email,
telephone, or otherwise (“Feedback”), suggesting or recommending changes to the Subscription Services, including,
without limitation, new features or functionality relating thereto, all such Feedback is, and will be exclusively owned
by Evident. Relying Party hereby assigns all right, title, and interest in, and Evident is free to use, without any
attribution or compensation to Relying Party, any ideas, know-how, concepts, techniques, and all applicable
intellectual property rights relating to the Feedback, whether or not patentable, for any purpose whatsoever, including
but not limited to, developing, manufacturing, having manufactured, licensing, marketing, and selling, directly or
indirectly, products and services using such Feedback. Relying Party agrees and understands that Evident is not
obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or techniques contained in
the Feedback, and Relying Party has no right to compel such use, display, reproduction, or distribution.
    </p>
  
    <p>
      7.4. <u>Use of Marks</u>.
  
     Relying Party expressly authorizes Evident to use the indicia (i.e., name, logo, trademarks)
of Relying Party on its website and such use shall not violate any Intellectual Property Rights or Confidential
Information of Relying Party.
    </p>
  
    <p>
      8. <u>WARRANTIES, DISCLAIMERS, EXCLUSIONS AND LIMITATIONS OF LIABILITY</u>.
    </p>
  
    <p>
      8.1. <u>Mutual Warranties</u>.
  
    Each party hereby represents and warrants (i) that it is duly organized, validly existing
and in good standing under the laws of its jurisdiction of incorporation or organization, (ii) that the Agreement
constitutes a valid and binding obligation of such party and will be enforceable against such party in accordance with
its terms and (iii) that it will comply with any and all local, state and/or national laws, and/or regulations applicable to
such party, including those related to data privacy and the transmission of personal data. 
    </p>
  
    <p>
      8.2. <u>Disclaimer</u>.
  
       EXCEPT AS EXPRESSLY REPRESENTED OR WARRANTED IN SECTION 8.1, TO THE
MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SUBSCRIPTION SERVICES, THE DATA
SERVICES, AND THE DOCUMENTATION ARE PROVIDED “AS IS” AND EVIDENT, ITS LICENSORS AND
SUPPLIERS DISCLAIM ANY AND ALL OTHER PROMISES, REPRESENTATIONS AND WARRANTIES,
WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY AND ALL IMPLIED WARRANTIES
OF MERCHANTABILITY, ACCURACY, RESULTS OF USE, RELIABILITY, FITNESS FOR A PARTICULAR
PURPOSE, TITLE, INTERFERENCE WITH QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD-PARTY
RIGHTS. FURTHER, EVIDENT, ON BEHALF OF ITSELF AND ITS LICENSORS AND SUPPLIERS, DOES NOT
WARRANT THAT THE SUBSCRIPTION SERVICES, THE DATA SERVICES, OR THE DOCUMENTATION WILL
MEET RELYING PARTY’S REQUIREMENTS OR THAT THE OPERATION OF THE SUBSCRIPTION SERVICES
OR THE DATA SERVICES WILL BE ERROR-FREE, UNINTERRUPTED, VIRUS-FREE OR SECURE, OR THAT
ALL ERRORS WILL BE CORRECTED. EVIDENT DOES NOT GUARANTEE RELYING PARTY’S COMPLIANCE
WITH ALL APPLICABLE LAWS IN ITS USE OF REPORTED INFORMATION, AND DOES NOT PROVIDE LEGAL
OR OTHER COMPLIANCE RELATED SERVICES UPON WHICH RELYING PARTY MAY RELY IN CONNECTION
WITH ITS FURNISHING OF REPORTS. RELYING PARTY UNDERSTANDS THAT ANY CONVERSATION OR
COMMUNICATION WITH EVIDENT'S REPRESENTATIVES REGARDING SEARCHES, VERIFICATIONS OR
OTHER SERVICES OFFERED BY EVIDENT ARE NOT TO BE CONSIDERED A LEGAL OPINION REGARDING
SUCH USE. RELYING PARTY AGREES THAT IT WILL CONSULT WITH ITS OWN LEGAL OR OTHER COUNSEL
REGARDING THE USE OF BACKGROUND SCREENING SERVICES, INCLUDING BUT NOT LIMITED TO, THE 
7
LEGALITY OF USING OR RELYING ON REPORTED INFORMATION, DEVELOPMENT OF INTERNAL POLICIES
AND PROCEDURES, AND ADVERSE ACTION PROCESSES.
    </p>
  
    <p>
      8.3. <u>Exclusions of Remedies; Limitation of Liability</u>.
  
     TO THE FULLEST EXTENT PERMITTED UNDER
APPLICABLE LAW, IN NO EVENT WILL EVIDENT OR ITS LICENSORS OR SUPPLIERS BE LIABLE TO RELYING
PARTY OR TO ANY THIRD PARTY UNDER ANY TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
OTHER LEGAL OR EQUITABLE THEORY FOR (A) ANY LOST PROFITS, LOST OR CORRUPTED DATA,
COMPUTER FAILURE OR MALFUNCTION, INTERRUPTION OF BUSINESS, OR OTHER SPECIAL, INDIRECT,
INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF THE USE OR INABILITY TO
USE EITHER THE SUBSCRIPTION SERVICES OR THE DATA SERVICES OR (B) ANY DAMAGES, IN THE
AGGREGATE, IN EXCESS OF THE TOTAL AMOUNT OF ALL FEES PAID TO EVIDENT BY RELYING PARTY
UNDER SECTION 5 DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE ACT, OMISSION OR EVENT
GIVING RISE TO SUCH LIABILITY, EVEN IF EVIDENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
LOSS OR DAMAGES AND WHETHER OR NOT SUCH LOSS OR DAMAGES ARE FORESEEABLE. ANY CLAIM
ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE BROUGHT WITHIN ONE (1) YEAR AFTER
THE OCCURRENCE OF THE EVENT GIVING RISE TO SUCH CLAIM. IN ADDITION, EVIDENT DISCLAIMS ALL
LIABILITY OF ANY KIND ON BEHALF OF ANY OF EVIDENT’S SUPPLIERS AND LICENSORS.
  </p>
  
    <p>
      8.4. <u>Essential Basis of the Agreement</u>.
  
     Relying Party acknowledges and understands that the disclaimers,
exclusions and limitations of liability set forth in this Section 8 form an essential basis of the agreement between the
parties, that the parties have relied upon such disclaimers, exclusions and limitations of liability in negotiating the
terms and conditions in this Agreement, and that absent such disclaimers, exclusions and limitations of liability, the
terms and conditions of this Agreement would be substantially different.
    </p>
  
    <p>
      9. <u>INDEMNIFICATION</u>.
    </p>
  
    <p>
      9.1.
  
     Relying Party will defend Evident against any and all actions, demands, claims and suits and indemnify and
hold Evident harmless from any and all liabilities, damages and costs (including without limitation reasonable
attorneys’ fees) to the extent arising out of: (i) Relying Party’s use of the Subscription Services or (ii) Relying Party’s
gross negligence or willful misconduct. In the event Evident seeks indemnification or defense from you under this
provision, Evident will promptly notify you in writing of the claim(s) brought against Evident for which it seeks
indemnification or defense. Evident reserves the right, at its option and sole discretion, to assume full control of the
defense of claims with legal counsel of its choice. Relying Party may not enter into any third party agreement, which
would, in any manner whatsoever, affect the rights of Evident, constitute an admission of fault by Evident or bind
Evident in any manner, without the prior written consent of Evident. In the event Evident assumes control of the
defense of such claim, Evident will not settle any such claim requiring payment from Relying Party without Relying
Party’s prior written approval.
    </p>
  
    <p>
      9.2.
  
      EEvident will defend Relying Party against any and all actions, demands, claims and suits to the extent arising
out of any claim that the Subscription Services infringe on or violate any intellectual property rights of any third party.
In the event Relying Party seeks indemnification or defense from Evident under this provision, Relying Party will
promptly notify Evident in writing of the claim(s) brought against Relying Party for which it seeks indemnification or
defense. Evident may not enter into any third party agreement which would, in any manner whatsoever, constitute
an admission of fault by Relying Party without the prior written consent of Relying Party.
    </p>
  
    <p>
      10. <u>TERM AND TERMINATION</u>.
    </p>
  
    <p>
      10.1. <u>Term</u>.
  
       Unless otherwise specified in the applicable Order Form, each term set forth in a Subscription Term
will (i) begin on the start date set forth on the applicable Order, (ii) remain in effect for a minimum period of one (1)
year, and (iii) after the initial Subscription Term, automatically renew as set forth in Section 5.3 above. Unless
otherwise terminated pursuant to this Section 10, the term of these Terms and Conditions commences on the start
date of the first Subscription Term of the first Subscription and continues until terminated as set forth in these Terms
and Conditions.
    </p>
  
    <p>
      10.2. <u>Termination</u>.
  
      This Agreement may be earlier terminated by either party (i) if the other party breaches a
material provision of this Agreement and fails to cure such breach within thirty (30) days (ten (10) days in the case of
non-payment) after receiving written notice of such breach from the non-breaching party; (ii) immediately if Evident
reasonably believes Relying Party or an Authorized Relying Party User is misusing the Subscription Services or (iii)
immediately if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to
insolvency, receivership, liquidation or assignment for the benefit of creditors. 
    </p>
  
    <p>
      10.3. <u>Effects of Termination</u>.
  
      Upon any expiration or termination of this Agreement:
    </p>
  
    <ul class="LegalList">
      <li>
        (a) All rights and obligations of the parties will cease, except that all obligations that accrued prior to the effective
date of termination (including without limitation, all payment obligations) and all remedies for breach of this
Agreement will survive;
      </li>
      <li>
        (b)  Relying Party will make no further use of the Subscription Services or the Documentation, provided that no
termination will relieve the Relying Party of the obligation to pay any fees accrued or payable to Evident;
      </li>
      <li>
        (c) Relying Party will promptly pay to Evident all amounts due and payable to Evident hereunder, inclusive of any
minimum monthly or annual amounts in place for the applicable Subscription Term;
      </li>
      <li>
        (d) All Order Forms hereunder shall terminate;
      </li>
      <li>
        (e) Evident has no obligation to retain Relying Party Data following thirty (30) days after complete termination of the Agreement; and
      </li>
      <li>
        (f) Upon termination, each party will delete any of the other party’s Confidential Information still in their possession
(with the exception of Relying Party Data as set forth in the preceding paragraph and as required to comply with
applicable law) from computer storage or any other media including, but not limited to, online and off-line libraries;
and each party will return to the other party or, at the other party’s option, destroy, all physical copies of any the
other party’s Confidential Information.
      </li>
    </ul>
  
    <p>
      10.4. <u>Survival</u>.
  
      Any provision of these Terms and Conditions which, either by its terms or to give effect to its
meaning, must survive, including the provisions titled Confidentiality, Proprietary Rights, Warranties, Disclaimers,
Exclusions and Limitations on Liability, Indemnification, Effects of Termination and General Provisions will survive.
    </p>
  
    <p>
      11. <u>GENERAL PROVISIONS</u>.
    </p>
  
    <p>
      11.1. <u>Entire Agreement</u>.
  
     This Agreement (which includes any Order Form completed by Relying Party, as well as
those terms and documents incorporated by reference) constitute the entire agreement, and supersedes all prior
negotiations, understandings or agreements (oral or written), between the parties about the subject matter of this
Agreement. No oral statements or prior written material not specifically incorporated herein will be of any force and
effect, and no changes in or additions to this Agreement will be recognized unless incorporated herein by amendment
as provided herein and signed by duly authorized representatives of both parties. The application of Relying Party’s
general terms and conditions in any general vendor acknowledgement or Relying Party’s other general purchasing
conditions (including pre-printed boilerplate terms) are hereby expressly excluded, rejected, and objected to by
Evident. This Agreement will apply and supersede the pre-printed terms and conditions of any form submitted by
either party, unless such form is expressly included herein.
    </p>
  
    <p>
      11.2. <u>Audit</u>.
  
   Relying Party understands and agrees that in order to ensure compliance with applicable law and
Evident’s obligations under its contracts with Suppliers, Evident may conduct periodic reviews of Relying Party’s use
of the Subscription Services and may, upon reasonable notice, audit Relying Party’s records, processes and
procedures related to Relying Party’s use, storage and disposal of the Subscription Services and information received
therefrom. Relying Party agrees to cooperate fully with any and all audits.
    </p>
  
    <p>
      11.3. <u>Waivers, Consents and Modifications</u>.
  
      No waiver, consent or modification of this Agreement will bind Evident
or Relying Party unless in writing and signed by the party against which enforcement is sought. This Agreement may
be modified solely upon the written agreement of both Relying Party and Evident. The failure of either party to enforce
its rights under this Agreement at any time for any period will not be construed as a waiver of such rights.
    </p>
  
    <p>
      11.4. <u>Severability</u>.
  
     If any provision of this Agreement is determined to be illegal or unenforceable, that provision
will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force
and effect and enforceable.
    </p>
  
    <p>
      11.5. <u>Governing Law</u>.
  
     The laws of the State of Georgia, excluding its conflicts of law rules, govern this Agreement.
The exclusive jurisdiction and venue of any action arising out of or related to this Agreement will be either the state
or federal courts in Fulton County, Georgia, and the parties agree and submit to the personal and exclusive jurisdiction
and venue of these courts. Neither the United Nations Convention on Contracts for the International Sale of Goods
nor any implementation of the Uniform Computer Information Transactions Act in any jurisdiction will apply to this
Agreement.
    </p>
  
    <p>
      11.6. <u>Force Majeure</u>.
  
    In the event that either party is prevented from performing, or is unable to perform, any of its
obligations under this Agreement (except payment obligations) due to any cause beyond its reasonable control, the
affected party will give written notice thereof to the other party and its performance will be extended for the period of
delay or inability to perform due to such occurrence.
    </p>
  
    <p>
      11.7. <u>Notices</u>.
  
     Any notice or communication hereunder will be in writing and either personally delivered or sent via
confirmed facsimile, recognized express delivery courier or certified or registered mail, prepaid and return receipt
requested, addressed to the other party, which, in the case of Relying Party, will be the address provided to Evident
in the Order Form, and, in the case of Evident, will be the address set forth in the contact section of Evident’s website,
or at such other address for either party as is designated in a subsequent notice. All notices will be in English,
effective upon receipt.
    </p>
  
    <p>
      11.8. <u>Assignment</u>.
  
     This Agreement will be binding upon and for the benefit of Evident, Relying Party and their
permitted successors and assigns. Either party may assign this Agreement to its affiliates and as part of a corporate
reorganization, consolidation, merger, or sale of substantially all of its assets or business to which this Agreement
relates, with notice to the other party. Except as expressly stated in this Agreement, neither party may otherwise
assign its rights or obligations under this Agreement either in whole or in part without the prior written consent of the
other party, and any attempted assignment or delegation without such consent will be void. 
    </p>
  
    <p>
      11.9. <u>Publicity</u>.
  
      Relying Party shall not reference Evident or the existence of this Agreement publicly without the
prior, express written consent of Evident. Any such approved usage of Evident indicia (i.e., name, logo, trademarks)
by Relying Party shall be in compliance with Evident’s brand and marks usage guidelines as shared by Evident, and
such use if proper shall not violate any Intellectual Property Rights or Confidential Information of Evident. 
    </p>
  
    <p>
      11.10. <u>Independent Contractors</u>.
  
     The parties will be independent contractors under this Agreement, and nothing
herein will constitute either party as the employer, employee, agent or representative of the other party, or both parties
as joint ventures or partners for any purpose.
    </p>
    
    <p>
        <strong>IN WITNESS WHEREOF</strong>, this Agreement is effective as of the start date of the Subscription Term, and each party
hereto has executed and delivered this Agreement by a duly authorized representative as of the dates written
below.
    </p>    
  </article>
`);

const htmlAppendix1 = cleanCode(rpContractAppendixOne);
const htmlAppendix2 = cleanCode(rpContractAppendixTwo);

const htmlCombined = [
    htmlTermsAndConditions,
    htmlAppendix1,
    htmlAppendix2,
].join('');

exports.content = `
    ${htmlTermsAndConditions}
    ${htmlAppendix1}
    ${htmlAppendix2}
`;
exports.hash = crypto.createHash('sha256').update(htmlCombined).digest('hex');
