<template>
    <div v-if="nonExtractionData" class="PolicyInfoNonExtraction">
        <div
            v-for="({ label, titles, evaluationError, criterionName, hasException }) in nonExtractionData"
            :key="label"
            :data-test-id="`PolicyInfoNonExtraction__${criterionName}`"
            class="border-0 border-solid border-t border-eidSecureBlue-10 flex flex-col"
        >
            <div class="flex">
                <span
                    data-test-id="PolicyInfoNonExtraction__nonExtractionCriterionLabel"
                    class="inline-block px-5 pt-3 pb-1 text-sm text-eidLightGray font-medium"
                >
                    {{ label }}
                </span>
                <EvaluationError
                    v-if="evaluationError"
                    data-test-id="PolicyInfoNonExtraction__evaluationExceptionGrandRemove"
                    exception-badge-label="Exception granted"
                    :show-go-to-criterion="false"
                    :evaluation-error="evaluationError"
                    :show-exception-sub-label="false"
                    @grantExceptionToCriterion="grantExceptionToCriterion(evaluationError)"
                    @removeExceptions="removeExceptions"
                />
            </div>
            <div v-for="({ title, valid }) in titles" :key="title" class="inline-flex items-center px-5 py-3">
                <EidIcon
                    v-if="isRequirementHasError(hasException, valid)"
                    data-test-id="PolicyInfoNonExtraction__errorIcon"
                    class="text-eidDangerRed mr-1 text-xs"
                    alt="error"
                    :icon-src="faExclamationTriangle"
                />
                <span
                    data-test-id="PolicyInfoNonExtraction__itemText"
                    class="text-xs"
                    :class="{
                        'text-eidDangerRed': isRequirementHasError(hasException, valid),
                        'text-eidSecureBlue': !isRequirementHasError(hasException, valid)
                    }"
                >
                    {{ title }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import startCase from 'lodash/startCase';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import {
        NonExtractionResults,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review/actions/non-extraction-resolution/NonExtractionResults.model';

    interface NonExtractionData {
        criterionName: string;
        label: string;
        evaluationError: FieldEvaluationResultError | null;
        hasException: boolean;
        titles: {
            title: string;
            valid: boolean;
        }[];
    }

    export default Vue.extend({
        name: 'PolicyInfoNonExtraction',
        components: {
            EidIcon,
            EvaluationError,
        },
        props: {
            nonExtractionResults: {
                type: Object as PropType<NonExtractionResults>,
                default: undefined,
            },
            getEvaluationResult: {
                type: Function as PropType<(url: string) => FieldEvaluationResultError | null>,
                required: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
            };
        },
        computed: {
            nonExtractionData(): NonExtractionData[] | null {
                if (!this.nonExtractionResults) {
                    return null;
                }
                return Object.entries(this.nonExtractionResults).map<NonExtractionData>(([ key, value ]) => {
                    const evaluationResult = this.getEvaluationResult(`nonextraction/${key}`);
                    const { acceptedRequirements, rejectedRequirements } = value;
                    return {
                        criterionName: key,
                        label: startCase(key).toUpperCase(),
                        evaluationError: evaluationResult,
                        hasException: evaluationResult && evaluationResult.complianceStatusMetadata
                            ? evaluationResult.complianceStatusMetadata.length > 0
                            : false,
                        titles: acceptedRequirements.map((ar) => ({ title: ar, valid: true }))
                            .concat(rejectedRequirements.map((rr) => ({
                                title: rr,
                                valid: false,
                            }))),
                    };
                });
            },
        },
        methods: {
            grantExceptionToCriterion(evaluationError: FieldEvaluationResultError | null): void {
                if (!evaluationError) {
                    return;
                }
                this.$emit(
                    'grant-exception-to-criterion',
                    evaluationError.criterionId,
                );
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
            isRequirementHasError(hasException: boolean, valid: boolean) {
                return !hasException && !valid;
            },
        },
    });
</script>
