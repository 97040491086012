<template>
    <div
        class="FileListInput"
        :class="{
            'FileListInput--dragHover': dragHover,
            'FileListInput--disabled': disabled,
        }"
    >
        <label
            v-if="multiple || value.length === 0"
            class="FileListInput__button"
            :for="id"
            @click="openFileBrowser"
            @dragover="onDragOver"
            @dragleave="onDragLeave"
            @dragend="onDragLeave"
            @drop="onDrop"
        >
            <slot>
                <div class="FileListInput__buttonContent">
                    <FontAwesomeIcon :icon="faUpload" />
                    <span v-if="multiple">Drag your file(s) here, or click to browse</span>
                    <span v-else>Drag your file here, or click to browse</span>
                </div>
            </slot>
        </label>
        <input
            :id="id"
            ref="input"
            class="FileListInput__input"
            type="file"
            :accept="acceptAttribute"
            :disabled="disabled"
            style="display: none"
            :multiple="multiple"
            @change="onChange"
        >
        <div class="FileListInput__files">
            <FileListInputFilePreview
                v-for="(file, index) in value"
                :key="index"
                :file="file"
                :allow-remove="!disabled"
                v-bind="buildDescription(file)"
                @remove="removeFile"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faUpload } from '@fortawesome/free-solid-svg-icons';
    import { getAcceptAttribute, validateFiles } from '@evidentid/file-utils/prepareFile';
    import FileListInputFilePreview from './FileListInputFilePreview.vue';

    @Component({
        components: { FileListInputFilePreview, FontAwesomeIcon },
    })
    export default class FileListInput extends Vue {
        private faUpload = faUpload;

        @Prop({ type: String, default: undefined })
        private id!: string | undefined;

        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: Boolean, default: true })
        private multiple!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ default: undefined })
        private accept!: string[] | string | undefined;

        @Prop({ type: Array, default: () => [] })
        private value!: File[];

        @Prop({ type: Function, default: () => ({ description: '' }) })
        private buildDescription!: (file: File) => { description: string, error?: boolean };

        private dragHover: boolean = false;

        private get acceptAttribute(): string | undefined {
            return getAcceptAttribute(this.mimeTypes);
        }

        private get mimeTypes(): string[] | undefined {
            return this.accept
                ? Array.isArray(this.accept) ? this.accept : [ this.accept ]
                : undefined;
        }

        private openFileBrowser(event: Event) {
            event.preventDefault();

            const input = this.$refs.input as HTMLInputElement;

            if (input) {
                input.click();
            }
        }

        private removeFile(file: File) {
            this.$emit('input', (this.value || []).filter((x) => x !== file));
        }

        private addFiles(files: FileList | File[]) {
            const { success, failed } = validateFiles(files, this.mimeTypes);
            this.$emit('input', (this.value || []).concat(success));
            if (failed.length > 0) {
                this.$emit('invalid', failed);
            }
        }

        private onDragOver(event: Event) {
            event.preventDefault();
            this.dragHover = true;
        }

        private onDragLeave(event: Event) {
            event.preventDefault();
            this.dragHover = false;
        }

        private onDrop(event: Event) {
            event.preventDefault();
            this.dragHover = false;

            if (this.disabled) {
                return;
            }

            const { files } = ((event as DragEvent).dataTransfer as DataTransfer) || (event.target as HTMLInputElement);
            this.addFiles(files);
        }

        private onChange(event: Event) {
            this.onDrop(event);

            if (event.target) {
                (event.target as HTMLInputElement).value = '';
            }
        }
    }
</script>
