<template>
    <div class="PopoverCollateralFilters">
        <h3 class="m-0 mt-5 mx-2.5 text-eidTrustGreen font-bold text-sm">
            Collateral
        </h3>
        <template v-for="{ name, label, placeholder, type } in collateralFiltersFields">
            <CustomPropertyFilterField
                :key="name"
                :data-test-id="`PopoverCollateralFilters__${name}`"
                class="inline-block"
                :class="{
                    'w-full': name === 'description',
                    'w-1/2': name !== 'description'
                }"
                :value="filters[getEntityCollateralFieldQueryKey(collateralCustomPropertyKey, name)] || null"
                :type="type"
                :label="label"
                :placeholder="placeholder"
                :filter-key="name"
                @input="inputFilter"
                @scroll-to="emitScrollDistance"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import omit from 'lodash/omit';
    import { getEntityCollateralFieldQueryKey } from '@/modules/entity-filtering/utils/entityFilterUtils';
    import CustomPropertyFilterField
        from '@/modules/entity-filtering/components/EntityFiltersPopover/CustomPropertyFilterField/CustomPropertyFilterField.vue';
    import {
        EntityCollateralFieldFilters,
    } from '@/modules/entity-filtering/models/EntityCollateralFieldFilters.model';
    import { CustomPropertyFilterInputData } from '@/modules/entity-filtering/models/CustomPropertyFilterInputData.model';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    interface CollateralFiltersField {
        name: keyof EntityCollateralFieldFilters;
        type: 'text' | 'number';
        label: string;
        placeholder: string;
    }

    export default Vue.extend({
        name: 'PopoverCollateralFilters',
        components: {
            CustomPropertyFilterField,
        },
        props: {
            customPropertyCollaterals: {
                type: Object as PropType<CustomProperty>,
                required: true,
            },
            filters: {
                type: Object as PropType<Record<string, string>>,
                default: () => ({}) as Record<string, string>,
            },
            collateralCustomPropertyKey: {
                type: String as PropType<string>,
                default: '',
            },
        },
        computed: {
            collateralFiltersFields(): CollateralFiltersField[] {
                const order = [
                    'description',
                    'uniqueIdentifier',
                    'category',
                    'limitRequired',
                    'maximumDeductible',
                ] as (keyof EntityCollateralFieldFilters)[];
                const fields = omit((this.customPropertyCollaterals.schema as any).items.properties, 'id');
                return order.map((fieldKey) => {
                    const { title, type, description } = fields[fieldKey];
                    return {
                        name: fieldKey as keyof EntityCollateralFieldFilters,
                        label: title,
                        type,
                        placeholder: description,
                    } as CollateralFiltersField;
                });
            },
        },
        methods: {
            inputFilter({ filterKey, value }: CustomPropertyFilterInputData): void {
                const key = getEntityCollateralFieldQueryKey(this.collateralCustomPropertyKey, filterKey);
                this.$emit('input', { ...this.filters, [key]: value });
            },
            getEntityCollateralFieldQueryKey,
            emitScrollDistance(distance: number): void {
                this.$emit('scroll-to', distance);
            },
        },
    });
</script>
