import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { IamPermission } from './types';

import './RequestPermissionsModal.less';

export class RequestPermissionsModal {
    private listeners: { resolve: (permissions: IamPermission[]) => void, reject: () => void }[] = [];
    private permissions: IamPermission[] = [];
    private element!: Element;

    public constructor() {
        this.buildElement();
    }

    public get opened(): boolean {
        return this.permissions.length > 0;
    }

    private buildElement(): void {
        this.element = document.createElement('div');
        this.element.className = 'IamRequestPermissionsModal IamRequestPermissionsModal--closed';
        this.element.innerHTML = `
            <div class="IamRequestPermissionsModal__content">
                To perform this operation you need some additional permissions.
                <ul class="IamRequestPermissionsModal__list">
                </ul>
                <div class="IamRequestPermissionsModal__footer">
                    <button type="button" class="IamRequestPermissionsModal__decline">Decline</button>
                    <button type="button" class="IamRequestPermissionsModal__accept">Continue</button>
                </div>
            </div>
        `;
        this.element.querySelector('.IamRequestPermissionsModal__decline')!
            .addEventListener('click', this.decline.bind(this));
        this.element.querySelector('.IamRequestPermissionsModal__accept')!
            .addEventListener('click', this.accept.bind(this));
    }

    public request(...permissions: IamPermission[]): Promise<IamPermission[]> {
        return new Promise((resolve, reject) => {
            this.listeners.push({ resolve, reject });
            this.permissions = uniqWith([ ...this.permissions, ...permissions ], isEqual);
            this.update();
        });
    }

    public decline(): RequestPermissionsModal {
        const listeners = this.listeners;
        this.permissions = [];
        this.listeners = [];
        listeners.forEach(({ reject }) => reject());
        this.update();
        return this;
    }

    private accept(): RequestPermissionsModal {
        const listeners = this.listeners;
        const permissions = this.permissions;
        this.permissions = [];
        this.listeners = [];
        this.update();
        listeners.forEach(({ resolve }) => resolve(cloneDeep(permissions)));
        return this;
    }

    private update(): void {
        if (this.opened) {
            this.element.className = 'IamRequestPermissionsModal IamRequestPermissionsModal--opened';
        } else {
            this.element.className = 'IamRequestPermissionsModal IamRequestPermissionsModal--closed';
        }

        const labels = this.permissions
            .map((x) => `${x.permission || x.resource || x.service}`);

        this.element.querySelector('.IamRequestPermissionsModal__list')!.innerHTML = uniq(labels)
            .map((label) => {
                const li = document.createElement('li');
                li.textContent = label;
                return li.outerHTML;
            })
            .join('\n');
    }

    public mount(element: Element): RequestPermissionsModal {
        element.appendChild(this.element);
        return this;
    }

    public unmount(): RequestPermissionsModal {
        if (this.element?.parentNode) {
            this.element.parentNode.removeChild(this.element);
        }
        return this;
    }
}
