<template>
    <portal v-if="open" :to="target">
        <div
            :key="uuid"
            :class="additionalClassName"
            class="Modal"
            data-test-id="Modal"
            @click="closeModalOnBackdropClick"
        >
            <component :is="form ? 'form' : 'div'" class="Modal__body" @submit="$emit('submit', $event)">
                <div v-if="$scopedSlots.above" class="Modal__above">
                    <slot name="above" />
                </div>
                <header class="Modal__header">
                    <button v-if="allowClose" type="button" class="Modal__headerClose" @click="$emit('close')">
                        <FontAwesomeIcon :icon="closeIcon" />
                    </button>
                    <div class="Modal__headerContent">
                        <slot name="header" />
                    </div>
                </header>
                <div class="Modal__content">
                    <slot />
                </div>
                <div v-if="$scopedSlots.footer" class="Modal__footer">
                    <slot name="footer" />
                </div>
            </component>
        </div>
    </portal>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faTimes } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Modal extends Vue {
        @Prop({ type: String, default: 'default-modal-target' })
        private target!: string;

        @Prop({ type: Boolean, default: true })
        private allowClose!: boolean;

        @Prop({ type: Boolean, default: false })
        private closeOnBackdrop!: boolean;

        @Prop({ type: Boolean, default: true })
        private stopClickPropagation!: boolean;

        @Prop({ type: Boolean, default: false })
        public readonly open!: boolean;

        @Prop({ type: Boolean, default: false })
        private form!: boolean;

        @Prop({ type: String, default: null })
        private additionalClassName!: string | null;

        @Prop({ default: () => faTimes })
        private closeIcon!: IconDefinition;

        private uuid = `${Math.random()}.${Math.random()}`;

        private closeModalOnBackdropClick(event: MouseEvent) {
            if (this.closeOnBackdrop && (event.target as HTMLElement).classList.contains('Modal')) {
                this.$emit('close');
            } else if (this.stopClickPropagation) {
                event.stopPropagation();
            }
        }

        @Watch('target', { immediate: true })
        private onTargetChange(target: string): void {
            this.$modalManager.registerModal(this, target);
        }

        @Watch('open')
        private onOpenChange(): void {
            this.$modalManager.refresh(this.target);
        }

        private destroyed() {
            this.$modalManager.unregisterModal(this);
        }
    }
</script>
