import { ChatParty } from '../models/ChatParty.model';
import { ChatMessage } from '../models';

export function createOnlineChatMessage(message: string, party: ChatParty, createdAt?: string): ChatMessage {
    return {
        id: `${Math.random()}.${Math.random()}`,
        content: message,
        party,
        createdAt: createdAt || new Date().toISOString(),
    };
}
