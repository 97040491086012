<template>
    <div class="OnlineChatMessage" :class="`OnlineChatMessage--${message.party}`">
        <div class="OnlineChatMessage__bubble flex">
            <div
                v-if="typing"
                data-test-id="OnlineChatMessage__typing"
                class="OnlineChatMessage__typing"
            >
                <LineLoader :dot-color="eidSecureBlue" :dot-amount="3" />
            </div>
            <div v-else>
                <div data-test-id="OnlineChatMessage__content" class="OnlineChatMessage__content">
                    <!--
                        the reason to give extra 50 gap is so that, when user hits the exact threshold,
                        the view more modal will actually shows some content that was not on the small chat view
                    -->
                    <div v-if="!disallowViewMore && message.content.length > 550">
                        <span data-test-id="OnlineChatMessage__contentText">{{ message.content.substring(0, 500) }}...</span>
                        <span
                            data-test-id="OnlineChatMessage__messageViewMore"
                            class="OnlineChatMessage__messageViewMore"
                            @click="viewMessageInModal(message)"
                        >
                            View More
                        </span>
                    </div>
                    <div v-else data-test-id="OnlineChatMessage__contentText">
                        {{ message.content }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!typing"
            data-test-id="OnlineChatMessage__timeStamp"
            class="OnlineChatMessage__timeStamp text-eidSecureBlue-50 text-xs"
        >
            {{ messageMark }}
        </div>
    </div>
</template>

<style lang="scss">
    @import "./index.scss";
</style>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { ChatMessage, ChatParty } from '../../models';
    import { LineLoader } from '../../../../components/LineLoader';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'OnlineChatMessage',
        components: {
            LineLoader,
        },
        props: {
            message: {
                type: Object as PropType<ChatMessage>,
                required: true,
            },
            disallowViewMore: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            typing: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data(): { eidSecureBlue: string } {
            return {
                // TODO: support tailwind colors in dadshboard commons
                eidSecureBlue: '#2D3F50',
            };
        },
        computed: {
            messageOwnerName(): string {
                return this.message.party === ChatParty.agent ? 'Evie' : 'Me';
            },
            timeStamp(): string | null {
                if (!this.message.createdAt) {
                    return null;
                }
                return new Date(this.message.createdAt)
                    .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            },
            messageMark(): string {
                const timeStamp = this.timeStamp ? ` - ${this.timeStamp}` : '';
                return `${this.messageOwnerName}${timeStamp}`;
            },
        },
        methods: {
            viewMessageInModal(message: ChatMessage): void {
                this.$emit('view-message-in-modal', message);
            },
        },
    });
</script>
