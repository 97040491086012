<template>
    <div
        class="RequirementTypeCard"
        :class="{
            'RequirementTypeCard--selected': selected,
            'RequirementTypeCard--hasDeprecatedCriteria': hasDeprecatedCriteria,
            'RequirementTypeCard--hasInvalidReferences': hasInvalidReferences,
        }"
        @click="$emit('click')"
    >
        <div class="RequirementTypeCard__titleRow">
            <div class="RequirementTypeCard__iconAndTitle">
                <EidIcon
                    class="RequirementTypeCard__typeIcon"
                    :icon-src="typeIconSrc"
                    :svg-props="{color: evidentGreenColor}"
                />
                <span class="RequirementTypeCard__title">{{ title }}</span>
            </div>
            <div class="RequirementTypeCard__iconContainer">
                <EidIcon
                    :icon-src="deleteIconSrc"
                    alt="delete"
                    class="RequirementTypeCard__deleteIcon"
                    :svg-props="{color: evidentGreenColor}"
                    @click="onDelete"
                />
            </div>
        </div>
        <div class="RequirementTypeCard__criteriaRow">
            <span class="RequirementTypeCard__numberOfCriteria">
                <strong>{{ criteriaCount }}</strong>
                <span> Criteria</span>
            </span>
            <div v-if="isRequirementTypeInEvaluationRule" class="RequirementTypeCard__iconContainer">
                <img :src="balanceScaleLeftSrc" class="RequirementTypeCard__evaluationRuleIcon" alt="icon">
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import balanceScaleLeft from '@/assets/icons/icon-balance-scale-left.svg';
    import { getRequirementTypeIcon } from '@/modules/entity-details/utils/getRequirementTypeIcon';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: {
            EidIcon,
            FontAwesomeIcon,
        },
    })
    export default class RequirementTypeCard extends Vue {
        @Prop()
        private requirementType!: TprmRequirementType;

        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Number, default: 0 })
        private criteriaCount!: number;

        @Prop({ type: Boolean, default: false })
        private selected!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Boolean, default: false })
        private isRequirementTypeInEvaluationRule!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasInvalidReferences!: boolean;

        private deleteIconSrc = binIcon;
        private typeIconSrc = getRequirementTypeIcon(this.requirementType);
        private balanceScaleLeftSrc = balanceScaleLeft;
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;

        @Watch('requirementType', { immediate: true })
        private onTypeChange() {
            this.typeIconSrc = getRequirementTypeIcon(this.requirementType);
        }

        private onDelete(clickEvent: MouseEvent): void {
            clickEvent.stopPropagation();
            this.$emit('delete', this.requirementType);
        }
    }
</script>
