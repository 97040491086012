import omit from 'lodash/omit';
import { SpecialCombinedTprmFieldName } from '@/modules/entity-details/models/SpecialCombinedTprmFieldName.model';
import {
    SpecialCombinedTprmFieldTypeName,
} from '@/modules/entity-details/models/SpecialCombinedTprmFieldTypeName.model';
type PossibleFieldValue = string | number | boolean | object | null;
type ObjectValue = Record<string, PossibleFieldValue>;
function combineCommonNameTypeField(fieldName: string, typeName: string, objectValue: ObjectValue): ObjectValue {
    if (fieldName in objectValue || typeName in objectValue) {
        const fieldValue =
            objectValue[typeName] === 'BLANKET'
                ? [ 'Blanket' ]
                : objectValue[fieldName] || null;
        return omit({
            ...objectValue,
            [fieldName]: fieldValue,
        }, typeName);
    }
    return objectValue;
}

function combineAdditionalInsuredNameAndType(objectValue: ObjectValue): ObjectValue {
    return combineCommonNameTypeField(
        SpecialCombinedTprmFieldName.additionalInsured,
        SpecialCombinedTprmFieldTypeName.additionalInsuredType,
        objectValue,
    );
}

function combineWosCompanyNameAndType(objectValue: ObjectValue): ObjectValue {
    return combineCommonNameTypeField(
        SpecialCombinedTprmFieldName.waiverOfSubrogationCompanyName,
        SpecialCombinedTprmFieldTypeName.waiverOfSubrogationCompanyNameType,
        objectValue,
    );
}

const combiners = [ combineAdditionalInsuredNameAndType, combineWosCompanyNameAndType ];

export function combineSpecialTprmFieldValues(objectValue: ObjectValue): ObjectValue {
    return combiners.reduce((value, combineFunc) => combineFunc(value), objectValue);
}
