<template>
    <div class="JsonSchemaTextView">
        <div class="JsonSchemaTextView__title">
            {{ form.schema.title }}
        </div>
        <div class="JsonSchemaTextView__valueWithError">
            <div class="JsonSchemaTextView__value" :class="displayedValueAdditionalClass">
                {{ value }}
            </div>
            <EvaluationError
                v-if="evaluationError && !evaluationError.isMultiFieldCriterion"
                :evaluation-error="evaluationError"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaNumber, JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    type JsonFormNumber = JsonFormBase<JsonSchemaNumber>;
    type JsonFormString = JsonFormBase<JsonSchemaString>;
    @Component({
        components: { EvaluationError },
        inheritAttrs: false,
    })
    export default class JsonSchemaTextView
        extends AbstractJsonSchemaView<(JsonFormString | JsonFormNumber), (string | number)> {
    }
</script>
