type AnyFn = (...args: any) => any;
export function callableOnce<T extends AnyFn>(fn: T): T {
    let executed = false;
    let result!: ReturnType<T>;
    return ((...args: any) => {
        if (!executed) {
            executed = true;
            result = fn(...args);
        }
        return result;
    }) as unknown as T;
}
