<template>
    <div class="OnlineChatInput">
        <div
            data-test-id="OnlineChatInput__inputContainer"
            class="OnlineChatInput__inputContainer"
        >
            <textarea
                ref="input"
                v-model="message"
                type="text"
                placeholder="Type your message"
                @keyup.ctrl.enter="sendMessage"
                @input="adjustInputHeight"
            />
        </div>
        <div
            data-test-id="OnlineChatInput__sendButton"
            class="OnlineChatInput__sendButton"
            :class="{'OnlineChatInput__sendButton--disabled': sendDisabled}"
            @click="sendMessage"
        >
            <EidIcon
                :icon-src="faPaperPlane"
                class="text-eidTrustGreen"
                data-test-id="OnlineChatInput__sendButtonIcon"
            />
        </div>
    </div>
</template>

<style lang="scss">
    @import "./index.scss";
</style>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { faPaperPlaneTop } from '../../../../assets/custom-icon-definitions';

    // default height of the textarea input that matches stylesheet("./index.scss")
    const DEFAULT_INPUT_HEIGHT = '50px';

    export default Vue.extend({
        name: 'OnlineChatInput',
        components: {
            EidIcon,
        },
        data() {
            return {
                message: '',
                faPaperPlane: faPaperPlaneTop,
            };
        },
        computed: {
            sendDisabled(): boolean {
                return !this.message || this.message.trim().length === 0;
            },
        },
        methods: {
            sendMessage(): void {
                if (!this.sendDisabled) {
                    this.$emit('message-input', this.message.trim());
                    this.message = '';
                    if (this.$refs.input) {
                        (this.$refs.input as HTMLTextAreaElement).style.height = DEFAULT_INPUT_HEIGHT;
                    }
                }
            },
            adjustInputHeight(event: InputEvent): void {
                const input = event.target as HTMLTextAreaElement;
                // reset to default min with match to css
                input.style.height = DEFAULT_INPUT_HEIGHT;
                // if scrollHeight over 50px, will take effect. if less, will not affect, min-height 50px will be used
                input.style.height = `${input.scrollHeight}px`;
            },
        },
    });
</script>
