import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import vuex from '@/modules/decisioning-criteria/vuex';
import Severity from '@evidentid/logger/Severity';
import DecisioningCriteria from '@/modules/decisioning-criteria/views/DecisioningCriteria.vue';
import RiskProfileView from '@/modules/decisioning-criteria/views/RiskProfileView.vue';

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/decisioningCriteria',
        redirect: '/:rpId?/decisioning',
    }, {
        path: '/:rpId?/decisioningCriteria/group/',
        redirect: '/:rpId?/decisioning/riskProfile/',
    }, {
        path: '/:rpId?/decisioningCriteria/group/:id',
        redirect: '/:rpId?/decisioning/riskProfile/:id',
    },
];

export const decisioningCriteriaModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/decisioning',
            name: 'decisioning',
            component: DecisioningCriteria as any,
            meta: { title: 'Decisioning' },
        },
        {
            path: '/:rpId?/decisioning/riskProfile/',
            name: 'createDecisioningRiskProfile',
            component: RiskProfileView as any,
            meta: { title: 'Decisioning' },
        },
        {
            path: '/:rpId?/decisioning/riskProfile/:id',
            name: 'decisioningRiskProfile',
            component: RiskProfileView as any,
            meta: { title: 'Decisioning' },
        },
        ...legacyRoutesRedirect,
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ decisioningCriteria: vuex.instantiateModule })
    .execute((app) => {
        function onLoadingRiskProfilesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Risk Profiles',
                    description: `[Requested] Load risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Risk Profiles',
                    message: `[Requested] Load risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRiskProfilesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Risk Profiles',
                    description: `[Finished] Load risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Risk Profiles',
                    message: `[Finished] Load risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRiskProfilesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Risk Profiles Failed',
                    description: `[Failed] Load risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning - Risk Profiles',
                    message: `[Failed] Load risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEntityRiskProfilesRequest(rpName: string, entityId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Risk Profiles',
                    description: `[Requested] Load entity risk profiles for rp: ${rpName} entity: ${entityId}`,
                    rpName,
                    entityId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Entity Risk Profiles',
                    message: `[Requested] Load entity risk profiles for rp: ${rpName} entity: ${entityId}`,
                    data: { rpName, entityId },
                });
            }
        }

        function onLoadingEntityRiskProfilesFinish(rpName: string, entityId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Risk Profiles',
                    description: `[Finished] Load entity risk profiles for rp: ${rpName} entity: ${entityId}`,
                    rpName,
                    entityId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Entity Risk Profiles',
                    message: `[Finished] Load entity risk profiles for rp: ${rpName} entity: ${entityId}`,
                    data: { rpName, entityId },
                });
            }
        }

        function onLoadingEntityRiskProfilesFailure(rpName: string, entityId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Risk Profiles Failed',
                    description: `[Failed] Load entity risk profiles for rp: ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning - Entity Risk Profiles',
                    message: `[Failed] Load entity risk profiles for rp: ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentRiskProfileRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Risk Profile',
                    description: `[Requested] Load single risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Risk Profile',
                    message: `[Requested] Load single risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentRiskProfileFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Risk Profile',
                    description: `[Finished] Load single risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Risk Profile',
                    message: `[Finished] Load single risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentRiskProfileFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Risk Profile Failed',
                    description: `[Failed] Load single risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning - Risk Profile',
                    message: `[Failed] Load single risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriteriaRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criteria',
                    description: `[Requested] Load criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning - Risk Profile',
                    message: `[Requested] Load criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriteriaFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Risk Profile',
                    description: `[Finished] Load criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile',
                    message: `[Finished] Load criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriteriaFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criteria Failed',
                    description: `[Failed] Load criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile',
                    message: `[Failed] Load criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriterionTemplatesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criterion Templates',
                    description: `[Requested] Load criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Criterion Templates RiskProfile',
                    message: `[Requested] Load criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriterionTemplatesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criterion Templates RiskProfile',
                    description: `[Finished] Load criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Criterion Templates RiskProfile',
                    message: `[Finished] Load criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriterionTemplatesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criterion Templates Failed',
                    description: `[Failed] Load criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Criterion Templates RiskProfile',
                    message: `[Failed] Load criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementTypesPerCountryRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Risk Profile',
                    description: `[Requested] Load single risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile',
                    message: `[Requested] Load single risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementTypesPerCountryFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirements Per Country',
                    description: `[Finished] Load requirements per country for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Requirements Per Country',
                    message: `[Finished] Load requirements per country for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementTypesPerCountryFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Risk Profile Failed',
                    description: `[Failed] Load requirements per country for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Requirements Per Country',
                    message: `[Failed] Load requirements per country for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingRiskProfileRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Risk Profile ',
                    description: `[Requested] Delete risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Requested] Delete risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingRiskProfileFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Risk Profile ',
                    description: `[Finished] Delete risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Finished] Delete risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingRiskProfileFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Risk Profile Failed',
                    description: `[Failed] Delete risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Failed] Delete risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingRiskProfileRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Risk Profile ',
                    description: `[Requested] Create risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Requested] Create risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingRiskProfileFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Risk Profile ',
                    description: `[Finished] Create risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Finished] Create risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingRiskProfileFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Risk Profile Failed',
                    description: `[Failed] Create risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Failed] Create risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCriteriaRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Criteria ',
                    description: `[Requested] Patch criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Requested] Patch criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCriteriaFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Criteria ',
                    description: `[Finished] Patch criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Finished] Patch criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCriteriaFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Criteria Failed',
                    description: `[Failed] Patch criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Failed] Patch criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingRiskProfileRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Risk Profile ',
                    description: `[Requested] Update risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Requested] Update risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingRiskProfileFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Risk Profile ',
                    description: `[Finished] Update risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Finished] Update risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingRiskProfileFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Risk Profile Failed',
                    description: `[Failed] Update risk profile for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Failed] Update risk profile for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCriterionMessageRequest(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Loading Criterion Message ',
                    description: `[Requested] Load risk profile for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Criterion Message ',
                    message: `[Requested] Load criterion message for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingCriterionMessageFinish(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Criterion Message ',
                    description: `[Finished] Load criterion message for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Criterion Message ',
                    message: `[Finished] Load criterion message for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingCriterionMessageFailure(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Risk Profile Failed',
                    description: `[Failed] Update risk profile for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Risk Profile ',
                    message: `[Failed] Update risk profile for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingRequirementModelsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Models',
                    description: `[Requested] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Requirement Models',
                    message: `[Requested] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementModelsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Models',
                    description: `[Finished] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Requirement Models',
                    message: `[Finished] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementModelsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Models Failed',
                    description: `[Failed] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Requirement Models',
                    message: `[Failed] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingRiskProfiles', (x) => onLoadingRiskProfilesRequest(x.rpName));
        app.observer.onMutation('finishLoadingRiskProfiles', (x) => onLoadingRiskProfilesFinish(x.rpName));
        app.observer.onMutation('failLoadingRiskProfiles', (x) => onLoadingRiskProfilesFailure(x.rpName));
        app.observer.onMutation('startLoadingEntityRiskProfiles', (x) => onLoadingEntityRiskProfilesRequest(x.rpName, x.entityId));
        app.observer.onMutation('finishLoadingEntityRiskProfiles', (x) => onLoadingEntityRiskProfilesFinish(x.rpName, x.entityId));
        app.observer.onMutation('failLoadingEntityRiskProfiles', (x) => onLoadingEntityRiskProfilesFailure(x.rpName, x.entityId));
        app.observer.onMutation('startLoadingCurrentRiskProfile', (x) => onLoadingCurrentRiskProfileRequest(x.rpName));
        app.observer.onMutation('finishLoadingCurrentRiskProfile', (x) => onLoadingCurrentRiskProfileFinish(x.rpName));
        app.observer.onMutation('failLoadingCurrentRiskProfile', (x) => onLoadingCurrentRiskProfileFailure(x.rpName));
        app.observer.onMutation('startLoadingCriteria', (x) => onLoadingCriteriaRequest(x.rpName));
        app.observer.onMutation('finishLoadingCriteria', (x) => onLoadingCriteriaFinish(x.rpName));
        app.observer.onMutation('failLoadingCriteria', (x) => onLoadingCriteriaFailure(x.rpName));
        app.observer.onMutation('startLoadingCriterionTemplates', (x) => onLoadingCriterionTemplatesRequest(x.rpName));
        app.observer.onMutation('finishLoadingCriterionTemplates', (x) => onLoadingCriterionTemplatesFinish(x.rpName));
        app.observer.onMutation('failLoadingCriterionTemplates', (x) => onLoadingCriterionTemplatesFailure(x.rpName));
        app.observer.onMutation('startLoadingRequirementTypesPerCountry', (x) => onLoadingRequirementTypesPerCountryRequest(x.rpName));
        app.observer.onMutation('finishLoadingRequirementTypesPerCountry', (x) => onLoadingRequirementTypesPerCountryFinish(x.rpName));
        app.observer.onMutation('failLoadingRequirementTypesPerCountry', (x) => onLoadingRequirementTypesPerCountryFailure(x.rpName));
        app.observer.onMutation('startDeletingRiskProfile', (x) => onDeletingRiskProfileRequest(x.rpName));
        app.observer.onMutation('finishDeletingRiskProfile', (x) => onDeletingRiskProfileFinish(x.rpName));
        app.observer.onMutation('failDeletingRiskProfile', (x) => onDeletingRiskProfileFailure(x.rpName));
        app.observer.onMutation('startCreatingRiskProfile', (x) => onCreatingRiskProfileRequest(x.rpName));
        app.observer.onMutation('finishCreatingRiskProfile', (x) => onCreatingRiskProfileFinish(x.rpName));
        app.observer.onMutation('failCreatingRiskProfile', (x) => onCreatingRiskProfileFailure(x.rpName));
        app.observer.onMutation('startPatchingCriteria', (x) => onPatchingCriteriaRequest(x.rpName));
        app.observer.onMutation('finishPatchingCriteria', (x) => onPatchingCriteriaFinish(x.rpName));
        app.observer.onMutation('failPatchingCriteria', (x) => onPatchingCriteriaFailure(x.rpName));
        app.observer.onMutation('startUpdatingRiskProfile', (x) => onUpdatingRiskProfileRequest(x.rpName));
        app.observer.onMutation('finishUpdatingRiskProfile', (x) => onUpdatingRiskProfileFinish(x.rpName));
        app.observer.onMutation('failUpdatingRiskProfile', (x) => onUpdatingRiskProfileFailure(x.rpName));

        app.observer.onMutation('startLoadingCriterionMessage', (x) => onLoadingCriterionMessageRequest(x.rpName, x.criterionId));
        app.observer.onMutation('finishLoadingCriterionMessage', (x) => onLoadingCriterionMessageFinish(x.rpName, x.criterionId));
        app.observer.onMutation('failLoadingCriterionMessage', (x) => onLoadingCriterionMessageFailure(x.rpName, x.criterionId));
        app.observer.onMutation('startLoadingRequirementModels', (x) => onLoadingRequirementModelsRequest(x.rpName));
        app.observer.onMutation('finishLoadingRequirementModels', (x) => onLoadingRequirementModelsFinish(x.rpName));
        app.observer.onMutation('failLoadingRequirementModels', (x) => onLoadingRequirementModelsFailure(x.rpName));
    })
    .end();
