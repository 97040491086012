<template>
    <LoadingModal
        v-if="loading"
        @close="$emit('abort')"
    />
    <SetRemindersModal
        v-else
        open
        :type="type"
        :initial-value="initialValue"
        :submitting="submitting"
        @close="$emit('abort')"
        @submit="onSubmit"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { NotificationName } from '@evidentid/config-api-client/types';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import SetRemindersModal from '../components/SetRemindersModal.vue';
    import {
        NotificationCadence,
        NotificationsConfigurationState,
    } from '@/modules/notifications-configuration/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

    @Component({
        components: {
            LoadingModal,
            SetRemindersModal,
        },
    })
    export default class SetRemindersProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private type!: NotificationName;

        private get state(): NotificationsConfigurationState {
            return this.$store.state.notificationsConfiguration;
        }

        private get initialValue(): any | null {
            return this.state.cadences?.[this.rpName]?.[this.type] || null;
        }

        private get loadStatus(): OperationStatus {
            return this.state.loadCadences?.[this.rpName]?.[this.type] || OperationStatus.uninitialized;
        }

        private get updateStatus(): OperationStatus {
            return this.state.updateCadences?.[this.rpName]?.[this.type] || OperationStatus.uninitialized;
        }

        private get loading(): boolean {
            return [ OperationStatus.uninitialized, OperationStatus.loading ].includes(this.loadStatus);
        }

        private get submitting(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private async onSubmit(cadence: NotificationCadence): Promise<void> {
            await this.$store.actions.notificationsConfiguration.updateNotificationCadence({
                rpName: this.rpName,
                type: this.type,
                cadence,
            });
            if (this.updateStatus === OperationStatus.success) {
                await this.$store.actions.dashboard.loadRequestsConfig({ rpName: this.rpName });
                await this.$store.actions.snackbar.displaySnackbar({
                    message: 'Updated reminders successfully.',
                    success: true,
                });
                this.$emit('finish');
            }
        }

        private mounted(): void {
            this.$store.actions.notificationsConfiguration.loadNotificationCadence({
                rpName: this.rpName,
                type: this.type,
            });
        }
    }
</script>
