<template>
    <div :class="`Popover Popover--position-${position}`">
        <div v-if="$slots.base"
            class="Popover__base"
        >
            <slot name="base" />
        </div>
        <div v-if="open"
            ref="popper"
            class="Popover__popper"
            @click="clickedInside"
            @mousedown="dragging=true"
            @mouseup="dragging=false"
        >
            <slot />
        </div>
    </div>
</template>
<style lang="scss">
    .Popover {
        &__base {
            position: relative;
        }

        &__popper {
            position: absolute;
            z-index: 9999999;
            margin-top: 5px;
        }

        &--position-top-end &__popper {
            right: 0;
            bottom: 100%;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from '@evidentid/vue-property-decorator';
    import { isElementAncestorOf } from '@evidentid/browser-framework/isElementAncestorOf';

    @Component
    export default class Popover extends Vue {
        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: String, default: 'bottom-start' })
        private position!: string;

        @Ref()
        private popper!: HTMLDivElement;

        private dragging = false;
        private unsubscribeBodyListener: Function | null = null;

        private clickedInside(event: Event): void {
            event.stopPropagation();
        }

        private clickedOutside(event: Event): void {
            if (this.open && !this.dragging && !isElementAncestorOf(event.target, this.popper)) {
                this.$emit('hide');
            }
            this.dragging = false;
        }

        private addListeners(): void {
            const onClickOutside = this.clickedOutside.bind(this);
            document.body.addEventListener('click', onClickOutside, true);
            this.unsubscribeBodyListener = () => {
                document.body.removeEventListener('click', onClickOutside, true);
            };
        }

        private removeListeners(): void {
            if (this.unsubscribeBodyListener) {
                this.unsubscribeBodyListener();
                this.unsubscribeBodyListener = null;
            }
        }

        private mounted() {
            this.addListeners();
        }

        private destroyed() {
            this.removeListeners();
        }
    }
</script>
