import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import ApiSettings from '@/modules/api-settings/views/ApiSettings.vue';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import {
    addCommonFailTracker,
    addCommonFinishTracker,
    addCommonStartTracker,
} from '@/utils/vuex-common/addCommonRequestTracker';
import ConfigApiClient from '@evidentid/config-api-client';

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/admin/api-settings',
        redirect: '/:rpId?/configuration/api-settings',
    },
];
export const apiSettingsModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/configuration/api-settings',
            name: 'apiSettings',
            component: ApiSettings as any,
            meta: { title: 'API Settings' },
        },
        ...legacyRoutesRedirect,
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        configClient: ConfigApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ apiSettings: vuex.instantiateModule })
    .execute((app) => {
        app.observer.onMutation('startLoadingApiKey',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'Api Key', 'load'),
        );
        app.observer.onMutation('finishLoadingApiKey',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'Api Key', 'load'),
        );
        app.observer.onMutation('failLoadingApiKey',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'Api Key', 'load'),
        );
        app.observer.onMutation('startLoadingWebhooks',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'Webhooks', 'load'),
        );
        app.observer.onMutation('finishLoadingWebhooks',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'Webhooks', 'load'),
        );
        app.observer.onMutation('failLoadingWebhooks',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'Webhooks', 'load'),
        );
        app.observer.onMutation('startLoadingWebhookTypes',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'WebhookTypes', 'load'),
        );
        app.observer.onMutation('finishLoadingWebhookTypes',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'WebhookTypes', 'load'),
        );
        app.observer.onMutation('failLoadingWebhookTypes',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'WebhookTypes', 'load'),
        );
        app.observer.onMutation('startCreatingWebhook',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Create'),
        );
        app.observer.onMutation('finishCreatingWebhook',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Create'),
        );
        app.observer.onMutation('failCreatingWebhook',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Create'),
        );
        app.observer.onMutation('startPatchingWebhook',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Patch'),
        );
        app.observer.onMutation('finishPatchingWebhook',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Patch'),
        );
        app.observer.onMutation('failPatchingWebhook',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Patch'),
        );
        app.observer.onMutation('startDeletingWebhook',
            (x) => addCommonStartTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Delete'),
        );
        app.observer.onMutation('finishDeletingWebhook',
            (x) => addCommonFinishTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Delete'),
        );
        app.observer.onMutation('failDeletingWebhook',
            (x) => addCommonFailTracker(app, x.rpName, 'Api Settings', 'Webhook', 'Delete'),
        );
    })
    .end();
