<template>
    <div v-if="isInformationBannerVisible" class="CriterionInformationBanner py-0 px-[15px]">
        <Alert
            v-if="template.displayMetadata.informationText"
            type="regular"
        >
            <img class="CriterionInformationBanner__bannerIcon" :src="infoCircle" alt="info">
            <span>{{ template.displayMetadata.informationText }}</span>
        </Alert>
        <GeneralAggregateLimitBreakdownBanner
            v-if="isSpecialConditionSetsCriterion"
            :criterion="criterion"
            :custom-properties="customProperties"
        />
    </div>
</template>

<style lang="scss">
    .CriterionInformationBanner {
        .Alert {
            @apply bg-eidTechGray-10 text-[0.9rem] rounded-md items-center justify-start;
        }

        &__bannerIcon {
            @apply mr-2.5;
        }
    }
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import infoCircle from '@/assets/icons/info-circle-transparent-background-icon.svg';
    import GeneralAggregateLimitBreakdownBanner
        from '@/modules/decisioning-criteria/components/GeneralAggregateLimitBreakdownBanner/GeneralAggregateLimitBreakdownBanner.vue';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { isSpecialConditionSetsCriterion, isInformationBannerVisible } from './utils';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    export default Vue.extend({
        name: 'CriterionInformationBanner',
        components: {
            GeneralAggregateLimitBreakdownBanner,
            Alert,
        },
        props: {
            criterion: {
                type: Object as PropType<Criterion | CriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<CriterionTemplate>,
                required: true,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
        },
        data() {
            return {
                infoCircle,
            };
        },
        computed: {
            isSpecialConditionSetsCriterion(): boolean {
                return isSpecialConditionSetsCriterion(this.criterion);
            },
            isInformationBannerVisible(): boolean {
                return isInformationBannerVisible(this.criterion, this.template);
            },
        },
    });
</script>
