<template>
    <label class="RelyingPartySelection">
        <FormInput>
            <template #icon>
                <FontAwesomeIcon :icon="faChevronDown" />
            </template>
            <Select placeholder="Select Client" class="RelyingPartySelection__select" :value="value" @change="onChange">
                <option v-for="rp in sortedOptions" :key="rp.name" :value="rp.name">
                    {{ rp.displayName || rp.name }}
                </option>
            </Select>
        </FormInput>
    </label>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import sortBy from 'lodash/sortBy';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { Select } from '@evidentid/dashboard-commons/components/Select';
    import { FormInput } from '@evidentid/dashboard-commons/components/Form';

    @Component({
        components: { Select, FontAwesomeIcon, FormInput },
    })
    export default class RelyingPartySelection extends Vue {
        private faChevronDown = faChevronDown;

        @Prop({ type: Array, default: () => [] })
        private options!: ({ displayName: string, name: string })[];

        @Prop({ type: String, default: null })
        private value!: string | null;

        private sortedOptions: ({ displayName?: string, name: string })[] = [];

        @Watch('options', { immediate: true })
        private onOptionsChanged(): void {
            this.sortedOptions = sortBy(this.options, ({ displayName }) => displayName?.toLowerCase());
        }

        private onChange(event: InputEvent) {
            this.$emit('input', (event.target as HTMLSelectElement).value);
        }
    }
</script>
