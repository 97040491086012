<template>
    <Menu big>
        <MenuLink
            v-for="item in flatMenuItems"
            :key="item.name"
            :active="activeMenu === item.name"
            :href="item.href"
            :icon="item.icon"
            :label="item.label"
            class="SidebarMenu__topLevelItem"
        />
        <SidebarMenuConfigurationItem :active="activeMenu === 'configuration'" />
        <SidebarMenuNotificationItem :active="activeMenu === 'notifications'" />
    </Menu>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import WithPermissions from '@evidentid/dashboard-commons/modules/auth/components/WithPermissions.vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import dashboardIcon from '@/assets/icons/nav-icon-dashboard.svg';
    import decisioningIcon from '@/assets/icons/nav-icon-decisioning-criteria.svg';
    import actionsIcon from '@/assets/icons/nav-icon-actions-review.svg';
    import SidebarMenuConfigurationItem from './sub-components/SidebarMenuConfigurationItem.vue';
    import SidebarMenuNotificationItem from './sub-components/SidebarMenuNotificationItem.vue';

    @Component({
        components: {
            EidIcon,
            Menu,
            MenuLink,
            SidebarMenuConfigurationItem,
            SidebarMenuNotificationItem,
            WithPermissions,
        },
    })
    export default class SidebarMenu extends Vue {
        private flatMenuItems = [
            { name: 'dashboard', href: '#/dashboard', label: 'Dashboard', icon: dashboardIcon },
            { name: 'decisioning', href: '#/decisioning', label: 'Decisioning', icon: decisioningIcon },
            { name: 'actions', href: '#/actions', label: 'Actions', icon: actionsIcon },
        ];

        private get activeMenu(): string | null {
            const routeName = this.$route.name!;
            const routePath = this.$route.path!;
            if (routeName === 'dashboard') {
                return 'dashboard';
            }
            if (routePath.includes('/decisioning')) {
                return 'decisioning';
            }
            if (routePath.includes('/actions')) {
                return 'actions';
            }
            if (routePath.includes('/notifications')) {
                return 'notifications';
            }
            /*
             * routePath.includes('/admin') due to manage user route is under admin as it's a shared module,
             * condition included here in case any unexpected usage by manually type in urls with /admin/manage-user
             */
            if (routePath.includes('/configuration') || routePath.includes('/admin')) {
                return 'configuration';
            }
            return null;
        }
    }
</script>
