<template>
    <JsonSchemaPhoneForm
        v-if="form.schema.format === 'phone'"
        v-bind="$props"
        :disabled="isImmutable || disabled"
        @blur="$emit('change')"
        v-on="$listeners"
    >
        <template #icon>
            <slot name="icon" />
        </template>
    </JsonSchemaPhoneForm>
    <JsonSchemaEmailForm
        v-else-if="form.schema.format === 'email'"
        v-bind="$props"
        :disabled="isImmutable || disabled"
        v-on="$listeners"
    >
        <template #icon>
            <img v-if="isImmutable" :src="lockImg" alt="">
        </template>
    </JsonSchemaEmailForm>
    <JsonSchemaTextForm
        v-else
        v-bind="$props"
        :disabled="isImmutable || disabled"
        v-on="$listeners"
    >
        <template #icon>
            <slot name="icon" />
        </template>
    </JsonSchemaTextForm>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement } from '@evidentid/dashboard-commons/components/Form';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import JsonSchemaEmailForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaEmailForm.vue';
    import JsonSchemaTextForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaTextForm.vue';
    import JsonSchemaPhoneForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaPhoneForm.vue';
    import lockImg from '@/modules/entity-details/assets/lock-icon.svg';

    type JsonFormString = JsonFormBase<JsonSchemaString>;

    @Component({
        components: {
            JsonSchemaEmailForm,
            JsonSchemaTextForm,
            JsonSchemaPhoneForm,
            FormElement,
        },
        inheritAttrs: false,
    })
    export default class EntityDetailsStringForm extends AbstractJsonSchemaForm<JsonFormString, string> {
        private lockImg = lockImg;

        private get isImmutable(): boolean {
            return this.form.schema.title === 'Primary Contact Email';
        }
    }
</script>
