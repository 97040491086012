import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/entity-details/constant';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
import { RequirementDetailsKeysWithErrors } from '@/modules/entity-details/models/RequirementDetailsKeysWithErrors.model';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

function complexDetailsKeysWithErrors(
    requirement: EntityRequirement,
    evaluationErrors: Record<string, FieldEvaluationResultError>
): RequirementDetailsKeysWithErrors {
    const evaluationErrorDetailsPath = `#/${requirement.coverageType}/details/`;
    const pathDelimiter = '/';
    return Object.keys(evaluationErrors).reduce<RequirementDetailsKeysWithErrors>((result, key) => {
        if (key.startsWith(evaluationErrorDetailsPath)) {
            const [ complexPropKey, complexPropSubKey ] =
                key.replace(evaluationErrorDetailsPath, '').split(pathDelimiter);

            if (complexPropSubKey) {
                if (!result[complexPropKey]) {
                    result[complexPropKey] = {};
                }

                result[complexPropKey][complexPropSubKey] = true;
            }
        }

        return result;
    }, {} as RequirementDetailsKeysWithErrors);
}

export function getRequirementDetailsKeysValueWithErrors(
    { key, value, requirement, evaluationErrors, crossCriteria }: {
        key: string;
        value: unknown;
        requirement: EntityRequirement;
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        crossCriteria?: EntityMultiFieldCriterion[];
    }
): unknown {
    if (value === null) {
        const complexDetailWithErrorsForItsProps =
            complexDetailsKeysWithErrors(requirement, evaluationErrors)[key];

        if (complexDetailWithErrorsForItsProps) {
            return Object.keys(complexDetailWithErrorsForItsProps)
                .reduce<Record<string, string>>(
                    (_result, _key) => {
                        _result[_key] = FIELD_NOT_AVAILABLE_MESSAGE;
                        return _result;
                    },
                    {} as Record<string, string>,
                );
        } else if (evaluationErrors[`#/${requirement.coverageType}/details/${key}`] && crossCriteria) {
            const keyIsInMultiFieldCriterion = crossCriteria.some((criterion) =>
                Object.keys(criterion.fields).includes(key),
            );

            if (!keyIsInMultiFieldCriterion) {
                return FIELD_NOT_AVAILABLE_MESSAGE;
            }
        }
    } else if (Array.isArray(value) && value.length === 0 && evaluationErrors[`#/${requirement.coverageType}/details/${key}`]) {
        return FIELD_NOT_AVAILABLE_MESSAGE;
    } else if (!Array.isArray(value) && typeof value === 'object') {
        return Object.entries(value).reduce<Record<string, any>>(
            (newValue, [ propKey, propValue ]) => {
                const hasEvaluationError = evaluationErrors[`#/${requirement.coverageType}/details/${key}/${propKey}`];
                newValue[propKey] = propValue;

                if (!newValue[propKey] && hasEvaluationError) {
                    newValue[propKey] = FIELD_NOT_AVAILABLE_MESSAGE;
                }
                return newValue;
            },
            {} as Record<string, any>,
        );
    }

    return value;
}
