<template>
    <div
        class="EntitySearchAndFilter"
        :class="{
            'EntitySearchAndFilter--open':opened,
            'EntitySearchAndFilter--active': hasFilters,
            'EntitySearchAndFilter--disabled': disabled
        }"
    >
        <Popover
            :open="opened"
            @hide="closeFilterPopover"
        >
            <template #base>
                <div class="EntitySearchAndFilter__container">
                    <div class="EntitySearchAndFilter__prefix">
                        <FontAwesomeIcon :icon="faSearch" />
                    </div>
                    <input
                        class="EntitySearchAndFilter__embeddedSearch"
                        type="text"
                        placeholder="Search..."
                        :value="search"
                        :disabled="disabled"
                        @input="scheduleChange"
                    >
                    <div class="EntitySearchAndFilter__postfix" @click="toggleFilterPopover">
                        <span>Advanced</span>
                        <FontAwesomeIcon :icon="faCaretDown" />
                    </div>
                </div>
            </template>

            <EntityFiltersPopover
                :filters="filters"
                :custom-properties="customProperties"
                :risk-profiles="riskProfiles"
                :effective-risk-profiles="effectiveRiskProfiles"
                :collateral-custom-property-key="collateralCustomPropertyKey"
                :custom-property-collaterals="customPropertyCollaterals"
                @close="closeFilterPopover"
                @submit="applyFilters"
            />
        </Popover>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import isEqual from 'lodash/isEqual';
    import pickBy from 'lodash/pickBy';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
    import { EntityFilters } from '@/modules/entity-filtering/types';
    import EntityFiltersPopover
        from '@/modules/entity-filtering/components/EntityFiltersPopover/EntityFiltersPopover.vue';
    import Popover from '@evidentid/dashboard-commons/components/Popover/Popover.vue';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        RiskProfile,
    } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';
    import {
        EffectiveRiskProfiles,
    } from '@evidentid/tprm-portal-lib/models/decisioning/EffectiveRiskProfiles.model';

    export default Vue.extend({
        name: 'EntitySearchAndFilter',
        components: {
            Popover,
            FontAwesomeIcon,
            EntityFiltersPopover,
        },
        props: {
            debounceTime: {
                type: Number as PropType<number>,
                default: 500,
            },
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            filters: {
                type: Object as PropType<EntityFilters>,
                default: () => ({}) as EntityFilters,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
            riskProfiles: {
                type: Array as PropType<RiskProfile[]>,
                default: () => [] as RiskProfile[],
            },
            effectiveRiskProfiles: {
                type: Array as PropType<EffectiveRiskProfiles[]>,
                default: () => [] as EffectiveRiskProfiles[],
            },
            collateralCustomPropertyKey: {
                type: String as PropType<string>,
                default: '',
            },
            customPropertyCollaterals: {
                type: Object as PropType<CustomProperty|null|undefined>,
                default: undefined,
            },
        },
        data() {
            return {
                search: '',
                faSearch,
                faCaretDown,
                isMounted: false,
                debounceTimeout: undefined as undefined|number,
                opened: false,
            };
        },
        computed: {
            hasFilters(): boolean {
                return Object.keys(this.filters).length > 0;
            },
        },
        watch: {
            filters: {
                immediate: true,
                handler(newValue: EntityFilters) {
                    this.search = newValue.search || '';
                    this.abortScheduledAction();
                },
            },
        },
        mounted() {
            this.isMounted = true;
        },
        destroyed() {
            this.isMounted = false;
        },
        methods: {
            scheduleChange(inputEvent: InputEvent): void {
                this.search = (inputEvent.target as HTMLInputElement).value;
                this.abortScheduledAction();
                this.debounceTimeout = setTimeout(
                    this.immediateChange.bind(this),
                    this.debounceTime,
                ) as unknown as number;
            },
            immediateChange(): void {
                this.abortScheduledAction();
                if (this.isMounted && !isEqual(this.search, this.filters.search)) {
                    this.$emit('change', pickBy({ ...this.filters, search: this.search }, Boolean));
                }
            },
            abortScheduledAction() {
                clearTimeout(this.debounceTimeout);
            },
            closeFilterPopover() {
                this.opened = false;
            },
            toggleFilterPopover(event: Event) {
                this.opened = !this.opened;
                if (this.opened) {
                    event.stopPropagation();
                }
            },
            applyFilters(filters: EntityFilters) {
                this.$emit('change', pickBy({
                    ...filters,
                }, Boolean));
                this.closeFilterPopover();
            },
        },
    });
</script>
