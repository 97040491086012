import { Component } from 'vue';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import type ConfigApiClient from '@evidentid/config-api-client';
import { NotificationName } from '@evidentid/config-api-client/types';
import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import { createPersistingErrorModule } from '@evidentid/dashboard-commons/modules/persisting-error';
import { createSnackbarModule } from '@evidentid/dashboard-commons/modules/snackbar';
import NotificationsEmailConfiguration from './views/NotificationsEmailConfiguration.vue';
import NotificationsEmailTemplates from './views/NotificationsEmailTemplates.vue';
import BrandingSettings from './views/BrandingSettings.vue';
import SupportContactInformation from './views/SupportContactInformation.vue';
import SetRemindersProcedure from './procedures/SetRemindersProcedure.vue';
import EditEmailTemplateProcedure from './procedures/EditEmailTemplateProcedure.vue';
import vuex from './vuex';
import SendTestEmailProcedure from '@/modules/notifications-configuration/procedures/SendTestEmailProcedure.vue';

import {
    VerificationRequestType,
} from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

interface NotificationsConfigurationModuleExpectedViews {
    Page: Component;
}

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/admin/email-settings/configuration',
        redirect: '/:rpId?/notifications/email-settings',
    }, {
        path: '/:rpId?/admin/email-settings/templates',
        redirect: '/:rpId?/notifications/email-content',
    }, {
        path: '/:rpId?/admin/notifications/branding',
        redirect: '/:rpId?/notifications/branding',
    }, {
        path: '/:rpId?/admin/notifications/support',
        redirect: '/:rpId?/notifications/contact',
    },
];

export function createNotificationsConfigurationModule() {
    return createModuleBuilder()
        .demandOptions<{
            views: NotificationsConfigurationModuleExpectedViews;
        }>()
        .demandContext<{
            rpweb: RpWebApiClient;
            configClient: ConfigApiClient;
        }>()
        .registerRoutes(() => [
            {
                path: '/:rpId?/notifications/email-content',
                name: 'notifications-email-content',
                component: NotificationsEmailConfiguration as any,
                meta: { title: 'Email Settings - Configuration' },
            },
            {
                path: '/:rpId?/notifications/email-settings',
                name: 'notifications-email-settings',
                component: NotificationsEmailTemplates as any,
                meta: { title: 'Email Settings - Templates' },
            },
            {
                path: '/:rpId?/notifications/branding',
                name: 'notifications-branding-settings',
                component: BrandingSettings as any,
                meta: { title: 'Branding Settings' },
            },
            {
                path: '/:rpId?/notifications/contact',
                name: 'notifications-support-contact',
                component: SupportContactInformation as any,
                meta: { title: 'Support Contact Information' },
            },
            ...legacyRoutesRedirect,
        ])
        .injectVue((app) => ({
            $notificationsConfiguration: {
                Page: app.options.views.Page,
            },
        }))
        .demand<ReturnType<typeof createPersistingErrorModule>>()
        .demand<ReturnType<typeof createSnackbarModule>>()
        .registerVuex({ notificationsConfiguration: vuex.instantiateModule })
        .registerProcedures<{
            setNotificationReminders: { rpName: string, type: NotificationName };
            editNotificationEmailTemplate: { rpName: string, type: VerificationRequestType };
            sendTestEmail: { rpName: string, type: VerificationRequestType };
        }>({
            setNotificationReminders: SetRemindersProcedure,
            editNotificationEmailTemplate: EditEmailTemplateProcedure,
            sendTestEmail: SendTestEmailProcedure,
        })
        .end();
}

export type NotificationsConfigurationModule = ReturnType<typeof createNotificationsConfigurationModule>;
