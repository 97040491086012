<template>
    <div class="RequirementTypeList">
        <div class="RequirementTypeList__header">
            <strong>Requirements</strong>
            <Button
                v-if="!disableEvaluationRule"
                tag="button"
                class="RequirementTypeList__button RequirementTypeList__addRuleButtonContent"
                @click="toggleEvaluationRuleModal"
            >
                <template v-if="hasEvaluationRule">
                    Edit Evaluation Rule
                </template>
                <template v-else>
                    <span class="RequirementTypeList__plusIcon">+</span>
                    Add Evaluation Rule
                </template>
            </Button>
        </div>
        <div
            class="RequirementTypeList__content"
            :class="{'RequirementTypeList__content--empty':requirementTypeList.length === 0}"
        >
            <CriteriaWarningMessage
                v-if="hasDeprecatedCriteria"
                class="RequirementTypeList__alert"
            >
                One or more requirements have deprecated criteria.
            </CriteriaWarningMessage>
            <CriteriaDangerMessage
                v-if="isInvalidCriteriaMessage"
                class="RequirementTypeList__alert"
            >
                One or more requirements could not be validated. Please check for errors.
            </CriteriaDangerMessage>
            <p v-if="requirementTypeList.length === 0 && !addingRequirementType">
                You haven’t added any requirements yet. Select requirements to start configuring your risk profile.
            </p>
            <RequirementTypeCard
                v-for="(requirementType,index) of sortedRequirementTypeList"
                :key="index"
                :has-deprecated-criteria="hasDeprecatedCriteriaByRequirementType(requirementType)"
                :has-invalid-references="isReferenceStartsWithIsInvalid(requirementType)"
                :requirement-type="requirementType"
                :title="getRequirementTypeLabel(requirementType)"
                :criteria-count="getCriteriaCount(requirementType)"
                :selected="requirementType === selectedRequirementType"
                :is-requirement-type-in-evaluation-rule="isRequirementTypeInEvaluationRule(requirementType)"
                @delete="deleteRequirementType"
                @click="selectRequirementType(requirementType)"
            />
            <div v-if="addingRequirementType">
                <Expandable ref="requirementTypeSelector" legacy-mode>
                    <template #button>
                        <div class="RequirementTypeList__selectTypeBox">
                            <span>Select Requirement</span>
                            <FontAwesomeIcon :icon="faCaretDown" />
                        </div>
                    </template>
                    <Menu spaced>
                        <MenuLink
                            v-for="(coveragesType, index) of availableRequirementTypes"
                            :key="index"
                            class="RequirementTypeList__requirementTypeMenu"
                            :class="{'RequirementTypeList__requirementTypeMenu--hover': currentlyHoveredIndex === index}"
                            :icon="getRequirementTypeIcon(coveragesType)"
                            :label="getRequirementTypeLabel(coveragesType)"
                            @mouseenter.native="setCurrentHoverIndex(index)"
                            @mouseleave.native="setCurrentHoverIndex(-1)"
                            @click="addNewRequirementType(coveragesType)"
                        />
                    </Menu>
                </Expandable>
            </div>
            <div
                v-else-if="!addingRequirementType && availableRequirementTypes.length > 0"
            >
                <Button
                    tag="button"
                    class="RequirementTypeList__button RequirementTypeList__addCoverageButtonContent"
                    @click="startAddRequirementType"
                >
                    <span class="RequirementTypeList__plusIcon">+</span>
                    Select Requirement
                </Button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import difference from 'lodash/difference';
    import sortBy from 'lodash/sortBy';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { getRequirementTypeIcon } from '@/modules/entity-details/utils/getRequirementTypeIcon';
    import RequirementTypeCard from '@/modules/decisioning-criteria/components/RequirementTypeCard/RequirementTypeCard.vue';
    import CriteriaWarningMessage
        from '@/modules/decisioning-criteria/components/CriteriaWarningMessage/CriteriaWarningMessage.vue';
    import {
        GetRequirementTypeLabel, IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditRiskProfile/types';
    import CriteriaDangerMessage
        from '@/modules/decisioning-criteria/components/CriteriaDangerMessage/CriteriaDangerMessage.vue';
    import { Criterion } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    type HasDeprecatedCriteriaByRequirementType = (requirementType: TprmRequirementType) => boolean;
    type IsRequirementTypeInEvaluationRule = (requirementType: TprmRequirementType) => boolean;

    @Component({
        components: {
            Button,
            RequirementTypeCard,
            Dropdown,
            Expandable,
            FontAwesomeIcon,
            FormInput,
            FormElement,
            Menu,
            MenuLink,
            CriteriaWarningMessage,
            CriteriaDangerMessage,
        },
    })
    export default class RequirementTypeList extends Vue {
        @Prop({ type: Array, default: () => [] })
        private requirementTypes!: TprmRequirementType[];

        @Prop({ type: Object, default: () => ({}) })
        private criteriaByType!: Record<string, Criterion[]>;

        @Prop({ type: String, default: null })
        private selectedRequirementType!: string | null;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Function as PropType<HasDeprecatedCriteriaByRequirementType>, required: true })
        private hasDeprecatedCriteriaByRequirementType!: HasDeprecatedCriteriaByRequirementType;

        @Prop({ type: Function as PropType<GetRequirementTypeLabel>, required: true })
        private getRequirementTypeLabel!: GetRequirementTypeLabel;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private disableEvaluationRule!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private hasEvaluationRule!: boolean;

        @Prop({ type: Function as PropType<IsRequirementTypeInEvaluationRule>, required: true })
        private isRequirementTypeInEvaluationRule!: IsRequirementTypeInEvaluationRule;

        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private invalidReferences!: string[];

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        private addingRequirementType: boolean = false;
        private faCaretDown = faChevronDown;
        private currentlyHoveredIndex: number = -1;
        private getRequirementTypeIcon = getRequirementTypeIcon;

        private get isInvalidCriteriaMessage(): boolean {
            return this.invalidReferences.length > 0;
        }

        private get requirementTypeList(): TprmRequirementType[] {
            return Object.keys(this.criteriaByType) as TprmRequirementType[];
        }

        private get sortedRequirementTypeList(): TprmRequirementType[] {
            return sortBy([ ...this.requirementTypeList ], this.getRequirementTypeLabel);
        }

        private get sortedRequirementTypes(): TprmRequirementType[] {
            return sortBy([ ...this.requirementTypes ], this.getRequirementTypeLabel);
        }

        private get availableRequirementTypes(): TprmRequirementType[] {
            return difference(this.sortedRequirementTypes, this.sortedRequirementTypeList);
        }

        private startAddRequirementType(): void {
            this.addingRequirementType = true;
        }

        private get noRequirements(): boolean {
            return !this.addingRequirementType && this.availableRequirementTypes.length > 0;
        }

        private addNewRequirementType(requirementType: TprmRequirementType): void {
            this.addingRequirementType = false;
            this.currentlyHoveredIndex = -1;
            this.$emit('add', requirementType);
        }

        private setCurrentHoverIndex(index: number): void {
            this.currentlyHoveredIndex = index;
        }

        private deleteRequirementType(requirementType: TprmRequirementType): void {
            this.$emit('delete', requirementType);
        }

        private selectRequirementType(requirementType: TprmRequirementType): void {
            this.$emit('select', requirementType);
        }

        private getCriteriaCount(type: TprmRequirementType): number {
            return this.criteriaByType[type]?.length || 0;
        }

        private toggleEvaluationRuleModal() {
            this.$emit('toggle-evaluation-rule-modal');
        }
    }
</script>
