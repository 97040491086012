<template>
    <LoadingModal
        v-if="status === 'loading' || status === 'uninitialized'"
        additional-class-name="EntitiesDocumentsModal EntitiesDocumentsModal--loading"
        header="Documents"
        @close="close"
    />
    <EntitiesDocumentsErrorModal v-else-if="status === 'error'" @close="close" />
    <EntitiesDocumentsEmptyModal
        v-else-if="filteredDocuments.length === 0"
        :email="email"
        @close="close"
    />
    <EntitiesDocumentsListModal
        v-else
        :email="email"
        :documents="filteredDocuments"
        :requirement-models="requirementModels"
        @close="close"
        @open="open"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import { DocumentDetails } from '@/modules/entity-documents/types';
    import EntitiesDocumentsErrorModal from '@/modules/entity-documents/components/EntitiesDocumentsErrorModal.vue';
    import EntitiesDocumentsEmptyModal from '@/modules/entity-documents/components/EntitiesDocumentsEmptyModal.vue';
    import EntitiesDocumentsListModal from '@/modules/entity-documents/components/EntitiesDocumentsListModal.vue';
    import DocumentDetailsModal from '@/modules/entity-documents/components/DocumentDetailsModal.vue';
    import { hasDocument } from '@/modules/entity-documents/services/rprDocumentUtilities';

    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            LoadingModal,
            DocumentDetailsModal,
            EntitiesDocumentsListModal,
            EntitiesDocumentsEmptyModal,
            EntitiesDocumentsErrorModal,
        },
    })
    export default class EntitiesDocumentsModal extends Vue {
        @Prop({ type: String })
        private status!: OperationStatus;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String })
        private customEmptyMessage!: string;

        @Prop({ type: Array, default: () => [] })
        private documents!: DocumentDetails[];

        @Prop({ type: Array })
        private requirementModels!: TprmRequirementModel[];

        private get filteredDocuments(): DocumentDetails[] {
            return this.documents.filter(hasDocument);
        }

        private open(document: DocumentDetails): void {
            this.$emit('open', document);
        }

        private close(): void {
            this.$emit('close');
        }
    }
</script>
