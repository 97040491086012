type AnyFn = (...args: any) => any;
type WindowAlike = any;

function isStorageAvailable(storageAccessor: () => {setItem: AnyFn, removeItem: AnyFn}): boolean {
    try {
        const storage = storageAccessor();
        const storageTestKey = '___test';
        storage.setItem(storageTestKey, '');
        storage.removeItem(storageTestKey);
        return true;
    } catch (e) {
        return false;
    }
}

function areCookiesAvailable(windowApi: WindowAlike): boolean {
    try {
        // Copied from Modernizr
        if (windowApi.navigator.cookieEnabled) {
            return true;
        }

        windowApi.document.cookie = '___cookie=1';
        const cookieStored = windowApi.document.cookie.indexOf('___cookie=') !== -1;
        windowApi.document.cookie = '___cookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

        return cookieStored;
    } catch (e) {
        return false;
    }
}

function isSafariBrowser(windowApi: WindowAlike): boolean {
    return (
        /constructor/i.test(windowApi.HTMLElement) ||
        (windowApi.safari?.pushNotification || '').toString() === '[object SafariRemoteNotification]'
    );
}

interface BrowserFeaturesInterface {
    cookies: boolean;
    userMedia: boolean;
    isMobileBrowser: boolean;
    isAndroid: boolean;
    isBlackBerry: boolean;
    isIOS: boolean;
    isSafari: boolean;
    isIPad: boolean;
    isFirefox: boolean;
    isChrome: boolean;
    isChromeIOS: boolean;
    localStorage: boolean;
    sessionStorage: boolean;
    isIPhone: boolean;
    isIPod: boolean;
    isIE: boolean;
    isMobileWindows: boolean;
    isOperaMini: boolean;
    isWebOS: boolean;
    isInWebAppIOS: boolean;
    isInWebAppAndroid: boolean;
    isInWebAppBrowser: boolean;
    supportsWasm: boolean;
}

function isTouchBasedDevice() {
    try {
        document.createEvent('TouchEvent');
        return true;
    } catch (e) {
        return false;
    }
}

export default function features(windowApi: WindowAlike): Readonly<BrowserFeaturesInterface> {
    if (!windowApi) {
        throw new Error('No window api provided to features()');
    }

    const { navigator } = windowApi;

    const userMedia = Boolean(navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function');

    const isInWebAppIOS = (navigator.standalone === true);
    const isInWebAppAndroid = windowApi.matchMedia && windowApi.matchMedia('(display-mode: standalone)').matches;

    // Detect type of device.
    const isAndroid = navigator.userAgent.match(/Android/i);
    const isBlackBerry = navigator.userAgent.match(/BlackBerry/i);
    const isIPad = navigator.userAgent.match(/iPad/i);
    const isIPod = navigator.userAgent.match(/iPod/i);
    const isIPhone = navigator.userAgent.match(/iPhone/i);
    const isOperaMini = navigator.userAgent.match(/Opera Mini/i);
    const isMobileWindows = navigator.userAgent.match(/IEMobile/i);
    const isWebOS = navigator.userAgent.match(/webOS/i);
    const isSafari = isSafariBrowser(windowApi);
    const isIOS = Boolean(isIPad || isIPhone || isIPod);
    // @ts-ignore: IE-specific property, not included in standard
    const isIE = Boolean(document.documentMode);
    // One or more available input mechanism(s) cannot hover (or there are no pointing input mechanisms)
    // this check is required to detect when user use "Desktop Mode" on their mobile device
    // matchMedia is not well-supported on Chrome for Android (https://caniuse.com/matchmedia),
    // thus the fallback with checking if device can create TouchEvent
    const noHoverDevices = window.matchMedia('(any-hover: none)').matches || isTouchBasedDevice();
    const isMobileBrowser = Boolean(
        isAndroid || isBlackBerry || isIOS || isOperaMini || isWebOS || isMobileWindows || noHoverDevices
    );
    const isFirefox = /firefox\//i.test(navigator.userAgent);
    const isChromeIOS = /CriOS/.test(navigator.userAgent);
    // @see {@link https://stackoverflow.com/a/13348618/2922986}
    const isChrome = isChromeIOS || (
        // @ts-ignore: Chrome-specific property, not included in standard
        window.chrome !== null &&
        // @ts-ignore: Opera-specific property, not included in standard
        window.opr != null &&
        /Google Inc/.test(navigator.vendor) &&
        !/Edge/.test(navigator.userAgent)
    );

    const isInWebAppBrowser = isInWebAppIOS || isInWebAppAndroid;

    const supportsWasm = (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function');
    // Browser storage support
    const localStorageSupport = isStorageAvailable(() => windowApi.localStorage);
    const sessionStorageSupport = isStorageAvailable(() => windowApi.sessionStorage);

    return Object.freeze({
        cookies: areCookiesAvailable(windowApi),
        userMedia,
        isMobileBrowser,
        isAndroid,
        isBlackBerry,
        isIOS,
        isSafari,
        isIPad,
        localStorage: localStorageSupport,
        sessionStorage: sessionStorageSupport,
        isIPhone,
        isIPod,
        isIE,
        isChromeIOS,
        isChrome,
        isFirefox,
        isMobileWindows,
        isOperaMini,
        isWebOS,
        isInWebAppIOS,
        isInWebAppAndroid,
        isInWebAppBrowser,
        supportsWasm,
    });
}
