<template>
    <div class="OnlineChatButton">
        <div
            v-if="showGreetingBubble"
            data-test-id="OnlineChatButton__bubble"
            class="OnlineChatButton__bubble"
        >
            Hi, I'm Evie! Need Help?
        </div>
        <img
            data-test-id="OnlineChatButton__evieIcon"
            class="OnlineChatButton__evieIcon"
            :src="evieImg"
            alt="evie"
            @click="$emit('toggle', $event)"
            @dragstart="$emit('dragstart', $event)"
            @dragend="$emit('dragend', $event)"
        >
    </div>
</template>

<style lang="scss">
    @import "index";
</style>

<script lang="ts">
    import { PropType } from 'vue';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { faComment } from '@fortawesome/free-regular-svg-icons';
    import evieImg from '../../assets/online-chat-evie-image.svg';
    import { SESSION_KEY_CHAT_BOT_GREETED } from '../../utils/session-states/constants';
    import { Vue } from '@evidentid/vue-property-decorator';

    export default Vue.extend({
        name: 'OnlineChatButton',
        props: {
            chatOpened: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faTimes,
                faComment,
                evieImg,
                showGreetingBubble: !sessionStorage.getItem(SESSION_KEY_CHAT_BOT_GREETED),
            };
        },
        mounted() {
            setTimeout(this.hideBubble, 5000);
        },
        methods: {
            hideBubble() {
                document.querySelector('.OnlineChatButton__bubble')?.classList.add('hideWithAnimation');
                sessionStorage.setItem(SESSION_KEY_CHAT_BOT_GREETED, 'true');
                this.showGreetingBubble = false;
            },
        },
    });
</script>
