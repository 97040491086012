import { TprmRequirementType } from '../entity-details';
import { TprmSchema } from '../common';
import { ConditionalValueReference } from '../decisioning';

interface CriterionEnumValues {
    reference: string;
    values: Record<string, string>;
}

export interface CriterionField {
    value: any;
    schema: TprmSchema;
}

export interface CriterionEvaluator {
    references: Record<string, CriterionField>;
}

export enum CriterionCondition {
    equals = 'Equals',
    greaterThan = 'Greater than',
    greaterThanOrEqualTo = 'Greater than or equal to',
    lessThanOrEqualTo = 'less than or equal to',
    lessThan = 'less than',
}

export interface CriterionDisplayMeta {
    title: string;
    condition: CriterionCondition;
    requiredValueReference?: string;
    conditionalValueReference?: ConditionalValueReference | null;
    nonInputCriterionText?: string;
    informationText?: string;
    enumValues?: CriterionEnumValues[];
    verifyToggleDisabled?: boolean;
}

export interface CriterionBase {
    coverageType: TprmRequirementType;
    field: string;
    evaluator: CriterionEvaluator;
    verify: boolean;
    emailMessage: string;
    deprecated: boolean;
}

export interface CriterionInput extends CriterionBase {
    displayMetadata?: CriterionDisplayMeta;
}

export interface CriterionTemplate extends CriterionBase {
    default: boolean;
    displayMetadata: CriterionDisplayMeta;
}

export interface Criterion extends CriterionBase {
    id: string;
}

export interface CriterionMessage {
    nonComplianceMessage: string;
}
