<template>
    <div class="ActionableAlert">
        <Alert :type="type">
            <div class="ActionableAlert__titleSection">
                <FontAwesomeIcon v-if="icon" :icon="icon" />
                <span class="ActionableAlert__title">{{ title }}</span>
            </div>
            <Button v-if="actionTitle"
                class="ActionableAlert__actionButton"
                @click="$emit('action', name)"
            >
                {{ actionTitle }}
            </Button>
        </Alert>
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from '@evidentid/vue-property-decorator';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    @Component({
        components: { Alert, Button, FontAwesomeIcon },
    })
    export default class ActionableAlert extends Alert {
        @Prop({ type: String, default: null })
        private name!: string | null;

        @Prop({ type: Object, default: null })
        private icon!: IconDefinition | null;

        @Prop({ type: String, default: null })
        private actionTitle!: string | null;
    }
</script>
