import moment from 'moment';
import AttributeRelyingPartyRequest from './interfaces/AttributeRelyingPartyRequest';
import { AsfStatusType } from './interfaces/attributeStatuses';

export enum RelyingPartyRequestStatusType {
    new = 'new',
    processing = 'processing',
    complete = 'complete',
    timeout = 'timeout',
    canceled = 'canceled',
    maxAttemptsExceeded = 'attempts_exceeded',
}

export interface IdoRequestStatus {
    type: RelyingPartyRequestStatusType;
    requestedAt: string | null;
    submittedAt: string | null;
    completedAt: string | null;
    daysProcessing: number;
}

// TODO(PRODUCT-9194): Handle max_attempts_exceeded when it will be available
export function getRpRequestStatusType(rpRequest: AttributeRelyingPartyRequest): RelyingPartyRequestStatusType {
    if (rpRequest.status === AsfStatusType.complete) {
        return RelyingPartyRequestStatusType.complete;
    } else if (rpRequest.status === AsfStatusType.timeout) {
        // TODO(PRODUCT-9195): Consume 'canceled' status when it will be available
        return rpRequest.submitted_at ? RelyingPartyRequestStatusType.canceled : RelyingPartyRequestStatusType.timeout;
    } else if (rpRequest.status === AsfStatusType.terminated) {
        return RelyingPartyRequestStatusType.canceled;
    } else if (rpRequest.attribute_sharing_agreements[0].fulfilment.status === AsfStatusType.timeout) {
        return RelyingPartyRequestStatusType.timeout;
    } else if (rpRequest.status === AsfStatusType.new || rpRequest.status === AsfStatusType.pending) {
        return RelyingPartyRequestStatusType.new;
    }

    return RelyingPartyRequestStatusType.processing;
}

export default function getIdoRequestStatus(rpRequest: AttributeRelyingPartyRequest): IdoRequestStatus {
    const submittedAt = rpRequest.submitted_at?.timestamp
        ? moment(new Date(rpRequest.submitted_at?.timestamp * 1000)).format('MM/DD/YYYY')
        : null;
    const requestedAt = rpRequest.created_at?.timestamp
        ? moment(new Date(rpRequest.created_at?.timestamp * 1000)).format('MM/DD/YYYY')
        : null;
    const completedAt = rpRequest.completed_at?.timestamp
        ? moment(new Date(rpRequest.completed_at?.timestamp * 1000)).format('MM/DD/YYYY')
        : null;
    const lastProcessingTime = completedAt ? moment(completedAt, 'MM/DD/YYYY') : moment();
    const daysProcessing = submittedAt
        ? moment(lastProcessingTime).diff(submittedAt, 'days')
        : 0;

    return {
        type: getRpRequestStatusType(rpRequest),
        submittedAt,
        requestedAt,
        completedAt,
        daysProcessing,
    };
}
