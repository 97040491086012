<template>
    <div class="EmptyRiskProfiles">
        <img class="EmptyRiskProfiles__icon" :src="docWithExclamationMark" alt="docWithExclamationMark">
        <div class="EmptyRiskProfiles__titleText">
            The entity has not yet been assigned
            to a risk profile.
        </div>
        <p>
            You can update the entity’s details in the Overview tab to assign it to an existing group based on that
            group’s criteria.
        </p>
        <p>
            <span>Alternatively, you can </span>
            <span class="EmptyRiskProfiles__linkText" @click="$emit('goToRiskProfileList')">
                go to the Decisioning Criteria page
            </span>
            <span> and create a new risk profile with criteria that includes the entity.</span>
        </p>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import docWithExclamationMark from '@/modules/dashboard/assets/doc-with-exclamation-mark.svg';

    @Component({
        components: {
            FontAwesomeIcon,
        },
    })
    export default class EmptyRiskProfiles extends Vue {
        private docWithExclamationMark = docWithExclamationMark;
    }
</script>
