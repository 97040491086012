import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import {
    InsuranceApiKey,
    InsuranceWebhook,
    InsuranceWebhookInput,
} from '@evidentid/tprm-portal-lib/models/api-settings';
import {
    mutateCommonEntityFailState,
    mutateCommonEntityFinishState,
    mutateCommonEntityStartState,
} from '@/utils/vuex-common/commonEntityMutations';
import { performCommonGetAction } from '@/utils/vuex-common/performCommonGetAction';
import {
    mutateCommonListFailState,
    mutateCommonListFinishState,
    mutateCommonListStartState,
} from '@/utils/vuex-common/commonListMutations';
import {
    mutateCommonStatusFailState,
    mutateCommonStatusFinishState,
    mutateCommonStatusStartState,
} from '@/utils/vuex-common/commonStatusMutations';
import { performCommonCudAction } from '@/utils/vuex-common/performCommonCudAction';
import ConfigApiClient from '@evidentid/config-api-client';
import { InsuranceWebhookType } from '@evidentid/tprm-portal-lib/models/api-settings/InsuranceWebhookType.model';
import { VuexCommonEntityStatus } from '@/models/VuexCommonEntityStatus.model';
import { VuexCommonListStatus } from '@/models/VuexCommonListStatus.model';

export interface ApiSettingsRequirement {
    rpweb: RpWebApiClient;
    configClient: ConfigApiClient;
}

interface ApiSettingsState {
    apiKey: Record<string, VuexCommonEntityStatus<InsuranceApiKey>>;
    webhooks: Record<string, VuexCommonListStatus<InsuranceWebhook>>;
    webhookTypes: Record<string, VuexCommonListStatus<InsuranceWebhookType>>;
    createWebhookStatus: Record<string, OperationStatus>;
    patchWebhookStatus: Record<string, OperationStatus>;
    deleteWebhookStatus: Record<string, OperationStatus>;
}

const createState = createStateFactory<ApiSettingsRequirement>();

const { instantiateState, createMutationsFactories } = createState<ApiSettingsState>(() => ({
    apiKey: {},
    webhooks: {},
    webhookTypes: {},
    createWebhookStatus: {},
    patchWebhookStatus: {},
    deleteWebhookStatus: {},
}));
const { instantiateMutations, createActionFactories } = createMutationsFactories((a) => ({
    startLoadingApiKey(payload: { rpName: string }) {
        mutateCommonEntityStartState<InsuranceApiKey>(this, 'apiKey', payload);
    },
    finishLoadingApiKey(payload: { rpName: string, data: InsuranceApiKey }) {
        mutateCommonEntityFinishState<InsuranceApiKey>(this, 'apiKey', payload);
    },
    failLoadingApiKey(payload: { rpName: string }) {
        mutateCommonEntityFailState<InsuranceApiKey>(this, 'apiKey', payload);
    },
    startLoadingWebhooks(payload: { rpName: string }) {
        mutateCommonListStartState<InsuranceWebhook>(this, 'webhooks', payload);
    },
    finishLoadingWebhooks(payload: { rpName: string, list: InsuranceWebhook[] }) {
        mutateCommonListFinishState<InsuranceWebhook>(this, 'webhooks', payload);
    },
    failLoadingWebhooks(payload: { rpName: string }) {
        mutateCommonListFailState<InsuranceWebhook>(this, 'webhooks', payload);
    },
    startLoadingWebhookTypes(payload: { rpName: string }) {
        mutateCommonListStartState<InsuranceWebhookType>(this, 'webhookTypes', payload);
    },
    finishLoadingWebhookTypes(payload: { rpName: string, list: InsuranceWebhookType[] }) {
        mutateCommonListFinishState<InsuranceWebhookType>(this, 'webhookTypes', payload);
    },
    failLoadingWebhookTypes(payload: { rpName: string }) {
        mutateCommonListFailState<InsuranceWebhookType>(this, 'webhookTypes', payload);
    },
    startCreatingWebhook(payload: { rpName: string }) {
        mutateCommonStatusStartState(this, 'createWebhookStatus', payload);
    },
    finishCreatingWebhook(payload: { rpName: string, data: InsuranceWebhook }) {
        // adding the successful created webhook to the store without calling get all webhooks
        this.webhooks = {
            ...this.webhooks,
            [payload.rpName]: {
                ...this.webhooks[payload.rpName],
                status: OperationStatus.success,
                list: [ ...this.webhooks[payload.rpName].list, payload.data ],
            },
        };
        mutateCommonStatusFinishState(this, 'createWebhookStatus', payload);
    },
    failCreatingWebhook(payload: { rpName: string }) {
        mutateCommonStatusFailState(this, 'createWebhookStatus', payload);
    },
    startPatchingWebhook(payload: { rpName: string }) {
        mutateCommonStatusStartState(this, 'patchWebhookStatus', payload);
    },
    finishPatchingWebhook(payload: { rpName: string, data: InsuranceWebhook }) {
        // updating the successful patched webhook in the store without calling get all webhooks
        const currentList = this.webhooks[payload.rpName].list;
        const index = currentList.findIndex((x) => x.id === payload.data.id);
        this.webhooks = {
            ...this.webhooks,
            [payload.rpName]: {
                ...this.webhooks[payload.rpName],
                status: OperationStatus.success,
                list: [
                    ...currentList.slice(0, index),
                    payload.data,
                    ...currentList.slice(index + 1) ],
            },
        };
        mutateCommonStatusFinishState(this, 'patchWebhookStatus', payload);
    },
    failPatchingWebhook(payload: { rpName: string }) {
        mutateCommonStatusFailState(this, 'patchWebhookStatus', payload);
    },
    startDeletingWebhook(payload: { rpName: string }) {
        mutateCommonStatusStartState(this, 'deleteWebhookStatus', payload);
    },
    finishDeletingWebhook(payload: { rpName: string, deletedId: string }) {
        // remove the successful deleted webhook in the store without calling get all webhooks
        const currentList = this.webhooks[payload.rpName].list;
        const deletedIndex = currentList.findIndex((x) => x.id === payload.deletedId);
        this.webhooks = {
            ...this.webhooks,
            [payload.rpName]: {
                ...this.webhooks[payload.rpName],
                status: OperationStatus.success,
                list: [ ...currentList.slice(0, deletedIndex), ...currentList.slice(deletedIndex + 1) ],
            },
        };
        mutateCommonStatusFinishState(this, 'deleteWebhookStatus', payload);
    },
    failDeletingWebhook(payload: { rpName: string }) {
        mutateCommonStatusFailState(this, 'deleteWebhookStatus', payload);
    },
    clearApiKey(rpName: string) {
        this.apiKey = omit(this.apiKey, [ rpName ]);
    },
    clearWebhooks(rpName: string) {
        this.webhooks = omit(this.webhooks, [ rpName ]);
    },
    clearWebhookTypes(rpName: string) {
        this.webhookTypes = omit(this.webhookTypes, [ rpName ]);
    },
    clearCreatingWebhookStatus(rpName: string) {
        this.patchWebhookStatus = omit(this.patchWebhookStatus, [ rpName ]);
    },
    clearPatchingWebhookStatus(rpName: string) {
        this.patchWebhookStatus = omit(this.patchWebhookStatus, [ rpName ]);
    },
    clearDeletingWebhookStatus(rpName: string) {
        this.deleteWebhookStatus = omit(this.deleteWebhookStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb, configClient }: ApiSettingsRequirement) => ({
    async loadApiKey(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const mutateStart = () => this.mutations.startLoadingApiKey({ rpName });
        const mutateFinish = (data: InsuranceApiKey) => this.mutations.finishLoadingApiKey({ rpName, data });
        const mutateFail = () => this.mutations.failLoadingApiKey({ rpName });
        const getDataCall = () => rpweb.getTprmApiKey(rpName);
        await performCommonGetAction<InsuranceApiKey>(
            this, 'apiKey', rpName, getDataCall, mutateStart, mutateFinish, mutateFail,
        );
    },
    async loadWebhooks(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const mutateStart = () => this.mutations.startLoadingWebhooks({ rpName });
        const mutateFinish = (list: InsuranceWebhook[]) => this.mutations.finishLoadingWebhooks({ rpName, list });
        const mutateFail = () => this.mutations.failLoadingWebhooks({ rpName });
        const getDataCall = () => configClient.getInsuranceWebhooks(rpName);
        await performCommonGetAction<InsuranceWebhook>(
            this, 'webhooks', rpName, getDataCall, mutateStart, mutateFinish, mutateFail, true,
        );
    },
    async loadWebhookTypes(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const mutateStart = () => this.mutations.startLoadingWebhookTypes({ rpName });
        const mutateFinish = (list: InsuranceWebhookType[]) => this.mutations.finishLoadingWebhookTypes({
            rpName,
            list,
        });
        const mutateFail = () => this.mutations.failLoadingWebhookTypes({ rpName });
        const getDataCall = () => configClient.getWebhookTypes();
        await performCommonGetAction<InsuranceWebhookType>(
            this, 'webhookTypes', rpName, getDataCall, mutateStart, mutateFinish, mutateFail, true,
        );
    },
    async createWebhook(payload: {
        rpName: string;
        data: InsuranceWebhookInput;
    }) {
        const { rpName, data } = payload;
        const mutateStart = () => this.mutations.startCreatingWebhook({ rpName });
        const mutateFinish = (data: InsuranceWebhook) => this.mutations.finishCreatingWebhook({ rpName, data });
        const mutateFail = () => this.mutations.failCreatingWebhook({ rpName });
        const actionCall = () => configClient.createInsuranceWebhook(rpName, data);
        await performCommonCudAction(
            this, 'createWebhookStatus', rpName, actionCall, mutateStart, mutateFinish, mutateFail,
        );
    },
    async patchWebhook(payload: {
        rpName: string;
        data: InsuranceWebhook;
    }) {
        const { rpName, data } = payload;
        const mutateStart = () => this.mutations.startPatchingWebhook({ rpName });
        const mutateFinish = (data: InsuranceWebhook) => this.mutations.finishPatchingWebhook({ rpName, data });
        const mutateFail = () => this.mutations.failPatchingWebhook({ rpName });
        const actionCall = () => configClient.patchInsuranceWebhook(rpName, data);
        await performCommonCudAction(
            this, 'patchWebhookStatus', rpName, actionCall, mutateStart, mutateFinish, mutateFail,
        );
    },
    async deleteWebhook(payload: {
        rpName: string;
        data: InsuranceWebhook;
    }) {
        const { rpName, data } = payload;
        const mutateStart = () => this.mutations.startDeletingWebhook({ rpName });
        const mutateFinish = () => this.mutations.finishDeletingWebhook({ rpName, deletedId: data.id });
        const mutateFail = () => this.mutations.failDeletingWebhook({ rpName });
        const actionCall = () => configClient.deleteInsuranceWebhook(rpName, data.id);
        await performCommonCudAction(
            this, 'deleteWebhookStatus', rpName, actionCall, mutateStart, mutateFinish, mutateFail,
        );
    },
    clearApiKey(payload: { rpName: string }) {
        this.mutations.clearApiKey(payload.rpName);
    },
    clearWebhooks(payload: { rpName: string }) {
        this.mutations.clearWebhooks(payload.rpName);
    },
    clearWebhookTypes(payload: { rpName: string }) {
        this.mutations.clearWebhookTypes(payload.rpName);
    },
    clearCreatingWebhookStatus(payload: { rpName: string }) {
        this.mutations.clearCreatingWebhookStatus(payload.rpName);
    },
    clearPatchingWebhookStatus(payload: { rpName: string }) {
        this.mutations.clearPatchingWebhookStatus(payload.rpName);
    },
    clearDeletingWebhookStatus(payload: { rpName: string }) {
        this.mutations.clearDeletingWebhookStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
