export interface ResolvablePromise<T> {
    resolve: Function;
    promise: Promise<T>;
}

export default function createResolvablePromise<T>(): ResolvablePromise<T> {
    let resolve!: Function;
    const promise = new Promise<T>((r) => {
        resolve = r;
    });
    return { promise, resolve };
}
