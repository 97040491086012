<template>
    <div class="InfiniteScroll" ref="dom">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import throttle from 'lodash/throttle';

    @Component
    export default class InfiniteScroll extends Vue {
        private unsubscribe: (() => void)[] = [];
        private recalculateListener = () => this.recalculate();
        private interval: any;
        private scheduleRecalculation = throttle(() => this.recalculate(), 10);

        @Prop({ type: Number, default: 95 })
        private emitAt!: number;

        @Ref()
        private readonly dom!: HTMLDivElement;

        private updated() {
            this.recalculate();
        }

        private addListeners() {
            this.addListener(window, 'scroll');
            this.addListener(window, 'resize');
        }

        private addListener<T extends Element | Window>(element: T, eventName: Parameters<T['addEventListener']>[0]) {
            element.addEventListener(eventName, this.recalculateListener);
            this.unsubscribe.push(() => element.removeEventListener(eventName, this.recalculateListener));
        }

        private getScrolledPercentage() {
            const rect = this.dom.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

            const endsOn = rect.top + rect.height;
            const pixelsLeftToScroll = Math.max(0, endsOn - viewportHeight);
            const scrolledPart = (rect.height - pixelsLeftToScroll) / rect.height;
            return 100 * scrolledPart;
        }

        private recalculate() {
            if (this.getScrolledPercentage() >= this.emitAt) {
                this.$emit('scroll');
            }
        }

        private mounted() {
            // Regular listeners
            this.addListeners();

            // Fallback in case something goes wrong with detection
            this.interval = setInterval(() => this.scheduleRecalculation(), 500);
        }

        private destroyed() {
            clearInterval(this.interval);
            this.scheduleRecalculation.cancel();
            this.unsubscribe.forEach((fn) => fn());
            this.unsubscribe = [];
        }
    }
</script>
