<template>
    <div class="Accord25Cell">
        <div class="Accord25Cell__header">
            {{ title }}
        </div>
        <div class="Accord25Cell__value">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';

    @Component
    export default class Accord25Cell extends Vue {
        @Prop({ type: String })
        private title!: string;
    }
</script>
