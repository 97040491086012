import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

interface CommonVuexEntityDataStatus<T> {
    status: OperationStatus;
    data: T;
}

export function createEmptyEntityVuexStatus<T>(): CommonVuexEntityDataStatus<T> {
    return {
        status: OperationStatus.uninitialized,
        data: {} as unknown as T,
    };
}
