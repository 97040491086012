<template>
    <UserMenu
        v-if="user"
        :name="userName"
        :email="userName === userEmail ? null : userEmail"
        :avatarUrl="userAvatar"
    >
        <Menu spaced>
            <slot />
            <MenuLink label="Log out" @click="logOut" />
        </Menu>
    </UserMenu>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { UserMenu } from '../../../components/UserMenu';
    import { Menu, MenuLink } from '../../../components/Menu';
    import { UserState } from '../types';

    @Component({
        components: { Menu, MenuLink, UserMenu },
    })
    export default class UserBox extends Vue {
        private get user(): UserState['data'] {
            return (this as any).$store.state.user.data;
        }

        private get userName() {
            return this.user?.displayName || this.userEmail;
        }

        private get userEmail() {
            return this.user?.email;
        }

        private get userAvatar() {
            return this.user?.photoURL;
        }

        private logOut() {
            this.$store.actions.user.logOut();
        }
    }
</script>
