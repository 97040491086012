// This function is for the backward compatibility

import { Criterion, CriterionTemplate } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

// TODO(PRODUCT-19432): consider move this function into syncCriteriaWithTemplates
export function syncAdditionalInsuredCriteriaWithTemplate(
    criteria: Criterion[],
    templateAdditionalInsured: CriterionTemplate,
): Criterion[] {
    return criteria.map((criterion) => {
        if (criterion.field === 'coverage.endorsements.additionalInsured' &&
            criterion.evaluator.references.additionalInsured) {
            return {
                ...templateAdditionalInsured,
                id: criterion.id,
                coverageGroupId: (criterion as any).coverageGroupId,
                coverageType: (criterion as any).coverageType,
                evaluator: {
                    references: {
                        ...templateAdditionalInsured.evaluator.references,
                        additionalInsuredConfiguration: {
                            ...templateAdditionalInsured.evaluator.references.additionalInsuredConfiguration,
                            value: {
                                ...templateAdditionalInsured.evaluator.references
                                    .additionalInsuredConfiguration.value,
                                additionalInsured: criterion.evaluator.references
                                    .additionalInsured.value,
                            },
                        },
                    },
                },
                verify: criterion.verify,
            };
        }

        return criterion;
    });
}
