import {
    ComplianceStatus,
    TprmRequirementType,
    ExceptionInput,
    ExceptionLevel,
    ExceptionType,
} from '@evidentid/tprm-portal-lib/models/entity-details';

export function createEntityLevelException(
    type?: ExceptionType,
    status?: ComplianceStatus,
): ExceptionInput {
    return {
        level: ExceptionLevel.insured,
        type: type || ExceptionType.permanent,
        status: status || ComplianceStatus.compliant,
    };
}

export function createRequirementLevelException(
    requirementType: TprmRequirementType,
    type?: ExceptionType,
    status?: ComplianceStatus,
): ExceptionInput {
    return {
        coverageType: requirementType,
        level: ExceptionLevel.coverageType,
        type: type || ExceptionType.permanent,
        status: status || ComplianceStatus.compliant,
    };
}

export function createCriterionLevelException(
    requirementType: TprmRequirementType,
    criterionId: string,
    type?: ExceptionType,
    status?: ComplianceStatus,
): ExceptionInput {
    return {
        coverageType: requirementType,
        criterionId,
        level: ExceptionLevel.coverageCriterion,
        type: type || ExceptionType.permanent,
        status: status || ComplianceStatus.compliant,
    };
}

export function createEvaluateEntityLevelException(
    evaluatedEntityId: string,
    type?: ExceptionType,
    status?: ComplianceStatus,
): ExceptionInput {
    return {
        evaluatedEntityId,
        level: ExceptionLevel.evaluatedEntity,
        type: type || ExceptionType.permanent,
        status: status || ComplianceStatus.compliant,
    };
}
