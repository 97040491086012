import noop from 'lodash/noop';
import { IamClientAdapter, IamClientUser, IamPermission, LoginMethods, RegisterMethods } from './types';
import { UnsupportedMethod, NotInitializedError } from './errors';

type IamClientSubscription = (user: IamClientUser | null) => void;

export class IamClient {
    private adapter: IamClientAdapter;
    private initializePromise: Promise<void> | null = null;
    private initialized: boolean = false;
    private subscriptions: IamClientSubscription[] = [];
    private closeSubscriptions: (() => void)[] = [];

    private async ensureInitialized(): Promise<void> {
        if (this.initializePromise) {
            await this.initializePromise.catch(noop);
        }
        if (!this.initialized) {
            throw new NotInitializedError();
        }
    }

    /**
     * Ensure that the initialization happens only once for single adapter,
     * and that if the adapter has been replaced during initialization,
     * it will be chained back for previous initialization callback too.
     *
     * @private
     */
    private async setUpInitializationPromise(): Promise<void> {
        const promise: Promise<void> = this.initializePromise = Promise.resolve(this.adapter.initialize()).then(
            () => {
                if (this.initializePromise !== promise) {
                    return this.initializePromise!;
                }

                // Apply subscriptions
                this.initialized = true;
                this.closeSubscriptions = this.subscriptions.map((listener) => this.adapter.subscribe(listener));
            },
            (error) => {
                if (this.initializePromise !== promise) {
                    return this.initializePromise!;
                }
                // TODO(PRODUCT-13926): Think if it should initialize then too
                this.initialized = true;
                throw error;
            },
        );
        return this.initializePromise;
    }

    public constructor(adapter: IamClientAdapter) {
        this.adapter = adapter;
    }

    public async replaceAdapter(adapter: IamClientAdapter): Promise<void> {
        // Ignore if it's the very same adapter
        if (adapter === this.adapter) {
            return;
        }

        // Destroy previous adapter, so it will work fine
        await this.adapter.destroy();

        // Replace the adapter
        this.initialized = false;
        this.adapter = adapter;

        // Initialize new adapter if the previous one was initialized
        if (this.initializePromise) {
            return this.setUpInitializationPromise();
        }
    }

    public async hasPermissions(...permissions: IamPermission[]): Promise<boolean | null> {
        await this.ensureInitialized();
        return this.adapter.hasPermissions(...permissions);
    }

    public async hasAnyPermissions(...permissions: IamPermission[]): Promise<boolean | null> {
        await this.ensureInitialized();
        if (permissions.length === 0) {
            return this.adapter.hasPermissions();
        }
        const results = await Promise.all(permissions.map((x) => this.hasPermissions(x)));
        return results.some(Boolean)
            ? true
            : results.some((x) => x === null) ? null : false;
    }

    public async obtainPermissions(...permissions: IamPermission[]): Promise<boolean> {
        await this.ensureInitialized();
        const results = await Promise.all(permissions.map((x) => this.hasPermissions(x)));
        if (results.some((x) => x === false)) {
            return false;
        } else if (results.every(Boolean)) {
            return results.length > 0 || Boolean(await this.hasPermissions());
        }
        const missingPermissions = permissions.filter((_, i) => results[i] === null);
        return this.adapter.requestPermissions(...missingPermissions);
    }

    public getInitializationResult(): { done: boolean, result: any } {
        return this.initialized
            ? { done: true, result: this.adapter.getInitializationResult?.() ?? null }
            : { done: false, result: null };
    }

    public async initialize(): Promise<void> {
        return this.initializePromise || this.setUpInitializationPromise();
    }

    public async destroy(): Promise<void> {
        this.subscriptions = [];
        this.closeSubscriptions = [];
        this.initializePromise = null;
        await this.adapter.destroy();
    }

    public subscribe(listener: IamClientSubscription): () => void {
        const wrappedListener: typeof listener = (...args) => listener(...args);
        this.subscriptions.push(wrappedListener);
        if (this.initialized) {
            this.closeSubscriptions.push(this.adapter.subscribe(wrappedListener));
        }
        return () => {
            const index = this.subscriptions.indexOf(wrappedListener);
            if (index === -1) {
                return;
            } else if (this.closeSubscriptions.length > 0) {
                this.closeSubscriptions[index]();
                this.closeSubscriptions.splice(index, 1);
            }
            this.subscriptions.splice(index, 1);
        };
    }

    public async getUser(): Promise<IamClientUser | null> {
        await this.ensureInitialized();
        return this.adapter.getUser();
    }

    public async getAccessToken(forceRefresh?: boolean): Promise<string | null> {
        await this.ensureInitialized();
        if (forceRefresh) {
            await this.adapter.refreshToken();
        }
        return this.adapter.getAccessToken();
    }

    public async getIdToken(forceRefresh?: boolean): Promise<string | null> {
        await this.ensureInitialized();
        if (forceRefresh) {
            await this.adapter.refreshToken();
        }
        return this.adapter.getIdToken();
    }

    public async getTokens(forceRefresh?: boolean): Promise<{ accessToken: string | null, idToken: string | null }> {
        await this.ensureInitialized();
        if (forceRefresh) {
            await this.adapter.refreshToken();
        }
        const [ accessToken, idToken ] = await Promise.all([
            this.adapter.getAccessToken(),
            this.adapter.getIdToken(),
        ]);
        return { accessToken, idToken };
    }

    public async logOut(nextUrl?: string): Promise<void> {
        await this.ensureInitialized();
        await this.adapter.logOut(nextUrl);
    }

    public async login<K extends keyof LoginMethods>(method: K, ...args: Parameters<LoginMethods[K]>): Promise<void> {
        if (!this.adapter.loginMethods[method]) {
            throw new UnsupportedMethod();
        }
        await this.ensureInitialized();
        await this.adapter.loginMethods[method](...args as any);
    }

    public async register<K extends keyof RegisterMethods>(
        method: K,
        ...args: Parameters<RegisterMethods[K]>
    ): Promise<void> {
        if (!this.adapter.registerMethods[method]) {
            throw new UnsupportedMethod();
        }
        await this.ensureInitialized();
        await this.adapter.registerMethods[method](...args as any);
    }

    public getAvailableLoginMethods(): string[] {
        return Object.keys(this.adapter.loginMethods);
    }

    public getAvailableRegisterMethods(): string[] {
        return Object.keys(this.adapter.registerMethods);
    }

    public get canSendVerificationEmail(): boolean {
        return Boolean(this.adapter.sendEmailVerification);
    }

    public get canResetPassword(): boolean {
        return Boolean(this.adapter.resetPassword);
    }

    // TODO: Think if it should not throw UnsupportedMethod when it's not available in adapter
    public async resetPassword(username: string, continueUrl: string): Promise<void> {
        await this.ensureInitialized();
        if (this.canResetPassword) {
            await this.adapter.resetPassword!(username, continueUrl);
        }
    }

    // TODO: Think if it should not throw UnsupportedMethod when it's not available in adapter
    public async sendEmailVerification(continueUrl: string): Promise<void> {
        await this.ensureInitialized();
        if (this.canSendVerificationEmail) {
            await this.adapter.sendEmailVerification!(continueUrl);
        }
    }
}
