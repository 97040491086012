<template>
    <Modal additional-class-name="AMBestRatingDisclaimerProcedure" open close-on-backdrop @close="close">
        <template #header>
            AM Best Rating Disclaimer
        </template>
        <p>
            "Best's Credit Ratings™ are under continuous review and subject to change and/or affirmation. For the latest
            Best's Credit Ratings™ and Best's Credit Reports (which include Best's Credit Ratings™), visit the A.M. Best
            website at <a class="AMBestRatingDisclaimerProcedure__link" target="_blank" href="http://www.ambest.com">http://www.ambest.com</a>. See Guide to Best's
            Credit Ratings™ for explanation of use and charges."
        </p>
        <p>
            "Best's Credit Ratings™ reproduced herein appear under license from A.M. Best and do not constitute, either
            expressly or impliedly, an endorsement of Evident or its recommendations, formulas, criteria or comparisons
            to any other ratings, rating scales or rating organizations which are published or referenced herein. A.M.
            Best is not responsible for transcription errors made in presenting Best's Credit Ratings™.
        </p>
        <p>
            Best's Credit Ratings™ are proprietary and may not be reproduced or distributed without the express written
            permission of A.M. Best Company."
            A Best's Financial Strength Rating opinion addresses the relative ability of an insurer to meet its ongoing
            insurance obligations. It is not a warranty of a company's financial strength and ability to meet its
            obligations to policyholders. View our Important Notice: Best's Credit Ratings for a disclaimer notice and
            complete details at <a class="AMBestRatingDisclaimerProcedure__link" target="_blank" href="http://www.ambest.com/ratings/index.html">http://www.ambest.com/ratings/index.html</a>.
        </p>
    </Modal>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';

    export default Vue.extend({
        name: 'AMBestRatingDisclaimerProcedure',
        components: { Modal },
        methods: {
            close(): void {
                this.$emit('abort');
            },
        },
    });
</script>

<style lang="scss">
    .AMBestRatingDisclaimerProcedure {
        &__link {
            color: #2d3e50;
        }

        .Modal__body {
            max-width: 700px;
        }
    }
</style>
