<template>
    <div class="Snackbar"
        :class="{
            'Snackbar--open': open,
            'Snackbar--success': open && success,
            'Snackbar--failure': open && !success,
        }"
    >
        <div class="Snackbar__message">
            {{ message }}
        </div>
        <button v-if="permanent" class="Snackbar__close" @click="close">
            <FontAwesomeIcon :icon="faTimes" />
        </button>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Snackbar extends Vue {
        private faTimes = faTimes;

        @Prop({ type: Number, default: 4000 })
        private timeout!: number;

        @Prop({ type: Boolean, default: false })
        private success!: boolean;

        @Prop({ type: Boolean, default: false })
        private permanent!: boolean;

        @Prop({ type: String, default: null })
        private message!: string | null;

        private resetTimeoutPointer: any = null;
        private prevMessage: string | null = null;

        private get open() {
            return this.message !== null;
        }

        private mounted() {
            this.reschedule();
        }

        private beforeUpdate() {
            this.reschedule();
        }

        private beforeDestroy() {
            this.clearTimeout();
        }

        private close() {
            this.prevMessage = null;
            this.$emit('close');
        }

        private reschedule() {
            // When the message is changed, start calculating time again,
            // to keep new message for proper time.
            if (this.resetTimeoutPointer && (this.permanent || this.message !== this.prevMessage)) {
                this.clearTimeout();
            }

            if (this.message !== this.prevMessage) {
                this.prevMessage = this.message;

                if (this.message && !this.permanent) {
                    this.resetTimeoutPointer = setTimeout(this.close.bind(this), this.timeout);
                }
            }
        }

        private clearTimeout() {
            if (this.resetTimeoutPointer) {
                clearTimeout(this.resetTimeoutPointer);
                this.resetTimeoutPointer = null;
            }
        }
    }
</script>
