import isEqual from 'lodash/isEqual';
import TrackerAdapter from './TrackerAdapter';
import TrackerUserData from './TrackerUserData';
import TrackerEvent, { TrackerEventInput } from './TrackerEvent';

export default class Tracker<T extends TrackerUserData = TrackerUserData> {
    private readonly adapter: TrackerAdapter<T>;
    private userData: T | null = null;
    private eventsQueue: { event: TrackerEvent, time: number }[] = [];
    private lastEvent: TrackerEvent | null = null;

    public constructor(adapter: TrackerAdapter<T>) {
        this.adapter = adapter;
    }

    public async initialize(): Promise<void> {
        if (!this.adapter.isReady()) {
            await this.adapter.initialize();
            this.flushQueue();
        }
    }

    public track(eventInput: TrackerEventInput): void {
        const time = Date.now();
        const { ignoreConsecutiveDuplicate = false, ...event } = eventInput;

        // Ignore duplicated consecutive event, when it's expected
        if (ignoreConsecutiveDuplicate && isEqual(this.lastEvent, event)) {
            return;
        }

        this.lastEvent = event;
        if (this.adapter.isReady()) {
            this.adapter.track(event, time);
        } else {
            this.eventsQueue.push({ event, time });
        }
    }

    public setUserData(userData: T | null): void {
        this.userData = userData;
        if (this.adapter.isReady()) {
            this.adapter.setUserData(userData);
        }
    }

    public appendUserData(userData: Partial<T>): void {
        this.setUserData({
            ...(this.userData as T),
            ...userData,
        });
    }

    private flushQueue(): void {
        if (this.userData) {
            this.adapter.setUserData(this.userData);
        }

        for (const { event, time } of this.eventsQueue) {
            this.adapter.track(event, time);
        }

        this.eventsQueue = [];
    }
}
