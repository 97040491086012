import type { Component } from 'vue';
import type { RouteConfig } from 'vue-router/types';
import noop from 'lodash/noop';
import withPropsInjected from '@evidentid/vue-commons/decorators/withPropsInjected';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import delay from '@evidentid/universal-framework/delay';
import { createModuleBuilder } from '../../app';
import ErrorWrapper from './components/ErrorWrapper.vue';
import vuex from './vuex';
import { ErrorDefinition } from './types';
import { commonErrors } from './commonErrors';

export function createPersistingErrorModule() {
    return createModuleBuilder()
        .demandOptions<{
            errorDefinitions?: Record<string, ErrorDefinition>;
            views: { Error: Component };
            temporaryErrors?: string[];
        }>()
        .demandContext<{
            tracker?: Tracker<any>;
            logger?: Logger<any>;
        }>()
        .registerVuex({ error: vuex.instantiateModule })
        .registerRoutes((app) => [
            {
                path: '/error/application',
                name: 'error.application',
                component: withPropsInjected(ErrorWrapper, {
                    Component: app.options.views.Error,
                }),
                meta: { title: 'Error' },
            },
            {
                path: '/error/:reason',
                name: 'error',
                component: withPropsInjected(ErrorWrapper, {
                    Component: app.options.views.Error,
                }),
                meta: { title: 'Error' },
            },
            {
                path: '/:rpId/error/:reason',
                name: 'error.rp',
                component: withPropsInjected(ErrorWrapper, {
                    Component: app.options.views.Error,
                }),
                meta: { title: 'Error' },
            },
            {
                path: '/error',
                name: 'error.generic',
                component: Error,
                meta: { title: 'Error' },
            },
        ] as RouteConfig[])
        .injectVue((app) => ({
            $errorDefinitions: {
                ...commonErrors,
                ...app.options.errorDefinitions,
            },
        }))
        .execute(async (app) => {
            // Clean up temporary errors on initialization
            const temporaryErrors = app.options.temporaryErrors || [];

            // Ensure the current route is already resolved
            while (!app.router.currentRoute.name) {
                await delay(0);
            }

            // Retrieve route details
            const route = app.router.currentRoute;
            const next = typeof route.query.next === 'string' ? route.query.next : '/';

            // Clean up temporary errors
            if (
                (route.name === 'error' || route.name === 'error.rp') &&
                (next || temporaryErrors.includes(route.params.reason))
            ) {
                app.router.replace(next).catch(noop);
            }
        })
        .execute((app) => {
            function tick() {
                const error = app.store.state.error.error;
                const route = app.router.currentRoute;

                if (error && route.name !== 'error.application' && route.name !== 'error') {
                    app.router.replace({
                        name: 'error.application',
                        query: { next: route.fullPath, force: '' },
                    }).catch(noop);
                } else if (!error && route.name === 'error.application') {
                    const next = route.query.next;
                    app.router.replace(next && typeof next === 'string' ? next : '/').catch(noop);
                }
            }
            app.store.watch((state) => state.error.error, tick);
            app.router.afterEach((to) => {
                // Clean up full-screen errors after routing change.
                // It should be dispatched anyway, but it doesn't look clean in Vuex log.
                if (app.store.state.error.error && to.name !== 'error.application') {
                    app.store.actions.error.showError(null);
                }
                tick();
            });
            tick();
        })

        // Track events
        .execute((app) => {
            app.observer.onChange((state) => state.error.error, (error) => {
                const reason = error && 'reason' in error ? error.reason : null;
                const xhr = error && (('xhr' in error && error.xhr) || ('xhrObj' in error && error.xhrObj));

                // Lift error when it is a JavaScript error
                if (error && error instanceof Error) {
                    // Do not capture cancelled network requests as errors in Sentry, as we can't do anything about it
                    // It needs to be inside, otherwise TypeScript will detect `error` type incorrectly
                    if ((error as any).reason !== 'cancelled-network-request') {
                        setTimeout(() => {
                            throw error;
                        });
                    }
                }

                if (app.tracker) {
                    app.tracker.track({
                        name: error ? 'Error shown' : 'Error hidden',
                        description: error ? `Error shown: ${reason || 'unknown'}` : 'Error hidden',
                        message: error?.message,
                        reason,
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        level: error ? Severity.warning : Severity.info,
                        category: 'error',
                        message: error ? 'Error shown' : 'Error hidden',
                        data: {
                            reason: reason || '',
                            message: error?.message || '',
                            xhr: Boolean(xhr),
                            native: Boolean(error && error instanceof Error),
                        },
                    })
                }
            });
        })

        .end();
}

export const getPersistingErrorActions = vuex.getActions;
export type PersistingErrorModule = ReturnType<typeof createPersistingErrorModule>;
