<template>
    <div class="LoginLayout__section">
        <LoginLayoutDivider v-if="!noDivider" :content="title" />
        <slot />
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import LoginLayoutDivider from './LoginLayoutDivider.vue';

    @Component({
        components: {
            LoginLayoutDivider,
        },
    })
    export default class LoginLayoutSection extends Vue {
        @Prop({ type: String, default: null })
        private title!: string | null;

        @Prop({ type: Boolean, default: false })
        private noDivider!: boolean;
    }
</script>
