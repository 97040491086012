<template>
    <div
        class="JsonSchemaColorForm"
        :class="{'JsonSchemaColorForm--focus': focused}"
    >
        <FormElement
            :label-for="id"
            :label="form.schema.title"
            :required="requiredElement"
        >
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>HEX color (ex. #3ab4fc)</span>
                </div>
            </template>
            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <input
                    :id="id"
                    type="text"
                    :disabled="disabled"
                    :value="viewValue"
                    :placeholder="form.schema.placeholder"
                    :maxlength="form.schema.maxLength || 1024"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @change="onChange"
                >

                <div class="JsonSchemaColorForm__color">
                    <Popover position="top-end" :open="pickerOpened && !disabled" @hide="closePicker">
                        <template slot="base">
                            <button
                                type="button"
                                :disabled="disabled"
                                class="JsonSchemaColorForm__colorBox"
                                :style="'background-color: ' + viewValue"
                                @click="openPicker"
                            />
                        </template>

                        <VueColor
                            :value="viewValue"
                            :disabled="disabled"
                            disable-alpha
                            disable-fields
                            @input="onPick"
                        />
                    </Popover>
                </div>

                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Chrome as VueColor } from 'vue-color';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { Popover } from '../../Popover';
    import { FormElement, FormInput } from '../../Form';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        components: { Popover, FormInput, FormElement, FontAwesomeIcon, VueColor },
        inheritAttrs: false,
    })
    export default class JsonSchemaColorForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        private faExclamationCircle = faExclamationCircle;
        private faTimes = faTimes;
        private pickerOpened = false;

        private openPicker(event: Event): void {
            event.stopPropagation();
            if (!this.disabled) {
                this.pickerOpened = !this.pickerOpened;
            }
        }

        private closePicker(): void {
            this.pickerOpened = false;
        }

        @Watch('disabled')
        private closePickerWhenDisabled(): void {
            if (this.disabled) {
                this.closePicker();
            }
        }

        protected override getCustomError(): string | null {
            const value = this.value;
            if ((this.form.schema.minLength || 0) > value.length) {
                return this.translate('error.text.tooShort');
            }
            return null;
        }

        private get requiredElement(): boolean {
            return Boolean(this.required && (this.form.schema.minLength || this.form.schema.pattern));
        }

        private get viewValue(): string {
            return typeof this.value === 'string' || typeof this.value === 'number' ? `${this.value}` : '';
        }

        private onPick(data: { hex: string }): void {
            this.$emit('input', this.form.getValue(data.hex));
        }

        private onInput(event: InputEvent): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }

        private onChange(event: Event): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('change', this.form.getValue(inputValue));
        }
    }
</script>
