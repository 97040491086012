import {
    Criterion,
    CriterionInput,
    CriterionTemplate,
} from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

interface Params {
    template: CriterionTemplate;
    criterion: CriterionInput | Criterion;
}

export function getFlagDisabledReferenceValueSchema({ template, criterion }: Params) {
    const conditionalRefs = template.displayMetadata.conditionalValueReference;

    if (!conditionalRefs) {
        return null;
    }

    const schema = criterion.evaluator.references[conditionalRefs.flagDisabledReference]?.schema;
    return schema;
}
