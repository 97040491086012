import JsonSchema, {
    JsonSchemaArray, JsonSchemaBoolean, JsonSchemaComplexObject,
    JsonSchemaConst, JsonSchemaType,
    JsonSchemaEnum, JsonSchemaNumber, JsonSchemaObject, JsonSchemaString, JsonSchemaInteger,
} from './interfaces/JsonSchema';

function isSchemaOfType(schema: JsonSchema, type: JsonSchemaType): boolean {
    return Boolean(schema) && 'type' in schema && schema.type === type;
}

export function isEnum(schema: JsonSchema): schema is JsonSchemaEnum {
    return Boolean(schema) && 'enum' in schema && Array.isArray(schema.enum);
}

export function isConst(schema: JsonSchema): schema is JsonSchemaConst {
    return Boolean(schema) && 'const' in schema;
}

export function isArray(schema: JsonSchema): schema is JsonSchemaArray {
    return isSchemaOfType(schema, JsonSchemaType.array);
}

export function isString(schema: JsonSchema): schema is JsonSchemaString {
    return isSchemaOfType(schema, JsonSchemaType.string);
}

export function isNumber(schema: JsonSchema): schema is JsonSchemaNumber {
    return isSchemaOfType(schema, JsonSchemaType.number);
}

export function isInteger(schema: JsonSchema): schema is JsonSchemaInteger {
    return isSchemaOfType(schema, JsonSchemaType.integer);
}

export function isBoolean(schema: JsonSchema): schema is JsonSchemaBoolean {
    return isSchemaOfType(schema, JsonSchemaType.boolean);
}

export function isObject(schema: JsonSchema): schema is JsonSchemaObject {
    return isSchemaOfType(schema, JsonSchemaType.object);
}

export function isComplexObject(schema: JsonSchema): schema is JsonSchemaComplexObject {
    return isSchemaOfType(schema, JsonSchemaType.object) && 'if' in schema && Boolean(schema.if);
}
