const dummyAttributeMapping: Record<string, string> = {
    src: 'data-x-src',
};
const realAttributeMapping: Record<string, string> = {
    'data-x-src': 'src',
};
const dummyAttributes = Object.keys(dummyAttributeMapping);

function isScript(node: any): node is HTMLScriptElement {
    return node?.tagName === 'SCRIPT';
}

function isElement(node: any): node is HTMLElement {
    return Boolean(node?.tagName);
}

function isText(node: any): node is Text {
    return node?.nodeType === 3;
}

function getTagName(node: Node): string | null {
    return isElement(node) ? node.tagName : null;
}

function getRealAttributeName(attributeName: string): string {
    return realAttributeMapping[attributeName] || attributeName;
}

function getDummyAttributeName(attributeName: string): string {
    return dummyAttributeMapping[attributeName] || attributeName;
}

function cloneNode(node: Node, domDocument: Document): Node {
    if (isScript(node)) {
        const script: HTMLScriptElement = domDocument.createElement('script');
        for (const attribute of node.attributes) {
            script.setAttribute(attribute.name, attribute.value);
        }
        script.appendChild(domDocument.createTextNode(node.innerHTML));
        return script;
    }
    return node.cloneNode(true);
}

function replaceChildren(prevNode: HTMLElement, nextNode: HTMLElement, domDocument: Document): void {
    // Copy child nodes; sometimes <script> may create new elements, so only controlled items should be modified.
    let childNodes: Node[] = Array.from(prevNode.childNodes)
        .filter((node) => (!isElement(node) || node.hasAttribute('data-controlled')));

    // Remove obsolete items
    for (let i = nextNode.childNodes.length; i < childNodes.length; i++) {
        prevNode.removeChild(childNodes[i]);
    }
    childNodes = childNodes.slice(0, nextNode.childNodes.length);

    // Replace items
    for (let i = 0; i < childNodes.length; i++) {
        // eslint-disable-next-line no-use-before-define
        const newChild = replaceNode(childNodes[i], nextNode.childNodes[i], domDocument);
        if (newChild) {
            childNodes[i] = newChild;
        } else {
            childNodes.splice(i, 1);
        }
    }

    // Add new items
    for (let i = childNodes.length; i < nextNode.childNodes.length; i++) {
        const newChild = cloneNode(nextNode.childNodes[i], domDocument);
        const lastChildren = childNodes[childNodes.length - 1];
        if (lastChildren?.nextSibling) {
            prevNode.insertBefore(newChild, lastChildren.nextSibling);
        } else {
            prevNode.appendChild(newChild);
        }
        childNodes.push(newChild);
    }
}

// eslint-disable-next-line complexity
export function replaceNode(prevNode: Node, nextNode: Node | null, domDocument: Document): Node | null {
    const replace = () => {
        const finalNode = cloneNode(nextNode!, domDocument);
        prevNode.parentNode!.replaceChild(finalNode, prevNode);
        return finalNode;
    };
    const remove = () => {
        prevNode.parentNode!.removeChild(prevNode);
        return null;
    };

    // Remove the element, when it is not text or element (so - irrelevant)
    if (!isText(nextNode) && !isElement(nextNode)) {
        return remove();
    }

    // Replace the node when the previous one is irrelevant
    if (!isText(prevNode) && !isElement(prevNode)) {
        return replace();
    }

    // Get information about nodes type
    const prevTag = getTagName(prevNode);
    const nextTag = getTagName(nextNode);

    // Replace the node with the new one, when these are different types
    if (prevTag !== nextTag) {
        return replace();
    }

    // Replace text contents only when it has changed
    if (isText(prevNode) && isText(nextNode)) {
        if (prevNode.textContent !== nextNode.textContent) {
            prevNode.textContent = nextNode.textContent;
        }
        return prevNode;
    }

    // Type safety check - at this point we should have two elements
    if (!isElement(prevNode) || !isElement(nextNode)) {
        console.log('Previous node', prevNode);
        console.log('Next node', nextNode);
        throw new Error('Preview implementation problem. At this point we should have only elements.');
    }

    // Replace & execute script, when it has changed
    if (isScript(prevNode) && isScript(nextNode)) {
        if (
            nextNode.innerHTML !== prevNode.innerHTML ||
            nextNode.getAttribute(getDummyAttributeName('src')) !== prevNode.getAttribute('src')
        ) {
            if (nextNode.hasAttribute(getDummyAttributeName('src'))) {
                nextNode.setAttribute('src', prevNode.getAttribute(getDummyAttributeName('src'))!);
            }
            return replace();
        }
        return prevNode;
    }

    // Remove obsolete attributes
    for (const attribute of prevNode.attributes) {
        if (!nextNode.hasAttribute(getDummyAttributeName(attribute.name))) {
            prevNode.removeAttribute(attribute.name);
        }
    }

    // Set new attributes
    for (const attribute of nextNode.attributes) {
        if (prevNode.getAttribute(getRealAttributeName(attribute.name)) !== attribute.value) {
            try {
                prevNode.setAttribute(getRealAttributeName(attribute.name), attribute.value);
            } catch (e) {
                /**
                 * Sometimes, when invalid template was provided, there may be created invalid attribute.
                 * Unfortunately, such attribute may be created via HTML code (like named `"`),
                 * but the setAttribute will fail during copy with DOMException.
                 *
                 * As it's not a big deal for anyway broken data,
                 * that should be simply ignored.
                 */
            }
        }
    }

    // Replace contents
    replaceChildren(prevNode, nextNode, domDocument);

    return prevNode;
}

function removeInsignificantNodes(node: Node): void {
    for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i];
        if (childNode.nodeType !== 1 && childNode.nodeType !== 3) {
            i--;
            node.removeChild(childNode);
        } else if (childNode.childNodes.length > 0) {
            removeInsignificantNodes(childNode);
        }
    }
}

export function updateContents(wrapper: HTMLElement, nextHtmlContents: string, domDocument: Document): void {
    // Build the desired DOM structure
    const temporaryWrapper = domDocument.createElement('div');
    temporaryWrapper.innerHTML = nextHtmlContents;

    for (const element of temporaryWrapper.querySelectorAll('*')) {
        // Mark elements as controlled
        element.setAttribute('data-controlled', 'true');

        // Replace some attributes temporarily: i.e. do not use "src", to reduce network calls and glitches
        for (const attributeName of dummyAttributes) {
            if (element.hasAttribute(attributeName)) {
                element.setAttribute(getDummyAttributeName(attributeName), element.getAttribute(attributeName)!);
                element.removeAttribute(attributeName);
            }
        }
    }

    // Remove all comments
    removeInsignificantNodes(temporaryWrapper);

    // Update wrapper contents
    replaceChildren(wrapper, temporaryWrapper, domDocument);
}

export function encodeText(text: string): string {
    const element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML
        // Disallowing escaping from attribute properties
        .replace(/"/g, '&quot;');
}
