import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import vuex from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';

export const localStorageModule = createModuleBuilder()
    .demandContext<{
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ localStorage: vuex.instantiateModule })
    .execute((app) => {
        function onloadUserPreference(email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load User Preference from Local Storage',
                    description: `[Requested] Load User Preference for ${email}`,
                    email,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Local Storage - User Preference',
                    message: `[Requested] Load User Preference for ${email}`,
                    data: { email },
                });
            }
        }

        function onSaveUserPreference(email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Save User Preference to Local Storage',
                    description: `[Requested] Save User Preference for ${email}`,
                    email,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Local Storage - User Preference',
                    message: `[Requested] Save User Preference for ${email}`,
                    data: { email },
                });
            }
        }

        app.observer.onAction('loadUserPreference', (x) => onloadUserPreference(x.email));
        app.observer.onAction('saveUserPreference', (x) => onSaveUserPreference(x.email));
    })
    .end();
