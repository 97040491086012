<template>
    <div class="Pagination">
        <div class="Pagination__previous"
            :class="{'Pagination__previous--disabled': currentPage === 1}"
            @click="previous"
        >
            <FontAwesomeIcon :icon="faChevronLeft" />
        </div>
        <span v-for="(page, index) in buildPages()"
            :key="index"
            class="Pagination__pageIndex"
            :class="{'Pagination__ellipse': page === '...', 'Pagination__current': page === String(currentPage)}"
            @click="navigate(page)"
        >{{ page }}</span>
        <div class="Pagination__next"
            :class="{'Pagination__next--disabled': currentPage === getTotalPages()}"
            @click="next">
            <FontAwesomeIcon :icon="faChevronRight" />
        </div>
    </div>
</template>

<script lang="ts">
    import range from 'lodash/range';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Pagination extends Vue {
        private faChevronLeft = faChevronLeft;
        private faChevronRight = faChevronRight;

        @Prop({ type: Number, default: 20 })
        private perPage!: number;

        @Prop({ type: Array, default: () => [] })
        private items!: any[];

        private currentPage = 1;

        private getTotalPages(): number {
            return Math.ceil(this.items.length / this.perPage || 0);
        }

        @Watch('items', { immediate: true })
        private onCurrentItemsChanged(): void {
            this.navigate(this.currentPage);
        }

        private buildPages(): string[] {
            const totalPages = this.getTotalPages();
            const first5 = range(1, 6);
            const last5 = range(5, 0).map((i) => (totalPages - i));
            let pages;
            if (totalPages <= 5) {
                pages = range(1, totalPages + 1);
            } else if (this.currentPage < 5) {
                pages = [ ...first5, '...', totalPages ];
            } else if (this.currentPage > totalPages - 5) {
                pages = [ '1', '...', ...last5 ];
            } else {
                pages = [
                    '1',
                    '...',
                    this.currentPage - 1,
                    this.currentPage,
                    this.currentPage + 1,
                    '...',
                    totalPages,
                ];
            }
            return pages.map((page) => `${page}`);
        }

        private previous(): void {
            if (this.currentPage > 1) {
                this.navigate(`${this.currentPage - 1}`);
            }
        }

        private next(): void {
            if (this.currentPage < this.getTotalPages()) {
                this.navigate(`${this.currentPage + 1}`);
            }
        }

        public navigate(page: string | number): void {
            const destinationPage = parseInt(`${page}`, 10);
            if (isNaN(destinationPage)) {
                return;
            }
            this.currentPage = destinationPage;
            const startIndex = Math.max(0, this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            this.$emit('input', this.items.slice(startIndex, endIndex));
        }
    }
</script>
