<template>
    <div class="LineLoader" :style="{ '--dot-amount': limitedDotAmount }">
        <div
            v-for="i in limitedDotAmount"
            :key="i"
            class="LineLoader__dot"
            :style="{backgroundColor: primaryColor}"
        />
    </div>
</template>

<style lang="scss">
    $line-loader-max-dots-amount: 5;
    $line-loader-dot-margin: 5px;
    $line-loader-animation-time: 1.5s;
    $line-loader-dot-animation-time: $line-loader-animation-time * 0.1;
    $line-loader-color: #24313a;

    .LineLoader {
        position: relative;
        display: flex;

        &__dot {
            border-radius: 50%;
            width: 1em;
            height: 1em;
            background: $line-loader-color;
            margin: 0 $line-loader-dot-margin;
            animation: line-loader-dot-animation $line-loader-animation-time infinite ease-in-out;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @for $i from 1 through $line-loader-max-dots-amount {
            &__dot:nth-child(#{$i}) {
                animation-delay: calc(($i - var(--dot-amount) - 1) * $line-loader-dot-animation-time);
            }
        }
    }

    @keyframes line-loader-dot-animation {
        0%, 80%, 100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }
</style>

<script lang="ts">
    import {
        PartnersSettingsService,
    } from '@evidentid/iam-client/services/partners-settings-service/partnersSettingsService';
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class LineLoader extends Vue {
        @Prop({ type: String, default: '#24313a' })
        private dotColor!: string;

        @Prop({ type: Number, default: 5 })
        private dotAmount!: number;

        /*
        * this needs to match $line-loader-max-dots-amount in the scss, this variable is static, if needs to be changed.
        * will need to adjust animation time variables within the scss to support the new max number
        */
        private maxDotAmount = 5;
        private limitedDotAmount = this.dotAmount <= this.maxDotAmount ? this.dotAmount : this.maxDotAmount;
        private primaryColor = this.dotColor;

        private beforeCreate(): void {
            PartnersSettingsService.fetchPartnerSettings()
                .then((response) => {
                    if (response?.primary_color) {
                        this.primaryColor = response.primary_color;
                    }
                });
        }
    }
</script>
