import LoggerUserData from '@evidentid/logger/LoggerUserData';
import ConsoleLoggerAdapter from '@evidentid/logger/adapters/ConsoleLoggerAdapter';
import SentryLoggerAdapter from '@evidentid/logger/adapters/SentryLoggerAdapter';
import Logger from '@evidentid/logger';
import { SealedModule } from '../../app/internals/SealedModule';
import {
    ModuleDemandedOptions,
    ModuleInjectedContext,
    ModuleInjectedVueContext,
} from '../../app/types/ModuleDeclaration';
import { createModuleBuilder } from '../../app';

export function createLoggerModule<
    T extends LoggerUserData = LoggerUserData,
>() {
    return createModuleBuilder()
        .demandOptions<{ Sentry?: any | null }>()
        .inject(async (app) => ({
            logger: new Logger<T>(
                app.options.Sentry
                    ? new SentryLoggerAdapter(app.options.Sentry)
                    : new ConsoleLoggerAdapter()
            ),
        }))
        .injectVue((app) => ({
            $logger: app.logger,
        }))
        .end();
}

export type LoggerModule<T extends LoggerUserData = LoggerUserData> = SealedModule<
    ModuleDemandedOptions<{ Sentry?: any | null }> &
    ModuleInjectedContext<{ logger: Logger<T> }> &
    ModuleInjectedVueContext<{ $logger: Logger<T> }>
>;
