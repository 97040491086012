import * as Sentry from '@sentry/browser';
import LoggerAdapter from '../LoggerAdapter';
import Breadcrumb from '../Breadcrumb';
import LoggerUserData from '../LoggerUserData';
import Severity from '../Severity';
import formatLogData from '../formatLogData';
import reporters from '../consoleReporters';

type SentryClient = typeof Sentry;

export default class SentryLoggerAdapter implements LoggerAdapter {
    private client: SentryClient;

    public constructor(sentryClient: SentryClient) {
        this.client = sentryClient;
    }

    public addBreadcrumb(breadcrumb: Breadcrumb): void {
        this.client.addBreadcrumb({
            ...breadcrumb,
            level: breadcrumb.level as unknown as Sentry.Severity,
            data: formatLogData(breadcrumb.data),
        });
    }

    public setUser(userData: LoggerUserData | null): void {
        this.client.setUser(userData);
    }

    public setTags(tags: Record<string, string>): void {
        for (const key of Object.keys(tags)) {
            this.client.setTag(key, tags[key]);
        }
    }

    public log(level: Severity, message: string | Error): void {
        return reporters[level](message);
    }
}
