<template>
    <Modal additional-class-name="RiskProfileSetupModal" open @close="abort">
        <template #header>
            {{ modalTitle }}
        </template>
        <div class="RiskProfileSetupModal__content">
            <div v-if="collateralEnabled" data-test-id="RiskProfileSetupModal_typeSelection">
                <div
                    class="flex justify-around"
                    data-test-id="RiskProfileSetupModal__requirementTypesRiskProfileContainer"
                >
                    <RiskProfileTypeSelectionCard
                        :selected="riskProfileType === defaultRiskProfileTypeConfig.type"
                        :type="defaultRiskProfileTypeConfig.type"
                        :title="defaultRiskProfileTypeConfig.title"
                        :description="defaultRiskProfileTypeConfig.description"
                        @input="selectRiskProfileType"
                    />
                    <RiskProfileTypeSelectionCard
                        :selected="riskProfileType === collateralsTypeConfig.type"
                        :type="collateralsTypeConfig.type"
                        :title="collateralsTypeConfig.title"
                        :description="collateralsTypeConfig.description"
                        @input="selectRiskProfileType"
                    />
                </div>
            </div>
            <FormElement
                class="RiskProfileSetupModal__riskProfileName"
                label="Name Risk Profile:"
            >
                <template #labelHint>
                    This can be edited later.
                    <span class="RiskProfileSetupModal__requiredHint">(Required)</span>
                </template>
                <FormInput>
                    <input v-model="riskProfileName" type="text">
                </FormInput>
            </FormElement>
            <div class="RiskProfileSetupModal__contentText">
                Select Country:
            </div>
            <Dropdown
                class="RiskProfileSetupModal__countryDropdown"
                :options="options"
                :selected="selectedOption"
                @input="selectOption"
            />
        </div>
        <template #footer>
            <div class="RiskProfileSetupModal__footer">
                <Button type="secondary" @click="abort">
                    Cancel
                </Button>
                <Button type="primary" :disabled="!canConfirm" @click="confirm">
                    Create Risk Profile
                </Button>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import RiskProfileTypeSelectionCard
        from '@/modules/decisioning-criteria/components/RiskProfileTypeSelectionCard/RiskProfileTypeSelectionCard.vue';
    import { ConfirmEventPayload } from '@/modules/decisioning-criteria/models/ConfirmEventPayload.model';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';

    import { RiskProfileType } from '@evidentid/tprm-portal-lib/models/decisioning';

    @Component({
        components: {
            Button,
            RiskProfileTypeSelectionCard,
            Dropdown,
            EidIcon,
            FontAwesomeIcon,
            Modal,
            FormElement,
            FormInput,
        },
    })
    export default class RiskProfileSetupModal extends Vue {
        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private countryCodeList!: string[];

        @Prop({ type: Object as PropType<CategorizedEnumLabels>, default: () => ({}) })
        private categorizedEnumLabels!: CategorizedEnumLabels;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private collateralEnabled!: boolean;

        private selectedCountryCode: DropdownOption | null = null;
        private riskProfileName: string = '';
        private riskProfileType: RiskProfileType = RiskProfileType.default;
        private defaultRiskProfileTypeConfig = {
            title: 'Requirement-Based Risk Profile',
            type: RiskProfileType.default,
            description: 'Set assignment rules for specific entity and conditions for requirements.',
        };
        private collateralsTypeConfig = {
            title: 'Collateral-Based Risk Profile',
            type: RiskProfileType.collateral,
            description: 'Choose requirements, set assignment rules, map collateral, and set decision criteria.',
        };

        private get modalTitle(): string {
            return this.collateralEnabled
                ? 'Select the type of risk profile you are creating:'
                : 'Create a New Risk Profile';
        }

        private get selectedOption(): DropdownOption[] {
            return this.selectedCountryCode ? [ this.selectedCountryCode ] : [];
        }

        private get options(): DropdownOption[] {
            return this.countryCodeList.map((code) => ({
                label:
                    getEnumDefaultDisplayValue(EnumCategories.countryCode, code, this.categorizedEnumLabels) as string,
                value: code.toUpperCase(),
            }));
        }

        private get defaultOption(): DropdownOption | null {
            const option = this.options.find((option) => option.value === 'US') || this.options[0] || null;
            return option ?
                {
                    ...option,
                    label: getEnumDefaultDisplayValue(
                        EnumCategories.countryCode, option.value, this.categorizedEnumLabels,
                    ),
                }
                : null;
        }

        private mounted() {
            this.selectedCountryCode = this.defaultOption;
        }

        private selectOption(option: DropdownOption): void {
            this.selectedCountryCode = option;
        }

        private get canConfirm(): boolean {
            return Boolean(this.riskProfileName);
        }

        private confirm(): void {
            this.$emit('confirm', {
                countryCode: this.selectedCountryCode?.value,
                displayName: this.riskProfileName,
                riskProfileType: this.riskProfileType,
            } as ConfirmEventPayload);
        }

        private selectRiskProfileType(type: RiskProfileType): void {
            this.riskProfileType = type;
        }

        private abort(): void {
            this.$emit('abort');
        }
    }
</script>
