import { CustomPropertyAddress } from '@evidentid/tprm-portal-lib/models/dashboard';

function countryLabel(schema: any, value: CustomPropertyAddress, customLabels?: Record<string, any>): string {
    const countryCode = value?.country || '';
    let label;
    if (customLabels) {
        label = customLabels?.countryLabels?.[countryCode]?.label;
    } else {
        const indexCountryCode = schema.properties.country.enum?.findIndex((code: string) => code === countryCode);
        if (indexCountryCode !== undefined && indexCountryCode !== -1) {
            label = schema.properties.country.enumLabels?.[indexCountryCode];
        }
    }
    return label || countryCode;
}

function isAddressValueEmpty(value?: CustomPropertyAddress): boolean {
    const { street, city, state, zipCode, country } = value || {};
    return !street && !city && !state && !zipCode && !country;
}

const addCommaSpace = (text?: string) => (text ? `, ${text}` : '');

export function getDisplayAddress({
    schema,
    value,
    customLabels,
}: {
    schema: any;
    value: CustomPropertyAddress;
    customLabels?: Record<string, any>;
}): string {
    const isEmptyAddress = isAddressValueEmpty(value);
    if (!value || isEmptyAddress) {
        return '-';
    }
    const addSpace = (text: string) => (text ? ` ${text}` : '');
    const street = value.street || '';
    const unit = addCommaSpace(value.aptSuiteOrUnit);
    const city = addCommaSpace(value.city);
    const state = addCommaSpace(value.state);
    const country = addCommaSpace(countryLabel(schema, value, customLabels));
    const zip = addSpace(value.zipCode);
    return `${street}${unit}${city}${state}${zip}${country}` || '-';
}
