<template>
    <div class="EnableFulfillmentToggle">
        <span class="EnableFulfillmentToggle__container">
            <SwitchToggle
                :value="value"
                :disabled="disabled"
                hide-label
                v-on="$listeners"
            />
            Enable fulfillment
        </span>
    </div>
</template>

<style lang="scss">
    .EnableFulfillmentToggle {
        &__container {
            display: flex;
            align-items: center;

            .SwitchToggle {
                padding: 0;
                zoom: 0.9;
                margin-right: 15px;

                &--off .SwitchToggle__slider {
                    background-color: #778ea1;
                }

                &--on .SwitchToggle__slider {
                    background-color: #29b496;
                }
            }
        }
    }
</style>

<script lang="ts">
    import { Vue } from 'vue-property-decorator';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';

    export default Vue.extend({
        name: 'EnableFulfillmentToggle',
        components: {
            SwitchToggle,
        },
        props: {
            value: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
            },
        },
    });
</script>
