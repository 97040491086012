<template>
    <!-- TODO: deprecated, stop using this implementation and converting existing ones to use popover implementation-->
    <div
        v-if="legacyMode"
        class="Expandable Expandable--legacy"
        :class="{ 'Expandable--opened': opened, 'Expandable--disabled': disabled }"
    >
        <div class="Expandable__button" data-test-id="Expandable__button" @click="onButtonClick">
            <div class="Expandable__buttonContent">
                <slot name="button">
                    <Dots />
                </slot>
            </div>
            <div v-if="label" class="Expandable__buttonLabel">
                {{ label }}
            </div>
        </div>
        <div v-if="opened" class="Expandable__content" @click="onClickInside">
            <slot />
        </div>
    </div>
    <div v-else class="Expandable" :class="{ 'Expandable--opened': opened, 'Expandable--disabled': disabled }">
        <v-popover
            trigger="manual"
            popover-class="Expandable__popover"
            :disabled="disabled"
            :open="opened"
            :auto-hide="false"
            placement="bottom-end"
        >
            <div class="Expandable__button" data-test-id="Expandable__button" @click="onButtonClick">
                <div class="Expandable__buttonContent">
                    <slot name="button">
                        <Dots />
                    </slot>
                </div>
                <div v-if="label" class="Expandable__buttonLabel">
                    {{ label }}
                </div>
            </div>
            <template slot="popover">
                <div class="Expandable__content" @click="onClickInside">
                    <slot />
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
    import { Dots } from '../Dots';

    @Component({
        components: { Dots },
    })
    export default class Expandable extends Vue {
        private opened: boolean = false;
        private timeoutId?: number;

        @Prop({ type: String, default: null })
        private label!: string | null;

        @Prop({ type: Boolean, default: true })
        private autoHideOnClickOutside!: boolean;

        @Prop({ type: Boolean, default: true })
        private autoHideOnClickInside!: boolean;

        @Prop({ type: Boolean, default: true })
        private autoHideOnOpenAnother!: boolean;

        @Prop({ type: String, default: 'common' })
        private group!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private legacyMode!: boolean;

        public open(): void {
            this.opened = true;
            this.$emit('open');
        }

        public close(): void {
            this.opened = false;
            this.$emit('close');
        }

        public toggle(): void {
            if (this.opened) {
                this.close();
            } else {
                this.open();
            }
        }

        private get openEventName(): string {
            return `expandable:open:${this.group}`;
        }

        private onButtonClick(event: Event): void {
            if (!event.defaultPrevented) {
                event.preventDefault();
            }
            event.stopPropagation();
            if (!this.disabled) {
                this.toggle();
            }
        }

        private onClickOutside(): void {
            this.$emit('clickOutside');
            if (this.autoHideOnClickOutside) {
                this.close();
            }
        }

        private onClickInside(event: Event): void {
            this.$emit('clickInside');
            if (!this.autoHideOnClickInside) {
                event.stopPropagation();
            }
        }

        private onOpenAnother(): void {
            this.$emit('openAnother');
            if (this.autoHideOnOpenAnother) {
                this.close();
            }
        }

        @Watch('opened')
        private adjustListenersBasedOnOpenStatus(): void {
            if (this.opened) {
                this.timeoutId = window.setTimeout(() => this.addListeners());
                document.body.dispatchEvent(new Event(this.openEventName));
            } else {
                this.removeListeners();
            }
        }

        @Watch('group')
        private rebuildListeners(): void {
            this.removeListeners();
            this.addListeners();
        }

        private destroyed(): void {
            this.removeListeners();
        }

        private addListeners(): void {
            document.body.addEventListener('click', this.onClickOutside);
            document.body.addEventListener(this.openEventName, this.onOpenAnother);
        }

        private removeListeners(): void {
            window.clearTimeout(this.timeoutId);
            document.body.removeEventListener('click', this.onClickOutside);
            document.body.removeEventListener(this.openEventName, this.onOpenAnother);
        }
    }
</script>
