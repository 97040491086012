import type { RoleName, ServiceName } from '@evidentid/user-management-api-client/types';

export interface UserManagementConfig {
    usersPerPage?: number;
    serviceName: ServiceName;
    availableRoles: RoleName[];
    roleLabels?: Record<RoleName, string>;
    roleDescriptions?: Record<RoleName, string>;
    routeAlias?: Record<string, string[]>;
}

export interface UserInput {
    email: string;
    name: string;
    role: RoleName;
}

export enum UserManagementProcedure {
    invite = 'userManagementInvite',
    edit = 'userManagementEdit',
    delete = 'userManagementDelete',
    resendInvite = 'userManagementResendInvite',
}
