
export function formatNumber(num: number): string;
export function formatNumber(num: string | boolean | symbol | object): null;
export function formatNumber(num: unknown): string | null;
export function formatNumber(num: unknown): string | null {
    if (typeof num !== 'number' || !isFinite(num)) {
        return null;
    }
    const integer = num < 0 ? Math.ceil(num) : Math.floor(num);
    const digit = num < 0 ? '-' : '';
    const [ restStr ] = `${num}`.match(/\..*$/) || [ '' ];
    const integerStr = `${digit}${integer}`
        .replace(/^-+/, '-')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${integerStr}${restStr}`;
}
