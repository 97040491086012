import { Component, Prop, Vue } from 'vue-property-decorator';
import { JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
import {
    JsonSchemaFormComponentMatchingFunction,
    JsonSchemaFormCustomComponentInput, JsonSchemaFormCustomComponentsMap,
    JsonSchemaFormStrings,
} from './types';
import { PropType } from 'vue';
import JsonSchemaNumberForm from './elements/JsonSchemaNumberForm.vue';
import JsonSchemaObjectForm from './elements/JsonSchemaObjectForm.vue';
import JsonSchemaArrayForm from './elements/JsonSchemaArrayForm.vue';
import JsonSchemaBooleanForm from './elements/JsonSchemaBooleanForm.vue';
import JsonSchemaStringForm from './elements/JsonSchemaStringForm.vue';
import JsonSchemaEnumForm from './elements/JsonSchemaEnumForm.vue';
import JsonSchemaConstForm from './elements/JsonSchemaConstForm.vue';

@Component({
    inheritAttrs: false,
})
export default class AbstractJsonSchemaFormElement extends Vue {
    @Prop({ type: Boolean, default: false })
    protected disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    protected touched!: string;

    @Prop({ type: Object, default: () => ({}) })
    protected strings!: Partial<JsonSchemaFormStrings>;

    @Prop({ type: [ Object, Function ] as PropType<JsonSchemaFormCustomComponentInput | null> })
    protected customComponentInput?: JsonSchemaFormCustomComponentInput | null;

    protected get getCustomComponent(): JsonSchemaFormComponentMatchingFunction | null {
        return typeof this.customComponentInput === 'function'
            ? this.customComponentInput as JsonSchemaFormComponentMatchingFunction
            : null;
    }

    protected get customComponents(): JsonSchemaFormCustomComponentsMap | null {
        return typeof this.customComponentInput === 'object'
            ? this.customComponentInput as JsonSchemaFormCustomComponentsMap
            : null;
    }

    protected get components(): Record<JsonFormType, Vue.Component | Vue.AsyncComponent> {
        return {
            array: JsonSchemaArrayForm,
            string: JsonSchemaStringForm,
            number: JsonSchemaNumberForm,
            boolean: JsonSchemaBooleanForm,
            const: JsonSchemaConstForm,
            object: JsonSchemaObjectForm,
            enum: JsonSchemaEnumForm,
            ...this.customComponents,
        };
    }
}
