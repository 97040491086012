import omit from 'lodash/omit';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { User } from '@evidentid/user-management-api-client/types';
import { UserManagementApiClient } from '@evidentid/user-management-api-client';
import { UserInput, UserManagementConfig } from './types';

export interface UserManagementRequirements {
    userManagement: UserManagementApiClient;
    options: {
        userManagementConfig: UserManagementConfig;
    };
}

export interface BaseUserStatus {
    status: OperationStatus;
    error: any;
}

export interface UserManagementUsersList extends BaseUserStatus {
    nextToken: string | null;
    users: User[];
    canLoadMore: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserManagementState {
    usersList: Record<string, UserManagementUsersList>;
    inviteUser: Record<string, BaseUserStatus>;
    updateUser: Record<string, Record<string, BaseUserStatus>>;
    deleteUser: Record<string, Record<string, BaseUserStatus>>;
    resendInvite: Record<string, Record<string, BaseUserStatus>>;
}

export const emptyUsersListStatus: UserManagementUsersList = {
    status: OperationStatus.uninitialized,
    nextToken: null,
    users: [],
    canLoadMore: true,
    error: null,
};

const createState = createStateFactory<UserManagementRequirements>();

const { instantiateState, createMutationsFactories } = createState<UserManagementState>(() => ({
    usersList: {},
    inviteUser: {},
    updateUser: {},
    deleteUser: {},
    resendInvite: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingMoreUsers(payload: { rpName: string }) {
        this.usersList = {
            ...this.usersList,
            [payload.rpName]: {
                ...emptyUsersListStatus,
                ...this.usersList[payload.rpName],
                status: OperationStatus.loading,
            },
        };
    },
    failLoadingMoreUsers(payload: { rpName: string, error: any }) {
        this.usersList = {
            ...this.usersList,
            [payload.rpName]: {
                ...emptyUsersListStatus,
                ...this.usersList[payload.rpName],
                status: OperationStatus.error,
                error: payload.error,
            },
        };
    },
    finishLoadingMoreUsers(payload: { rpName: string, users: User[], nextToken: string | null }) {
        this.usersList = {
            ...this.usersList,
            [payload.rpName]: {
                ...emptyUsersListStatus,
                ...this.usersList[payload.rpName],
                status: OperationStatus.success,
                nextToken: payload.nextToken,
                canLoadMore: Boolean(payload.nextToken),
                users: [
                    ...(this.usersList[payload.rpName]?.users || []),
                    ...payload.users,
                ],
            },
        };
    },
    resetUsersList(payload: { rpName: string }) {
        this.usersList = omit(this.usersList, [ payload.rpName ]);
    },
    startInvitingUser(payload: { rpName: string }) {
        this.inviteUser = {
            ...this.inviteUser,
            [payload.rpName]: {
                status: OperationStatus.loading,
                error: null,
            },
        };
    },
    failInvitingUser(payload: { rpName: string, error: any }) {
        this.inviteUser = {
            ...this.inviteUser,
            [payload.rpName]: {
                status: OperationStatus.error,
                error: payload.error,
            },
        };
    },
    finishInvitingUser(payload: { rpName: string }) {
        this.inviteUser = {
            ...this.inviteUser,
            [payload.rpName]: {
                status: OperationStatus.success,
                error: null,
            },
        };
    },
    startUpdatingUser(payload: { rpName: string, id: string }) {
        this.updateUser = {
            ...this.updateUser,
            [payload.rpName]: {
                ...(this.updateUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.loading,
                    error: null,
                },
            },
        };
    },
    failUpdatingUser(payload: { rpName: string, id: string, error: any }) {
        this.updateUser = {
            ...this.updateUser,
            [payload.rpName]: {
                ...(this.updateUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.error,
                    error: payload.error,
                },
            },
        };
    },
    finishUpdatingUser(payload: { rpName: string, id: string }) {
        this.updateUser = {
            ...this.updateUser,
            [payload.rpName]: {
                ...(this.updateUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.success,
                    error: null,
                },
            },
        };
    },
    startDeletingUser(payload: { rpName: string, id: string }) {
        this.deleteUser = {
            ...this.deleteUser,
            [payload.rpName]: {
                ...(this.deleteUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.loading,
                    error: null,
                },
            },
        };
    },
    failDeletingUser(payload: { rpName: string, id: string, error: any }) {
        this.deleteUser = {
            ...this.deleteUser,
            [payload.rpName]: {
                ...(this.deleteUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.error,
                    error: payload.error,
                },
            },
        };
    },
    finishDeletingUser(payload: { rpName: string, id: string }) {
        this.deleteUser = {
            ...this.deleteUser,
            [payload.rpName]: {
                ...(this.deleteUser[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.success,
                    error: null,
                },
            },
        };
    },
    startResendingInvite(payload: { rpName: string, id: string }) {
        this.resendInvite = {
            ...this.resendInvite,
            [payload.rpName]: {
                ...(this.resendInvite[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.loading,
                    error: null,
                },
            },
        };
    },
    failResendingInvite(payload: { rpName: string, id: string, error: any }) {
        this.resendInvite = {
            ...this.resendInvite,
            [payload.rpName]: {
                ...(this.resendInvite[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.error,
                    error: payload.error,
                },
            },
        };
    },
    finishResendingInvite(payload: { rpName: string, id: string }) {
        this.resendInvite = {
            ...this.resendInvite,
            [payload.rpName]: {
                ...(this.resendInvite[payload.rpName] || {}),
                [payload.id]: {
                    status: OperationStatus.success,
                    error: null,
                },
            },
        };
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ userManagement: client, options }: UserManagementRequirements) => ({
    resetUsersList(payload: { rpName: string }) {
        this.mutations.resetUsersList(payload);
    },

    async loadMoreUsers({ rpName }: { rpName: string }) {
        // Obtain current information
        const currentState = this.state.usersList[rpName] || emptyUsersListStatus;

        // Ignore when it's already loading more users, or can't load more
        if (currentState.status === OperationStatus.loading || !currentState.canLoadMore) {
            return;
        }

        try {
            // Load more users
            this.mutations.startLoadingMoreUsers({ rpName });
            const prevState = this.state.usersList[rpName] || emptyUsersListStatus;
            const { results, next } = await client.getUsers({
                page: currentState.nextToken,
                limit: options.userManagementConfig.usersPerPage || 10,
            }, rpName);

            // Ensure there is no race condition
            const nextState = this.state.usersList[rpName] || emptyUsersListStatus;
            if (prevState !== nextState) {
                return;
            }

            // Push new data
            this.mutations.finishLoadingMoreUsers({
                rpName,
                users: results,
                nextToken: next,
            });
        } catch (error) {
            this.mutations.failLoadingMoreUsers({ rpName, error });
        }
    },

    async inviteUser(payload: {
        rpName: string;
        rpDisplayName?: string | null;
        input: UserInput;
    }) {
        const { rpName, rpDisplayName, input } = payload;
        const service = options.userManagementConfig.serviceName;
        const status = this.state.inviteUser[rpName]?.status || OperationStatus.uninitialized;

        // Ignore when some invitation to this RP is already in progress
        if (status === OperationStatus.loading) {
            return;
        }

        // Invite user
        try {
            this.mutations.startInvitingUser({ rpName });

            const loginUrl = Object.assign(new URL(location.href), { hash: '#/auth' }).toString();
            const user = {
                name: input.name || input.email,
                email: input.email,
                services: {
                    [service]: {
                        enabled: true,
                        roles: [ input.role ],
                        resources: [ rpName ],
                    },
                },
            };
            await client.createUser({
                user,
                loginUrl,
                displayName: rpDisplayName || rpName,
            }, rpName);

            this.mutations.finishInvitingUser({ rpName });
        } catch (error) {
            this.mutations.failInvitingUser({ rpName, error });
        }
    },

    async updateUser(payload: {
        id: string;
        rpName: string;
        input: UserInput;
    }) {
        const { rpName, id, input } = payload;
        const service = options.userManagementConfig.serviceName;
        const status = this.state.inviteUser[rpName]?.status || OperationStatus.uninitialized;

        // Ignore when some update to this user is already in progress
        if (status === OperationStatus.loading) {
            return;
        }

        // Invite user
        try {
            this.mutations.startUpdatingUser({ rpName, id });

            const user = {
                name: input.name || input.email,
                email: input.email,
                services: {
                    [service]: {
                        enabled: true,
                        roles: [ input.role ],
                        resources: [ rpName ],
                    },
                },
            };
            await client.updateUser(id, user, rpName);

            this.mutations.finishUpdatingUser({ rpName, id });
        } catch (error) {
            this.mutations.failUpdatingUser({ rpName, id, error });
        }
    },

    async deleteUser(payload: {
        id: string;
        rpName: string;
    }) {
        const { rpName, id } = payload;
        const status = this.state.inviteUser[rpName]?.status || OperationStatus.uninitialized;

        // Ignore when some deletion of this user is already in progress
        if (status === OperationStatus.loading) {
            return;
        }

        // Invite user
        try {
            this.mutations.startDeletingUser({ rpName, id });
            await client.deleteUser(id, rpName);
            this.mutations.finishDeletingUser({ rpName, id });
        } catch (error) {
            this.mutations.failDeletingUser({ rpName, id, error });
        }
    },

    async resendInvite(payload: {
        id: string;
        rpName: string;
    }) {
        const { rpName, id } = payload;
        const status = this.state.inviteUser[rpName]?.status || OperationStatus.uninitialized;

        // Ignore when some deletion of this user is already in progress
        if (status === OperationStatus.loading) {
            return;
        }

        // Invite user
        try {
            this.mutations.startResendingInvite({ rpName, id });

            const loginUrl = Object.assign(new URL(location.href), { hash: '#!/auth' }).toString();
            const status = await client.resendInvite({ userId: id, loginUrl }, rpName);
            if (!status) {
                throw new Error('It was not possible to re-send invite to this user.');
            }

            this.mutations.finishResendingInvite({ rpName, id });
        } catch (error) {
            this.mutations.failResendingInvite({ rpName, id, error });
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
