export enum OperationStatus {
    loading = 'loading',
    success = 'success',
    error = 'error',
    uninitialized = 'uninitialized',
}

const availableOperationStatuses = Object.values(OperationStatus);

export function isOperationStatus(status: unknown): status is OperationStatus {
    return availableOperationStatuses.includes(status as OperationStatus);
}
