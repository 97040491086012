// reason for two stages with 'pending' and 'disabled' is to prevent refresh, crash, cases where status is not cleared
// when umbrella is removed, recorded it as pending. Change to disabled after saved
// Do not auto add umbrella if pending or disabled
// remove all pending when component that uses this service created, so that all records that did not save get purged
const PENDING = 'pending';
const DISABLED = 'disabled';
export const RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID = 'createGroup';
export const KEY_PREFIX = 'autoAddUmbrellaStatus-';

export function getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId?: string): string {
    return `${KEY_PREFIX}${riskProfileId || RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID}`;
}

export function isRiskProfileAutoAddUmbrellaDisabled(riskProfileId: string): boolean {
    const status = window.sessionStorage.getItem(getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId));
    return status === DISABLED || status === PENDING;
}

// pending when umbrella coverage type removed
export function startDisablingRiskProfileAutoAddUmbrella(riskProfileId: string) {
    window.sessionStorage.setItem(getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId), PENDING);
}

// disabled when riskProfile saved
export function confirmDisablingRiskProfileAutoAddUmbrella(riskProfileId: string) {
    if (window.sessionStorage.getItem(getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId)) === PENDING) {
        window.sessionStorage.setItem(getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId), DISABLED);
    }
}

export function assignCreateGroupAutoUmbrellaStatusId(riskProfileId: string) {
    if (!riskProfileId) {
        return;
    }
    const createGroupStatus =
        window.sessionStorage.getItem(
            getRiskProfileAutoAddUmbrellaStatusKey(RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID)
        );
    // exclude the cases of undefined and nullclea
    if (createGroupStatus != null) {
        window.sessionStorage.removeItem(
            getRiskProfileAutoAddUmbrellaStatusKey(RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID)
        );
        window.sessionStorage.setItem(getRiskProfileAutoAddUmbrellaStatusKey(riskProfileId), createGroupStatus);
    }
}

export function purgeRiskProfileAutoAddUmbrellaPendingItems() {
    const listToRemove = [] as string[];
    for (let i = 0; i < sessionStorage.length; i++) {
        const itemKey = sessionStorage.key(i);
        const itemStatus = itemKey ? sessionStorage.getItem(itemKey) : null;
        if (itemStatus === PENDING && itemKey && itemKey.startsWith(KEY_PREFIX)) {
            listToRemove.push(itemKey);
        }
    }
    listToRemove.forEach((key) => sessionStorage.removeItem(key));
}
