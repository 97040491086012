import { createStateFactory } from '@evidentid/vue-commons/store';
import { ErrorObject, PersistingErrorState } from './types';

const createState = createStateFactory<{}>();

const { instantiateState, createMutationsFactories } = createState<PersistingErrorState>(() => ({
    error: null,
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    setError(error: ErrorObject | { message: string } | null) {
        this.error = error || null;
    },
}));

const { instantiateActions, instantiateModule, getActions } = createActionFactories(() => ({
    async showError(error: ErrorObject | Error | { message: string } | string | null) {
        this.mutations.setError(typeof error === 'string' ? { reason: error } : error || null);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
