<template>
    <div
        class="CriterionArrayInput"
        :class="{
            'CriterionArrayInput--focus': focused,
            'CriterionArrayInput--objectItems': hasObjectChildren,
            'CriterionArrayInput--empty': empty
        }"
    >
        <FormElement :label="arrayLabel">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>{{ typeLabel }}</span>
                </div>
                <div
                    v-else
                    v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <div v-for="(item, index) in iteratorValue" :key="index" class="CriterionArrayInput__itemContainer">
                <div v-if="hasObjectChildren" class="CriterionArrayInput__itemIndex">
                    {{ index + 1 }}
                </div>
                <div class="CriterionArrayInput__item">
                    <component
                        :is="FormElementComponent"
                        :id="`${id}_${index}`"
                        :key="index"
                        :depth="depth + 1"
                        :form="childrenForm"
                        :value="item"
                        :touched="touched"
                        :required="required"
                        :disabled="disabled"
                        :strings="strings"
                        :deletable="minItems < itemsLength"
                        :description="form.schema.description"
                        :hide-title="hideTitle"
                        :show-error="showError"
                        :form-element-component="FormElementComponent"
                        :custom-component-input="customComponentInput"
                        v-bind="$attrs"
                        @change="onChange(index, $event)"
                        @focus="onChildFocused(index)"
                        @touch="onChildTouched(index)"
                        @delete="onDelete(index)"
                        @input="onInput(index, $event)"
                        @blur="$emit('blur', $event)"
                    >
                        <template v-if="$slots.icon" #icon>
                            <slot name="icon" />
                        </template>
                    </component>
                </div>
            </div>
            <FormInput force-action-slot>
                <Button
                    v-if="!disabled && itemsLength < maxItems"
                    class="CriterionArrayInput__addButton"
                    type="primary"
                    :disabled="!valid"
                    :icon="faPlus"
                    @click="onAdd"
                >
                    <strong>{{ addItemLabel }}</strong>
                </Button>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import startCase from 'lodash/startCase';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { faPlus, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
    import { JsonFormArray } from '@evidentid/json-schema/interfaces/JsonForm';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/AbstractCriterionInput';
    import { isObject } from '@evidentid/json-schema/schemaChecks';

    @Component({
        components: { FormInput, FormElement, Button, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class CriterionArrayInput extends AbstractCriterionInput<JsonFormArray, any[]> {
        private faPlus = faPlus;
        private faExclamationCircle = faExclamationCircle;
        private faQuestionCircle = faQuestionCircle;
        private childAccessedList: boolean[] = new Array(this.itemsLength);
        private focusedChildIndex: number | null = null;

        private get iteratorValue(): any[] {
            if (!this.value || !Array.isArray(this.value) || this.value.length >= this.minItems) {
                return this.value;
            }
            const missingItems = this.minItems - this.value.length;
            return [ ...this.value ].concat(Array.from(new Array(missingItems)).map(() => null));
        }

        private get itemsLength(): number {
            return Array.isArray(this.value) ? this.value.length : 0;
        }

        private get minItems(): number {
            return this.form.schema.minItems || 0;
        }

        private get maxItems(): number {
            return this.form.schema.maxItems == null ? Infinity : this.form.schema.maxItems;
        }

        private get typeLabel(): string {
            const stringFormat = (this.form.item.schema as JsonSchemaString).format;
            const label = this.form.item.type === 'string' && stringFormat ? stringFormat : this.form.item.type;
            return `${label.charAt(0).toUpperCase()}${label.substring(1).toLowerCase()} only`;
        }

        protected get TypeLabelClass(): string | null {
            if (!this.focused || this.focusedChildIndex == null) {
                return null;
            }
            return this.childAccessedList[this.focusedChildIndex] && !this.isChildValid(this.focusedChildIndex)
                ? 'JsonSchemaForm__typeLabel--error'
                : 'JsonSchemaForm__typeLabel--focused';
        }

        private get childrenForm(): any {
            // give parent's title if items does not have one.
            return {
                ...this.form.item,
                schema: {
                    ...this.form.item.schema,
                    title: this.form.schema.title,
                    description: this.form.schema.description,
                },
            };
        }

        private get addItemLabel(): string {
            return this.form.schema.addItemTitle || `Add ${startCase(this.form.schema.title)}`;
        }

        private get arrayLabel(): string {
            return this.itemsLength && this.hasObjectChildren && this.form.schema.title
                ? this.form.schema.title
                : '';
        }

        private get hasObjectChildren(): boolean {
            return isObject(this.form.item.schema);
        }

        private isChildValid(index: number): boolean {
            return !this.required && !this.value[index] ? true : this.form.item.isValid(this.value[index]);
        }

        private onChildFocused(index: number): void {
            this.focused = true;
            this.focusedChildIndex = index;
        }

        private onChildTouched(index: number): void {
            this.focused = false;
            this.childAccessedList[index] = true;
            this.focusedChildIndex = null;
        }

        private onAdd(): void {
            if (this.valid) {
                this.childAccessedList.push(false);
                this.$emit('input', this.form.getValue([
                    ...(this.value || []),
                    this.form.item.getValue(),
                ]));
            }
        }

        protected onDelete(index: number): void {
            this.childAccessedList = [
                ...this.childAccessedList.slice(0, index),
                ...this.childAccessedList.slice(index + 1),
            ];
            const val = this.form.getValue([
                ...this.value.slice(0, index),
                ...this.value.slice(index + 1),
            ]);
            this.$emit('input', val);
            this.$emit('change', val);
        }

        private onInput(index: number, value: any): void {
            this.$emit('input', this.form.getValue([
                ...this.value.slice(0, index),
                value,
                ...this.value.slice(index + 1),
            ]));
        }

        private onChange(index: number, value: any): void {
            this.$emit('change', this.form.getValue([
                ...this.value.slice(0, index),
                value,
                ...this.value.slice(index + 1),
            ]));
        }
    }
</script>
