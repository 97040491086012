import { createModuleBuilder } from '../../app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';

export function createOnlineChatModule() {
    return createModuleBuilder()
        .demandContext<{
            rpweb: RpWebApiClient;
        }>()
        .registerVuex({ onlineChat: vuex.instantiateModule })
        .end();
}

export type OnlineChatModule = ReturnType<typeof createOnlineChatModule>;
