<template>
    <div class="ProcedureTarget" :style="hideContainer ? 'display: none !important;' : null">
        <div v-for="execution in activeExecutions" :key="execution.uuid" class="ProcedureTarget__procedure">
            <v-component
                :is="$procedures.views[execution.type]"
                :uuid="execution.uuid"
                v-bind="execution.options"
                @finish="$procedures.finish(execution, $event)"
                @abort="$procedures.abort(execution, $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import noop from 'lodash/noop';
    import { beforeRouteChange } from '@evidentid/vue-commons/router/guards';
    import { ProcedureExecution } from './types';

    @Component
    export default class ProcedureTarget extends Vue {
        @Prop({ type: Boolean, default: false })
        private disableRoutingDuringProcedure!: boolean;

        @Prop({ type: Boolean, default: true })
        private abortOnRedirect!: boolean;

        @Prop({ type: Boolean, default: false })
        private hideContainer!: boolean;

        private activeExecutions: ProcedureExecution<any>[] = [];
        private unsubscribe: Function = noop;

        private get active(): boolean {
            return this.activeExecutions.length > 0;
        }

        private mounted(): void {
            // @ts-ignore: use not public property
            this.activeExecutions = this.$procedures.executions;
            // @ts-ignore: use not public method
            this.unsubscribe = this.$procedures.subscribe((executions) => {
                this.activeExecutions = executions;
            });
        }

        private destroyed(): void {
            this.unsubscribe();
        }

        /*
         * Disallow route change when any procedure is active,
         * so User will not accidentally go through history.
         */

        private shouldRedirect(to: any): boolean {
            return !this.disableRoutingDuringProcedure || !this.active || ('force' in (to?.query || {}));
        }

        @beforeRouteChange
        private onLeave(to: any): boolean {
            const shouldRedirect = this.shouldRedirect(to);
            if (shouldRedirect && this.abortOnRedirect) {
                this.activeExecutions.forEach((execution) => (this as any).$procedures.abort(execution));
            }
            return shouldRedirect;
        }
    }
</script>
