<template>
    <Modal additional-class-name="EntitiesDocumentsModal EntitiesDocumentsModal--error" open @close="close">
        <template #header>
            Documents
        </template>
        <Alert type="danger">
            {{ error }}
        </Alert>
    </Modal>
</template>

<style lang="scss">
    .EntitiesDocumentsModal--error {
        .Modal__body {
            width: 750px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';

    @Component({
        components: {
            Modal,
            Alert,
        },
    })
    export default class EntitiesDocumentsErrorModal extends Vue {
        @Prop({ type: String, default: 'Something went wrong, please try again.' })
        private error!: string;

        private close(): void {
            this.$emit('close');
        }
    }
</script>
