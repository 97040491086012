import Severity from './Severity';

// Ensure that we will not keep reference to `console` methods, to use their global implementations all the time.
const reporters: Record<Severity, (...args: any) => void> = {
    [Severity.error]: (...args: any) => console.error(...args),
    [Severity.warning]: (...args: any) => console.warn(...args),
    [Severity.info]: (...args: any) => console.info(...args),
    [Severity.verbose]: (...args: any) => console.log(...args),
};

export default reporters;
