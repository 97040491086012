<template>
    <div class="CriterionBooleanInput">
        <FormElement :label="hideTitle ? null : form.schema.title">
            <template v-if="form.schema.description" #labelHint>
                <div
                    v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot force-action-slot>
                <Dropdown
                    :options="booleanOptions"
                    :selected="convertBoolToOptions(value)"
                    @input="onBooleanFieldChange($event)"
                />
                <template v-if="(showError || accessed) && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            :icon-src="binIcon"
                            alt="delete"
                            :svg-props="{color: alertRedColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/AbstractCriterionInput';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { Dropdown, EidIcon, FormInput, FormElement, FontAwesomeIcon },
    })
    export default class CriterionBooleanInput extends AbstractCriterionInput<JsonFormBoolean, boolean> {
        private binIcon = binIcon;
        private faQuestionCircle = faQuestionCircle;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        private booleanOptions: DropdownOption[] = [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ];

        private convertBoolToOptions(value: boolean | undefined): DropdownOption[] {
            return value === undefined ? [] : [ { label: value ? 'Yes' : 'No', value: Boolean(value) } ];
        }

        private onBooleanFieldChange(option: DropdownOption): void {
            this.$emit('input', option.value);
        }
    }
</script>
