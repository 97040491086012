<template>
    <div class="CriterionEnumInput">
        <FormElement :label="hideTitle ? null : form.schema.title">
            <template v-if="form.schema.description" v-slot:labelHint>
                <div v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <!--TODO(PRODUCT-16737): replace once enum type/value mismatch fixed-->
            <!--  <FormInput :invalid="(showError || accessed) && hasError" force-error-slot force-action-slot>-->
            <!--                <Dropdown :options="options" :selected="currentOption" @input="onInput" />-->
            <!--                <template v-if="(showError || accessed) && error" v-slot:errorMessage>-->
            <!--                    {{ error }}-->
            <!--                </template>-->
            <!--                <template v-if="deletable" v-slot:actionButtons>-->
            <!--                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">-->
            <!--                        <img :src="tranIcon" alt="delete" />-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--            </FormInput>-->
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot force-action-slot>
                <Dropdown :options="options"
                    :selected="currentOption"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                />
                <template v-if="(showError || accessed) && error" v-slot:errorMessage>
                    {{ error }}
                </template>
                <template v-if="deletable" v-slot:actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            alt="delete"
                            :icon-src="trashBinIcon"
                            :svg-props="{color: evidentGreenColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import trashBinIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import AbstractCriterionInput from './AbstractCriterionInput';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: { FormInput, Dropdown, EidIcon, FontAwesomeIcon, FormElement },
        inheritAttrs: false,
    })
    export default class CriterionEnumInput extends AbstractCriterionInput<JsonFormBase<JsonSchemaEnum>, any> {
        private trashBinIcon = trashBinIcon;
        private faQuestionCircle = faQuestionCircle;
        private options: DropdownOption[] = [];
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;

        @Watch('form.schema.enum', { immediate: true })
        private onEnumChange(): void {
            const labels = this.form.schema.enumLabels && this.form.schema.enumLabels.length > 0
                ? this.form.schema.enumLabels
                : null;
            this.options = labels
                ? this.form.schema.enum.map((value, i) => ({ value, label: labels[i] }))
                : this.form.schema.enum.map(this.buildOptionFromValue);
        }

        private get currentOption(): DropdownOption[] {
            // FIXME: handle duplicate values
            const option = this.options.find((option) => option.value === this.value);
            return option ? [ option ] : [];
        }

        private buildOptionFromValue(value: any): DropdownOption {
            return { value, label: value };
        }

        private onInput(option: DropdownOption): void {
            this.$emit('input', option.value);
        }
    }
</script>
