import RpWebApiClient, { EntityPatch } from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import {
    AddEntitiesFailure,
    EntityPostResponse,
    EntityUpsertResponse,
    EntityPatchResult,
} from '@evidentid/rpweb-api-client/models';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import dashboard from '@/modules/dashboard/vuex/dashboard';
import { convertEntityInputViewToData } from '@/modules/entity-management/utils/entityProcessing';
import { Entity, EntityInput, EntityInputApiModel } from '@evidentid/tprm-portal-lib/models/dashboard';

function mergeBatchResults<T extends EntityPostResponse | EntityUpsertResponse>(
    partialResults: PromiseSettledResult<T>[]): T {
    return partialResults.reduce((result, partialResult) => (partialResult.status === 'fulfilled'
        ? {
            ...result,
            totalCount: result.totalCount + (partialResult.value.totalCount || 0),
            successCount: result.successCount + (partialResult.value.successCount || 0),
            failureCount: result.failureCount + (partialResult.value.failureCount || 0),
            successes: [ ...result.successes, ...(partialResult.value.successes || []) ],
            failures: [ ...result.failures, ...(partialResult.value.failures || []) ],
        }
        : result),
        {
            totalCount: 0,
            successCount: 0,
            failureCount: 0,
            successes: [] as Entity[],
            failures: [] as T['failures'],
        } as T);
}

export interface EntityManagementRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<EntityManagementRequirement>();

// totalCount = total entities requested for the batch call
// successOnRequestCount = successCount + failureCount(request succeed but entity not created/updated)
export interface BatchStatus {
    status: OperationStatus;
    progress: number;
    totalCount: number;
    successCount: number; // on success calls
    failureCount: number; // on success calls
    successOnRequestCount: number;
    failedOnRequestCount: number; // on failed calls
    successes: any[];
    failures: any[];
}

export interface BatchAddEntitiesStatus extends BatchStatus {
    successes: Entity[];
    failures: AddEntitiesFailure[];
}

export interface BatchUpdateEntitiesStatus extends BatchStatus {
    successes: Entity[];
    failures: (Entity | EntityInputApiModel)[];
}

export interface PatchEntitiesStatus {
    status: OperationStatus;
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: EntityPatchResult[];
    failures: { element: EntityPatchResult, error: { message: string, error: string } } [];
    currentlyUpdated: string[];
    isBulk: boolean;
}

interface EntityManagementState {
    addEntitiesStatus: Record<string, BatchAddEntitiesStatus>;
    updateEntitiesStatus: Record<string, BatchUpdateEntitiesStatus>;
    patchEntitiesStatus: Record<string, PatchEntitiesStatus>;
}

const { instantiateState, createMutationsFactories } = createState<EntityManagementState>(() => ({
    addEntitiesStatus: {},
    updateEntitiesStatus: {},
    patchEntitiesStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startAddingEntities(payload: { rpName: string, count: number }) {
        this.addEntitiesStatus = {
            ...this.addEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                totalCount: payload.count,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    failAddingEntities(payload: { rpName: string }) {
        this.addEntitiesStatus = {
            ...this.addEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                totalCount: this.addEntitiesStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: this.addEntitiesStatus[payload.rpName]?.totalCount || 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    progressAddingEntities(payload: {
        rpName: string;
        progress: number;
    }) {
        this.addEntitiesStatus = {
            ...this.addEntitiesStatus,
            [payload.rpName]: {
                ...this.addEntitiesStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishAddingEntities(payload: {
        rpName: string;
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: Entity[];
        failures: AddEntitiesFailure[];
    }) {
        this.addEntitiesStatus = {
            ...this.addEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                successOnRequestCount: payload.successOnRequestCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                failures: payload.failures,
                successes: payload.successes,
            },
        };
    },
    clearAddEntitiesStatus(rpName: string) {
        this.addEntitiesStatus = omit(this.addEntitiesStatus, [ rpName ]);
    },
    startUpdateEntities(payload: { rpName: string, count: number }) {
        this.updateEntitiesStatus = {
            ...this.updateEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                totalCount: payload.count,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                successes: [],
                failures: [],
            },
        };
    },
    failUpdateEntities(payload: { rpName: string }) {
        this.updateEntitiesStatus = {
            ...this.updateEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                totalCount: this.updateEntitiesStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: this.updateEntitiesStatus[payload.rpName]?.totalCount || 0,
                successes: [],
                failures: [],
            },
        };
    },
    progressUpdateEntities(payload: {
        rpName: string;
        progress: number;
    }) {
        this.updateEntitiesStatus = {
            ...this.updateEntitiesStatus,
            [payload.rpName]: {
                ...this.updateEntitiesStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishUpdateEntities(payload: {
        rpName: string;
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: Entity[];
        failures: (Entity | EntityInputApiModel)[];
    }) {
        this.updateEntitiesStatus = {
            ...this.updateEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                successOnRequestCount: payload.successOnRequestCount,
                successes: payload.successes,
                failures: payload.failures,
            },
        };
    },
    clearUpdateEntitiesStatus(rpName: string) {
        this.updateEntitiesStatus = omit(this.updateEntitiesStatus, [ rpName ]);
    },
    startPatchEntities(payload: { rpName: string, entitiesUpdate: EntityPatch[], isBulk: boolean }) {
        this.patchEntitiesStatus = {
            ...this.patchEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                successCount: 0,
                failureCount: 0,
                totalCount: payload.entitiesUpdate.length,
                successes: [],
                failures: [],
                currentlyUpdated: payload.entitiesUpdate.map((patch) => patch.id),
                isBulk: payload.isBulk,
            },
        };
    },
    failPatchEntities(payload: {
        rpName: string;
        successCount: number;
        failureCount: number;
        totalCount: number;
        successes: EntityPatchResult[];
        failures: { element: EntityPatchResult, error: { message: string, error: string } }[];
    }) {
        this.patchEntitiesStatus = {
            ...this.patchEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                totalCount: payload.totalCount,
                successes: payload.successes,
                failures: payload.failures,
                currentlyUpdated: [],
                isBulk: false,
            },
        };
    },
    finishPatchEntities(payload: {
        rpName: string;
        successCount: number;
        failureCount: number;
        totalCount: number;
        successes: EntityPatchResult[];
        failures: { element: EntityPatchResult, error: { message: string, error: string } }[];
    }) {
        this.patchEntitiesStatus = {
            ...this.patchEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                totalCount: payload.totalCount,
                successes: payload.successes,
                failures: payload.failures,
                currentlyUpdated: [],
                isBulk: false,
            },
        };
    },
    clearPatchEntitiesStatus(rpName: string) {
        this.patchEntitiesStatus = omit(this.patchEntitiesStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: EntityManagementRequirement) => ({

    async addEntities(payload: {
        rpName: string; entities: EntityInput[];
    }) {
        const { rpName } = payload;
        const entities = payload.entities.map(convertEntityInputViewToData);
        try {
            this.mutations.startAddingEntities({ rpName, count: entities.length });
            const updateProgress = (progress: number) => {
                this.mutations.progressAddingEntities({ rpName, progress });
            };
            const batchResults = await rpweb.batchCreateTprmEntities(rpName, entities, 50, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = entities.length - successOnRequestCount;
            if (entities.length > 0 && failedOnRequestCount === entities.length) {
                this.mutations.failAddingEntities({ rpName });
            } else {
                this.mutations.finishAddingEntities({
                    rpName,
                    ...results,
                    totalCount: entities?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
            }
        } catch (error) {
            console.warn('Add entities error', error);
            this.mutations.failAddingEntities({ rpName });
        }
    },
    async updateEntities(payload: {
        rpName: string;
        entities: EntityInput[];
        updateEntitiesListState?: boolean;
    }) {
        const { rpName } = payload;
        const entities = payload.entities.map(convertEntityInputViewToData);
        try {
            this.mutations.startUpdateEntities({ rpName, count: entities.length });
            const updateProgress = (progress: number) => {
                this.mutations.progressUpdateEntities({ rpName, progress });
            };
            const batchResults = await rpweb.batchUpsertTprmEntities(rpName, entities, 50, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = (entities?.length - results.totalCount) || 0;
            if (entities.length > 0 && failedOnRequestCount === entities.length) {
                this.mutations.failUpdateEntities({ rpName });
            } else {
                this.mutations.finishUpdateEntities({
                    rpName,
                    ...results,
                    totalCount: entities?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
                if (payload.updateEntitiesListState) {
                    await dashboard.getActions(this).updateEntitiesInList({ rpName, entities: results.successes });
                }
            }
        } catch (error) {
            console.warn('Update entities error', error);
            this.mutations.failUpdateEntities({ rpName });
        }
    },
    clearAddEntitiesStatus(payload: { rpName: string }) {
        this.mutations.clearAddEntitiesStatus(payload.rpName);
    },
    clearUpdateEntitiesStatus(payload: { rpName: string }) {
        this.mutations.clearUpdateEntitiesStatus(payload.rpName);
    },
    clearPatchEntitiesStatus(payload: { rpName: string }) {
        this.mutations.clearPatchEntitiesStatus(payload.rpName);
    },
    async patchEntities(payload: {
        rpName: string;
        patch: EntityPatch[];
        isBulk: boolean;
    }) {
        const { rpName, patch, isBulk } = payload;
        try {
            this.mutations.startPatchEntities({ rpName, entitiesUpdate: patch, isBulk });
            const { successCount, failureCount, totalCount, successes, failures } =
                await rpweb.patchTprmEntity(rpName, patch);
            if (totalCount === failureCount) {
                this.mutations.failPatchEntities(
                    { rpName, successCount, failureCount, totalCount, successes, failures },
                );
            } else {
                this.mutations.finishPatchEntities({
                    rpName,
                    successCount,
                    failureCount,
                    totalCount,
                    successes,
                    failures,
                });
                await dashboard.getActions(this).patchEntitiesInList(
                    { rpName: payload.rpName, entitiesPatch: successes });
            }
        } catch (error) {
            console.warn('Patch entities error', error);
            this.mutations.failPatchEntities({
                rpName,
                successCount: 0,
                failureCount: patch.length,
                totalCount: patch.length,
                successes: [],
                failures: [],
            });
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
