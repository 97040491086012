import { JsonSchemaFormStrings } from '../components/JsonSchemaForm';
import { defaultStrings } from '../components/JsonSchemaForm/defaultStrings';
import getIn from 'lodash/get';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';

export function translate({
    phrase,
    data = {},
    schema,
    strings = {},
}: {
    phrase: keyof JsonSchemaFormStrings;
    data?: any;
    schema: JsonSchema;
    strings?: Partial<JsonSchemaFormStrings>;
}): string {
    const schemaStrings = schema.strings || {};
    const finalData = { ...data, schema };
    const baseString = schemaStrings[phrase] || strings[phrase] || defaultStrings[phrase] || phrase;
    return baseString.replace(/{([^}]+)}/g, (_: string, key: string) => `${getIn(finalData, key)}`);
}
