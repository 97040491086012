<template>
    <form class="EntityFiltersPopover" @submit.prevent="applyFilters">
        <div class="EntityFiltersPopover__headerBar">
            <div class="EntityFiltersPopover__headerText">
                Advanced Filters
            </div>
            <div class="EntityFiltersPopover__clearButton" @click="clearFilters">
                CLEAR
            </div>
        </div>
        <div ref="scrollContainer" class="EntityFiltersPopover__filterSection">
            <PopoverStandardFilters
                :filters="standardFilters"
                :risk-profiles="riskProfiles"
                :effective-risk-profiles="effectiveRiskProfiles"
                @input="standardFiltersChanged"
                @scrollTo="scrollTo"
            />
            <PopoverCustomPropertyFilters
                v-if="customProperties.length > 0"
                :filters="customPropertyFilters"
                :custom-properties="customProperties"
                @input="customPropertyFiltersChanged"
                @scroll-to="scrollTo"
            />
            <PopoverCollateralFilters
                v-if="collateralCustomPropertyKey !== '' && customPropertyCollaterals"
                :filters="collateralFieldFilters"
                :collateral-custom-property-key="collateralCustomPropertyKey"
                :custom-property-collaterals="customPropertyCollaterals"
                @scroll-to="scrollTo"
                @input="entityCollateralFieldFiltersChanged"
            />
        </div>
        <div class="EntityFiltersPopover__footerBar">
            <div class="EntityFiltersPopover__cancelButton" @click="$emit('close')">
                Cancel
            </div>
            <Button
                class="EntityFiltersPopover__applyButton"
                type="primary"
                submit
                :disabled="disableApply"
            >
                Apply
            </Button>
        </div>
    </form>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import isEqual from 'lodash/isEqual';
    import omit from 'lodash/omit';
    import pickBy from 'lodash/pickBy';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import {
        EntityFilters,
        EntityStandardFilters,
    } from '@/modules/entity-filtering/types';
    import PopoverStandardFilters
        from '@/modules/entity-filtering/components/EntityFiltersPopover/PopoverStandardFilters.vue';
    import PopoverCustomPropertyFilters
        from '@/modules/entity-filtering/components/EntityFiltersPopover/PopoverCustomPropertyFilters.vue';
    import PopoverCollateralFilters
        from '@/modules/entity-filtering/components/EntityFiltersPopover/PopoverCollateralFilters/PopoverCollateralFilters.vue';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        RiskProfile,
    } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';
    import {
        EffectiveRiskProfiles,
    } from '@evidentid/tprm-portal-lib/models/decisioning/EffectiveRiskProfiles.model';

    export default Vue.extend({
        name: 'EntityFiltersPopover',
        components: {
            PopoverStandardFilters,
            PopoverCustomPropertyFilters,
            Button,
            PopoverCollateralFilters,
        },
        props: {
            filters: {
                type: Object as PropType<EntityFilters>,
                default: () => ({}) as EntityFilters,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
            riskProfiles: {
                type: Array as PropType<RiskProfile[]>,
                default: () => [] as RiskProfile[],
            },
            effectiveRiskProfiles: {
                type: Array as PropType<EffectiveRiskProfiles[]>,
                default: () => [] as EffectiveRiskProfiles[],
            },
            collateralCustomPropertyKey: {
                type: String as PropType<string>,
                default: '',
            },
            customPropertyCollaterals: {
                type: Object as PropType<CustomProperty|null|undefined>,
                default: undefined,
            },
        },
        data() {
            return {
                standardFilters: {} as EntityStandardFilters,
                customPropertyFilters: {} as Record<string, string>,
                collateralFieldFilters: {} as Record<string, string>,
            };
        },
        computed: {
            currentFilters(): EntityFilters {
                const nonEmptyEntityFilters = pickBy(this.customPropertyFilters, Boolean);
                const nonEmptyCollateralFilters = pickBy(this.collateralFieldFilters, Boolean);
                const filters = { ...this.standardFilters };

                if (Object.keys(nonEmptyEntityFilters).length > 0) {
                    Object.assign(filters, { customPropertyFilters: nonEmptyEntityFilters });
                }

                if (Object.keys(nonEmptyCollateralFilters).length > 0) {
                    Object.assign(filters, { collateralFieldFilters: nonEmptyCollateralFilters });
                }

                return filters;
            },
            disableApply(): boolean {
                return isEqual(this.filters, this.currentFilters);
            },
        },
        watch: {
            filters: {
                immediate: true,
                handler(newValue: EntityFilters) {
                    this.standardFilters = omit(newValue, [ 'customPropertyFilters', 'collateralFieldFilters' ]);
                    this.customPropertyFilters = newValue.customPropertyFilters || {};
                    this.collateralFieldFilters = newValue.collateralFieldFilters || {};
                },
            },
        },
        methods: {
            clearFilters(): void {
                this.standardFilters = {};
                this.customPropertyFilters = {};
                this.collateralFieldFilters = {};
            },
            standardFiltersChanged(standardFilters: EntityStandardFilters): void {
                this.standardFilters = { ...standardFilters };
            },
            customPropertyFiltersChanged(customPropertyFilters: Record<string, string>): void {
                this.customPropertyFilters = { ...customPropertyFilters };
            },
            entityCollateralFieldFiltersChanged(entityCollateralFieldFilters: Record<string, string>): void {
                this.collateralFieldFilters = entityCollateralFieldFilters;
            },
            applyFilters(): void {
                this.$emit('submit', this.currentFilters);
            },
            scrollTo(scrollTo: number): void {
                if (this.$refs.scrollContainer) {
                    (this.$refs.scrollContainer as HTMLDivElement).scrollTop = scrollTo;
                }
            },
        },
    });
</script>
