export default function trackOnlineStatusInSentry(
    scope: { addBreadcrumb(breadcrumb: any): void },
    space: { addEventListener(name: string, fn: (...args: any) => any): void } = window,
): void {
    space.addEventListener('beforeunload', () => {
        scope.addBreadcrumb({
            level: 'info',
            category: 'connection',
            message: 'beforeunload: Page has been refreshed',
        });
    });

    space.addEventListener('online', () => {
        scope.addBreadcrumb({
            level: 'info',
            category: 'connection',
            message: 'online: User get back internet connection',
        });
    });

    space.addEventListener('offline', () => {
        scope.addBreadcrumb({
            level: 'info',
            category: 'connection',
            message: 'offline: User lost internet connection',
        });
    });
}
