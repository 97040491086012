import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';

export const isSubstitutableSchema = (schema: JsonSchema): boolean => {
    if (isArray(schema) && isObject(schema.items as JsonSchema)) {
        return isSubstitutableSchema(schema.items as JsonSchema);
    }
    return (isObject(schema) &&
            'if' in schema &&
            Object.keys(schema.properties).some((prop) => prop.includes('BasedOnInsuredField'))) ||
        (isObject(schema) && Object.values(schema.properties).some(isSubstitutableSchema));
};
