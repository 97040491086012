<template>
    <DashboardConfirmationModal
        :header-icon="faStopCircle"
        :header-text="headerText"
        :progress="saving"
        yes="Deactivate"
        no="Cancel"
        v-on="$listeners"
    >
        <div v-if="entities.length === 1">
            You are about to deactivate <span class="DeactivationConfirmationModal__deactivationDetails">{{ entities[0].contactEmail }}</span>. Please confirm you would like to deactivate this entity.
        </div>
        <div v-else-if="entities.length > 1">
            You are about to deactivate <span class="DeactivationConfirmationModal__deactivationDetails">{{ entities.length }} entities</span>. Please confirm you would like to deactivate these entities.
        </div>
    </DashboardConfirmationModal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';
    import { faStopCircle } from '@fortawesome/free-solid-svg-icons';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            DashboardConfirmationModal,
        },
    })
    export default class DeactivationConfirmationModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Array, default: () => [] })
        private entities!: Entity[];

        private faStopCircle = faStopCircle;

        private get headerText() {
            return `Are you sure you want to deactivate ${this.entities.length > 1 ? 'selected entities' : 'this entity'}?`;
        }
    }
</script>
