import { createStateFactory } from '@evidentid/vue-commons/store';
import { LocalStorageKeys } from '@/modules/local-storage/types';

const createState = createStateFactory<{}>();

export interface UserPreference {
    sidenavOpened: boolean;
}

interface LocalStorageState {
    userPreferences: Record<string, UserPreference>;
}

const { instantiateState, createMutationsFactories } = createState<LocalStorageState>(() => ({
    userPreferences: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    updatingUserPreference(payload: { email: string, preference: UserPreference }) {
        this.userPreferences = {
            ...this.userPreferences,
            [payload.email]: {
                ...payload.preference,
            },
        };
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(() => ({
    loadUserPreference(payload: { email: string }) {
        const { email } = payload;
        const preferencesString = window.localStorage.getItem(LocalStorageKeys.userPreferences);
        const preferences = preferencesString ? JSON.parse(preferencesString) : null;
        const preference = email && preferences ? preferences[email] : {};
        this.mutations.updatingUserPreference({ email, preference });
    },
    saveUserPreference(payload: { email: string, preference: UserPreference }) {
        const { email, preference } = payload;
        this.mutations.updatingUserPreference({ email, preference });
        window.localStorage.setItem(LocalStorageKeys.userPreferences, JSON.stringify(this.state.userPreferences || {}));
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
