<template>
    <div class="SetRemindersRow">
        <div
            class="SetRemindersRow__description"
            :class="{
                'SetRemindersRow__description--small': smallDescription,
            }"
        >
            {{ label }}
        </div>
        <div class="SetRemindersRow__content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
    .SetRemindersRow {
        @apply border-solid border-0 border-b-[1px] last-of-type:border-b-0 border-eidSecureBlue-25;

        display: flex;
        align-items: baseline;
        margin: 15px 0;
        padding-bottom: 20px;

        &__description {
            @apply text-eidDarkBlue-75 text-base;

            margin: 0 0 15px;

            &--small {
                font-size: 1em;
            }
        }

        & > &__description {
            margin: 0 30px 0 0;
        }

        &__content {
            flex: 1 auto;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class SetRemindersRow extends Vue {
        @Prop({ type: Boolean, default: false })
        private smallDescription!: boolean;

        @Prop({ type: String, default: '' })
        private label!: string;
    }
</script>
