import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    TPRM_FIELD_ORDER_MAP,
    TPRM_MULTI_FIELD_CRITERION_FIELD_ORDER_MAP,
} from '@/static-data/entity-details/tprmFieldOrders.constants';

export function getFieldOrderByRequirementType(requirementType: TprmRequirementType): string[] {
    return TPRM_FIELD_ORDER_MAP[requirementType] || [];
}

export function getMultiFieldsOrderByRequirementType(
    requirementType: TprmRequirementType
): Record<string, string[]> | {} {
    return TPRM_MULTI_FIELD_CRITERION_FIELD_ORDER_MAP[requirementType] || {};
}
