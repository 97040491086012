import Logger from '@evidentid/logger';
import Tracker from '@evidentid/tracker';
import { createModuleBuilder } from '../../app';
import AppSnackbar from './components/AppSnackbar.vue';
import vuex from './vuex';

export function createSnackbarModule() {
    return createModuleBuilder()
        .registerVuex({ snackbar: vuex.instantiateModule })
        .registerTopLevelComponent(AppSnackbar)

        // Track events
        .demandContext<{ tracker?: Tracker<any>, logger?: Logger<any> }>()
        .execute((app) => {
            app.observer.onMutation('setSnackbarContent', (content) => {
                const message = content?.message || null;
                const success = Boolean(content?.success);
                if (app.tracker) {
                    app.tracker.track({
                        name: message ? 'Snackbar opened' : 'Snackbar closed',
                        description: message ? `Snackbar opened (${success ? 'success' : 'error'}): ${message}` : 'Snackbar closed',
                        message,
                        success,
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'snackbar',
                        message: message ? `Snackbar opened: ${message}` : 'Snackbar closed',
                        data: { success },
                    });
                }
            });
        })

        .end();
}

export const getSnackbarActions = vuex.getActions;
export type SnackbarModule = ReturnType<typeof createSnackbarModule>;
