<template>
    <form class="EidJsonSchemaForm">
        <template v-for="[propKey, propSchema ] in rootProperties">
            <component
                :is="rootPropertiesWrapper"
                v-if="rootPropertiesWrapper"
                :key="propKey"
                :schema="propSchema"
            >
                <component
                    :is="getFormElementComponent({
                        customComponentInput,
                        valuePath: getPath(propKey),
                        schema: propSchema
                    })"
                    :custom-component-input="customComponentInput"
                    :schema="propSchema"
                    :value="formValue[propKey]"
                    :initial-value="initialValue[propKey]"
                    :default-value="defaultValue[propKey]"
                    :value-path="getPath(propKey)"
                    :touched="touched"
                    :schema-path="getSchemaPath(propKey)"
                    @input="onInput(propKey, $event)"
                />
            </component>
            <component
                :is="getFormElementComponent({
                    customComponentInput,
                    valuePath: getPath(propKey),
                    schema: propSchema
                })"
                v-else
                :key="propKey"
                :custom-component-input="customComponentInput"
                :schema="propSchema"
                :value="formValue[propKey]"
                :initial-value="initialValue[propKey]"
                :default-value="defaultValue[propKey]"
                :value-path="getPath(propKey)"
                :schema-path="getSchemaPath(propKey)"
                :touched="touched"
                @input="onInput(propKey, $event)"
            />
        </template>
    </form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Provide, ProvideReactive, Watch } from 'vue-property-decorator';
    import { PropType } from 'vue';
    import cloneDeep from 'lodash/cloneDeep';
    import { createValueValidatorAllErrors } from '@evidentid/json-schema/createValueValidator';
    import JsonSchema, { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import {
        formValueInjectKey,
        formErrorsInjectKey,
        setSchemaVisibleInjectKey,
    } from './jsonSchemaProvideInject';
    import {
        EidJsonSchemaFormCustomComponentInput,
        FormErrors,
    } from './types';
    import { getFormElementComponent } from './formElementComponent';

    @Component({})
    export default class EidJsonSchemaForm extends Vue {
        @Prop({ type: Object as PropType<JsonSchemaObject>, required: true })
        private schema!: JsonSchemaObject;

        @Prop({ type: Object as PropType<Record<string, any>>, default: () => ({}) })
        private initialValue!: Record<string, any>;

        @Prop({ type: Object as PropType<Record<string, any>>, default: () => ({}) })
        private defaultValue!: Record<string, any>;

        @Prop({ type: [ Object, Function ] as PropType<EidJsonSchemaFormCustomComponentInput> })
        private customComponentInput?: EidJsonSchemaFormCustomComponentInput;

        @Prop({ type: [ Object, Function ] as PropType<Vue.Component | Vue.AsyncComponent> })
        private rootPropertiesWrapper?: Vue.Component | Vue.AsyncComponent;

        @Prop({ type: Boolean, default: false })
        private touched!: boolean;

        @Prop({ type: Boolean, default: false })
        private validateOnMount!: boolean;

        @ProvideReactive(formValueInjectKey)
        private formValue = cloneDeep(this.initialValue);

        @ProvideReactive(formErrorsInjectKey)
        private formErrors?: FormErrors = null;

        private visibleSchemas: string[] = [];

        private avjSchema = createValueValidatorAllErrors(this.schema);

        @Provide(setSchemaVisibleInjectKey)
        private setSchemaVisible(schemaPath: string, visible: boolean): void {
            if (visible) {
                this.visibleSchemas = [ ...this.visibleSchemas, schemaPath ];
            } else {
                this.visibleSchemas = this.visibleSchemas.filter((path) => path !== schemaPath);
            }
        }

        private validate(): void {
            this.avjSchema(this.formValue);
            this.formErrors = this.avjSchema.errors;
        }

        private get rootProperties(): [ string, JsonSchema ][] {
            return Object.entries(this.schema.properties || {});
        }

        private onInput(propKey: string, value: any) {
            this.formValue = { ...this.formValue, [propKey]: value };
            this.validate();
            const propErrors = this.formErrors?.filter((error) => error.dataPath === this.getPath(propKey)) || [];
            this.$emit('input', {
                formValue: this.formValue,
                formErrors: this.formErrors,
                propValue: value,
                propKey,
                propErrors,
            });
        }

        private getPath(propKey: string): string {
            return [ '', propKey ].join('.');
        }

        private getSchemaPath(propKey: string): string {
            return [ '#', 'properties', propKey ].join('/');
        }

        private getFormElementComponent = getFormElementComponent;

        @Watch('visibleSchemas')
        private onVisibleSchemasChange(newValue: string[]): void {
            this.$emit('update:visible-schemas', newValue);
        }

        private beforeMount(): void {
            if (this.validateOnMount) {
                this.validate();
            }
        }
    }
</script>
