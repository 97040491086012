import _Vue from 'vue';
import * as original from 'vue-property-decorator';

export const Component = original.Component;
export const Mixins = original.Mixins;
export const Emit = original.Emit;
export const Inject = original.Inject;
export const InjectReactive = original.InjectReactive;
export const Model = original.Model;
export const ModelSync = original.ModelSync;
export const Prop = original.Prop;
export const Provide = original.Provide;
export const ProvideReactive = original.ProvideReactive;
export const Ref = original.Ref;
export const VModel = original.VModel;
export const Watch = original.Watch;

// eslint-disable-next-line
export const Vue = _Vue;
