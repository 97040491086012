import { SESSION_KEY_CHAT_OPEN_STATE } from './constants';
import { getSessionData, setSessionData } from './getSetSessionData';

export function getChatOpenStatus(): boolean {
    const data = getSessionData(SESSION_KEY_CHAT_OPEN_STATE);
    return data ? JSON.parse(data) : false;
}

export function setChatOpenStatus(isOpen: boolean): void {
    return setSessionData(SESSION_KEY_CHAT_OPEN_STATE, isOpen);
}
