var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"EidJsonSchemaForm"},[_vm._l((_vm.rootProperties),function(ref){
var propKey = ref[0];
var propSchema = ref[1];
return [(_vm.rootPropertiesWrapper)?_c(_vm.rootPropertiesWrapper,{key:propKey,tag:"component",attrs:{"schema":propSchema}},[_c(_vm.getFormElementComponent({
                    customComponentInput: _vm.customComponentInput,
                    valuePath: _vm.getPath(propKey),
                    schema: propSchema
                }),{tag:"component",attrs:{"custom-component-input":_vm.customComponentInput,"schema":propSchema,"value":_vm.formValue[propKey],"initial-value":_vm.initialValue[propKey],"default-value":_vm.defaultValue[propKey],"value-path":_vm.getPath(propKey),"touched":_vm.touched,"schema-path":_vm.getSchemaPath(propKey)},on:{"input":function($event){return _vm.onInput(propKey, $event)}}})],1):_c(_vm.getFormElementComponent({
                customComponentInput: _vm.customComponentInput,
                valuePath: _vm.getPath(propKey),
                schema: propSchema
            }),{key:propKey,tag:"component",attrs:{"custom-component-input":_vm.customComponentInput,"schema":propSchema,"value":_vm.formValue[propKey],"initial-value":_vm.initialValue[propKey],"default-value":_vm.defaultValue[propKey],"value-path":_vm.getPath(propKey),"schema-path":_vm.getSchemaPath(propKey),"touched":_vm.touched},on:{"input":function($event){return _vm.onInput(propKey, $event)}}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }