import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faPaperPlaneTop = {
    prefix: 'fa',
    iconName: 'faPaperPlaneTop',
    icon: [
        15,
        15,
        [],
        'e002',
        'M15.4141 6.52539C15.7656 6.67188 15.9707 7.02344 16 7.375C16 7.75586 15.7656 8.10742 15.4141 8.25391L2.28906 13.8789C2.17188 13.9375 2.05469 13.9668 1.9375 13.9668C1.67383 13.9668 1.41016 13.8496 1.23438 13.6445C0.970703 13.3809 0.912109 12.9414 1.08789 12.5898L3.8125 7.375L1.08789 2.18945C0.912109 1.83789 0.970703 1.39844 1.23438 1.10547C1.41016 0.900391 1.67383 0.8125 1.9375 0.8125C2.05469 0.8125 2.17188 0.841797 2.28906 0.900391L15.4141 6.52539ZM2.93359 2.7168L5.01367 6.67188H12.2207L2.93359 2.7168ZM5.01367 8.07812L2.93359 12.0625L12.2207 8.07812H5.01367Z',
    ],
} as any as IconDefinition;
