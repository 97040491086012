<template>
    <Accord25Cell v-if="limits.length > 0" class="PolicyInfoOtherCellsInfoLimit" title="LIMITS">
        <div class="p-3.5">
            <div
                v-for="limit in limits"
                :key="limit.name"
                class="grid grid-cols-[40%_20%_40%] pb-2.5 items-center"
                :data-test-id="`PolicyInfoOtherCellsInfoLimit__${limit.name}Limit`"
            >
                <div class="flex items-center" data-test-id="PolicyInfoOtherCellsInfoLimit__limitName">
                    <img
                        v-if="limit.evaluationError && limit.evaluationError.isMultiFieldCriterion"
                        class="pl-1.5"
                        :src="foldersIcon"
                    >
                    <span class="text-left">{{ formatAttributeName(limit.name) }}</span>
                </div>
                <span
                    class="pl-2.5"
                    :class="getValueClass(limit.evaluationError)"
                    data-test-id="PolicyInfoOtherCellsInfoLimit__limitValue"
                >
                    {{ limit.value }}
                </span>
                <EvaluationError
                    v-if="limit.evaluationError && !limit.evaluationError.isMultiFieldCriterion"
                    class="text-xxs"
                    data-test-id="PolicyInfoOtherCellsInfoLimit__limitError"
                    :evaluation-error="limit.evaluationError"
                    @goToCriterion="goToCriterion"
                    @grantExceptionToCriterion="grantExceptionToCriterion"
                    @removeExceptions="removeExceptions"
                />
            </div>
        </div>
    </Accord25Cell>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import startCase from 'lodash/startCase';
    import Accord25Cell from '@/modules/entity-details/components/Accord25Cell/Accord25Cell.vue';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

    type Limits = {
        name: string;
        value: number | string | unknown;
        evaluationError: FieldEvaluationResultError | null;
    }[];

    export default Vue.extend({
        name: 'PolicyInfoOtherCellsInfoLimit',
        components: {
            Accord25Cell,
            EvaluationError,
        },
        props: {
            limits: {
                type: Array as PropType<Limits>,
                default: () => [] as Limits,
            },
        },
        methods: {
            goToCriterion(riskProfileId: string): void {
                this.$emit('go-to-criterion', riskProfileId);
            },
            grantExceptionToCriterion(criterionId: string): void {
                this.$emit('grant-exception-to-criterion', criterionId);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
            formatAttributeName(name: string): string {
                return startCase(name);
            },
            getValueClass(evaluationError: FieldEvaluationResultError): string | null {
                if (!evaluationError || evaluationError.isMultiFieldCriterion) {
                    return null;
                }
                return (
                    evaluationError?.complianceStatusMetadata.length > 0
                        ? 'PolicyRecord--exception'
                        : 'PolicyRecord--invalidValue'
                );
            },
        },
    });
</script>
