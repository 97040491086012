<template>
    <div class="RiskProfileAssignment">
        <div class="RiskProfileAssignment__title">
            <strong>Assignment</strong>
            <div
                v-if="showError && showEmptyError"
                class="RiskProfileAssignment__error"
            >
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span>
                    At least one rule needs to be added
                </span>
            </div>
        </div>
        <div class="RiskProfileAssignment__body">
            <div class="RiskProfileAssignment__radioOptions">
                <span class="RiskProfileAssignment__radioOptionsPrefix">
                    Assign
                </span>
                <RadioOption
                    class="RiskProfileAssignment__radioOption"
                    name="RiskProfileAssignment"
                    :checked="assignmentOption === allInsureds"
                    @click="$emit('setAssignmentRuleOption', allInsureds)"
                >
                    All Entities
                </RadioOption>
                <RadioOption
                    class="RiskProfileAssignment__radioOption"
                    name="RiskProfileAssignment"
                    :checked="assignmentOption === subsetOfInsureds"
                    @click="$emit('setAssignmentRuleOption', subsetOfInsureds)"
                >
                    Select Entities
                </RadioOption>
            </div>
            <AssignmentRuleView
                v-if="assignmentOption === subsetOfInsureds"
                :custom-properties="customProperties"
                :basic-rules="rules"
                @openRuleEditor="$emit('openRuleEditor')"
                @remove="$emit('remove')"
            />
            <Button
                v-if="assignmentOption === subsetOfInsureds && rules.length === 0"
                class="RiskProfileAssignment__addButton"
                @click="$emit('openRuleEditor')"
            >
                + Add Assignment Rule
            </Button>
            <span
                v-else-if="assignmentOption === allInsureds"
                class="RiskProfileAssignment__message"
            >
                All entities will be auto assigned.
            </span>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import AssignmentRuleView
        from '@/modules/decisioning-criteria/components/AssignmentRuleView/AssignmentRuleView.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { BasicAssignmentRule, RiskProfileAssignmentOptions } from '@/modules/decisioning-criteria/types';
    import { convertToBasicAssignmentRules } from '@/modules/decisioning-criteria/utils/parseRulesLogic';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { AssignmentRuleView, Button, FontAwesomeIcon, RadioOption },
    })
    export default class RiskProfileAssignment extends Vue {
        @Prop({ type: Object, default: null })
        private riskProfileAssignment!: any;

        @Prop({ type: String, default: RiskProfileAssignmentOptions.allInsureds })
        private assignmentOption!: string;

        @Prop({ type: Boolean, default: false })
        private showError!: boolean;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];
        private faExclamationTriangle = faExclamationTriangle;

        private get rules(): BasicAssignmentRule[][] {
            return this.riskProfileAssignment
                ? convertToBasicAssignmentRules(this.riskProfileAssignment)
                : [];
        }

        private get showEmptyError(): boolean {
            return this.assignmentOption === RiskProfileAssignmentOptions.subsetOfInsureds && this.rules.length === 0;
        }

        private allInsureds: string = RiskProfileAssignmentOptions.allInsureds;
        private subsetOfInsureds: string = RiskProfileAssignmentOptions.subsetOfInsureds;
    }
</script>
