<template>
    <div class="BinaryDataDisplay" :class="`BinaryDataDisplay--${type}`">
        <img v-if="type === 'image'" alt="" class="BinaryDataDisplay__image" :src="url" />
        <iframe v-else class="BinaryDataDisplay__frame" :src="`${url}#view=FitH`" />
    </div>
</template>

<style lang="scss">
    .BinaryDataDisplay {
        display: flex;
        align-items: center;
        justify-content: center;

        &__image {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }

        &__frame {
            display: block;
            width: 100%;
            border: 0;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';

    export interface BinaryData {
        $objectType: 'BinaryData';
        data: string;
        // eslint-disable-next-line camelcase
        mime_type: string;
        metadata: any;
    }

    @Component
    export default class BinaryDataDisplay extends Vue {
        @Prop({ type: Object })
        private value!: BinaryData;

        private type: string = 'image';
        private url: string | null = null;

        @Watch('value', { immediate: true })
        private onBinaryDataChange(): void {
            // Clear remaining data
            this.clearBlob();

            // Compute document type
            this.type = /^image\//.test(this.value.mime_type) ? 'image' : 'other';

            // Build object URL
            const data = new Uint8Array([ ...atob(this.value.data) ].map((char) => char.charCodeAt(0)));
            const blob = new Blob([ data ], { type: this.value.mime_type });
            this.url = URL.createObjectURL(blob);
        }

        private destroyed(): void {
            this.clearBlob();
        }

        private clearBlob(): void {
            if (this.url !== null) {
                URL.revokeObjectURL(this.url);
                this.url = null;
            }
        }
    }
</script>
