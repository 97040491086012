<template>
    <v-component
        :is="component"
        :template="template"
        :criterion="criterion"
        :custom-properties="customProperties"
        :load-criterion-message="loadCriterionMessage"
        @input="$emit('input', $event)"
        @reference-accessed="$emit('reference-accessed', $event)"
        @controller-value-update="$emit('controller-value-update', $event)"
        @update:visible-schemas="$emit('update:visible-schemas', $event)"
    />
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import EidCriterion
        from '@/modules/decisioning-criteria/components/EidCriterion/EidCriterion.vue';
    import PublicProductsLiabilityCriterion
        from '@/modules/decisioning-criteria/components/PublicProductsLiabilityCriterion/PublicProductsLiabilityCriterion.vue';
    import { LoadCriterionMessage } from '@/modules/decisioning-criteria/models/CriterionSettingLoadMessageFunc.model';
    import {
        isNewJsonSchemaForm,
        isPublicOrProductsLiability,
    } from './helpers';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
    import RiskProfileCriterion from '@/modules/decisioning-criteria/components/RiskProfileCriterion/RiskProfileCriterion.vue';

    interface CriterionCompCondition {
        conditionFunction: Function;
        args: any[];
        component: Vue.Component;
    }

    export default Vue.extend({
        name: 'DynamicCriterionSetting',
        components: {
            PublicProductsLiabilityCriterion,
            RiskProfileCriterion,
            EidCriterion,
        },
        props: {
            criterion: {
                type: Object as PropType<Criterion | CriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<CriterionTemplate>,
                required: true,
            },
            loadCriterionMessage: {
                type: Function as PropType<LoadCriterionMessage>,
                required: true,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
        },
        computed: {
            rpName(): string {
                return this.$route.params.rpId;
            },
            component(): Vue.Component {
                return this.getComponent(this.criterionCompConditions);
            },
            criterionCompConditions(): CriterionCompCondition[] {
                return [
                    this.getCondition(isNewJsonSchemaForm, EidCriterion),
                    this.getCondition(isPublicOrProductsLiability, PublicProductsLiabilityCriterion),
                ];
            },
        },
        methods: {
            getCondition(
                conditionFunction: Function,
                component: Vue.Component,
                optionalArgs?: any[],
            ): CriterionCompCondition {
                const args = optionalArgs || [ this.template ];
                return { conditionFunction, args, component };
            },
            getComponent(criterionConditions: CriterionCompCondition[]): Vue.Component {
                for (const { conditionFunction, args, component } of criterionConditions) {
                    if (conditionFunction(...args)) {
                        return component;
                    }
                }
                return RiskProfileCriterion;
            },
        },
    });
</script>
