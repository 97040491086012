<template>
    <LoadingModal
        v-if="loading"
        @close="$emit('abort')"
    />
    <EditEmailTemplateModal
        v-else
        open
        :initial-value="initialValue"
        :submitting="submitting"
        :custom-properties="customProperties"
        @close="$emit('abort')"
        @submit="onSubmit"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import EditEmailTemplateModal from '../components/EditEmailTemplateModal.vue';
    import { EmailTemplate, NotificationsConfigurationState } from '../types';

    import {
        VerificationRequestType,
    } from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

    @Component({
        components: {
            LoadingModal,
            EditEmailTemplateModal,
        },
    })
    export default class EditEmailTemplateProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private type!: VerificationRequestType;

        private get state(): NotificationsConfigurationState {
            return this.$store.state.notificationsConfiguration;
        }

        private get initialValue(): EmailTemplate | null {
            return this.state.emailTemplates?.[this.rpName]?.[this.type] || null;
        }

        private get customProperties(): string[] {
            return this.state.insuredFields?.[this.rpName] || [];
        }

        private get loadStatus(): OperationStatus {
            return this.state.loadEmailTemplates?.[this.rpName] || OperationStatus.uninitialized;
        }

        private get loadCustomPropertiesStatus(): OperationStatus {
            return this.state.loadInsuredFields?.[this.rpName] || OperationStatus.uninitialized;
        }

        private get updateStatus(): OperationStatus {
            return this.state.updateEmailTemplates?.[this.rpName]?.[this.type] || OperationStatus.uninitialized;
        }

        private get loading(): boolean {
            return (
                [ OperationStatus.uninitialized, OperationStatus.loading ].includes(this.loadStatus) ||
                [ OperationStatus.uninitialized, OperationStatus.loading ].includes(this.loadCustomPropertiesStatus)
            );
        }

        private get submitting(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private async onSubmit(template: EmailTemplate): Promise<void> {
            await this.$store.actions.notificationsConfiguration.updateEmailTemplate({
                rpName: this.rpName,
                type: this.type,
                template,
            });

            if (this.updateStatus === OperationStatus.success) {
                await this.$store.actions.snackbar.displaySnackbar({
                    message: 'Email template has been updated successfully.',
                    success: true,
                });
                this.$emit('finish');
            }
        }

        private mounted(): void {
            this.$store.actions.notificationsConfiguration.loadEmailTemplates({ rpName: this.rpName });
            if ([ OperationStatus.uninitialized, OperationStatus.error ].includes(this.loadCustomPropertiesStatus)) {
                this.$store.actions.notificationsConfiguration.loadInsuredFieldIds({ rpName: this.rpName });
            }
        }
    }
</script>
