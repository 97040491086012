<template>
    <div class="CriterionObjectInput" :class="{'CriterionObjectInput--substitutable': customPropertySubstitutable}">
        <component
            :is="getFormElementComponent({
                customComponentInput,
                valuePath: getPath(key),
                schema: propSchema

            })"
            v-for="{ key, propSchema, thenOrElse } in schemasToRender"
            :id="getPath(key)"
            :key="key"
            :depth="depth + 1"
            :schema="propSchema"
            :value="value[key]"
            :initial-value="initialValue[key]"
            :default-value="defaultValue[key]"
            :value-path="getPath(key)"
            :required="isRequired(key)"
            :parent-schema-errors="getChildErrors(key)"
            :touched="touched"
            :hide-title="hideTitle"
            :show-error="showError"
            :custom-component-input="customComponentInput"
            :schema-path="getSchemaPath(key, thenOrElse)"
            @input="onInput(key, $event)"
            @change="onChange(key, $event)"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';
    import AbstractCriterionInput from './AbstractCriterionInput';
    import trashIcon from '@/assets/icons/bin-icon.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import JsonSchema, {
        JsonSchemaObject,
    } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { SchemaError } from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/types';
    import { getFormElementComponent } from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/formElementComponent';
    import CriterionSettingsRow
        from '@/modules/decisioning-criteria/components/CriterionSettingsRow/CriterionSettingsRow.vue';
    import {
        isSchemaCustomPropertySubstitutable,
    } from '@/modules/decisioning-criteria/utils/isSchemaCustomPropertySubstitutable';

    enum IfThenElse {
        THEN = 'THEN',
        ELSE = 'ELSE',
    }

    @Component({
        inheritAttrs: false,
        components: {
            EidIcon,
            CriterionSettingsRow,
        },
    })
    export default class CriterionObjectInput extends AbstractCriterionInput<JsonSchemaObject, Record<string, any>> {
        @Prop()
        protected disallowedValues: any;

        private trashIcon = trashIcon;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;
        private currentConditionalThenOrElse: IfThenElse | null = null;

        private get ifPropertiesEntries(): [string, JsonSchema][] {
            return Object.entries(this.schema.if?.properties || {});
        }

        private getCurrentConditionalThenOrElse(value?: Record<string, any>): IfThenElse | null {
            if (!this.ifPropertiesEntries.length) {
                return null;
            }
            const ifTrue = this.ifPropertiesEntries.every(([ key, ifPropValue ]) => {
                if ('const' in ifPropValue) {
                    return value?.[key] === ifPropValue.const;
                }
                if ('enum' in ifPropValue) {
                    return ifPropValue.enum.some((v) => v === value?.[key]);
                }
                throw new Error(`Unknown if properties conditions ${Object.keys(ifPropValue)}`);
            });
            return ifTrue ? IfThenElse.THEN : IfThenElse.ELSE;
        }

        private getConditionalSchema(conditionalThenOrElse: IfThenElse | null): Record<string, JsonSchema> | null {
            switch (conditionalThenOrElse) {
                case IfThenElse.THEN:
                    return this.schema.then?.properties || null;
                case IfThenElse.ELSE:
                    return this.schema.else?.properties || null;
                default:
                    return null;
            }
        }

        private get schemasToRender(): { key: string, propSchema: JsonSchema, thenOrElse: IfThenElse | null }[] {
            const conditionalSchema = this.getConditionalSchema(this.currentConditionalThenOrElse);
            if (this.schema.propertiesOrder && this.schema.propertiesOrder.length > 0) {
                return this.schema.propertiesOrder.map((key) => {
                    const propSchema = this.schema.properties[key] || conditionalSchema?.[key];
                    if (!propSchema) {
                        throw new Error(`Property ${key} not found in schema`);
                    }
                    return {
                        key,
                        propSchema,
                        thenOrElse: this.currentConditionalThenOrElse,
                    };
                });
            }

            let mainProps = Object.entries(this.schema.properties).map(([ key, propSchema ]) => ({
                key,
                propSchema,
                thenOrElse: null as IfThenElse | null,
            }));
            if (!this.customPropertySubstitutable) {
                mainProps = mainProps.filter(({ key }) => !key.includes('BasedOnInsuredField'));
            }
            if (conditionalSchema) {
                const conditionalProps = Object.entries(conditionalSchema).map(([ key, propSchema ]) => ({
                    key,
                    propSchema,
                    thenOrElse: this.currentConditionalThenOrElse,
                }));
                mainProps.push(...conditionalProps);
            }
            return mainProps;
        }

        private get customPropertySubstitutable(): boolean {
            return isSchemaCustomPropertySubstitutable(this.schema);
        }

        private getChildErrors(key: string): SchemaError[] {
            return this.schemaErrors?.filter((error) => error.dataPath === `${this.valuePath}`
                && 'missingProperty' in error.params
                && error.params?.missingProperty === key,
            ) || [];
        }

        private getPath(propKey: string): string {
            return [ this.valuePath, propKey ].join('.');
        }

        private getSchemaPath(propKey: string, ifThenElse?: IfThenElse): string {
            switch (ifThenElse) {
                case IfThenElse.THEN:
                    return [ this.schemaPath, 'then', 'properties', propKey ].join('/');
                case IfThenElse.ELSE:
                    return [ this.schemaPath, 'else', 'properties', propKey ].join('/');
                default:
                    return [ this.schemaPath, 'properties', propKey ].join('/');
            }
        }

        private isRequired(propKey: string): boolean {
            const thenElseSchema = this.currentConditionalThenOrElse === IfThenElse.THEN
                ? this.schema.then
                : this.schema.else;
            const theElseRequired = Boolean(thenElseSchema?.required?.includes(propKey));
            const mainRequired = Boolean(this.schema.required?.includes(propKey));
            return mainRequired || theElseRequired;
        }

        private onInput(property: string, value: any) {
            const newValue = { ...this.value, [property]: value };
            const isEmptyValue = value == null || value === '';
            if (isEmptyValue && !(property in (this.defaultValue || {}))) {
                delete newValue[property];
            }
            const oldCurrentConditionalThenOrElse = this.currentConditionalThenOrElse;
            const newCurrentConditionalThenOrElse = this.getCurrentConditionalThenOrElse(newValue);
            if (
                oldCurrentConditionalThenOrElse !== null
                && oldCurrentConditionalThenOrElse !== newCurrentConditionalThenOrElse
            ) {
                Object.entries(this.getConditionalSchema(oldCurrentConditionalThenOrElse) || {})
                    .forEach(([ key ]) => {
                        if (key in (this.defaultValue || {})) {
                            newValue[key] = this.defaultValue?.[key];
                        } else {
                            delete newValue[key];
                        }
                    });
            }
            this.$emit('input', newValue);
            if (oldCurrentConditionalThenOrElse !== newCurrentConditionalThenOrElse) {
                this.currentConditionalThenOrElse = newCurrentConditionalThenOrElse;
            }
        }

        private onChange(property: string, value: any) {
            this.$emit('change', { ...this.value, [property]: value });
        }

        private getFormElementComponent = getFormElementComponent;

        private beforeMount() {
            this.setSchemaVisible?.(this.schemaPath, true);
            const conditionalThenOrElse = this.getCurrentConditionalThenOrElse(this.value);
            if (conditionalThenOrElse) {
                this.currentConditionalThenOrElse = conditionalThenOrElse;
            }
        }

        private beforeDelete() {
            this.setSchemaVisible?.(this.schemaPath, false);
        }
    }
</script>
