import parseJsonConditionally from './parseJsonConditionally';

export type XhrError = Error & { code?: string, reason: string, response: any, xhrObj?: XMLHttpRequest };
export type XhrErrorFactory = (xhr?: XMLHttpRequest) => XhrError;

export default function createCustomXhrErrorFactory(reason: string, message: string): XhrErrorFactory {
    return (xhr?: XMLHttpRequest) => {
        const error = new Error(message) as XhrError;
        const response = error.response = parseJsonConditionally(xhr?.responseText);
        error.reason = reason;
        error.xhrObj = xhr;
        error.code = (response?.downstream_error?.error || response)?.error || response?.code;
        return error;
    };
}
