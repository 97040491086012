<template>
    <div class="InputZoneSelector" :class="{
        'InputZoneSelector--hide-input': shouldHide('input'),
        'InputZoneSelector--hide-hint': shouldHide('hint'),
        'InputZoneSelector--hide-required': shouldHide('required'),
        'InputZoneSelector--hide-label': shouldHide('label'),
        'InputZoneSelector--hide-content': shouldHide('content'),
        'InputZoneSelector--hide-error': shouldHide('error'),
        'InputZoneSelector--hide-action-buttons': shouldHide('actionButtons'),
        'InputZoneSelector--hide-icon': shouldHide('icon'),
        'InputZoneSelector--hide-clear-button': shouldHide('clearButton'),
    }">
        <slot></slot>
    </div>
</template>

<style lang="scss">
    .InputZoneSelector {
        display: inline;

        &--hide-input .FormInput__inputContainer,
        &--hide-hint .FormElement__labelHint,
        &--hide-required .FormElement__requiredIndicator,
        &--hide-label .FormElement__label,
        &--hide-content .FormInput__inputRow,
        &--hide-error .FormElement__errorMessage,
        &--hide-error .FormInput__errorMessage,
        &--hide-action-buttons .FormInput__actionContainer,
        &--hide-clear-button .JsonSchemaEnumForm__clear,
        &--hide-icon .FormInput__icon {
            // stylelint-disable-next-line declaration-no-important
            display: none !important;
        }

        &--hide-content,
        &--hide-input {
            .FormElement {
                // stylelint-disable-next-line declaration-no-important
                margin: 0 !important;
            }

            .FormInput__errorMessage {
                // stylelint-disable-next-line declaration-no-important
                padding: 0 !important;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class InputZoneSelector extends Vue {
        // By default all fields will be hidden, except the one flagged.
        // When "hide: true" will be passed, then it's opposite:
        // all by default will be visible, and flagged will be hidden.
        @Prop({ type: Boolean, default: false })
        private hide!: boolean;

        @Prop({ type: Boolean, default: false })
        private input!: boolean;

        @Prop({ type: Boolean, default: false })
        private hint!: boolean;

        @Prop({ type: Boolean, default: false })
        private required!: boolean;

        @Prop({ type: Boolean, default: false })
        private label!: boolean;

        @Prop({ type: Boolean, default: false })
        private content!: boolean;

        @Prop({ type: Boolean, default: false })
        private error!: boolean;

        @Prop({ type: Boolean, default: false })
        private actionButtons!: boolean;

        @Prop({ type: Boolean, default: false })
        private icon!: boolean;

        @Prop({ type: Boolean, default: false })
        private clearButton!: boolean;

        private shouldHide(key: keyof InputZoneSelector): boolean {
            return this.hide ? this[key] : !this[key];
        }
    }
</script>
