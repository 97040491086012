<template>
    <div>
        <FontAwesomeIcon
            v-if="loading"
            :icon="faSpinner"
            class="ActionsButton"
            spin
        />
        <Expandable v-else-if="entity.active" legacy-mode @open="$emit('actionsButtonClicked')">
            <template #button>
                <FontAwesomeIcon
                    :icon="faEllipsisH"
                    class="ActionsButton ActionsButton__clickable"
                />
            </template>

            <Menu spaced>
                <MenuLink
                    :icon="entity.paused ? faPlayCircle : faPauseCircle"
                    additional-class="ActionsButton__menuLink"
                    :label="entity.paused ? 'Unpause' : 'Pause'"
                    @click="changePauseState"
                />
                <MenuLink
                    :icon="faStopCircle"
                    additional-class="ActionsButton__menuLink"
                    label="Deactivate"
                    @click="deactivateEntity"
                />
                <MenuLink
                    v-if="showRemoveExceptionButton"
                    :icon="faSlidersH"
                    additional-class="ActionsButton__menuLink"
                    label="Remove exception"
                    @click="$emit('removeExceptions', [ exceptionOverride.id ])"
                />
                <MenuLink
                    v-else-if="showGrantExceptionButton"
                    @click="grantExceptionToEntity"
                >
                    <template #label>
                        <div class="ActionsButton__menuLink">
                            <span class="ActionsButton__label">
                                <FontAwesomeIcon :icon="faSlidersH" />
                                <div class="ActionsButton__menuTitle">
                                    Grant exception for entire entity
                                </div>
                            </span>
                            <div class="ActionsButton__menuBody">
                                <div> All non-compliant status will be waived as compliant. This can be undone.</div>
                            </div>
                        </div>
                    </template>
                </MenuLink>
                <MenuLink
                    :disabled="!shouldEnableDocumentRequests"
                    :icon="faPaperPlane"
                    additional-class="ActionsButton__menuLink"
                    label="Create a New Link"
                    @click="sendDocumentRequest"
                />
                <MenuLink
                    :disabled="isLoadingSubmissionLink || !hasSubmissionLink"
                    :icon="isLoadingSubmissionLink ? faSpinner : copyIcon"
                    :icon-props="{ spin: isLoadingSubmissionLink }"
                    additional-class="ActionsButton__menuLink"
                    label="Copy submission link to clipboard"
                    @click="copySubmissionLink"
                />
                <MenuLink
                    v-if="showViewActionReviewButton"
                    @click="navigateToActionReview"
                >
                    <template #label>
                        <div class="ActionsButton__menuLink">
                            <span class="ActionsButton__label">
                                <EidIcon :icon-src="actionReviewIcon" />
                                <span class="ActionsButton__menuTitle">
                                    View pending actions
                                </span>
                            </span>
                        </div>
                    </template>
                </MenuLink>
                <MenuLink
                    v-if="showViewMappingButton"
                    @click="openMappingAdjustmentModal"
                >
                    <template #label>
                        <div class="ActionsButton__menuLink">
                            <span class="ActionsButton__label">
                                <FontAwesomeIcon :icon="faShareNodes" />
                                <span class="ActionsButton__menuTitle">
                                    View mapping
                                </span>
                            </span>
                        </div>
                    </template>
                </MenuLink>
                <MenuLink
                    @click="openHistoricDocumentModal"
                >
                    <template #label>
                        <div class="ActionsButton__menuLink">
                            <span class="ActionsButton__label">
                                <FontAwesomeIcon :icon="faClockRotateLeft" />
                                <span class="ActionsButton__menuTitle">
                                    View submission history
                                </span>
                            </span>
                        </div>
                    </template>
                </MenuLink>
            </Menu>
        </Expandable>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import noop from 'lodash/noop';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faClockRotateLeft,
        faEllipsisH,
        faPauseCircle,
        faPlayCircle,
        faSlidersH,
        faSpinner,
        faStopCircle,
    } from '@fortawesome/free-solid-svg-icons';
    import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
    import { faShareNodes } from '@evidentid/dashboard-commons/assets/custom-icon-definitions';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Prop } from '@evidentid/vue-property-decorator';
    import {
        ComplianceStatus,
        ComplianceStatusOverride, VerificationStatus,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { shouldEnableDocumentRequest } from '@/utils/should-enable-document-request/shouldEnableDocumentRequest';
    import copyIcon from '@/modules/dashboard/assets/copy-icon.svg';
    import actionReviewIcon from '@/modules/dashboard/assets/icon-actions-review.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            FontAwesomeIcon, Menu, MenuLink, Expandable,
            EidIcon,
        },
    })
    export default class ActionsButton extends Vue {
        @Prop({ type: Object })
        private entity!: Entity;

        @Prop({ type: Object })
        private exceptionOverride!: ComplianceStatusOverride;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: String, default: '' })
        private submissionLink!: string;

        // Temporary to not allow them in certain place until PM decision for later release. subject to be removed
        @Prop({ type: Boolean, default: false })
        private hideExceptionOptions!: boolean;

        @Prop({ type: Boolean, default: false })
        private hideViewMapping!: boolean;

        @Prop({ type: Boolean, default: false })
        private isCollateralEnabled!: boolean;

        private faEllipsisH = faEllipsisH;
        private faPaperPlane = faPaperPlane;
        private faPauseCircle = faPauseCircle;
        private faPlayCircle = faPlayCircle;
        private faShareNodes = faShareNodes;
        private faSlidersH = faSlidersH;
        private faSpinner = faSpinner;
        private faStopCircle = faStopCircle;
        private copyIcon = copyIcon;
        private faClockRotateLeft = faClockRotateLeft;
        private actionReviewIcon = actionReviewIcon;

        private get shouldEnableDocumentRequests(): boolean {
            return shouldEnableDocumentRequest(this.entity);
        }

        private get isNonCompliant(): boolean {
            return this.entity.complianceStatus === ComplianceStatus.nonCompliant;
        }

        private get hasSubmissionLink(): boolean {
            return Boolean(this.submissionLink);
        }

        private get showViewActionReviewButton(): boolean {
            return this.entity.verificationStatus === VerificationStatus.actionsReview &&
                this.entity.active &&
                !this.entity.paused;
        }

        private get showViewMappingButton(): boolean {
            return this.isCollateralEnabled && !this.hideViewMapping;
        }

        private get showRemoveExceptionButton(): boolean {
            return Boolean(!this.hideExceptionOptions && this.exceptionOverride);
        }

        private get showGrantExceptionButton(): boolean {
            return !this.hideExceptionOptions && this.isNonCompliant;
        }

        private changePauseState() {
            this.$emit('setEntitiesPauseState', {
                entities: [ this.entity ],
                value: !this.entity.paused,
                isBulk: false,
            });
        }

        private deactivateEntity() {
            this.$emit('deactivateEntities', {
                entities: [ this.entity ],
                isBulk: false,
            });
        }

        private sendDocumentRequest() {
            this.$emit('sendDocumentRequest', [ this.entity ]);
        }

        private copySubmissionLink(): void {
            this.$emit('copySubmissionLink', this.submissionLink);
        }

        private grantExceptionToEntity(): void {
            this.$emit('grantExceptionToEntity');
        }

        private navigateToActionReview(): void {
            this.$router.push({
                name: 'entityActionsReview',
                query: { entityId: this.entity.id },
            } as any).catch(noop);
        }

        private openMappingAdjustmentModal(): void {
            this.$emit('open-collateral-mapping-modal');
        }

        private openHistoricDocumentModal(): void {
            this.$emit('open-historic-document-modal');
        }
    }
</script>
