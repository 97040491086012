import CriterionTextInput from './CriterionTextInput.vue';
import CriterionObjectInput from './CriterionObjectInput.vue';
import CriterionCheckboxInput from './CriterionCheckboxInput.vue';
import CriterionEnumInput from './CriterionEnumInput.vue';
import CriterionEnumCountryInput from './CriterionEnumCountryInput.vue';
import CriterionBooleanInput from './CriterionBooleanInput.vue';
import CriterionCurrencyInput from './CriterionCurrencyInput.vue';
import JsonSchema, { JsonSchemaEnum, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    EidJsonSchemaFormComponentMatchingFunction,
    EidJsonSchemaFormCustomComponentsMap,
} from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/types';
import {
    getFormElementComponentFromComponentsMap,
} from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/formElementComponent';
import { TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';

export const criterionJsonSchemaFormElementsMap: EidJsonSchemaFormCustomComponentsMap = {
    [TprmSchemaDisplayFormat.currency]: CriterionCurrencyInput,
    [TprmSchemaDisplayFormat.checkbox]: CriterionCheckboxInput,
    [TprmSchemaDisplayFormat.country]: CriterionEnumCountryInput,
    [TprmSchemaDisplayFormat.insuredField]: CriterionEnumInput,
    [JsonSchemaType.boolean]: CriterionBooleanInput,
    [JsonSchemaType.object]: CriterionObjectInput,
    [JsonSchemaType.string]: CriterionTextInput,
};

function isJsonSchemaEnum(schema: JsonSchema): schema is JsonSchemaEnum {
    return (schema as any).type === JsonSchemaType.string && (schema as any).enum && (schema as any).enumLabels;
}

export const getJsonSchemaCustomComponentInput: EidJsonSchemaFormComponentMatchingFunction = ({ schema }) => {
    if (isJsonSchemaEnum(schema)) {
        return CriterionEnumInput;
    }
    return getFormElementComponentFromComponentsMap({ componentsMap: criterionJsonSchemaFormElementsMap, schema });
};
