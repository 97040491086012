import { Vue } from '@evidentid/vue-property-decorator';
import CriterionBooleanInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionBooleanInput.vue';
import CriterionTextInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionTextInput.vue';
import CriterionNumberInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionNumberInput.vue';
import CriterionEnumInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionEnumInput.vue';
import CriterionArrayInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionArrayInput.vue';
import CriterionObjectInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionObjectInput.vue';
import CriterionCheckboxInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionCheckboxInput.vue';
import CriterionCurrencyInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionCurrencyInput.vue';
import CriterionDaysInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionDaysInput.vue';
import CriterionMultiEnumInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionMultiEnumInput.vue';
import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import { JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
import CriterionMonthsInput
    from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionMonthsInput.vue';
import { TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';

const customFormElementsMap: Record<string, Vue.Component> = {
    [JsonSchemaType.boolean]: CriterionBooleanInput,
    [JsonSchemaType.string]: CriterionTextInput,
    [JsonSchemaType.number]: CriterionNumberInput,
    [JsonSchemaType.array]: CriterionArrayInput,
    [JsonSchemaType.object]: CriterionObjectInput,
    [TprmSchemaDisplayFormat.checkbox]: CriterionCheckboxInput,
    [TprmSchemaDisplayFormat.currency]: CriterionCurrencyInput,
    [TprmSchemaDisplayFormat.days]: CriterionDaysInput,
    [TprmSchemaDisplayFormat.months]: CriterionMonthsInput,
    [TprmSchemaDisplayFormat.insuredField]: CriterionEnumInput,
    enum: CriterionEnumInput,
    multi_enum: CriterionMultiEnumInput,
};

export function getCriterionJsonSchemaComponent(): (form: JsonForm) => Vue.Component {
    return getJsonSchemaCompFunction(customFormElementsMap);
}
