import { ComplianceStatus } from './ComplianceStatus.model';
import { TprmRequirementType } from './TprmRequirementType';

export enum ExceptionLevel {
    insured = 'INSURED',
    coverageType = 'COVERAGE_TYPE',
    coverageCriterion = 'COVERAGE_CRITERION',
    evaluatedEntity = 'EVALUATED_ENTITY',
}

export enum ExceptionType {
    permanent = 'PERMANENT',
    untilDate = 'UNTIL_DATE',
    untilCoverageChange = 'UNTIL_COVERAGE_CHANGE',
    untilCoverageExpiration = 'UNTIL_COVERAGE_EXPIRATION',
}

export interface ExceptionInput {
    type: ExceptionType;
    level: ExceptionLevel;
    status: ComplianceStatus | null;
    until?: string | null;
    coverageType?: TprmRequirementType;
    criterionId?: string;
    evaluatedEntityId?: string;
    basedOnMigratedCoverage?: boolean;
}

export interface Exception extends ExceptionInput {
    id: string;
}
