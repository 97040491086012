<template>
    <ConfirmationModal
        v-if="confirmPageLeave"
        size="mediumFixed"
        theme="primary"
        yes="Save my changes first"
        no="Discard changes"
        :yes-icon="null"
        :no-icon="null"
        @input="onActionSelected"
    >
        <template v-slot:header>
            Your changes on this page have not been saved yet
        </template>
        Are you sure you want to navigate away from page
        without saving? Any changes you've made will be discarded if you choose not to save them
    </ConfirmationModal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import { Route } from 'vue-router';
    import { beforeRouteChange, NavigationResult, RawNavigationResult } from '@evidentid/vue-commons/router/guards';
    import createResolvablePromise from '@evidentid/universal-framework/createResolvablePromise';
    import { ConfirmationModal } from '../ConfirmationModal';

    @Component({
        components: { ConfirmationModal },
    })
    export default class ConfirmNavigationOnDirtyState extends Vue {
        @Prop({ type: Boolean, default: false })
        private dirty!: boolean;

        @Prop({ type: Boolean, default: false })
        private ignoreQuery!: boolean;

        @Prop({ type: Boolean, default: false })
        private disableErrorRoute!: boolean;

        @Prop({ type: RegExp, default: () => /^error(\..+)?/ })
        private errorRouteRegex!: RegExp;

        private confirmPageLeave: Function | null = null;

        @beforeRouteChange
        private onLeave(to: Route, from: Route): NavigationResult {
            const onlyQueryChanged = to.name === from.name && to.path === from.path && !isEqual(to.query, from.query);
            const isForcedRedirect = !this.disableErrorRoute && this.errorRouteRegex.test(to.name!);
            if ((this.ignoreQuery && onlyQueryChanged) || isForcedRedirect) {
                return;
            }
            if (this.dirty) {
                const { promise, resolve } = createResolvablePromise<RawNavigationResult>();
                this.confirmPageLeave = resolve;
                return promise;
            }
        }

        private onActionSelected(stayToSave: boolean) {
            if (this.confirmPageLeave) {
                this.confirmPageLeave(!stayToSave);
                this.confirmPageLeave = null;
            }
        }
    }
</script>
