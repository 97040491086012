<template>
    <ConfirmationModal
        additional-class-name="RemoveExceptionProcedure"
        title="Are you sure?"
        yes="Confirm"
        no="Cancel"
        :yes-icon="null"
        :no-icon="null"
        :progress="loading"
        @input="confirm"
    >
        Please confirm you want to remove the exception.
    </ConfirmationModal>
</template>

<style lang="scss">
    .RemoveExceptionProcedure {
        .Modal__body {
            min-width: 650px;
        }

        .Modal__content {
            margin: 50px 0 80px 0;
        }

        .Modal__footer {
            flex-direction: row-reverse;
            gap: 20px;
            margin: 20px 0 40px 0;
        }

        .Button {
            border-radius: 22px;
            padding: 0.8em 4em;
        }

        .Button--gray {
            background: transparent;
            border: 1px solid #29b496;
            color: #29b496;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { ConfirmationModal },
    })

    export default class RemoveExceptionProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        @Prop({ type: Object })
        private entity!: Entity;

        @Prop({ type: Array, default: () => [] })
        private exceptionIds!: string[];

        private get loading(): boolean {
            return this.$store.state.entityDetails.removeExceptionStatus[this.rpName] === OperationStatus.loading;
        }

        private async confirm(confirm: boolean): Promise<void> {
            if (confirm) {
                // We can't do it asynchronously because backend returns error if more than one exception is being
                // removed for given entity at a time
                for (const id of this.exceptionIds) {
                    await this.$store.actions.entityDetails.removeException({
                        rpName: this.rpName,
                        entityId: this.entity.id,
                        exceptionId: id,
                    });
                    if (this.$store.state.entityDetails.removeExceptionStatus[this.rpName] !==
                        OperationStatus.success) {
                        this.$emit('abort');
                    }
                }
                this.$emit('finish', this.entity.displayName);
            } else {
                this.$emit('abort');
            }
        }

        private close(): void {
            this.$emit('abort');
        }
    }
</script>
