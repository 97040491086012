<template>
    <div class="DraggableItem">
        <div class="DraggableItem__container" :class="{'DraggableItem__container--hover': handleHovered}">
            <div class="DraggableItem__handle" @mouseover="hoverHandle" @mouseleave="leaveHandle">
                <span>.. .. ..</span>
            </div>
            <div class="DraggableItem__content">
                <slot />
            </div>
            <div v-if="$slots.actionable" class="DraggableItem__actionable">
                <slot name="actionable"></slot>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class DraggableItem extends Vue {
        private handleHovered: boolean = false;

        private hoverHandle(): void {
            this.handleHovered = true;
        }

        private leaveHandle(): void {
            this.handleHovered = false;
        }
    }
</script>
