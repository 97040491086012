<template>
    <div class="FormRow">
        <div class="FormRow__description">
            <header v-if="hasTitle">
                <slot name="title">
                    {{ title }}
                </slot>
            </header>
            <p v-if="hasDescription">
                <slot name="description">
                    {{ description }}
                </slot>
            </p>
        </div>
        <div class="FormRow__content">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class FormRow extends Vue {
        @Prop({ type: String, default: null })
        private title!: string | null;

        @Prop({ type: String, default: null })
        private description!: string | null;

        private get hasTitle(): boolean {
            return Boolean(this.$scopedSlots.title || this.title);
        }

        private get hasDescription(): boolean {
            return Boolean(this.$scopedSlots.description || this.description);
        }
    }
</script>
