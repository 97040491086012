import Vue, { Component as VueComponent } from 'vue';

export default function withPropsInjected(Component: VueComponent, props: object) {
    return Vue.component('withPropsInjected', {
        // eslint-disable-next-line
        // @ts-ignore: use internal prop types
        props: Component.options?.props,
        render(createElement) {
            return createElement(Component, {
                props: { ...this.$attrs, ...this.$props, ...props },
                on: this.$listeners,
                scopedSlots: this.$scopedSlots,
            });
        },
    });
}
