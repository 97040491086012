<template>
    <div class="PopoverEntityNumberFilter">
        <FormElement
            ref="formElement"
            class="PopoverEntityNumberFilter__container"
            :label="label"
        >
            <FormInput class="PopoverEntityNumberFilter__operator">
                <Dropdown
                    :options="operatorOptions"
                    :selected="selectedRelationalOperator"
                    @input="onOperatorChanged"
                    @open="scrollToElement"
                />
            </FormInput>
            <FormInput class="PopoverEntityNumberFilter__valueInput">
                <input
                    type="text"
                    :value="numberString"
                    :placeholder="placeholder"
                    @input="onNumberChange"
                    @paste="onPaste"
                    @keydown="numberKeysOnly"
                >
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';

    enum RelationalOperatorLabels {
        equal = '=',
        greater = '>',
        greaterOrEqual = '≥',
        less = '<',
        lessOrEqual = '≤',
    }

    enum RelationalOperator {
        equal = '=',
        greater = '>',
        greaterOrEqual = '>=',
        less = '<',
        lessOrEqual = '<=',
    }

    @Component({
        components: { FontAwesomeIcon, Dropdown, FormElement, FormInput },
    })
    export default class PopoverEntityNumberFilter extends Vue {
        @Prop({ type: String, default: '' })
        private value!: string;

        @Prop({ type: String, default: 'number' })
        private type!: 'number' | 'integer';

        @Prop({ type: String, default: 'Type' })
        private placeholder!: string;

        @Prop({ type: String, default: 'Number Filter' })
        private label!: string;

        private relationalOperator: RelationalOperator = RelationalOperator.equal;
        private numberString: string = '';
        private relationalOperatorMap: Record<RelationalOperator, RelationalOperatorLabels> = {
            [RelationalOperator.equal]: RelationalOperatorLabels.equal,
            [RelationalOperator.greater]: RelationalOperatorLabels.greater,
            [RelationalOperator.greaterOrEqual]: RelationalOperatorLabels.greaterOrEqual,
            [RelationalOperator.less]: RelationalOperatorLabels.less,
            [RelationalOperator.lessOrEqual]: RelationalOperatorLabels.lessOrEqual,
        };

        @Watch('value', { immediate: true })
        private onValueChange() {
            if (this.value) {
                const operatorMatches = this.value.match(/>=|<=|>|<|=/g);
                this.relationalOperator = operatorMatches
                    ? operatorMatches[0] as RelationalOperator
                    : RelationalOperator.equal;
                const tokens = this.value.split(this.relationalOperator).filter(Boolean);
                this.numberString = tokens.length ? tokens[0] : '';
            } else {
                this.numberString = '';
                this.relationalOperator = RelationalOperator.equal;
            }
        }

        private operatorOptions: DropdownOption[] = [];

        private get selectedRelationalOperator() {
            return [ { label: this.relationalOperatorMap[this.relationalOperator], value: this.relationalOperator } ];
        }

        private onOperatorChanged(option: DropdownOption) {
            this.relationalOperator = option.value;
            this.onChange();
        }

        private onNumberChange(value: InputEvent) {
            this.numberString = (value.target as HTMLInputElement).value;
            this.onChange();
        }

        private onChange() {
            const operator = this.relationalOperator === RelationalOperator.equal ? '' : this.relationalOperator;
            const filterString = this.numberString ? `${operator}${this.numberString}` : null;
            this.$emit('input', filterString);
        }

        private mounted() {
            this.operatorOptions = this.getOperatorOptions(Object.values(RelationalOperator));
        }

        private getOperatorOptions(operators: RelationalOperator[]): DropdownOption[] {
            return operators.map((operator) => ({
                label: this.relationalOperatorMap[operator],
                value: operator as any,
            }));
        }

        private numberKeysOnly(inputEvent: KeyboardEvent): void {
            const regex = this.type === 'integer' ? /^[\d]$/ : /^[\d|.]$/;
            const comboKey = inputEvent.metaKey || inputEvent.ctrlKey || inputEvent.altKey;
            if (!comboKey && inputEvent.key?.length === 1 && !regex.test(inputEvent.key)) {
                inputEvent.preventDefault();
            }
        }

        private onPaste(inputEvent: ClipboardEvent) {
            const regex = this.type === 'integer' ? /[^\d]/g : /[^\d|.]/g;
            inputEvent.preventDefault();
            if (inputEvent.clipboardData) {
                const filtered = inputEvent.clipboardData.getData('text/plain').replace(regex, '');
                (inputEvent.target as HTMLInputElement).value = filtered;
                this.numberString = filtered;
            }
            this.onChange();
        }

        private scrollToElement() {
            this.$nextTick(() => {
                this.$emit('scrollTo', ((this.$refs.formElement as FormElement).$el as HTMLElement).offsetTop);
            });
        }
    }
</script>
