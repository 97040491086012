<template>
    <div class="CollateralListHeader flex w-full">
        <div class="flex flex-col">
            <div
                class="text-sm"
                :class="{
                    'text-eidDarkBlue': !hasEvaluationError && !isGrantedException,
                    'text-eidDangerRed': hasEvaluationError && !isGrantedException
                }"
                data-test-id="CollateralListHeader__description"
            >
                {{ description }}
            </div>
            <div class="text-xs text-eidDarkBlue-50 italic" data-test-id="CollateralListHeader__uniqueIdentifier">
                {{ uniqueIdentifier }}
            </div>
        </div>
        <EvaluationError
            v-if="hasEvaluationError"
            class="ml-auto"
            data-test-id="CollateralListHeader__evaluationExceptionGrandRemove"
            :show-go-to-criterion="false"
            :show-error-message="false"
            :evaluation-error="evaluationError"
            exception-badge-label="Exception granted"
            @grantExceptionToCriterion="grantEvaluateEntityException"
            @removeExceptions="removeExceptions"
        >
            <template #grant-exception-label>
                Grant exceptions for this collateral.
            </template>
            <template #grant-exception-sub-label>
                All current and future non-compliant criteria
                <span class="text-eidTrustGreen">will be waived as compliant. This can be undone.</span>
            </template>
        </EvaluationError>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import {
        createEvaluateEntityLevelException,
    } from '@/modules/entity-details/utils/createException';

    export default Vue.extend({
        name: 'CollateralListHeader',
        components: {
            EvaluationError,
        },
        props: {
            description: {
                type: String as PropType<string>,
                default: '',
            },
            uniqueIdentifier: {
                type: String as PropType<string>,
                default: '',
            },
            evaluationError: {
                type: Object as PropType<FieldEvaluationResultError>,
                default: undefined,
            },
        },
        computed: {
            isGrantedException(): boolean {
                return this.evaluationError?.complianceStatusMetadata?.length > 0;
            },
            hasEvaluationError(): boolean {
                return Boolean(this.evaluationError);
            },
        },
        methods: {
            grantEvaluateEntityException(): void {
                if (!this.evaluationError) {
                    return;
                }
                const exception = createEvaluateEntityLevelException(this.evaluationError.evaluatedEntityId as string);
                this.$emit('grant-evaluate-entity-exception', [ exception ]);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
        },
    });
</script>
