import mapValues from 'lodash/mapValues';
import type { RouteConfig } from 'vue-router/types';
import {
    ApplicationForExecution,
    ExtractModuleInjectedContext,
    ExtractModuleInjectedVueContext,
    ExtractModuleInjectedVuex,
    PartialModuleDeclaration,
    PutBaseDemandsOnApplication,
} from '../types/ModuleDeclaration';
import { BaseApplication, InjectApplicationContext } from '../types/Application';

/* eslint-disable no-use-before-define */

export class SealedModule<T extends PartialModuleDeclaration = {}> {
    private readonly vuexModules: any;
    private readonly injections: any[];
    private readonly vueInjections: any[];
    private readonly executions: any[];
    private readonly routes: any[];
    private readonly slotComponents: any;
    private readonly procedures: any;

    // eslint-disable-next-line max-params
    public constructor(
        vuexModules: any,
        injections: any[],
        vueInjections: any[],
        executions: any[],
        routes: any[],
        slotComponents: Record<string, any[]>,
        procedures: Record<string, any>,
    ) {
        this.vuexModules = { ...vuexModules };
        this.injections = [ ...injections ];
        this.vueInjections = [ ...vueInjections ];
        this.executions = [ ...executions ];
        this.routes = [ ...routes ];
        this.slotComponents = { ...slotComponents };
        this.procedures = { ...procedures };
    }

    public buildRouteConfig<A extends ApplicationForExecution<T>>(app: A): RouteConfig[] {
        return this.routes.reduce((acc, route) => [
            ...acc,
            ...route(app),
        ], []);
    }

    public registerInjections<A extends PutBaseDemandsOnApplication<BaseApplication, T>>(
        app: A
    ): Promise<InjectApplicationContext<A, ExtractModuleInjectedContext<T>>> {
        // @ts-ignore: based on simulated interface
        return Promise.resolve(
            this.injections
                .reduce(async (resultApp, next) => Object.assign(resultApp, await next(resultApp)), app)
        );
    }

    public buildStoreModules<A extends ApplicationForExecution<T>>(app: A): ExtractModuleInjectedVuex<T> {
        // @ts-ignore: based on simulated interface
        return mapValues(this.vuexModules, (createVuexModule) => createVuexModule(app));
    }

    public buildVueInjections<A extends ApplicationForExecution<T>>(app: A): ExtractModuleInjectedVueContext<T> {
        return this.vueInjections.reduce((result, inject) => ({
            ...result,
            ...inject(app),
        }), {});
    }

    public getSlotComponents(): Record<string, any[]> {
        return { ...this.slotComponents };
    }

    public getProceduresMap(): Record<string, any> {
        return { ...this.procedures };
    }

    public execute<A extends ApplicationForExecution<T>>(app: A): void {
        for (const execute of this.executions) {
            execute(app);
        }
    }
}
