import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isObject } from '@evidentid/json-schema/schemaChecks';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

export function getObjectCustomPropertyLabels(field: CustomProperty): string[] {
    if (!isObject(field.schema)) {
        return [];
    }
    const props = Object.values((field.schema as JsonSchemaObject).properties);
    return props.map((prop) => `${field.name} ${prop.title}`);
}

export function getObjectCustomPropertyLabel(field: CustomProperty, key: string): string {
    if (!isObject(field.schema)) {
        return '';
    }
    return (field.schema as JsonSchemaObject).properties[key]?.title || key;
}
