var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CollateralList border-0 border-solid border-t border-eidSecureBlue-10"},[_c('span',{staticClass:"inline-block px-5 pt-3 pb-1 text-sm text-eidSecureBlue font-medium"},[_vm._v(" COLLATERAL ")]),_vm._l((_vm.collaterals),function(ref,index){
var description = ref.description;
var uniqueIdentifier = ref.uniqueIdentifier;
var limitTypes = ref.limitTypes;
return _c('ExpansionItem',{key:index,staticClass:"pb-3",attrs:{"data-test-id":"CollateralList__item"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var isExpanded = ref.isExpanded;
return [_c('CollateralListHeader',{attrs:{"description":description,"unique-identifier":uniqueIdentifier,"is-collapsed":!isExpanded,"evaluation-error":_vm.evaluationErrors[_vm.getEvaluationErrorPath(index)]},on:{"grant-evaluate-entity-exception":_vm.grantEvaluateEntityException,"remove-exceptions":_vm.removeExceptions}})]}}],null,true)},[_c('Table',{staticClass:"m-auto",scopedSlots:_vm._u([{key:"head",fn:function(){return _vm._l((_vm.columns),function(ref){
var name = ref.name;
var label = ref.label;
return _c('HeadCell',{key:label,staticClass:"text-eidSecureBlue-50 font-normal",attrs:{"data-test-id":("CollateralList__" + name + "HeadCell")}},[_vm._v(" "+_vm._s(label)+" ")])})},proxy:true}],null,true)},_vm._l((limitTypes),function(limitType,limitTypeIndex){return _c('CollateralListRow',{key:limitTypeIndex,attrs:{"columns":_vm.columns,"collateral-limit-type":limitType,"non-compliant-messages":_vm.getNonCompliantMessages(index, limitType.limitType)}})}),1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }