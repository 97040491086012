import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isComplexObject, isObject } from '@evidentid/json-schema/schemaChecks';

export function isSubstitutableSchemav2(schema: JsonSchema): boolean {
    return (isComplexObject(schema) &&
        Object.keys(schema.properties).some((prop) => prop.includes('BasedOnInsuredField')));
}

export function getAllSubstitutableSchemas(
    schemaToCheck: JsonSchema
): { schemaPath: string, schema: JsonSchemaComplexObject }[] {
    const stack: { schemaPath: string, schema: JsonSchema }[] = [ { schemaPath: '#', schema: schemaToCheck } ];
    const substitutableSchemas: { schemaPath: string, schema: JsonSchemaComplexObject }[] = [];

    while (stack.length > 0) {
        const { schema, schemaPath } = stack.pop() || {};

        if (!schema || !schemaPath) {
            return substitutableSchemas;
        }

        if (isSubstitutableSchemav2(schema)) {
            substitutableSchemas.push({ schema: schema as JsonSchemaComplexObject, schemaPath });
        }

        if (isArray(schema)) {
            stack.push({ schema: schema.items as JsonSchema, schemaPath: `${schemaPath}/items` });
        } else if (isObject(schema)) {
            Object.entries(schema.properties).forEach(([ propertyKey, propertyValue ]) => {
                stack.push({ schema: propertyValue, schemaPath: `${schemaPath}/properties/${propertyKey}` });
            });
            Object.entries(schema.then?.properties || {}).forEach(([ propertyKey, propertyValue ]) => {
                stack.push({ schema: propertyValue, schemaPath: `${schemaPath}/then/properties/${propertyKey}` });
            });
            Object.entries(schema.else?.properties || {}).forEach(([ propertyKey, propertyValue ]) => {
                stack.push({ schema: propertyValue, schemaPath: `${schemaPath}/else/properties/${propertyKey}` });
            });
        }
    }

    return substitutableSchemas;
}
