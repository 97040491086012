import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { standardizeCustomPropertySchema } from './standardizeCustomPropertySchema';
import { removeNonStandardSchemaFormat } from './removeNonStandardSchemaFormat';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

// TODO: rename it to standardizeCriterionSchema when we fully migrate to usage of this function
export function eidStandardizeCriterionSchema(
    schema: JsonSchema,
    customProperties?: CustomProperty[],
): JsonSchema {
    return removeNonStandardSchemaFormat(
        standardizeCustomPropertySchema(schema, customProperties),
    );
}
