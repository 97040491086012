import {
    PartnersSettingsService,
} from '@evidentid/iam-client/services/partners-settings-service/partnersSettingsService';
import { RawLocation, Route } from 'vue-router/types/router';
import { TermsState, UserState } from './types';
import { RelyingPartySignature } from '@evidentid/rpweb-api-client/models/relying-party/RelyingPartySignature.model';

interface AuthRedirection {
    to: RawLocation;
    replace: boolean;
}

interface AuthRedirectionOptions {
    user: UserState;
    route: Route;
    sign?: TermsState['signDetails'][keyof TermsState['signDetails']];
    getDefaultAuthenticatedRoute(route: Route): RawLocation;
}

enum AuthRouteMode {
    user = 'user',
    guest = 'guest',
    none = 'none',
}

interface AuthRouteDetails {
    mode: AuthRouteMode;
    url: string | null;
    internal: boolean;
    rpSpecific: boolean;
    rpId: string | null;
}

interface AuthUserDetails {
    mode: AuthRouteMode.guest | AuthRouteMode.user;
    ready: boolean;
    needsVerification: boolean;
    relyingParties: RelyingPartySignature[];
}

function isRelyingPartyAvailable(rpId: string | null, relyingParties: RelyingPartySignature[]) {
    return Boolean(rpId && relyingParties.find((rp) => rp.name === rpId));
}

function getLastRpName(): string | null {
    try {
        return localStorage.getItem('$lastSelectedRp') || null;
    } catch (error) {
        return null;
    }
}

function getPreferredRelyingPartyName(relyingParties: RelyingPartySignature[]) {
    const lastRpName = getLastRpName();
    return lastRpName && relyingParties.find((rp) => rp.name === lastRpName)
        ? lastRpName
        : relyingParties.length > 0 ? relyingParties[0].name : null;
}

function getRouteDetails(route: Route): AuthRouteDetails {
    const internal = /^auth\./.test(route.name || '');
    const rpSpecific = /\/:rpId\??\//.test(route.matched[0]?.path || '');
    const rpId = typeof route.params.rpId === 'string' ? route.params.rpId : null;
    const guestOnly = Boolean(route.meta?.guestOnly);
    const userOnly = Boolean(route.meta?.userOnly || rpSpecific);
    const mode = userOnly ? AuthRouteMode.user : guestOnly ? AuthRouteMode.guest : AuthRouteMode.none;
    const next = typeof route.query.next === 'string' ? route.query.next : null;
    const url = internal
        ? next || null
        : route.fullPath;

    if (guestOnly && userOnly) {
        throw new Error('The route can\'t be registered as both guest and user only!');
    }

    return { mode, url, internal, rpSpecific, rpId };
}

function getUserDetails(user: UserState): AuthUserDetails {
    const ready = user.initialized && Boolean(!user.data || user.relyingParties);
    const relyingParties = user.relyingParties || [];
    const mode = user.data ? AuthRouteMode.user : AuthRouteMode.guest;
    const needsVerification = Boolean(user.data && !user.data.verified);

    return { mode, ready, needsVerification, relyingParties };
}

export function getAuthRedirection(options: AuthRedirectionOptions): AuthRedirection | null {
    // Extract base information
    const { user, route, sign } = options;
    const defaultAuthenticatedRoute = options.getDefaultAuthenticatedRoute(route);
    const routeDetails = getRouteDetails(route);
    const userDetails = getUserDetails(user);
    const isEvidentUser = /@evidentid\.com$/.test(user.data?.email || '');
    const partnersSettings = PartnersSettingsService.getPartnerSettings();

    if ((route.name === 'error' && route.params.reason === 'unactivated') || (user.data?.accountNotActivated)) {
        return null;
    }
    // Basic page, which doesn't have any auth expectations
    if (routeDetails.mode === AuthRouteMode.none && !routeDetails.internal) {
        return null;
    }

    if (userDetails.needsVerification) {
        if (route.name === 'auth.verify') {
            return null;
        }
        return {
            to: { name: 'auth.verify', query: { next: routeDetails.url, force: '' } },
            replace: true,
        };
    } else if (route.name === 'auth.verify') {
        return {
            to: routeDetails.url || defaultAuthenticatedRoute,
            replace: false,
        };
    }

    if (!userDetails.ready) {
        if (route.name === 'auth.check' || route.name === 'error.rp') {
            return null;
        }
        return {
            to: { name: 'auth.check', query: { next: routeDetails.url, force: '' } },
            replace: true,
        };
    } else if (route.name === 'auth.check') {
        return {
            to: routeDetails.url || defaultAuthenticatedRoute,
            replace: false,
        };
    }

    if (userDetails.mode === AuthRouteMode.guest && routeDetails.mode === AuthRouteMode.user) {
        return {
            to: { name: 'auth.login', query: { next: routeDetails.url, force: '' } },
            replace: true,
        };
    }

    if (userDetails.mode === AuthRouteMode.user && routeDetails.mode === AuthRouteMode.guest) {
        return {
            to: defaultAuthenticatedRoute,
            replace: false,
        };
    }

    if (!routeDetails.rpSpecific) {
        return null;
    }

    if (userDetails.relyingParties.length === 0) {
        if (route.name === 'auth.noRelyingParties') {
            return null;
        }
        return {
            to: { name: 'auth.noRelyingParties', query: { next: routeDetails.url, force: '' } },
            replace: true,
        };
    } else if (route.name === 'auth.noRelyingParties') {
        return {
            to: routeDetails.url || defaultAuthenticatedRoute,
            replace: false,
        };
    }

    if (!routeDetails.rpId || !isRelyingPartyAvailable(routeDetails.rpId, userDetails.relyingParties)) {
        const rpId = getPreferredRelyingPartyName(userDetails.relyingParties);
        return {
            to: { ...route, params: { ...route.params, rpId } } as any,
            replace: true,
        };
    }

    // Handle RP-specific errors nicely
    if (route.name === 'error.rp') {
        return null;
    }

    const isEmailWhitelisted = partnersSettings
        ?.whitelisted_domains
        ?.includes(user.data?.email?.split('@')?.[1] || '');
    // Check if RP has terms accepted
    if (!isEvidentUser && !sign?.signedAt && !isEmailWhitelisted) {
        if (/^auth\.terms(?:\.redirect)?$/.test(route.name || '')) {
            return null;
        }
        return {
            to: {
                name: 'auth.terms.redirect',
                params: { rpId: routeDetails.rpId },
                query: { next: routeDetails.url, force: '' },
            },
            replace: true,
        };
    }

    // Handle finished acceptation
    if (route.name === 'auth.terms.redirect') {
        if (routeDetails.url) {
            return { to: routeDetails.url, replace: true };
        }
        return {
            to: {
                name: 'auth.terms',
                params: { rpId: routeDetails.rpId, force: '' },
            },
            replace: true,
        };
    }

    return null;
}
