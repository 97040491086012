<template>
    <div
        class="CriterionSettingsRow"
        :class="{
            'CriterionSettingsRow--nonSubstitutable':
                !isArrayWithObject(schema) &&
                !schemaHaveSubstitutableSchemaWithValue(schema),
            'CriterionSettingsRow--substitutable':
                schemaHaveSubstitutableSchemaWithValue(schema),
            'CriterionSettingsRow--conditionSets':
                isKeyConditionSets(keyName),
        }"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';
    import { isSubstitutableSchema } from '@/modules/decisioning-criteria/utils/isSubstitutableSchema';
    import { GeneralAggregateLimitReferences } from '@/modules/decisioning-criteria/types';
    import { schemaHaveSubstitutableSchemaWithValue } from '@/modules/decisioning-criteria/utils/schemaHaveSubstitutableSchemaWithValue';

    export default Vue.extend({
        name: 'CriterionSettingsRow',
        props: {
            schema: {
                type: Object as PropType<JsonSchema>,
                required: true,
            },
            keyName: {
                type: String as PropType<string>,
                default: '',
            },
        },
        methods: {
            isArrayWithObject(schema: JsonSchema): boolean {
                return isArray(schema) && isObject(schema.items as JsonSchema);
            },
            isSubstitutableSchema,
            schemaHaveSubstitutableSchemaWithValue,
            isKeyConditionSets(key: string) {
                return key === GeneralAggregateLimitReferences.conditionSets;
            },
        },
    });
</script>
