<template>
    <DeactivationConfirmationModal :entities="entities" :saving="saving" @input="onAction" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import { EntityPatch, PatchOperationType } from '@evidentid/rpweb-api-client';
    import DeactivationConfirmationModal
        from '@/modules/entity-management/components/DeactivationConfirmationModal/DeactivationConfirmationModal.vue';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { LoadingModal, DeactivationConfirmationModal },
    })

    export default class DeactivateEntityProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        @Prop({ type: Array, default: () => [] })
        private entities!: Entity[];

        @Prop({ type: Boolean, default: false })
        private isBulk!: boolean;

        private opened: boolean = true;
        private saving: boolean = false;

        private onAction(yes: boolean) {
            yes ? this.deactivateInsured() : this.close();
        }

        private close(): void {
            this.opened = false;
            this.$emit('abort');
        }

        private async deactivateInsured() {
            const { rpName, entities, isBulk } = this;
            this.saving = true;
            await this.$store.actions.entityManagement.patchEntities({
                rpName,
                isBulk,
                patch: entities.map((entity) => ({
                    id: entity.id,
                    active: {
                        op: PatchOperationType.replace,
                        newValue: false,
                    },
                } as EntityPatch)),
            });
            this.$emit('finish', this.entities);
        }
    }
</script>
