<template>
    <div class="Chip" :class="{ 'Chip--disabled': disabled }" @click="$emit('click')">
        {{ title }}
        <span v-if="allowClose" class="Chip__closebtn" @click="closeChip">
            <FontAwesomeIcon :icon="faTimes" />
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';

    @Component({ components: { FontAwesomeIcon } })
    export default class Chip extends Vue {
        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: String, default: null })
        private id!: string | null;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private allowClose!: boolean;

        private faTimes = faTimes;

        private closeChip(): void {
            if (!this.disabled) {
                this.$emit('closeChip', this.id);
            }
        }

        private mounted() {
            this.$emit('mounted');
        }

        private destroyed() {
            this.$emit('destroyed');
        }
    }
</script>
