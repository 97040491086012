<template>
    <v-component v-if="loading" :is="Loading" />
    <v-component v-else :is="Component" :can-sign="canSign" v-bind="$props" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import type { IamClient } from '@evidentid/iam-client';

    @Component
    export default class AuthTermsWrapper extends Vue {
        @Prop()
        private Component!: Vue;

        @Prop()
        private Loading!: Vue;

        @Prop({ type: String })
        private content!: string;

        @Prop({ type: String })
        private hash!: string;

        private canSignRp: string | null = null;
        private canSign: boolean | null = null;

        private get iam(): IamClient {
            return (this as any).$auth.client;
        }

        private get rpId(): string {
            return this.$route.params.rpId;
        }

        private get currentSign(): any {
            return (this as any).$store.state.terms.signDetails[this.rpId];
        }

        private get loading(): boolean {
            return (
                !this.currentSign ||
                this.currentSign.status === OperationStatus.loading ||
                this.canSign === null
            );
        }

        @Watch('$route.params.rpId')
        private onRpChange(): void {
            this.recalculate();
        }

        @Watch('$store.state.terms.currentSign')
        private onSignChange(): void {
            this.recalculate();
        }

        private mounted(): void {
            this.recalculate();
        }

        private async recalculate(): Promise<void> {
            const rpName = this.rpId;
            const currentSign = this.currentSign;

            // Change permissions status to indeterminate
            if (this.canSignRp !== rpName) {
                this.canSign = null;
                this.canSignRp = rpName;
            }

            // Check the permissions
            const [ canLoadSign, canSign ] = await Promise.all([
                this.iam.obtainPermissions({
                    resource: this.rpId,
                    permission: 'read:request',
                    service: 'rpweb',
                }),
                this.iam.obtainPermissions({
                    resource: this.rpId,
                    permission: 'manage:configuration',
                    service: 'rpweb',
                }),
            ]);

            // Stop when there is race condition
            if (this.canSignRp !== rpName) {
                return;
            }

            // Save information about permissions
            this.canSign = canSign;

            // Don't reload data without need
            if (currentSign && currentSign.status !== OperationStatus.uninitialized) {
                return;
            }

            // Load sign details or show information that the User is not authorized
            if (canLoadSign) {
                await (this as any).$store.actions.terms.loadSignDetails({ rpName });
            } else {
                this.$router.push({
                    name: 'error.rp',
                    params: { reason: 'unauthorized' },
                    query: { next: this.$route.query.next || this.$route.query.fullPath },
                });
            }
        }
    }
</script>
