<template>
    <input
        :id="id"
        ref="input"
        :disabled="disabled"
        class="PhoneNumberInput"
        type="text"
        @input="onInput"
        @countrychange="onInput"
        @change="onChange"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keydown="filterInputCharacter"
    >
</template>

<style lang="scss">
    @import "~intl-tel-input/build/css/intlTelInput.min.css";
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
    import intlTelInput, { Plugin as IntlTelInputPlugin } from 'intl-tel-input';
    import 'intl-tel-input/build/js/utils';
    import { PhoneNumber } from './types';

    @Component
    export default class PhoneNumberInput extends Vue {
        @Prop()
        private id!: string;

        @Prop({ default: '' })
        private value!: string | PhoneNumber;

        @Prop({ type: Boolean, default: false })
        private noPlaceholder!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private disableAutoFormat!: boolean;

        @Ref()
        private input!: HTMLInputElement;

        private intlTelInput: IntlTelInputPlugin | null = null;
        private internalChangeInProgress: boolean = false;
        private isMounted: boolean = false;

        private get telInput(): Element | null {
            return this.intlTelInput ? (this.intlTelInput as any).telInput : null;
        }

        private mounted() {
            this.intlTelInput = intlTelInput(this.input, {
                formatOnDisplay: !this.disableAutoFormat,
                autoPlaceholder: this.noPlaceholder ? 'off' : 'polite',
            });
            this.isMounted = true;
            this.setInternalValue(this.value);
            this.persistPlaceholder();
        }

        /**
         * For some reason the placeholder happens to be removed from time to time.
         * Example: submit form with "Enter" button.
         *
         * @private
         */
        private persistPlaceholder(): void {
            if (this.intlTelInput && !this.noPlaceholder) {
                // @ts-ignore: force placeholder rebuild
                this.intlTelInput.setPlaceholderNumberType(this.intlTelInput.options.placeholderNumberType);
            }
            if (this.isMounted) {
                setTimeout(() => this.persistPlaceholder(), 50);
            }
        }

        private beforeDestroy() {
            this.isMounted = false;
            if (this.intlTelInput && this.telInput) {
                // intlTelInput has problems with detaching from DOM on Vue component destroy,
                // So this small hack will isolate and remove the input safely.
                const div = document.createElement('div');
                div.appendChild(this.telInput);
                document.body.appendChild(div);
                this.intlTelInput.destroy();

                if (this.input && this.input.parentNode) {
                    this.input.parentNode.removeChild(this.input);
                }
            }
        }

        private setInternalValue(rawValue: string | PhoneNumber) {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }

            this.internalChangeInProgress = true;

            const value = typeof rawValue === 'string' ? { number: rawValue, country: null } : rawValue;

            if (value) {
                if (value.country) {
                    this.intlTelInput.setCountry(value.country);
                }
                if (value.number && value.number !== this.input.value) {
                    this.intlTelInput.setNumber(value.number);
                }
            } else if (!value) {
                this.intlTelInput.setNumber('');
            }

            this.internalChangeInProgress = false;
        }

        @Watch('value')
        private onExternalChange(value: string | PhoneNumber) {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }

            this.setInternalValue(value);
        }

        private onInput() {
            if (this.internalChangeInProgress) {
                return;
            }
            this.emitChangeEvent();
        }

        private onChange() {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }
            this.intlTelInput.setNumber(this.input.value);
            this.emitChangeEvent();
        }

        private emitChangeEvent() {
            if (!this.intlTelInput) {
                throw new Error('IntlTelInput is not initialized.');
            }
            const phone = this.input.value.replace(/[^+\d().\s-]/g, '');
            const valid = Boolean(this.intlTelInput.isValidNumber());
            const countryData = this.intlTelInput.getSelectedCountryData();
            this.$emit('input', {
                number: phone,
                dialCode: countryData.dialCode,
                country: countryData.iso2,
                qualifiedNumber: valid ? `+${countryData.dialCode || 1} ${phone}` : null,
                valid,
            });
        }

        private filterInputCharacter(inputEvent: KeyboardEvent): void {
            const comboKey = inputEvent.metaKey || inputEvent.ctrlKey || inputEvent.altKey;
            if (!comboKey && inputEvent.key?.length === 1 && !/^[\d\-+\s().]$/.test(inputEvent.key)) {
                inputEvent.preventDefault();
            }
        }
    }
</script>
