<template>
    <div class="Tabs">
        <div class="Tabs__headerContainer">
            <div class="Tabs__headers">
                <div v-for="tab in tabs" :key="tab.id"
                    class="Tabs__header"
                    :class="{'Tabs__header--active': tab.isActive}"
                    @click="onTabChange(tab)"
                >
                    {{ tab.name }}
                </div>
            </div>
            <div v-if="$slots.headerEnd" class="Tabs__headerEnd">
                <slot name="headerEnd" />
            </div>
        </div>

        <div class="Tabs__content">
            <slot />
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import Tab from './Tab.vue';

    @Component
    export default class Tabs extends Vue {
        // also act as a switch to emit tab changes instead of self control
        @Prop({ type: String, default: null })
        private selectedTab!: string | null;

        @Prop({ type: String, default: null })
        private initialTab!: string | null;

        private templateChildren: Vue[] = [];
        private tabs: Tab[] = [];

        @Watch('selectedTab')
        private onSelectedTabExternalChange() {
            this.updateTabsActiveStatus(this.selectedOrDefaultTab);
        }

        @Watch('templateChildren', { immediate: true })
        private onInnerChildrenTabsChange() {
            this.tabs = this.templateChildren.filter((child) => child.$vnode.componentOptions?.tag === 'Tab') as Tab[];
            this.updateTabsActiveStatus(this.activeTab || this.selectedOrDefaultTab);
        }

        private mounted() {
            // assign $children reference to detect when $children changes
            this.templateChildren = this.$children;
        }

        private updateTabsActiveStatus(selectedTabId: string | null | undefined) {
            for (const tab of this.tabs) {
                tab.isActive = selectedTabId === tab.id;
            }
        }

        private onTabChange(tab: Tab): void {
            this.$emit('change', tab.id);
            if (!this.isControlledTabs) {
                this.updateTabsActiveStatus(tab.id);
            }
        }

        private get isControlledTabs(): boolean {
            return this.selectedTab !== null;
        }

        private get activeTab(): string | undefined {
            return this.tabs.find((tab) => tab.isActive)?.id;
        }

        private get selectedOrDefaultTab(): string | null | undefined {
            const availableTabs: string[] = this.tabs.map((tab) => tab.id);
            return [ this.selectedTab, this.initialTab, availableTabs[0] ]
                .find((tab) => tab != null && availableTabs.includes(tab));
        }
    }
</script>
