export const marshFulfillmentConfiguration = {
    enabled: false,
    logoUri: 'https://privacy.evidentid.com/cust-icons/wordmark-marsh.png',
    primaryColor: '#002C77',
    secondaryColor: '#fff',
    text: '<p>Get insurance and expertise at your fingertips. Marsh is committed to help you with your insurance needs. Connect with Marsh today to secure compliant insurance, or if you need help with the verification process.</p>',
    buttonLabel: 'GET QUOTE',
    fulfillmentUrl: '',
};
export const evidentFulfillmentConfiguration = {
    enabled: false,
    logoUri: 'https://privacy.evidentid.com/cust-icons/wordmark-evidentid.png',
    primaryColor: '#778EA1',
    secondaryColor: '#fff',
    text: '<p>Evident provides a simple, secure solution for businesses and individuals to share verified personal information. With Evident, you can manage and store your information safely and easily, giving you control of when and how your data is shared.</p>',
    buttonLabel: 'Learn More',
    fulfillmentUrl: 'https://www.evidentid.com/applicants/',
};
