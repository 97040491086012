<template>
    <ActionStatusModal
        v-if="sendTestEmailStatus==='success'"
        additional-class-name="Send"
        message="Test Email Sent"
        type="success"
        open
    />
    <SendTestEmailModal
        v-else
        open
        :type="type"
        :submitting="submitting"
        @close="$emit('abort')"
        @submit="onSubmit"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import SetRemindersModal from '../components/SetRemindersModal.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import SendTestEmailModal
        from '@/modules/notifications-configuration/components/SendTestEmailModal/SendTestEmailModal.vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import ActionStatusModal
        from '@/modules/notifications-configuration/components/ActionStatusModal/ActionStatusModal.vue';

    import {
        VerificationRequestType,
    } from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

    @Component({
        components: {
            ActionStatusModal,
            LoadingModal,
            Modal,
            SendTestEmailModal,
            SetRemindersModal,
        },
    })
    export default class SendTestEmailProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private type!: VerificationRequestType;

        private get sendTestEmailStatus(): OperationStatus {
            return this.$store.state.notificationsConfiguration.sendTestEmailStatus?.[this.rpName]
                || OperationStatus.uninitialized;
        }

        private get submitting(): boolean {
            return this.sendTestEmailStatus === OperationStatus.loading;
        }

        private get userEmail(): string {
            return this.$store.state.user.data?.email || '';
        }

        private async onSubmit(): Promise<void> {
            await this.$store.actions.notificationsConfiguration.sendTestEmail({
                rpName: this.rpName,
                type: this.type,
                to: this.userEmail,
                cc: [],
            });

            if (this.sendTestEmailStatus === OperationStatus.success) {
                setTimeout(() => {
                    this.$emit('finish');
                    this.$store.actions.notificationsConfiguration.clearSendTestEmailStatus(this.rpName);
                }, 2000);
            } else if (this.sendTestEmailStatus === OperationStatus.error) {
                await this.$store.actions.snackbar.displaySnackbar({
                    message: 'Something went wrong, please try again later.',
                    success: false,
                });
            }
        }
    }
</script>
