import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { JsonSchemaFormStrings } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
import { defaultStrings } from '@evidentid/dashboard-commons/components/JsonSchemaForm/defaultStrings';
import getIn from 'lodash/get';
import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import { PropType } from 'vue';
import { JsonSchemaFormCustomComponentInput } from '@evidentid/dashboard-commons/components/JsonSchemaForm/types';

@Component
class AbstractCriterionInput<T extends JsonForm = JsonForm, V = any> extends Vue {
    @Prop(Object)
    protected form!: T;

    @Prop()
    protected value!: V;

    @Prop({ type: Boolean, default: false })
    protected hideTitle!: boolean;

    @Prop({ type: Boolean, default: false })
    protected showError!: boolean;

    @Prop({ type: Boolean, default: false })
    protected required!: boolean;

    @Prop({ type: Boolean, default: false })
    protected disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    protected touched!: boolean;

    @Prop({ type: Boolean, default: false })
    protected deletable!: boolean;

    @Prop(String)
    protected id!: string;

    @Prop({ type: Number, default: 0 })
    protected depth!: number;

    @Prop(Function)
    protected FormElementComponent!: Vue.Component;

    @Prop({ type: [ Object, Function ] as PropType<JsonSchemaFormCustomComponentInput | null> })
    private customComponentInput?: JsonSchemaFormCustomComponentInput | null;

    @Prop({ type: Object, default: () => ({}) })
    protected strings!: Partial<JsonSchemaFormStrings>;

    @Prop({ type: String, default: '' })
    protected description!: string;

    protected localTouched = false;
    protected focused = false;

    protected get empty(): boolean {
        return this.form.isEmpty(this.form.getValue(this.value, true), true);
    }

    protected get valid(): boolean {
        return this.form.isValid(this.form.getValue(this.value, true), true);
    }

    protected get accessed(): boolean {
        return this.touched || this.localTouched;
    }

    protected touch(): void {
        this.localTouched = true;
        this.focused = false;
        this.$emit('touch');
        this.$emit('blur');
    }

    protected onFocus(): void {
        this.focused = true;
        this.$emit('focus');
    }

    protected get hasError(): boolean {
        return Boolean(this.error);
    }

    protected get error(): string | null {
        if (this.empty && this.required && !this.valid) {
            return this.translate('error.required');
        } else if (this.empty) {
            return null;
        }
        return this.valid ? null : this.getCustomError() || this.translate('error.unknown');
    }

    protected getCustomError(): string | null {
        return null;
    }

    protected get schemaStrings(): Partial<JsonSchemaFormStrings> {
        return this.form.schema.strings || {};
    }

    protected translate(phrase: keyof JsonSchemaFormStrings, data: any = {}): string {
        const finalData = { ...data, schema: this.form.schema };
        const baseString = this.schemaStrings[phrase] || this.strings[phrase] || defaultStrings[phrase] || phrase;
        return baseString.replace(/{([^}]+)}/g, (_: string, key: string) => `${getIn(finalData, key)}`);
    }
}

const AbstractCriterionInputMixin = Mixins(AbstractCriterionInput) as typeof AbstractCriterionInput;
export default AbstractCriterionInputMixin;
