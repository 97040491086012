import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { InsuranceCarrier } from '@evidentid/tprm-portal-lib/models/entity-details/InsuranceCarrier.model';

type GetEvaluationError = (url: string) => FieldEvaluationResultError | null;

export function isShowingAmBestRatingRecord(
    carrier: InsuranceCarrier,
    getEvaluationResult: GetEvaluationError
): boolean {
    return Boolean(carrier.amBestFinancialStrengthRating) ||
        Boolean(getEvaluationResult('policy/carrier/amBestFinancialStrengthRating'));
}

export function isShowingAmBestFinancialSizeCategory(
    carrier: InsuranceCarrier,
    getEvaluationResult: GetEvaluationError
): boolean {
    return Boolean(carrier.amBestFinancialSizeCategory) ||
        Boolean(getEvaluationResult('policy/carrier/amBestFinancialSizeCategory'));
}

export function isShowingNaicNumber(carrier: InsuranceCarrier, getEvaluationResult: GetEvaluationError): boolean {
    return Boolean(carrier.naicNumber) ||
        Boolean(getEvaluationResult('policy/carrier/naicNumber'));
}

export function isShowingName(carrier: InsuranceCarrier, getEvaluationResult: GetEvaluationError): boolean {
    return Boolean(carrier.name) || Boolean(getEvaluationResult('policy/carrier/name'));
}

export function isShowingCarrierInfo(carrier: InsuranceCarrier, getEvaluationResult: GetEvaluationError): boolean {
    return isShowingAmBestRatingRecord(carrier, getEvaluationResult) ||
        isShowingAmBestFinancialSizeCategory(carrier, getEvaluationResult) ||
        isShowingNaicNumber(carrier, getEvaluationResult) ||
        isShowingName(carrier, getEvaluationResult);
}
