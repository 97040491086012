<template>
    <v-component
        :is="Component"
        :allow-refresh="allowRefresh"
        :warning="warning"
        :html="html"
        :title="title"
        :rp-only="rpOnly"
        :message="message"
        @refresh="refresh"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import formatMessage from '@evidentid/universal-framework/formatMessage';
    import { ErrorDefinition, ErrorObject } from '../types';

    @Component
    export default class ErrorWrapper extends Vue {
        @Prop()
        private Component!: Vue;

        private get errors(): Record<string, ErrorDefinition> {
            return (this as any).$errorDefinitions;
        }

        private getRouteError() {
            return this.$route?.params?.reason
                ? this.$route.params as unknown as { reason: string, meta?: Record<string, string> }
                : null;
        }

        private get html(): boolean {
            return Boolean(this.error.html);
        }

        private get warning(): boolean {
            return Boolean(this.error.warning);
        }

        private get rpOnly(): boolean {
            return Boolean(this.getRouteError() && this.$route?.params.rpId);
        }

        private get allowRefresh(): boolean {
            return Boolean(this.error.showRefreshButton);
        }

        private get stateError(): ErrorObject | null {
            return (this as any).$store.state.error.error;
        }

        private get error(): ErrorDefinition & { metadata: Record<string, string> } {
            const source = this.stateError || this.getRouteError();
            const reason = source && 'reason' in source && source.reason;
            const metadata = (source && 'meta' in source && source.meta) || {};

            return {
                ...(this.errors[reason as string] || this.errors.unexpected),
                metadata,
            };
        }

        private get title(): string {
            const error = this.error;
            return formatMessage(error.heading, error.metadata);
        }

        private get message(): string {
            const error = this.error;
            return formatMessage(error.message, error.metadata);
        }

        private refresh(): void {
            window.location.reload();
        }
    }
</script>
