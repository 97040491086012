<template>
    <div class="DocumentSubmissionHistoryTable min-w-[800px]">
        <Table>
            <template #head>
                <HeadCell>Request Date</HeadCell>
                <HeadCell>Submission Date</HeadCell>
                <HeadCell>Requirement</HeadCell>
                <HeadCell>Document</HeadCell>
            </template>
            <template v-if="submissions.length === 0" #status>
                No submission history for this entity.
            </template>
            <DocumentSubmissionHistoryTableRow
                v-for="(submission,i) in submissions"
                :key="`${submission.coverageType}-${submission.submissionDate}-${i}`"
                :submission="submission"
                @show-document="$emit('show-document', $event)"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import DocumentSubmissionHistoryTableRow
        from '@/modules/entity-details/components/DocumentSubmissionHistoryTableRow/DocumentSubmissionHistoryTableRow.vue';
    import { HistoricDocumentSubmission } from '@/models/HistoricDocumentSubmission.model';

    export default Vue.extend({
        name: 'DocumentSubmissionHistoryTable',
        components: {
            Table,
            HeadCell,
            DocumentSubmissionHistoryTableRow,
        },
        props: {
            submissions: {
                type: Array as PropType<HistoricDocumentSubmission[]>,
                default: () => [] as HistoricDocumentSubmission[],
            },
        },
    });
</script>
