import { ErrorDefinition } from './types';

export const commonErrors: Record<string, ErrorDefinition> = {
    'local-storage-required': {
        showRefreshButton: true,
        heading: 'Local storage required.',
        message: `You need to enable third-party data in your browser settings
                  to use this application. If you are using private mode in Safari,
                  please return with private mode disabled.`,
    },
    'session-storage-required': {
        showRefreshButton: true,
        heading: 'Session storage required.',
        message: `You need to enable third-party data in your browser settings
                  to use this application. If you are using private mode in Safari,
                  please return with private mode disabled.`,
    },
    'page-not-found': {
        html: true,
        heading: 'That doesn\'t seem to be here.',
        message: '<p>The page you have requested was not found.</p>' +
            '<a href="/" class="Error__homeButton">Go to homepage</a>',
    },
    'cancelled-network-request': {
        showRefreshButton: true,
        warning: true,
        heading: 'Network connection issues',
        message:
            'The request has been canceled, either because of a page refresh or network issues.',
    },
    'network-outage': {
        showRefreshButton: true,
        heading: 'Network connection error',
        message: `There was a connection problem. These are usually
                  intermittent, so please wait a few minutes and try again.`,
    },
    'unauthorized': {
        showRefreshButton: true,
        heading: 'You don\'t have permission to use this application.',
        message: 'Please contact the account administrator for access.',
    },
    'no-relying-parties': {
        showRefreshButton: true,
        warning: true,
        heading: 'You don\'t have permission to use this application.',
        message: 'Please contact the account administrator for access.',
    },
    'cookies-needed': {
        showRefreshButton: true,
        heading: 'Cookies are disabled.',
        message:
            'You need to enable third-party cookies and data in your browser settings to use this application.',
    },
    'unexpected': {
        showRefreshButton: true,
        heading: 'Uh oh, something went wrong!',
        message: `We're currently working on this, and should have things
                  back to normal soon. Please try again later.`,
    },
    'unactivated': {
        showRefreshButton: false,
        heading: 'Uh oh, something went wrong!',
        message: `There is no account associated with the email address provided. Please contact your administrator to request access to the Evident application.`,
    },
};
