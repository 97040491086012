import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import {
    ExceptionInput,
} from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    transformCollateralsDetails,
} from '@/modules/entity-details/utils/transformCollateralsDetails/transformCollateralsDetails';
import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
import { CollateralMappingOptions } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralMappingOptions.model';
import {
    EntityRequirementDetails,
} from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
import {
    VerificationRequest,
} from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

export interface EntityDetailsRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<EntityDetailsRequirement>();

export interface RequirementDetailsListStatus {
    status: OperationStatus;
    list: EntityRequirementDetails[];
}

interface EntityDetailsState {
    requirementDetailsListStatus: Record<string, RequirementDetailsListStatus>;
    collateralEntitiesStatus: Record<string, { status: OperationStatus, list: CollateralEntity[] }>;
    collateralMappingOptionsStatus:
        Record<string, { status: OperationStatus, list: CollateralMappingOptions[] }>;
    verificationRequestsStatus:
        Record<string, { status: OperationStatus, list: VerificationRequest[] }>;
    patchCollateralStatus: Record<string, OperationStatus>;
    grantExceptionStatus: Record<string, OperationStatus>;
    removeExceptionStatus: Record<string, OperationStatus>;
}

const { instantiateState, createMutationsFactories } = createState<EntityDetailsState>(() => ({
    requirementDetailsListStatus: {},
    collateralEntitiesStatus: {},
    collateralMappingOptionsStatus: {},
    verificationRequestsStatus: {},
    patchCollateralStatus: {},
    grantExceptionStatus: {},
    removeExceptionStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startLoadingRequirementDetailsList(payload: { rpName: string }) {
        this.requirementDetailsListStatus = {
            ...this.requirementDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.requirementDetailsListStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingRequirementDetailsList(payload: { rpName: string }) {
        this.requirementDetailsListStatus = {
            ...this.requirementDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.requirementDetailsListStatus[payload.rpName]?.list || [],
            },
        };
    },
    finishLoadingRequirementDetailsList(payload: {
        rpName: string;
        list: EntityRequirementDetails[];
    }) {
        payload.list.forEach((requirementDetails) => {
            if (!requirementDetails.coverage) {
                return;
            }

            let collateral = requirementDetails.coverage.details?.collateral;

            if (collateral) {
                collateral = transformCollateralsDetails(collateral as any);
                requirementDetails.coverage.details.collateral = collateral;
            }
        });

        this.requirementDetailsListStatus = {
            ...this.requirementDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    clearRequirementDetailsList(rpName: string) {
        this.requirementDetailsListStatus = omit(this.requirementDetailsListStatus, [ rpName ]);
    },

    startLoadingCollateralEntities(payload: { rpName: string }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.collateralEntitiesStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingCollateralEntities(payload: { rpName: string }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.collateralEntitiesStatus[payload.rpName]?.list || [],
            },
        };
    },

    finishLoadingCollateralEntities(payload: {
        rpName: string;
        list: CollateralEntity[];
    }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    startLoadingCollateralMappingOptions(payload: { rpName: string }) {
        this.collateralMappingOptionsStatus = {
            ...this.collateralMappingOptionsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.collateralMappingOptionsStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingCollateralMappingOptions(payload: { rpName: string }) {
        this.collateralMappingOptionsStatus = {
            ...this.collateralMappingOptionsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.collateralMappingOptionsStatus[payload.rpName]?.list || [],
            },
        };
    },

    finishLoadingCollateralMappingOptions(payload: {
        rpName: string;
        list: CollateralMappingOptions[];
    }) {
        this.collateralMappingOptionsStatus = {
            ...this.collateralMappingOptionsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    startLoadingVerificationRequests(payload: { rpName: string }) {
        this.verificationRequestsStatus = {
            ...this.verificationRequestsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.verificationRequestsStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingVerificationRequests(payload: { rpName: string }) {
        this.verificationRequestsStatus = {
            ...this.verificationRequestsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.verificationRequestsStatus[payload.rpName]?.list || [],
            },
        };
    },
    finishLoadingVerificationRequests(payload: {
        rpName: string;
        list: VerificationRequest[];
    }) {
        this.verificationRequestsStatus = {
            ...this.verificationRequestsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    clearVerificationRequests(rpName: string) {
        this.verificationRequestsStatus = omit(this.verificationRequestsStatus, [ rpName ]);
    },

    clearCollateralMappingOptions(rpName: string) {
        this.collateralMappingOptionsStatus = omit(this.collateralMappingOptionsStatus, [ rpName ]);
    },

    clearCollateralEntities(rpName: string) {
        this.collateralEntitiesStatus = omit(this.collateralEntitiesStatus, [ rpName ]);
    },

    startPatchingCollaterals(payload: { rpName: string }) {
        this.patchCollateralStatus = {
            ...this.patchCollateralStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failPatchingCollaterals(payload: { rpName: string }) {
        this.patchCollateralStatus = {
            ...this.patchCollateralStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishPatchingCollaterals(payload: {
        rpName: string;
    }) {
        this.patchCollateralStatus = {
            ...this.patchCollateralStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearPatchCollateralsStatus(rpName: string) {
        this.patchCollateralStatus = omit(this.patchCollateralStatus, [ rpName ]);
    },
    startGrantingExceptions(payload: { rpName: string }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failGrantingExceptions(payload: { rpName: string }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishGrantingExceptions(payload: {
        rpName: string;
    }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearGrantExceptionsStatus(rpName: string) {
        this.grantExceptionStatus = omit(this.grantExceptionStatus, [ rpName ]);
    },

    startRemovingExceptions(payload: { rpName: string }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failRemovingExceptions(payload: { rpName: string }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishRemovingExceptions(payload: {
        rpName: string;
    }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearRemoveExceptionsStatus(rpName: string) {
        this.removeExceptionStatus = omit(this.removeExceptionStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: EntityDetailsRequirement) => ({

    async loadRequirementDetailsList(payload: {
        rpName: string; entityId: string;
    }) {
        const { rpName, entityId } = payload;
        try {
            this.mutations.startLoadingRequirementDetailsList({ rpName });
            const list = await rpweb.getTprmRequirementsDetailsList(rpName, entityId);
            this.mutations.finishLoadingRequirementDetailsList({ rpName, list });
        } catch (error) {
            console.warn('load requirement details error', error);
            this.mutations.failLoadingRequirementDetailsList({ rpName });
        }
    },
    clearRequirementDetailsList(payload: { rpName: string }) {
        this.mutations.clearRequirementDetailsList(payload.rpName);
    },
    async loadCollateralEntities(payload: {
        rpName: string; entityId: string;
    }) {
        const { rpName, entityId } = payload;
        try {
            this.mutations.startLoadingCollateralEntities({ rpName });
            const list = await rpweb.getTprmCollaterals(rpName, entityId);
            this.mutations.finishLoadingCollateralEntities({ rpName, list });
        } catch (error) {
            console.warn('load collateral entities error', error);
            this.mutations.failLoadingCollateralEntities({ rpName });
        }
    },
    async loadCollateralMappingOptions(payload: {
        rpName: string; entityId: string;
    }) {
        const { rpName, entityId } = payload;
        try {
            this.mutations.startLoadingCollateralMappingOptions({ rpName });
            const list = await rpweb.getTprmCollateralMappingOptions(rpName, entityId);
            this.mutations.finishLoadingCollateralMappingOptions({ rpName, list });
        } catch (error) {
            console.warn('load collateral mapping options error', error);
            this.mutations.failLoadingCollateralMappingOptions({ rpName });
        }
    },
    async loadVerificationRequests(payload: {
        rpName: string; entityId: string;
    }) {
        const { rpName, entityId } = payload;
        try {
            this.mutations.startLoadingVerificationRequests({ rpName });
            const list = await rpweb.getTprmEntityVerifications(rpName, entityId);
            this.mutations.finishLoadingVerificationRequests({ rpName, list });
        } catch (error) {
            console.warn('load collateral mapping options error', error);
            this.mutations.failLoadingVerificationRequests({ rpName });
        }
    },
    clearVerificationRequests(payload: { rpName: string }) {
        this.mutations.clearVerificationRequests(payload.rpName);
    },
    clearCollateralMappingOptions(payload: { rpName: string }) {
        this.mutations.clearCollateralMappingOptions(payload.rpName);
    },
    clearCollateralEntities(payload: { rpName: string }) {
        this.mutations.clearCollateralEntities(payload.rpName);
    },
    async patchCollaterals(payload: {
        rpName: string;
        entityId: string;
        collateralPatchObjects: Partial<CollateralEntity>[];
    }) {
        const { rpName, entityId, collateralPatchObjects } = payload;
        try {
            this.mutations.startPatchingCollaterals({ rpName });
            await rpweb.patchTprmCollaterals(rpName, entityId, collateralPatchObjects);
            this.mutations.finishPatchingCollaterals({ rpName });
        } catch (error) {
            this.mutations.failPatchingCollaterals({ rpName });
            console.error(error);
        }
    },
    clearPatchCollateralsStatus(payload: { rpName: string }) {
        this.mutations.clearPatchCollateralsStatus(payload.rpName);
    },
    async grantExceptions(payload: {
        rpName: string;
        entityId: string;
        exceptions: ExceptionInput[];
    }) {
        const { rpName, entityId, exceptions } = payload;
        try {
            this.mutations.startGrantingExceptions({ rpName });
            await rpweb.grantException(rpName, entityId, exceptions);
            this.mutations.finishGrantingExceptions({ rpName });
        } catch (error) {
            this.mutations.failGrantingExceptions({ rpName });
        }
    },
    clearGrantExceptionsStatus(payload: { rpName: string }) {
        this.mutations.clearGrantExceptionsStatus(payload.rpName);
    },

    async removeException(payload: {
        rpName: string;
        entityId: string;
        exceptionId: string;
    }) {
        const { rpName, entityId, exceptionId } = payload;
        try {
            this.mutations.startRemovingExceptions({ rpName });
            await rpweb.removeException(rpName, entityId, exceptionId);
            this.mutations.finishRemovingExceptions({ rpName });
        } catch (error) {
            this.mutations.failRemovingExceptions({ rpName });
        }
    },
    clearRemoveExceptionsStatus(payload: { rpName: string }) {
        this.mutations.clearRemoveExceptionsStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
