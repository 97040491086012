<template>
    <v-component v-if="loaded && enabled" :is="component" v-bind="props" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-component>
    <v-component v-else-if="loaded && restricted" :is="restricted" v-bind="propsRestricted" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-component>
    <v-component v-else-if="!loaded && loader" :is="loader" v-bind="propsLoader" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-component>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import pick from 'lodash/pick';
    import { IamPermission } from '@evidentid/iam-client/types';
    import { IamClient } from '@evidentid/iam-client';

    @Component
    export default class WithPermissions extends Vue {
        @Prop()
        private component!: Vue;

        @Prop({ default: null })
        private loader!: Vue | null;

        @Prop({ default: null })
        private restricted!: Vue | null;

        @Prop({ type: String, default: null })
        private adapter!: string | null;

        @Prop({ default: () => ({}) })
        private props!: Record<string, any>;

        @Prop({ default: () => ({}) })
        private propsRestricted!: Record<string, any>;

        @Prop({ default: () => ({}) })
        private propsLoader!: Record<string, any>;

        @Prop({ type: Boolean, default: false })
        private request!: boolean;

        @Prop({ type: Boolean, default: false })
        private acceptNotScoped!: boolean;

        @Prop({ type: Boolean, default: false })
        private allowAny!: boolean;

        @Prop({ type: Array })
        private permissions!: IamPermission[];

        private enabled = false;
        private loaded = false;

        private get iam(): IamClient {
            return (this as any).$auth.client;
        }

        private get currentAdapter(): string {
            return (this as any).$auth.type;
        }

        private get hash(): string {
            return JSON.stringify(pick(this, [ 'adapter', 'request', 'acceptNotScoped', 'allowAny', 'permissions' ]));
        }

        @Watch('hash', { immediate: true })
        private async handlePermissions(): Promise<void> {
            const { permissions, iam, adapter, hash } = this;

            if (adapter && adapter !== this.currentAdapter) {
                this.enabled = false;
                this.loaded = true;
                return;
            }

            const requestStatus = this.request
                ? await iam.obtainPermissions(...permissions)
                : null;
            const finalStatus = requestStatus || (
                this.allowAny
                    ? await iam.hasAnyPermissions(...permissions)
                    : await iam.hasPermissions(...permissions)
            );

            // Update status when the permissions are finally known
            if (this.hash === hash) {
                this.enabled = (finalStatus === null && this.acceptNotScoped) || Boolean(finalStatus);
                this.loaded = true;
            }
        }
    }
</script>
