import ConfigApiClient from '@evidentid/config-api-client';
import { createModuleBuilder } from '../../app';

export function createConfigApiClientModule() {
    return createModuleBuilder()
        .demandOptions<{ configApiUrl: string }>()
        .demandContext<{ auth: { getTokens(): Promise<{ accessToken: string | null, idToken: string | null }> } }>()
        .inject(async (app) => {
            const configClient = new ConfigApiClient(app.options.configApiUrl);
            configClient.setTokens(() => app.auth.getTokens());
            return { configClient };
        })
        .end();
}

export type ConfigApiClientModule = ReturnType<typeof createConfigApiClientModule>;
