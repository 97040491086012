<template>
    <ScreenMessage type="pending">
        <template v-slot:icon>
            <LogoLoader />
        </template>
        <slot />
    </ScreenMessage>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { LogoLoader } from '../../components/LogoLoader';
    import { ScreenMessage } from '../ScreenMessage';

    @Component({
        components: { ScreenMessage, LogoLoader },
    })
    export default class PendingScreen extends Vue {
    }
</script>
