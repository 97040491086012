<template>
    <Modal additional-class-name="ConfirmUpdateBulkEntitiesModal" open @close="close">
        <template #header>
            Confirm updates of existing entities
        </template>

        <Alert v-if="successCount > 0" type="success">
            <template #title>
                <FontAwesomeIcon :icon="faCheckCircle" />
                <span v-if="successCount === 1"> 1 new entity were added</span>
                <span v-else>{{ successCount }} new entities were added</span>
            </template>
        </Alert>
        <Alert type="highWarning">
            <template #title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span v-if="entities.length === 1">
                    1 entity emails already exist and the record will be updated. Please confirm to proceed.
                </span>
                <span v-else>
                    {{ entities.length }} entities emails already exist and the record will be updated. Please confirm to proceed.
                </span>
            </template>
        </Alert>

        <Alert v-if="externalError" type="danger">
            <template #title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                <span> {{ externalError }}</span>
            </template>
        </Alert>
        <div class="ConfirmUpdateBulkEntitiesModal__entitiesTable">
            <div class="ConfirmUpdateBulkEntitiesModal__entitiesTableHeaderRow">
                <strong>Entity Name</strong>
                <strong>Primary Contact Email</strong>
            </div>
            <div
                v-for="(entity, index) of entities"
                :key="index"
                class="ConfirmUpdateBulkEntitiesModal__entitiesTableRow"
            >
                <strong>{{ entity.displayName }}</strong>
                <p>{{ entity.contactEmail }}</p>
            </div>
        </div>
        <div class="ConfirmUpdateBulkEntitiesModal__actionSection">
            <Button :disabled="saving" @click="close">
                Cancel update
            </Button>
            <Button
                type="primary"
                :loading="saving"
                :progress="savingPercentage"
                :disabled="saving"
                @click="confirm"
            >
                Update Entities
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FontAwesomeIcon,
            Table,
            HeadCell,
            Row,
            Cell,
        },
    })
    export default class ConfirmUpdateBulkEntitiesModal extends Vue {
        @Prop({ type: Number, default: 0 })
        private successCount!: number;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Number, default: null })
        private savingPercentage!: number | null;

        @Prop({ type: Array, default: [] })
        private entities!: EntityInput[];

        @Prop({ type: String, default: '' })
        private externalError!: string;

        private faExclamationTriangle = faExclamationTriangle;
        private faCheckCircle = faCheckCircle;

        private close(): void {
            this.$emit('close');
        }

        private confirm(): void {
            this.$emit('updateEntities', this.entities);
        }
    }
</script>
