<template>
    <div
        class="JsonSchemaTextForm"
        :class="{'JsonSchemaTextForm--focus': focused}"
    >
        <FormElement
            :label-for="id"
            :label="form.schema.title"
            :required="requiredElement"
        >
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
            </template>
            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <RichTextEditor
                    :id="id"
                    :disabled="disabled"
                    :value="viewValue"
                    :placeholder="form.schema.placeholder"
                    :maxlength="form.schema.maxLength"
                    :variables="form.schema.variables"
                    :allow-links="allowLinks"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                />

                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import { RichTextEditor } from '../../RichTextEditor';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormString = JsonFormBase<JsonSchemaString>;

    @Component({
        components: { RichTextEditor, FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaHtmlForm extends AbstractJsonSchemaForm<JsonFormString, string> {
        private faTimes = faTimes;

        protected override getCustomError(): string | null {
            const value = this.value;
            if ((this.form.schema.minLength || 0) > value.length) {
                return this.translate('error.text.tooShort');
            } else if ((this.form.schema.maxLength || Infinity) < value.length) {
                return this.translate('error.text.tooLong');
            }
            return null;
        }

        private get requiredElement(): boolean {
            return Boolean(this.required && (this.form.schema.minLength || this.form.schema.pattern));
        }

        private get viewValue(): string {
            return typeof this.value === 'string' || typeof this.value === 'number' ? `${this.value}` : '';
        }

        private onInput(value: string): void {
            this.$emit('input', value);
            this.$emit('change', value);
        }

        private get allowLinks(): boolean {
            return Boolean(this.form.schema.htmlExtensions?.links);
        }
    }
</script>
