<template>
    <EidSidePanel
        class="RiskProfileAssignmentRulePanel"
        :class="{ 'RiskProfileAssignmentRulePanel--loading': loadingCustomProperties }"
        :click-out-exception-selectors="clickOutExceptions"
        @click-out="close"
    >
        <LogoLoader v-if="loadingCustomProperties" />
        <template v-if="!loadingCustomProperties" #headerText>
            <span>Assignment</span>
        </template>
        <template v-if="!loadingCustomProperties" #content>
            <div
                v-for="(basicRules, rulesGroupIndex) in localRules"
                :key="rulesGroupIndex"
                class="RiskProfileAssignmentRulePanel__orRule"
            >
                <span class="RiskProfileAssignmentRulePanel__ruleIndexLetter">
                    {{ getLetterIndex(rulesGroupIndex) }}
                </span>
                <div class="RiskProfileAssignmentRulePanel__orRuleBody">
                    <div class="RiskProfileAssignmentRulePanel__header">
                        <span
                            class="RiskProfileAssignmentRulePanel__conditionFieldHeader"
                        >
                            {{ rulesGroupIndex > 0 ? 'Or if ...' : 'If ...' }}
                        </span>
                        <span class="RiskProfileAssignmentRulePanel__conditionOperatorHeader">
                            Condition
                        </span>
                        <span class="RiskProfileAssignmentRulePanel__conditionValueHeader">
                            Value
                        </span>
                    </div>
                    <div
                        v-for="(basicRule, basicRuleIndex) in basicRules"
                        :key="basicRuleIndex"
                        class="RiskProfileAssignmentRulePanel__andRule"
                    >
                        <Dropdown
                            class="RiskProfileAssignmentRulePanel__conditionFieldDropdown"
                            placeholder="Select Custom Property"
                            :options="customPropertiesOptions"
                            :selected="convertCustomPropertyToDropdownOption(basicRule.insuredFieldKey)"
                            @input="onCustomPropertyNameChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <Dropdown
                            class="RiskProfileAssignmentRulePanel__conditionOperatorDropdown"
                            :options="getAvailableOperators(basicRule)"
                            :selected="convertToOperatorOption(basicRule.operator)"
                            @input="onOperatorChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <Dropdown
                            v-if="getCustomPropertyType(basicRule) === 'boolean'"
                            class="RiskProfileAssignmentRulePanel__conditionValueDropdown
                            RiskProfileAssignmentRulePanel__conditionValue"
                            :options="booleanOptions"
                            :selected="convertToBooleanOption(basicRule.value)"
                            :disabled="!isCustomPropertySelected(basicRule)"
                            @input="onBooleanValueChange(rulesGroupIndex, basicRuleIndex, $event)"
                        />
                        <input
                            v-else
                            class="RiskProfileAssignmentRulePanel__conditionValue
                             RiskProfileAssignmentRulePanel__input"
                            :type="getInputValueType(basicRule)"
                            :value="basicRule.value"
                            :disabled="!isCustomPropertySelected(basicRule)"
                            @input="onValueChange(rulesGroupIndex, basicRuleIndex, $event)"
                        >
                        <EidIcon
                            class="RiskProfileAssignmentRulePanel__binIcon"
                            alt=""
                            :icon-src="binIcon"
                            :svg-props="{color: alertRedColor}"
                            @click="removeBasicRule(rulesGroupIndex, basicRuleIndex)"
                        />
                    </div>
                    <Button
                        class="RiskProfileAssignmentRulePanel__addAndConditionButton"
                        @click="addBasicRule(rulesGroupIndex)"
                    >
                        + And
                    </Button>
                </div>
            </div>
            <Button
                class="RiskProfileAssignmentRulePanel__addOrConditionButton"
                :disabled="localRules.length > 25"
                @click="addRulesGroup"
            >
                + Or if ...
            </Button>
        </template>
    </EidSidePanel>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import get from 'lodash/get';
    import EidSidePanel from '@/modules/decisioning-criteria/components/EidSidePanel/EidSidePanel.vue';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { AssignmentRuleValue, BasicAssignmentRule } from '@/modules/decisioning-criteria/types';
    import { CustomPropertiesListStatus } from '@/modules/decisioning-criteria/vuex';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        allAssignmentOperators,
        booleanAssignmentOperators,
        collateralCategoryOperators,
        JsonRuleOperator,
        stringAssignmentOperators,
    } from '@/modules/decisioning-criteria/utils/assigmentRuleOperators';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import { getLetterIndex } from '@/modules/decisioning-criteria/utils/getLetterIndex';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
    import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    export default Vue.extend({
        name: 'RiskProfileAssignmentRulePanel',
        components: {
            EidIcon,
            EidSidePanel,
            Button,
            Dropdown,
            LogoLoader,
        },
        props: {
            rules: {
                type: Array as PropType<BasicAssignmentRule[][]>,
                default: () => [],
            },
            customPropertiesStatus: {
                type: Object as PropType<CustomPropertiesListStatus>,
                required: true,
            },
            loadingCustomProperties: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            collateralsOnly: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                alertRedColor: tailwindColors.eidDangerRed.DEFAULT,
                binIcon,
                booleanOptions: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                ] as DropdownOption[],
                clickOutExceptions: [
                    '.RiskProfileSaveButton__saveButton',
                    '.RiskProfileSaveButton__saveButton span',
                ],
            };
        },
        computed: {
            customProperties(): CustomProperty[] {
                return this.customPropertiesStatus.list;
            },
            collateralCustomProperty(): CustomProperty | null {
                return this.customProperties.find(isCollateralCustomProperty) || null;
            },
            categoryOption(): DropdownOption | null {
                return this.collateralCustomProperty
                    ? { label: 'Category', value: `${this.collateralCustomProperty.key}[items].category` }
                    : null;
            },
            customPropertiesOptions(): DropdownOption[] {
                if (this.collateralsOnly) {
                    const hasCollateralField = Boolean(this.collateralCustomProperty);
                    return hasCollateralField && this.categoryOption
                        ? [ this.categoryOption ]
                        : [];
                }
                return this.customProperties
                    .filter((insuredField) => 'type' in insuredField.schema && this.isAllowedCustomProperty(insuredField))
                    .map((insuredField) => ({ label: insuredField.name, value: insuredField.key }));
            },
            localRules(): BasicAssignmentRule[][] {
                if (!this.rules || this.rules.length === 0) {
                    return [ [
                        { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' },
                    ] ] as BasicAssignmentRule[][];
                }

                return this.rules;
            },
        },
        methods: {
            getLetterIndex,
            getFieldSchema(rule: BasicAssignmentRule): JsonSchema | null {
                // get top schema, traverse down until the desired schema the key indicates
                // first complete word without special character is insured field key ex. collaterals[items].category
                const insuredFieldKeyFromRule = rule.insuredFieldKey.match(/\w+/)?.[0] || '';
                const topSchema = this.customProperties
                    .find((insuredField) => insuredField.key === insuredFieldKeyFromRule)?.schema;
                const schemaWrapper = { [insuredFieldKeyFromRule]: topSchema };
                const schemaPath = rule.insuredFieldKey
                    // change dot charater into peroperties as it indicates object type
                    .replaceAll(/\./g, '.properties.')
                    // change [items] into .items to access array items schema
                    .replaceAll(/\[(\w+)\]/g, '.$1');
                return get(schemaWrapper, schemaPath) || null;
            },
            getCustomPropertyType(rule: BasicAssignmentRule): string {
                const schema = this.getFieldSchema(rule);
                return (schema && 'type' in schema ? schema.type : '');
            },
            getAvailableOperators(rule: BasicAssignmentRule): DropdownOption[] {
                const collateralKey = this.customProperties.find(isCollateralCustomProperty)?.key;
                const collateralsKeyRegex = new RegExp(`^${collateralKey}`);
                if (collateralsKeyRegex.test(rule.insuredFieldKey)) {
                    return collateralCategoryOperators;
                }
                const type = this.getCustomPropertyType(rule);
                switch (type) {
                    case 'boolean':
                        return booleanAssignmentOperators;
                    case 'string':
                        return stringAssignmentOperators;
                    default:
                        return allAssignmentOperators;
                }
            },
            convertCustomPropertyToDropdownOption(value: string): DropdownOption[] {
                if (!value) {
                    return [];
                }
                if (this.collateralsOnly && this.categoryOption) {
                    return [ this.categoryOption ];
                }
                return [
                    {
                        value,
                        label: this.customProperties.find((insuredField) => insuredField.key === value)?.name || '',
                    },
                ];
            },
            convertToOperatorOption(value: string): DropdownOption[] {
                return [ {
                    value,
                    label: allAssignmentOperators.find((option) => option.value === value)?.label || value,
                } ];
            },
            convertToBooleanOption(value: AssignmentRuleValue): DropdownOption[] {
                if (value === true) {
                    return [ { value, label: 'Yes' } ];
                } else if (value === false) {
                    return [ { value, label: 'No' } ];
                } else {
                    return [];
                }
            },
            onCustomPropertyNameChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].insuredFieldKey = option.value;

                if (this.getCustomPropertyType(updatedRules[groupIndex][basicRuleIndex]) === 'boolean') {
                    updatedRules[groupIndex][basicRuleIndex].value = false;
                } else {
                    updatedRules[groupIndex][basicRuleIndex].value = '';
                }
                updatedRules[groupIndex][basicRuleIndex].operator = JsonRuleOperator.equal;
                this.$emit('update-rules', updatedRules);
            },
            onOperatorChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].operator = option.value;
                this.$emit('update-rules', updatedRules);
            },
            onValueChange(groupIndex: number, basicRuleIndex: number, event: InputEvent): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].value = (event.target as HTMLInputElement).value;
                this.$emit('update-rules', updatedRules);
            },
            onBooleanValueChange(groupIndex: number, basicRuleIndex: number, option: DropdownOption): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex][basicRuleIndex].value = option.value;
                this.$emit('update-rules', updatedRules);
            },
            addBasicRule(groupIndex: number): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules[groupIndex].push({ operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' });
                this.$emit('update-rules', updatedRules);
            },
            addRulesGroup(): void {
                const updatedRules = this.localRules.map((basicRules) => basicRules.slice());
                updatedRules.push([ { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' } ]);
                this.$emit('update-rules', updatedRules);
            },
            removeBasicRule(groupIndex: number, basicRuleIndex: number): void {
                const updatedRules = this.localRules.map((basicRules, index) => (index === groupIndex
                    ? [ ...basicRules.slice(0, basicRuleIndex), ...basicRules.slice(basicRuleIndex + 1) ]
                    : basicRules.slice()));

                if (updatedRules[groupIndex].length === 0) {
                    updatedRules.splice(groupIndex, 1);
                }

                if (updatedRules.length === 0) {
                    updatedRules.push([ { operator: JsonRuleOperator.equal, insuredFieldKey: '', value: '' } ]);
                }

                this.$emit('update-rules', updatedRules);
            },
            isCustomPropertySelected(rule: BasicAssignmentRule): boolean {
                return rule.insuredFieldKey.length > 0;
            },
            getInputValueType(basicRule: BasicAssignmentRule): string {
                return [ 'number', 'integer' ].includes(this.getCustomPropertyType(basicRule)) ? 'number' : 'text';
            },
            close(): void {
                this.$emit('close');
            },
            isAllowedCustomProperty(insuredField: CustomProperty): boolean {
                return !isArray(insuredField.schema) && !isObject(insuredField.schema);
            },
        },
    });
</script>
