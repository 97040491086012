import _Vue, { PluginObject, VueConstructor } from 'vue';
import VTooltip from 'v-tooltip';

function createTooltipPlugin(): PluginObject<_Vue> {
    return {
        install(Vue: VueConstructor): void {
            Vue.use(VTooltip, {
                defaultHtml: false,
                defaultTemplate: '<div class="VTooltip" role="tooltip"><div class="VTooltip__arrow"></div><div class="VTooltip__inner"></div></div>',
                defaultClass: 'VTooltip',
                defaultTargetClass: 'VTooltip__target',
                defaultArrowSelector: '.VTooltip__arrow',
                defaultInnerSelector: '.VTooltip__inner',
                defaultLoadingClass: '.VTooltip--loading',
                popover: {
                    defaultClass: 'VTooltip--popover',
                    defaultBaseClass: 'VTooltip VTooltip--popover',
                    defaultInnerClass: 'VTooltip__inner VTooltip__inner--popover',
                    defaultWrapperClass: 'VTooltip__wrapper',
                    defaultArrowClass: 'VTooltip__arrow VTooltip__arrow--popover',
                    defaultOpenClass: 'VTooltip--open',
                },
            });
        },
    };
}

export default createTooltipPlugin;
