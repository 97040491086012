<template>
    <div
        class="EntitiesTable"
        :class="{ 'EntitiesTable--with-custom-property': displayedCustomProperties.length > 0 }"
    >
        <Table>
            <template #head>
                <HeadCell class="EntitiesTable__selectionHeader">
                    <Checkbox
                        :disabled="entities.length === 0 || isPerformingBulkAction"
                        :value="areAllSelected"
                        @input="toggleSelection"
                    />
                </HeadCell>
                <HeadCell
                    class="EntitiesTable__nameHeader"
                    sortable
                    sort-type="alpha"
                    :sort="getSort('displayName')"
                    @sort="changeSort('displayName', $event)"
                >
                    Entity
                </HeadCell>
                <HeadCell
                    class="EntitiesTable__effectiveGroupHeader"
                >
                    Effective Risk Profile
                </HeadCell>
                <HeadCell
                    v-for="customProperty in displayedCustomProperties"
                    :key="customProperty.id"
                    v-tooltip="{ content: customProperty.name, delay: { show: 1000 } }"
                    class="EntitiesTable__customPropertyHeader"
                >
                    {{ customProperty.name }}
                </HeadCell>
                <HeadCell
                    class="EntitiesTable__expirationHeader"
                    sortable
                    :sort="getSort('nextExpiration')"
                    @sort="changeSort('nextExpiration', $event)"
                >
                    Expiration Date
                </HeadCell>
                <HeadCell
                    class="EntitiesTable__complianceStatusHeader"
                    sortable
                    :sort="getSort('complianceStatus')"
                    @sort="changeSort('complianceStatus', $event)"
                >
                    Compliance Status
                </HeadCell>
                <HeadCell class="EntitiesTable__verificationStatusHeader">
                    Verification Status
                </HeadCell>
                <HeadCell class="EntitiesTable__actionsHeader" />
            </template>

            <template #toolbar>
                <div class="EntitiesTable__toolbar">
                    <BulkActionsExpandable
                        :enable-send-document-request="isEnabledSendDocumentRequest"
                        :disabled="noActiveEntitiesSelected || currentlyUpdatedEntitiesIds.length > 0"
                        :loading="isPerformingBulkAction"
                        :show-pause="showBulkActionsPause"
                        :show-unpause="showBulkActionsUnpause"
                        @bulkDeactivate="bulkDeactivateEntities"
                        @bulkSetPauseState="bulkSetEntitiesPauseStatus"
                        @sendDocumentRequest="sendDocumentRequest"
                    />
                    <slot name="toolbar" />
                </div>
            </template>

            <template #toolbarEnd>
                <ExportButton
                    class="EntitiesTable__exportButton"
                    tooltip-message="This may take up to one minute…"
                    :submitting="exporting"
                    v-on="$listeners"
                >
                    Download Data
                </ExportButton>
                <Expandable class="EntitiesTable__importExpandable" label="Add/Edit Entity" legacy-mode>
                    <template #button>
                        <FontAwesomeIcon :icon="faUsers" />
                    </template>

                    <Menu spaced>
                        <MenuLink
                            :icon="faPlusSquare"
                            additional-class="EntitiesTable__menuLink"
                            label="Add/Edit Entity"
                            @click="$emit('openAddIndividualEntityModal')"
                        />
                        <MenuLink
                            :icon="faUpload"
                            additional-class="EntitiesTable__menuLink"
                            label="Bulk Import Entities"
                            @click="$emit('openBulkImportEntitiesModal')"
                        />
                    </Menu>
                </Expandable>
            </template>

            <EntitiesTableRow
                v-for="entity of entities"
                :key="entity.contactEmail"
                :entity="entity"
                :is-performing-bulk-action="isPerformingBulkAction"
                :displayed-custom-properties="displayedCustomProperties"
                :currently-updated-entities-ids="currentlyUpdatedEntitiesIds"
                :selected="selectedIds.has(entity.id)"
                :is-loading-submission-link="isLoadingSubmissionLink"
                :submission-link="getSubmissionLink(entity.id)"
                @selectionChange="changeSelection"
                v-on="$listeners"
            />
            <template v-if="entities.length === 0 && finished" #message>
                <slot name="message">
                    <NoEntitiesFound @reset="$emit('clearFilters')" />
                </slot>
            </template>
            <template v-else-if="entities.length > 0 && finished" #status>
                <span>All Entities Displayed.</span>
            </template>
            <template v-else-if="loading" #status>
                <LineLoader />
                <span>Loading...</span>
            </template>
        </Table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faUsers, faPlusSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
    import { LineLoader } from '@evidentid/dashboard-commons/components/LineLoader';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Todo } from '@evidentid/dashboard-commons/components/Todo';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Sorting, SortingDirection } from '@evidentid/universal-framework/sorting';
    import EntitiesTableRow from '../EntitiesTableRow/EntitiesTableRow.vue';
    import ExportButton from '@/modules/dashboard/components/ExportButton/ExportButton.vue';
    import NoEntitiesFound from '@/modules/entity-filtering/components/NoEntitiesFound/NoEntitiesFound.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import BulkActionsExpandable
        from '@/modules/entity-management/components/BulkActionsExpandable/BulkActionsExpandable.vue';
    import { shouldEnableDocumentRequest } from '@/utils/should-enable-document-request/shouldEnableDocumentRequest';
    import { CustomProperty, Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            BulkActionsExpandable, Checkbox, NoEntitiesFound, EntitiesTableRow, HeadCell, Todo, Table, LineLoader,
            ExportButton, Expandable, Menu, MenuLink, FontAwesomeIcon,
        },
    })
    export default class EntitiesTable extends Vue {
        @Prop({ type: Boolean, default: false })
        private finished!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: false })
        private exporting!: boolean;

        @Prop({ type: Array, default: () => [] })
        private entities!: Entity[];

        @Prop({ type: Object })
        private sort!: Sorting;

        @Prop({ type: Array, default: () => [] })
        private displayedCustomProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedEntitiesIds!: string[];

        @Prop({ type: Boolean, default: false })
        private isPerformingBulkAction!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Object, default: {} })
        private submissionLinkPerEntityId!: Record<string, string>;

        private faUsers = faUsers;
        private faPlusSquare = faPlusSquare;
        private faUpload = faUpload;

        private selectedEntities: Entity[] = [];
        private selectedIds: Set<string> = new Set<string>();

        @Watch('entities')
        private onEntitiesChange() {
            this.selectedEntities = this.entities.filter((entity) => this.selectedIds.has(entity.id));
            this.selectedIds = new Set<string>(this.selectedEntities.map((entity) => entity.id));
        }

        private changeSort(column: string, direction: SortingDirection): void {
            this.$emit('sort', { column, direction });
        }

        private getSort(column: string): SortingDirection | undefined {
            return this.sort?.column === column ? this.sort?.direction : undefined;
        }

        private toggleSelection(value: boolean): void {
            this.selectedEntities = value ? [ ...this.entities ] : [];
            this.selectedIds = new Set<string>(this.selectedEntities.map((entity) => entity.id));
        }

        private get areAllSelected(): boolean {
            return this.entities.length > 0 ? this.entities.length === this.selectedEntities.length : false;
        }

        private get noActiveEntitiesSelected(): boolean {
            return !this.selectedEntities.some((entity) => entity.active);
        }

        private get showBulkActionsPause(): boolean {
            return this.selectedEntities.some((entity) => !entity.paused);
        }

        private get showBulkActionsUnpause(): boolean {
            return this.selectedEntities.some((entity) => entity.paused);
        }

        private getSubmissionLink(entityId: string): string {
            return this.submissionLinkPerEntityId[entityId] || '';
        }

        private changeSelection(entity: Entity): void {
            this.selectedIds = new Set<string>(this.selectedIds);
            this.selectedIds.has(entity.id) ? this.deselectEntity(entity) : this.selectEntity(entity);
        }

        private deselectEntity(entity: Entity): void {
            this.selectedIds.delete(entity.id);
            const index = this.selectedEntities.findIndex((selectedEntity) => selectedEntity.id === entity.id);
            this.selectedEntities = [
                ...this.selectedEntities.slice(0, index),
                ...this.selectedEntities.slice(index + 1),
            ];
        }

        private selectEntity(entity: Entity): void {
            this.selectedIds.add(entity.id);
            this.selectedEntities = [ ...this.selectedEntities, entity ];
        }

        private bulkDeactivateEntities(): void {
            this.$emit('deactivateEntities', { entities: this.selectedEntities, isBulk: true });
        }

        private bulkSetEntitiesPauseStatus(value: boolean): void {
            this.$emit('setEntitiesPauseState', { entities: this.selectedEntities, isBulk: true, value });
        }

        private get isEnabledSendDocumentRequest(): boolean {
            return this.selectedEntities.some(shouldEnableDocumentRequest);
        }

        private sendDocumentRequest(): void {
            this.$emit('sendDocumentRequest', this.selectedEntities);
        }
    }
</script>
