import pickBy from 'lodash/pickBy';
import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/entity-details/constant';

enum YesNoOrNA {
    YES = 'YES',
    NO = 'NO',
    N_A = 'N/A',
}

function getValueYesNoOrNA(value: string | boolean): boolean | string {
    if (typeof value === 'boolean') {
        return value;
    }
    return value === YesNoOrNA.N_A ? value : value === YesNoOrNA.YES;
}

/**
 * Use this function only after preprocessing the values, filtering out all nullish values,
 * and setting a no available messages.
 */
export function transformValuesWithIsPresent(values: Record<string, any>): Record<string, any> {
    const keyPostfix = 'IsPresent';
    const isPresentValues = pickBy(values, (_val, key) => key.endsWith(keyPostfix));
    const otherValues = pickBy<Record<string, any>>(values, (_val, key) => !key.endsWith(keyPostfix));
    Object.entries(isPresentValues).forEach(([ key, val ]) => {
        const keyWithoutPostfix = key.slice(0, -keyPostfix.length);
        const valueWithoutPostfix = otherValues[keyWithoutPostfix];
        const isEmptyValue = valueWithoutPostfix === undefined;
        if (isEmptyValue || valueWithoutPostfix === FIELD_NOT_AVAILABLE_MESSAGE) {
            otherValues[keyWithoutPostfix] = getValueYesNoOrNA(val);
        }
    });
    return otherValues;
}
