import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import SendDocumentRequestProcedure from '@/modules/entity-request/procedures/SendDocumentRequestProcedure.vue';

import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

export const entityRequestModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ entityRequest: vuex.instantiateModule })
    .registerProcedures<{
        openSendDocumentRequestProcedure: { rpName: string, entities: Entity[] };
    }>({
        openSendDocumentRequestProcedure: SendDocumentRequestProcedure,
    })
    .execute((app) => {
        function onSendDocumentRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send document Request',
                    description: `[Requested] Send document request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Requested] Send document request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFinishSendDocumentRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send document Request',
                    description: `[Finished] Send document request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Finished] Send document request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onSendDocumentRequestFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send document Request',
                    description: `[Failed] Send document request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entities',
                    message: `[Failed] Send document request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startSendingDocumentRequests', (x) => onSendDocumentRequest(x.rpName));
        app.observer.onMutation('finishSendingDocumentRequests', (x) => onFinishSendDocumentRequest(x.rpName));
        app.observer.onMutation('failSendingDocumentRequests', (x) => onSendDocumentRequestFailure(x.rpName));
    })
    .end();
