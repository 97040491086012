import { DeclineReason } from '@evidentid/tprm-portal-lib/models/entity-details/DeclineReason.model';

export const DECLINE_REASON_TEXTS: Record<DeclineReason, { header: string, body: string }> = {
    [DeclineReason.Declined]: {
        header: 'Entity Declined to Provide Document',
        body: 'This entity declined to include the requested document in their submission. Please contact them if this is incorrect.',
    },
    [DeclineReason.Missing]: {
        header: 'Entity Doesn\'t Have Document',
        body: 'This entity indicated they do not have the requested document. Please contact them for more details.',
    },
    [DeclineReason.ExceptionRequested]: {
        header: 'Entity Has Requested an Exception',
        body: 'This entity requested to have an exception applied to this requirement.',
    },
    [DeclineReason.NotRenewed]: {
        header: 'Entity Not Renewing Document',
        body: 'This entity has indicated they will not be renewing this document. Please contact them for more details.',
    },
};
