import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { getSnackbarActions } from '../../snackbar';
import { getPersistingErrorActions } from '../../persisting-error';
import { TermsState } from '../types';

export interface TermsRequirements {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<TermsRequirements>();

const { instantiateState, createMutationsFactories } = createState<TermsState>(() => ({
    signDetails: {},
    updateSign: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingSignDetails(payload: { rpName: string }) {
        this.signDetails = {
            ...this.signDetails,
            [payload.rpName]: {
                status: OperationStatus.loading,
                signedBy: null,
                signedAt: null,
            },
        };
    },
    finishLoadingSignDetails(payload: { rpName: string, signedBy?: string, signedAt?: number }) {
        const { rpName, signedBy, signedAt } = payload;
        this.signDetails = {
            ...this.signDetails,
            [rpName]: {
                status: OperationStatus.success,
                signedBy: signedBy || null,
                signedAt: signedAt || null,
            },
        };
    },
    failLoadingSignDetails(payload: { rpName: string }) {
        this.signDetails = {
            ...this.signDetails,
            [payload.rpName]: {
                status: OperationStatus.error,
                signedBy: null,
                signedAt: null,
            },
        };
    },
    startUpdatingSign(payload: { rpName: string }) {
        this.updateSign = {
            ...this.updateSign,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishUpdatingSign(payload: { rpName: string }) {
        this.updateSign = {
            ...this.updateSign,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failUpdatingSign(payload: { rpName: string }) {
        this.updateSign = {
            ...this.updateSign,
            [payload.rpName]: OperationStatus.error,
        };
    },
}));

const { instantiateActions, instantiateModule, getActions } = createActionFactories(({ rpweb }) => ({
    async loadSignDetails(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            this.mutations.startLoadingSignDetails({ rpName });
            const sign = await rpweb.getTermsAndConditionsSign(rpName);
            this.mutations.finishLoadingSignDetails({
                signedAt: sign?.signedAt,
                signedBy: sign?.signedBy,
                rpName,
            });
        } catch (error) {
            this.mutations.failLoadingSignDetails({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async signTerms(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            this.mutations.startUpdatingSign({ rpName });
            await rpweb.signTermsAndConditions(rpName);
            this.mutations.finishUpdatingSign({ rpName });
            this.mutations.finishLoadingSignDetails({
                signedAt: Math.floor(Date.now() / 1000),
                signedBy: 'you',
                rpName,
            });
            await getSnackbarActions(this).displaySnackbar({
                success: true,
                message: 'Successfully accepted the terms and conditions.',
            });
        } catch (error) {
            this.mutations.failUpdatingSign({ rpName });
            await getSnackbarActions(this).displaySnackbar({
                success: false,
                permanent: true,
                message: 'Sorry, something went wrong. Please try again later.',
            });
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
