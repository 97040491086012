import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { Many, ListIteratee } from 'lodash';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBasicObject,
    JsonSchemaType,
    RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

const customPropertyCollateralOrder = [
    'description',
    'category',
    'uniqueIdentifier',
    'limitRequired',
    'maximumDeductible',
];

const schemas: Record<string, JsonSchema> = {
    displayName: {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Display Name',
        description: 'The display name is a custom name unique to this entity.',
        placeholder: 'Type Entity Display Name',
        // FIXME: a proper way to validate trimmed string for empty check
        pattern: '^(?!\\s+$)',
    },
    legalName: {
        type: JsonSchemaType.string,
        title: 'Legal Name',
        description: 'The legal business name is the official name of the entity that appears on legal and government forms.',
        placeholder: 'Type Entity Legal Name',
        pattern: '^(?!\\s+$)',
    },
    doingBusinessAs: {
        type: JsonSchemaType.array,
        title: 'DBA Name(s)',
        addItemTitle: 'ADD DBA',
        items: {
            type: JsonSchemaType.string,
            description: 'The “doing business as” (DBA) name is a different name the entity uses publicly instead of the legal name. ',
            placeholder: 'Type Entity Doing Business Name',
            minLength: 1,
            pattern: '^(?!\\s+$)',
        } as JsonSchema,
    },
    contactEmail: {
        type: JsonSchemaType.string,
        format: 'email',
        minLength: 1,
        title: 'Primary Contact Email',
        placeholder: 'Type entity email',
    },
    contactName: {
        type: JsonSchemaType.string,
        title: 'Primary Contact Name',
        placeholder: 'Type contact name',
    },
    contactPhoneNumber: {
        type: JsonSchemaType.string,
        format: 'phone',
        title: 'Primary Contact Phone Number',
    },
};

const commonPropertiesOrder = [
    'contactEmail',
    'contactName',
    'contactPhoneNumber',
    'insuredFields',
    'exceptions',
];
const commonRequiredFields = [
    'contactEmail',
    'insuredFields',
    'exceptions',
];

function getCustomPropertySchema(
    customProperties: CustomProperty[],
    customPropertiesSortingIteratees: Many<ListIteratee<CustomProperty>> = (_, index) => index,
    customPropertyOrders: Parameters<typeof orderBy>[2] = [],
): JsonSchema {
    return {
        type: JsonSchemaType.object,
        properties: {
            ...customProperties.reduce((accu, field) => {
                let property;
                if (isCollateralCustomProperty(field)) {
                    property = {
                        ...field.schema,
                        items: {
                            ...(field.schema as any).items,
                            propertiesOrder: customPropertyCollateralOrder,
                        },
                    } as JsonSchemaArray;
                } else {
                    const notSpaceOnlyRegex = '^(?!\\s+$)';
                    property = {
                        ...field.schema,
                        // FIXME: a proper way to validate trimmed string for empty check
                        ...(field.required && (field.schema as RegularJsonSchema).type === 'string' && { pattern: notSpaceOnlyRegex }),
                    };
                }
                if ((field.schema as RegularJsonSchema).type === JsonSchemaType.array && field.required) {
                    (property as JsonSchemaArray).minItems = 1;
                }
                accu[field.key] = property;
                return accu;
            }, {} as JsonSchemaBasicObject['properties']),
        },
        propertiesOrder: orderBy(customProperties, customPropertiesSortingIteratees, customPropertyOrders)
            .map((x) => x.key),
        required: customProperties.filter((x) => x.required).map((x) => x.key),
    };
}

function getExceptionsSchema(requirementTypes: string[]): JsonSchema {
    const requirementTypeKeyLabels = requirementTypes.map((x) => (
        { key: x, label: startCase(x.toLowerCase().replace(/_/g, ' ')) }),
    );
    return {
        type: JsonSchemaType.object,
        properties: requirementTypeKeyLabels.reduce((accu, requirementType) => ({
            ...accu, [requirementType.key]: {
                type: JsonSchemaType.string,
                format: 'date',
                title: `Existing ${requirementType.label} Policy Expiration Date`,
            },
        }), {} as JsonSchemaBasicObject['properties']),
        propertiesOrder: [ ...requirementTypes ],
        required: [],
    };
}

export function buildSingleEntityImportJsonSchema(
    customProperties: CustomProperty[],
    requirementTypes: string[] = [],
    customPropertiesSortingIteratees: Many<ListIteratee<CustomProperty>> = (_, index) => index,
    customPropertyOrders: Parameters<typeof orderBy>[2] = []
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            insuredName: {
                type: JsonSchemaType.object,
                title: 'Name(s)',
                properties: {
                    displayName: schemas.displayName,
                    legalName: schemas.legalName,
                    doingBusinessAs: schemas.doingBusinessAs,
                },
                required: [ 'displayName' ],
                propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs' ],
            },
            contactEmail: schemas.contactEmail,
            contactName: schemas.contactName,
            contactPhoneNumber: schemas.contactPhoneNumber,
            insuredFields: getCustomPropertySchema(
                customProperties, customPropertiesSortingIteratees, customPropertyOrders
            ),
            exceptions: getExceptionsSchema(requirementTypes),
        },
        propertiesOrder: [ 'insuredName', ...commonPropertiesOrder ],
        required: [ 'insuredName', ...commonRequiredFields ],
    };
}

export function buildBulkEntityImportJsonSchema(
    customProperties: CustomProperty[],
    requirementTypes: string[] = [],
): JsonSchemaBasicObject {
    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: schemas.displayName,
            legalName: schemas.legalName,
            doingBusinessAs: schemas.doingBusinessAs,
            contactEmail: schemas.contactEmail,
            contactName: schemas.contactName,
            contactPhoneNumber: schemas.contactPhoneNumber,
            insuredFields: getCustomPropertySchema(customProperties),
            exceptions: getExceptionsSchema(requirementTypes),
        },
        propertiesOrder: [ 'displayName', 'legalName', 'doingBusinessAs', ...commonPropertiesOrder ],
        required: [ 'displayName', ...commonRequiredFields ],
    };
}
