<template>
    <Modal :additional-class-name="className" open :allow-close="false">
        <template #above>
            <slot name="icon">
                <div v-if="icon" class="ConfirmationModal__icon">
                    <FontAwesomeIcon :icon="icon" />
                </div>
            </slot>
        </template>

        <template #header>
            <slot name="header">
                {{ title }}
            </slot>
        </template>

        <slot />

        <template #footer>
            <template v-if="progress">
                <Button loading disabled>
                    {{ loadingLabel }}
                </Button>
            </template>
            <template v-else>
                <Button
                    :icon="yesIcon"
                    :type="confirmButtonType"
                    class="ConfirmationModal__button-yes"
                    @click="$emit('input', true)"
                >
                    {{ yes }}
                </Button>
                <Button
                    :icon="noIcon"
                    :type="declineButtonType"
                    class="ConfirmationModal__button-no"
                    @click="$emit('input', false)"
                >
                    {{ no }}
                </Button>
            </template>
        </template>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { Modal } from '../Modal';
    import { Button } from '../Button';

    type ThemeType = 'primary' | 'navy' | 'default';

    const declineButtonTypes: Record<string, string> = {
        primary: 'secondary',
        navy: 'link',
        default: 'gray',
    };

    const destructiveButtonTypes: Record<string, string> = {
        default: 'danger',
    };

    const buttonTypes: Record<string, string> = {
        navy: 'navy',
        primary: 'primary',
        default: 'primary',
    };

    function getRecordString(map: Record<string, string>, name: string | null): string {
        return name == null ? map.default : map[name];
    }

    @Component({
        components: { Button, Modal, FontAwesomeIcon },
    })
    export default class ConfirmationModal extends Vue {
        @Prop({ type: String, default: 'Yes' })
        private yes!: string;

        @Prop({ type: Object, default: () => faCheck })
        private yesIcon!: IconDefinition;

        @Prop({ type: String, default: 'No' })
        private no!: string;

        @Prop({ type: Object, default: null })
        private icon!: IconDefinition | null;

        @Prop({ type: Object, default: () => faTimes })
        private noIcon!: IconDefinition;

        @Prop({ type: Boolean, default: false })
        private destructive!: boolean;

        @Prop({ type: Boolean, default: false })
        private progress!: boolean;

        @Prop({ type: String, default: null })
        private loadingLabel!: string | null;

        @Prop({ type: String, default: null })
        private size!: string | null;

        @Prop({ type: String, default: null })
        private theme!: ThemeType | null;

        @Prop({ type: String, default: 'Are you sure?' })
        private title!: string | null;

        @Prop({ type: String, default: null })
        private additionalClassName!: string | null;

        private get confirmButtonType(): string {
            const classMap = this.destructive ? destructiveButtonTypes : buttonTypes;
            return getRecordString(classMap, this.theme);
        }

        private get declineButtonType(): string {
            return getRecordString(declineButtonTypes, this.theme);
        }

        private get className(): string {
            const mainClassName = 'ConfirmationModal';
            const additionalClassName = this.additionalClassName ? ` ${this.additionalClassName}` : '';
            const size = this.size ? ` ${mainClassName}--${this.size}` : '';
            const theme = this.theme ? ` ${mainClassName}--${this.theme}` : '';
            return `${mainClassName}${size}${theme}${additionalClassName}`;
        }
    }
</script>
