<template>
    <div class="RiskProfileHeader">
        <div class="RiskProfileHeader__headerStart">
            <div class="EntityDetails__backButton" @click="onCloseButtonClick">
                <FontAwesomeIcon
                    class="EntityDetails__headerBackIcon"
                    :icon="faChevronLeft"
                />
            </div>
            <EidIcon
                v-if="riskProfileType"
                :icon-src="riskProfileIconSrc"
                :svg-props="{color: eidTrustGreen, width: '25px', height: '25px', margin: '0 5px'}"
                data-test-id="RiskProfileHeader_riskProfileTypeIcon"
            />
            <div
                v-if="viewMode && !isInvalid"
                class="RiskProfileHeader__riskProfileNameText"
                :class="{'RiskProfileHeader__riskProfileNamePlaceholder': !riskProfileName}"
                @click="displayInput"
            >
                {{ riskProfileName || 'Enter Risk Profile Name' }}
            </div>
            <FormInput v-else>
                <input
                    ref="input"
                    class="RiskProfileHeader__input"
                    :class="{'RiskProfileHeader__input--invalid': isInvalid}"
                    :value="riskProfileName"
                    placeholder="Enter Risk Profile Name"
                    @blur="displayText"
                    @input="onNameInput"
                >
            </FormInput>
            <span class="RiskProfileHeader__country">Country: {{ countryCode }}</span>
        </div>
        <slot name="headerMiddle" />
        <div class="RiskProfileHeader__headerEnd">
            <div class="RiskProfileHeader__headerStatus">
                <span>Status: </span>
                <span
                    class="RiskProfileHeader__headerStatusText"
                    :class="{'RiskProfileHeader__headerStatusText--active': riskProfileActive}"
                    data-test-id="RiskProfileHeader__saveStatus"
                >
                    {{ status }}
                </span>
                <div
                    v-if="dirtyState"
                    class="RiskProfileHeader__saveStatus"
                    data-test-id="RiskProfileHeader__saveStatus"
                >
                    Unsaved Changes
                </div>
            </div>
            <div class="RiskProfileHeader__headerSaveButtonWrapper">
                <RiskProfileSaveButton
                    :disabled="!riskProfileName || saveDisabled"
                    @saveActive="onSave(true)"
                    @saveDiscover="onSave(false)"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import RiskProfileSaveButton
        from '@/modules/decisioning-criteria/components/RiskProfileSaveButton/RiskProfileSaveButton.vue';
    import { getRiskProfileTypeIconSrc } from '@/modules/decisioning-criteria/utils/get-risk-profile-type-icon-src/getRiskProfileTypeIconSrc';
    import { PropType } from 'vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    import { RiskProfileType } from '@evidentid/tprm-portal-lib/models/decisioning';

    @Component({
        components: {
            RiskProfileSaveButton,
            Button,
            EidIcon,
            Expandable,
            Menu,
            MenuLink,
            FontAwesomeIcon,
            FormInput,
            FormElement,
        },
    })
    export default class RiskProfileHeader extends Vue {
        @Prop({ type: String })
        private riskProfileName!: string;

        @Prop({ type: String })
        private countryCode!: string;

        @Prop({ type: Boolean, default: false })
        private riskProfileActive!: boolean;

        @Prop({ type: Boolean, default: false })
        private dirtyState!: boolean;

        @Prop({ type: Boolean, default: false })
        private saveDisabled!: boolean;

        @Prop({ type: [ String, null ] as PropType<RiskProfileType | null>, default: null })
        private riskProfileType!: RiskProfileType | null;

        private faChevronLeft = faChevronLeft;
        private eidTrustGreen = tailwindColors.eidTrustGreen.DEFAULT;
        private isDirty = false;
        private viewMode: boolean = true;

        private get status(): string {
            return this.riskProfileActive ? 'Active' : 'Draft';
        }

        private get isInvalid(): boolean {
            return this.isDirty && !this.riskProfileName;
        }

        private get riskProfileIconSrc(): string {
            return this.riskProfileType ? getRiskProfileTypeIconSrc(this.riskProfileType) : '';
        }

        private onCloseButtonClick(): void {
            this.$emit('back');
        }

        private onNameInput(event: InputEvent): void {
            this.isDirty = true;
            this.$emit('input', (event.target as HTMLInputElement).value);
        }

        private async displayInput(): Promise<void> {
            this.viewMode = false;
            await this.$forceUpdate();
            (this.$refs.input as HTMLInputElement).focus();
        }

        private displayText(): void {
            this.viewMode = true;
        }

        private onSave(isActive: boolean): void {
            this.$emit('save', { isActive });
        }
    }
</script>
