<template>
    <form ref="formElement" :class="finalClassName" @submit="onSubmit">
        <slot
            :fields="_controller.structure.properties"
            :data="_controller.value"
            :disabled="_controller.disabled"
            :valid="_controller.structure.valid"
        />

        <!-- Allow submission with Enter key -->
        <button type="submit" style="display: none" />
    </form>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import JsonForm, { JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import { FormController } from '@evidentid/json-schema/FormController';
    import { Form } from '../Form';
    import { JsonSchemaFormStrings } from './types';

    @Component({
        components: { Form },
    })
    export default class JsonSchemaCustomForm extends Vue {
        @Prop({ type: Object, default: null })
        private form!: JsonForm;

        @Prop({ type: Object, default: null })
        private controller!: FormController;

        @Prop({ type: String, default: '' })
        private className!: string;

        @Prop()
        private value!: any;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private touched!: boolean;

        @Prop({ type: Boolean, default: true })
        private scrollToErrors!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private strings!: Partial<JsonSchemaFormStrings>;

        @Ref()
        private formElement!: Form;

        private isMounted = false;
        private _controller: FormController = this.controller || new FormController(this.form, this.value);
        private unsubscribe: (() => void) | null = null;
        private unsubscribeDisabled: (() => void) | null = null;

        public get $customJsonSchemaForm(): true {
            return true;
        }

        @Watch('form', { immediate: true })
        @Watch('controller')
        private updateController(): void {
            const baseForm = this.form || this.controller?.form;
            if (!baseForm) {
                throw new Error('You need to pass either "form" or "controller" for JsonSchemaCustomForm.');
            } else if (baseForm.type !== JsonFormType.object) {
                throw new Error('Only "object" forms are allowed for JsonSchemaCustomForm.');
            }
            this._controller = this.controller || new FormController(this.form, this.value);
            this._controller.disabled = this.disabled;
            this._controller.touched = this.touched;
        }

        @Watch('_controller', { immediate: true })
        private subscribeToChanges(): void {
            this.unsubscribe?.();
            this.unsubscribe = this._controller?.onValueChange((value) => {
                this.$emit('input', value);
                this.$forceUpdate();
            }) || null;
            this.unsubscribeDisabled?.();
            this.unsubscribe = this._controller?.onDisabledChange(() => {
                this.$forceUpdate();
            }) || null;
        }

        private mounted(): void {
            this.isMounted = true;
        }

        private destroyed(): void {
            this.isMounted = false;
            this.unsubscribe?.();
            this.unsubscribeDisabled?.();
            this.unsubscribe = null;
            this.unsubscribeDisabled = null;
        }

        private get finalClassName(): string {
            return `JsonSchemaCustomForm ${this.className}`;
        }

        @Watch('value', { immediate: true, deep: true })
        private updateCurrentValue(): void {
            this._controller.value = this.value;
        }

        @Watch('disabled', { immediate: true })
        private updateDisabled(): void {
            this._controller.disabled = this.disabled;
        }

        private onSubmit(event: Event): void {
            event.preventDefault();
            if (!this.disabled) {
                this.touch();
                this.$emit('submit');
            }
        }

        private scrollToError(): void {
            if (!this.isMounted || !this.scrollToErrors) {
                return;
            }
            const element = this.formElement?.dom?.querySelector('.FormInput--error');
            if (!element) {
                return;
            }
            if (element.scrollIntoView) {
                element.scrollIntoView({ block: 'end' });
            }
            const innerElement = element.querySelector('input') as HTMLElement | null;
            if (innerElement) {
                innerElement.focus();
            }
        }

        public touch(): void {
            this._controller.touched = true;
        }

        /**
         * Component public method for the usage by ref too.
         */
        public scrollToFirstError(delay = true): void {
            if (delay) {
                requestAnimationFrame(() => this.scrollToError());
            } else {
                this.scrollToError();
            }
        }
    }
</script>
