import { render, staticRenderFns } from "./CriterionSettings.vue?vue&type=template&id=94c51f94&"
import script from "./CriterionSettings.vue?vue&type=script&lang=ts&"
export * from "./CriterionSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports