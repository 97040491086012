import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faCopy = {
    prefix: 'fa',
    iconName: 'faCopy',
    icon: [
        15,
        15,
        [],
        'e002',
        'M14.7266 2.69141L13.0586 1.02344C12.8945 0.859375 12.6758 0.75 12.4297 0.75H7.97266C6.98828 0.75 6.22266 1.54297 6.22266 2.5V9.5C6.25 10.4844 7.01562 11.25 8 11.25H13.25C14.207 11.25 15 10.4844 15 9.5V3.32031C15 3.07422 14.8906 2.85547 14.7266 2.69141ZM13.6875 9.5C13.6875 9.74609 13.4688 9.9375 13.25 9.9375H7.97266C7.72656 9.9375 7.53516 9.74609 7.53516 9.5V2.52734C7.53516 2.28125 7.72656 2.08984 7.97266 2.08984H11.4727L11.5 3.375C11.5 3.86719 11.8828 4.25 12.375 4.25H13.6602V9.5H13.6875ZM8.4375 13C8.4375 13.2461 8.21875 13.4375 8 13.4375H2.72266C2.47656 13.4375 2.28516 13.2461 2.28516 13V6.02734C2.28516 5.78125 2.50391 5.58984 2.72266 5.58984H5.375V4.25H2.72266C1.76562 4.25 0.972656 5.04297 0.972656 6L1 13C1 13.9844 1.76562 14.75 2.75 14.75H8C8.95703 14.75 9.75 13.9844 9.75 13V12.125H8.4375V13Z',
    ],
} as any as IconDefinition;
