import { ComplianceStatusMetadata } from '@evidentid/tprm-portal-lib/models/entity-details';

import {
    EntityRequirement,
    EntityRequirementDetails,
} from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

export type AssignmentRuleValue = string | number | boolean;

export interface BasicAssignmentRule {
    operator: string;
    insuredFieldKey: string;
    value: AssignmentRuleValue;
}

export enum RiskProfileAssignmentOptions {
    allInsureds = 'ALL_INSUREDS',
    subsetOfInsureds = 'SUBSET_OF_INSUREDS',
}

export interface FieldEvaluationResultErrorMessage {
    message: string;
    coverageCriteriaGroupName: string;
}

export interface FieldEvaluationResultError {
    evaluatedEntityId?: string;
    useDuringComplianceCalculation?: boolean;
    messages: FieldEvaluationResultErrorMessage[];
    coverageCriteriaGroupId: string;
    criterionId: string[];
    criterionNames: string[];
    coverageDetailsBelongTo: EntityRequirementDetails;
    complianceStatusMetadata: ComplianceStatusMetadata[];
    isMultiFieldCriterion: boolean;
}

export const requirementTypeFieldReferenceDelimiter = '-';

export enum GeneralAggregateLimitReferences {
    generalAggregateLimitThreshold = 'generalAggregateLimitThreshold',
    conditionSets = 'conditionSets',
}
