import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createModuleBuilder } from '../../app';

export function createRpWebApiClientModule() {
    return createModuleBuilder()
        .demandOptions<{ rpwebApiUrl: string }>()
        .demandContext<{ auth: { getTokens(): Promise<{ accessToken: string | null, idToken: string | null }> } }>()
        .inject(async (app) => {
            const rpweb = new RpWebApiClient(app.options.rpwebApiUrl);
            rpweb.setTokens(() => app.auth.getTokens());
            return { rpweb };
        })
        .end();
}

export type RpWebApiClientModule = ReturnType<typeof createRpWebApiClientModule>;
