<template>
    <div class="EmptyValueDashSymbol">
        <FontAwesomeIcon :icon="faMinus" />
    </div>
</template>
<style lang="scss">
    .EmptyValueDashSymbol {
        font-size: 10px;
        color: #778ea1;
        opacity: 0.7;
    }
</style>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faMinus } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class EmptyValueDashSymbol extends Vue {
        private faMinus = faMinus;
    }
</script>
