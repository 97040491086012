var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PopoverCollateralFilters"},[_c('h3',{staticClass:"m-0 mt-5 mx-2.5 text-eidTrustGreen font-bold text-sm"},[_vm._v(" Collateral ")]),_vm._l((_vm.collateralFiltersFields),function(ref){
var name = ref.name;
var label = ref.label;
var placeholder = ref.placeholder;
var type = ref.type;
return [_c('CustomPropertyFilterField',{key:name,staticClass:"inline-block",class:{
                'w-full': name === 'description',
                'w-1/2': name !== 'description'
            },attrs:{"data-test-id":("PopoverCollateralFilters__" + name),"value":_vm.filters[_vm.getEntityCollateralFieldQueryKey(_vm.collateralCustomPropertyKey, name)] || null,"type":type,"label":label,"placeholder":placeholder,"filter-key":name},on:{"input":_vm.inputFilter,"scroll-to":_vm.emitScrollDistance}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }