import features from '@evidentid/browser-framework/features';
import { createModuleBuilder } from '../../app';

const support = features(window);

export function createSupportModule() {
    return createModuleBuilder()
        .inject(async (app) => ({ support }))
        .injectVue((app) => ({ $support: app.support }))
        .end();
}

export type SupportModule = ReturnType<typeof createSupportModule>;
