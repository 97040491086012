import { DeclineReason } from '@evidentid/tprm-portal-lib/models/entity-details/DeclineReason.model';

export const DECLINE_REASON_TEXTS: Record<DeclineReason, string> = {
    [DeclineReason.Declined]: 'Declined',
    [DeclineReason.Missing]: 'Did not have',
    [DeclineReason.NotRenewed]: 'Not renewing',
    [DeclineReason.ExceptionRequested]: 'Exception requested',
};

export const DECLINE_REASON_TOOLTIP_TEXTS: Record<DeclineReason, string> = {
    [DeclineReason.Declined]: 'Insured declined this coverage',
    [DeclineReason.Missing]: 'Insured does not have this coverage',
    [DeclineReason.NotRenewed]: 'Insured not renewing this coverage',
    [DeclineReason.ExceptionRequested]: 'Insured requested an exception/waiver',
};

