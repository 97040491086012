<template>
    <article class="Tile" :class="additionalClassName" @click="onArticleClicked">
        <header v-if="title" class="Tile__title">
            {{ title }}
        </header>
        <div class="Tile__content">
            <div v-if="icon" class="Tile__icon">
                <img v-if="typeof icon === 'string'" :src="icon" alt="">
                <FontAwesomeIcon v-else :icon="icon" />
            </div>
            <div class="Tile__value">
                <slot />
            </div>
        </div>
        <div class="Tile__bar">
            <div class="Tile__barInner" :style="barStyle" />
        </div>
        <div v-if="$scopedSlots.label" class="Tile__label">
            <slot name="label" />
        </div>
    </article>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Tile extends Vue {
        @Prop({ type: String, default: null })
        private title!: string | null;

        @Prop({ type: String, default: null })
        private color!: string | null;

        @Prop({ default: null })
        private icon!: IconDefinition | string; // FontAwesome icon or image URL

        @Prop({ type: Number, default: 1 })
        private value!: number; // Between 0 and 1

        @Prop({ type: Boolean, default: false })
        private selected!: boolean;

        @Prop({ type: Boolean, default: false })
        private clickable!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private get additionalClassName() {
            return {
                [`Tile--color-${this.color}`]: this.color,
                'Tile--selected': this.selected,
                'Tile--clickable': this.clickable,
                'Tile--disabled': this.disabled,
            };
        }

        private get barStyle() {
            const value = Math.max(0, Math.min(100, this.value * 100));
            return `width: ${value}%`;
        }

        private onArticleClicked(e: MouseEvent): void {
            if (!this.disabled) {
                this.$emit('click');
            }
        }
    }
</script>
