import { fieldsUseNewJsonForm } from '@/modules/decisioning-criteria/components/DynamicCriterionSetting/constants';

import { CriterionTemplate } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

export function isNewJsonSchemaForm(template: CriterionTemplate): boolean {
    return fieldsUseNewJsonForm.includes(template.field);
}

export function isPublicOrProductsLiability(template: CriterionTemplate): boolean {
    return [
        'coverage.publicLiability',
        'coverage.productsLiability',
    ].includes(template.field);
}
