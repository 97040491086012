import { EvaluationResult } from '@evidentid/tprm-portal-lib/models/entity-details';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
import getIn from 'lodash/get';
import isNil from 'lodash/isNil';
import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/entity-details/constant';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import {
    EntityRequirementDetails,
} from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

export function getMultiFieldCriteria(
    evaluationResults: EvaluationResult[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    requirementDetailsList: EntityRequirementDetails[],
    categorizedEnumLabels: CategorizedEnumLabels
): EntityMultiFieldCriterion[] {
    const multiFieldCriteria = evaluationResults.reduce((acc, evaluationResult) => {
        if (evaluationResult.usedFields.length > 1) {
            const entityMultiFieldCriterion: EntityMultiFieldCriterion = {
                name: evaluationResult.criterionName,
                fields: evaluationResult.usedFields.reduce((fields, field) => {
                    const parts = field.split('/');
                    const coverageName = parts.at(1);
                    const name = parts.at(-1);
                    const requirementDetails = requirementDetailsList
                        .find((details) => coverageName === details.coverage?.coverageType)
                        ?.coverage?.details;
                    if (name) {
                        const value = getIn(requirementDetails, parts.slice(3), null);
                        fields[name] = !isNil(value) ?
                            getEnumDefaultDisplayValue(name, value, categorizedEnumLabels) :
                            FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                    return fields;
                }, {} as Record<string, unknown>),
                evaluationError: evaluationErrors[evaluationResult.criterionName],
            };
            acc.push(entityMultiFieldCriterion);
        }

        return acc;
    }, [] as EntityMultiFieldCriterion[]);
    return uniqWith(multiFieldCriteria, isEqual);
}
