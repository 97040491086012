<template>
    <div
        class="RiskProfilesExpandableRows"
        :class="{'RiskProfilesExpandableRows--expanded': expanded}"
    >
        <div class="RiskProfilesExpandableRows__summaryRowContainer">
            <div class="RiskProfilesExpandableRows__summaryRow">
                <div class="RiskProfilesExpandableRows__summaryBranch">
                    <div class="RiskProfilesExpandableRows__expandableCaret" @click="toggle">
                        <FontAwesomeIcon v-if="expanded" :icon="faCaretDown" />
                        <FontAwesomeIcon v-else :icon="faCaretUp" />
                    </div>
                    <div class="RiskProfilesExpandableRows__summaryBranchLower" />
                </div>
                <div class="RiskProfilesExpandableRows__cells">
                    <div class="RiskProfilesExpandableRows__summaryNameCell">
                        <span @click="toggle">
                            Effective Profile:
                        </span>
                    </div>
                    <div class="RiskProfilesExpandableRows__summaryRequirementTypesContentCell">
                        {{ summaryRequirementTypes }}
                    </div>
                    <div class="RiskProfilesExpandableRows__summaryComplianceStatusContentCell">
                        <ComplianceStatusBadge :status="entity.complianceStatus" />
                    </div>
                </div>
            </div>
        </div>
        <template v-if="expanded">
            <div
                v-for="profile in entityRiskProfiles"
                :key="profile.id"
                class="RiskProfilesExpandableRows__rowContainer"
            >
                <div class="RiskProfilesExpandableRows__row">
                    <div class="RiskProfilesExpandableRows__branch">
                        <div class="RiskProfilesExpandableRows__branchUpper" />
                        <div class="RiskProfilesExpandableRows__branchLower" />
                    </div>
                    <div class="RiskProfilesExpandableRows__cells">
                        <div class="RiskProfilesExpandableRows__displayNameContentCell">
                            <span @click="$emit('goToRiskProfile', profile.id)">
                                {{ profile.displayName }}
                            </span>
                        </div>
                        <div class="RiskProfilesExpandableRows__requirementTypesContentCell">
                            {{ profile.coverageTypes.length }}
                        </div>
                        <div class="RiskProfilesExpandableRows__complianceStatusContentCell">
                            <ComplianceStatusBadge :status="profile.complianceStatus" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import union from 'lodash/union';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { Button, ComplianceStatusBadge, FontAwesomeIcon },
    })
    export default class RiskProfilesExpandableRows extends Vue {
        @Prop({ type: Array, default: () => [] })
        private entityRiskProfiles!: EntityRiskProfile[];

        @Prop({ type: Object, required: true })
        private entity!: Entity;

        private faCaretDown = faCaretDown;
        private faCaretUp = faCaretUp;
        private expanded = true;

        private get summaryRequirementTypes(): number {
            return this.entityRiskProfiles
                .reduce((accu, profile) => union(accu, profile.coverageTypes), [] as TprmRequirementType[])
                .length;
        }

        private toggle(): void {
            this.expanded = !this.expanded;
        }
    }
</script>
