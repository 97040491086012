var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CriterionSettingsRow",class:{
        'CriterionSettingsRow--nonSubstitutable':
            !_vm.isArrayWithObject(_vm.schema) &&
            !_vm.schemaHaveSubstitutableSchemaWithValue(_vm.schema),
        'CriterionSettingsRow--substitutable':
            _vm.schemaHaveSubstitutableSchemaWithValue(_vm.schema),
        'CriterionSettingsRow--conditionSets':
            _vm.isKeyConditionSets(_vm.keyName),
    }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }