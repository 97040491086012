import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import Dashboard from '@/modules/dashboard/views/Dashboard.vue';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuexDashboardModule from './vuex/dashboard';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';

export const dashboardModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/dashboard',
            name: 'dashboard',
            component: Dashboard as any,
            meta: { title: 'Dashboard' },
        },
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ dashboard: vuexDashboardModule.instantiateModule })
    .execute((app) => {
        function onLoadEntitiesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entities',
                    description: `[Requested] Get entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Requested] Get entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadEntitiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entities',
                    description: `[Finished] Get entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Finished] Get entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadEntitiesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entities Failed',
                    description: `[Failed] Get entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entities',
                    message: `[Failed] Get entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadCustomPropertiesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Fields',
                    description: `[Requested] Get custom properties for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entity Fields',
                    message: `[Requested] Get custom properties for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadCustomPropertiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Fields',
                    description: `[Finished] Get custom properties for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entity Fields',
                    message: `[Finished] Get custom properties for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadCustomPropertiesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Fields Failed',
                    description: `[Failed] Get custom properties for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entity Fields',
                    message: `[Failed] Get custom properties for ${rpName}`,
                    data: { rpName },
                });
            }
        }
        function onLoadRequirementTypesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types',
                    description: `[Requested] Get requirementTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Coverage Types',
                    message: `[Requested] Get requirementTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequirementTypesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types',
                    description: `[Finished] Get requirementTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Coverage Types',
                    message: `[Finished] Get requirementTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequirementTypesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types Failed',
                    description: `[Failed] Get requirementTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Coverage Types',
                    message: `[Failed] Get requirementTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadEntityStatisticsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Statistics',
                    description: `[Requested] Get entity Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entity Statistics',
                    message: `[Requested] Get entity Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadEntityStatisticsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Statistics',
                    description: `[Finished] Get entity Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entity Statistics',
                    message: `[Finished] Get entity Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadEntityStatisticsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Entity Statistics Failed',
                    description: `[Failed] Get entity Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entity Statistics',
                    message: `[Failed] Get entity Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config',
                    description: `[Requested] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Requests Config',
                    message: `[Requested] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config',
                    description: `[Finished] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Requests Config',
                    message: `[Finished] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config Failed',
                    description: `[Failed] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Requests Config',
                    message: `[Failed] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onChange((state) => (state as any).route, (newRoute, oldRoute) => {
            if (newRoute.params.rpId !== oldRoute.params.rpId) {
                app.store.actions.dashboard.loadCategorizedEnumLabels({ rpName: newRoute.params.rpId });
            }
        });
        app.observer.onMutation('startEntitiesListUpdate', (x) => onLoadEntitiesRequest(x.rpName));
        app.observer.onMutation('pushToEntitiesList', (x) => onLoadEntitiesFinish(x.rpName));
        app.observer.onMutation('failEntitiesListUpdate', (x) => onLoadEntitiesFailure(x.rpName));
        app.observer.onMutation('startLoadingCustomProperties', (x) => onLoadCustomPropertiesRequest(x.rpName));
        app.observer.onMutation('finishLoadingCustomProperties', (x) => onLoadCustomPropertiesFinish(x.rpName));
        app.observer.onMutation('failLoadingCustomProperties', (x) => onLoadCustomPropertiesFailure(x.rpName));
        app.observer.onMutation('startLoadingRequirementTypes', (x) => onLoadRequirementTypesRequest(x.rpName));
        app.observer.onMutation('finishLoadingRequirementTypes', (x) => onLoadRequirementTypesFinish(x.rpName));
        app.observer.onMutation('failLoadingRequirementTypes', (x) => onLoadRequirementTypesFailure(x.rpName));
        app.observer.onMutation('startLoadingEntityStatistics', (x) => onLoadEntityStatisticsRequest(x.rpName));
        app.observer.onMutation('finishLoadingEntityStatistics', (x) => onLoadEntityStatisticsFinish(x.rpName));
        app.observer.onMutation('failLoadingEntityStatistics', (x) => onLoadEntityStatisticsFailure(x.rpName));
        app.observer.onMutation('startLoadingRequestsConfig', (x) => onLoadRequestsConfigRequest(x.rpName));
        app.observer.onMutation('finishLoadingRequestsConfig', (x) => onLoadRequestsConfigFinish(x.rpName));
        app.observer.onMutation('failLoadingRequestsConfig', (x) => onLoadRequestsConfigFailure(x.rpName));
    })
    .end();
