<template>
    <label class="RadioOption" :class="{ 'RadioOption--disabled': disabled }">
        <input
            class="RadioOption__input"
            type="radio"
            :name="name"
            :value="currentValue"
            :checked="checked"
            :disabled="disabled"
            v-on="filteredListeners"
            @input="onInput"
        >
        <span class="RadioOption__icon" />
        <span class="RadioOption__description">
            <slot />
        </span>
    </label>
</template>

<style lang="scss">
    .RadioOption {
        display: flex;
        cursor: pointer;

        &__icon {
            position: relative;
            display: block;
            top: 0.2em;
            margin-right: 10px;
            border-radius: 50%;
            width: 1em;
            height: 1em;
            min-width: 1em;
            min-height: 1em;
            line-height: 1em;
            background: #fff;
            border: 2px solid #fff;
            box-shadow: 0 0 0 1px #ddd;
        }

        &__input {
            display: none;
        }

        &__input:checked + &__icon {
            background: #2ab496;
            box-shadow: 0 0 0 1px #2ab496;
        }

        &--disabled {
            cursor: not-allowed;
        }

        &--disabled &__icon {
            background: #ddd;
            border-color: #ddd;
            box-shadow: 0 0 0 1px #ccc;
        }

        &--disabled &__input:checked + &__icon {
            background: #aaa;
            box-shadow: 0 0 0 1px #aaa;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import omit from 'lodash/omit';

    @Component
    export default class RadioOption extends Vue {
        @Prop({ type: String })
        private name!: string;

        @Prop({ type: String, default: null })
        private value!: string | null;

        @Prop({ type: Boolean, default: false })
        private checked!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private uniqueValue = `${Date.now()}.${Math.random()}.${Math.random()}`;

        private get filteredListeners(): Record<string, Function | Function[]> {
            return omit(this.$listeners, [ 'input' ]);
        }

        private get currentValue(): string {
            return this.value == null ? this.uniqueValue : this.value;
        }

        private onInput(event: Event): void {
            if (!this.disabled) {
                this.$emit('input', event);
            }
        }
    }
</script>
