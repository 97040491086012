<template>
    <Modal
        additional-class-name="EditEmailTemplateModal"
        :allow-close="!submitting"
        :open="open"
        form
        @close="$emit('close')"
        @submit="onSubmit"
    >
        <template #header>
            Edit email template
        </template>

        <JsonSchemaCustomForm v-slot="{ fields }" v-model="value" :disabled="submitting" :form="form">
            <JsonSchemaCustomFormElement :field="fields.summaryTemplate" />
            <JsonSchemaCustomFormElement :field="fields.descriptionTemplate" />
        </JsonSchemaCustomForm>

        <template #footer>
            <Button type="primary" submit :loading="submitting" :disabled="!valid || submitting">
                Save
            </Button>
        </template>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import createForm from '@evidentid/json-schema/createForm';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { buildEmailTemplateSchema } from '../schemas/buildEmailTemplateSchema';
    import { EmailTemplate } from '../types';

    @Component({
        components: {
            Modal,
            Button,
            JsonSchemaCustomForm,
            JsonSchemaCustomFormElement,
        },
    })
    export default class EditEmailTemplateModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: string[];

        @Prop({ type: Object, default: () => ({}) })
        private initialValue!: EmailTemplate;

        private form = createForm(buildEmailTemplateSchema(this.customProperties));
        private value = this.form.getValue(this.initialValue, true);

        @Watch('customProperties')
        private onCustomPropertiesChange(): void {
            this.form = createForm(buildEmailTemplateSchema(this.customProperties));
        }

        private get valid(): boolean {
            return this.form.isValid(this.value, true);
        }

        private onSubmit(event: Event): void {
            event.preventDefault();
            if (this.valid && !this.submitting) {
                this.$emit('submit', this.value);
            }
        }
    }
</script>
