import range from 'lodash/range';
import { SchedulePatternType } from '@evidentid/config-api-client/types';
import timezones from '@evidentid/common-datasets/timezones.json';
import {
    JsonSchemaArray,
    JsonSchemaBoolean,
    JsonSchemaEnum,
    JsonSchemaInteger,
    JsonSchemaObject,
    JsonSchemaType,
} from '@evidentid/json-schema/interfaces/JsonSchema';

interface Timezone {
    name: string;
    city: string;
}

const allowedTypes = [
    SchedulePatternType.interval,
    SchedulePatternType.schedule,
    SchedulePatternType.sequence,
];

const allowedTypesLabels: Record<SchedulePatternType, string> = {
    [SchedulePatternType.interval]: 'Interval',
    [SchedulePatternType.schedule]: 'Schedule',
    [SchedulePatternType.sequence]: 'Sequence',
};

export function buildReminderSchema(
    types: SchedulePatternType[],
    allowDaysBefore = false,
): JsonSchemaObject {
    // Compute allowed reminder types
    const reminderTypes = allowedTypes.filter((type) => types.includes(type));
    const reminderTypeLabels = reminderTypes.map((type) => allowedTypesLabels[type]);

    // Build schema
    const recurrenceSchema: JsonSchemaEnum = {
        title: 'Reminder recurrence type',
        description: 'Choose recurrence type',
        enum: reminderTypes,
        enumLabels: reminderTypeLabels,
    };

    const dayOfWeekSchema: JsonSchemaEnum = {
        enum: range(1, 8),
        enumLabels: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
    };

    const daysOfWeekSchema: JsonSchemaArray = {
        type: JsonSchemaType.array,
        title: 'Days of week',
        description: 'Choose day of week',
        addItemTitle: 'Add day',
        items: dayOfWeekSchema,
        minItems: 1,
    };

    const intervalAmountSchema: JsonSchemaInteger = {
        type: JsonSchemaType.integer,
        description: 'Choose number',
        minimum: 1,
        maximum: 200,
    };

    const daysBeforeCountSchema: JsonSchemaEnum = {
        description: 'Select number',
        enum: [
            15,
            30,
            45,
            60,
            75,
            90,
        ],
        enumLabels: [
            '15 days',
            '30 days',
            '45 days',
            '60 days',
            '75 days',
            '90 days',
        ],
    };

    const intervalUnitSchema: JsonSchemaEnum = {
        description: 'Choose interval period',
        enum: [
            'hours',
            'days',
        ],
        enumLabels: [
            'Hours',
            'Days',
        ],
    };

    const intervalLabelsSchemaPartial: any = {
        if: {
            properties: { unit: { const: 'hours' } },
        },
        then: {
            properties: {
                amount: { ...intervalAmountSchema, maximum: 50, description: 'Number of hours' },
            },
        },
        else: {
            if: {
                properties: { unit: { const: 'days' } },
            },
            then: {
                properties: {
                    amount: { ...intervalAmountSchema, maximum: 35, description: 'Number of days' },
                },
            },
            else: {},
        },
    };

    const intervalSchema: JsonSchemaObject = {
        type: JsonSchemaType.object,
        title: 'Every',
        properties: {
            amount: intervalAmountSchema,
            unit: intervalUnitSchema,
        },
        required: [ 'amount', 'unit' ],
        ...intervalLabelsSchemaPartial,
    };

    const hourSchema: JsonSchemaEnum = {
        description: 'Select time',
        enum: range(0, 24),
        enumLabels: [
            '12 am',
            ...range(1, 12).map((x) => `${x} am`),
            '12 pm',
            ...range(1, 12).map((x) => `${x} pm`),
        ],
    };

    const sequenceDaysSchema: JsonSchemaArray = {
        type: JsonSchemaType.array,
        items: {
            type: JsonSchemaType.integer,
            minimum: 1,
        } as JsonSchemaInteger,
    };

    const sendsAtSchema: JsonSchemaArray = {
        type: JsonSchemaType.array,
        title: 'Sends at',
        minItems: 1,
        items: hourSchema,
        addItemTitle: 'Add time',
    };

    const timezoneSchema: JsonSchemaEnum = {
        title: 'Timezone',
        description: 'Choose timezone',
        enum: (timezones as Timezone[]).map((x) => x.city),
        enumLabels: (timezones as Timezone[]).map((x) => `${x.name} - ${x.city.replace(/_/g, ' ')}`),
    };

    const limitSchema: JsonSchemaEnum = {
        title: 'Max. number of reminders to be sent',
        description: 'Choose limit',
        enum: [
            ...range(1, 31),
            10000,
        ],
        enumLabels: [
            ...range(1, 31).map((x) => `${x}`),
            'Unlimited',
        ],
    };
    const initEnabledSchema: JsonSchemaBoolean = {
        type: JsonSchemaType.boolean,
        title: 'Send Initial Notification',
        description: 'The first email is sent automatically when a new entity is added to a risk profile. ',
    };

    const remindersEnabledSchema: JsonSchemaBoolean = {
        type: JsonSchemaType.boolean,
        title: 'Send Reminder Notifications',
        description: 'Configure your reminder cadence to send emails to entities until a submission is completed.',
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            recurrence: recurrenceSchema,
            initEnabled: initEnabledSchema,
            remindersEnabled: remindersEnabledSchema,
        },
        required: [ 'recurrence' ],
        if: {
            properties: { recurrence: { const: SchedulePatternType.interval } },
        },
        then: {
            properties: {
                interval: intervalSchema,
                limit: limitSchema,
            },
            required: [
                'interval',
                'limit',
            ],
        },
        else: {
            if: {
                properties: { recurrence: { const: SchedulePatternType.schedule } },
            },
            then: {
                properties: {
                    daysOfWeek: daysOfWeekSchema,
                    sendsAt: sendsAtSchema,
                    timezone: timezoneSchema,
                    limit: limitSchema,
                },
                required: [
                    'daysOfWeek',
                    'sendsAt',
                    'timezone',
                    'limit',
                ],
            },
            else: allowDaysBefore
                ? {
                    properties: {
                        daysBeforeCount: daysBeforeCountSchema,
                        daysBefore: sequenceDaysSchema,
                        daysAfter: sequenceDaysSchema,
                    },
                    required: [
                        'daysBeforeCount',
                        'daysBefore',
                        'daysAfter',
                    ],
                }
                : {
                    properties: {
                        daysAfter: sequenceDaysSchema,
                    },
                    required: [
                        'daysAfter',
                    ],
                },
        },
    };
}
