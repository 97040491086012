<template>
    <div
        class="JsonSchemaNumberForm"
        :class="{'JsonSchemaNumberForm--focus': focused}"
    >
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>{{ typeLabel }}</span>
                </div>
            </template>
            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <input
                    :id="id"
                    :type="temporaryString ? 'text' : 'number'"
                    :disabled="disabled"
                    :value="viewValue"
                    :min="minimum"
                    :max="maximum"
                    :step="multipleOf"
                    :placeholder="form.schema.placeholder"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @keydown="filterInputCharacter"
                    @change="onChange"
                >
                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaInteger, JsonSchemaNumber, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { numberToString } from '../../../utils/numericUtilities';
    import { FormElement, FormInput } from '../../Form';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormNumeric = JsonFormBase<JsonSchemaNumber> | JsonFormBase<JsonSchemaInteger>;

    @Component({
        components: { FormElement, FormInput, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaNumberForm extends AbstractJsonSchemaForm<JsonFormNumeric, string | number> {
        private faExclamationCircle = faExclamationCircle;
        private faTimes = faTimes;

        // Used to decide if the input shouldn't be temporarily without [type=number] to show actual value
        protected get temporaryString(): boolean {
            return isNaN(this.viewValue.replace(/[eE]/, 'x') as any) && this.viewValue.trim().length > 0;
        }

        protected override getCustomError(): string | null {
            const value = Number(this.value);
            const meta = {
                type: this.form.schema.type === JsonSchemaType.integer ? 'integer' : 'number',
                minimum: this.minimum,
                maximum: this.maximum,
                multipleOf: this.multipleOf,
            };
            if (isNaN(value)) {
                return this.translate('error.number.invalid', meta);
            } else if (this.form.schema.type === JsonSchemaType.integer && Math.floor(value) !== value) {
                return this.translate('error.number.integer', meta);
            } else if (this.minimum !== undefined && value < this.minimum) {
                return this.translate('error.number.tooSmall', meta);
            } else if (this.maximum !== undefined && value > this.maximum) {
                return this.translate('error.number.tooBig', meta);
            } else if (this.form.schema.multipleOf !== undefined && value % this.form.schema.multipleOf !== 0) {
                return this.translate('error.number.multipleOf', meta);
            }
            return null;
        }

        private filterInputCharacter(inputEvent: KeyboardEvent): void {
            const comboKey = inputEvent.metaKey || inputEvent.ctrlKey || inputEvent.altKey;
            if (!comboKey && inputEvent.key?.length === 1 && !/^[\d\-+\s().]$/.test(inputEvent.key)) {
                inputEvent.preventDefault();
            }
        }

        private get minimum() {
            const { minimum, exclusiveMinimum, type } = this.form.schema;

            if (minimum !== undefined) {
                return minimum;
            }

            return exclusiveMinimum === undefined
                ? undefined
                : type === JsonSchemaType.integer ? exclusiveMinimum + 1 : exclusiveMinimum;
        }

        private get maximum() {
            const { maximum, exclusiveMaximum, type } = this.form.schema;

            if (maximum !== undefined) {
                return maximum;
            }

            return exclusiveMaximum === undefined
                ? undefined
                : type === JsonSchemaType.integer ? exclusiveMaximum - 1 : exclusiveMaximum;
        }

        private get multipleOf() {
            if (this.form.schema.multipleOf) {
                return this.form.schema.multipleOf;
            }

            return this.form.schema.type === JsonSchemaType.integer ? '1' : 'any';
        }

        private get viewValue() {
            return this.value == null ? '' : typeof this.value === 'number' ? numberToString(this.value) : this.value;
        }

        private get typeLabel() {
            return this.form.schema.type === JsonSchemaType.integer ? 'Integer Only' : 'Number Only';
        }

        private onInput(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }

        private onChange(event: Event): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('change', this.form.getValue(inputValue));
        }
    }
</script>
