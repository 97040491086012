import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

export function getEvaluationResultsFromEvaluationErrors(
    url: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    requirement: EntityRequirement
): FieldEvaluationResultError | null {
    return evaluationErrors[`#/${requirement.coverageType}/${url}`] || null;
}
