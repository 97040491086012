<template>
    <tr
        class="TableRow"
        :class="{
            'TableRow--clickable': clickable,
            'TableRow--hoverable': hoverable,
            'TableRow--alignTop': alignTop,
        }"
        v-on="$listeners"
    >
        <slot />
    </tr>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class TableRow extends Vue {
        @Prop({ type: Boolean, default: false })
        private clickable!: boolean;

        @Prop({ type: Boolean, default: false })
        private hoverable!: boolean;

        @Prop({ type: Boolean, default: false })
        private alignTop!: boolean;
    }
</script>
