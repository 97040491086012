export enum TprmSchemaDisplayFormat {
    checkbox = 'CHECKBOX',
    collateral = 'COLLATERAL',
    collaterals = 'COLLATERALS',
    currency = 'CURRENCY',
    days = 'DAYS',
    months = 'MONTHS',
    hidden = 'HIDDEN',
    insuredField = 'INSURED_FIELD',
    country = 'COUNTRY',
    address = 'ADDRESS',
}
