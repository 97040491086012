import { CollateralMappingStatus } from '@/modules/entity-details/models/CollateralMappingStatus.model';
import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
import { ConciseCollateral } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/ConciseCollateral.model';
import { ComplianceStatus } from '@evidentid/tprm-portal-lib/models/entity-details';
import { CollateralMappingType } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralMappingType.model';

export function getCollateralMappingOptionStatus(
    mappingOptions: ConciseCollateral[], collateralEntity: CollateralEntity,
): CollateralMappingStatus {
    if (mappingOptions.length === 0) {
        return CollateralMappingStatus.error;
    }
    if (collateralEntity?.complianceStatus === ComplianceStatus.pending) {
        return CollateralMappingStatus.actionReview;
    } else if (collateralEntity?.mapping?.mappingType === CollateralMappingType.manual) {
        return CollateralMappingStatus.manual;
    }
    return CollateralMappingStatus.auto;
}
