import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { createEmptyEntityVuexStatus } from '@/utils/vuex-common/createEmptyEntityVuexStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import { createEmptyListVuexStatus } from '@/utils/vuex-common/createEmptyListVuexStatus';

// eslint-disable-next-line max-params
export async function performCommonGetAction<T>(
    scope: any, stateKey: string, rpName: string, getDataFunc: Function,
    mutateStart: Function, mutateFinish: Function, mutateFail: Function, isList = false
): Promise<void> {
    const createEmptyStatusFunc = isList ? createEmptyListVuexStatus : createEmptyEntityVuexStatus;
    const state = scope.state;
    const currentStatus = state[stateKey][rpName] || createEmptyStatusFunc<T>();
    // Ignore when it is already updating
    if (currentStatus.status === OperationStatus.loading) {
        return;
    }
    mutateStart();
    try {
        const data = await getDataFunc();
        const latestStatus = state[stateKey][rpName] || createEmptyStatusFunc<T>();
        // Handle race condition
        if (latestStatus.status !== OperationStatus.loading &&
            latestStatus.status !== OperationStatus.uninitialized) {
            return;
        }
        // Process data
        mutateFinish(data);
    } catch (error) {
        mutateFail();
        await getPersistingErrorActions(scope).showError(error);
    }
}
