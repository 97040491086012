<template>
    <nav class="Menu" :class="{ 'Menu--big': big, 'Menu--spaced': spaced, 'Menu--inline': inline }">
        <ul>
            <slot></slot>
        </ul>
    </nav>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Menu extends Vue {
        @Prop({ type: Boolean, default: false })
        private big!: boolean;

        @Prop({ type: Boolean, default: false })
        private spaced!: boolean;

        @Prop({ type: Boolean, default: false })
        private inline!: boolean;
    }
</script>
