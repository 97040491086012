import omit from 'lodash/omit';
import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';

// referenced from https://json-schema.org/understanding-json-schema/reference/string.html#built-in-formats
const standardStringFormat = [
    'date-time', 'time', 'date', 'duration', 'email', 'idn-hostname', 'ipv4', 'ipv6', 'uuid', 'uri', 'uri-reference',
    'iri', 'iri-reference', 'uri-template', 'json-pointer', 'relative-json-pointer', 'regex',
];
const customStringFormats = [
    'color', 'html', 'phone', 'image-url',
];

const acceptedStringFormats = [ ...standardStringFormat, ...customStringFormats ];

export function removeNonStandardSchemaFormat(
    schema: JsonSchema,
): JsonSchema {
    const moddedSchema = 'if' in schema
        ? {
            ...schema,
            if: removeNonStandardSchemaFormat(schema.if as JsonSchema),
            then: removeNonStandardSchemaFormat(schema.then as JsonSchema),
            else: removeNonStandardSchemaFormat(schema.else as JsonSchema),
        } as JsonSchemaComplexObject
        : schema;
    if ('properties' in moddedSchema) {
        return {
            ...moddedSchema,
            properties: Object.keys(moddedSchema.properties).reduce((acc, key) => ({
                ...acc,
                [key]: removeNonStandardSchemaFormat(moddedSchema.properties[key]),
            }), {}),
        };
    } else if ('items' in moddedSchema) {
        return {
            ...moddedSchema,
            items: removeNonStandardSchemaFormat(moddedSchema.items as JsonSchema),
        };
    }
    return 'format' in moddedSchema && moddedSchema.format && !acceptedStringFormats.includes(moddedSchema.format)
        ? omit(moddedSchema, 'format')
        : moddedSchema;
}
