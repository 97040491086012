<template>
    <Menu :spaced="spaced">
        <MenuLink
            v-if="user.status !== 'active'"
            :disabled="submitting"
            :icon="faEnvelope"
            label="Resend invite"
            @click="resendInvite"
        />
        <MenuLink
            :disabled="submitting"
            :icon="faPencilAlt"
            label="Edit"
            @click="editUser"
        />
        <MenuLink
            :disabled="submitting"
            :icon="faTrash"
            label="Delete"
            @click="deleteUser"
        />
    </Menu>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { faEnvelope, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
    import type { User } from '@evidentid/user-management-api-client/types';
    import { Menu, MenuLink } from '../../../components/Menu';
    import { ConfirmationModal } from '../../../components/ConfirmationModal';

    @Component({
        components: {
            ConfirmationModal,
            MenuLink,
            Menu,
        },
    })
    export default class UserActionsMenu extends Vue {
        private faEnvelope = faEnvelope;
        private faPencilAlt = faPencilAlt;
        private faTrash = faTrash;

        @Prop({ type: Object })
        private user!: User;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Boolean, default: false })
        private spaced!: boolean;

        @Prop({ type: Boolean, default: false })
        private preventEvents!: boolean;

        private processEvent(event: Event | null | undefined): void {
            if (event && this.preventEvents) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        private resendInvite(event: Event): void {
            this.processEvent(event);
            if (!this.submitting) {
                this.$emit('resendInvite');
            }
        }

        private editUser(event: Event): void {
            this.processEvent(event);
            if (!this.submitting) {
                this.$emit('edit');
            }
        }

        private deleteUser(event: Event): void {
            this.processEvent(event);
            if (!this.submitting) {
                this.$emit('delete');
            }
        }
    }
</script>
