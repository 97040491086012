<template>
    <div class="UserManagementUserNameBox">
        <div class="UserManagementUserNameBox__name" v-if="name">
            {{ name }}
            <span class="UserManagementUserNameBox__label" v-if="label">
                ({{ label }})
            </span>
        </div>
        <div class="UserManagementUserNameBox__email">
            {{ email }}
        </div>
    </div>
</template>

<style lang="scss">
    .UserManagementUserNameBox {
        line-height: 1.2;

        &__name {
            margin-bottom: 5px;
        }

        &__label {
            font-weight: bold;
        }

        &__email {
            font-size: 0.85em;
            color: #99adbd;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class UserNameBox extends Vue {
        @Prop({ type: String, default: null })
        private name!: string | null;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String, default: null })
        private label!: string | null;
    }
</script>
