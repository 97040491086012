<template>
    <div class="RiskProfilesTableRow">
        <div class="RiskProfilesTableRow__displayNameContentCell">
            <span class="RiskProfilesTableRow__displayNameContentText" @click="$emit('goToRiskProfile', entityRiskProfile.id)">
                {{ entityRiskProfile.displayName }}
            </span>
        </div>
        <div class="RiskProfilesTableRow__requirementTypesContentCell">
            {{ entityRiskProfile.coverageTypes.length }}
        </div>
        <div class="RiskProfilesTableRow__complianceStatusContentCell">
            <ComplianceStatusBadge :status="entityRiskProfile.complianceStatus" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';

    @Component({
        components: { Button, ComplianceStatusBadge },
    })
    export default class RiskProfilesTableRow extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private entityRiskProfile!: EntityRiskProfile;
    }
</script>
