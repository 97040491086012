import { Store } from 'vuex/types';
import { Route, VueRouter } from 'vue-router/types/router';

interface FnsMap {
    [key: string]: (...args: any) => any;
}
interface ApplicationPart<S> {
    store: Store<S> | null;
    router: VueRouter | null;
}

type AnyChoice<T, WhenAny, WhenNotAny> = (any extends T ? true : false) extends true
        ? WhenAny
        : WhenNotAny;

export default class ApplicationObserver<S extends object, A extends FnsMap, M extends FnsMap> {
    private app: ApplicationPart<S>;
    private subscriptions: (() => any)[] = [];

    public constructor(app: ApplicationPart<S>) {
        this.app = app;
    }

    public onChange<T>(getter: (state: S) => T, handler: (value: T, oldValue: T) => any) {
        if (!this.app.store) {
            throw new Error('Unable to listen for actions on not initialized store.');
        }
        this.subscriptions.push(this.app.store.watch(getter, handler));
    }

    public onValue<T extends AnyChoice<S, string, keyof S>>(
        key: T,
        handler: (
            value: T extends keyof S ? S[T] : any,
            oldValue: T extends keyof S ? S[T] : any
        ) => any
    ) {
        // @ts-ignore: something wrong there, after changing to "any" choice
        this.onChange((state) => state[key], handler);
    }

    public onAction<T extends AnyChoice<A, string, keyof A>>(
        type: T,
        handler: (payload: AnyChoice<A, any, Parameters<A[T]>[0]>, actionType: T, state: S) => any,
    ) {
        const store = this.app.store;
        if (!store) {
            throw new Error('Unable to listen for actions on not initialized store.');
        }
        this.subscriptions.push(store.subscribeAction((action) => {
            if (action.type === type) {
                handler(action.payload, action.type as T, store.state);
            }
        }));
    }

    public onActionFinish<T extends AnyChoice<A, string, keyof A>>(
        type: T,
        handler: (payload: AnyChoice<A, any, Parameters<A[T]>[0]>, actionType: T, state: S) => any,
    ) {
        const store = this.app.store;
        if (!store) {
            throw new Error('Unable to listen for actions on not initialized store.');
        }
        this.subscriptions.push(store.subscribeAction({
            after: (action) => {
                if (action.type === type) {
                    handler(action.payload, action.type as T, store.state);
                }
            },
        }));
    }

    public onMutation<T extends AnyChoice<M, string, keyof M>>(
        type: T,
        handler: (payload: AnyChoice<M, any, Parameters<M[T]>[0]>, actionType: T) => any
    ) {
        if (!this.app.store) {
            throw new Error('Unable to listen for mutations on not initialized store.');
        }
        this.subscriptions.push(this.app.store.subscribe((mutation) => {
            if (mutation.type === type) {
                handler(mutation.payload, mutation.type as T);
            }
        }));
    }

    public onRouteChange(handler: (to: Route, from: Route, state: S) => any) {
        if (!this.app.router) {
            throw new Error('Unable to listen for route changes on not initialized router.');
        }
        this.subscriptions.push(this.app.router.afterEach((to, from) => {
            if (this.app.store) {
                handler(to, from, this.app.store.state);
            }
        }) as () => any);
    }

    public onRoute(name: string, handler: (route: Route, from: Route) => any) {
        this.onRouteChange((to, from) => {
            if (to && to.name === name) {
                handler(to, from);
            }
        });
    }

    public removeListeners() {
        for (const unsubscribe of this.subscriptions) {
            unsubscribe();
        }
        this.subscriptions = [];
    }
}
