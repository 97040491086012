<template>
    <LoginLayout title="Sign in to your Insurance account" :hide-footer="duringSubmit" divided>
        <template v-slot:header>
            <img :src="logoUrl" alt="">
        </template>

        <template v-if="canRegister" v-slot:footer>
            Don't have an account? <a href="#/register">Create one</a>.
        </template>

        <LoginLayoutSections>
            <LoginLayoutSection no-divider>
                <OauthButtonList>
                    <OauthButton
                        v-if="hasMethod('google')"
                        type="google"
                        :icon="googleLogoUrl"
                        :disabled="duringSubmit"
                        @click="startSsoLogin('google')"
                    >
                        Sign in with Google
                    </OauthButton>
                    <OauthButton
                        v-if="hasMethod('external')"
                        type="external"
                        :icon="faLock"
                        :disabled="duringSubmit"
                        @click="startSsoLogin('external')"
                    >
                        Sign in
                    </OauthButton>
                </OauthButtonList>
            </LoginLayoutSection>

            <LoginLayoutSection v-if="hasMethod('credentials')" title="OR">
                <Form @submit.prevent="startRegularLogin">
                    <FormElement label-for="login-email" label="Email:">
                        <input
                            id="login-email"
                            v-model="email"
                            type="email"
                            name="email"
                            :disabled="duringSubmit"
                        >
                    </FormElement>
                    <FormElement label-for="login-password" label="Password:">
                        <input
                            id="login-password"
                            v-model="password"
                            type="password"
                            name="password"
                            :disabled="duringSubmit"
                        >
                    </FormElement>

                    <template v-slot:buttons>
                        <Button v-if="canResetPassword && duringSubmit" tag="a" type="link small" disabled>
                            Forgot password?
                        </Button>
                        <Button v-else-if="canResetPassword" tag="a" type="link small" href="#/forgot-password">
                            Forgot password?
                        </Button>
                        <Button :loading="duringSubmit" :disabled="!canSubmit" type="primary" submit>
                            Log in
                        </Button>
                    </template>
                </Form>
            </LoginLayoutSection>
        </LoginLayoutSections>
    </LoginLayout>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faLock } from '@fortawesome/free-solid-svg-icons';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Form, FormElement } from '@evidentid/dashboard-commons/components/Form';
    import { OauthButton, OauthButtonList } from '@evidentid/dashboard-commons/components/OauthButtonList';
    import {
        LoginLayout,
        LoginLayoutDivider,
        LoginLayoutSection,
        LoginLayoutSections,
    } from '@evidentid/dashboard-commons/layouts/LoginLayout';
    import googleLogoUrl from '@/assets/icons/google-logo.svg';

    @Component({
        components: {
            FontAwesomeIcon,
            OauthButtonList,
            OauthButton,
            Button,
            FormElement,
            Form,
            LoginLayout,
            LoginLayoutDivider,
            LoginLayoutSection,
            LoginLayoutSections,
        },
    })
    export default class Login extends Vue {
        private logoUrl = logoUrl;
        private googleLogoUrl = googleLogoUrl;
        private faLock = faLock;

        private email = '';
        private password = '';

        private get duringSubmit() {
            return Boolean(this.$store.state.user.duringRegularLogin || this.$store.state.user.duringSsoLogin);
        }

        private get canSubmit() {
            return Boolean(this.email && this.password) && !this.duringSubmit;
        }

        private get canResetPassword(): boolean {
            return Boolean(this.$auth?.canResetPassword);
        }

        private get canRegister(): boolean {
            return Boolean(this.$auth?.canRegister);
        }

        private hasMethod(method: string): boolean {
            return this.$auth?.availableLoginMethods.includes(method);
        }

        private async startSsoLogin(method: string) {
            if (!this.duringSubmit) {
                await this.$store.actions.snackbar.displaySnackbar(null);
                this.$store.actions.user.logInWithSso(method);
            }
        }

        private async startRegularLogin() {
            if (!this.duringSubmit) {
                await this.$store.actions.snackbar.displaySnackbar(null);
                this.$store.actions.user.logIn({ email: this.email, password: this.password });
            }
        }
    }
</script>
