<template>
    <ConfirmationModal
        size="optimal"
        :icon="faTrashAlt"
        title="Do you want to delete this user?"
        yes="Delete"
        :yesIcon="faTrashAlt"
        no="Cancel"
        :noIcon="null"
        loadingLabel="Deleting..."
        destructive
        :progress="submitting"
        @input="onConfirm"
    >
        You are about to remove <strong>{{ user.email }}</strong>.<br>
        This action can't be undone.
    </ConfirmationModal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { User } from '@evidentid/user-management-api-client/types';
    import { ConfirmationModal } from '../../../components/ConfirmationModal';
    import ConfigureUserModal from '../components/ConfigureUserModal.vue';

    @Component({
        components: {
            ConfirmationModal,
            ConfigureUserModal,
        },
    })
    export default class DeleteUserProcedure extends Vue {
        private faTrashAlt = faTrashAlt;

        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: Object })
        private user!: User;

        private get status(): { status: OperationStatus, error: any } {
            return (this as any).$store.state.userManagement.deleteUser[this.rpName]?.[this.user.id] || {
                status: OperationStatus.uninitialized,
                error: null,
            };
        }

        private get submitting() {
            return this.status.status === OperationStatus.loading;
        }

        private success(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: true });
        }

        private error(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: false });
        }

        private onConfirm(confirmed: boolean): void {
            if (confirmed) {
                this.deleteUser();
            } else {
                this.$emit('abort');
            }
        }

        private async deleteUser(): Promise<void> {
            if (this.status.status === OperationStatus.loading) {
                return;
            }

            await (this as any).$store.actions.userManagement.deleteUser({
                rpName: this.rpName,
                id: this.user.id,
            });

            const { status } = this.status;
            if (status === OperationStatus.success) {
                this.success(`${this.user.email} was removed`);
                this.$emit('finish');
            } else {
                this.error('Oops, something went wrong. Please try again!');
            }
        }
    }
</script>
