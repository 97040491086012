import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    EntityPatchResult,

} from '@evidentid/rpweb-api-client/models';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { EntityFilters } from '@/modules/entity-filtering/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import mapValues from 'lodash/mapValues';
import { CustomProperty, Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
import { EntityStatistics } from '@evidentid/tprm-portal-lib/models/dashboard/EntityStatistics.model';
import {
    DashboardConfiguration,
} from '@evidentid/tprm-portal-lib/models/dashboard-configuration/DashboardConfiguration.model';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
import {
    CategorizedEnumLabelObject,
    RawCategorizedEnumLabels,
} from '@evidentid/tprm-portal-lib/models/dashboard/CategorizedEnumLabelObject.model';
import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';
import {
    VerificationRequest,
} from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

export interface DashboardRequirements {
    rpweb: RpWebApiClient;
}

const dashboardConfig = {
    recordsPerPage: 25,
};

const createState = createStateFactory<DashboardRequirements>();

interface EntitiesListStatus {
    loading: boolean;
    finished: boolean;
    count: number;
    list: Entity[];
}

interface CustomPropertiesListStatus {
    status: OperationStatus;
    list: CustomProperty[];
}

interface RequirementTypeListStatus {
    status: OperationStatus;
    list: string[];
}

interface EntityStatisticsStatus {
    status: OperationStatus;
    statistics: EntityStatistics;
}

interface InsuranceDashboardConfigurationStatus {
    status: OperationStatus;
    configuration: DashboardConfiguration;
}

interface InsuranceCoveragesStatus {
    status: OperationStatus;
    coverages: EntityRequirement[];
}

interface InsuranceRequestStatus {
    status: OperationStatus;
    requests: VerificationRequest[];
}

interface InsuranceRequestsConfigStatus {
    status: OperationStatus;
    data: TprmRequestsConfig | null;
}

interface InsuranceRequests {
    requests: Record<string, InsuranceRequestStatus>;
}

interface InsuranceSubmissionLink {
    status: OperationStatus;
    submissionLink: string;
}

interface InsuranceSubmissionLinks {
    submissionLinks: Record<string, InsuranceSubmissionLink>;
}

export interface DashboardState {
    entities: Record<string, EntitiesListStatus>;
    customProperties: Record<string, CustomPropertiesListStatus>;
    entityStatistics: Record<string, EntityStatisticsStatus>;
    requirementTypes: Record<string, RequirementTypeListStatus>;
    dashboardConfiguration: Record<string, InsuranceDashboardConfigurationStatus>;
    coverages: Record<string, InsuranceCoveragesStatus>;
    requests: Record<string, InsuranceRequests>;
    requestsConfig: Record<string, InsuranceRequestsConfigStatus>;
    submissionLinks: Record<string, InsuranceSubmissionLinks>;
    categorizedEnumLabels: CategorizedEnumLabels;
}

export function createEmptyListStatus(): EntitiesListStatus {
    return {
        count: Infinity,
        finished: false,
        loading: false,
        list: [],
    };
}

export function createEmptyCustomPropertyStatus(): CustomPropertiesListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyRequirementTypesStatus(): RequirementTypeListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyEntityStatisticsStatus(): EntityStatisticsStatus {
    return {
        status: OperationStatus.uninitialized,
        statistics: {
            compliance: {
                forcePlaced: 0,
                compliant: 0,
                nonCompliant: 0,
                pending: 0,
                new: 0,
            },
            expiration: {
                within30Days: 0,
            },
        },
    };
}

export function createEmptyInsuranceDashboardStatus(): InsuranceDashboardConfigurationStatus {
    return {
        status: OperationStatus.uninitialized,
        configuration: {
            insuredFields: [],
        },
    };
}

export function createEmptyCoveragesInfoStatus(): InsuranceCoveragesStatus {
    return {
        status: OperationStatus.uninitialized,
        coverages: [],
    };
}

export function createEmptyRequestStatus(): InsuranceRequestStatus {
    return {
        status: OperationStatus.uninitialized,
        requests: [],
    };
}

export function createEmptyRequestsConfigStatus(): InsuranceRequestsConfigStatus {
    return {
        status: OperationStatus.uninitialized,
        data: null,
    };
}

export function createEmptySubmissionLinkStatus(): InsuranceSubmissionLink {
    return {
        status: OperationStatus.uninitialized,
        submissionLink: '',
    };
}

function patchStandardFields(entityToPatch: Entity, patchChanges: EntityPatchResult): void {
    entityToPatch.displayName = patchChanges.displayName?.newValue ?? entityToPatch.displayName;
    entityToPatch.legalName = patchChanges.legalName?.newValue ?? entityToPatch.legalName;
    entityToPatch.doingBusinessAs = patchChanges.doingBusinessAs?.newValue ?? entityToPatch.doingBusinessAs;
    entityToPatch.contactName = patchChanges.contactName?.newValue ?? entityToPatch.contactName;
    entityToPatch.contactEmail = patchChanges.contactEmail?.newValue ?? entityToPatch.contactEmail;
    entityToPatch.contactPhoneNumber = patchChanges.contactPhoneNumber?.newValue
        ?? entityToPatch.contactPhoneNumber;
    entityToPatch.active = patchChanges.active?.newValue ?? entityToPatch.active;
    entityToPatch.complianceStatus = patchChanges.complianceStatus?.newValue
        ?? entityToPatch.complianceStatus;
    entityToPatch.nextExpiration = patchChanges.nextExpiration === undefined
        ? entityToPatch.nextExpiration
        : patchChanges.nextExpiration.newValue;
}

function patchCustomProperties(entityToPatch: Entity, patchChanges: EntityPatchResult): void {
    if (patchChanges.insuredFields) {
        Object.entries(patchChanges.insuredFields)
            .forEach(([ k, v ]) => {
                if (!entityToPatch.insuredFields) {
                    entityToPatch.insuredFields = {};
                }
                entityToPatch.insuredFields[k] = v.newValue;
            });
    }
}

const { instantiateState, createMutationsFactories } = createState<DashboardState>(() => ({
    entities: {},
    customProperties: {},
    entityStatistics: {},
    requirementTypes: {},
    dashboardConfiguration: {},
    coverages: {},
    requests: {},
    requestsConfig: {},
    submissionLinks: {},
    categorizedEnumLabels: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    clearEntitiesList(rpName: string) {
        this.entities = omit(this.entities, [ rpName ]);
    },

    clearCustomPropertiesList(rpName: string) {
        // FIXME: remove this once we have a nav guard that block nav until a component is destoryed
        if (this.customProperties[rpName]?.status === OperationStatus.loading) {
            return;
        }
        this.customProperties = omit(this.customProperties, [ rpName ]);
    },

    clearEntityStatistics(rpName: string) {
        this.entityStatistics = omit(this.entityStatistics, [ rpName ]);
    },

    clearRequirementTypes(rpName: string) {
        this.requirementTypes = omit(this.requirementTypes, [ rpName ]);
    },

    clearRequests(rpName: string) {
        this.requests = omit(this.requests, [ rpName ]);
    },

    clearRequestsConfig(rpName: string) {
        this.requestsConfig = omit(this.requestsConfig, [ rpName ]);
    },

    clearSubmissionLinks(rpName: string) {
        this.submissionLinks = omit(this.submissionLinks, [ rpName ]);
    },

    startEntitiesListUpdate(payload: { rpName: string }) {
        this.entities = {
            ...this.entities,
            [payload.rpName]: {
                count: Infinity,
                finished: false,
                list: [],
                ...(this.entities[payload.rpName] as EntitiesListStatus | undefined),
                loading: true,
            },
        } as DashboardState['entities'];
    },

    pushToEntitiesList(payload: {
        rpName: string; entities: Entity[]; count?: number; finished?: boolean;
    }) {
        const prevResult: EntitiesListStatus = this.entities[payload.rpName] || createEmptyListStatus();
        const entitiesCopy = [ ...prevResult.list ];
        payload.entities.forEach(((newEntity) => {
            const index = entitiesCopy.findIndex(
                (entitiesCopy) => newEntity.id === entitiesCopy.id,
            );
            if (index > -1) {
                entitiesCopy[index] = newEntity;
            } else {
                entitiesCopy.push(newEntity);
            }
        }));
        const nextResult: EntitiesListStatus = {
            loading: false,
            count: payload.count == null ? prevResult.count : payload.count,
            finished: payload.finished == null ? prevResult.finished : payload.finished,
            list: uniqBy(entitiesCopy, 'id'),
        };
        this.entities = {
            ...this.entities,
            [payload.rpName]: nextResult,
        } as DashboardState['entities'];
    },

    updateEntitiesInList(
        payload: { rpName: string, entities: Entity[] }) {
        const prevResult: EntitiesListStatus = this.entities[payload.rpName] || createEmptyListStatus();
        let newEntities = [ ...prevResult.list ];
        payload.entities.forEach((toUpdate) => {
            const index = newEntities.findIndex((x) => x.id === toUpdate.id);
            newEntities = [
                ...prevResult.list.slice(0, index),
                toUpdate,
                ...prevResult.list.slice(index + 1),
            ];
        });
        const nextResult: EntitiesListStatus = {
            loading: false,
            count: prevResult.count,
            finished: prevResult.finished,
            list: uniqBy(newEntities, 'id'),
        };
        this.entities = {
            ...this.entities,
            [payload.rpName]: nextResult,
        } as DashboardState['entities'];
    },

    patchEntitiesInList(payload: { rpName: string, entitiesPatch: EntityPatchResult[] }) {
        payload.entitiesPatch.forEach((patchChanges) => {
            this.entities[payload.rpName].list = this.entities[payload.rpName].list.map((entity) => {
                if (entity.id !== patchChanges.id) {
                    return entity;
                }
                const entityToPatch = { ...entity };
                patchStandardFields(entityToPatch, patchChanges);
                patchCustomProperties(entityToPatch, patchChanges);
                return entityToPatch;
            });
        });
    },

    failEntitiesListUpdate(payload: { rpName: string }) {
        const prevResult: EntitiesListStatus = this.entities[payload.rpName] || createEmptyListStatus();
        const nextResult: EntitiesListStatus = {
            loading: false,
            count: prevResult.count,
            finished: prevResult.finished,
            list: [ ...prevResult.list ],
        };

        this.entities = {
            ...this.entities,
            [payload.rpName]: nextResult,
        } as DashboardState['entities'];
    },

    startLoadingCustomProperties(payload: { rpName: string }) {
        this.customProperties = {
            ...this.customProperties,
            [payload.rpName]: {
                ...(this.customProperties[payload.rpName] as CustomPropertiesListStatus),
                status: OperationStatus.loading,
                list: [],
            },
        };
    },

    finishLoadingCustomProperties(payload: { rpName: string, customProperties: CustomProperty[] }) {
        this.customProperties = {
            ...this.customProperties,
            [payload.rpName]: {
                ...(this.customProperties[payload.rpName] as CustomPropertiesListStatus),
                status: OperationStatus.success,
                list: payload.customProperties,
            },
        };
    },

    failLoadingCustomProperties(payload: { rpName: string }) {
        this.customProperties = {
            ...this.customProperties,
            [payload.rpName]: {
                ...(this.customProperties[payload.rpName] as CustomPropertiesListStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },

    startLoadingRequirementTypes(payload: { rpName: string }) {
        this.requirementTypes = {
            ...this.requirementTypes,
            [payload.rpName]: {
                ...(this.requirementTypes[payload.rpName] as RequirementTypeListStatus),
                status: OperationStatus.loading,
                list: [],
            },
        };
    },

    finishLoadingRequirementTypes(payload: { rpName: string, requirementTypes: string[] }) {
        this.requirementTypes = {
            ...this.requirementTypes,
            [payload.rpName]: {
                ...(this.requirementTypes[payload.rpName] as RequirementTypeListStatus),
                status: OperationStatus.success,
                list: payload.requirementTypes,
            },
        };
    },

    failLoadingRequirementTypes(payload: { rpName: string }) {
        this.requirementTypes = {
            ...this.requirementTypes,
            [payload.rpName]: {
                ...(this.requirementTypes[payload.rpName] as RequirementTypeListStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },

    startLoadingEntityStatistics(payload: { rpName: string }) {
        this.entityStatistics = {
            ...this.entityStatistics,
            [payload.rpName]: {
                ...createEmptyEntityStatisticsStatus(),
                ...(this.entityStatistics[payload.rpName] as EntityStatisticsStatus),
                status: OperationStatus.loading,
            },
        };
    },

    finishLoadingEntityStatistics(payload: { rpName: string, entityStatistics: EntityStatistics }) {
        this.entityStatistics = {
            ...this.entityStatistics,
            [payload.rpName]: {
                ...(this.entityStatistics[payload.rpName] as EntityStatisticsStatus),
                status: OperationStatus.success,
                statistics: payload.entityStatistics,
            },
        };
    },

    failLoadingEntityStatistics(payload: { rpName: string }) {
        this.entityStatistics = {
            ...this.entityStatistics,
            [payload.rpName]: {
                ...createEmptyEntityStatisticsStatus(),
                ...(this.entityStatistics[payload.rpName] as EntityStatisticsStatus),
                status: OperationStatus.error,
            },
        };
    },

    startLoadingCoveragesInfo(payload: { rpName: string }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.loading,
                coverages: [],
            },
        };
    },

    finishLoadingCoveragesInfo(payload: { rpName: string, coverages: any }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.success,
                coverages: payload.coverages,
            },
        };
    },

    failLoadingCoveragesInfo(payload: { rpName: string }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.error,
                coverages: [],
            },
        };
    },

    startLoadingRequests(payload: { rpName: string, entityId: string }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.entityId]: {
                        status: OperationStatus.loading,
                        requests: [],
                    },
                },
            },
        };
    },

    finishLoadingRequests(payload: { rpName: string, entityId: string, requests: VerificationRequest[] }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.entityId]: {
                        status: OperationStatus.success,
                        requests: payload.requests,
                    },
                },
            },
        };
    },

    failLoadingRequests(payload: { rpName: string, entityId: string }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.entityId]: {
                        status: OperationStatus.error,
                        requests: [],
                    },
                },
            },
        };
    },

    startLoadingRequestsConfig(payload: { rpName: string }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.loading,
                data: null,
            },
        };
    },

    finishLoadingRequestsConfig(payload: { rpName: string, requestsConfig: TprmRequestsConfig }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.success,
                data: payload.requestsConfig,
            },
        };
    },

    failLoadingRequestsConfig(payload: { rpName: string }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.error,
                data: null,
            },
        };
    },

    startLoadingSubmissionLink(payload: { rpName: string, requestId: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.loading,
                        submissionLink: '',
                    },
                },
            },
        };
    },

    finishLoadingSubmissionLink(payload: { rpName: string, requestId: string, submissionLink: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.success,
                        submissionLink: payload.submissionLink,
                    },
                },
            },
        };
    },

    failLoadingSubmissionLink(payload: { rpName: string, requestId: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.success,
                        submissionLink: '',
                    },
                },
            },
        };
    },

    updateCategorizedEnumLabels(payload: RawCategorizedEnumLabels) {
        this.categorizedEnumLabels = mapValues(payload,
            (labelObjects) => labelObjects.reduce((acc, labelObj) => ({
                ...acc,
                [labelObj.token]: omit(labelObj, 'token'),
            }), {} as Record<string, Omit<CategorizedEnumLabelObject, 'token'>>),
        );
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: DashboardRequirements) => ({
    async loadNextEntities(payload: {
        rpName: string;
        sort?: string | null;
        filters?: EntityFilters;
    }) {
        const { rpName, sort, filters } = payload;
        const currentStatus = this.state.entities[rpName] || createEmptyListStatus();

        // Ignore when it is already updating
        if (currentStatus.loading) {
            return;
        }

        // Mark as "during update"
        this.mutations.startEntitiesListUpdate({ rpName });

        let pausedQuery = null;
        // TODO(PRODUCT-17634): remove this when paused becomes part of verification status on the data level
        if (filters) {
            if (filters.paused) {
                pausedQuery = { paused: Boolean(filters.paused) };
            } else {
                // false or empty
                pausedQuery = filters.verificationStatus ? { paused: false } : null;
            }
        }

        // Build query options
        const queryOptions = {
            skip: currentStatus.list.length,
            limit: dashboardConfig.recordsPerPage,
            sort: sort || null,
            ...pausedQuery,
            ...(!(filters?.showDeactivated === 'true') && { active: true }),
            ...(omit(filters, [ 'showDeactivated', 'paused' ])),
        };

        try {
            const { insureds: entities, count } = await rpweb.getTprmEntities(rpName, queryOptions);
            const latestStatus = this.state.entities[rpName] || createEmptyListStatus();
            // Handle race condition
            if (!latestStatus.loading) {
                return;
            }
            // Process data
            const list = entities;
            this.mutations.pushToEntitiesList({
                rpName,
                count,
                entities: list,
                finished: list.length < queryOptions.limit,
            });
        } catch (error) {
            this.mutations.pushToEntitiesList({ rpName, entities: [] });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadEntity(payload: {
        rpName: string;
        id: string;
    }) {
        const { rpName, id } = payload;
        try {
            const entity = await rpweb.getTprmEntity(rpName, id);
            this.mutations.pushToEntitiesList({
                rpName,
                count: 1,
                entities: [ entity ],
            });
        } catch (error) {
            if (error.reason === 'not-found') {
                console.error(error);
                throw error;
            }
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadCustomProperties(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.customProperties[rpName] || createEmptyCustomPropertyStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCustomProperties({ rpName });
        try {
            const customProperties = await rpweb.getTprmCustomProperties(rpName);
            const latestStatus = this.state.customProperties[rpName] || createEmptyCustomPropertyStatus();

            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingCustomProperties({
                rpName,
                customProperties,
            });
        } catch (error) {
            this.mutations.failLoadingCustomProperties({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadRequirementTypes(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.coverages[rpName] || createEmptyCoveragesInfoStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequirementTypes({ rpName });
        try {
            const rpAttributesData = await rpweb.getRpUsedAttributesData(rpName);
            const requirementTypes = rpAttributesData.coverageTypes
                .map((requirementTypeAttributes) => requirementTypeAttributes.coverageType)
                .filter((requirementType) => requirementType !== 'BROKER_INFO');
            const latestStatus = this.state.requirementTypes[rpName] || createEmptyRequirementTypesStatus();

            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingRequirementTypes({ rpName, requirementTypes });
        } catch (error) {
            this.mutations.failLoadingRequirementTypes({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadEntityStatistics(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.entityStatistics[rpName] || createEmptyEntityStatisticsStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingEntityStatistics({ rpName });
        try {
            const entityStatistics = await rpweb.getTprmEntityStatistics(rpName);
            const latestStatus = this.state.entityStatistics[rpName] || createEmptyEntityStatisticsStatus();
            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingEntityStatistics({ rpName, entityStatistics });
        } catch (error) {
            this.mutations.failLoadingEntityStatistics({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadRequestsConfig(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.requestsConfig[rpName] || createEmptyRequestsConfigStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequestsConfig({ rpName });
        try {
            const requestsConfig = await rpweb.getTprmRequestsConfig(rpName);
            const latestStatus = this.state.requestsConfig[rpName] || createEmptyRequestsConfigStatus();
            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingRequestsConfig({ rpName, requestsConfig });
        } catch (error) {
            console.warn('Loading requests config error', error);
            this.mutations.failLoadingRequestsConfig({ rpName });
        }
    }, async getEntityCoverages(payload: {
        rpName: string;
        entityId: string;
    }) {
        const { rpName, entityId } = payload;
        const currentStatus = this.state.entityStatistics[rpName] || createEmptyCoveragesInfoStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoveragesInfo({ rpName });
        try {
            const coverages = await rpweb.getTprmRequirementData(rpName, entityId);
            // Process data
            this.mutations.finishLoadingCoveragesInfo({ rpName, coverages });
        } catch (error) {
            this.mutations.failLoadingCoveragesInfo({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    updateEntitiesInList(payload: { rpName: string, entities: Entity[] }) {
        this.mutations.updateEntitiesInList({ rpName: payload.rpName, entities: payload.entities });
    },
    clearEntitiesList(payload: { rpName: string }) {
        this.mutations.clearEntitiesList(payload.rpName);
    },
    clearCustomPropertiesList(payload: { rpName: string }) {
        this.mutations.clearCustomPropertiesList(payload.rpName);
    },
    clearRequirementTypeList(payload: { rpName: string }) {
        this.mutations.clearRequirementTypes(payload.rpName);
    },
    clearEntityStatistics(payload: { rpName: string }) {
        this.mutations.clearEntityStatistics(payload.rpName);
    },
    clearEntityRequests(payload: { rpName: string }) {
        this.mutations.clearRequests(payload.rpName);
    },
    clearRequestsConfig(payload: { rpName: string }) {
        this.mutations.clearRequestsConfig(payload.rpName);
    },
    clearSubmissionLinks(payload: { rpName: string }) {
        this.mutations.clearSubmissionLinks(payload.rpName);
    },
    async patchEntitiesInList(payload: { rpName: string, entitiesPatch: EntityPatchResult[] }) {
        this.mutations.patchEntitiesInList(payload);
    },
    async loadEntityRequests(payload: {
        rpName: string;
        entityId: string;
    }) {
        const { rpName, entityId } = payload;
        const currentRpStatus = this.state.requests[rpName];
        const currentStatus = currentRpStatus && currentRpStatus.requests
            ? currentRpStatus.requests[entityId]
            : createEmptyRequestStatus();
        // Ignore when it is already updating
        if (currentStatus && currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequests({ rpName, entityId });
        try {
            const requests = await rpweb.getTprmVerificationRequests(rpName, entityId);
            // Process data
            this.mutations.finishLoadingRequests({ rpName, entityId, requests });
        } catch (error) {
            this.mutations.failLoadingRequests({ rpName, entityId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadEntitySubmissionLink(payload: {
        rpName: string;
        requestId: string;
    }) {
        const { rpName, requestId } = payload;
        const currentRpStatus = this.state.submissionLinks[rpName];
        const currentStatus = currentRpStatus && currentRpStatus.submissionLinks
            ? currentRpStatus.submissionLinks[requestId]
            : createEmptySubmissionLinkStatus();
        // Ignore when it is already updating
        if (currentStatus && currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingSubmissionLink({ rpName, requestId });
        try {
            const submissionLink = await rpweb.getTprmSubmissionLink(rpName, requestId);
            // Process data
            this.mutations.finishLoadingSubmissionLink({ rpName, requestId, submissionLink });
        } catch (error) {
            this.mutations.failLoadingSubmissionLink({ rpName, requestId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCategorizedEnumLabels(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            const enumHumanReadableValues = await rpweb.getCategorizedEnumLabels(rpName);
            this.mutations.updateCategorizedEnumLabels(enumHumanReadableValues);
        } catch (error) {
            await getPersistingErrorActions(this).showError(error);
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
