import JsonForm, { JsonFormObject } from '@evidentid/json-schema/interfaces/JsonForm';

// FIXME: It's just single level
export function getFormValue(form: JsonForm, value: any): any {
    const object = form.getValue(value, true);
    const properties = (form as JsonFormObject).getProperties(object, true);
    return properties.reduce((result, { name }) => ({
        ...result,
        [name]: object[name] ?? null,
    }), {});
}
