<template>
    <div class="GeneralAggregateLimitBreakdownBanner">
        <Alert v-if="generalAggregateLimit || conditionSetsValue.length > 0" type="regular">
            <div class="GeneralAggregateLimitBreakdownBanner__info">
                <img class="CriterionInformationBanner__bannerIcon" :src="infoCircle" alt="info">
                <span>Total limit required: </span>
            </div>
            <div class="GeneralAggregateLimitBreakdownBanner__breakdownBlock">
                <div class="GeneralAggregateLimitBreakdownBanner__breakdownList">
                    <div v-if="breakdownTextList.length <= 0 && generalAggregateLimit">
                        <span>{{ generalAggregateLimit }}</span>
                    </div>
                    <div v-else>
                        <div
                            v-for="(breakdown, index ) in breakdownTextList"
                            :key="index"
                            class="GeneralAggregateLimitBreakdownBanner__breakdownContainer"
                        >
                            <span
                                v-if="index === 0 && hasTopAppliesPer"
                                class="GeneralAggregateLimitBreakdownBanner__staticTextPer"
                            >
                                Per
                            </span>
                            <strong v-if="index > 0" class="GeneralAggregateLimitBreakdownBanner__staticTextOr">
                                OR
                            </strong>
                            <span class="GeneralAggregateLimitBreakdownBanner__breakdownText">{{ breakdown }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Alert>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import startCase from 'lodash/startCase';
    import infoCircle from '@/assets/icons/info-circle-transparent-background-icon.svg';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { formatNumber } from '@evidentid/universal-framework/formatNumber';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion,
        CriterionField,
        CriterionInput,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    function parseToLabel(value: string): string {
        // TODO: need a way to get display label eventually
        return startCase(value.replace(/[_-]/g, ' ').toLowerCase());
    }

    function parseCurrencyWithComma(number: number): string {
        return formatNumber(number);
    }

    @Component({
        components: { Alert },
    })
    export default class GeneralAggregateLimitBreakdownBanner extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private criterion!: Criterion | CriterionInput;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        private infoCircle = infoCircle;

        private get references(): Record<string, CriterionField> {
            return this.criterion.evaluator.references;
        }

        private get conditionSetsValue(): any {
            return this.references.conditionSets.value || [];
        }

        private get generalAggregateLimit(): string {
            return parseCurrencyWithComma(this.references.generalAggregateLimitThreshold.value) || '';
        }

        private get hasTopAppliesPer(): boolean {
            return this.conditionSetsValue?.[0]?.generalAggregateLimitAppliesPer.length > 0;
        }

        private get breakdownTextList(): string[] {
            return this.conditionSetsValue.map(this.getBreakdownText).filter(Boolean);
        }

        private getCustomPropertyLabel(value: any): string {
            return this.customProperties.find((insuredField) => insuredField.key === value)?.name || value;
        }

        private getBaseLimitString(baseLimitObj: any): string {
            if (baseLimitObj.isBasedOnInsuredField) {
                return this.getCustomPropertyLabel(baseLimitObj.insuredField) || '';
            } else {
                return baseLimitObj.value ? `${parseCurrencyWithComma(baseLimitObj.value)}` : '';
            }
        }

        private getPerUnitLimitString(limitScaleFactor: any): string {
            if (!limitScaleFactor?.[0]?.perUnitLimit) {
                return '';
            }
            const perUnitLimitObj = limitScaleFactor[0].perUnitLimit;
            if (perUnitLimitObj.isBasedOnInsuredField) {
                return this.getCustomPropertyLabel(perUnitLimitObj.insuredField) || '';
            } else {
                return perUnitLimitObj.value ? `${parseCurrencyWithComma(perUnitLimitObj.value)}` : '';
            }
        }

        private getUnitString(limitScaleFactor: any): string {
            if (!limitScaleFactor?.[0]?.unit) {
                return '';
            }
            const unitObj = limitScaleFactor[0].unit;
            if (unitObj.isBasedOnInsuredField) {
                return this.getCustomPropertyLabel(unitObj.insuredField) || '';
            } else {
                return unitObj.value?.toString() || '';
            }
        }

        private getBreakdownText(conditionSetsValue: any): string | null {
            let appliesPer = conditionSetsValue.generalAggregateLimitAppliesPer.filter(Boolean).map(parseToLabel).join(', ');
            appliesPer = appliesPer ? ` ${appliesPer} ` : '';
            const baseLimit = this.getBaseLimitString(conditionSetsValue.baseLimit);
            const perUnitLimit = this.getPerUnitLimitString(conditionSetsValue.limitScaleFactor);
            const unit = this.getUnitString(conditionSetsValue.limitScaleFactor);

            let limitScale = perUnitLimit && unit != null && unit !== ''
                ? `${perUnitLimit} x ${unit}`
                : perUnitLimit;
            limitScale = baseLimit && limitScale && /^.+ x .+$/.test(limitScale) ? `(${limitScale})` : limitScale;

            const plus = baseLimit && limitScale ? ' + ' : '';
            return baseLimit || limitScale ? `${appliesPer}${baseLimit}${plus}${limitScale}` : null;
        }
    }
</script>
