import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import vuex from '@/modules/entity-details/vuex';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import GrantExceptionProcedure from '@/modules/entity-details/procedures/GrantExceptionProcedure.vue';
import { Exception } from '@/modules/dashboard/types';
import RemoveExceptionProcedure from '@/modules/entity-details/procedures/RemoveExceptionProcedure.vue';
import AMBestRatingDisclaimerProcedure from '@/modules/entity-details/procedures/AMBestRatingDisclaimerProcedure.vue';
import CollateralMappingProcedure from '@/modules/entity-details/procedures/CollateralMappingProcedure.vue';
import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
import ViewHistoricDocumentModalProcedure
    from '@/modules/entity-details/procedures/ViewHistoricDocumentProcedure/ViewHistoricDocumentProcedure.vue';
import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

export const entityDetailsModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ entityDetails: vuex.instantiateModule })
    .registerProcedures<{
        grantExceptionProcedure: { exception: Exception, requirement?: EntityRequirement | null };
        removeExceptionProcedure: { rpName: string, entity: Entity, exceptionIds: string[] };
        aMBestRatingDisclaimerProcedure: {};
        collateralMappingProcedure: { collaterals: CollateralEntity[], entityContactEmail: string, entityId: string };
        viewHistoricDocumentProcedure: {
            rpName: string;
            entity: Entity;
            requirementModels: TprmRequirementModel[];
        };
    }>({
        grantExceptionProcedure: GrantExceptionProcedure,
        removeExceptionProcedure: RemoveExceptionProcedure,
        aMBestRatingDisclaimerProcedure: AMBestRatingDisclaimerProcedure,
        collateralMappingProcedure: CollateralMappingProcedure,
        viewHistoricDocumentProcedure: ViewHistoricDocumentModalProcedure,
    })
    .execute((app) => {
        function onLoadingRequirementDetailsList(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Details',
                    description: `[Requested] Load requirement details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Entity Details - Requirement Details',
                    message: `[Requested] Load requirement details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementDetailsListFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Details',
                    description: `[Finished] Load requirement details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Entity Details - Requirement Details',
                    message: `[Finished] Load requirement details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingRequirementDetailsListFail(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Requirement Details Failed',
                    description: `[Failed] Load requirement details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Entity Details - Requirement Details',
                    message: `[Failed] Load requirement details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntities(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities',
                    description: `[Requested] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Entity Details - Collateral Entities',
                    message: `[Requested] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntitiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities',
                    description: `[Finished] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Entity Details - Collateral Entities',
                    message: `[Finished] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntitiesFail(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities Failed',
                    description: `[Failed] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Entity Details - Collateral Entities',
                    message: `[Failed] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingRequirementDetailsList', (x) => onLoadingRequirementDetailsList(x.rpName));
        app.observer.onMutation('finishLoadingRequirementDetailsList', (x) => onLoadingRequirementDetailsListFinish(x.rpName));
        app.observer.onMutation('failLoadingRequirementDetailsList', (x) => onLoadingRequirementDetailsListFail(x.rpName));
        app.observer.onMutation('startLoadingCollateralEntities', (x) => onLoadingCollateralEntities(x.rpName));
        app.observer.onMutation('finishLoadingCollateralEntities', (x) => onLoadingCollateralEntitiesFinish(x.rpName));
        app.observer.onMutation('failLoadingCollateralEntities', (x) => onLoadingCollateralEntitiesFail(x.rpName));
    })
    .end();
