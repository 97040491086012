<template>
    <select
        :class="className"
        v-bind="$attrs"
        :value="value"
        v-on="passedListeners"
        @change="onInputChange"
    >
        <option v-if="placeholder" value="" disabled :selected="isEmpty()">{{ placeholder }}</option>
        <slot></slot>
    </select>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import omit from 'lodash/omit';

    @Component({
        inheritAttrs: false,
    })
    export default class Select extends Vue {
        @Prop({ type: String, default: null })
        private placeholder!: string | null;

        @Prop({ default: undefined })
        private value!: any;

        private get className() {
            return [ this.isEmpty() ? 'empty' : '', this.$attrs.class ].filter(Boolean);
        }

        private get passedListeners() {
            return omit(this.$listeners, [ 'input' ]);
        }

        private isEmpty() {
            return this.value === undefined;
        }

        private onInputChange(event: Event) {
            const theValue = (event.target as HTMLSelectElement).value;
            this.$emit('input', theValue);
        }
    }
</script>
