<template>
    <div class="ChipList">
        <slot />
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class ChipList extends Vue {
    }
</script>
