<template>
    <Button
        v-tooltip="{content: tooltipMessage, show: submitting, trigger: 'manual'}"
        class="ExportButton"
        :loading="submitting"
        :icon="faFileImport"
        @click="initiateExport"
    >
        <span>
            <slot />
        </span>
    </Button>
</template>

<script lang="ts">
    import Button from '@evidentid/dashboard-commons/components/Button/Button.vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faFileImport } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: {
            Button,
        },
    })
    export default class ExportButton extends Vue {
        private faFileImport = faFileImport;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: String, default: 'This may take up some time...' })
        private tooltipMessage!: string;

        private initiateExport() {
            this.$emit('export');
        }
    }
</script>
