import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import { createModuleBuilder } from '../../app';

export function createTrackRoutesModule() {
    return createModuleBuilder()
        .demandContext<{ tracker?: Tracker<any>, logger?: Logger<any> }>()
        .execute((app) => {
            app.observer.onRouteChange((route) => {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Route change',
                        description: `Route change: ${route.name || '<unknown>'}`,
                        route: route.name || '<unknown>',
                    });
                }

                if (app.logger) {
                    app.logger.setTag('route', route.name || '<unknown>');
                }
            });
        })
        .end();
}

export type TrackRoutesModule = ReturnType<typeof createTrackRoutesModule>;
