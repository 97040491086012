<template>
    <div class="PublicProductsLiabilityCriterion">
        <JsonSchemaCustomForm
            v-model="controller.value"
            :controller="controller"
            touched
        >
            <CriterionSettingsRow
                class="py-2.5"
                data-test-id="PublicProductsLiabilityCriterion__acceptFieldRow"
                :schema="acceptField.form.schema"
            >
                <JsonSchemaCustomFormElement
                    :field="acceptField"
                    :custom-component-input="customComponentInput"
                />
            </CriterionSettingsRow>
            <CriterionSettingsRow
                data-test-id="PublicProductsLiabilityCriterion__aggregateLimitFieldRow"
                class="pb-2.5"
                :class="{'PublicProductsLiabilityCriterion__settingRow--disabled': !showAggregateLimit}"
                :schema="aggregateLimitField.form.schema"
            >
                <JsonSchemaCustomFormElement
                    :field="aggregateLimitField"
                    :custom-component-input="customComponentInput"
                    show-error
                />
            </CriterionSettingsRow>
            <CriterionSettingsRow
                data-test-id="PublicProductsLiabilityCriterion__occurrenceLimitFieldRow"
                class="pb-2.5"
                :class="{'PublicProductsLiabilityCriterion__settingRow--disabled': !showOccurrenceLimit}"
                :schema="occurrenceLimitField.form.schema"
            >
                <JsonSchemaCustomFormElement
                    :field="occurrenceLimitField"
                    :custom-component-input="customComponentInput"
                    show-error
                />
            </CriterionSettingsRow>
            <CriterionSettingsRow
                data-test-id="PublicProductsLiabilityCriterion__umbrellaFieldRow"
                class="pb-2.5"
                :schema="umbrellaField.form.schema"
            >
                <JsonSchemaCustomFormElement
                    :field="umbrellaField"
                    :custom-component-input="customComponentInput"
                    show-error
                />
            </CriterionSettingsRow>
        </JsonSchemaCustomForm>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import {
        FormController,
    } from '@evidentid/json-schema/FormController';
    import { requirementTypeFieldReferenceDelimiter } from '@/modules/decisioning-criteria/types';
    import CriterionSettingsRow
        from '@/modules/decisioning-criteria/components/CriterionSettingsRow/CriterionSettingsRow.vue';
    import {
        getCriterionJsonSchemaComponent,
    } from '@/modules/decisioning-criteria/utils/getCriterionJsonSchemaComponent';
    import createForm from '@evidentid/json-schema/createForm';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import { buildCriterionSchema } from '@/modules/decisioning-criteria/schemas/buildCriterionSchema';
    import mapValues from 'lodash/mapValues';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    type LoadCriterionMessage = (
        controller: FormController,
        updatedCriterion: Criterion | CriterionInput,
    ) => void;

    enum AcceptForValue {
        perOccurrence = 'PER_OCCURRENCE_LIMIT_ONLY',
        aggregateLimitOnly = 'AGGREGATE_LIMIT_ONLY',
    }

    enum Reference {
        productsLiability = 'productsLiabilityConfig',
        publicLiability = 'publicLiabilityConfig',
    }

    export default Vue.extend({
        name: 'PublicProductsLiabilityCriterion',
        components: {
            JsonSchemaCustomForm,
            JsonSchemaCustomFormElement,
            CriterionSettingsRow,
        },
        props: {
            criterion: {
                type: Object as PropType<Criterion | CriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<CriterionTemplate>,
                required: true,
            },
            loadCriterionMessage: {
                type: Function as PropType<LoadCriterionMessage>,
                default: () => undefined,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
        },
        data() {
            const orderedTemplateReferences = Object.keys(this.template.evaluator?.references) || [];
            const value = mapValues(this.criterion.evaluator.references, (ref) => ref.value);
            const schema = standardizeCriterionSchema(
                buildCriterionSchema(this.criterion, orderedTemplateReferences, this.template),
                this.customProperties,
            );
            const form = createForm(schema);
            const controller = new FormController(form, form.getValue(value, true));
            return {
                controller,
                customComponentInput: getCriterionJsonSchemaComponent(),
            };
        },
        computed: {
            referenceName(): Reference {
                return this.template.field === 'coverage.productsLiability'
                    ? Reference.productsLiability
                    : Reference.publicLiability;
            },
            showAggregateLimit(): boolean {
                return this.controller.value[this.referenceName].acceptedFor !== AcceptForValue.perOccurrence;
            },
            showOccurrenceLimit(): any {
                return this.controller.value[this.referenceName].acceptedFor !== AcceptForValue.aggregateLimitOnly;
            },
            acceptField(): any {
                return this.controller.structure.properties?.[this.referenceName]?.properties?.acceptedFor;
            },
            aggregateLimitField(): any {
                return this.controller.structure.properties?.[this.referenceName]?.properties?.aggregateLimitValue;
            },
            occurrenceLimitField(): any {
                return this.controller.structure.properties?.[this.referenceName]?.properties?.perOccurrenceLimitValue;
            },
            umbrellaField(): any {
                return this.controller.structure.properties?.allowUmbrellaExcess;
            },
            computedValue(): Record<string, any> {
                return { ...this.controller.form.getValue(this.controller.value) };
            },
        },
        watch: {
            computedValue: {
                handler(newValue: any) {
                    const reference = [
                        this.criterion.coverageType,
                        this.criterion.field,
                        this.referenceName,
                    ].join(requirementTypeFieldReferenceDelimiter);
                    const updatedCriterion = {
                        ...this.criterion,
                        evaluator: {
                            references: {
                                [this.referenceName]: {
                                    ...this.criterion.evaluator.references[this.referenceName],
                                    value: {
                                        ...newValue[this.referenceName],
                                    },
                                },
                                allowUmbrellaExcess: {
                                    ...this.criterion.evaluator.references.allowUmbrellaExcess,
                                    value: newValue.allowUmbrellaExcess,
                                },
                            },
                        },
                    };
                    this.$emit('input', updatedCriterion);
                    this.$emit('reference-accessed', { reference, isValid: this.controller.valid });
                    this.$emit('controller-value-update', this.controller.value);
                    this.loadCriterionMessage(this.controller, updatedCriterion);
                },
            },
        },
        created() {
            this.loadCriterionMessage(this.controller, this.criterion);
            this.$emit('controller-value-update', this.controller.value);
        },
    });
</script>
