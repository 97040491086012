<template>
    <Row
        class="CustomPropertyCollateralRow"
        :class="{
            'CustomPropertyCollateralRow--focus': isAnyFieldFocused,
            'CustomPropertyCollateralRow--readOnly': !isEditing
        }"
    >
        <Cell
            v-for="({ name }) in columns"
            :key="name"
            :data-test-id="`CustomPropertyCollateralRow__${name}Cell`"
        >
            <template v-if="name==='action'">
                <div v-if="isEditing" class="flex">
                    <EidIcon
                        data-test-id="CustomPropertyCollateralRow__confirmButton"
                        class="CustomPropertyCollateralRow__actionButton text-eidTrustGreen"
                        :class="{'CustomPropertyCollateralRow__actionButton--disabled': !isValidCollateral || updatingInsured}"
                        :icon-src="faCheck"
                        alt="confirm"
                        @click="confirmEditCollateral(true)"
                    />
                    <EidIcon
                        data-test-id="CustomPropertyCollateralRow__cancelButton"
                        class="CustomPropertyCollateralRow__actionButton text-eidDangerRed"
                        :class="{'CustomPropertyCollateralRow__actionButton--disabled': updatingInsured}"
                        alt="cancel"
                        :icon-src="faTimes"
                        @click="confirmEditCollateral(false)"
                    />
                </div>
                <div v-else class="flex">
                    <EidIcon
                        data-test-id="CustomPropertyCollateralRow__editButton"
                        class="CustomPropertyCollateralRow__actionButton"
                        :class="{'CustomPropertyCollateralRow__actionButton--disabled': updatingInsured}"
                        alt="edit"
                        :icon-src="pencilIcon"
                        :svg-props="{color: trustGreen}"
                        @click="onClickEdit"
                    />
                    <EidIcon
                        data-test-id="CustomPropertyCollateralRow__deleteButton"
                        alt="delete"
                        class="CustomPropertyCollateralRow__actionButton"
                        :class="{'CustomPropertyCollateralRow__actionButton--disabled': updatingInsured}"
                        :icon-src="binIcon"
                        :svg-props="{color: trustGreen}"
                        @click="onClickDelete"
                    />
                </div>
            </template>
            <JsonSchemaFormElement
                v-else
                v-model="localCollateral[name]"
                :form="fields[name].form"
                :disabled="!isEditing"
                :required="form.schema.required.includes(name)"
                :custom-component-input="getCustomComponent"
                @focus="onFocusField(true)"
                @blur="onFocusField(false)"
            />
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
    import {
        JsonSchemaAbstractObject,
        JsonSchemaArray,
    } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import createForm from '@evidentid/json-schema/createForm';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Row, Cell } from '@evidentid/dashboard-commons/components/Table';
    import { JsonSchemaFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import pencilIcon from '@/assets/icons/icon-pencil-hollow.svg';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import CriterionCurrencyInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionCurrencyInput.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import { TableColumn } from '@/modules/entity-details/models/TableColumn.model';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { CustomPropertyCollateral } from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';
    import {
        TprmSchemaDisplayFormat,
    } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';

    const getCustomComponent = getJsonSchemaCompFunction({
        [TprmSchemaDisplayFormat.currency]: CriterionCurrencyInput,
    });
    export default Vue.extend({
        name: 'CustomPropertyCollateralRow',
        components: {
            JsonSchemaFormElement,
            Cell,
            EidIcon,
            Row,
        },
        props: {
            customPropertyCollaterals: {
                type: Object as PropType<CustomProperty>,
                required: true,
            },
            collateral: {
                type: Object as PropType<CustomPropertyCollateral>,
                default: () => ({}) as CustomPropertyCollateral,
            },
            isEditing: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            hideActions: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            updatingInsured: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            columns: {
                type: Array as PropType<TableColumn[]>,
                required: true,
            },
        },
        data() {
            const schema = (this.customPropertyCollaterals.schema as JsonSchemaArray<JsonSchemaAbstractObject>).items;
            const form = createForm(schema);
            const newCollateralData = form.getValue(undefined, true);
            return {
                binIcon,
                faCheck,
                faTimes,
                pencilIcon,
                form,
                newCollateralData,
                getCustomComponent,
                isAnyFieldFocused: false,
                localCollateral: {} as CustomPropertyCollateral,
                trustGreen: tailwindColors.eidTrustGreen.DEFAULT,
            };
        },
        computed: {
            fields(): Record<string, JsonFormProperty> {
                const properties = this.form.getProperties();
                return properties.reduce((acc, property) => {
                    acc[property.name] = property;
                    return acc;
                }, {} as Record<string, JsonFormProperty>);
            },
            isValidCollateral(): boolean {
                return this.form.isValid(this.form.getValue(this.localCollateral, true), true);
            },
        },
        watch: {
            collateral: {
                immediate: true,
                handler(newValue?: boolean) {
                    if (newValue) {
                        this.localCollateral = { ...this.collateral };
                    }
                },
            },
        },
        methods: {
            onFocusField(focused: boolean): void {
                this.isAnyFieldFocused = focused;
            },
            onClickDelete(): void {
                this.$emit('delete');
            },
            onClickEdit(): void {
                this.$emit('edit', true);
            },
            confirmEditCollateral(confirmed: boolean): void {
                if (confirmed && this.isValidCollateral) {
                    this.$emit('input', this.localCollateral);
                    this.$emit('edit', false);
                } else if (!confirmed) {
                    this.$emit('cancel-edit');
                    this.$emit('edit', false);
                }
            },
        },
    });
</script>
