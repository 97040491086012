<template>
    <div class="EvaluationError">
        <template v-if="showErrorMessage && !hasException">
            <FontAwesomeIcon :icon="faExclamationTriangle" />
            <div class="EvaluationError__messages">
                <div
                    v-for="(message, index) in evaluationError.messages"
                    :key="index"
                    class="EvaluationError__message"
                >
                    <b>{{ message.coverageCriteriaGroupName }} - </b>
                    <span>{{ message.message }}</span>
                </div>
            </div>
        </template>
        <ExceptionBadge
            v-if="showExceptionBadge && hasException"
            class="EvaluationError__exceptionBadge"
            :required-message="evaluationError.messages[0].message"
            :applied-to="exceptionAppliedTo"
            :exception-type="statusOverride[0] && statusOverride[0].type"
            :until="exceptionUntil"
            :disable-popover="false"
            :label="exceptionBadgeLabel"
        />
        <Expandable>
            <template #button>
                <FontAwesomeIcon
                    :icon="faEllipsisH"
                    class="EvaluationError__actionsButton"
                />
            </template>

            <Menu v-if="statusOverride.length > 0" spaced>
                <MenuLink
                    label="Remove Exception"
                    :disabled="hasHigherLevelException"
                    @click="$emit('removeExceptions', exceptionIds)"
                >
                    <template #label>
                        <div class="EvaluationError__menuLink">
                            <span class="EvaluationError__label">
                                Remove Exception
                            </span>
                        </div>
                    </template>
                </MenuLink>
            </Menu>
            <Menu v-else spaced>
                <MenuLink @click="$emit('grantExceptionToCriterion', evaluationError.criterionId)">
                    <template #label>
                        <div class="EvaluationError__menuLink">
                            <span class="EvaluationError__label">
                                <slot name="grant-exception-label">Grant Exception</slot>
                            </span>
                            <div class="EvaluationError__menuBody">
                                <slot v-if="showExceptionSubLabel" name="grant-exception-sub-label">
                                    This non-compliant criteria will be waived as compliant. This can be undone.
                                </slot>
                            </div>
                        </div>
                    </template>
                </MenuLink>
                <MenuLink
                    v-if="showGoToCriterion"
                    additional-class="EvaluationError__menuLink"
                    label="View criteria"
                    @click="$emit('goToCriterion', evaluationError.coverageCriteriaGroupId)"
                />
            </Menu>
        </Expandable>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faEllipsisH, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import ExceptionBadge from '@/modules/entity-details/components/ExceptionBadge/ExceptionBadge.vue';
    import {
        ExceptionLevel,
        ExceptionType,
        ComplianceStatusOverride,
    } from '@evidentid/tprm-portal-lib/models/entity-details';

    export default Vue.extend({
        name: 'EvaluationError',
        components: {
            FontAwesomeIcon,
            ExceptionBadge,
            Expandable,
            Menu,
            MenuLink,
        },
        props: {
            evaluationError: {
                type: Object as PropType<FieldEvaluationResultError>,
                required: true,
            },
            showErrorMessage: {
                type: Boolean as PropType<Boolean>,
                default: true,
            },
            showExceptionBadge: {
                type: Boolean as PropType<Boolean>,
                default: true,
            },
            showGoToCriterion: {
                type: Boolean as PropType<Boolean>,
                default: true,
            },
            exceptionBadgeLabel: {
                type: String as PropType<string>,
                default: undefined,
            },
            showExceptionSubLabel: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
                faEllipsisH,
            };
        },
        computed: {
            hasException(): boolean {
                return this.evaluationError.complianceStatusMetadata.length > 0;
            },
            statusOverride(): ComplianceStatusOverride[] {
                return this.evaluationError.complianceStatusMetadata
                    .reduce((acc, metadata) => {
                        if (metadata.statusOverride) {
                            acc.push(metadata.statusOverride);
                        }
                        return acc;
                    }, [] as ComplianceStatusOverride[]);
            },
            exceptionUntil(): string | null {
                if (this.statusOverride[0]?.type === ExceptionType.untilCoverageExpiration) {
                    return this.evaluationError.coverageDetailsBelongTo.coverage?.policy.expirationDate || null;
                } else {
                    return this.statusOverride[0]?.until || null;
                }
            },
            exceptionAppliedTo(): string {
                if (this.statusOverride.length === 0) {
                    return '';
                }
                switch (this.statusOverride[0].level) {
                    case ExceptionLevel.insured:
                        return 'Entity';
                    case ExceptionLevel.coverageType:
                        return 'Requirement';
                    default:
                        return this.evaluationError.criterionNames[0];
                }
            },
            exceptionIds(): string[] {
                return this.statusOverride.map((override) => override.id);
            },
            hasHigherLevelException(): boolean {
                return this.evaluationError.complianceStatusMetadata.some((metadata) => {
                    const exceptionLevel = metadata?.statusOverride?.level;
                    if (!exceptionLevel) {
                        return false;
                    }
                    return [
                        ExceptionLevel.coverageType,
                        ExceptionLevel.insured,
                    ].includes(exceptionLevel);
                });
            },
        },
    });
</script>
