<template>
    <Modal additional-class-name="DocumentDetailsModal" open @close="close">
        <template #header>
            Documents
            <span class="DocumentDetailsModal__titleSeparator" />
            {{ title }}
            <span class="DocumentDetailsModal__titleSeparator" />
            Upload Date:
            <LocalDate format="MM/DD/YYYY" :value="document.uploadedAt" />

            <Expandable v-if="pages.length > 0" class="DocumentDetailsModal__download" legacy-mode>
                <template #button>
                    <FontAwesomeIcon :icon="faDownload" />
                </template>

                <Menu spaced>
                    <MenuLink
                        v-for="(_, index) in pages"
                        :key="index"
                        :label="`File ${index + 1}`"
                        @click="download(index)"
                    />
                </Menu>
            </Expandable>
        </template>

        <template v-if="pages.length === 0">
            There are no documents attached.
        </template>
        <template v-else>
            <BinaryDataDisplay v-for="(page, index) in pages" :key="index" :value="page.content" />
        </template>
    </Modal>
</template>

<style lang="scss">
    // stylelint-disable selector-max-compound-selectors, selector-max-combinators
    .DocumentDetailsModal {
        .Modal__body {
            width: 90vw;
        }

        .Modal__headerClose {
            order: 10;
            border-right: 0;
            margin-right: 0;
            border-left: 1px solid #ddd;
        }

        .Modal__content {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 20px;
            padding-bottom: 20px;

            &::-webkit-scrollbar {
                width: 10px;
                background: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background: #bbb;
            }
        }

        .Modal__headerContent {
            padding-left: 20px;
            display: flex;
            align-items: center;
            align-self: stretch;
            flex: 1 0;
        }

        &__titleSeparator {
            position: relative;
            display: inline-block;
            align-self: stretch;
            width: 1px;
            margin: 0 20px;

            &:before {
                content: "";
                position: absolute;
                display: block;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #ddd;
            }
        }

        & &__download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            align-self: stretch;
            margin-left: auto;
            border-left: 1px solid #ddd;

            .Expandable__button {
                display: flex;
                color: #2ab496;
                width: 60px;
                height: 60px;
                align-items: center;
                justify-content: center;
            }

            .Expandable__content {
                margin-top: 0;
                right: 0;
            }

            &:not(.Expandable--opened):hover {
                .Expandable__button {
                    background: #eee;
                    box-shadow: none;
                }
            }

            &.Expandable--opened {
                .Expandable__button {
                    background: #eee;
                    box-shadow: none;
                }
            }
        }

        .BinaryDataDisplay {
            display: block;
            width: 100%;
            height: 75vh;

            &__frame {
                height: 100%;
            }

            & + & {
                margin-top: 20px;
            }
        }

        .LocalDate {
            margin-left: 10px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faDownload } from '@fortawesome/free-solid-svg-icons';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { LocalDate } from '@evidentid/dashboard-commons/components/LocalDate';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { mimetypeExtensions } from '@evidentid/file-utils/mimeTypes';
    import { sanitizeFileName } from '@evidentid/file-utils/sanitizeFileName';
    import { downloadFileUsingBase64String } from '@evidentid/file-utils/blobs';
    import { DocumentDetails } from '@/modules/entity-documents/types';
    import BinaryDataDisplay from '@/components/binary-data-display/BinaryDataDisplay.vue';
    import { getRequirementTypeLabelByAttribute } from '@/modules/entity-documents/services/attributeUtilities';

    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            FontAwesomeIcon,
            Modal,
            LocalDate,
            Expandable,
            BinaryDataDisplay,
            Menu,
            MenuLink,
        },
    })
    export default class DocumentDetailsModal extends Vue {
        private faDownload = faDownload;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: Object })
        private document!: DocumentDetails;

        @Prop({ type: Array })
        private requirementModels!: TprmRequirementModel[];

        @Prop({ type: String })
        private customTitle!: string;

        private get documentContent() {
            return this.document.content.certificate_of_insurance_document || this.document.content.document;
        }

        private get pages() {
            return this.documentContent.value?.content || [];
        }

        private get title(): string {
            const requirementTypeLabel = getRequirementTypeLabelByAttribute(
                this.document.id, false, this.requirementModels,
            );
            return this.customTitle || this.document.category || requirementTypeLabel || this.document.id;
        }

        private download(index: number): void {
            const { mime_type: mimeType, data } = this.pages[index].content;
            const extension = mimetypeExtensions[mimeType];
            const suffix = this.pages.length > 1 ? ` - File ${index + 1}` : '';
            const fileName = `${this.email} - ${this.document.category} - Document${suffix}${extension}`;
            downloadFileUsingBase64String(data, sanitizeFileName(fileName));
        }

        private close(): void {
            this.$emit('close');
        }
    }
</script>
