import uniq from 'lodash/uniq';
import { InternalUser, InternalUserInput, ServiceConfiguration, User, UserInput } from './types';

export function buildInternalUserInput(input: UserInput): InternalUserInput {
    return {
        name: input.name,
        email: input.email,
        services_enabled: Object.keys(input.services).filter((name) => input.services[name].enabled),
        resources_permitted: Object.keys(input.services).reduce((resources, name) => ({
            ...resources,
            [name]: input.services[name].resources,
        }), {}),
        roles: Object.keys(input.services).reduce((roles, name) => ({
            ...roles,
            [name]: input.services[name].roles,
        }), {}),
    };
}

export function buildUser(user: InternalUser): User {
    const serviceNames = uniq([
        ...user.services_enabled,
        ...Object.keys(user.roles),
        ...Object.keys(user.resources_permitted),
    ]);
    return {
        id: user.id,
        status: user.status,
        name: user.name,
        email: user.email,
        emailVerified: user.email_verified,
        avatarUrl: user.avatar_url,
        lastInviteAt: user.invite_email_timestamp,
        editable: /^auth0\|/.test(user.id),
        services: serviceNames.reduce((services, name) => ({
            ...services,
            [name]: {
                enabled: user.services_enabled.includes(name),
                resources: user.resources_permitted[name] || [],
                roles: user.roles[name] || [],
            },
        }), {} as Record<string, ServiceConfiguration>),
    };
}
