<template>
    <div class="MultiSelectOptions">
        <slot />
        <div class="MultiSelectOptions__headerActionBar">
            <div class="MultiSelectOptions__selectAllButton MultiSelectOptions__textButton" @click="selectAll">
                SELECT ALL
            </div>
            <div class="MultiSelectOptions__textButton" @click="clearAll">
                CLEAR
            </div>
        </div>
        <div class="MultiSelectOptions__options">
            <DropdownSelectOption
                v-for="(option, index) of options"
                :key="index"
                :disabled="isDisabledOption(option)"
                @click="selectOption(option)"
            >
                <Checkbox :value="selectedOptionsMap.has(option.label)" />
                <span>{{ option.label }}</span>
            </DropdownSelectOption>
        </div>
        <div class="MultiSelectOptions__footerActionBar">
            <div class="MultiSelectOptions__textButton" @click="$emit('abort')">
                Cancel
            </div>
            <div class="MultiSelectOptions__textButton MultiSelectOptions__submitButton" @click="$emit('change')">
                Apply
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '../Button';
    import Select from '../Select/Select.vue';
    import Checkbox from '../Checkbox/Checkbox.vue';
    import { DropdownOption, IsDisabledOption } from './types';
    import DropdownSelectOption from './DropdownSelectOption.vue';

    @Component({
        components: {
            Checkbox,
            Select,
            FontAwesomeIcon,
            Button,
            DropdownSelectOption,
        },
    })
    export default class Dropdown extends Vue {
        @Prop({ type: Array, default: () => [] })
        private options!: DropdownOption[];

        @Prop({ type: Array, default: () => [] })
        private selected!: DropdownOption[];

        @Prop({ type: Function as PropType<IsDisabledOption>, default: () => false })
        private isDisabledOption!: IsDisabledOption;

        private selectedOptionsMap: Map<string, DropdownOption> = new Map<string, DropdownOption>();

        @Watch('selected', { immediate: true })
        private onSelectedChanged() {
            this.selectedOptionsMap = new Map<string, DropdownOption>();
            this.selected.forEach((selected) => this.selectedOptionsMap.set(selected.label, selected));
        }

        private selectOption(option: DropdownOption): void {
            if (this.isDisabledOption(option)) {
                return;
            }
            if (this.selectedOptionsMap.has(option.label)) {
                this.selectedOptionsMap.delete(option.label);
            } else {
                this.selectedOptionsMap.set(option.label, option);
            }
            this.$emit('select', Array.from(this.selectedOptionsMap.values()));
        }

        private selectAll(): void {
            this.$emit('select', [ ...this.options ]);
        }

        private clearAll(): void {
            this.$emit('select', []);
            this.$emit('clear');
        }
    }
</script>
