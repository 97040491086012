import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isObject } from '@evidentid/json-schema/schemaChecks';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

function getObjectPropKey(field: CustomProperty): string[] {
    const getObjChildKey = (key: string) => `${field.key}.${key}`;
    return ('propertiesOrder' in field.schema &&
        field.schema.propertiesOrder &&
        field.schema.propertiesOrder.length > 0)
        ? field.schema.propertiesOrder.map(getObjChildKey)
        : Object.keys((field.schema as JsonSchemaObject).properties)
            .map(getObjChildKey);
}

export function getFlattenedCustomPropertyKeys(customProperties: CustomProperty[]): string[] {
    const keys = [] as string[];
    customProperties.forEach((field) => {
        if (isObject(field.schema)) {
            keys.push(...getObjectPropKey(field));
        } else {
            keys.push(field.key);
        }
    });
    return keys;
}
