import {
    IamClient,
    IamClientAdapterName,
    IamClientAdapterOptions,
    getAuthErrorMessage,
} from '@evidentid/iam-client';
import { createModuleBuilder } from '../../app';
import type { SnackbarModule } from '../snackbar';

export interface IamConfig<K extends IamClientAdapterName> {
    name: K;
    options: IamClientAdapterOptions<K>;
}

export function createAuthConnectorNotifySnackbarModule() {
    return createModuleBuilder()
        .demandContext<{ auth: IamClient }>()
        .demand<SnackbarModule>()
        .execute((app) => {
            // Handle the IAM result
            const { result, done } = app.auth.getInitializationResult();
            if (done && result instanceof Error) {
                app.store.actions.snackbar.displaySnackbar({
                    message: getAuthErrorMessage(result),
                    permanent: true,
                    success: false,
                });
            }
        })
        .end();
}

export type AuthConnectorModule = ReturnType<typeof createAuthConnectorNotifySnackbarModule>;
