import { LocationService } from './LocationService';
import { PartnerSettings } from './model/PartnerSettings.model';

const environmentToUrlMap: Record<string, string> = {
    dev: 'https://privacy-staging.evidentid.com/partner-profiles/config-dev/',
    int: 'https://privacy-staging.evidentid.com/partner-profiles/config-int/',
    loadtest: 'https://privacy-staging.evidentid.com/partner-profiles/config-loadtest/',
    staging: 'https://privacy.evidentid.com/partner-profiles/config-staging/',
    demo: 'https://privacy.evidentid.com/partner-profiles/config-demo/',
    production: 'https://privacy.evidentid.com/partner-profiles/config/',
};
const cache: Record<string, PartnerSettings | null> = {};
export const PartnersSettingsService = {
    extractPartnerName: (): string | undefined => {
        const URL_PARTS_LENGTH_WITH_PARTNER_NAME = 4;

        const currentLocation = LocationService.getCurrentUrl();
        const locationWithoutHttps = currentLocation.split('//')[1];
        const urlParts = locationWithoutHttps.split('.');
        if (urlParts.length < URL_PARTS_LENGTH_WITH_PARTNER_NAME) {
            return undefined;
        }
        return urlParts[0];
    },
    getPartnerSettingsUrl: (partnerName: string, environment: string): string => {
        const baseUrl = environmentToUrlMap[environment];
        return `${baseUrl + partnerName}.json`;
    },
    getPartnerSettings: (): PartnerSettings | null => {
        const partnerName = PartnersSettingsService.extractPartnerName() ?? '';
        const cachedPartnerSettings = cache[partnerName];
        if (cachedPartnerSettings === undefined) {
            PartnersSettingsService.fetchPartnerSettings();
        }
        return cachedPartnerSettings;
    },
    fetchPartnerSettings: async (): Promise<PartnerSettings | null> => {
        const partnerName = PartnersSettingsService.extractPartnerName();
        const environment = deploy.WEB_PUBLIC_ENVIRONMENT;

        if (!partnerName) {
            return null;
        }
        if (cache[partnerName]) {
            return cache[partnerName];
        }
        const url = PartnersSettingsService.getPartnerSettingsUrl(partnerName, environment);
        try {
            const response = await fetch(url, { cache: 'force-cache' });
            const partnerSettings = await response.json();
            cache[partnerName] = partnerSettings;
            return partnerSettings;
        } catch (e) {
            console.error(e);
            cache[partnerName] = null;
        }
        return null;
    },
};
