/**
 * Loosely based on broken "quill-smart-break" module.
 * @see {@link https://www.npmjs.com/package/quill-smart-break}
 */

import Quill, { RangeStatic } from 'quill';
const Embed = Quill.import('blots/embed');

class SmartBreak extends Embed {
    public static value(): string {
        return '\n';
    }

    public static create(): Element {
        const node = super.create();
        node.appendChild(document.createTextNode('\n'));
        return node;
    }

    public length(): number {
        return 1;
    }

    public value(): string {
        return '\n';
    }

    public insertInto(parent: any, ref: any): void {
        if (parent.children.length === 0) {
            this.remove();
        } else if (parent.children.length === 1 && parent.children.head.domNode.tagName === 'BR') {
            this.remove();
        } else if (parent.children.length === 1 && parent.children.head.domNode.tagName === 'SBR') {
            this.remove();
        } else {
            super.insertInto(parent, ref);
        }
    }
}

SmartBreak.blotName = 'simulated-break';
SmartBreak.tagName = 'SBR';

class SmartBreaker {
    private quill: Quill;
    private binding = {
        key: 'enter',
        shiftKey: true,
    };

    public static register(): void {
        Quill.register(SmartBreak, true);
    }

    public constructor(quill: Quill) {
        this.quill = quill;

        // @ts-ignore: invalid type definitions
        quill.keyboard.addBinding(this.binding, this.enterHandler.bind(this));
        // @ts-ignore: hitting internals
        quill.keyboard.bindings[13].unshift(quill.keyboard.bindings[13].pop());
        const text = quill.getText(quill.getLength() - 2, 2);
        if (text === '\n\n') {
            quill.deleteText(quill.getLength() - 2, 2);
        }
    }

    private enterHandler(range: RangeStatic): void {
        this.quill.insertEmbed(range.index, 'simulated-break', true, 'user');
        this.quill.setSelection(range.index + 1, 0, 'silent');
    }
}

export default SmartBreaker;
