import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common';

import {
    EidJsonSchemaFormComponentMatchingFunction,
    EidJsonSchemaFormCustomComponentInput,
    EidJsonSchemaFormCustomComponentsMap,
} from './types';
import { EID_JSON_SCHEMA_COMPONENTS } from './defaults';

export function getFormElementComponentFromComponentsMap({
    componentsMap,
    schema,
}: {
    componentsMap: EidJsonSchemaFormCustomComponentsMap;
    schema: JsonSchema;
}): Vue.Component | Vue.AsyncComponent {
    let component;
    if ('displayFormat' in schema) {
        // TODO: fix type for the jsonSchema
        component = componentsMap[(schema as any).displayFormat as TprmSchemaDisplayFormat];
    }
    if (!component && 'type' in schema) {
        component = component || componentsMap[schema.type];
    }
    if (!component) {
        throw new Error(`No component found for schema: ${JSON.stringify(schema)}`);
    }
    return component;
}

export function getFormElementComponent({
    customComponentInput,
    valuePath,
    schema,
}: {
    customComponentInput?: EidJsonSchemaFormCustomComponentInput;
    schema: JsonSchema;
    valuePath: string;
}): Vue.Component | Vue.AsyncComponent {
    const getCustomComponent = typeof customComponentInput === 'function'
        ? customComponentInput as EidJsonSchemaFormComponentMatchingFunction
        : null;
    const components = {
        ...EID_JSON_SCHEMA_COMPONENTS,
        ...(typeof customComponentInput === 'object'
            ? customComponentInput as EidJsonSchemaFormCustomComponentsMap
            : {}),
    };
    const component = getCustomComponent?.({ schema, valuePath });
    if (component) {
        return component;
    }
    return getFormElementComponentFromComponentsMap({ componentsMap: components, schema });
}
