<template>
    <portal-target class="ModalTarget" :name="name" multiple />
</template>

<script lang="ts">
    import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
    import noop from 'lodash/noop';

    @Component
    export default class ModalTarget extends Vue {
        @Prop({ type: String, default: 'default-modal-target' })
        private name!: string;

        @Prop({ type: String, default: null })
        private parentClassNameOpened!: string | null;

        @Prop({ type: String, default: null })
        private parentClassNameClosed!: string | null;

        @Prop({ default: () => document.body })
        private parent!: HTMLElement;

        private isMounted = false;
        private open = false;
        private unsubscribe = noop;

        private get openedClassNames(): string[] {
            return (this.parentClassNameOpened || '').trim().split(/\s+/g).filter(Boolean);
        }

        private get closedClassNames(): string[] {
            return (this.parentClassNameClosed || '').trim().split(/\s+/g).filter(Boolean);
        }

        private subscribe(name: string): void {
            this.open = this.$modalManager.getOpenStatus(name);
            this.unsubscribe = this.$modalManager.subscribe(name, (open: boolean) => {
                this.open = open;
            });
        }

        @Watch('name', { immediate: true })
        private onNameChange(name: string): void {
            this.unsubscribe();
            this.subscribe(name);
        }

        @Watch('open', { immediate: true })
        @Watch('isMounted')
        private onOpenChange(): void {
            if (this.open && this.isMounted) {
                this.closedClassNames.forEach((cls) => this.parent.classList.remove(cls));
                this.openedClassNames.forEach((cls) => this.parent.classList.add(cls));
            } else {
                this.openedClassNames.forEach((cls) => this.parent.classList.remove(cls));
                this.closedClassNames.forEach((cls) => this.parent.classList.add(cls));
            }
        }

        @Watch('parentClassNameOpened')
        @Watch('parentClassNameClosed')
        private notImplemented(): void {
            console.warn('Updating expected classes for ModalTarget is not supported yet.');
        }

        private mounted() {
            this.isMounted = true;
        }

        private destroyed(): void {
            this.isMounted = false;
            this.unsubscribe();
        }
    }
</script>
