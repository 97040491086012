export type ServiceName = string;
export type RoleName = string;
export type ResourcePattern = string;

/* eslint-disable camelcase */

export enum UserStatus {
    active = 'active',
    pending = 'pending',
}

export interface InternalUserInput {
    name: string;
    email: string;
    services_enabled: ServiceName[];
    resources_permitted: Record<ServiceName, ResourcePattern[]>;
    roles: Record<ServiceName, RoleName[]>;
}

export interface ServiceConfiguration {
    enabled: boolean;
    resources: ResourcePattern[];
    roles: RoleName[];
}

export interface UserInput {
    name: string;
    email: string;
    services: Record<ServiceName, ServiceConfiguration>;
}

export interface User {
    id: string;
    email: string;
    name: string;
    emailVerified: boolean;
    lastInviteAt: string | null; // ISO Date
    avatarUrl: string;
    editable: boolean;
    status: UserStatus;
    services: Record<ServiceName, ServiceConfiguration>;
}

export interface InternalUser extends InternalUserInput {
    id: string;
    email_verified: boolean;
    invite_email_timestamp: string | null; // ISO Date
    status: UserStatus;
    avatar_url: string;
}

export interface UserSearchResult {
    prev: string | null;
    next: string | null;
    results: User[];
}

export enum TicketStatus {
    error = 'error',
    success = 'success',
}
