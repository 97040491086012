import { ModuleBuilder } from './internals/ModuleBuilder';
import { ApplicationBuilder } from './internals/ApplicationBuilder';
export type { ExtractVueContext } from './types/Application';
export type { ExtractVueContextFromFactory } from './types/ModuleDeclaration';

export function createModuleBuilder() {
    return new ModuleBuilder();
}

export function createApplicationBuilder() {
    return new ApplicationBuilder();
}
