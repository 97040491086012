<template>
    <div class="BulkImportArrayField" :class="{'BulkImportArrayField--focus': focused}">
        <FormElement>
            <div v-for="(item, index) in (localValue || [])" :key="index" class="BulkImportArrayField__item">
                <v-component
                    :is="FormElementComponent"
                    :id="`${id}_${index}`"
                    :key="index"
                    :custom-component-input="customComponentInput"
                    :depth="depth + 1"
                    :form="form.item"
                    :value="item"
                    :touched="touched"
                    :required="required"
                    :disabled="disabled"
                    :strings="strings"
                    :deletable="itemsLength > 1"
                    :description="form.schema.description"
                    @focus="onChildFocused(index)"
                    @touch="onChildTouched(index)"
                    @delete="onDelete(index)"
                    @input="onChange(index, $event)"
                >
                    <template v-if="$slots.icon" v-slot:icon>
                        <slot name="icon" />
                    </template>
                </v-component>
            </div>
        </FormElement>
    </div>
</template>

<script lang="ts">

    import { Component, Watch } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import { JsonFormArray } from '@evidentid/json-schema/interfaces/JsonForm';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    @Component({
        components: { FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class BulkImportArrayField extends AbstractJsonSchemaForm<JsonFormArray, any[]> {
        private childAccessedList: boolean[] = new Array(this.itemsLength);
        private focusedChildIndex: number | null = null;
        private localValue = this.value;

        @Watch('value', { immediate: true })
        private onValueChange() {
            // perform deep copy if ever supporting Object
            if (this.form.getValue(this.value).length === 0) {
                this.localValue = [ this.form.item.getValue() ];
            } else {
                this.localValue = [ ...this.value ];
            }
        }

        private get itemsLength() {
            return Array.isArray(this.localValue) ? this.localValue.length : 0;
        }

        private get minItems() {
            return this.form.schema.minItems || 0;
        }

        private onChildFocused(index: number): void {
            this.focused = true;
            this.focusedChildIndex = index;
            this.$emit('focus');
        }

        private onChildTouched(index: number): void {
            this.focused = false;
            this.childAccessedList[index] = true;
            this.focusedChildIndex = null;
            this.$emit('blur');
        }

        private onDelete(index: number) {
            this.childAccessedList = [
                ...this.childAccessedList.slice(0, index),
                ...this.childAccessedList.slice(index + 1),
            ];
            this.$emit('input', this.form.getValue([
                ...this.localValue.slice(0, index),
                ...this.localValue.slice(index + 1),
            ]));
        }

        private onChange(index: number, value: any) {
            this.$emit('input', this.form.getValue([
                ...this.localValue.slice(0, index),
                value,
                ...this.localValue.slice(index + 1),
            ]));
        }
    }
</script>
