<template>
    <div class="FormElement">
        <label v-if="hasLabel" class="FormElement__label" :for="labelFor">
            <slot name="label">{{ label }}</slot>
            <span v-if="required" class="FormElement__requiredIndicator">
                <slot name="requiredIndicator">*</slot>
            </span>
            <span class="FormElement__labelHint">
                <slot name="labelHint" />
            </span>
        </label>
        <div class="FormElement__input">
            <slot />
        </div>
        <div v-if="forceErrorSlot || $scopedSlots.errorMessage" class="FormElement__errorMessage">
            <div v-if="errorIcon" class="FormInput__errorIcon">
                <FontAwesomeIcon v-if="$scopedSlots.errorMessage" :icon="errorIcon" />
            </div>
            <div class="FormInput__errorContent">
                <slot name="errorMessage" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class FormElement extends Vue {
        @Prop({ type: String, default: null })
        private label?: string | null;

        @Prop({ type: String, default: null })
        private labelFor!: string | null;

        @Prop({ type: Boolean, default: false })
        private required!: boolean;

        @Prop({ type: Boolean, default: false })
        protected forceErrorSlot!: boolean;

        @Prop({ default: () => faExclamationTriangle })
        protected errorIcon!: IconDefinition;

        private get hasLabel(): boolean {
            return Boolean(this.$scopedSlots.label || this.label);
        }
    }
</script>
