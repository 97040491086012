<template>
    <div class="WebhooksTable text-eidDarkBlue">
        <template>
            <Table>
                <template #head>
                    <HeadCell
                        data-test-id="WebhooksTable__nameHeadCell"
                        class="text-eidDarkBlue-50 font-semibold"
                    >
                        Event
                    </HeadCell>
                    <HeadCell
                        data-test-id="WebhooksTable__urlHeadCell"
                        class="text-eidDarkBlue-50 font-semibold"
                    >
                        Webhook URL
                    </HeadCell>
                </template>
                <WebhooksTableRow
                    v-for="(webhook, index) in localWebhooks"
                    :key="index"
                    :webhook="webhook"
                    :hide-actions="indexOfEditWebhook != null && indexOfEditWebhook !== index"
                    :updating="updating"
                    :is-editing="indexOfEditWebhook === index"
                    :schema="rowSchema"
                    @change="onWebhookChange($event,index)"
                    @edit="onEditStatusChange($event, index)"
                    @cancel-edit="cancelEdit(index)"
                    @delete="onDeleteWebhook(index)"
                />
            </Table>
        </template>
        <template v-if="!localWebhooks || localWebhooks.length === 0">
            <div
                data-test-id="WebhooksTable__noWebhooksText"
                class="WebhooksTable__emptyContainer px-7 text-eidDarkBlue-50"
            >
                You can configure API settings to integrate with systems you are already using.
            </div>
        </template>
        <Button
            v-if="indexOfEditWebhook == null && !updating"
            data-test-id="WebhooksTable__addButton"
            class="bg-transparent text-eidTrustGreen px-7 py-4"
            type="primary"
            :icon="faPlus"
            @click="onClickAddWebhook"
        >
            ADD WEBHOOK
        </Button>

        <ConfirmationModal
            v-if="indexOfDeletingWebhook != null"
            additional-class-name="ConfirmDeleteCollateralModal"
            size="optimal"
            yes="Delete"
            no="Cancel"
            :yes-icon="null"
            :no-icon="null"
            :destructive="true"
            :progress="deleting"
            @input="onConfirmDelete"
        >
            <template #header>
                Are you sure you want to delete the webhook?
            </template>
            <p>
                Deleting an item will permanently remove it from the system.
            </p>
        </ConfirmationModal>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faPlus } from '@fortawesome/free-solid-svg-icons';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import WebhooksTableRow
        from '@/modules/api-settings/components/webhooks-table-row/WebhooksTableRow.vue';
    import { InsuranceWebhook, InsuranceWebhookType } from '@evidentid/tprm-portal-lib/models/api-settings';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import {
        buildWebhookTableRowSchema,
    } from '@/modules/api-settings/utils/buildWebhookTableRowSchema/buildWebhookTableRowSchema';

    export default Vue.extend({
        name: 'WebhooksTable',
        components: {
            WebhooksTableRow,
            Button,
            HeadCell,
            Table,
            ConfirmationModal,
        },
        props: {
            webhooks: {
                type: Array as PropType<InsuranceWebhook[]>,
                default: () => [] as InsuranceWebhook[],
            },
            webhookTypes: {
                type: Array as PropType<InsuranceWebhookType[]>,
                default: () => [] as InsuranceWebhookType[],
            },
            updating: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            deleting: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faPlus,
                indexOfDeletingWebhook: null as number | null,
                localWebhooks: [] as InsuranceWebhook[],
                indexOfEditWebhook: null as number | null,
                rowSchema: buildWebhookTableRowSchema(this.webhookTypes),
            };
        },
        watch: {
            deleting(newValue: boolean) {
                if (!newValue) {
                    this.indexOfDeletingWebhook = null;
                }
            },
            webhooks: {
                immediate: true,
                handler(newValue?: boolean) {
                    if (newValue) {
                        this.localWebhooks = [ ...this.webhooks ];
                    }
                },
            },
            webhookTypes: {
                immediate: true,
                handler() {
                    this.rowSchema = buildWebhookTableRowSchema(this.webhookTypes);
                },
            },
        },
        methods: {
            onClickAddWebhook(): void {
                this.localWebhooks.push({} as InsuranceWebhook);
                this.indexOfEditWebhook = this.localWebhooks.length - 1;
            },
            onWebhookChange(webhook: InsuranceWebhook, listIndex: number): void {
                this.localWebhooks = [
                    ...this.localWebhooks.slice(0, listIndex),
                    webhook,
                    ...this.localWebhooks.slice(listIndex + 1),
                ];
                this.$emit('change', webhook);
            },
            onDeleteWebhook(webhookIndex: number): void {
                if (this.localWebhooks[webhookIndex]?.id) {
                    this.indexOfDeletingWebhook = webhookIndex;
                } else {
                    // if no id, it's new input, simply remove from local list
                    this.localWebhooks = [
                        ...this.localWebhooks.slice(0, webhookIndex),
                        ...this.localWebhooks.slice(webhookIndex + 1),
                    ];
                }
            },
            onConfirmDelete(confirmed: boolean): void {
                if (!confirmed) {
                    this.indexOfDeletingWebhook = null;
                } else if (this.indexOfDeletingWebhook != null) {
                    const toDelete = { ...this.localWebhooks[this.indexOfDeletingWebhook] };
                    this.$emit('delete', toDelete);
                    this.localWebhooks = [
                        ...this.localWebhooks.slice(0, this.indexOfDeletingWebhook),
                        ...this.localWebhooks.slice(this.indexOfDeletingWebhook + 1),
                    ];
                }
            },
            onEditStatusChange(editing: boolean, webhookIndex: number): void {
                this.indexOfEditWebhook = editing ? webhookIndex : null;
            },

            cancelEdit(webhookIndex: number): void {
                const isExistedWebhook = Boolean(this.localWebhooks[webhookIndex]?.id);
                if (isExistedWebhook) {
                    this.localWebhooks = [
                        ...this.localWebhooks.slice(0, webhookIndex),
                        { ...this.localWebhooks[webhookIndex] },
                        ...this.localWebhooks.slice(webhookIndex + 1),
                    ];
                } else {
                    this.localWebhooks.pop();
                }
            },
        },
    });
</script>
