<template>
    <div class="DecisioningCriteria">
        <Page :loading="isLoading" title="Decisioning">
            <DashboardConfirmationModal
                v-if="deleteId"
                additional-class-name="DecisioningCriteria__deleteConfirmation"
                header-text="Are you sure?"
                :progress="deletingRiskProfileStatus === OperationStatus.loading"
                @input="onConfirmationInput"
                @abort="closeDeleteConfirmation"
            >
                <span>Please confirm you want to delete </span>
                <span class="DecisioningCriteria__deleteRiskProfileName">{{ deletingRiskProfileName }}</span>
            </DashboardConfirmationModal>
            <RiskProfiles
                :risk-profiles="sortedRiskProfiles"
                @create="createRiskProfile"
                @edit="editRiskProfile"
                @delete="deleteRiskProfile"
            />
        </Page>
    </div>
</template>

<style lang="scss">
    .DecisioningCriteria {
        // TODO[PRODUCT-20869]: remove temp solution unblocks evie from view content once long term solution provided
        .MainLayout__content {
            padding-bottom: 100px;
        }

        &__deleteConfirmation.Modal {
            .Modal__body {
                height: 350px;
            }
        }

        &__deleteRiskProfileName {
            color: #2eb495;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import sortBy from 'lodash/sortBy';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import Page from '@/layouts/Page.vue';
    import RiskProfileAssignment from '@/modules/decisioning-criteria/components/RiskProfileAssignment/RiskProfileAssignment.vue';
    import { RiskProfilesStatus } from '@/modules/decisioning-criteria/vuex';
    import RiskProfiles
        from '@/modules/decisioning-criteria/components/RiskProfiles/RiskProfiles.vue';
    import CreateEditRiskProfile
        from '@/modules/decisioning-criteria/components/CreateEditRiskProfile/CreateEditRiskProfile.vue';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';
    import { RiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    @Component({
        components: {
            DashboardConfirmationModal,
            RiskProfiles,
            CreateEditRiskProfile,
            RiskProfileAssignment,
            Page,
            PendingScreen,
        },
    })
    export default class DecisioningCriteria extends Vue {
        private lastRpName!: string;
        private deleteId: string | null = null;
        private OperationStatus = OperationStatus;

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get riskProfilesStatus(): RiskProfilesStatus {
            return this.$store.state.decisioningCriteria.riskProfiles[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get deletingRiskProfileStatus(): OperationStatus {
            return this.$store.state.decisioningCriteria.deletingRiskProfileStatus[this.rpName] ||
                OperationStatus.uninitialized;
        }

        private get isLoading(): boolean {
            return this.riskProfilesStatus.status === OperationStatus.loading;
        }

        private get deletingRiskProfileName(): string {
            return this.riskProfilesStatus.list.find((x) => x.id === this.deleteId)?.displayName || '';
        }

        private get sortedRiskProfiles(): RiskProfile[] {
            return sortBy(this.riskProfilesStatus.list, [ (riskProfile) => riskProfile.displayName.toLowerCase() ]);
        }

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            if (prevRpName) {
                this.$store.actions.decisioningCriteria.clearRiskProfiles({ rpName: prevRpName });
            }
            this.loadRiskProfiles();
        }

        private loadRiskProfiles() {
            if (this.riskProfilesStatus.status !== OperationStatus.loading) {
                this.$store.actions.decisioningCriteria.loadRiskProfiles({ rpName: this.rpName });
            }
        }

        private async onConfirmationInput(confirmed: boolean): Promise<void> {
            if (confirmed && this.deleteId && this.riskProfilesStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.deleteRiskProfile({
                    rpName: this.rpName,
                    riskProfileId: this.deleteId,
                });
            }
            this.closeDeleteConfirmation();
        }

        private closeDeleteConfirmation(): void {
            this.deleteId = null;
        }

        private createRiskProfile() {
            this.$router.push({
                name: 'createDecisioningRiskProfile',
                params: {
                    rpId: this.$route.params.rpId,
                },
            });
        }

        private editRiskProfile(id: string) {
            this.$router.push({
                name: 'decisioningRiskProfile',
                params: {
                    rpId: this.$route.params.rpId,
                    id,
                },
            });
        }

        private deleteRiskProfile(id: string) {
            this.deleteId = id;
        }

        private destroyed() {
            this.$store.actions.decisioningCriteria.clearRiskProfiles({ rpName: this.lastRpName });
            this.$store.actions.decisioningCriteria.clearDeletingRiskProfileStatus(
                { rpName: this.lastRpName });
        }
    }
</script>
