<template>
    <PendingScreen />
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';

    @Component({
        components: { PendingScreen },
    })
    export default class Loading extends Vue {
    }
</script>
