import TrackerUserData from '@evidentid/tracker/TrackerUserData';
import TrackerAdapter from '@evidentid/tracker/TrackerAdapter';
import Tracker from '@evidentid/tracker';
import { SealedModule } from '../../app/internals/SealedModule';
import {
    ModuleDemandedOptions,
    ModuleInjectedContext,
    ModuleInjectedVueContext,
} from '../../app/types/ModuleDeclaration';
import { createModuleBuilder } from '../../app';

export enum TrackerType {
    noop = 'noop',
    console = 'console',
    woopra = 'woopra',
    mixPanel = 'mixpanel',
    heapAnalytics = 'heapanalytics',
}

export function isTrackerType(name: unknown): name is TrackerType {
    return (
        typeof name === 'string' &&
        (Object.values(TrackerType) as string[]).includes(name)
    );
}

const factories: Record<TrackerType, (options: any) => Promise<TrackerAdapter>> = {
    async [TrackerType.noop]() {
        const { default: Adapter } = await import('@evidentid/tracker/adapters/NoopTrackerAdapter');
        return new Adapter();
    },
    async [TrackerType.console]() {
        const { default: Adapter } = await import('@evidentid/tracker/adapters/ConsoleTrackerAdapter');
        return new Adapter();
    },
    async [TrackerType.woopra](options) {
        const { default: Adapter } = await import('@evidentid/tracker/adapters/WoopraTrackerAdapter');
        return new Adapter(options);
    },
    async [TrackerType.mixPanel](options) {
        const { default: Adapter } = await import('@evidentid/tracker/adapters/MixpanelTrackerAdapter');
        return new Adapter(options);
    },
    async [TrackerType.heapAnalytics](options) {
        const { default: Adapter } = await import('@evidentid/tracker/adapters/HeapAnalyticsTrackerAdapter');
        return new Adapter(options);
    },
};

interface TrackerOptions {
    tracker: { adapter: TrackerType | string, options?: object };
}

export function createTrackerModule<
    T extends TrackerUserData = TrackerUserData,
>() {
    return createModuleBuilder()
        .demandOptions<TrackerOptions>()
        .inject(async (app) => {
            const { adapter, options } = app.options.tracker;
            if (!isTrackerType(adapter)) {
                throw new Error('Invalid tracker type');
            }
            return {
                tracker: new Tracker<T>(await factories[adapter](options || {})),
            };
        })
        .execute((app) => {
            app.tracker.initialize()
                .catch((error) => console.error('Something went wrong during Tracker initialization', error));
        })
        .injectVue((app) => ({
            $tracker: app.tracker,
        }))
        .end();
}

export type TrackerModule<T extends TrackerUserData = TrackerUserData> = SealedModule<
    ModuleDemandedOptions<TrackerOptions> &
    ModuleInjectedContext<{ tracker: Tracker<T> }> &
    ModuleInjectedVueContext<{ $tracker: Tracker<T> }>
>;

