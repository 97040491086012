import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import {
    getEvaluationErrorWithCombinedFields,
} from '@/modules/entity-details/utils/getEvaluationErrorWithCombinedFields/getEvaluationErrorWithCombinedFields';

export function getEvaluationError(
    fieldId: string,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    hasCombinedFields?: boolean,
): FieldEvaluationResultError | null {
    return hasCombinedFields
        ? getEvaluationErrorWithCombinedFields(fieldId, evaluationErrors)
        : evaluationErrors[fieldId] || null;
}
