<template>
    <ScreenMessage
        v-if="canSign"
        type="warning"
        has-user-panel
        has-rp-panel
        has-logo
        relative
    >
        <div class="Terms">
            <div class="Terms__content" v-html="content" />
            <div class="Terms__acknowledgement">
                <Checkbox v-model="acknowledged" :disabled="submitting">
                    I acknowledge that I have read and accept these terms and conditions
                </Checkbox>
            </div>
            <div class="Terms__footer">
                <Button :loading="submitting" :disabled="!submittable" type="primary" @click="submit">
                    Sign
                </Button>
            </div>
        </div>
    </ScreenMessage>
    <ScreenMessage
        v-else
        type="warning"
        has-user-panel
        has-rp-panel
        has-logo
    >
        <template slot="icon">
            <FontAwesomeIcon :icon="faFileContract" />
        </template>
        <h3>T&Cs requires approval</h3>
        <p>
            To continue using Evident's services you need to ask your account administrator to log in and accept them.
        </p>
    </ScreenMessage>
</template>

<style lang="scss">
    .Terms {
        max-width: 800px;
        margin: 0 auto;

        &__content {
            padding: 20px;
            height: 50vh;
            min-height: 300px;
            max-height: 600px;
            border: 1px solid #bbb;
            overflow-y: scroll;
            text-align: justify;
            background: #fff;

            &::-webkit-scrollbar {
                width: 8px;
                background: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background: #bbb;
            }
        }

        &__acknowledgement {
            margin-top: 20px;

            .Checkbox {
                margin: 0 auto;
            }
        }

        &__footer {
            margin-top: 20px;
        }

        .Checkbox {
            display: inline-flex;
            margin: 0 auto;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import noop from 'lodash/noop';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faFileContract } from '@fortawesome/free-solid-svg-icons';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ScreenMessage from '@/components/screen-message/ScreenMessage.vue';

    @Component({
        components: { Checkbox, ScreenMessage, Button, FontAwesomeIcon },
    })
    export default class Terms extends Vue {
        private faFileContract = faFileContract;

        @Prop({ type: String })
        private content!: string;

        @Prop({ type: String })
        private hash!: string;

        @Prop({ type: Boolean, default: false })
        private canSign!: string;

        private acknowledged = false;
        private fired = false;

        private get nextLocation(): string | null {
            const next = this.$route.query.next;
            return next && typeof next === 'string' ? next : null;
        }

        @Watch('submitting', { immediate: true })
        private ensureAcknowledgmentStatus(submitting: boolean): void {
            this.fired = true;
            if (submitting) {
                this.acknowledged = true;
            }
        }

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get updateStatus(): OperationStatus {
            return this.$store.getStatus('terms', 'updateSign', this.rpName);
        }

        private get submitting(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private get submittable(): boolean {
            return !this.submitting && this.acknowledged;
        }

        private async submit(): Promise<void> {
            if (!this.submittable) {
                return;
            }

            await this.$store.actions.terms.signTerms({ rpName: this.rpName });

            if (this.nextLocation && this.updateStatus === OperationStatus.success) {
                this.$router.push(this.nextLocation).catch(noop);
            }
        }
    }
</script>
