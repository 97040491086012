import { TprmRequirementType } from '../../../../tprm-portal-lib/models/entity-details';

const w9FormFieldOrder = [
    'status',
    'Entity Name',
    'federalTaxClassification',
    'llcTaxClassification',
    'otherClassification',
    'foreignPartnersOwnersOrBeneficiaries',
    'exemptPayeeCode',
    'exemptionFromFatcaReportingCode',
    'entityAddress',
    'requesterName',
    'requesterAddress',
    'accountNumbers',
    'Taxpayer Identification Number',
    'signature',
    'dateOfSignature',
];
const w9FormMultiFieldOrder = {
    'Entity Name': [ 'entityIndividualName', 'businessName' ],
    'Taxpayer Identification Number': [ 'socialSecurityNumber', 'employerIdentificationNumber' ],
};

export const TPRM_FIELD_ORDER_MAP: Partial<Record<TprmRequirementType, string[]>> = {
    [TprmRequirementType.w9Form]: w9FormFieldOrder,
};

export const TPRM_MULTI_FIELD_CRITERION_FIELD_ORDER_MAP: Partial<
    Record<TprmRequirementType, Record<string, string[]>>
> = {
    [TprmRequirementType.w9Form]: w9FormMultiFieldOrder,
};
