var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MenuLink',{staticClass:"SidebarMenu__topLevelItem",attrs:{"data-test-id":"SidebarMenuNotificationItem","icon":_vm.emailSettingsIcon,"active":_vm.active,"open":_vm.subMenuExpanded},on:{"click":_vm.toggleMenu},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"MenuElement__linkText flex items-center"},[_vm._v(" Display & Email "),(_vm.someNotificationsOff)?_c('EidIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    html: true,
                    content: '<strong>Some notifications are off</strong> <br> See more in Email Settings',
                }),expression:"{\n                    html: true,\n                    content: '<strong>Some notifications are off</strong> <br> See more in Email Settings',\n                }"}],staticClass:"inline-block ml-2 text-eidSecureBlue-50",attrs:{"data-test-id":"SidebarMenu__notificationsOffIcon","icon-src":_vm.faBellSlash}}):_vm._e()],1)]},proxy:true}])},[(_vm.eventManagerEnabled)?_c('WithPermissions',{staticClass:"SidebarMenu__configurationItem",attrs:{"data-test-id":"SidebarMenu__brandingSettings","component":_vm.MenuLink,"props":{ href: '#/notifications/branding',
                  label: 'Branding Settings',
                  active: _vm.notificationsTab === 'branding'},"permissions":[ _vm.manageConfigPermission ],"accept-not-scoped":""}}):_vm._e(),(_vm.eventManagerEnabled)?_c('WithPermissions',{staticClass:"SidebarMenu__configurationItem",attrs:{"data-test-id":"SidebarMenu__supportContactInformation","component":_vm.MenuLink,"props":{ href: '#/notifications/contact',
                  label: 'Contact Information',
                  active: _vm.notificationsTab === 'contact'},"permissions":[ _vm.manageConfigPermission ],"accept-not-scoped":""}}):_vm._e(),_c('MenuLink',{staticClass:"SidebarMenu__configurationItem",attrs:{"data-test-id":"SidebarMenu__emailContent","href":'#/notifications/email-content',"active":_vm.notificationsTab === 'emailContent',"label":"Email Content"}}),_c('MenuLink',{staticClass:"SidebarMenu__configurationItem",attrs:{"data-test-id":"SidebarMenu__emailSettings","href":'#/notifications/email-settings',"active":_vm.notificationsTab === 'emailSettings'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"MenuElement__linkText flex items-center"},[_vm._v(" Email Settings "),(_vm.someNotificationsOff)?_c('EidIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        html: true,
                        content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',
                    }),expression:"{\n                        html: true,\n                        content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',\n                    }"}],staticClass:"inline-block ml-2 text-eidSecureBlue-50",attrs:{"data-test-id":"SidebarMenu__emailSettingsNotificationsOffIcon","icon-src":_vm.faBellSlash}}):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }