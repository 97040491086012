<template>
    <div id="root">
        <router-view />
        <v-component
            v-for="(Component, index) in topLevelComponents"
            :key="`${Component.name}-${index}`"
            :is="Component"
        />
        <ModalTarget parent-class-name-opened="body--modalOpened" />
        <ProcedureTarget hide-container disable-routing-during-procedure />
    </div>
</template>

<script lang="ts">
    import { Vue, Component } from 'vue-property-decorator';
    import { ModalTarget } from '../../components/Modal';
    import { ProcedureTarget } from '../../components/ProcedureManager';

    @Component({
        components: { ProcedureTarget, ModalTarget },
    })
    export default class Main extends Vue {
        private get topLevelComponents(): Vue[] {
            return (this as any).$slotComponents.topLevel || [];
        }
    }
</script>

<style lang="scss">
    // stylelint-disable selector-max-combinators, selector-max-compound-selectors, selector-no-qualifying-type
    body.body--modalOpened {
        overflow: hidden;

        #root > :not(.ModalTarget):not(.Snackbar),
        #root > .ModalTarget > :not(.Modal) {
            filter: blur(5px);
        }
    }
</style>
