import _Vue, { PluginObject, VueConstructor } from 'vue';
import PortalVue from 'portal-vue';
import ModalManager from './ModalManager';

function createModalPlugin(): PluginObject<_Vue> {
    return {
        install(Vue: VueConstructor): void {
            Vue.use(PortalVue);

            Vue.mixin({
                beforeCreate() {
                    if (this === this.$root) {
                        Object.defineProperty(this, '$modalManager', {
                            value: new ModalManager(),
                        });
                    } else {
                        Object.defineProperty(this, '$modalManager', {
                            get() {
                                return this.$root.$modalManager;
                            },
                        });
                    }
                },
            });
        },
    };
}

export default createModalPlugin;
