<template>
    <div class="DatePicker" :class="{ 'DatePicker--disabled': disabled, 'DatePicker--enabledClear': enableClear }">
        <div class="DatePicker__control">
            <Datetime
                ref="datepicker"
                :class="{'DatePicker__picker--hidden': hidePicker}"
                :key="configHash"
                :value="currentValue"
                input-class="DatePicker__input"
                :format="displayFormat"
                :disabled="disabled"
                :min-datetime="currentMinDate"
                :max-datetime="currentMaxDate"
                :week-start="7"
                :placeholder="placeholder"
                auto
                @input="onChange"
            />
            <div v-if="showInvalidDate && invalidValue"
                class="DatePicker__invalidDate"
                @click="openCalendar"
            >
                <span>
                    {{ value }}
                </span>
                <div v-if="enableClear && value" class="DatePicker__invalidDateClear" @click="clear">
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </div>
        </div>
        <div v-if="enableClear && currentValue" ref="clearIcon" class="DatePicker__clearIcon" @click="clear">
            <FontAwesomeIcon :icon="faTimes" />
        </div>
        <div class="DatePicker__icon">
            <FontAwesomeIcon :icon="faCalendarAlt" />
        </div>
    </div>
</template>

<style lang="scss">
    // stylelint-disable selector-max-combinators, selector-max-compound-selectors
    @import "~vue-datetime/dist/vue-datetime.css";

    $datepicker-theme-color: #2ab496;
    $datepicker-input-padding-horizontal: 10px;
    $datepicker-clear-icon-margin: 5px;
    $datepicker-icon-color: #2b2b2b;
    $datepicker-clear-icon-color: #788ea1;
    $datepicker-input-border-color: #e7e6e6;
    $datepicker-input-background-color: #f9fafb;
    $datepicker-disabled-icon-color: #bbb;
    $datepicker-disabled-input-background-color: #f3f3f3;
    $datepicker-disabled-input-border-color: $datepicker-input-border-color;
    $datepicker-popover-width: 340px;

    .DatePicker {
        position: relative;

        & &__picker--hidden input {
            // stylelint-disable-next-line declaration-no-important
            display: none !important;
        }

        &__icon {
            position: absolute;
            pointer-events: none;
            right: $datepicker-input-padding-horizontal;
            top: 50%;
            transform: translateY(-50%);
            color: $datepicker-icon-color;
        }

        &__clearIcon {
            display: none;
            position: absolute;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%) translateX($datepicker-input-padding-horizontal + $datepicker-clear-icon-margin);
            color: $datepicker-clear-icon-color;
            width: 13px;
            text-align: center;
        }

        &__invalidDateClear {
            display: none;
            cursor: pointer;
            margin-left: $datepicker-clear-icon-margin;
            color: $datepicker-clear-icon-color;
            width: 13px;
            text-align: center;
        }

        .vdatetime {
            &-popup__header,
            &-calendar__month__day--selected > span > span,
            &-calendar__month__day--selected:hover > span > span {
                background: $datepicker-theme-color;
            }

            &-popup__actions__button,
            &-year-picker__item--selected,
            &-month-picker__item--selected,
            &-time-picker__item--selected {
                color: $datepicker-theme-color;
            }
        }

        .vdatetime-popup {
            width: $datepicker-popover-width;
            transform: none;
            left: calc((100% - #{$datepicker-popover-width}) / 2);
            top: calc((100% - #{$datepicker-popover-width}) / 2);
        }

        &__input, &__invalidDate {
            display: block;
            width: 100%;
            border: 1px solid $datepicker-input-border-color;
            height: 2.5em;
            border-radius: 5px;
            outline: none;
            padding: 0 $datepicker-input-padding-horizontal;
            background: $datepicker-input-background-color;
            font: inherit;
            cursor: pointer;
        }

        &__invalidDate {
            display: flex;
            align-items: center;
        }

        &--enabledClear:hover &__clearIcon, &--enabledClear:hover &__invalidDateClear {
            display: block;
        }

        &--disabled &__input {
            background: $datepicker-disabled-input-background-color;
            border-color: $datepicker-disabled-input-border-color;
            cursor: not-allowed;
        }

        &--disabled > &__icon {
            color: $datepicker-disabled-icon-color;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { DateTime } from 'luxon';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { Datetime } from 'vue-datetime';
    import delay from '@evidentid/universal-framework/delay';

    /* This component is referenced by ido-facing/components/forms/DatePicker.
     * please update both components if needed when making changes
     */
    @Component({
        components: { FontAwesomeIcon, Datetime },
    })
    export default class DatePicker extends Vue {
        private faCalendarAlt = faCalendarAlt;
        private faTimes = faTimes;

        // string must matches valueFormat Prop, default is ISO
        @Prop({ default: null })
        private value!: Date | string | null;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private showInvalidDate!: boolean;

        @Prop({ type: Boolean, default: false })
        private enableClear!: boolean;

        @Prop({ default: null })
        private minDate!: Date | string | null;

        @Prop({ default: null })
        private maxDate!: Date | string | null;

        @Prop({ type: String, default: '' })
        private placeholder!: string;

        // dates use luxon format
        @Prop({ type: String, default: 'yyyy-MM-dd' })
        private valueFormat!: string;

        @Prop({ type: String, default: 'M/d/yyyy' })
        private displayFormat!: string;

        private currentValue!: string | null;
        private currentMinDate!: string | null;
        private currentMaxDate!: string | null;

        private get configHash(): string {
            return `${this.disabled}-${this.currentMinDate}-${this.currentMaxDate}`;
        }

        @Watch('value', { immediate: true })
        private async onExternalValueChange(value: Date | string | null): Promise<void> {
            this.currentValue = this.formatDate(value);
            await this.$forceUpdate();
            this.positionClearIcon();
        }

        @Watch('minDate', { immediate: true })
        private onExternalMinDateChange(minDate: Date | string | null): void {
            this.currentMinDate = this.formatDate(minDate) || null;
            this.$forceUpdate();
        }

        @Watch('maxDate', { immediate: true })
        private onExternalMaxDateChange(maxDate: Date | string | null): void {
            this.currentMaxDate = this.formatDate(maxDate) || null;
            this.$forceUpdate();
        }

        private mounted(): void {
            this.positionClearIcon();
        }

        private openCalendar(event: MouseEvent): void {
            const dateTimeComp = (this.$refs.datepicker || {}) as any;
            if (dateTimeComp) {
                dateTimeComp.open(event);
            }
        }

        private positionClearIcon(): void {
            if (this.$refs.clearIcon) {
                const currentValue = this.currentValue;
                const displayDate = currentValue && DateTime.fromISO(currentValue).toFormat(this.displayFormat);
                (this.$refs.clearIcon as HTMLElement).style.left = `${displayDate?.length || 0}ch`;
            }
        }

        private get hidePicker(): boolean {
            return this.showInvalidDate ? this.invalidValue : false;
        }

        private get invalidValue(): boolean {
            return Boolean(this.value) && !this.currentValue;
        }

        // vue-datetime is returning invalid date (with invalid timezone) on "input" event,
        // so we need to use their internal Luxon object with this hacky implementation.
        private async onChange(): Promise<void> {
            await delay(1);
            if (!this.$refs.datepicker) {
                return;
            }
            const date = (this.$refs.datepicker as any).datetime;
            const isoDate = !date || date.invalid ? null : DateTime.fromMillis(date.ts).toISODate();
            const outputDate = isoDate ? DateTime.fromISO(isoDate).toFormat(this.valueFormat) : null;
            if (isoDate !== this.currentValue) {
                this.currentValue = isoDate;
                this.$emit('input', outputDate);
            }
            this.positionClearIcon();
        }

        private clear(event: MouseEvent): void {
            event.stopPropagation();
            this.currentValue = null;
            this.$emit('input', null);
        }

        private formatDate(date: Date | string | null): string {
            if (!date) {
                return '';
            }
            const finalDate = date instanceof Date
                ? DateTime.fromJSDate(date)
                : /^\d{4}-\d{2}-\d{2}(T\d{1,2}:\d{2}:\d{2}\.\d{3}Z)?$/.test(date)
                    ? DateTime.fromISO(date)
                    : DateTime.fromFormat(date, this.valueFormat);
            return finalDate.toISODate();
        }
    }
</script>
