<template>
    <v-component
        :is="tag"
        :href="tag === 'a' && href == null ? 'javascript:void(0)' : href"
        :type="tag === 'button' ? submit ? 'submit' : 'button' : null"
        :disabled="disabled"
        class="Button"
        :class="[ typeClassName, {
            'Button--disabled': disabled || loading,
            'Button--iconOnly': hasIcon && !hasContent,
        } ]"
        v-on="$listeners"
    >
        <FontAwesomeIcon v-if="loading" :icon="faSpinner" spin />
        <span v-if="loading && progress != null" class="Button__progress">{{ progress.toFixed() }}%</span>
        <EidIcon v-else-if="icon" :icon-src="icon" v-bind="iconProps" />
        <span>
            <slot />
        </span>
    </v-component>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '../EidIcon/EidIcon.vue';

    @Component({
        components: { FontAwesomeIcon, EidIcon },
    })
    export default class Button extends Vue {
        private faSpinner = faSpinner;

        @Prop({ type: String, default: 'button' })
        private tag!: string;

        @Prop({ type: String, default: null })
        private href!: string | null;

        @Prop({ type: String, default: null })
        private type!: string | null;

        @Prop({ type: [ Object, String ], default: null })
        private icon!: IconDefinition | string | null;

        @Prop()
        private iconProps!: any;

        @Prop({ type: Boolean, default: false })
        private submit!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Number, default: null })
        private progress!: number | null;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private hasContent: boolean = false;

        private get typeClassName(): string | null {
            const type = (this.type || '').trim();
            return type ? type.replace(/(^|\s+)/g, (x) => `${x}Button--`) : null;
        }

        private get hasIcon(): boolean {
            return Boolean(this.loading || this.icon);
        }

        private created(): void {
            this.updateContentStatus();
        }

        private beforeUpdate(): void {
            this.updateContentStatus();
        }

        // Vue doesn't have working reactivity on `$slots`, so we can't watch this property easily
        private updateContentStatus(): void {
            this.hasContent = Boolean(this.$slots.default);
        }
    }
</script>
