<template>
    <article class="Box">
        <header v-if="$scopedSlots.header || title" class="Box__header">
            <slot name="header">
                {{ title }}
            </slot>
        </header>
        <div class="Box__content">
            <slot />
        </div>
    </article>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Box extends Vue {
        @Prop({ type: String, default: null })
        private title!: string | null;
    }
</script>
