<template>
    <span class="InfoCircle">
        <FontAwesomeIcon
            :icon="faInfoCircle"
            v-tooltip="{
                content: computedContent,
                classes: tooltipClasses,
                html: html,
            }"
        />
    </span>
</template>

<style lang="scss">
    .InfoCircle {
        display: inline-block;
        color: #2ab496;
        cursor: help;
        padding: 0.3em;

        > svg {
            outline: none;
        }

        &__tooltip {
            max-width: 300px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: {
            FontAwesomeIcon,
        },
    })
    export default class InfoCircle extends Vue {
        private faInfoCircle = faInfoCircle;

        @Prop({ type: String })
        private message!: string;

        @Prop({ type: Boolean, default: false })
        private html!: boolean;

        private computedContent: string = '';
        private tooltipClasses: string[] = [ 'InfoCircle__tooltip' ];

        @Watch('message', { immediate: true })
        private onMessageChange(): void {
            this.computedContent = this.message.trim().replace(/\s+/g, ' ');
        }
    }
</script>
