import { v4 as createUuidV4 } from 'uuid';
import { SESSION_KEY_ID } from './constants';
import { getSessionData, setSessionData } from './getSetSessionData';

export function getChatSessionId(): string | null {
    return getSessionData(SESSION_KEY_ID);
}

export function setChatSessionId(sessionId?: string): void {
    const existed = Boolean(getChatSessionId());
    if (!existed) {
        setSessionData(SESSION_KEY_ID, sessionId || createUuidV4());
    }
}
