function createError(name: string, defaultMessage: string): ErrorConstructor {
    // Cache globally error instances, so different `createError` calls (or even from different files) will work fine
    const symbol = Symbol.for(`@auth-errors/${name}/${defaultMessage}`);
    // @ts-ignore: global cache
    if (window[symbol]) {
        // @ts-ignore: global cache
        return window[symbol];
    }

    // eslint-disable-next-line no-new-func
    const ErrorClass = (new Function('Error', 'defaultMessage', `return function ${name}(message) {
        var error = Error.call(this, message == null ? defaultMessage : message);
        error.name = ${JSON.stringify(name)};
        Object.defineProperties(this, {
            $auth: { get: function () { return true } },
            name: { get: function() { return ${JSON.stringify(name)} } },
            stack: { get: function() { return error.stack } },
        });
    }`))(Error, defaultMessage);

    ErrorClass.prototype = Object.create(Error.prototype, {
        constructor: { value: ErrorClass },
    });

    // @ts-ignore: global cache
    window[symbol] = ErrorClass;

    return ErrorClass;
}

export const InvalidCredentialsError = createError('InvalidCredentialsError', 'Invalid credentials');
export const InvalidEmailError = createError('InvalidEmailError', 'Invalid e-mail address');
export const UserNotFoundError = createError('UserNotFoundError', 'User not found');
export const UserProvisioningError = createError('UserProvisioningError', 'User provisioning error');
export const UserDisabledError = createError('UserDisabledError', 'User is disabled');
export const PasswordLeakedError = createError('PasswordLeakedError', 'User\'s password has been leaked');
export const DifferentProviderError = createError('DifferentProviderError', 'Account exists with different provider');

export const WeakPasswordError = createError('WeakPasswordError', 'Weak password');
export const EmailAlreadyUsedError = createError('EmailAlreadyUsedError', 'E-mail already used');

export const RateLimitError = createError('RateLimitError', 'Too many auth requests');
export const UnknownAuthError = createError('UnknownAuthError', 'Unknown auth error');
export const NotInitializedError = createError('NotInitializedError', 'The auth adapter is not initialized yet');
export const TimeoutError = createError('TimeoutError', 'The operation has timed out');

export const UnsupportedMethod = createError('UnsupportedMethod', 'The auth adapter does not support this method');
