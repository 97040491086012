<template>
    <ExceptionExpirationModal
        :exception-inputs="exception.data"
        :requirement="requirement"
        :loading="loading"
        @close="close"
        @grant-exception="grantException"
    />
</template>

<style lang="scss">
    .GrantExceptionProcedureModal {
        .Modal__body {
            max-width: 650px;
        }

        .Modal__content {
            display: inline-flex;
            gap: 40px;
            flex-direction: column;
            align-items: flex-start;
        }

        .Modal__footer {
            flex-direction: row-reverse;
            gap: 20px;
            margin: 20px 0 40px 0;
        }

        .Button {
            border-radius: 22px;
            padding: 0.8em 4em;
        }

        .Button--gray {
            background: transparent;
            border: 1px solid #29b496;
            color: #29b496;
        }

        &__line {
            text-align: start;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        ExceptionInput,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Exception } from '@/modules/dashboard/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import ExceptionExpirationModal
        from '@/modules/entity-details/components/ExceptionExpirationModal/ExceptionExpirationModal.vue';
    import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

    @Component({
        components: { ExceptionExpirationModal },
    })

    export default class GrantExceptionProcedure extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private exception!: Exception;

        @Prop({ type: Object, default: () => ({}) })
        private requirement!: EntityRequirement | null;

        private exceptionTypeSelected: boolean = false;
        private exceptionToGrant = this.exception;

        private get loading(): boolean {
            return (
                this.$store.state.entityDetails.grantExceptionStatus[this.exception.rpName] === OperationStatus.loading
            );
        }

        private async grantException(exceptionInputs: ExceptionInput[]): Promise<void> {
            this.exceptionToGrant = { ...this.exception, data: exceptionInputs };
            this.exceptionTypeSelected = true;
            await this.$store.actions.entityDetails.grantExceptions({
                rpName: this.exceptionToGrant.rpName,
                entityId: this.exceptionToGrant.insured.id,
                exceptions: this.exceptionToGrant.data,
            });
            const grantExceptionStatus =
                this.$store.state.entityDetails.grantExceptionStatus[this.exceptionToGrant.rpName];
            if (grantExceptionStatus === OperationStatus.success) {
                this.$emit('finish', this.exceptionToGrant.insured.displayName);
            } else {
                this.$emit('abort');
            }
        }

        private close(): void {
            this.$emit('abort');
        }
    }
</script>
