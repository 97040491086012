<template>
    <div class="Table">
        <div v-if="$scopedSlots.toolbar || $scopedSlots.toolbarEnd" class="Table__toolbar">
            <div class="Table__toolbarContent">
                <slot name="toolbar" />
            </div>
            <div class="Table__toolbarEndContent">
                <slot name="toolbarEnd" />
            </div>
        </div>
        <table class="Table__table">
            <thead>
                <tr>
                    <slot name="head" />
                </tr>
            </thead>
            <tbody>
                <slot />
            </tbody>
        </table>
        <div v-if="$scopedSlots.message" class="Table__message">
            <slot name="message" />
        </div>
        <div v-if="$scopedSlots.footer" class="Table__footer">
            <slot name="footer" />
        </div>
        <div v-if="$scopedSlots.status" class="Table__status">
            <slot name="status" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class Table extends Vue {
    }
</script>
