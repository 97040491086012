<template>
    <div class="BulkImportObjectField">
        <div v-if="deletable" class="BulkImportObjectField__deleteIconContainer">
            <div class="BulkImportObjectField__deleteIcon" @click="$emit('delete')">
                <FontAwesomeIcon :icon="faTimes" />
            </div>
        </div>
        <template v-if="form.schema.title">
            <h1 v-if="depth === 0" class="BulkImportObjectField_title">
                {{ form.schema.title }}
            </h1>
            <h2 v-else-if="depth === 1" class="BulkImportObjectField_title">
                {{ form.schema.title }}
            </h2>
            <h3 v-else-if="depth === 2" class="BulkImportObjectField_title">
                {{ form.schema.title }}
            </h3>
            <h4 v-else class="BulkImportObjectField_title">
                {{ form.schema.title }}
            </h4>
        </template>
        <p v-if="form.schema.description">
            {{ form.schema.description }}
        </p>

        <component
            :is="FormElementComponent"
            v-for="property in properties"
            :id="`${id}_${property.name}`"
            :key="property.form.key"
            :depth="depth + 1"
            :form="property.form"
            :required="property.required"
            :disabled="disabled || property.form.schema.readOnly"
            :form-element-component="FormElementComponent"
            :custom-component-input="customComponentInput"
            :value="value[property.name]"
            :touched="touched"
            :strings="strings"
            v-bind="$attrs"
            @input="onInput(property, $event)"
            @change="onChange(property, $event)"
        >
            <template v-if="$slots.icon" v-slot:icon>
                <slot name="icon" />
            </template>
        </component>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';

    @Component({
        inheritAttrs: false,
        components: {
            FontAwesomeIcon,
        },
    })
    export default class BulkImportObjectField extends AbstractJsonSchemaForm<JsonFormObject, object> {
        private faTimes = faTimes;

        private get properties() {
            return this.form.getProperties(this.value);
        }

        private onInput(property: JsonFormProperty, value: any) {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onChange(property: JsonFormProperty, value: any) {
            this.$emit('change', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }
    }
</script>
