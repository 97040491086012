<template>
    <EidSidePanel
        class="RiskProfileCriteriaPanel"
        :click-out-exception-selectors="clickOutExceptions"
        @click-out="close"
    >
        <template #headerText>
            <template v-if="currentCriterion">
                <span
                    class="RiskProfileCriteriaPanel__headerPath--active"
                    @click="deselectCriterion"
                >
                    {{ title }}
                </span>
                <div class="RiskProfileCriteriaPanel__headerPathSeparator">
                    <FontAwesomeIcon :icon="faChevronRight" />
                </div>
                <span>
                    {{ criterionTitle }}
                </span>
            </template>
            <span v-else>{{ title }}</span>
        </template>

        <template #headerOther>
            <CriteriaWarningMessage v-if="hasDeprecatedCriteria">
                One or more criteria has been deprecated and may result in incorrect entity compliance.
            </CriteriaWarningMessage>
            <CriteriaWarningMessage v-if="isEvaluationRuleRequirementTypeAllCriteriaNotVerify">
                At least 1 Criterion must be verified for the Evaluation Rule to stay valid.
                If not, the Evaluation Rule will be removed.
            </CriteriaWarningMessage>
            <CriteriaDangerMessage v-if="isInvalidCriteriaMessage">
                One or more fields could not be validated.
            </CriteriaDangerMessage>
        </template>

        <template #content>
            <div class="RiskProfileCriteriaPanel__content">
                <CollateralCriterionSetting
                    v-if="currentCriterion && isCollateralCriterion"
                    :criterion="currentCriterion"
                    :template="templatesByFieldName[currentCriterion.field]"
                    @input="updateCriterion"
                    @reference-accessed="$emit('reference-accessed', $event)"
                />
                <CriterionSettings
                    v-else-if="currentCriterion"
                    :requirement-type-title="title"
                    :criterion="currentCriterion"
                    :template="templatesByFieldName[currentCriterion.field]"
                    :custom-properties="customProperties"
                    :country-code="countryCode"
                    @input="updateCriterion"
                    @reference-accessed="$emit('reference-accessed', $event)"
                />
                <RiskProfileCriteriaTable
                    v-else-if="criteriaTemplates.length > 0"
                    :criteria-list="sortedCriteria"
                    :templates-by-field-name="templatesByFieldName"
                    :custom-properties="customProperties"
                    :accessed-reference-map="accessedReferenceMap"
                    :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                    @openSettings="openCriterionSettings"
                    @input="$emit('input',$event, requirementType)"
                    @reference-accessed="$emit('reference-accessed', $event)"
                    @validityChange="$emit('validityChange', $event)"
                />
            </div>
            <div class="RiskProfileCriteriaPanel__footerAction">
                <CriterionAddButtonWithPopover
                    v-if="!currentCriterion"
                    :criterion-templates="availableCriterionTemplate"
                    :disabled="availableCriterionTemplate.length===0"
                    @add="addCriterion"
                />
                <Alert class="RiskProfileCriteriaPanel__footer-alert" rounded>
                    <img
                        class="CriteriaWarningMessage__icon"
                        :src="exclamationCircleGray"
                        alt="icon"
                    >
                    All changes will save automatically.
                </Alert>
            </div>
        </template>
    </EidSidePanel>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import differenceBy from 'lodash/differenceBy';
    import sortBy from 'lodash/sortBy';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
    import infoCircle from '@/assets/icons/info-circle-transparent-background-icon.svg';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import Popover from '@evidentid/dashboard-commons/components/Popover/Popover.vue';
    import RiskProfileCriteriaTable
        from '@/modules/decisioning-criteria/components/RiskProfileCriteriaTable/RiskProfileCriteriaTable.vue';
    import CriterionSettings
        from '@/modules/decisioning-criteria/components/CriterionSettings/CriterionSettings.vue';
    import CriterionAddButtonWithPopover
        from '@/modules/decisioning-criteria/components/CriterionAddButtonWithPopover/CriterionAddButtonWithPopover.vue';
    import { isCriterionTypeAndFieldEqual } from '@/modules/decisioning-criteria/utils/riskProfileCriteria';
    import CriteriaWarningMessage
        from '@/modules/decisioning-criteria/components/CriteriaWarningMessage/CriteriaWarningMessage.vue';
    import {
        GetRequirementTypeLabel,
        IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditRiskProfile/types';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import CriteriaDangerMessage
        from '@/modules/decisioning-criteria/components/CriteriaDangerMessage/CriteriaDangerMessage.vue';
    import EidSidePanel from '@/modules/decisioning-criteria/components/EidSidePanel/EidSidePanel.vue';
    import { requirementTypeFieldReferenceDelimiter } from '@/modules/decisioning-criteria/types';
    import CollateralCriterionSetting
        from '@/modules/decisioning-criteria/components/CollateralCriterionSetting/CollateralCriterionSetting.vue';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            Alert,
            CollateralCriterionSetting,
            CriteriaDangerMessage,
            CriteriaWarningMessage,
            CriterionAddButtonWithPopover,
            CriterionSettings,
            RiskProfileCriteriaTable,
            EidSidePanel,
            FontAwesomeIcon,
            Popover,
        },
    })
    export default class RiskProfileCriteriaPanel extends Vue {
        @Prop()
        private requirementType!: TprmRequirementType;

        @Prop({ type: Array, default: () => [] })
        private criteria!: (CriterionInput | Criterion)[];

        @Prop({ type: Array, default: () => [] })
        private criteriaTemplates!: CriterionTemplate[];

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private requirementModels!: TprmRequirementModel[];

        @Prop({ type: Object, default: () => ({}) })
        private accessedReferenceMap!: Record<string, boolean>;

        @Prop({ type: Boolean, default: false })
        private hasDeprecatedCriteria!: boolean;

        @Prop({ type: Function as PropType<GetRequirementTypeLabel>, required: true })
        private getRequirementTypeLabel!: GetRequirementTypeLabel;

        @Prop({ type: Array as PropType<TprmRequirementType[]>, default: () => ([]) })
        private evaluationRuleRequirementTypes!: TprmRequirementType[];

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        @Prop({ type: String as PropType<string> })
        private countryCode?: string;

        @Prop({ type: Array as PropType<string[]>, default: () => [] })
        private invalidReferences!: string[];

        private currentCriterion: Criterion | null = null;
        private faChevronRight = faChevronRight;
        private exclamationCircleGray = infoCircle;
        private clickOutExceptions =
            [ '.RiskProfileSaveButton__saveButton', '.RiskProfileSaveButton__saveButton span' ];

        @Watch('criteria')
        private onCriteriaChange() {
            // update the current criterion
            if (this.currentCriterion != null) {
                const found = this.criteria.find(
                    (criterion) =>
                        isCriterionTypeAndFieldEqual(criterion, this.currentCriterion as Criterion),
                );
                this.currentCriterion = found as Criterion;
            }
        }

        private get isInvalidCriteriaMessage(): boolean {
            if (this.currentCriterion) {
                const criterionFieldReferenceStart = [ this.currentCriterion.coverageType, this.currentCriterion.field ]
                    .join(requirementTypeFieldReferenceDelimiter);
                return this.isReferenceStartsWithIsInvalid(criterionFieldReferenceStart);
            }

            return this.isReferenceStartsWithIsInvalid(this.requirementType);
        }

        private get title(): string {
            return this.getRequirementTypeLabel(this.requirementType);
        }

        private get criterionTitle(): string {
            if (!this.currentCriterion) {
                return '';
            }

            return this.templatesByFieldName[this.currentCriterion.field].displayMetadata.title;
        }

        private get sortedCriteria(): (CriterionInput | Criterion)[] {
            return sortBy(this.criteria, (criterion) =>
                this.criteriaTemplates
                    .findIndex((template) => isCriterionTypeAndFieldEqual(template, criterion)),
            );
        }

        private get availableCriterionTemplate(): CriterionTemplate[] {
            return differenceBy(this.criteriaTemplates, this.criteria, 'field').filter((template) => !template.deprecated);
        }

        private get isEvaluationRuleRequirementTypeAllCriteriaNotVerify(): boolean {
            const isRequirementTypeInEvaluationRule = this.evaluationRuleRequirementTypes.includes(
                this.requirementType,
            );
            const allCriteriaAreNotVerify = this.criteria.every((criterion) => !criterion.verify);
            return isRequirementTypeInEvaluationRule && allCriteriaAreNotVerify;
        }

        private get templatesByFieldName(): Record<string, CriterionTemplate> {
            return this.criteriaTemplates
                .reduce((acc, temp) => ({ ...acc, [temp.field]: temp }), {});
        }

        private get isCollateralCriterion(): boolean {
            return Boolean(this.currentCriterion && this.currentCriterion.field === 'coverage.collateral');
        }

        @Watch('requirementType')
        private onRequirementTypeChange(): void {
            this.currentCriterion = null;
        }

        private openCriterionSettings(criterion: Criterion): void {
            this.currentCriterion = criterion;
        }

        private deselectCriterion(): void {
            this.currentCriterion = null;
        }

        private addCriterion(criterion: Criterion): void {
            this.$emit('input', [ ...this.criteria, criterion ], this.requirementType);
        }

        private updateCriterion(criterion: Criterion): void {
            const index = this.criteria.findIndex((fromList) => isCriterionTypeAndFieldEqual(fromList, criterion));
            const updatedList = [ ...this.criteria.slice(0, index), criterion, ...this.criteria.slice(index + 1) ];
            this.$emit('input', updatedList, this.requirementType);
        }

        private close() {
            this.$emit('close', this.isEvaluationRuleRequirementTypeAllCriteriaNotVerify);
        }
    }
</script>
