<template>
    <div class="NoPolicy">
        <img :src="iconSrc">
        <div class="NoPolicy__headerText">
            {{ texts.header }}
        </div>
        <div class="NoPolicy__bodyText">
            <div data-test-id="NoPolicy__mainMessage">
                <span>{{ texts.body }}</span>
                <strong v-if="migratedCompliant || isNoRequirementWithRequirementLevelException">manually marked as compliant.</strong>
            </div>
            <div v-if="bodySubText" class="NoPolicy__bodySubText">
                {{ bodySubText }}
            </div>
        </div>
        <Button
            v-if="shouldShowGrantException"
            class="NoPolicy__btnGrantException"
            type="primary"
            @click="$emit('grant-exception-for-requirement')"
        >
            Grant Exception for Requirement
        </Button>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { ComplianceStatus, ExceptionLevel } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import docClock from '@/modules/dashboard/assets/doc-with-clock.svg';
    import dockInfo from '@/modules/dashboard/assets/doc-info.svg';
    import { DECLINE_REASON_TEXTS } from '@/modules/entity-details/components/NoPolicy/config';
    import { PropType } from 'vue';
    import { DeclineReason } from '@evidentid/tprm-portal-lib/models/entity-details/DeclineReason.model';
    import {
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

    @Component({
        components: { Button },
    })
    export default class NoPolicy extends Vue {
        @Prop({ type: Object as PropType<EntityRequirementDetails>, required: true })
        private requirementDetails!: EntityRequirementDetails;

        @Prop({ type: Boolean, default: false })
        private requirementProcessing!: boolean;

        private pendingIcon = docClock;
        private infoIcon = dockInfo;

        private get migratedCompliant(): boolean {
            return this.requirementDetails.complianceStatus === ComplianceStatus.compliant
                && Boolean(this.requirementDetails?.complianceStatusMetadata?.statusOverride?.basedOnMigratedCoverage);
        }

        private get isNoRequirementWithRequirementLevelException(): boolean {
            const isRequirementLevelException = this.requirementDetails?.complianceStatusMetadata?.statusOverride?.level
                === ExceptionLevel.coverageType;
            return isRequirementLevelException && !this.requirementDetails.coverage;
        }

        private get texts(): { header: string, body: string } {
            if (this.requirementProcessing) {
                return {
                    header: 'Document Processing',
                    body: 'This document is currently being processed. Its details will be displayed once it\'s been fully evaluated.',
                };
            }

            if (this.migratedCompliant) {
                return {
                    header: 'Migrated Compliance',
                    body: 'This entity has been',
                };
            }

            const declineReasonText = this.getDeclineReasonTexts();

            if (declineReasonText) {
                return declineReasonText;
            }

            if (this.requirementDetails.complianceStatus === ComplianceStatus.pending) {
                return {
                    header: 'Pending Submission',
                    body: 'The entity has been sent a submission link.',
                };
            }

            if (this.isNoRequirementWithRequirementLevelException) {
                return {
                    header: 'No Document Provided - Exception Granted',
                    body: 'This requirement has been',
                };
            }

            return {
                header: 'No Document Provided',
                body: 'This entity did not provide the requested document. Please contact them for more details.',
            };
        }

        private get bodySubText(): string {
            return this.migratedCompliant || this.isNoRequirementWithRequirementLevelException ? 'No submission details have been provided' : '';
        }

        private get iconSrc(): string {
            return this.migratedCompliant ? this.infoIcon : this.pendingIcon;
        }

        private get shouldShowGrantException(): boolean {
            return this.requirementDetails.declineReason === DeclineReason.ExceptionRequested &&
                !this.requirementDetails.complianceStatusMetadata?.statusOverride;
        }

        private getDeclineReasonTexts(): null | { header: string, body: string } {
            if (!this.requirementDetails.declineReason) {
                return null;
            }
            return DECLINE_REASON_TEXTS[this.requirementDetails.declineReason];
        }
    }
</script>
