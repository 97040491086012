import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import AddIndividualEntityProcedure from '@/modules/entity-management/procedures/AddIndividualEntityProcedure.vue';
import BulkImportEntitiesProcedure from '@/modules/entity-management/procedures/BulkImportEntitiesProcedure.vue';
import DeactivateEntityProcedure from '@/modules/entity-management/procedures/DeactivateEntityProcedure.vue';
import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

export const entityManagementModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ entityManagement: vuex.instantiateModule })
    .registerProcedures<{
        openAddIndividualEntityModal: { rpName: string };
        openBulkImportEntitiesModal: { rpName: string };
        openDeactivateEntityProcedure: { rpName: string, entities: Entity[], isBulk: boolean };
    }>({
        openAddIndividualEntityModal: AddIndividualEntityProcedure,
        openBulkImportEntitiesModal: BulkImportEntitiesProcedure,
        openDeactivateEntityProcedure: DeactivateEntityProcedure,
    })
    .execute((app) => {
        function onAddEntitiesRequest(rpName: string, count: number) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Entities',
                    description: `[Requested] Add entities for ${rpName}`,
                    rpName,
                    addEntitiesCount: count,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Requested] Add entities for ${rpName}`,
                    data: { rpName, count },
                });
            }
        }

        function onAddEntitiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Entities',
                    description: `[Finished] Add entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Finished] Add entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onAddEntitiesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Entities Failed',
                    description: `[Failed] Add entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Custom Properties',
                    message: `[Failed] Add entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdateEntitiesRequest(rpName: string, count: number) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Entities',
                    description: `[Requested] Update entities for ${rpName}`,
                    updateEntitiesCount: count,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Requested] Update entities for ${rpName}`,
                    data: { rpName, count },
                });
            }
        }

        function onUpdateEntitiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Entities',
                    description: `[Finished] Update entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Finished] Update entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdateEntitiesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Entities Failed',
                    description: `[Failed] Update entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entity Fields',
                    message: `[Failed] Update entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onStartPatchEntities(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Entities',
                    description: `[Requested] Patch entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Requested] Patch entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFinishPatchEntities(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Entities',
                    description: `[Finished] Patch entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Entities',
                    message: `[Finished] Patch entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFailPatchEntities(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Entities Failed',
                    description: `[Failed] Patch entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Entities',
                    message: `[Failed] Patch entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startAddingEntities', (x) => onAddEntitiesRequest(x.rpName, x.count));
        app.observer.onMutation('finishAddingEntities', (x) => onAddEntitiesFinish(x.rpName));
        app.observer.onMutation('failAddingEntities', (x) => onAddEntitiesFailure(x.rpName));
        app.observer.onMutation('startUpdateEntities', (x) => onUpdateEntitiesRequest(x.rpName, x.count));
        app.observer.onMutation('finishUpdateEntities', (x) => onUpdateEntitiesFinish(x.rpName));
        app.observer.onMutation('failUpdateEntities', (x) => onUpdateEntitiesFailure(x.rpName));
        app.observer.onMutation('startPatchEntities', (x) => onStartPatchEntities(x.rpName));
        app.observer.onMutation('finishPatchEntities', (x) => onFinishPatchEntities(x.rpName));
        app.observer.onMutation('failPatchEntities', (x) => onFailPatchEntities(x.rpName));
    })
    .end();
