import decodeJwt from 'jwt-decode';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { IamPermission } from '../../types';

export class Auth0AccessToken {
    private readonly namespace: string;
    private readonly decodedToken: any;
    private readonly permissions: string[];
    private readonly obtainedPermissions: string[];
    private readonly availablePermissions: string[];
    private readonly availableResources: RegExp[];

    public constructor(token: string | null, namespace = '') {
        this.decodedToken = token ? decodeJwt(token) : null;
        this.namespace = namespace;
        this.obtainedPermissions = this.decodedToken?.scope.split(/\s+/g) || [];
        this.availablePermissions = this.decodedToken?.permissions || [];
        this.permissions = uniq([ ...this.obtainedPermissions, ...this.availablePermissions ]);
        this.availableResources = this.getAvailableResourceExpressions();
    }

    public getAvailablePermissions(): string[] {
        return this.availablePermissions;
    }

    public getObtainedPermissions(): string[] {
        return this.obtainedPermissions;
    }

    public getPermissions(): string[] {
        return this.permissions;
    }

    // eslint-disable-next-line camelcase
    public getResourcePolicies(): { api_identifier: string, resources_permitted: string[] }[] {
        return (this.decodedToken?.[`${this.namespace}/resource_policies`] || []);
    }

    public getAvailableResources(apiIdentifier?: string): string[] {
        return flatten(
            this.getResourcePolicies()
                .filter((x) => (apiIdentifier == null || x.api_identifier === apiIdentifier))
                .map((x) => x.resources_permitted)
        );
    }

    public getAvailableResourceExpressions(apiIdentifier?: string): RegExp[] {
        return this.getAvailableResources(apiIdentifier)
            .map((x) => new RegExp(`^${x.replace(/./g, (char) => (
                char === '*' ? '.+' : /[.*+?^${}()|[\]\\]/.test(char) ? `\\${char}` : char
            ))}$`));
    }

    public getMissingPermissions(permissions: IamPermission[]): string[] {
        const requestedPermissions = permissions.map((x) => x.permission!).filter(Boolean);
        return uniq(requestedPermissions.filter((x) => !this.obtainedPermissions.includes(x)));
    }

    public canObtainPermissions(permissions: IamPermission[]): boolean {
        const missingPermissions = this.getMissingPermissions(permissions);
        return missingPermissions.every((x) => this.availablePermissions.includes(x));
    }

    public getPartialPermissions(permissions: IamPermission[]): IamPermission[] {
        return permissions.filter((x) => (this.hasPermission(x) === null));
    }

    public hasPermission({ permission, resource }: IamPermission): boolean | null {
        // TODO(PRODUCT-13934): Support "service", with "https://evidentid.com/has_access_to_services" exposed
        if (resource && !this.availableResources.some((regexp) => regexp.test(resource!))) {
            return false;
        } else if (permission && !this.availablePermissions.includes(permission)) {
            return false;
        } else if (permission && !this.obtainedPermissions.includes(permission)) {
            return null;
        }
        return true;
    }

    public hasPermissions(permissions: IamPermission[]): boolean | null {
        return permissions.some((x) => this.hasPermission(x) === false)
            ? false
            : permissions.some((x) => this.hasPermission(x) === null) ? null : true;
    }
}
