<template>
    <v-component
        :is="formElementComponent"
        :id="id"
        :form-element-component="selfComponent"
        :custom-component-input="customComponentInput"
        :form="form"
        :touched="touched"
        :value="value"
        :depth="depth"
        :required="required"
        :disabled="disabled"
        :strings="strings"
        :deletable="deletable"
        :description="description"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #icon>
            <slot name="icon" />
        </template>
    </v-component>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaFormElement from './AbstractJsonSchemaFormElement';

    @Component({
        inheritAttrs: false,
        name: 'JsonSchemaFormElement',
    })
    export default class JsonSchemaFormElement extends AbstractJsonSchemaFormElement {
        private selfComponent = JsonSchemaFormElement;

        @Prop(Object)
        private form!: JsonForm;

        @Prop()
        private value!: any;

        @Prop({ type: Boolean, default: false })
        private required!: boolean;

        @Prop({ type: Boolean, default: false })
        private deletable!: boolean;

        @Prop(String)
        private id!: string;

        @Prop({ type: String, default: '' })
        private description!: string;

        @Prop({ type: Number, default: null })
        private depth!: number | null;

        private get formElementComponent(): Vue.Component | Vue.AsyncComponent {
            return this.getCustomComponent?.(this.form, this.components) || this.components[this.form.type];
        }
    }
</script>
