<template>
    <ConfigureUserModal
        v-model="input"
        :rp-name="rpName"
        :submitting="submitting"
        :disable-name="!user.editable"
        :name-warning="user.editable ? undefined : 'Name cannot be edited for users with external provider (i.e. Google)'"
        :show-role-change-warning="hasRoleBeenChanged"
        disable-email-address
        open
        :title="`Edit ${user.email} user`"
        submit-label="Save"
        @submit="onEditRequest"
        @close="$emit('abort')"
    />
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { RoleName, ServiceName, User } from '@evidentid/user-management-api-client/types';
    import ConfigureUserModal from '../components/ConfigureUserModal.vue';
    import { UserInput, UserManagementConfig } from '../types';

    @Component({
        components: {
            ConfigureUserModal,
        },
    })
    export default class EditUserProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: Object })
        private user!: User;

        private input: UserInput = {
            name: '',
            email: '',
            role: this.defaultRole,
        };

        private get defaultRole(): RoleName {
            return (this as any).$userManagement.config.availableRoles[0];
        }

        private get config(): UserManagementConfig {
            return (this as any).$userManagement.config;
        }

        private get service(): ServiceName {
            return this.config.serviceName;
        }

        private get hasRoleBeenChanged(): boolean {
            return !isEqual([ this.input.role ], this.user.services[this.service]?.roles || []);
        }

        @Watch('user', { immediate: true })
        private updateValueExternally() {
            this.input = {
                name: this.user.name || '',
                email: this.user.email || '',
                role: this.config.availableRoles[0] || this.defaultRole,
            };
        }

        private get status(): { status: OperationStatus, error: any } {
            return (this as any).$store.state.userManagement.updateUser[this.rpName]?.[this.user.id] || {
                status: OperationStatus.uninitialized,
                error: null,
            };
        }

        private get submitting() {
            return this.status.status === OperationStatus.loading;
        }

        private success(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: true });
        }

        private error(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: false });
        }

        private async onEditRequest(input: UserInput): Promise<void> {
            if (this.status.status === OperationStatus.loading) {
                return;
            }

            await (this as any).$store.actions.userManagement.updateUser({
                rpName: this.rpName,
                id: this.user.id,
                input,
            });

            const { status, error } = this.status;
            const response = error?.response;
            if (status === OperationStatus.success) {
                this.success(`User information for ${input.email} was updated`);
                this.$emit('finish');
            } else if (error?.reason === 'forbidden' && response?.message?.includes?.('Not permitted to modify')) {
                this.error(`
                    This action is not allowed since the user has multiple accounts with Evident.
                    Please reach out to Evident support to invite this user.
                `);
            } else if (error?.reason === 'forbidden') {
                this.error('You are not allowed to change selected user.');
            } else {
                this.error('Oops, something went wrong. Please try again!');
            }
        }
    }
</script>
