import { TableColumn } from '@/modules/entity-details/models/TableColumn.model';

export const customPropertyCollateralColumns: TableColumn[] = [
    {
        name: 'description-uniqIdentifier',
        label: 'Description/Unique Identifier',
        tooltipData: {
            content: 'The description is the collateral in which needs insurance.',
        },
    },
    {
        name: 'limitRequired',
        field: 'limitRequired',
        label: 'Limit Required',
        tooltipData: {
            content: 'The minimum coverage limit that you require for this collateral.',
        },
    },
    {
        name: 'coverageLimit',
        field: 'coverageLimit',
        label: 'Coverage Limit',
        tooltipData: {
            content: 'The coverage limit evidenced in this collateral\'s insurance policy.',
        },
    },
    {
        name: 'maximumDeductible',
        field: 'maximumDeductible',
        label: 'Max Deductible',
        tooltipData: {
            content: 'The maximum deductible that you require for this collateral.',
        },
    },
    {
        name: 'coverageDeductible',
        field: 'coverageDeductible',
        label: 'Coverage Deductible',
        tooltipData: {
            content: ' The deductible evidenced in this collateral\'s insurance policy.',
        },
    },
    {
        name: 'coverageType',
        field: 'coverageType',
        label: 'Requirement',
        tooltipData: {
            content: 'The type of insurance policy that covers this collateral.',
        },
    },
    {
        name: 'action',
        label: '',
    },
];
