import { Vue } from 'vue/types/vue';
import { RawLocation } from 'vue-router/types/router';
import { createDecorator } from 'vue-class-component';

type DecoratorFactory = Parameters<typeof createDecorator>[0];
type Vm = Parameters<DecoratorFactory>[0];

export type RawNavigationResult = RawLocation | false | void;
export type NavigationResult = RawNavigationResult | Promise<RawNavigationResult>;

function addRouteChangeListener(options: Vm, key: string) {
    // Store listener destroyers outside of the original component instance
    const listeners = new WeakMap<Vue, Function>();

    // Subscribe to router changes on instance creation
    const originalCreated = options.created;
    options.created = function created(this: Vue): void {
        const destroyListener = this.$router.beforeEach((to, from, next) => {
            // @ts-ignore: calling unknown method
            Promise.resolve(this[key](to, from)).then(next);
        });
        listeners.set(this, destroyListener);
        if (originalCreated) {
            originalCreated.call(this);
        }
    };

    // Unsubscribe from router changes after instance is destroyed
    const originalDestroyed = options.destroyed;
    options.destroyed = function destroyed(this: Vue): void {
        const destroyListener = listeners.get(this);
        if (destroyListener) {
            destroyListener();
        }
        if (originalDestroyed) {
            originalDestroyed.call(this);
        }
    };
}

export const beforeRouteChange = createDecorator(addRouteChangeListener);

export const beforeRouteNameChange = createDecorator((options, key) => {
    const methods = options.methods!;

    // Override original method to check for proper route name
    const originalMethod = methods[key] as Function;
    methods[key] = function onRouteNameChange(to, from) {
        if (to?.name !== from?.name) {
            return originalMethod.call(this, to, from);
        }
    };

    // For better logging, retrieve back original name
    Object.defineProperty(methods[key], 'name', { value: `decorated_${key}`, enumerable: false });

    // Attach regular route listener
    addRouteChangeListener(options, key);
});
