<template>
    <div class="AssignmentRuleView">
        <div v-for="(rulesGroup, rulesGroupIndex) in basicRulesGroups" :key="rulesGroupIndex">
            <BasicAssignmentRuleView
                :basic-rules="rulesGroup"
                :index="rulesGroupIndex"
                :custom-properties="customProperties"
                @close="close"
                @remove="$emit('remove')"
                @openRuleEditor="$emit('openRuleEditor')"
            >
                <template #footer>
                    <Button v-if="!opened && basicRules.length > 1" @click="open">
                        + {{ numberOfHiddenGroups }} more
                    </Button>
                </template>
            </BasicAssignmentRuleView>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { BasicAssignmentRule } from '@/modules/decisioning-criteria/types';
    import BasicAssignmentRuleView
        from '@/modules/decisioning-criteria/components/BasicAssignmentRuleView/BasicAssignmentRuleView.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { BasicAssignmentRuleView, Button },
    })
    export default class AssignmentRuleView extends Vue {
        @Prop({ type: Array, default: () => [] })
        private basicRules!: BasicAssignmentRule[][];

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        private opened: boolean = false;

        private get basicRulesGroups(): BasicAssignmentRule[][] {
            if (this.basicRules.length === 0) {
                return [];
            }
            return this.opened ? this.basicRules : this.basicRules.slice(0, 1);
        }

        private get numberOfHiddenGroups(): number {
            return this.basicRules.length - 1;
        }

        private open(): void {
            this.opened = true;
        }

        private close(): void {
            this.opened = false;
        }
    }
</script>
