<template>
    <Snackbar :timeout="timeout" v-bind="context" @close="close" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Snackbar } from '../../../components/Snackbar';

    @Component({
        components: { Snackbar },
    })
    export default class AppSnackbar extends Vue {
        @Prop({ type: Number, default: 4000 })
        private timeout!: number;

        private get context() {
            return (this as any).$store.state.snackbar;
        }

        private close() {
            (this as any).$store.actions.snackbar.displaySnackbar(null);
        }
    }
</script>
