<template>
    <FormElement class="JsonSchemaFormBoolean" :label="form.schema.title" :required="required" force-error-slot>
        <template v-slot:requiredIndicator>
            {{ translate('requiredLabel') }}
        </template>

        <FormInput :invalid="accessed && hasError" force-action-slot>
            <div class="JsonSchemaFormBoolean__radios" :id="id">
                <RadioOption
                    :name="id"
                    value="yes"
                    :checked="value === true"
                    :disabled="disabled"
                    @click="onChange(true)"
                >
                    <span>{{ translate('boolean.yes') }}</span>
                </RadioOption>

                <RadioOption
                    :name="id"
                    value="no"
                    :checked="value === false"
                    :disabled="disabled"
                    @click="onChange(false)"
                >
                    <span>{{ translate('boolean.no') }}</span>
                </RadioOption>
            </div>
            <template v-if="deletable" v-slot:actionButtons>
                <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </template>
        </FormInput>

        <template v-if="accessed && error" v-slot:errorMessage>{{ error }}</template>
    </FormElement>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import { RadioOption } from '../../RadioOption';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { FormInput, FormElement, RadioOption, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaBooleanForm extends AbstractJsonSchemaForm<JsonFormBoolean, boolean> {
        private faTimes = faTimes;

        private onChange(value: boolean) {
            this.touch();
            this.$emit('input', this.form.getValue(value));
        }
    }
</script>
