import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuexExportEntitiesModule from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

type PartialOperationStatus = Exclude<OperationStatus, 'uninitialized'>;
export const exportEntitiesModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ exportEntities: vuexExportEntitiesModule.instantiateModule })
    .execute((app) => {
        function onExportRequest(rpName: string, status: PartialOperationStatus) {
            if (app.tracker) {
                const descriptionMap: Record<PartialOperationStatus, string> = {
                    [OperationStatus.loading]: `[Requested] Export for ${rpName}`,
                    [OperationStatus.success]: `[Requested] Export for ${rpName}`,
                    [OperationStatus.error]: `[Requested] Export for ${rpName}`,
                };
                app.tracker.track({
                    name: 'Export',
                    description: descriptionMap[status],
                    rpName,
                });
            }
            if (app.logger) {
                const messageMap: Record<PartialOperationStatus, string> = {
                    [OperationStatus.loading]: `[Requested] Export for ${rpName}`,
                    [OperationStatus.success]: `[Finished] Export for ${rpName}`,
                    [OperationStatus.error]: `[Failed] Export for ${rpName}`,
                };
                app.logger.addBreadcrumb({
                    category: 'Export',
                    ...(status === OperationStatus.error && { level: Severity.error }),
                    message: messageMap[status],
                    data: { rpName },
                });
            }
        }
        app.observer.onMutation('setExportStatus', (x) => onExportRequest(x.rpName, x.status as PartialOperationStatus));
    })
    .end();
