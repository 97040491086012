import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import {
    TprmRequirementCountrySpecificEnumLabel,
} from '@evidentid/tprm-portal-lib/models/dashboard/CategorizedEnumLabelObject.model';

export function getRequirementTypeLabelByCountry(
    requirementType: TprmRequirementType,
    countryCode: string | null,
    enumLabels: CategorizedEnumLabels
): string {
    const requirementTypeLabels = enumLabels.coverageTypes;
    const requirementTypeEnumLabel = requirementTypeLabels[requirementType] as TprmRequirementCountrySpecificEnumLabel;
    const labelPerCountry =
        countryCode &&
        requirementTypeEnumLabel?.perCountryLabels &&
        requirementTypeEnumLabel?.perCountryLabels[countryCode];
    return labelPerCountry || requirementTypeEnumLabel?.label || requirementType;
}
