<template>
    <Modal
        additional-class-name="SendTestEmailModal"
        :allow-close="!submitting"
        :open="open"
        @close="$emit('close')"
        @submit="onSubmit"
    >
        <template #header>
            Send a {{ verificationType }} Test Email
        </template>
        <div class="SendTestEmailModal__content">
            <div class="SendTestEmailModal__title">
                Test your email before sending to your entities.
            </div>
            <div class="SendTestEmailModal__emailLabel">
                Email Address
            </div>
            <div class="SendTestEmailModal__email">
                {{ userEmail }}
            </div>
        </div>
        <template #footer>
            <Button
                class="SendTestEmailModal__submitButton"
                :loading="submitting"
                :disabled="submitting"
                type="primary"
                @click="onSubmit"
            >
                Send Test
            </Button>
        </template>
    </Modal>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import startCase from 'lodash/startCase';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    import {
        VerificationRequestType,
    } from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';

    @Component({
        components: {
            Button,
            Modal,
        },
    })
    export default class SendTestEmailModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: String })
        private type!: VerificationRequestType;

        private get verificationType(): string {
            return this.type === VerificationRequestType.nonCompliant
                ? 'Non-compliant'
                : startCase(this.type.toLowerCase());
        }

        private get userEmail(): string {
            return this.$store.state.user.data?.email || '';
        }

        private onSubmit(): void {
            if (this.submitting) {
                return;
            }
            this.$emit('submit');
        }
    }
</script>
