<template>
    <div class="FileListInputFilePreview" :class="{ 'FileListInputFilePreview--error': error }">
        <div class="FileListInputFilePreview__icon">
            <img v-if="[ '.csv', '.xls', '.xlsx' ].includes(extension)" :src="excelIconUrl" alt="">
            <FontAwesomeIcon v-else :icon="faFileAlt" />
        </div>
        <div class="FileListInputFilePreview__name">
            {{ name }}
            <span v-if="description" class="FileListInputFilePreview__description">{{ description }}</span>
        </div>
        <div v-if="allowRemove" class="FileListInputFilePreview__remove">
            <button type="button" class="FileListInputFilePreview__removeButton" @click="$emit('remove', file)">
                <FontAwesomeIcon :icon="faTrashAlt" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faFileAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import { midellipsis } from '@evidentid/universal-framework/strings';
    import excelIconUrl from './assets/excel-icon.png';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class FileListInputFilePreview extends Vue {
        private faFileAlt = faFileAlt;
        private faTrashAlt = faTrashAlt;
        private excelIconUrl = excelIconUrl;

        @Prop({ type: File })
        private file!: File;

        @Prop({ type: String, default: null })
        private description!: string | null;

        @Prop({ type: Boolean, default: false })
        private error!: boolean;

        @Prop({ type: Boolean, default: true })
        private allowRemove!: boolean;

        @Prop({ type: Number, default: 50 })
        private maxNameLength!: number;

        private get originalName() {
            return this.file.name;
        }

        private get extension() {
            const match = this.originalName.match(/\.[^.]+$/);
            return match ? match[0] : null;
        }

        private get name() {
            const name = this.originalName;

            if (name.length <= this.maxNameLength) {
                return name;
            }

            const extension = this.extension || '';
            const nameWithoutExtension = name.substr(0, name.length - extension.length);

            return `${midellipsis(nameWithoutExtension, this.maxNameLength - extension.length)}${extension}`;
        }
    }
</script>
