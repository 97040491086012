<template>
    <div v-if="enabled">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    // Component that allows to keep a code,
    // but not show it's result until we will be ready

    @Component
    export default class Todo extends Vue {
        @Prop({ type: Boolean, default: false })
        private enabled!: boolean;
    }
</script>
