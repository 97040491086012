<template>
    <div class="Preview">
        <iframe ref="frame" class="Preview__frame" :style="`height: ${height}px`" />
    </div>
</template>

<style lang="scss">
    .Preview {
        &__frame {
            display: block;
            border: 0;
            background: transparent;
            width: 100%;
            height: 100%;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import getIn from 'lodash/get';
    import { encodeText, updateContents } from './utils';

    @Component
    export default class Preview extends Vue {
        @Prop({ type: String })
        private template!: string;

        @Prop({ type: Object, default: () => ({}) })
        private variables!: Record<string, any>;

        @Ref()
        private frame!: HTMLIFrameElement;

        private id = `X${Math.floor(Math.random() * 10e6)}`;
        private contents = '';
        private height: number = NaN;
        private resizeTimeout: any = null;
        private isMounted = false;

        private get contentWindow(): Window {
            return this.frame.contentWindow!;
        }

        private get contentDocument(): Document {
            return this.frame.contentDocument!;
        }

        private get contentBody(): HTMLBodyElement {
            return this.contentDocument.body as HTMLBodyElement;
        }

        private get contentElement(): HTMLDivElement {
            const bodyElement = this.contentBody;
            const elementId = `PreviewWrapper-${this.id}`;
            const elementSelector = `#${elementId}`;
            const existingElement = bodyElement.querySelector(elementSelector);
            if (existingElement) {
                return existingElement as HTMLDivElement;
            }
            const element = this.contentDocument.createElement('div');
            element.id = elementId;
            bodyElement.appendChild(element);
            return element;
        }

        private getContentHeight(): number {
            const bodyStyle = this.contentWindow.getComputedStyle(this.contentBody);
            return (
                parseInt(bodyStyle.paddingTop, 10) +
                parseInt(bodyStyle.paddingBottom, 10) +
                parseInt(bodyStyle.marginTop, 10) +
                parseInt(bodyStyle.marginBottom, 10) +
                parseInt(bodyStyle.borderTopWidth, 10) +
                parseInt(bodyStyle.borderBottomWidth, 10) +
                (this.contentElement.offsetHeight || 0)
            );
        }

        @Watch('template', { immediate: true })
        @Watch('variables')
        private parseTemplate(): void {
            const literalSymbol = `<L${Math.random()}>`;
            const literalRegexp = new RegExp(literalSymbol.replace(/\./, '\\.'), 'g');
            this.contents = (this.template || '')
                .replace(/{{/g, literalSymbol)
                // "=" in front of variable indicating it's html format can be safely render without parsing
                // else parse value into html format
                .replace(/{(=)?\s*([^\s}]+)\s*}/g, (_, safe, phrase) => {
                    const [ name, defaultValue ] = phrase.split(/\|\s*default:/).map((x: string) => x.trim());
                    const variableValue = getIn(this.variables, name, safe);
                    const value = variableValue == null ? `${defaultValue || ''}` : `${variableValue}`;
                    return safe ? value : encodeText(value);
                })
                .replace(literalRegexp, '{');
        }

        @Watch('contents')
        private updateFrame(): void {
            updateContents(this.contentElement, this.contents, this.contentDocument);
            this.tickResize();
        }

        private tickResize(): void {
            if (!this.isMounted) {
                return;
            }
            this.height = this.getContentHeight();
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => this.tickResize(), 500);
        }

        private listenForSizeChanges(): void {
            this.frame.contentWindow!.addEventListener('resize', () => this.updateFrame());
            this.tickResize();
        }

        private mounted(): void {
            this.isMounted = true;
            this.updateFrame();
            this.listenForSizeChanges();
        }

        private destroyed(): void {
            this.isMounted = false;
            clearTimeout(this.resizeTimeout);
        }
    }
</script>
