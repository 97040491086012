<template>
    <ScreenMessage :icon="iconUrl" :type="type">
        <template v-slot:toolbar>
            <slot name="toolbar" />
        </template>
        <slot />
    </ScreenMessage>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import errorIconUrl from '@evidentid/ido-lib/images/ErrorIcon.svg';
    import warningIconUrl from '@evidentid/ido-lib/images/WarningIcon.svg';
    import { ScreenMessage } from '../ScreenMessage';

    @Component({
        components: { ScreenMessage },
    })
    export default class ErrorScreen extends Vue {
        @Prop({ type: Boolean, default: false })
        private warning!: boolean;

        private get type(): string {
            return this.warning ? 'warning' : 'error';
        }

        private get iconUrl(): string {
            return this.warning ? warningIconUrl : errorIconUrl;
        }
    }
</script>
