import { JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';

import {
    Criterion,
    CriterionInput,
    CriterionTemplate,
} from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

interface Params {
    template: CriterionTemplate;
    criterion: CriterionInput | Criterion;
}

export const getFlagDisabledReferenceValueType = ({ template, criterion }: Params): JsonSchemaType | null => {
    const conditionalRefs = template.displayMetadata.conditionalValueReference;

    if (!conditionalRefs) {
        return null;
    }

    const schema = criterion.evaluator.references[conditionalRefs.flagDisabledReference]?.schema;
    return (schema as RegularJsonSchema)?.type || null;
};
