<template>
    <LoginLayout title="Reset your Evident password" divided>
        <template v-slot:header>
            <img :src="logoUrl" alt="">
        </template>

        <template v-slot:description>
            Enter your account e-mail here
            and we will send you a link to reset your password.
        </template>

        <Form @submit.prevent="startReset">
            <FormElement label-for="reset-email" label="Email:">
                <input id="reset-email" v-model="email" type="email" name="email">
            </FormElement>

            <template v-slot:buttons>
                <Button tag="a" href="#/auth" type="small link">
                    Cancel
                </Button>
                <Button :loading="duringSubmit" :disabled="!canSubmit" type="primary" submit>
                    Request reset
                </Button>
            </template>
        </Form>
    </LoginLayout>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Form, FormElement } from '@evidentid/dashboard-commons/components/Form';
    import { LoginLayout } from '@evidentid/dashboard-commons/layouts/LoginLayout';

    @Component({
        components: { LoginLayout, Button, Form, FormElement },
    })
    export default class ResetPassword extends Vue {
        private logoUrl = logoUrl;

        private email = '';

        private get duringSubmit() {
            return this.$store.state.user.duringPasswordReset;
        }

        private get canSubmit() {
            return Boolean(this.email) && !this.duringSubmit;
        }

        private async startReset() {
            await this.$store.actions.snackbar.displaySnackbar(null);
            return this.$store.actions.user.resetPassword(this.email);
        }
    }
</script>
