<template>
    <div class="LoginLayout__divider">
        <span v-if="content || $scopedSlots.default">
            <slot>{{ content }}</slot>
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class LoginLayoutDivider extends Vue {
        @Prop({ type: String, default: null })
        private content!: string | null;
    }
</script>
