<template>
    <MenuLink
        class="SidebarMenu__topLevelItem"
        data-test-id="SidebarMenuNotificationItem"
        :icon="emailSettingsIcon"
        :active="active"
        :open="subMenuExpanded"
        @click="toggleMenu"
    >
        <template #label>
            <span class="MenuElement__linkText flex items-center">
                Display & Email
                <EidIcon
                    v-if="someNotificationsOff"
                    v-tooltip="{
                        html: true,
                        content: '<strong>Some notifications are off</strong> <br> See more in Email Settings',
                    }"
                    data-test-id="SidebarMenu__notificationsOffIcon"
                    class="inline-block ml-2 text-eidSecureBlue-50"
                    :icon-src="faBellSlash"
                />
            </span>
        </template>
        <WithPermissions
            v-if="eventManagerEnabled"
            class="SidebarMenu__configurationItem"
            data-test-id="SidebarMenu__brandingSettings"
            :component="MenuLink"
            :props="{ href: '#/notifications/branding',
                      label: 'Branding Settings',
                      active: notificationsTab === 'branding'}"
            :permissions="[ manageConfigPermission ]"
            accept-not-scoped
        />
        <WithPermissions
            v-if="eventManagerEnabled"
            class="SidebarMenu__configurationItem"
            data-test-id="SidebarMenu__supportContactInformation"
            :component="MenuLink"
            :props="{ href: '#/notifications/contact',
                      label: 'Contact Information',
                      active: notificationsTab === 'contact'}"
            :permissions="[ manageConfigPermission ]"
            accept-not-scoped
        />
        <MenuLink
            class="SidebarMenu__configurationItem"
            data-test-id="SidebarMenu__emailContent"
            :href="'#/notifications/email-content'"
            :active="notificationsTab === 'emailContent'"
            label="Email Content"
        />
        <MenuLink
            class="SidebarMenu__configurationItem"
            data-test-id="SidebarMenu__emailSettings"
            :href="'#/notifications/email-settings'"
            :active="notificationsTab === 'emailSettings'"
        >
            <template #label>
                <span class="MenuElement__linkText flex items-center">
                    Email Settings
                    <EidIcon
                        v-if="someNotificationsOff"
                        v-tooltip="{
                            html: true,
                            content: '<strong>Some notifications are off</strong> <br> Set schedules and switch on',
                        }"
                        data-test-id="SidebarMenu__emailSettingsNotificationsOffIcon"
                        class="inline-block ml-2 text-eidSecureBlue-50"
                        :icon-src="faBellSlash"
                    />
                </span>
            </template>
        </MenuLink>
    </MenuLink>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from '@evidentid/vue-property-decorator';
    import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
    import emailSettingsIcon from '@/assets/icons/nav-icon-email-settings.svg';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import WithPermissions from '@evidentid/dashboard-commons/modules/auth/components/WithPermissions.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import { RelyingPartySignature } from '@evidentid/rpweb-api-client/models';
    import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';

    @Component({
        components: { MenuLink, Menu, WithPermissions, EidIcon },
    })
    export default class SidebarMenuConfigurationItem extends Vue {
        private readonly MenuLink = MenuLink;
        private emailSettingsIcon = emailSettingsIcon;
        private faBellSlash = faBellSlash;

        private subMenuExpanded: boolean = false;

        @Prop({ type: Boolean, default: false })
        private active!: boolean;

        @Watch('active', { immediate: true })
        private onActiveChange(active: boolean): void {
            if (active) {
                this.subMenuExpanded = true;
            }
        }

        @Watch('rpName', { immediate: true })
        private onRpNameChange() {
            this.loadRequestsConfig();
        }

        private get rpName(): string {
            return this.$rp?.current || '';
        }

        private get currentRp(): RelyingPartySignature | null {
            return this.$store.state.user.relyingParties?.find(({ name }) => (name === this.$rp.current)) || null;
        }

        private get eventManagerEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get notificationsTab(): string | null {
            if (!this.active) {
                return null;
            }
            const routeName = this.$route.name!;
            if (routeName === 'notifications-branding-settings') {
                return 'branding';
            } else if (routeName === 'notifications-support-contact') {
                return 'contact';
            } else if (routeName === 'notifications-email-settings') {
                return 'emailSettings';
            } else if (routeName === 'notifications-email-content') {
                return 'emailContent';
            }
            return null;
        }

        private get someNotificationsOff(): boolean {
            return Object.values(this.requestsConfigStatus.data?.verificationRequestConfigurations || {}).some(
                ({ initEnabled, remindersEnabled }) => !initEnabled && !remindersEnabled,
            );
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: TprmRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName] || createEmptyRequestsConfigStatus();
        }

        private get loadingRequestsConfig(): boolean {
            return this.requestsConfigStatus.status === OperationStatus.loading;
        }

        private get manageConfigPermission() {
            return { service: 'rpweb', permission: 'manage:configuration', resource: this.$rp.current };
        }

        private toggleMenu(): void {
            this.subMenuExpanded = !this.subMenuExpanded;
        }

        private loadRequestsConfig() {
            if (!this.loadingRequestsConfig) {
                this.$store.actions.dashboard.loadRequestsConfig({ rpName: this.rpName });
            }
        }
    }

</script>
