<template>
    <div class="EffectiveRiskProfilesTooltipLabel">
        <v-popover
            v-if="riskProfileNames.length > 0"
            trigger="hover"
            :delay="{ show: 300, hide: 100 }"
            :placement="placement"
            popover-class="EffectiveRiskProfilesTooltipLabel__tooltip"
        >
            <div class="EffectiveRiskProfilesTooltipLabel__label tooltip-target">
                {{ label }}
            </div>
            <template slot="popover">
                <div class="EffectiveRiskProfilesTooltipLabel__popoverTitle">
                    Risk Profiles
                </div>
                <div
                    v-for="(name,index) in riskProfileNames"
                    :key="index"
                    class="EffectiveRiskProfilesTooltipLabel__popoverGroupName"
                >
                    {{ name }}
                </div>
            </template>
        </v-popover>
        <div v-else class="EffectiveRiskProfilesTooltipLabel__label">
            {{ showLabelPrefix ? 'Risk Profile:' : '' }} none
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';

    @Component({
        components: { Badge, FontAwesomeIcon },
    })
    export default class EffectiveRiskProfilesTooltipLabel extends Vue {
        @Prop({ type: Array, default: () => [] })
        private riskProfileNames!: string[];

        @Prop({ type: String, default: 'left' })
        private placement!: string;

        @Prop({ type: Boolean, default: true })
        private showLabelPrefix!: boolean;

        private get label(): string {
            return `${this.showLabelPrefix ? 'Risk Profiles:' : ''} ${this.riskProfileNames.join(' + ')}`;
        }
    }
</script>
