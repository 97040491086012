import JsonSchema, { JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { addEnumLabelToSchemaIfAvailable } from '@/modules/decisioning-criteria/utils/riskProfileCriteria';
import {
    Criterion,
    CriterionInput,
    CriterionTemplate,
} from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

// FIXME: remove unnecessary enum label fetching once schema from BE updated correctly(enumLabels in schema)
export function buildCriterionSchema(
    criterion: Criterion | CriterionInput,
    referenceOrder: string[],
    template: CriterionTemplate,
): JsonSchema {
    const references = criterion.evaluator.references;
    return {
        type: JsonSchemaType.object,
        properties: referenceOrder.reduce(
            (accu, refKey) => {
                const enumLabelsMap = template.displayMetadata
                    .enumValues?.find((x) => x.reference === refKey)?.values;
                const schema = enumLabelsMap
                    ? addEnumLabelToSchemaIfAvailable(references[refKey].schema, enumLabelsMap)
                    : references[refKey].schema;
                return {
                    ...accu,
                    ...(references[refKey] &&
                        {
                            [refKey]: schema,
                        }),
                };
            },
            {},
        ),
    };
}
