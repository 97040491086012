<template>
    <TileList style="margin-bottom: 30px">
        <Tile
            v-for="tile in tiles"
            v-bind="tile"
            :key="tile.title"
            :value="getPercentage(tile.categories) / 100"
            :selected="isSelected(tile.categories)"
            :disabled="disabled"
            clickable
            @click="select(tile.categories)"
        >
            <template #label>
                <template v-if="value.total > 0">
                    {{ getPercentage(tile.categories) }}% of {{ value.total }} Active Entities
                </template>
                <template v-else>
                    No active recipients
                </template>
            </template>
            {{ getValue(tile.categories) }}
        </Tile>
    </TileList>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import compliantIcon from '@/modules/entity-filtering/assets/icons/category-icon-compliant.svg';
    import expireSoonIcon from '@/modules/entity-filtering/assets/icons/category-icon-expiringSoon.svg';
    import nonCompliantIcon from '@/modules/entity-filtering/assets/icons/category-icon-nonCompliant.svg';
    import pendingIcon from '@/modules/entity-filtering/assets/icons/category-icon-pending.svg';
    import { Tile, TileList } from '@evidentid/dashboard-commons/components/TileList';
    import { EntityFilterCategory } from '@/modules/entity-filtering/types';

    @Component({
        components: { Tile, TileList },
    })
    export default class CategoryTiles extends Vue {
        private tiles = [
            {
                title: 'COMPLIANT EXPIRING IN MORE THAN 30 DAYS',
                color: 'green',
                icon: compliantIcon,
                categories: [ EntityFilterCategory.compliantAndNotExpiringSoon ],
            },
            {
                title: 'COMPLIANT EXPIRING 30 DAYS',
                color: 'orange',
                icon: expireSoonIcon,
                categories: [ EntityFilterCategory.compliantAndExpiringSoon ],
            },
            {
                title: 'NON-COMPLIANT',
                color: 'red',
                icon: nonCompliantIcon,
                categories: [ EntityFilterCategory.nonCompliant ],
            },
            {
                title: 'PENDING AND NEW',
                icon: pendingIcon,
                categories: [ EntityFilterCategory.pendingAndNew ],
            },
        ];

        @Prop({ type: Array, default: () => [] })
        private selected!: string[];

        @Prop({ type: Object, default: () => ({}) })
        private value!: Record<EntityFilterCategory | 'total', number>;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private getValue(categories: EntityFilterCategory[]): number {
            return categories.reduce((sum, status) => sum + (this.value[status] || 0), 0);
        }

        private getPercentage(categories: EntityFilterCategory[]): number {
            return Math.round(1000 * this.getValue(categories) / this.value.total) / 10;
        }

        private isSelected(value: EntityFilterCategory[]) {
            return value.every((status) => this.selected.includes(status));
        }

        private select(value: EntityFilterCategory[]) {
            this.$emit('input', { changed: !this.isSelected(value), value });
        }
    }
</script>
