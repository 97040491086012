<template>
    <Row
        class="UserManagementUsersTableRow"
        hoverable
    >
        <Cell class="UserManagementUsersTableRow__avatarCell">
            <Avatar
                :name="user.name"
                :email="user.email"
                :url="user.avatarUrl"
            />
        </Cell>
        <Cell>
            <UserNameBox
                :name="user.name"
                :email="user.email"
                :label="userLabel"
            />
        </Cell>
        <Cell>
            <RoleBadge
                :roles="roles"
                :role-labels="roleLabels"
            />
        </Cell>
        <Cell>
            <template v-if="user.status === 'active'">
                Active
            </template>
            <template v-else>
                Pending
                <button
                    v-tooltip="'Resend invite'"
                    class="UserManagementUsersTableRow__resendInviteButton"
                    type="button"
                    @click="$emit('resendInvite')"
                >
                    <FontAwesomeIcon :icon="faEnvelope" />
                </button>
            </template>
        </Cell>
        <Cell class="UserManagementUsersTableRow__actionsCell">
            <FontAwesomeIcon v-if="submitting" :icon="faSpinner" spin />
            <Expandable v-else legacy-mode>
                <UserActionsMenu
                    :user="user"
                    spaced
                    v-on="$listeners"
                />
            </Expandable>
        </Cell>
    </Row>
</template>

<style lang="scss">
    .UserManagementUsersTableRow {
        & &__avatarCell {
            font-size: 36px;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        & &__actionsCell .Expandable__button {
            font-size: 1.8em;
        }

        & &__actionsCell {
            max-width: 30px;
            text-align: center;
        }

        &__resendInviteButton {
            display: inline-block;
            border: 0;
            background: rgba(#000, 0.1);
            color: #2d3e50;
            width: 2em;
            height: 2em;
            line-height: 2em;
            padding: 0;
            margin: 0 0 0 5px;
            border-radius: 50%;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: rgba(#000, 0.2);
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faSpinner, faEnvelope } from '@fortawesome/free-solid-svg-icons';
    import type { User, RoleName, ServiceName } from '@evidentid/user-management-api-client/types';
    import { Cell, Row } from '../../../components/Table';
    import { Avatar } from '../../../components/Avatar';
    import { Expandable } from '../../../components/Expandable';
    import RoleBadge from './RoleBadge.vue';
    import UserNameBox from './UserNameBox.vue';
    import UserActionsMenu from './UserActionsMenu.vue';

    @Component({
        components: { FontAwesomeIcon, UserActionsMenu, Expandable, UserNameBox, RoleBadge, Avatar, Cell, Row },
    })
    export default class UsersTableRow extends Vue {
        private faSpinner = faSpinner;
        private faEnvelope = faEnvelope;

        @Prop({ type: Object })
        private user!: User;

        @Prop({ type: String, default: null })
        private service!: ServiceName | null;

        @Prop({ type: Object, default: () => ({}) })
        private roleLabels!: Record<string, string>;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: String, default: null })
        private userLabel!: string | null;

        private get roles(): RoleName[] {
            return this.user.services[this.service as any]?.roles || [];
        }
    }
</script>
