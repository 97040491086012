
export enum EntityFilterCategory {
    compliantAndNotExpiringSoon = 'compliantAndNotExpiringSoon',
    compliantAndExpiringSoon = 'compliantAndExpiringSoon',
    nonCompliant = 'nonCompliant',
    pendingAndNew = 'pendingAndNew',
}

export type FilterType = 'AND' | 'OR';

export interface EntityStandardFilters {
    search?: string;
    displayName?: string;
    legalName?: string;
    doingBusinessAs?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    complianceStatus?: string;
    verificationStatus?: string;
    expiresBeforeOrOn?: string;
    expiresAfterOrOn?: string;
    showDeactivated?: string;
    paused?: string;
    coverageCriteriaGroupFilterType?: FilterType;
    coverageCriteriaGroupId?: string;
    effectiveGroup?: string;
}

export interface CustomPropertyFilters {
    customPropertyFilters?: Record<string, string>;
}

export interface EntityCollateralFieldFilters {
    collateralFieldFilters?: Record<string, string>;
}

export type EntityFilters = EntityStandardFilters & CustomPropertyFilters & EntityCollateralFieldFilters;

export class EntityStandardFiltersObject implements EntityStandardFilters {
    public search = undefined;
    public displayName = undefined;
    public legalName = undefined;
    public doingBusinessAs = undefined;
    public contactName = undefined;
    public contactEmail = undefined;
    public contactPhoneNumber = undefined;
    public complianceStatus = undefined;
    public verificationStatus = undefined;
    public expiresBeforeOrOn = undefined;
    public expiresAfterOrOn = undefined;
    public showDeactivated = undefined;
    public paused = undefined;
    public coverageCriteriaGroupFilterType = undefined;
    public coverageCriteriaGroupId = undefined;
    public effectiveGroup = undefined;
}
