import LoggerAdapter from '../LoggerAdapter';
import Breadcrumb from '../Breadcrumb';
import LoggerUserData from '../LoggerUserData';
import Severity from '../Severity';
import reporters from '../consoleReporters';

const tagsLogStyle = 'font-weight: bold; color: magenta';
const userLogStyle = 'font-weight: bold';
const breadcrumbCategoryLogStyle = 'font-weight: bold';

export default class ConsoleLoggerAdapter implements LoggerAdapter {
    private tags: Record<string, string> = {};
    private user: LoggerUserData | null = null;

    public addBreadcrumb(breadcrumb: Breadcrumb): void {
        const lines: string[] = [];
        const styles: string[] = [];

        // Add heading line for breadcrumb
        lines.push(`%c[${breadcrumb.category}] %c${breadcrumb.message}`);
        styles.push(breadcrumbCategoryLogStyle, '');

        reporters[breadcrumb.level](lines.join('\n'), ...styles);
        reporters[breadcrumb.level]('Details', breadcrumb.data);
    }

    public setUser(userData: LoggerUserData | null): void {
        this.user = userData;
        reporters[Severity.info]('User updated', this.user);
    }

    public setTags(tags: Record<string, string>): void {
        this.tags = { ...this.tags, ...tags };
        reporters[Severity.info]('Tags updated', this.tags);
    }

    public log(level: Severity, message: string | Error): void {
        const tags = Object.keys(this.tags).map((tag) => `[${tag}: ${this.tags[tag]}]`).join(' ');
        const user = this.user
            ? Object.keys(this.user).map((key) => `${key}=${(this.user as Record<string, any>)[key]}`).join(' ')
            : 'anonymous';
        reporters[level](`${message} %c${tags} %c${user}`, tagsLogStyle, userLogStyle);
    }
}
