import type { Quill } from 'vue-quill-editor';
import type { DeltaOperation } from 'quill';
import noop from 'lodash/noop';
import { Subscriber } from '@evidentid/subscriber';
import type { QuillContent, SearchItem } from './types';

function isElement(node: any): node is HTMLElement {
    return Boolean(node?.tagName);
}

function isText(node: any): node is Text {
    return node?.nodeType === 3;
}

function removeQuillSpecialCharacters(content: string): string {
    return content.replace(/[\uFEFF]/g, '');
}

function createTemporaryElement(html: string): { element: Element, finish: () => { text: string, html: string } } {
    // Build a temporary element
    let element: HTMLDivElement | null = document.createElement('div');
    element.innerHTML = html;

    function destroy(): void {
        if (!element) {
            return;
        }

        // Unload all external temporary elements, to avoid network calls
        for (const child of element.querySelectorAll('[src], [srcset], [href]')) {
            child.removeAttribute('src');
            child.removeAttribute('srcset');
            child.removeAttribute('href');
        }

        // Clean up temporary element
        element.innerHTML = '';

        // Remove reference to the object
        element = null;
    }

    function finish(): { text: string, html: string } {
        if (!element) {
            throw new Error('The temporary element has been already destroyed.');
        }
        const result = {
            text: element.textContent || '',
            html: element.innerHTML,
        };
        destroy();
        return result;
    }

    return {
        element,
        finish,
    };
}

export function getMatchingVariables(items: string[], query?: string | null): SearchItem[] {
    const lowercaseQuery = query ? query.toLowerCase() : '';
    return items
        .map((x) => `${x}}}`)
        .filter((x) => x.toLowerCase().startsWith(lowercaseQuery))
        .map((x) => ({ id: x, value: x }));
}

export function buildOutputHtml(quillContents: string): { html: string, text: string } {
    let html = quillContents;

    // Replace empty <style> used for copy/pasted mention
    html = html.replace(/\s+style="background-color: rgba\(255, 255, 255, 0\);"/g, '');

    // Build a temporary element
    const { element, finish } = createTemporaryElement(html);

    // Remove wrapping paragraph
    if (element.childNodes.length === 1 && element.children[0]?.tagName === 'P') {
        const p = element.children[0]!;
        element.removeChild(p);
        while (p.childNodes.length > 0) {
            element.appendChild(p.childNodes[0]);
        }
    }

    // Replace Quill's simulated break lines with real
    const simulatedBreakLines = Array.from(element.querySelectorAll('sbr'));
    for (const sbr of simulatedBreakLines) {
        sbr.parentNode!.replaceChild(document.createElement('br'), sbr);
    }

    // Replace internal variables with regular syntax
    for (const mention of element.querySelectorAll('span.mention')) {
        mention.parentNode!.replaceChild(
            document.createTextNode(`{{${mention.getAttribute('data-value')}`),
            mention,
        );
    }

    // Extract HTML
    let result = finish();

    // Remove Quill's special characters
    result = {
        html: removeQuillSpecialCharacters(result.html),
        text: removeQuillSpecialCharacters(result.text),
    };

    return result;
}

function findRegexpIndex(text: string, regex: RegExp): number | null {
    regex.lastIndex = 0;
    const result = regex.exec(text);
    return result ? result.index : null;
}

function _replaceTextInTextNodes(
    element: Node,
    regex: RegExp,
    transform: (match: RegExpMatchArray) => Node[],
): Node[] {
    if (isText(element)) {
        const localRegex = new RegExp(regex.source, regex.flags.replace(/g/g, ''));
        const replacement: Node[] = [];
        let text = element.textContent!;
        while (text.length > 0) {
            // Find next occurrence
            const index = findRegexpIndex(text, regex);

            // Push the last (or only) part
            if (index == null) {
                replacement.push(document.createTextNode(text));
                break;
            }

            // Remove the static part
            if (index > 0) {
                replacement.push(document.createTextNode(text.substr(0, index)));
                text = text.substr(index);
            }

            // Add a replacement
            const match = text.match(localRegex)!;
            replacement.push(...transform(match));

            // Remove the replaced part
            text = text.substr(match[0].length);
        }
        return replacement;
    } else if (isElement(element)) {
        for (const child of Array.from(element.childNodes)) {
            const replacement = _replaceTextInTextNodes(child, regex, transform);
            if (replacement.length !== 1 || replacement[0] !== child) {
                for (const newChild of replacement) {
                    element.insertBefore(newChild, child);
                }
                element.removeChild(child);
            }
        }
    }
    return [ element ];
}

function replaceTextInTextNodes(
    element: Element,
    regex: RegExp,
    transform: (match: RegExpMatchArray) => Node[],
): void {
    _replaceTextInTextNodes(element, regex, transform);
}

export function buildQuillHtml(input: string, variables: string[] = []): { html: string, text: string } {
    // Build a temporary element
    const { element, finish } = createTemporaryElement(input);

    // Convert variables to known Quill variables
    replaceTextInTextNodes(element, /{{([a-zA-Z0-9 _.:-]+)}}/g, ([ original, name ]) => {
        if (!variables.includes(name)) {
            return [ document.createTextNode(original) ];
        } else {
            const span = document.createElement('span');
            span.className = 'mention';
            span.setAttribute('data-index', '0');
            span.setAttribute('data-denotation-char', '{{');
            span.setAttribute('data-id', `${name}}}`);
            span.setAttribute('data-value', `${name}}}`);
            // eslint-disable-next-line no-irregular-whitespace
            span.innerHTML = `﻿<span contenteditable="false"><span class="ql-mention-denotation-char">{{</span>${name}}}</span>﻿`;
            return [ span ];
        }
    });

    // Replace <br>'s into Quill's simulated breaks
    const breakLines = Array.from(element.querySelectorAll('br'));
    for (const br of breakLines) {
        if (br.parentNode!.childNodes.length !== 1) {
            br.parentNode!.replaceChild(document.createElement('sbr'), br);
        }
    }

    // Wrap everything in <p> if there is no <p> tag
    if (!element.querySelector('p')) {
        const p = document.createElement('p');
        while (element.childNodes.length > 0) {
            p.appendChild(element.childNodes[0]);
        }
        element.appendChild(p);
    }

    // Add missing nodes for <sbr>
    for (const sbr of Array.from(element.querySelectorAll('sbr'))) {
        // eslint-disable-next-line no-irregular-whitespace
        sbr.innerHTML = '﻿<span contenteditable="false">\n</span>﻿';
    }

    return finish();
}

function getQuillDeltaLength(delta: DeltaOperation): number {
    // 'delete' operation doesn't need to be supported, as it's static
    if (typeof delta.retain === 'number') {
        return delta.retain;
    } else if (typeof delta.insert === 'string') {
        return delta.insert.length;
    }
    return 1;
}

function getQuillDeltaText(delta: DeltaOperation): string {
    if (typeof delta.insert === 'string') {
        return delta.insert;
    } else if ((delta as any).insert.mention) {
        return `{${(delta as any).insert.mention.value || ''}`;
    }
    return '';
}

function getQuillContents(quill: Quill, index: number, length?: number): QuillContent[] {
    return quill.getContents(index, length).map((delta) => ({
        text: getQuillDeltaText(delta),
        length: getQuillDeltaLength(delta),
    }));
}

function getQuillPosition(quill: Quill): { index: number, length: number } | null {
    const position = quill.getSelection();
    if (!position) {
        return null;
    }
    return {
        index: position.index,
        length: position.length,
    };
}

function getQuillContentsCharIterator(contents: QuillContent[]): Iterator<string> {
    let textIndex = 0;
    let currentTextIndex = 0;
    let currentItemIndex = 0;
    return {
        next() {
            while (contents.length > currentItemIndex) {
                const item = contents[currentItemIndex];
                if (item.text.length > textIndex - currentTextIndex) {
                    const result = item.text.charAt(textIndex - currentTextIndex);
                    textIndex++;
                    return { done: false, value: result };
                } else {
                    currentTextIndex += item.text.length;
                    currentItemIndex++;
                }
            }
            return { done: true, value: undefined };
        },
    };
}

function getQuillIndexForCharAt(contents: QuillContent[], textIndex: number): number | null {
    let quillIndex = 0;
    let currentTextIndex = 0;
    let currentItemIndex = 0;
    if (textIndex === 0) {
        return 0;
    }
    while (contents.length > currentItemIndex) {
        const item = contents[currentItemIndex];
        if (item.text.length > textIndex - currentTextIndex) {
            quillIndex += (item.length * (textIndex - currentTextIndex) / item.text.length);
            return quillIndex;
        } else {
            quillIndex += item.length;
            currentTextIndex += item.text.length;
            currentItemIndex++;
        }
    }
    return null;
}

function setQuillPosition(quill: Quill, position: { index: number, length: number }): void {
    const currentPosition = quill.getSelection(true);
    if (currentPosition.length !== position.length || currentPosition.index !== position.index) {
        quill.setSelection({ index: position.index, length: position.length });
    }
}

export function rememberQuillPosition(quill: Quill): () => void {
    const prevPosition = getQuillPosition(quill);
    if (!prevPosition) {
        return noop;
    }
    const prevContents = getQuillContents(
        quill,
        0,
        prevPosition.index === 0 ? undefined : prevPosition.index,
    );
    return () => {
        const newPosition = getQuillPosition(quill);
        if (!newPosition) {
            return;
        }
        let maxQuillIndex = Math.max(newPosition.index, prevPosition.index);
        const newContents = getQuillContents(quill, 0);
        const prevTextIterator = getQuillContentsCharIterator(prevContents);
        const newTextIterator = getQuillContentsCharIterator(newContents);

        // Find the difference
        for (let textIndex = 0;; textIndex++) {
            const quillIndex = (
                getQuillIndexForCharAt(newContents, textIndex) ??
                getQuillIndexForCharAt(newContents, textIndex - 1)!
            );
            const prevChar = prevTextIterator.next();
            const newChar = newTextIterator.next();

            // Hack: allow hitting space button directly after {{variable}}
            // TODO: Revisit that, it's hack
            if (quillIndex === newPosition.index && (newChar.value === ' ' || newChar.value === ' ')) {
                maxQuillIndex++;
                // eslint-disable-next-line no-continue
                continue;
            }
            // End of hack

            if (quillIndex > maxQuillIndex) {
                break;
            } else if (prevChar.value !== newChar.value) {
                setQuillPosition(quill, {
                    index: Math.ceil(quillIndex),
                    length: quillIndex === prevPosition.index
                        ? prevPosition.length
                        : quillIndex === newPosition.index ? newPosition.length : 0,
                });
                return;
            } else if (!prevChar.value) {
                break;
            }
        }

        // Everything is the same, so get back to the previous position
        setQuillPosition(quill, {
            index: maxQuillIndex,
            length: maxQuillIndex === prevPosition.index
                ? prevPosition.length
                : maxQuillIndex === newPosition.index ? newPosition.length : 0,
        });
    };
}

const textObserverSymbol = Symbol('textObserver');
function observeQuill(quill: Quill): (fn: () => void) => () => void {
    // @ts-ignore: fallback to old syntax
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    if (!MutationObserver) {
        return () => noop;
    }
    // @ts-ignore: assigned hacky symbol
    if (!quill[textObserverSymbol]) {
        const subscriber = new Subscriber();
        const observer = new MutationObserver(() => subscriber.emit(undefined));
        observer.observe(quill.root, {
            childList: true,
        });
        // @ts-ignore: assigned hacky symbol
        quill[textObserverSymbol] = subscriber.listen.bind(subscriber);
    }
    // @ts-ignore: assigned hacky symbol
    return quill[textObserverSymbol];
}

export function onQuillUpdateReady(quill: Quill, onReady: () => void): () => void {
    const unsubscribe = observeQuill(quill)(onReady);
    const frame = requestAnimationFrame(onReady);
    return () => {
        unsubscribe();
        cancelAnimationFrame(frame);
    };
}
