import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

interface PayloadWithoutData {
    rpName: string;
}

export function mutateCommonStatusStartState(state: any, stateKey: string, payload: PayloadWithoutData): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: OperationStatus.loading,
    };
}

export function mutateCommonStatusFinishState(state: any, stateKey: string, payload: PayloadWithoutData): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: OperationStatus.success,
    };
}

export function mutateCommonStatusFailState(state: any, stateKey: string, payload: PayloadWithoutData): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: OperationStatus.error,
    };
}
