<template>
    <div class="CollateralCriterionSetting">
        <div class="CollateralCriterionSetting__mappingSection">
            <div class="CollateralCriterionSetting__sectionTitle">
                Mapping:
            </div>
            <div class="CollateralCriterionSetting__sectionDescription">
                Below is a list of mapping rules for the collateral associated in this coverage type.
                By selecting the mapping checkbox, you are defining the match types you would accept.
            </div>
            <CollateralMappingRulesTable
                :value="mappingCriteriaValue"
                :form="mappingCriteriaForm"
                :required="isMappingCriteriaRequired"
                show-error
                @input="onMappingCriteriaInput"
            />
        </div>
        <div class="CollateralCriterionSetting__decisioningSection">
            <div class="CollateralCriterionSetting__sectionTitle">
                Decisioning:-
            </div>
            <div class="CollateralCriterionSetting__sectionDescription">
                Add one or more condition sets that determine how the Limit Required will be evaluated against
                the limits evidenced by the entity.
            </div>
            <div class="LimitTypeBox">
                <CollateralLimitObjectInput
                    :form="limitForm"
                    :value="value.decisioning.limit || {}"
                    @input="onDecisioningPropInput($event, 'limit')"
                />
            </div>
            <div class="deductibleBox">
                <CollateralDeductibleObjectInput
                    :form="deductibleForm"
                    :value="value.decisioning.deductible || {}"
                    @input="onDecisioningPropInput($event, 'deductible')"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { cloneDeep } from 'lodash';
    import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
    import createForm from '@evidentid/json-schema/createForm';
    import JsonForm, { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import { buildCriterionSchema } from '@/modules/decisioning-criteria/schemas/buildCriterionSchema';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { requirementTypeFieldReferenceDelimiter } from '@/modules/decisioning-criteria/types';
    import CollateralDeductibleObjectInput
        from '@/modules/decisioning-criteria/components/CollateralCriterionSetting/CollateralDeductibleObjectInput.vue';
    import CollateralLimitObjectInput
        from '@/modules/decisioning-criteria/components/CollateralCriterionSetting/CollateralLimitObjectInput.vue';
    import CollateralMappingRulesTable
        from '@/modules/decisioning-criteria/components/CollateralCriterionSetting/CollateralMappingRulesTable.vue';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion, CriterionField,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    export default Vue.extend({
        name: 'CollateralCriterionSetting',
        components: {
            CollateralLimitObjectInput,
            CollateralDeductibleObjectInput,
            CollateralMappingRulesTable,
        },
        props: {
            criterion: {
                type: Object as PropType<Criterion | CriterionInput>,
                default: () => ({} as Criterion | CriterionInput),
            },
            template: {
                type: Object as PropType<CriterionTemplate>,
                default: () => ({} as CriterionTemplate),
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
        },
        data() {
            return {
                faQuestionCircle,
                localCriterion: cloneDeep(this.criterion),
                form: null as JsonForm | null,
                mappingCriteriaProp: null as JsonFormProperty | null,
                value: {} as any,
            };
        },
        computed: {
            isMappingCriteriaRequired(): boolean {
                return this.mappingCriteriaProp?.required || false;
            },
            mappingCriteriaForm(): JsonForm {
                return createForm((this.mappingReference.schema as JsonSchemaObject).properties.collateralMatchType);
            },
            mappingCriteriaValue(): string[] {
                return this.value.mapping?.collateralMatchType || [];
            },
            mappingReference(): CriterionField {
                return this.template.evaluator.references.mapping;
            },
            decisioningReference(): CriterionField {
                return this.template.evaluator.references.decisioning;
            },
            deductibleForm(): any {
                const schema = this.decisioningReference.schema as JsonSchemaObject;
                return createForm(schema.properties.deductible);
            },
            limitForm(): any {
                const schema = this.decisioningReference.schema as JsonSchemaObject;
                return createForm(schema.properties.limit);
            },
            orderedTemplateReferences(): string[] {
                return Object.keys(this.template?.evaluator?.references) || [];
            },
        },
        watch: {
            criterion: {
                immediate: true,
                handler() {
                    this.localCriterion = cloneDeep(this.criterion);
                    if (!this.form) {
                        this.form = this.buildJsonSchemaForm();
                    }
                    const mappingProp =
                        (this.form as JsonFormObject).getProperties().find((x) => x.name === 'mapping') || null;
                    this.mappingCriteriaProp = (mappingProp?.form as JsonFormObject).getProperties()
                        .find((x) => x.name === 'collateralMatchType') || null;
                    this.value = {
                        mapping: { ...this.localCriterion.evaluator.references.mapping.value },
                        decisioning: { ...this.localCriterion.evaluator.references.decisioning.value },
                    };
                },
            },
        },
        methods: {
            buildJsonSchemaForm(): JsonForm {
                return createForm(
                    standardizeCriterionSchema(
                        buildCriterionSchema(this.criterion, this.orderedTemplateReferences, this.template),
                    ),
                );
            },
            buildValue(): any {
                return Object.entries(this.localCriterion.evaluator.references)
                    .reduce((accu, [ key, ref ]) => ({ ...accu, [key]: ref.value }), {});
            },
            onMappingCriteriaInput(value: string[]) {
                const propValue = {
                    ...this.value.mapping,
                    collateralMatchType: value,
                };
                this.onInput(propValue, 'mapping');
            },
            onDecisioningPropInput(value: any, key: string) {
                const propValue = {
                    ...this.value.decisioning,
                    [key]: value,
                };
                this.onInput(propValue, 'decisioning');
            },
            onInput(value: any, key: string): void {
                const fieldForm = (this.form as JsonFormObject)?.getProperties()
                    .find((property) => property.name === key)?.form || null;
                const isValid = fieldForm?.isValid(fieldForm?.getValue(value, true), true) || false;
                this.localCriterion.evaluator.references[key].value = value;
                this.value[key] = value;
                const reference = [
                    this.localCriterion.coverageType,
                    this.localCriterion.field,
                    key,
                ].join(requirementTypeFieldReferenceDelimiter);
                this.$emit('input', this.localCriterion);
                this.$emit('reference-accessed', { reference, isValid });
            },
        },
    });
</script>
