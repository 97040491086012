import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

interface CommonVuexListDataStatus<T> {
    status: OperationStatus;
    list: T;
}

export function createEmptyListVuexStatus<T>(): CommonVuexListDataStatus<T> {
    return {
        status: OperationStatus.uninitialized,
        list: [] as unknown as T,
    };
}
