<template>
    <div
        ref="dropdown"
        class="Dropdown"
        :tabindex="tabIndex"
        :class="{'Dropdown--open':opened,
                 'Dropdown--disabled': disabled || !hasAvailableOptions,
                 'Dropdown--enabledClear': showClear}"
    >
        <div class="Dropdown__containerWrapper">
            <div class="Dropdown__container" @click="toggle">
                <div class="Dropdown__selectedContainer">
                    <div v-if="$scopedSlots.prepend" class="Dropdown__selectedPrepend">
                        <slot name="prepend" />
                    </div>
                    <span class="Dropdown__valueText">{{ selectedLabel }}</span>
                </div>
                <EidIcon v-if="hasAvailableOptions" class="Dropdown__arrow" :icon-src="faCaretDown" />
            </div>
            <div
                v-if="showClear"
                class="Dropdown__clearButton"
                @click="clearSelection"
            >
                <FontAwesomeIcon :icon="faTimes" />
            </div>
        </div>
        <div v-if="opened" class="Dropdown__optionsContainer">
            <template v-if="multiSelect">
                <MultiSelectOptions
                    :options="availableOptions"
                    :selected="selectedOptions"
                    :is-disabled-option="isDisabledOption"
                    @select="onMultiSelect"
                    @change="applySelections"
                    @abort="abort"
                    @clear="$emit('clear', $event)"
                >
                    <slot name="additionalContent" />
                </MultiSelectOptions>
            </template>
            <template v-else>
                <SingleSelectOptions
                    :options="nonSelectedOptions"
                    :is-disabled-option="isDisabledOption"
                    @select="onSingleSelect"
                />
            </template>
        </div>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import uniqWith from 'lodash/uniqWith';
    import isEqual from 'lodash/isEqual';
    import xorWith from 'lodash/xorWith';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '../Button';
    import Select from '../Select/Select.vue';
    import Checkbox from '../Checkbox/Checkbox.vue';
    import SingleSelectOptions from './SingleSelectOptions.vue';
    import MultiSelectOptions from './MultiSelectOptions.vue';
    import { DropdownOption } from './types';
    import EidIcon from '../EidIcon/EidIcon.vue';

    @Component({
        components: { EidIcon, MultiSelectOptions, SingleSelectOptions, Checkbox, Select, FontAwesomeIcon, Button },
    })
    export default class Dropdown extends Vue {
        @Prop({ type: Boolean, default: false })
        private multiSelect!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Array, default: () => [] })
        private disabledValues!: any[];

        @Prop({ type: Array, default: () => [] })
        private excludedValues!: any[];

        @Prop({ type: Boolean, default: false })
        private enableClear!: boolean;

        @Prop({ type: Number, default: 0 })
        private tabIndex!: number;

        @Prop({ type: String, default: 'Select' })
        private placeholder!: string;

        @Prop({ type: Array, default: () => [] })
        private selected!: DropdownOption[];

        @Prop({ type: Array, default: () => [] })
        private options!: DropdownOption[];

        private selectedOptions: DropdownOption[] = [];
        private previousOptions: DropdownOption[] = [];

        private faCaretDown = faCaretDown;
        private faTimes = faTimes;
        private opened: boolean = false;

        @Watch('selected', { immediate: true })
        private onSelectedChanged(): void {
            this.selectedOptions = uniqWith(this.selected, isEqual);
            this.previousOptions = [ ...this.selectedOptions ];
        }

        @Watch('opened', { immediate: true })
        private onOpened(): void {
            if (this.opened) {
                document.addEventListener('mouseup', this.dismissDropdownOnClickOut);
            } else {
                document.removeEventListener('mouseup', this.dismissDropdownOnClickOut);
            }
        }

        private get availableOptions(): DropdownOption[] {
            const uniqueOptions = uniqWith(this.options, isEqual);
            return uniqueOptions.filter(({ value }) => !this.excludedValues.some((val) => val === value));
        }

        private get selectedLabel(): string {
            return this.selectedOptions.map((option) => option.label).join(', ') || this.placeholder;
        }

        private get nonSelectedOptions(): DropdownOption[] {
            return xorWith(this.availableOptions, this.selectedOptions, isEqual);
        }

        private dismissDropdownOnClickOut(event: MouseEvent) {
            if (event.target && !(this.$refs.dropdown as Element)?.contains(event.target as Node)) {
                this.onBlur();
            }
        }

        private close(): void {
            this.opened = false;
            this.$emit('close');
        }

        private open(): void {
            if (this.hasAvailableOptions && !this.disabled) {
                this.opened = true;
                this.$emit('open');
                this.$emit('focus');
            }
        }

        private get hasAvailableOptions(): boolean {
            return this.multiSelect ? this.options.length > 0 : this.nonSelectedOptions?.length > 0;
        }

        private get showClear(): boolean {
            return this.enableClear && !this.multiSelect && this.selectedOptions.length > 0;
        }

        private get disabledOptions(): DropdownOption[] {
            return this.availableOptions.filter(
                (option) => this.disabledValues.some((val) => val === option.value),
            );
        }

        private abort(): void {
            this.close();
            this.selectedOptions = [ ...this.previousOptions ];
        }

        private toggle(): void {
            this.opened ? this.close() : this.open();
        }

        private onBlur(): void {
            if (this.multiSelect) {
                this.applySelections();
            } else {
                this.abort();
            }
            this.$emit('blur');
        }

        private onSingleSelect(option: DropdownOption): void {
            this.selectedOptions = [ option ];
            this.previousOptions = [ option ];
            this.$emit('select', option);
            this.$emit('input', option);
            this.close();
        }

        private clearSelection(): void {
            this.$emit('select', []);
            this.$emit('input', []);
            this.$emit('clear');
        }

        private onMultiSelect(options: DropdownOption[]): void {
            this.selectedOptions = [ ...options ];
            this.$emit('select', options);
        }

        private applySelections(): void {
            this.previousOptions = [ ...this.selectedOptions ];
            this.$emit('input', this.selectedOptions);
            this.close();
        }

        private isDisabledOption(option: DropdownOption): boolean {
            return this.disabledOptions.includes(option);
        }

        private beforeDestroy() {
            document.removeEventListener('mouseup', this.dismissDropdownOnClickOut);
        }
    }
</script>
