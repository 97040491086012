import sanitizeLogValue from './sanitizeLogValue';

export default function formatLogData(
    originalData: Record<string, any>,
    separator = '\n',
): Record<string, string | number> {
    const result: Record<string, any> = {};
    for (const key of Object.keys(originalData)) {
        result[key] = sanitizeLogValue(originalData[key], separator);
    }
    return result;
}
