<template>
    <div
        class="JsonSchemaEmailForm"
        :class="{'JsonSchemaEmailForm--focus': focused}"
    >
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>Email Only</span>
                </div>
            </template>

            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <input
                    :id="id"
                    type="email"
                    :disabled="disabled"
                    :value="viewValue"
                    :placeholder="form.schema.placeholder"
                    :maxlength="form.schema.maxLength || 1024"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @change="onChange"
                >

                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        components: { FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaEmailForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        private faExclamationCircle = faExclamationCircle;
        private faTimes = faTimes;

        protected override getCustomError(): string | null {
            return this.valid ? null : this.translate('error.email.invalid');
        }

        private get viewValue() {
            return typeof this.value === 'string' || typeof this.value === 'number' ? `${this.value}` : '';
        }

        private onInput(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }

        private onChange(event: Event): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('change', this.form.getValue(inputValue));
        }
    }
</script>
