<template>
    <div
        class="ExpansionItem"
        :class="{
            'ExpansionItem--expanded': isExpanded,
            'ExpansionItem--collapsed': !isExpanded
        }"
    >
        <div
            class="ExpansionItem__header flex px-2 py-3 cursor-pointer"
            data-test-id="ExpansionItem__header"
            @click="onClick"
        >
            <EidIcon class="mr-3" :icon-src="iconCaret" />
            <slot name="header" :is-expanded="isExpanded">
                <span>{{ label }}</span>
            </slot>
        </div>
        <div v-if="isExpanded" class="ExpansionItem__content">
            <slot :is-expanded="isExpanded" />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import EidIcon from '../EidIcon/EidIcon.vue';
    import { faCaretDown, faCaretRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';

    export default Vue.extend({
        name: 'ExpansionItem',
        components: {
            EidIcon,
        },
        props: {
            value: {
                type: Boolean as PropType<boolean>,
                default: undefined,
            },
            label: {
                type: String as PropType<string>,
                default: '',
            },
        },
        data() {
            return {
                faCaretDown,
                faCaretRight,
                isExpanded: false,
            };
        },
        computed: {
            iconCaret(): IconDefinition {
                return this.isExpanded ? faCaretDown : faCaretRight;
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue?: boolean) {
                    if (typeof newValue === 'boolean') {
                        this.isExpanded = newValue;
                    }
                },
            },
        },
        methods: {
            onClick(): void {
                this.isExpanded = !this.isExpanded;
                this.$emit('input', this.isExpanded);
            },
        },
    });
</script>
