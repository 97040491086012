<template>
    <MenuLink
        class="SidebarMenu__topLevelItem"
        data-test-id="SidebarMenuConfigurationItem"
        :icon="configurationIcon"
        :active="active"
        :open="subMenuExpanded"
        label="Configuration"
        @click="toggleMenu"
    >
        <template
            v-for="{href, name, label, permissions} in menuItems"
        >
            <WithPermissions
                v-if="permissions && permissions.length > 0"
                :key="name"
                :data-test-id="`SidebarMenuConfigurationItem__${name}`"
                :component="MenuLink"
                :props="{ href, label, active: activeMenu === name}"
                :permissions="permissions"
                class="SidebarMenu__configurationItem"
                adapter="auth0"
                accept-not-scoped
            />
            <MenuLink
                v-else
                :key="name"
                :data-test-id="`SidebarMenuConfigurationItem__${name}`"
                :href="href"
                :active="activeMenu === name"
                :label="label"
                class="SidebarMenu__configurationItem"
            />
        </template>
    </MenuLink>
</template>
<script lang="ts">
    import { Component, Vue, Prop, Watch } from '@evidentid/vue-property-decorator';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import WithPermissions from '@evidentid/dashboard-commons/modules/auth/components/WithPermissions.vue';
    import configurationIcon from '@/assets/icons/nav-icon-configuration.svg';

    @Component({
        components: { MenuLink, Menu, WithPermissions, EidIcon },
    })
    export default class SidebarMenuConfigurationItem extends Vue {
        private readonly MenuLink = MenuLink;
        private configurationIcon = configurationIcon;

        private subMenuExpanded: boolean = false;

        @Prop({ type: Boolean, default: false })
        private active!: boolean;

        private menuItems = [
            {
                href: '#/configuration/api-settings',
                name: 'apiSettings',
                label: 'API Settings',
                permissions: [ this.manageConfigPermission ],
            },
            { href: '#/configuration/properties', name: 'propertiesDisplay', label: 'Properties Display' },
            {
                href: '#/configuration/users',
                name: 'usersAndRoles',
                label: 'Users & Roles',
                permissions: [ this.manageUsersPermission ],
            },
        ];

        @Watch('active', { immediate: true })
        private onActiveChange(active: boolean): void {
            if (active) {
                this.subMenuExpanded = true;
            }
        }

        private get activeMenu(): string | null {
            if (!this.active) {
                return null;
            }
            const routeName = this.$route.name!;
            if (routeName.startsWith('propertiesDisplay')) {
                return 'propertiesDisplay';
            } else if (routeName.startsWith('manage-users')) {
                return 'usersAndRoles';
            } else if (routeName.startsWith('apiSettings')) {
                return 'apiSettings';
            }
            return null;
        }

        private get manageUsersPermission() {
            return { service: 'rpweb', permission: 'manage:users', resource: this.$rp?.current };
        }
        private get manageConfigPermission() {
            return { service: 'rpweb', permission: 'manage:configuration', resource: this.$rp?.current };
        }
        private toggleMenu(): void {
            this.subMenuExpanded = !this.subMenuExpanded;
        }
    }
</script>
