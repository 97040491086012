import { TableColumn } from '@/modules/entity-details/models/TableColumn.model';

export const collateralListColumns: TableColumn[] = [
    {
        name: 'limitType',
        field: 'limitType',
        label: 'Limit Type',
    },
    {
        name: 'coverageLimit',
        field: 'coverageLimit',
        label: 'Coverage Limit',
    },
    {
        name: 'valuation',
        field: 'valuation',
        label: 'Valuation for Coverage Limit',
    },
    {
        name: 'deductible',
        field: 'deductible',
        label: 'Coverage Deductible',
    },
];
