<template>
    <v-component v-if="hasOnlyExternal || duringSubmit" :is="Loading" />
    <v-component v-else :is="Component" v-bind="$props" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import type { IamClient } from '@evidentid/iam-client';
    import { UserState } from '../types';

    @Component
    export default class AuthLoginWrapper extends Vue {
        @Prop()
        private Component!: Vue;

        @Prop()
        private Loading!: Vue;

        private redirectedAlready: boolean = false;

        private get iam(): IamClient {
            return (this as any).$auth.client;
        }

        private get state(): UserState {
            return (this as any).$store.state.user;
        }

        private get duringSubmit(): boolean {
            const { state } = this;
            return Boolean(!state.initialized || state.duringRegularLogin || state.duringSsoLogin);
        }

        private get hasOnlyExternal(): boolean {
            const availableMethods = this.iam.getAvailableLoginMethods();
            const hasOneMethodOnly = availableMethods.length === 1;
            const hasExternalMethod = availableMethods.includes('external');
            return hasExternalMethod && hasOneMethodOnly;
        }

        @Watch('duringSubmit', { immediate: true })
        private async redirect(): Promise<void> {
            if (!this.redirectedAlready && !this.duringSubmit && !this.state.data && this.hasOnlyExternal) {
                this.redirectedAlready = true;
                await (this as any).$store.actions.user.logInWithSso('external');
            }
        }
    }
</script>
