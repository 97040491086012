export enum SortingType {
    amount = 'amount',
    alpha = 'alpha',
    arrow = 'arrow',
    numeric = 'numeric',
}

export enum SortingDirection {
    asc = 'asc',
    desc = 'desc',
}

export interface Sorting {
    column: string;
    direction: SortingDirection;
}

export const ascSymbol = '+';
export const descSymbol = '-';

export function serializeSorting(sorting: Sorting): string {
    const direction = sorting.direction === SortingDirection.desc ? descSymbol : ascSymbol;
    return `${direction}${sorting.column}`;
}

export function serializeMultiSorting(sortingList: Sorting[]): string {
    return sortingList.map(serializeSorting).join(',');
}

export function deserializeSorting(sorting: unknown): Sorting | null {
    if (!sorting || typeof sorting !== 'string' || sorting.length < 2) {
        return null;
    }
    const sortingSymbol = sorting[0];
    if (sortingSymbol !== ascSymbol && sortingSymbol !== descSymbol) {
        return null;
    }
    return {
        direction: sortingSymbol === descSymbol ? SortingDirection.desc : SortingDirection.asc,
        column: sorting.substr(1),
    };
}

export function deserializeMultiSorting(multiSorting: unknown): Sorting[] {
    return typeof multiSorting === 'string'
        ? multiSorting.split(',').map(deserializeSorting).filter(Boolean) as Sorting[]
        : [];
}

export function sanitizeSerializedMultiSorting(sorting: string): string {
    return serializeMultiSorting(deserializeMultiSorting(sorting));
}

export function toggleDirection(direction: unknown): SortingDirection {
    return direction === SortingDirection.asc ? SortingDirection.desc : SortingDirection.asc;
}
