<template>
    <div class="RiskProfileCriterion">
        <JsonSchemaCustomForm
            v-slot="{ fields }"
            v-model="controller.value"
            :controller="controller"
        >
            <div v-for="(field, key) in fields" :key="key">
                <CriterionSettingsRow
                    v-if="filteredReferenceByStaticNameAndValue.includes(key)"
                    class="py-2.5"
                    :key-name="key"
                    :schema="field.form.schema"
                >
                    <JsonSchemaCustomFormElement
                        :field="field"
                        :custom-component-input="getCustomComponent"
                        show-error
                        v-bind="{ disallowedValues: disallowedValues[key] }"
                        @input="onInput($event, key)"
                    />
                </CriterionSettingsRow>
            </div>
        </JsonSchemaCustomForm>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import difference from 'lodash/difference';
    import flatten from 'lodash/flatten';
    import omit from 'lodash/omit';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import {
        requirementTypeFieldReferenceDelimiter,
        GeneralAggregateLimitReferences,
    } from '@/modules/decisioning-criteria/types';
    import { getCriterionJsonSchemaComponent } from '@/modules/decisioning-criteria/utils/getCriterionJsonSchemaComponent';
    import { isSpecialConditionSetsCriterion } from '@/modules/decisioning-criteria/components/CriterionInformationBanner/utils';
    import { FormController } from '@evidentid/json-schema/FormController';
    import createForm from '@evidentid/json-schema/createForm';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import { buildCriterionSchema } from '@/modules/decisioning-criteria/schemas/buildCriterionSchema';
    import { JsonFormObject } from '@evidentid/json-schema/interfaces/JsonForm';
    import CriterionSettingsRow from
    '@/modules/decisioning-criteria/components/CriterionSettingsRow/CriterionSettingsRow.vue';
    import { getFlagDisabledReferenceValueSchema } from '@/modules/decisioning-criteria/utils/getFlagDisabledReferenceValueSchema';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion, CriterionField,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
    import {
        ConditionalValueReference,
    } from '@evidentid/tprm-portal-lib/models/decisioning/ConditionalValueReference.model';

    function gerOrderedTemplateReferences(template: CriterionTemplate): string[] {
        return Object.keys(template.evaluator?.references) || [];
    }

    function buildController(
        criterion: Criterion | CriterionInput,
        template: CriterionTemplate,
        customProperties: CustomProperty[],
    ): FormController {
        const orderedTemplateReferences = gerOrderedTemplateReferences(template);
        const criterionValueSchema = getFlagDisabledReferenceValueSchema({
            template,
            criterion,
        });
        const initValue = Object.entries(criterion.evaluator.references)
            .reduce((accu, [ key, ref ]) => ({ ...accu, [key]: ref.value }), {});
        return new FormController(
            createForm(
                standardizeCriterionSchema(
                    buildCriterionSchema(criterion, orderedTemplateReferences, template),
                    customProperties,
                    criterionValueSchema,
                ),
            ),
            initValue,
        );
    }

    type LoadCriterionMessage = (
        controller: FormController,
        updatedCriterion: Criterion | CriterionInput
    ) => void;

    export default Vue.extend({
        name: 'RiskProfileCriterion',
        components: {
            JsonSchemaCustomForm,
            JsonSchemaCustomFormElement,
            CriterionSettingsRow,
        },
        props: {
            criterion: {
                type: Object as PropType<Criterion | CriterionInput>,
                required: true,
            },
            template: {
                type: Object as PropType<CriterionTemplate>,
                required: true,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
            loadCriterionMessage: {
                type: Function as PropType<LoadCriterionMessage>,
                default: () => undefined,
            },
        },
        data() {
            const controller = buildController(this.criterion, this.template, this.customProperties);
            return {
                getCustomComponent: getCriterionJsonSchemaComponent(),
                controller,
                disallowedValues: {} as Record<string, any>,
            };
        },
        computed: {
            conditionalValueReferences(): ConditionalValueReference | null {
                return this.template.displayMetadata.conditionalValueReference || null;
            },
            orderedTemplateReferences(): string[] {
                return gerOrderedTemplateReferences(this.template);
            },
            conditionallyFilteredFields(): string[] {
                const conditionalValueReference = this.conditionalValueReferences;
                if (!conditionalValueReference) {
                    return this.orderedTemplateReferences;
                }
                const flagValue = conditionalValueReference
                    ? this.criterion.evaluator.references[conditionalValueReference.flagValueReference]?.value
                    : false;
                const unavailableFieldReference = flagValue
                    ? conditionalValueReference.flagDisabledReference
                    : conditionalValueReference.flagEnabledReference;
                return this.orderedTemplateReferences.filter((reference) => (
                    reference !== unavailableFieldReference &&
                    reference !== conditionalValueReference.flagValueReference
                ));
            },
            filteredReferenceByStaticNameAndValue(): string[] {
                const filterReferences = [];
                if (this.filterOutGeneralAggregateLimit()) {
                    filterReferences.push(GeneralAggregateLimitReferences.generalAggregateLimitThreshold);
                }
                return difference(this.conditionallyFilteredFields, filterReferences);
            },
            isSpecialConditionSetsCriterion(): boolean {
                return isSpecialConditionSetsCriterion(this.criterion);
            },
        },
        created() {
            this.loadCriterionMessage(this.controller, this.criterion);
            this.$emit('controller-value-update', this.controller.value);
        },
        methods: {
            isKeyConditionSets(key: string) {
                return key === GeneralAggregateLimitReferences.conditionSets;
            },
            filterOutGeneralAggregateLimit(): boolean {
                return Boolean(this.isSpecialConditionSetsCriterion &&
                    this.criterion.evaluator
                        .references[GeneralAggregateLimitReferences.generalAggregateLimitThreshold] &&
                    this.controller?.value.conditionSets?.length > 0);
            },
            onInput(value: any, key: string): void {
                this.trackInputValues(value, key);
                const fieldForm = (this.controller?.form as JsonFormObject).getProperties()
                    .find((property) => property.name === key)?.form;
                const isValid = fieldForm?.isValid(fieldForm?.getValue(value, true), true) || false;
                const controllerValues = this.controller.form.getValue(this.controller.value);
                const updateCriterion = {
                    ...this.criterion,
                    evaluator: {
                        ...this.criterion.evaluator,
                        references: Object.entries(this.criterion.evaluator.references)
                            .reduce(
                                (acc, [ refKey, field ]) => {
                                    acc[refKey] = {
                                        ...field,
                                        value: refKey === key ? value : controllerValues[refKey],
                                    };
                                    return acc;
                                },
                                {} as Record<string, CriterionField>,
                            ),
                    },
                };
                const reference = [
                    this.criterion.coverageType,
                    this.criterion.field,
                    key,
                ].join(requirementTypeFieldReferenceDelimiter);
                this.$emit('input', updateCriterion);
                this.$emit('reference-accessed', { reference, isValid });
                this.$emit('controller-value-update', this.controller.value);
                this.loadCriterionMessage(this.controller, updateCriterion);
            },
            trackInputValues(value: any, key: string): void {
                // tracking siblings with same type of input values, for fields
                // does not allow duplicate value across form
                if (this.isSpecialConditionSetsCriterion && this.isKeyConditionSets(key)) {
                    const allAppliesPer = flatten(value?.map((
                        conditionSetsVal: any) => conditionSetsVal.generalAggregateLimitAppliesPer,
                    )).filter(Boolean);
                    if (allAppliesPer?.length > 0) {
                        this.disallowedValues = {
                            ...this.disallowedValues,
                            conditionSets: { generalAggregateLimitAppliesPer: allAppliesPer },
                        };
                    } else if (this.disallowedValues?.conditionSets?.generalAggregateLimitAppliesPer) {
                        this.disallowedValues = {
                            ...this.disallowedValues,
                            conditionSets: omit(this.disallowedValues.conditionSets, 'generalAggregateLimitAppliesPer'),
                        };
                    }
                }
            },
        },
    });
</script>
