import { readFile } from './blobs';

const mimeTypeExtensions: Record<string, string> = {
    'text/csv': '.csv',
};

export function isFileInstance<T extends File | Blob | null>(file: T): file is Exclude<T, null> {
    return Boolean(file && (
        // Regular implementation
        file instanceof File ||
        // Pseudo-file used by Microsoft Edge
        // @ts-ignore: name is not a real Blob property
        (file instanceof Blob && file.name)
    ));
}

export function hasProperMimeTypeExtension(name: string, acceptedMimeTypes: string[]): boolean {
    return acceptedMimeTypes.some((mimeType) => {
        const extension = mimeTypeExtensions[mimeType];
        return extension ? name.endsWith(extension) : false;
    });
}

export function isFileValid(file: File | null, acceptedMimeTypes?: string[]): boolean {
    return Boolean(isFileInstance(file) && (
        !acceptedMimeTypes ||
        (file.type && acceptedMimeTypes.includes(file.type)) ||
        (file.name && hasProperMimeTypeExtension(file.name, acceptedMimeTypes))
    ));
}

export function getAcceptAttribute(mimeTypes: string[] | undefined): string | undefined {
    if (!mimeTypes || mimeTypes.length === 0) {
        return undefined;
    }
    return mimeTypes.reduce((acc, mimeType) => {
        const extension = mimeTypeExtensions[mimeType];
        acc.push(mimeType);
        if (extension) {
            acc.push(extension);
        }
        return acc;
    }, [] as string[]).join(', ');
}

export function validateFiles(files: FileList | File[], acceptedMimeTypes?: string[]) {
    const success: File[] = [];
    const failed: File[] = [];
    for (const file of Array.from(files)) {
        const destination = isFileValid(file, acceptedMimeTypes) ? success : failed;
        destination.push(file);
    }
    return { success, failed };
}

export async function buildBinaryDataObjectFromFile(file: File, metadata: any = null) {
    if (!isFileInstance(file)) {
        throw new Error('Invalid File passed for BinaryData');
    }

    const dataUrl = await readFile.asDataURL(file);
    const [ , base64 ] = dataUrl.split(',');

    return {
        $objectType: 'BinaryData',
        data: base64,
        mime_type: file.type,
        metadata,
    };
}
