<template>
    <div class="EntityTooltipDetails">
        <div class="EntityTooltipDetails__insuredName">
            <label>Display Name</label>
            <span>{{ entity.displayName }}</span>
        </div>
        <div class="EntityTooltipDetails__legalName">
            <label>Legal Name</label>
            <span>{{ entity.legalName }}</span>
        </div>
        <div class="EntityTooltipDetails__doingBusinessAs">
            <label>DBA(s)</label>
            <span v-for="dba in entity.doingBusinessAs" :key="dba">{{ dba }}</span>
        </div>
        <div class="EntityTooltipDetails__contactName">
            <label>Primary Contact Name</label>
            <span>{{ entity.contactName }}</span>
        </div>
        <div class="EntityTooltipDetails__contactEmail">
            <label>Primary Contact Email</label>
            <span>{{ entity.contactEmail }}</span>
        </div>
        <div class="EntityTooltipDetails__contactPhoneNumber">
            <label>Primary Contact Phone Number</label>
            <span>{{ formattedPhoneNumber }}</span>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import 'intl-tel-input/build/js/utils';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component
    export default class EntityTooltipDetails extends Vue {
        @Prop({ type: Object })
        private entity!: Entity;

        private get formattedPhoneNumber() {
            // @ts-ignore:
            return window.intlTelInputUtils.formatNumber(
                this.entity.contactPhoneNumber,
                null,
                window.intlTelInputUtils.numberFormat.NATIONAL,
            );
        }
    }
</script>
