export enum EnumCategories {
    complianceStatus = 'complianceStatus',
    countryCode = 'countryCode',
    coverageTypes = 'coverageTypes',
    verificationStatus = 'verificationStatus',
    automobilesCovered = 'automobilesCovered',
    physicalDamageCoverageValuationMethod = 'physicalDamageCoverageValuationMethod',
    limits = 'limits',
    valuation = 'valuation',
    perilsEntity = 'perilsEntity',
    occurrenceOrClaimsMade = 'occurrenceOrClaimsMade',
    limitAppliesPer = 'limitAppliesPer',
    coverageBasis = 'coverageBasis',
    generalAggregateLimitAppliesPer = 'generalAggregateLimitAppliesPer',
    currency = 'currency',
    collateralLimitType = 'collateralLimitType'
}
