import type { RouteConfig } from 'vue-router/types';
import {
    ApplicationForExecution,
    ApplicationForInjection,
    ExtractModuleDemandedOptions,
    ExtractModuleInjectedContext,
    ExtractModuleInjectedProcedures,
    ExtractModuleInjectedVueContext,
    ExtractModuleInjectedVuex,
    ModuleDemandedContext,
    ModuleDemandedOptions,
    ModuleDemandedVuex,
    ModuleInjectedContext,
    ModuleInjectedProcedures,
    ModuleInjectedVueContext,
    ModuleInjectedVuex,
    PartialModuleDeclaration,
} from '../types/ModuleDeclaration';
import { ExecuteVuexModulesFactoryMap, VuexModulesFactoryMap, VuexModulesMapChunk } from '../types/vuex';
import { SealedModule } from './SealedModule';

/* eslint-disable no-use-before-define */

export class ModuleBuilder<T extends PartialModuleDeclaration = {}> {
    private vuexModules: any = {};
    private injections: any[] = [];
    private vueInjections: any[] = [];
    private executions: any[] = [];
    private routes: any[] = [];
    private slotComponents: any = {};
    private procedures: any = {};

    public demand<D extends SealedModule | PartialModuleDeclaration>(): D extends SealedModule<infer MD>
        ? ModuleBuilder<T & ExtractMeaningfulDeclaration<MD>>
        : ModuleBuilder<T & D> {
        // @ts-ignore: simulating change
        return this;
    }

    public demandOptions<O extends {}>(): ModuleBuilder<T & ModuleDemandedOptions<O>> {
        // @ts-ignore: simulating change
        return this;
    }

    public demandContext<C extends {}>(): ModuleBuilder<T & ModuleDemandedContext<C>> {
        // @ts-ignore: simulating change
        return this;
    }

    public demandVuex<V extends VuexModulesMapChunk<V>>(
    ): ModuleBuilder<T & ModuleDemandedVuex<V>> {
        // @ts-ignore: simulating change
        return this;
    }

    public registerRoutes<
        A extends ApplicationForExecution<T>,
        R extends RouteConfig[]
    >(routesFactory: (app: A) => R): ModuleBuilder<T> {
        this.routes = this.routes.concat(routesFactory);
        return this;
    }

    public registerVuex<V extends VuexModulesFactoryMap<V, ApplicationForExecution<T>>>(
        vuexModules: V
    ): ModuleBuilder<T & ModuleInjectedVuex<ExecuteVuexModulesFactoryMap<V>>> {
        this.vuexModules = { ...this.vuexModules, ...vuexModules };
        // @ts-ignore: simulating change
        return this;
    }

    // TODO: Allow registering component positions as well
    // TODO: optionally, think how to pass the Vue.Component type here (while avoiding circular dependency)
    public registerComponent(position: string, Component: any): ModuleBuilder<T> {
        if (!this.slotComponents[position]) {
            this.slotComponents[position] = [];
        }
        this.slotComponents[position] = [ ...this.slotComponents[position], Component ];
        return this;
    }

    public registerTopLevelComponent(Component: any): ModuleBuilder<T> {
        return this.registerComponent('topLevel', Component);
    }

    public registerProcedures<P extends { [key: string]: object }>(
        procedures: { [K in keyof P]: any },
    ): ModuleBuilder<T & ModuleInjectedProcedures<P>> {
        this.procedures = { ...this.procedures, ...procedures };
        // @ts-ignore: simulating change
        return this;
    }

    public inject<R extends {}>(
        fn: (app: ApplicationForInjection<T>) => Promise<R>,
    ): ModuleBuilder<T & ModuleInjectedContext<R>> {
        this.injections.push(fn);
        // @ts-ignore: simulating change
        return this;
    }

    public injectVue<A extends ApplicationForExecution<T>, R extends {}>(
        fn: (app: A) => R,
    ): ModuleBuilder<T & ModuleInjectedVueContext<R>> {
        this.vueInjections.push(fn);
        // @ts-ignore: simulating change
        return this;
    }

    public execute<A extends ApplicationForExecution<T>>(
        fn: (app: A) => any,
    ): ModuleBuilder<T> {
        this.executions.push(fn);
        // @ts-ignore: simulating change
        return this;
    }

    public end(): SealedModule<T> {
        return new SealedModule<T>(
            this.vuexModules,
            this.injections,
            this.vueInjections,
            this.executions,
            this.routes,
            this.slotComponents,
            this.procedures,
        );
    }
}

interface ExtractMeaningfulDeclaration<T extends PartialModuleDeclaration> {
    demandedOptions: ExtractModuleDemandedOptions<T>;
    injectedContext: ExtractModuleInjectedContext<T>;
    injectedVuex: ExtractModuleInjectedVuex<T>;
    injectedVueContext: ExtractModuleInjectedVueContext<T>;
    injectedProcedures: ExtractModuleInjectedProcedures<T>;
}
