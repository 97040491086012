<script lang="ts">
    import Vue from 'vue';
    import CountryFlag from 'vue-country-flag';
    import CriterionEnumInput from './CriterionEnumInput.vue';
    export default Vue.extend({
        functional: true,
        render(createElement, context) {
            return createElement(
                CriterionEnumInput,
                {
                    ...context.data,
                    scopedSlots: {
                        dropdownPrepend(props: any) {
                            const currentCountryValue = props.currentOption[0]?.value || '';
                            return createElement(CountryFlag, { props: { country: currentCountryValue } });
                        },
                    },
                },
            );
        },
    });
</script>

