<template>
    <div class="LoginLayout" :class="{ 'LoginLayout--wide': wide }">
        <div v-if="$scopedSlots.header" class="LoginLayout__header">
            <slot name="header" />
        </div>
        <div class="LoginLayout__content">
            <slot name="title">
                <h2 v-if="title">
                    {{ title }}
                </h2>
            </slot>

            <LoginLayoutDivider v-if="divided" />

            <div v-if="$scopedSlots.description" class="LoginPage__description">
                <slot name="description" />
            </div>

            <slot />
        </div>

        <div
            v-if="$scopedSlots.footer"
            class="LoginLayout__footer"
            :style="hideFooter ? { visibility: 'hidden' } : null"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import LoginLayoutDivider from './LoginLayoutDivider.vue';

    @Component({
        components: { LoginLayoutDivider },
    })
    export default class LoginLayout extends Vue {
        @Prop({ type: Boolean, default: true })
        private hideFooter!: boolean;

        @Prop({ type: Boolean, default: false })
        private divided!: boolean;

        @Prop({ type: Boolean, default: false })
        private wide!: boolean;

        @Prop({ type: String, default: null })
        private title!: string | null;
    }
</script>
