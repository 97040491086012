<template>
    <div ref="self" class="EidSidePanel">
        <slot>
            <slot name="header">
                <div class="EidSidePanel__header">
                    <div class="EidSidePanel__headerText">
                        <slot name="headerText" />
                    </div>
                    <div class="EidSidePanel__closeButton" @click="clickOut">
                        <slot name="closeButton">
                            <img
                                :src="arrowIcon"
                                alt="close"
                                class="RiskProfileCriteriaPanel__closeIcon"
                            >
                        </slot>
                    </div>
                    <slot name="headerOther" />
                </div>
            </slot>

            <slot name="content" />
        </slot>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import arrowIcon from '@/assets/icons/arrow-pipe-icon.svg';

    const eventClickOptions: AddEventListenerOptions = {
        capture: true,
    };

    export default Vue.extend({
        name: 'EidSidePanel',
        props: {
            clickOutExceptionSelectors: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
        },
        data() {
            return {
                arrowIcon,
            };
        },
        mounted() {
            this.addEventListeners();
        },
        destroyed() {
            this.removeEventListeners();
        },
        methods: {
            checkClickOut(event: MouseEvent): void {
                const target = (event.target as HTMLElement);
                const clickedOnException = this.clickOutExceptionSelectors.some((selector) => target.closest(selector));
                const clickedOutside = !(this.$refs.self as HTMLElement)?.contains(target);
                if (!clickedOnException && clickedOutside) {
                    this.clickOut();
                }
            },
            addEventListeners(): void {
                document.body.addEventListener('click', this.checkClickOut, eventClickOptions);
            },
            removeEventListeners(): void {
                document.body.removeEventListener('click', this.checkClickOut, eventClickOptions);
            },
            clickOut(): void {
                this.$emit('click-out');
            },
        },
    });
</script>
