var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CriterionObjectInput",class:{'CriterionObjectInput--substitutable': _vm.customPropertySubstitutable}},_vm._l((_vm.schemasToRender),function(ref){
var key = ref.key;
var propSchema = ref.propSchema;
var thenOrElse = ref.thenOrElse;
return _c(_vm.getFormElementComponent({
            customComponentInput: _vm.customComponentInput,
            valuePath: _vm.getPath(key),
            schema: propSchema

        }),{key:key,tag:"component",attrs:{"id":_vm.getPath(key),"depth":_vm.depth + 1,"schema":propSchema,"value":_vm.value[key],"initial-value":_vm.initialValue[key],"default-value":_vm.defaultValue[key],"value-path":_vm.getPath(key),"required":_vm.isRequired(key),"parent-schema-errors":_vm.getChildErrors(key),"touched":_vm.touched,"hide-title":_vm.hideTitle,"show-error":_vm.showError,"custom-component-input":_vm.customComponentInput,"schema-path":_vm.getSchemaPath(key, thenOrElse)},on:{"input":function($event){return _vm.onInput(key, $event)},"change":function($event){return _vm.onChange(key, $event)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }