<template>
    <div class="Dots">
        <div v-for="n in 3" :key="n" class="Dots__dot" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Dots extends Vue {
        @Prop({ type: Number, default: 3 })
        private amount!: number;
    }
</script>

<style lang="scss">
    $dots-dot-margin: 4px;
    $dots-dot-size: 0.25em;
    $dots-height: 1em / ($dots-dot-size / ($dots-dot-size * 0 + 1));

    .Dots {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        font-size: $dots-dot-size;
        line-height: $dots-height;
        height: $dots-height;
        white-space: nowrap;
        border-radius: 50%;

        &__dot {
            width: 1em;
            height: 1em;
            background: currentColor;
            border-radius: 50%;
        }

        &__dot + &__dot {
            margin-left: $dots-dot-margin;
        }
    }
</style>
