<template>
    <Expandable
        class="BulkActionsExpandable"
        :disabled="disabled || loading"
        legacy-mode
    >
        <template #button>
            <Button
                class="BulkActionsExpandable__button Button Button--primary"
                :class="{'Button--gray': disabled, 'Button--disabled': disabled }"
                :loading="loading"
            >
                Bulk Actions
            </Button>
        </template>
        <Menu spaced>
            <MenuLink
                v-if="showPause"
                label="Pause"
                :icon="faPauseCircle"
                @click="setPauseStatus(true)"
            />
            <MenuLink
                v-if="showUnpause"
                label="Unpause"
                :icon="faPlayCircle"
                @click="setPauseStatus(false)"
            />
            <MenuLink
                label="Deactivate"
                :icon="faStopCircle"
                @click="deactivate"
            />
            <MenuLink
                :disabled="!enableSendDocumentRequest"
                :icon="faPaperPlane"
                label="Create a New Link"
                @click="$emit('sendDocumentRequest')"
            />
        </Menu>
    </Expandable>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faPauseCircle, faStopCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
    import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    @Component({
        components: { Expandable, FontAwesomeIcon, Menu, MenuLink, Button },
    })
    export default class BulkActionsExpandable extends Vue {
        private faPauseCircle = faPauseCircle;
        private faPlayCircle = faPlayCircle;
        private faStopCircle = faStopCircle;
        private faPaperPlane = faPaperPlane;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: true })
        private showPause!: boolean;

        @Prop({ type: Boolean, default: true })
        private showUnpause!: boolean;

        @Prop({ type: Boolean, default: true })
        private enableSendDocumentRequest!: boolean;

        private setPauseStatus(value: boolean) {
            this.$emit('bulkSetPauseState', value);
        }

        private deactivate() {
            this.$emit('bulkDeactivate');
        }
    }
</script>
