<template>
    <Row hoverable class="RiskProfileTableRow">
        <Cell class="RiskProfileTableRow__riskProfileName">
            <EidIcon
                v-if="showRiskProfileTypeIcon"
                :icon-src="riskProfileIconSrc"
                :svg-props="{color: qualityBlack75}"
                data-test-id="RiskProfileTableRow_riskProfileTypeIcon"
            />
            <span data-test-id="RiskProfileTableRow_riskProfileNameText">{{ riskProfile.displayName }}</span>
        </Cell>
        <Cell
            class="RiskProfileTableRow__decision"
            :class="{'RiskProfileTableRow__decision--active': riskProfile.active}"
        >
            {{ status }}
        </Cell>
        <Cell class="RiskProfileTableRow__requirementTypes">
            {{ riskProfile.numberOfCoverageTypes }}
        </Cell>
        <Cell>
            <span class="RiskProfileTableRow__entitiesAssigned" @click="goToEntityTable">
                {{ riskProfile.numberOfInsuredsAssigned }}
            </span>
        </Cell>

        <Cell class="RiskProfileTableRow__actionsCell">
            <img
                :src="editIcon"
                alt="edit"
                class="RiskProfileTableRow__editIcon"
                @click="$emit('edit', riskProfile.id)"
            >
            <Todo>
                <img
                    :src="copyIcon"
                    alt="copy"
                    class="RiskProfileTableRow__copyIcon"
                    @click="$emit('copy', riskProfile.id)"
                >
            </Todo>
            <EidIcon
                :icon-src="deleteIcon"
                alt="delete"
                class="RiskProfileTableRow__deleteIcon"
                :svg-props="{color: evidentGreenColor}"
                @click="$emit('delete', riskProfile.id)"
            />
        </Cell>
    </Row>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { Todo } from '@evidentid/dashboard-commons/components/Todo';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import pencilIcon from '@/assets/icons/icon-pencil-hollow.svg';
    import copyIcon from '@/assets/icons/icon-copy-hollow.svg';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import requirementTypesIcon from '@/modules/decisioning-criteria/assets/requirement-types-risk-profile-icon.svg';
    import collateralTypeIcon from '@/modules/decisioning-criteria/assets/collateral-type-risk-profile-icon.svg';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { getRiskProfileTypeIconSrc } from '@/modules/decisioning-criteria/utils/get-risk-profile-type-icon-src/getRiskProfileTypeIconSrc';
    import { PropType } from 'vue';
    import { RiskProfile, RiskProfileType } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    @Component({
        components: { Cell, EidIcon, Row, Todo },
    })
    export default class RiskProfileTableRow extends Vue {
        @Prop({ type: Object })
        private riskProfile!: RiskProfile;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private showRiskProfileTypeIcon!: boolean;

        private editIcon = pencilIcon;
        private copyIcon = copyIcon;
        private deleteIcon = binIcon;
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;
        private qualityBlack75 = tailwindColors.eidQualityBlack['75'];

        private get status(): string {
            return this.riskProfile.active ? 'Active' : 'Discover';
        }

        private get riskProfileIconSrc(): string {
            return getRiskProfileTypeIconSrc(this.riskProfile.ccgType);
        }

        private goToEntityTable(): void {
            this.$router.push({
                name: 'dashboard',
                params: {
                    rpId: this.$route.params.rpId,
                },
                query: {
                    coverageCriteriaGroupId: this.riskProfile.id,
                },
            });
        }
    }
</script>
