<template>
    <Modal additional-class-name="EntitiesDocumentsModal EntitiesDocumentsModal--ready" open @close="close">
        <template #header>
            Documents
        </template>
        <DocumentList :documents="documents" :requirement-models="requirementModels" @open="open" />
    </Modal>
</template>

<style lang="scss">
    .EntitiesDocumentsModal--ready {
        .Modal__body {
            width: 750px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { DocumentDetails } from '@/modules/entity-documents/types';
    import DocumentList from '@/modules/entity-documents/components/DocumentList.vue';

    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            DocumentList,
            Modal,
        },
    })
    export default class EntitiesDocumentsListModal extends Vue {
        @Prop({ type: String })
        private email!: string;

        @Prop({ type: Array })
        private documents!: DocumentDetails[];

        @Prop({ type: Array })
        private requirementModels!: TprmRequirementModel[];

        private close(): void {
            this.$emit('close');
        }

        private open(document: DocumentDetails): void {
            this.$emit('open', document);
        }
    }
</script>
