<template>
    <div class="FormInput" :class="{ 'FormInput--with-icon': $scopedSlots.icon, 'FormInput--error': invalid}">
        <div class="FormInput__inputRow">
            <div class="FormInput__inputContainer">
                <div class="FormInput__input">
                    <slot />
                </div>
                <div class="FormInput__icon" v-if="$scopedSlots.icon">
                    <slot name="icon" />
                </div>
            </div>
            <div class="FormInput__actionContainer" v-if="forceActionSlot || $scopedSlots.actionButtons">
                <slot name="actionButtons" />
            </div>
        </div>

        <div class="FormInput__errorMessage" v-if="forceErrorSlot || $scopedSlots.errorMessage">
            <div class="FormInput__errorIcon" v-if="errorIcon">
                <FontAwesomeIcon :icon="errorIcon" v-if="$scopedSlots.errorMessage" />
            </div>
            <div class="FormInput__errorContent">
                <slot name="errorMessage"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class FormInput extends Vue {
        @Prop({ type: Boolean, default: false })
        private invalid!: boolean;

        @Prop({ type: Boolean, default: false })
        protected forceErrorSlot!: boolean;

        @Prop({ type: Boolean, default: false })
        protected forceActionSlot!: boolean;

        @Prop({ default: () => faExclamationTriangle })
        protected errorIcon!: IconDefinition;
    }
</script>
