import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    DashboardConfiguration,
} from '@evidentid/tprm-portal-lib/models/dashboard-configuration/DashboardConfiguration.model';

export interface DashboardConfigurationRequirement {
    rpweb: RpWebApiClient;
}

interface DashboardConfigurationStatus {
    status: OperationStatus;
    config: DashboardConfiguration;
}

interface DashboardConfigurationState {
    configuration: Record<string, DashboardConfigurationStatus>;
    updateStatus: Record<string, OperationStatus>;
}

const createState = createStateFactory<DashboardConfigurationRequirement>();

export function createEmptyInsuranceDashboardStatus(): DashboardConfigurationStatus {
    return {
        status: OperationStatus.uninitialized,
        config: {
            insuredFields: [],
        },
    };
}

const { instantiateState, createMutationsFactories } = createState<DashboardConfigurationState>(() => ({
    configuration: {},
    updateStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingDashboardConfiguration(payload: { rpName: string }) {
        this.configuration = {
            ...this.configuration,
            [payload.rpName]: {
                ...createEmptyInsuranceDashboardStatus(),
                ...(this.configuration[payload.rpName] as DashboardConfigurationStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingDashboardConfiguration(
        payload: { rpName: string, configuration: DashboardConfiguration },
    ) {
        this.configuration = {
            ...this.configuration,
            [payload.rpName]: {
                ...(this.configuration[payload.rpName] as DashboardConfigurationStatus),
                status: OperationStatus.success,
                config: payload.configuration,
            },
        };
    },
    failLoadingDashboardConfiguration(payload: { rpName: string }) {
        this.configuration = {
            ...this.configuration,
            [payload.rpName]: {
                ...(this.configuration[payload.rpName] as DashboardConfigurationStatus),
                status: OperationStatus.error,
                config: createEmptyInsuranceDashboardStatus().config,
            },
        };
    },

    startUpdatingDashboardConfiguration(payload: { rpName: string }) {
        this.updateStatus = {
            ...this.updateStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failUpdatingDashboardConfiguration(payload: { rpName: string }) {
        this.updateStatus = {
            ...this.updateStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishUpdatingDashboardConfiguration(payload: { rpName: string }) {
        this.updateStatus = {
            ...this.updateStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearDashboardConfiguration(rpName: string) {
        this.configuration = omit(this.configuration, [ rpName ]);
    },
    clearUpdateDashboardConfigurationStatus(rpName: string) {
        this.updateStatus = omit(this.updateStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: DashboardConfigurationRequirement) => ({
    async loadDashboardConfiguration(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.configuration[rpName] || createEmptyInsuranceDashboardStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingDashboardConfiguration({ rpName });
        try {
            const configuration = await rpweb.getTprmDashboardConfiguration(rpName);
            const latestStatus = this.state.configuration[rpName] || createEmptyInsuranceDashboardStatus();
            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingDashboardConfiguration({ rpName, configuration });
        } catch (error) {
            this.mutations.failLoadingDashboardConfiguration({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateDashboardConfiguration(payload: {
        rpName: string;
        config: DashboardConfiguration;
    }) {
        const { rpName, config } = payload;
        const currentStatus = this.state.updateStatus[rpName] || OperationStatus.uninitialized;
        // Ignore when it is already updating
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startUpdatingDashboardConfiguration({ rpName });
        try {
            await rpweb.updateTprmDashboardConfiguration(rpName, config);
            const latestStatus = this.state.updateStatus[rpName] || OperationStatus.uninitialized;
            // Handle race condition
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishUpdatingDashboardConfiguration({ rpName });
        } catch (error) {
            this.mutations.failUpdatingDashboardConfiguration({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    clearDashboardConfiguration(payload: { rpName: string }) {
        this.mutations.clearDashboardConfiguration(payload.rpName);
    },
    clearUpdateDashboardConfigurationStatus(payload: { rpName: string }) {
        this.mutations.clearUpdateDashboardConfigurationStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
