<template>
    <v-component
        :is="Page"
        class="SettingsPage"
        :title="title"
        :loading="loading"
        @submit="save"
    >
        <template slot="toolbarEnd">
            <Button
                class="SettingsPage__cancelButton"
                :disabled="updating || loading || !changed"
                @click="cancel"
            >
                Cancel
            </Button>

            <Button
                type="primary"
                class="SettingsPage__saveButton"
                :disabled="updating || loading || !changed || !valid"
                :loading="updating"
                submit
                @click="save"
            >
                Save
            </Button>
        </template>

        <JsonSchemaCustomForm
            v-model="controller.value"
            class="SettingsPage__content"
            :controller="controller"
            :disabled="updating"
            touched
        >
            <div class="SettingsPage__columns">
                <div v-if="$scopedSlots.preview" class="SettingsPage__preview">
                    <slot
                        name="preview"
                        :fields="controller.structure.properties"
                        :value="computedValue"
                        :previous-value="previousValue"
                        :valid="valid"
                        :changed="changed"
                    />
                </div>
                <div class="SettingsPage__form">
                    <slot
                        :fields="controller.structure.properties"
                        :value="computedValue"
                        :previous-value="previousValue"
                        :valid="valid"
                        :changed="changed"
                    />
                </div>
            </div>
        </JsonSchemaCustomForm>
        <ConfirmNavigationOnDirtyState :dirty="changed && loggedIn" />
    </v-component>
</template>

<style lang="scss">
    .SettingsPage {
        h3 {
            font-family: "Roboto", "Arial", "Tahoma", sans-serif;
            font-weight: 500;
            font-size: 1.4em;
            margin: 0 0 10px;
        }

        hr {
            margin: 20px 0;
            border: 0;
            border-top: 1px solid #bbc6d0;
        }

        &__cancelButton {
            margin-right: 5px;

            &:not([disabled]) {
                color: #f00;
            }
        }

        & &__saveButton {
            padding: 9px 40px;
        }

        &__content {
            background: #fff;
            padding: 30px 45px 40px;
        }

        &__columns {
            display: flex;
            margin: 0 -18px;

            > * {
                flex: 1 0;
                margin: 0 18px;
            }
        }

        &__formRow {
            display: flex;
            justify-content: stretch;
            margin: 0 -10px;

            > * {
                flex: 1 0;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &__formRowSeparator {
            width: 20px;
            min-width: 20px;
            max-width: 20px;
            text-align: center;
            align-self: center;
            color: #ccc;

            &:before {
                content: "-";
            }
        }

        &__formRowDescription {
            max-width: 200px;
            min-width: 200px;
            width: 200px;
            margin-right: auto;
            align-self: center;
            font-size: 0.95em;
            color: #778ea1;
        }

        &__preview {
            display: flex;
            flex-direction: column;

            .Preview {
                width: 100%;
                margin-top: 10px;
                border: 1px solid #dfdfdf;
            }
        }

        &__note {
            margin-top: 20px;
            font-style: italic;
            font-size: 0.9em;
            color: #a4a4a4;
        }

        .FormElement__requiredIndicator {
            display: none;
        }

        .FormInput__actionContainer {
            display: none;
        }

        .FormElement {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import createForm from '@evidentid/json-schema/createForm';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import {
        FormController,
        FormControllerObjectFnc,
        FormControllerValidationFnc,
    } from '@evidentid/json-schema/FormController';
    import {
        JsonSchemaCustomForm,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Preview } from '@evidentid/dashboard-commons/components/Preview';
    import { ConfirmNavigationOnDirtyState } from '@evidentid/dashboard-commons/components/ConfirmNavigationOnDirtyState';
    import { getFormValue } from '../utils/getFormValue';
    import ActionEmailPreview from '../components/ActionEmailPreview.vue';
    import { SupportContactConfiguration } from '@/modules/notifications-configuration/types';

    @Component({
        components: {
            ConfirmNavigationOnDirtyState,
            ActionEmailPreview,
            JsonSchemaCustomForm,
            Button,
            Preview,
        },
    })
    export default class SettingsPage extends Vue {
        @Prop({ type: Object })
        private schema!: JsonSchemaObject;

        @Prop({ type: Object, default: () => null })
        private initialValue!: Record<string, any> | null;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: false })
        private updating!: boolean;

        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Function, default: () => true })
        private validateSupportTime!: FormControllerValidationFnc<SupportContactConfiguration>;

        private validation: FormControllerObjectFnc<SupportContactConfiguration> = {
            supportEndTime: (formValues: any) => this.validateSupportTime(formValues),
            supportStartTime: (formValues: any) => this.validateSupportTime(formValues),
        };
        private controller = new FormController(
            createForm(this.schema),
            this.initialValue,
            this.validation,
        );
        private controllerExternalValue = null;

        /**
         * Method for the usage by parent component via ref for the component.
         */
        public updateFormControllerValue(value: any): void {
            this.controller.value = {
                ...this.controller.value,
                ...value,
            };
            // contains only fulfillment data, expand to pass whole settings when needed
            this.controllerExternalValue = { ...value };
        }

        private get loggedIn(): boolean {
            return Boolean(this.$store.state.user?.data);
        }

        private get Page(): Vue.Component {
            return (this as any).$notificationsConfiguration.Page;
        }

        private get previousValue(): Record<string, any> {
            return getFormValue(this.controller.form, this.initialValue);
        }

        private get computedValue(): Record<string, any> {
            return getFormValue(this.controller.form, this.controller.value);
        }

        private get valid(): boolean {
            return this.controller.valid;
        }

        private get changed(): boolean {
            const externalValue = this.controllerExternalValue ? this.controllerExternalValue : this.initialValue;
            const withHiddenFulfillmentValue = (
                externalValue &&
                this.computedValue?.fulfillmentConfiguration &&
                Object.keys(this.computedValue.fulfillmentConfiguration).length === 1 &&
                this.computedValue.fulfillmentConfiguration.enabled === false
            )
                ? {
                    ...this.computedValue,
                    fulfillmentConfiguration: {
                        ...externalValue.fulfillmentConfiguration,
                        enabled: false,
                    },
                }
                : { ...this.computedValue };
            return !isEqual(this.previousValue, withHiddenFulfillmentValue);
        }

        private save(): void {
            if (this.updating || this.loading || !this.changed || !this.valid) {
                return;
            }
            this.$emit('save', this.computedValue);
        }

        private cancel(): void {
            if (!this.updating && !this.loading && this.changed) {
                this.reset();
            }
        }

        @Watch('schema', { immediate: true })
        @Watch('initialValue')
        private reset(): void {
            this.$emit('reset');
            this.controller = new FormController(
                createForm(this.schema),
                this.initialValue,
                this.validation,
            );
            this.controller.value = this.initialValue;
        }
    }
</script>
