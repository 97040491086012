import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import vuex from '@/modules/entity-actions-review/vuex';
import ActionsReview from '@/modules/entity-actions-review/views/ActionReview/ActionsReview.vue';

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/actions-review',
        redirect: '/:rpId?/actions',
    },
];

export const entityActionsReviewModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/actions',
            name: 'entityActionsReview',
            component: ActionsReview as any,
            meta: { title: 'Actions Review' },
        },
        ...legacyRoutesRedirect,
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ insuredActionsReview: vuex.instantiateModule })
    .execute((app) => {
        function onLoadingEntityActionsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Actions',
                    description: `[Requested] Load entity actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Entity Actions',
                    message: `[Requested] Load entity actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEntityActionsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Actions',
                    description: `[Finished] Load entity actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Entity Actions',
                    message: `[Finished] Load entity actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEntityActionsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Entity Actions Failed',
                    description: `[Failed] Load entity actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Entity Actions',
                    message: `[Failed] Load entity actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories',
                    description: `[Requested] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Requested] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories',
                    description: `[Finished] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Finished] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories Failed',
                    description: `[Failed] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Failed] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingInsuredActions', (x) => onLoadingEntityActionsRequest(x.rpName));
        app.observer.onMutation('finishLoadingInsuredActions', (x) => onLoadingEntityActionsFinish(x.rpName));
        app.observer.onMutation('failLoadingInsuredActions', (x) => onLoadingEntityActionsFailure(x.rpName));
        app.observer.onMutation('startLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesRequest(x.rpName));
        app.observer.onMutation('finishLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesFinish(x.rpName));
        app.observer.onMutation('failLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesFailure(x.rpName));
    })
    .end();
