<template>
    <FormElement class="CriterionCheckboxInput" :required="required">
        <template v-slot:requiredIndicator>
            {{ translate('requiredLabel') }}
        </template>

        <FormInput :invalid="accessed && hasError">
            <div class="CriterionCheckboxInput__checkBox" :id="id">
                <Checkbox
                    :value="value"
                    :disabled="disabled"
                    @input="onInput"
                />
            </div>
            <template v-if="deletable" v-slot:actionButtons>
                <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </template>
        </FormInput>

        <template v-if="accessed && error" v-slot:errorMessage>{{ error }}</template>
    </FormElement>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { FormInput, FormElement, Checkbox, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class CriterionCheckboxInput extends AbstractJsonSchemaForm<JsonFormBoolean, boolean> {
        private faTimes = faTimes;

        private onInput(value: boolean) {
            this.touch();
            this.$emit('input', this.form.getValue(value));
        }
    }
</script>
