export const extensions = {
    image: [ 'bmp', 'gif', 'jpeg', 'jpg', 'png' ],
    document: [ 'pdf', 'txt' ],
};

export const regularExtensions: string[] = ([] as string[]).concat(...Object.values(extensions));

export const mimeTypes = {
    image: [ 'image/bmp', 'image/gif', 'image/jpeg', 'image/png' ],
    document: [
        'application/pdf',
        'text/plain',
    ],
};

export const regularMimeTypes: string[] = ([] as string[]).concat(...Object.values(mimeTypes));

export const mimetypeExtensions: Record<string, string> = {
    'application/pdf': '.pdf',
    'image/png': '.png',
    'image/jpeg': '.jpg',
    'image/gif': '.gif',
    'image/webp': '.webp',
};

export const extensionMimetypes: Record<string, string> = Object.keys(mimetypeExtensions).reduce((acc, mimetype) => ({
    ...acc,
    [mimetypeExtensions[mimetype]]: mimetype,
}), {});
