<template>
    <Modal
        additional-class-name="UserManagementConfigureUserModal"
        :allow-close="!submitting"
        :open="open"
        form
        @close="onCloseRequest"
        @submit.prevent="onSubmit"
    >
        <template v-slot:header>
            {{ title }}
        </template>

        <div class="UserManagementConfigureUserModal__columns">
            <div
                v-if="config.roleDescriptions && config.availableRoles.length > 1"
                class="UserManagementConfigureUserModal__description"
                v-html="config.roleDescriptions[value.role]"
            />
            <JsonSchemaForm
                v-model="inputValue"
                class-name="UserManagementConfigureUserModal__form"
                simulated
                :disabled="submitting"
                :form="form"
            >
                <template v-slot:buttons>
                    <Button :loading="submitting" :disabled="!submittable" type="primary" submit>
                        {{ submitLabel }}
                    </Button>
                </template>
            </JsonSchemaForm>
        </div>
    </Modal>
</template>

<style lang="scss">
    .UserManagementConfigureUserModal {
        &__columns {
            display: flex;
            align-content: stretch;
            justify-content: center;
        }

        &__form {
            max-width: 600px;
            flex: 1 auto;
        }

        &__description {
            background: #f3f3f3;
            color: #2e3e50;
            padding: 20px;
            width: 250px;
            min-width: 250px;
            font-size: 0.88em;
            margin-right: 15px;

            ul, li, p {
                font-size: 1em;
            }
        }

        .Modal__content {
            max-width: 90vw;
            width: 870px;
        }

        .FormElement__requiredIndicator {
            color: inherit;
            font-style: italic;
        }

        .JsonSchemaForm__hint,
        .JsonSchemaForm__warning {
            font-style: italic;
        }

        // Hack: Put the description in proper position
        // stylelint-disable no-duplicate-selectors
        .Modal__content {
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 0;
            margin-bottom: 0;
        }

        &__description {
            margin-top: -20px;
            margin-bottom: -20px;
            margin-left: -20px;
        }
        // stylelint-enable no-duplicate-selectors
        // End of hack: Put the description in proper position

        // FIXME: It's not nice hack, but this container should most of time be not visible anyway and anywhere,
        //        but I don't want to modify it globally yet, as it may affect existing UI.
        .FormInput__actionContainer {
            display: none;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import isEqual from 'lodash/isEqual';
    import { JsonFormObject } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
    import createForm from '@evidentid/json-schema/createForm';
    import { RoleName } from '@evidentid/user-management-api-client/types';
    import { Modal } from '../../../components/Modal';
    import { ConfirmationModal } from '../../../components/ConfirmationModal';
    import { Button } from '../../../components/Button';
    import { JsonSchemaForm } from '../../../components/JsonSchemaForm';
    import { UserInput, UserManagementConfig } from '../types';

    @Component({
        components: {
            JsonSchemaForm,
            ConfirmationModal,
            Modal,
            Button,
        },
    })
    export default class ConfigureUserModal extends Vue {
        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: Boolean, default: false })
        private submitting!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private value!: Partial<UserInput>;

        @Prop({ type: String })
        private title!: string;

        @Prop({ type: String, default: 'Save' })
        private submitLabel!: string;

        @Prop({ type: Boolean, default: false })
        private disableEmailAddress!: boolean;

        @Prop({ type: Boolean, default: false })
        private disableName!: boolean;

        @Prop({ type: String, default: null })
        private nameWarning!: string | null;

        // TODO: Support it
        @Prop({ type: Boolean, default: false })
        private showRoleChangeWarning!: boolean;

        private form: JsonFormObject = null as any;

        private inputValue = {
            name: '',
            email: '',
            role: this.defaultRole,
        };

        private get config(): UserManagementConfig {
            return (this as any).$userManagement.config;
        }

        private get defaultRole(): RoleName {
            return this.config.availableRoles[0];
        }

        @Watch('config', { immediate: true })
        private initializeForm(): void {
            const roles = this.config.availableRoles;
            const roleLabels = roles.map((role) => (this.config.roleLabels?.[role] || role));
            const roleProperty = roles.length === 1
                ? {
                    const: roles[0],
                    title: 'User role',
                }
                : {
                    enum: roles,
                    enumLabels: roleLabels,
                    title: 'User role',
                    description: 'Select role',
                };

            this.form = createForm({
                type: JsonSchemaType.object,
                properties: {
                    name: {
                        type: JsonSchemaType.string,
                        readOnly: this.disableName,
                        title: 'Name',
                        description: 'Name',
                        warning: this.nameWarning,
                    },
                    email: {
                        type: JsonSchemaType.string,
                        readOnly: this.disableEmailAddress,
                        format: 'email',
                        title: 'Email address',
                        description: 'Email address',
                        hint: 'Emails cannot be edited',
                        minLength: 1,
                    },
                    role: roleProperty,
                },
                required: [ 'name', 'email', 'role' ],
                propertiesOrder: [ 'email', 'name', 'role' ],
            });
        }

        @Watch('value', { immediate: true })
        private updateValueExternally(): void {
            const nextInputValue = this.form.getValue({
                name: '',
                email: '',
                role: this.defaultRole,
                ...this.value,
            }, true);

            // Do not trigger watcher if not necessary
            if (!isEqual(this.inputValue, nextInputValue)) {
                this.inputValue = nextInputValue;
            }
        }

        @Watch('inputValue')
        private onChange(): void {
            this.$emit('input', this.inputValue, this.submittable);
        }

        private get submittable(): boolean {
            return this.form.isValid(this.value, true);
        }

        private onCloseRequest(): void {
            this.$emit('close');
        }

        private onSubmit(): void {
            if (this.submittable) {
                this.$emit('submit', this.value);
            }
        }
    }
</script>
