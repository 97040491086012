import range from 'lodash/range';
import {
    JsonSchemaArray,
    JsonSchemaEnum,
    JsonSchemaObject,
    JsonSchemaString,
    JsonSchemaType,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import timezones from '@evidentid/common-datasets/timezones.json';
import { get12HourTimeLabel } from '@/utils/get12HourTimeLabel';

interface Timezone {
    name: string;
    city: string;
}

const hours = range(0, 24);
const minutes = range(0, 60, 15);

export function buildSupportContactDetailsSchema(): JsonSchemaObject {
    const supportUrlSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'url',
        title: 'Support link',
        description: 'Enter link',
    };

    const supportEmailSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'email',
        title: 'Support email',
        description: 'Enter email',
    };

    const supportUrlTitleSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        title: 'Support link title',
        description: 'Enter title',
    };

    const supportPhoneSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        title: 'Support phone number',
        description: 'Enter number',
    };

    const supportDaysOfWeekSchema: JsonSchemaArray<JsonSchemaEnum> = {
        type: JsonSchemaType.array,
        title: 'Support days of the week',
        minItems: 0,
        maxItems: 7,
        items: {
            enum: range(1, 8),
            enumLabels: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
        },
    };

    const hours24Format = hours
        .flatMap((hour) => minutes.map((minute) => `${hour}:${`${minute}`.padStart(2, '0')}`));
    const hours12Format = hours24Format.map((time) => get12HourTimeLabel(time));

    const supportStartTimeSchema: JsonSchemaEnum = {
        title: 'Support start time',
        description: 'Select time',
        enum: hours24Format,
        enumLabels: hours12Format,
    };

    const supportEndTimeSchema: JsonSchemaEnum = {
        title: 'Support end time',
        description: 'Select time',
        enum: hours24Format,
        enumLabels: hours12Format,
    };

    const supportTimezoneSchema: JsonSchemaEnum = {
        title: 'Support timezone',
        description: 'Select timezone',
        enum: (timezones as Timezone[]).map((x) => x.city),
        enumLabels: (timezones as Timezone[]).map((x) => `${x.name} - ${x.city.replace(/_/g, ' ')}`),
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            supportUrl: supportUrlSchema,
            supportUrlTitle: supportUrlTitleSchema,
            supportEmail: supportEmailSchema,
            supportPhone: supportPhoneSchema,
            supportDaysOfWeek: supportDaysOfWeekSchema,
            supportStartTime: supportStartTimeSchema,
            supportEndTime: supportEndTimeSchema,
            supportTimezone: supportTimezoneSchema,
        },
    };
}
