import JsonSchema, { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isObject } from '@evidentid/json-schema/schemaChecks';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

function isEqualWhenCustomPropertyObject(customPropertySchema: JsonSchemaObject, schemaFilterBy: JsonSchema) {
    const typeEqual = isObject(schemaFilterBy) && customPropertySchema.type === schemaFilterBy.type;
    const displayFormatEqual = (customPropertySchema as any).displayFormat ?
        (schemaFilterBy as any).displayFormat === (customPropertySchema as any).displayFormat
        : true;
    return typeEqual && displayFormatEqual;
}

export function filterCustomPropertiesBySchema(
    customProperties: CustomProperty[],
    schemaFilterBy?: JsonSchema | null
): CustomProperty[] {
    if (!schemaFilterBy) {
        return customProperties;
    }

    return customProperties.filter(({ schema: customPropertySchema }) => {
        if (isObject(customPropertySchema)) {
            return isEqualWhenCustomPropertyObject(customPropertySchema, schemaFilterBy);
        } else if (isArray(customPropertySchema)) {
            const typeEqual = isArray(schemaFilterBy) && customPropertySchema.type === schemaFilterBy.type;
            if (!typeEqual) {
                return false;
            }
            const customPropertyItemsSchema = customPropertySchema.items as any;
            const schemaItemsFilterBy = schemaFilterBy.items as any;
            if (isObject(customPropertyItemsSchema)) {
                return isEqualWhenCustomPropertyObject(customPropertyItemsSchema, schemaItemsFilterBy);
            }
            return customPropertyItemsSchema.type === schemaItemsFilterBy.type;
        }

        return (customPropertySchema as any).type === (schemaFilterBy as any).type;
    });
}
