<template>
    <ConfirmSendEntityRequestModal
        :entities="entities"
        :saving="saving"
        @close="close"
        @confirm="sendDocumentRequest"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import ConfirmSendEntityRequestModal
        from '@/modules/entity-request/components/ConfirmSendEntityRequestModal/ConfirmSendEntityRequestModal.vue';
    import { SendRequestStatus } from '@/modules/entity-request/vuex';
    import { shouldEnableDocumentRequest } from '@/utils/should-enable-document-request/shouldEnableDocumentRequest';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { ConfirmSendEntityRequestModal },
    })

    export default class SendDocumentRequestProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        @Prop({ type: Array, default: () => [] })
        private entities!: Entity[];

        private get saving(): boolean {
            return this.sendRequestStatus.status === 'loading';
        }

        private close(): void {
            this.$store.actions.entityRequest.clearSendDocumentRequestStatus({ rpName: this.rpName });
            this.$emit('abort');
        }

        private async sendDocumentRequest() {
            const qualifiedEntities = this.entities.filter(shouldEnableDocumentRequest);
            await this.$store.actions.entityRequest.sendDocumentRequest({
                rpName: this.rpName,
                entityIds: qualifiedEntities.map((entity) => entity.id),
            });
            this.$emit('finish', this.entities);
        }

        private get sendRequestStatus(): SendRequestStatus {
            return this.$store.state.entityRequest.sendDocumentRequestStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            };
        }
    }
</script>
