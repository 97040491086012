import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import {
    ComplianceStatus,
    EvaluationResult,
    EntityRequirementDetails,
} from '@evidentid/tprm-portal-lib/models/entity-details';
import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

function getRiskProfileName(
    riskProfileId: string,
    entityRiskProfiles: EntityRiskProfile[],
): string {
    return entityRiskProfiles.find((riskProfile) => riskProfileId === riskProfile.id)?.displayName || '';
}

// eslint-disable-next-line max-params
function getFieldEvaluationResultError(
    evaluationResult: EvaluationResult,
    fieldEvaluationError: FieldEvaluationResultError,
    entityRiskProfiles: EntityRiskProfile[],
    { errorMessage, isMultiFieldCriterion }: { errorMessage: string, isMultiFieldCriterion: boolean },
    requirementDetails: EntityRequirementDetails,
): FieldEvaluationResultError {
    const evaluationError = fieldEvaluationError || {
        messages: [],
        criterionId: [],
        criterionNames: [],
        complianceStatusMetadata: [],
        evaluatedEntityId: evaluationResult.evaluatedEntityId,
        useDuringComplianceCalculation: evaluationResult.useDuringComplianceCalculation,
    };
    evaluationError.messages.push({
        message: errorMessage,
        coverageCriteriaGroupName: getRiskProfileName(
            evaluationResult.coverageCriteriaGroupId, entityRiskProfiles),
    });
    evaluationError.coverageCriteriaGroupId = evaluationResult.coverageCriteriaGroupId;
    evaluationError.criterionId.push(evaluationResult.criterionId);
    evaluationError.criterionNames.push(evaluationResult.criterionName);
    evaluationError.isMultiFieldCriterion = isMultiFieldCriterion;

    evaluationError.messages = uniqWith(evaluationError.messages, isEqual);
    evaluationError.criterionId = [ ...new Set(evaluationError.criterionId) ];
    evaluationError.criterionNames = [ ...new Set(evaluationError.criterionNames) ];
    evaluationError.coverageDetailsBelongTo = requirementDetails;
    if (evaluationResult.complianceStatusMetadata) {
        evaluationError.complianceStatusMetadata.push(evaluationResult.complianceStatusMetadata);
    }

    return evaluationError;
}

function mapErrorFromFailures({
    evaluationResult,
    evaluationErrors,
    entityRiskProfiles,
    requirementDetails,
    nonCompliantError,
}: {
    evaluationResult: EvaluationResult;
    evaluationErrors: Record<string, FieldEvaluationResultError>;
    entityRiskProfiles: EntityRiskProfile[];
    requirementDetails: EntityRequirementDetails;
    nonCompliantError?: string;
  }
): void {
    evaluationResult.failures.forEach((failure) => {
        const match = /#.*?:/.exec(failure);
        if (match && match.length > 0) {
            const key = `${match[0].slice(0, -1)}`;
            const errorMessage = nonCompliantError || failure.split(':')[1].trim();
            if (key && errorMessage) {
                evaluationErrors[key] = getFieldEvaluationResultError(
                    evaluationResult,
                    evaluationErrors[key],
                    entityRiskProfiles,
                    {
                        errorMessage,
                        isMultiFieldCriterion: false,
                    },
                    requirementDetails,
                );
            }
        }
    });
}

function addOrAdjustErrorEntry(
    evaluationResult: EvaluationResult,
    entityRiskProfiles: EntityRiskProfile[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    requirementDetails: EntityRequirementDetails,
): void {
    evaluationResult.usedFields.forEach((field) => {
        if (field) {
            evaluationErrors[field] = getFieldEvaluationResultError(
                evaluationResult,
                evaluationErrors[field],
                entityRiskProfiles,
                {
                    errorMessage: evaluationResult.nonComplianceMessage || '',
                    isMultiFieldCriterion: evaluationResult.usedFields.length > 1,
                },
                requirementDetails,
            );
        }
    });
}

function addMultiRequirementFieldEntry(
    evaluationResult: EvaluationResult,
    entityRiskProfiles: EntityRiskProfile[],
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    coverageDetailsBelongTo: EntityRequirementDetails,
): void {
    evaluationErrors[evaluationResult.criterionName] = {
        messages: [ {
            message: evaluationResult.nonComplianceMessage || '',
            coverageCriteriaGroupName: getRiskProfileName(
                evaluationResult.coverageCriteriaGroupId,
                entityRiskProfiles
            ),
        } ],
        complianceStatusMetadata: evaluationResult.complianceStatusMetadata
            ? [ evaluationResult.complianceStatusMetadata ]
            : [],
        coverageCriteriaGroupId: evaluationResult.coverageCriteriaGroupId,
        criterionId: [ evaluationResult.criterionId ],
        criterionNames: [ evaluationResult.criterionName ],
        coverageDetailsBelongTo,
        isMultiFieldCriterion: evaluationResult.usedFields.length > 1,
    };
}

export function mapEvaluationErrors(
    evaluationResults: EvaluationResult[],
    entityRiskProfiles: EntityRiskProfile[],
    requirementDetails: EntityRequirementDetails,
): Record<string, FieldEvaluationResultError> {
    // TODO[PRODUCT-19526] whole function and flow may need revisit, may seperate single field and multi into two maps
    return evaluationResults
        .filter((x) => (x.result === ComplianceStatus.nonCompliant
            || Boolean(x.complianceStatusMetadata)) && x.useDuringComplianceCalculation)
        .reduce((evaluationErrors, evaluationResult) => {
            if ((!evaluationResult.usedFields || evaluationResult.usedFields.length === 0) &&
                evaluationResult.failures.length > 0
            ) {
                mapErrorFromFailures(
                    {
                        evaluationResult,
                        evaluationErrors,
                        entityRiskProfiles,
                        requirementDetails,
                        nonCompliantError: evaluationResult.nonComplianceMessage,
                    }
                );
            } else if (evaluationResult.usedFields.length > 1) {
                addMultiRequirementFieldEntry(
                    evaluationResult, entityRiskProfiles, evaluationErrors, requirementDetails,
                );
            } else {
                addOrAdjustErrorEntry(
                    evaluationResult, entityRiskProfiles, evaluationErrors, requirementDetails,
                );
            }
            return evaluationErrors;
        }, {} as Record<string, FieldEvaluationResultError>);
}
