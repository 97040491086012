import { CollateralErrorType } from '@/modules/entity-details/models/CollateralErrorType.model';
import { CollateralEntityDisplayField } from '@/modules/entity-details/models/CollateralEntityDisplayField.model';
import { flatten } from 'lodash';

const limitRequiredErrors: CollateralErrorType[] = [];
const maxDeductibleErrors: CollateralErrorType[] = [];
const coverageTypeErrors: CollateralErrorType[] = [ CollateralErrorType.coverageTypeExpired ];
const coverageLimitErrors: CollateralErrorType[] = [ CollateralErrorType.limitInsufficient ];
const coverageLimitTypeErrors: CollateralErrorType[] = [ CollateralErrorType.limitTypeIncorrect ];
const coverageValuationErrors: CollateralErrorType[] = [ CollateralErrorType.limitValuationIncorrect ];
const coverageDeductibleErrors: CollateralErrorType[] = [
    CollateralErrorType.deductibleValueInsufficient,
    CollateralErrorType.deductiblePercentInsufficient,
];

const possibleErrorTypesPerField: Record<CollateralEntityDisplayField, CollateralErrorType[]> = {
    [CollateralEntityDisplayField.limitRequired]: limitRequiredErrors,
    [CollateralEntityDisplayField.maximumDeductible]: maxDeductibleErrors,
    [CollateralEntityDisplayField.coverageType]: coverageTypeErrors,
    [CollateralEntityDisplayField.coverageLimit]: coverageLimitErrors,
    [CollateralEntityDisplayField.coverageDeductible]: coverageDeductibleErrors,
    [CollateralEntityDisplayField.coverageLimitType]: coverageLimitTypeErrors,
    [CollateralEntityDisplayField.coverageValuation]: coverageValuationErrors,
};

const collateralShortErrors: Record<CollateralErrorType, string> = {
    [CollateralErrorType.limitInsufficient]: 'Coverage limit did not meet requirements.',
    [CollateralErrorType.limitTypeIncorrect]: 'Limit type not accepted.',
    [CollateralErrorType.limitValuationIncorrect]: 'Valuation did not meet specific requirements.',
    [CollateralErrorType.deductibleValueInsufficient]: 'Coverage deductible did not meet value requirements.',
    [CollateralErrorType.deductiblePercentInsufficient]: 'Coverage deductible did not meet percentage requirements.',
    [CollateralErrorType.coverageTypeExpired]: 'Coverage type is expired.',
    [CollateralErrorType.collateralNotProvided]: 'Collateral was not provided.',
};

const backwardCompatibilityLimitEvaluationErrorRegex = 'The accepted valuation\\(s\\) for this collateral are .*\\.';

const collateralNonCompliantErrorRegex: Record<CollateralErrorType, string> = {
    [CollateralErrorType.limitInsufficient]:
        'The coverage limit for this collateral must be greater than or equal to \\$?\\d+(\\,{1}\\d+)*\\.',
    [CollateralErrorType.limitTypeIncorrect]:
        'The accepted type\\(s\\) for this collateral\'s coverage limit include: .+\\.',
    [CollateralErrorType.limitValuationIncorrect]: `(${backwardCompatibilityLimitEvaluationErrorRegex})|(Valuation must be .+\\.)|(Or, valuation must be .+\\.)`,
    [CollateralErrorType.deductibleValueInsufficient]:
        'The deductible for this collateral must be less than or equal to \\$?\\d+(\\,{1}\\d+)*\\.',
    [CollateralErrorType.deductiblePercentInsufficient]:
        'The deductible for this collateral must not exceed \\d{1,3}% of its required limit\\.',
    [CollateralErrorType.coverageTypeExpired]: 'The policy covering this collateral has expired\\.',
    [CollateralErrorType.collateralNotProvided]: 'The collateral was not provided in your policy\\.',
};

export function getCollateralErrorTypes(nonCompliantError: string): CollateralErrorType[] {
    return Object.entries(collateralNonCompliantErrorRegex)
        .map(([ k, v ]) => (nonCompliantError.search(new RegExp(v)) >= 0 ? k : null))
        .filter(Boolean) as CollateralErrorType [];
}

export function getCollateralShortError(errorType: CollateralErrorType): string {
    return collateralShortErrors[errorType];
}

export function getPossibleErrorTypes(field: CollateralEntityDisplayField): CollateralErrorType[] {
    return possibleErrorTypesPerField[field] || [];
}

export function getTypesFromErrors(messages: string[]): CollateralErrorType[] {
    // watch out the order of the found types are based on order of collateralNonCompliantErrorRegex keys, not msg list
    const foundTypes = flatten(messages.map(getCollateralErrorTypes)).filter(Boolean) as CollateralErrorType[];
    return [ ...new Set(foundTypes) ];
}
