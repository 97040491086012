<template>
    <div class="LogoLoader">
        <img alt="Evident ID" :src="logoUrl">
        <LineLoader />
    </div>
</template>

<style lang="scss">
    .LogoLoader {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            height: 4em;
        }

        .LineLoader {
            margin-top: 2em;
        }
    }
</style>

<script lang="ts">
    import {
        PartnersSettingsService,
    } from '@evidentid/iam-client/services/partners-settings-service/partnersSettingsService';
    import { Component, Vue } from 'vue-property-decorator';
    import evidentIcon from '@evidentid/assets-bridge/evident-icon.svg';
    import { LineLoader } from '../LineLoader';

    @Component({
        components: { LineLoader },
    })
    export default class LogoLoader extends Vue {
        private logoUrl = evidentIcon;
        private beforeCreate(): void {
            PartnersSettingsService.fetchPartnerSettings()
                .then((response) => {
                    if (response?.loading_animation_url) {
                        this.logoUrl = response.loading_animation_url;
                    }
                });
        }
    }
</script>
