<template>
    <div class="RichTextEditor" :id="elementId" :class="{
        'RichTextEditor--disabled': disabled,
    }">
        <QuillEditor
            ref="editor"
            :options="options"
            :value="inputValue"
            @input="onInput"
            @change="onChange"
            v-on="{ ...$listeners, change: () => {}, input: () => {} }"
        />
    </div>
</template>

<style lang="scss">
    @import "~quill/dist/quill.snow.css";
    @import "~quill/dist/quill.core.css";
    @import "~quill-mention/dist/quill.mention.min.css";

    // stylelint-disable selector-max-combinators, selector-max-compound-selectors, rule-empty-line-before
    .RichTextEditor {
        display: flex;

        .quill-editor {
            display: flex;
            flex-direction: column;
            width: 100%;

            .ql-editor {
                overflow: hidden;

                h1, h2, h3, h4, h5, h6 {
                    font-family: inherit;
                    font-weight: bold;
                    margin: 15px 0;
                }

                p {
                    margin: 10px 0;
                }

                .mention {
                    position: relative;
                    display: inline-block;
                    white-space: nowrap;
                    user-select: all;
                    padding: 1.5px 4px;
                    width: auto;
                    height: auto;

                    // Hack: ensure that copied element will not break background styles
                    background: rgba(#fff, 0.001);
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(#2ab496, 0.3);
                        pointer-events: none;
                    }
                    // End of hack

                    > span {
                        margin: 0;
                        border-radius: 0;
                    }
                }

                // Hack: allow simulating <br>s inside of the container
                // stylelint-disable-next-line selector-type-no-unknown
                sbr {
                    white-space: pre;
                    user-select: all;
                }
            }
        }

        &--disabled {
            filter: grayscale(1);
            opacity: 0.4;
        }
    }

    .ql-mention-list-container {
        max-height: 120px;
        border-color: mix(#2ab496, #fff, 15%);

        &::-webkit-scrollbar {
            width: 4px;
            background: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background: #bbb;
        }
    }

    .ql-mention-list-item {
        font-size: 0.8rem;
        line-height: 1.5em;
        padding: 4px 7px;
        border-bottom: 1px solid mix(#2ab496, #fff, 15%);

        &.selected {
            background: mix(#2ab496, #fff, 50%);
        }

        &:before {
            content: "{{";
        }

        &:last-child {
            border-bottom: 0;
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
    import { quillEditor as QuillEditor, Quill } from 'vue-quill-editor';
    import 'quill-mention';
    import IndentStyle from './IndentAttributor';
    import SmartBreaker from './SmartBreaker';
    import {
        buildOutputHtml,
        buildQuillHtml,
        getMatchingVariables,
        onQuillUpdateReady,
        rememberQuillPosition,
    } from './utils';
    import { SearchItem } from './types';

    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    Quill.register(IndentStyle, true);
    Quill.register('modules/smart-breaker', SmartBreaker);

    @Component({
        components: { QuillEditor },
    })
    export default class RichTextEditor extends Vue {
        @Prop({ type: String, default: 'Enter text here' })
        private placeholder!: string;

        @Prop({ type: String, default: '' })
        private value!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Array, default: () => [] })
        private variables!: string[];

        @Prop({ type: Boolean })
        private allowLinks!: boolean;

        @Prop({ type: String, default: null })
        private id!: string | null;

        private uuid: string = `rt_${Math.round(Math.random() * 1e6)}_${Date.now()}`;
        private inputValue: string = this.value;

        @Ref()
        private readonly editor!: QuillEditor;

        private get elementId(): string {
            return this.id || this.uuid;
        }

        private options: any = {
            debug: 'warn',
            modules: {
                mention: {
                    allowedChars: /^[a-zA-Z0-9 _.:-]*}?$/,
                    mentionDenotationChars: [ '{{' ],
                    source: (query: string, render: (list: SearchItem[], query: string) => void) => {
                        render(getMatchingVariables(this.variables, query), query);
                    },
                },
                toolbar: [
                    [ 'bold', 'italic', 'underline', 'strike', this.allowLinks ? 'link' : null ].filter(Boolean),
                    [ { header: 1 }, { header: 2 } ],
                    [ { list: 'ordered' }, { list: 'bullet' } ],
                    [ { indent: '-1' }, { indent: '+1' } ],
                    [ { header: [ 1, 2, 3, 4, 5, 6, false ] } ],
                    [ { color: [] }, { background: [] } ],
                    [ { align: [] } ],
                    [ 'clean' ],
                ],
                'smart-breaker': true,
                clipboard: {
                    matchVisual: false,
                },
            },
            readOnly: false,
            theme: 'snow',
            bounds: `#${this.elementId}`,
            placeholder: '',
        };

        public getValue(): string {
            return (this.editor?.quill?.getText() || this.value || '').trim();
        }

        @Watch('value', { immediate: true })
        @Watch('variables')
        private onValueChange(): void {
            const quill = this.editor?.quill;
            const html = buildQuillHtml(this.value, this.variables).html;
            if (html === this.inputValue) {
                return;
            }
            this.inputValue = html;
            if (quill) {
                const retainPosition = rememberQuillPosition(quill);
                const unsubscribe = onQuillUpdateReady(quill, () => {
                    retainPosition();
                    unsubscribe();
                });
            }
        }

        public isEmpty(): boolean {
            return this.getValue() === '';
        }

        @Watch('placeholder', { immediate: true })
        private onPlaceholderChange(): void {
            this.options = { ...this.options, placeholder: this.placeholder };
            // @see: https://github.com/quilljs/quill/issues/1150
            if (this.editor?.quill) {
                this.quill.root.dataset.placeholder = this.options.placeholder;
            }
        }

        private onInput(value: string): void {
            const { html } = buildOutputHtml(value);
            if (html !== this.value) {
                this.$emit('input', html);
            }
        }

        private onChange(value: { quill: Quill, html: string, text: string }): void {
            const result = buildOutputHtml(value.html);
            if (result.html !== this.value) {
                this.$emit('change', { ...value, ...result });
            }
        }

        private get quill(): Quill {
            return this.editor.quill;
        }

        private mounted(): void {
            // @ts-ignore: accessing Quill internals, to fix bug that Ctrl+Z is getting back to empty content
            this.quill.history.clear();
            this.updateDisabilityStatus();
        }

        @Watch('disabled')
        private updateDisabilityStatus(): void {
            if (this.disabled) {
                this.quill?.disable();
            } else {
                this.quill?.enable();
            }
        }
    }
</script>
