<template>
    <Form ref="formElement" :simulated="simulated" :class="finalClassName" @submit="onSubmit">
        <JsonSchemaFormElement
            :id="form.key"
            :form="form"
            :value="currentValue"
            :disabled="disabled"
            :touched="touched"
            :strings="strings"
            :custom-component-input="customComponentInput"
            v-on="$listeners"
        />

        <template #buttons>
            <slot name="buttons" />
        </template>

        <!-- Allow submission with Enter key -->
        <button type="submit" style="display: none" />
    </Form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
    import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
    import { Form } from '../Form';
    import JsonSchemaFormElement from './JsonSchemaFormElement.vue';
    import { JsonSchemaFormCustomComponentInput, JsonSchemaFormStrings } from './types';
    import { PropType } from 'vue';

    @Component({
        components: { Form, JsonSchemaFormElement },
    })
    export default class JsonSchemaForm extends Vue {
        @Prop(Object)
        private form!: JsonForm;

        @Prop({ type: String, default: '' })
        private className!: string;

        @Prop()
        private value!: any;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private required!: boolean;

        @Prop({ type: Boolean, default: false })
        private simulated!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private strings!: Partial<JsonSchemaFormStrings>;

        @Prop({ type: [ Object, Function ] as PropType<JsonSchemaFormCustomComponentInput | null> })
        private customComponentInput?: JsonSchemaFormCustomComponentInput | null;

        @Ref()
        private formElement!: Form;

        private currentValue!: any;
        private touched = false;
        private isMounted = false;

        private mounted(): void {
            this.isMounted = true;
        }

        private destroyed(): void {
            this.isMounted = false;
        }

        private get finalClassName(): string {
            return `JsonSchemaForm ${this.className}`;
        }

        @Watch('value', { immediate: true })
        private updateCurrentValue(value: any) {
            this.currentValue = this.form.getValue(value, true);
        }

        private onSubmit(event: Event): void {
            event.preventDefault();
            if (!this.disabled) {
                this.touch();
                this.$emit('submit');
            }
        }

        private scrollToError(): void {
            if (!this.isMounted) {
                return;
            }
            const element = this.formElement?.dom?.querySelector('.FormInput--error');
            if (!element) {
                return;
            }
            if (element.scrollIntoView) {
                element.scrollIntoView({ block: 'end' });
            }
            const innerElement = element.querySelector('input') as HTMLElement | null;
            if (innerElement) {
                innerElement.focus();
            }
        }

        public touch(): void {
            this.touched = true;
        }

        /**
         * Component public method for the usage by ref too.
         */
        public scrollToFirstError(delay = true): void {
            if (delay) {
                requestAnimationFrame(() => this.scrollToError());
            } else {
                this.scrollToError();
            }
        }
    }
</script>
