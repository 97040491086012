<template>
    <div class="DaysInput" :class="className">
        <label
            v-for="day in maximum"
            :key="day"
            class="DaysInput__day"
            :class="{ 'DaysInput__day--selected': currentValue.includes(day) }"
        >
            <input
                type="checkbox"
                :disabled="disabled"
                :checked="true"
                :value="day"
                @input="onChange"
            >
            <template v-if="backwards">
                {{ maximum - day + 1 }}
            </template>
            <template v-else>
                {{ day }}
            </template>
        </label>
    </div>
</template>

<style lang="scss">
    .DaysInput {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        &__day {
            border-radius: 50%;
            font-size: 1.25em;
            width: 1.75em;
            height: 1.75em;
            line-height: 1.75em;
            text-align: center;
            margin-left: 5px;
            color: #778ea1;
            background: #f0f2f4;
            cursor: pointer;
            user-select: none;

            > input {
                display: none;
            }

            &--selected {
                background: #2ab496;
                color: #fff;
            }
        }

        &--disabled &__day {
            cursor: not-allowed;
        }

        &--readonly &__day {
            cursor: default;
        }

        &--disabled &__day--selected {
            background: #6f6f6f;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import sortBy from 'lodash/sortBy';
    import identity from 'lodash/identity';

    @Component({
        inheritAttrs: false,
    })
    export default class DaysInput extends Vue {
        @Prop({ type: Array, default: () => [] })
        private value!: number[];

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly!: boolean;

        @Prop({ type: Number })
        private maximum!: number;

        @Prop({ type: Boolean, default: false })
        private backwards!: number;

        private currentValue: number[] = [];

        @Watch('value', { immediate: true })
        private updateCurrentValue() {
            this.currentValue = this.value;
        }

        private get className() {
            return [
                this.currentValue ? 'DaysInput--selected' : '',
                this.disabled ? 'DaysInput--disabled' : '',
                this.readonly ? 'DaysInput--readonly' : '',
                this.$attrs.class,
            ].filter(Boolean);
        }

        private onChange(event: Event) {
            if (this.readonly) {
                return;
            }
            const checkbox = event.target as HTMLInputElement;
            const selectedDay = parseInt(checkbox.value, 10);
            const wasSelected = this.currentValue.includes(selectedDay);
            const currentValue = this.currentValue.filter((day) => (typeof day === 'number'));

            if (wasSelected) {
                this.currentValue = currentValue.filter((day) => day !== selectedDay);
            } else {
                this.currentValue = sortBy(currentValue.concat(selectedDay), identity);
            }
            this.$emit('input', this.currentValue);
        }
    }
</script>
