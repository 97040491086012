import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    Criterion,
    CriterionInput,
    CriterionTemplate,
} from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

export const isSpecialConditionSetsCriterion = (
    criterion: Criterion | CriterionInput
): boolean => criterion.coverageType === TprmRequirementType.commercialGeneralLiability &&
     criterion.field === 'coverage.generalAggregateLimit' &&
     Boolean(criterion.evaluator.references.conditionSets);

export const isInformationBannerVisible = (
    criterion: Criterion | CriterionInput,
    template: CriterionTemplate
): boolean => Boolean(template.displayMetadata.informationText) || isSpecialConditionSetsCriterion(criterion);
