<template>
    <SettingsPage
        title="Support Contact Information"
        :schema="schema"
        :initial-value="currentSettings"
        :loading="loading"
        :updating="updating"
        :validate-support-time="validateSupportTime"
        @save="save"
    >
        <template #preview="{ value }">
            <h3>Email Preview</h3>

            <ActionEmailPreview :value="value" :defaults="currentSettings" />

            <div class="SettingsPage__note">
                Note: The text displayed in the email above is for preview purposes only.
                The actual text used in your email is controlled on a per-template basis.
            </div>
        </template>

        <template #default="{ fields }">
            <h3>Support Contact Details</h3>
            <h5 class="mt-0">
                Add support contact information below to customize notifications and display on the Evident Network where your entities complete their requirements.
            </h5>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.supportUrl" />
                <JsonSchemaCustomFormElement :field="fields.supportEmail" />
            </div>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.supportUrlTitle" />
                <JsonSchemaCustomFormElement :field="fields.supportPhone" />
            </div>

            <hr>

            <h3>Support Availability</h3>

            <div class="SettingsPage__formRow">
                <div class="SettingsPage__formRowDescription">
                    Select days of the week
                </div>
                <div>
                    <FormElement>
                        <FormInput>
                            <DayOfWeekInput
                                v-model="fields.supportDaysOfWeek.value"
                                :disabled="fields.supportDaysOfWeek.disabled"
                                @input="fields.supportDaysOfWeek.touch()"
                            />
                        </FormInput>

                        <InputZoneSelector show error>
                            <JsonSchemaCustomFormElement :field="fields.supportDaysOfWeek" />
                        </InputZoneSelector>
                    </FormElement>
                </div>
            </div>

            <template v-if="fields.supportDaysOfWeek.value.length > 0">
                <div class="SettingsPage__formRow">
                    <div class="SettingsPage__formRowDescription">
                        Select time range
                    </div>
                    <InputZoneSelector hide label clear-button>
                        <JsonSchemaCustomFormElement
                            :field="fields.supportStartTime"
                        />
                    </InputZoneSelector>
                    <span class="SettingsPage__formRowSeparator" />
                    <InputZoneSelector hide label clear-button>
                        <JsonSchemaCustomFormElement
                            :field="fields.supportEndTime"
                        />
                    </InputZoneSelector>
                </div>

                <div class="SettingsPage__formRow">
                    <div class="SettingsPage__formRowDescription">
                        Select time zone
                    </div>
                    <InputZoneSelector hide label clear-button>
                        <JsonSchemaCustomFormElement :field="fields.supportTimezone" />
                    </InputZoneSelector>
                </div>
            </template>
        </template>
    </SettingsPage>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { JsonSchemaCustomFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { DayOfWeekInput } from '@evidentid/dashboard-commons/components/DayOfWeekInput';
    import { FormInput, FormElement } from '@evidentid/dashboard-commons/components/Form';
    import InputZoneSelector from '../../notifications-configuration/components/InputZoneSelector.vue';
    import { buildSupportContactDetailsSchema } from '../schemas/buildSupportContactDetailsSchema';
    import ActionEmailPreview from '../components/ActionEmailPreview.vue';
    import SettingsPage from '../components/SettingsPage.vue';
    import {
        SupportContactConfiguration,
        NotificationsConfiguration,
        NotificationsConfigurationState,
    } from '../types';
    import { RelyingPartySignature } from '@evidentid/rpweb-api-client/models';

    @Component({
        components: {
            SettingsPage,
            ActionEmailPreview,
            JsonSchemaCustomFormElement,
            DayOfWeekInput,
            InputZoneSelector,
            FormElement,
            FormInput,
        },
    })
    export default class SupportContactInformation extends Vue {
        private DayOfWeekInput = DayOfWeekInput;

        private schema = buildSupportContactDetailsSchema();

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get currentRp(): RelyingPartySignature | null {
            return this.$store.state.user.relyingParties?.find(({ name }) => (name === this.rpName)) || null;
        }

        private get externalConfigurationEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get state(): NotificationsConfigurationState {
            return this.$store.state.notificationsConfiguration;
        }

        private get status(): OperationStatus {
            return this.state.loadSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get loading(): boolean {
            return ![ OperationStatus.success, OperationStatus.error ].includes(this.status);
        }

        private get updateStatus(): OperationStatus {
            return this.state.updateSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get updating(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private get currentSettings(): NotificationsConfiguration | null {
            return this.state.settings[this.rpName] || null;
        }

        private async save(settings: SupportContactConfiguration): Promise<void> {
            await this.$store.actions.notificationsConfiguration.updateSettings({
                rpName: this.rpName,
                settings,
            });

            if (this.updateStatus === OperationStatus.success) {
                this.$store.actions.snackbar.displaySnackbar({
                    message: 'Contact information updated successfully.',
                    success: true,
                });
            }
        }

        @Watch('status', { immediate: true })
        private loadSettings(): void {
            if (this.externalConfigurationEnabled && this.status === OperationStatus.uninitialized) {
                this.$store.actions.notificationsConfiguration.loadSettings({ rpName: this.rpName });
            }
        }

        @Watch('externalConfigurationEnabled', { immediate: true })
        private onExternalConfigurationStatus(): void {
            if (!this.externalConfigurationEnabled && this.$store.state.user?.data) {
                this.$router.replace({ name: 'error', params: { reason: 'page-not-found' } });
            }
        }

        private validateSupportTime(value: SupportContactConfiguration): boolean {
            const { supportDaysOfWeek, supportEndTime, supportStartTime } = value || {};

            if (!supportDaysOfWeek?.length || !supportEndTime || !supportStartTime) {
                return true;
            }

            const [ endTime24Hours, endTimeMinutes ] = supportEndTime.split(':').map((time) => parseInt(time, 10));
            const [ startTime24Hours, startTimeMinutes ] = supportStartTime.split(':').map((time) => parseInt(time, 10));

            if (startTime24Hours > endTime24Hours) {
                return false;
            }

            return !(startTime24Hours === endTime24Hours && startTimeMinutes >= endTimeMinutes);
        }
    }
</script>
