const defaultLocale = 'en-US';
const defaultOptions = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
};

export function currencyFormatter(
    currency?: string, locale?: string, extraOptions?: Intl.NumberFormatOptions,
): Intl.NumberFormat {
    return Intl.NumberFormat(
        locale || defaultLocale, {
            ...defaultOptions,
            ...extraOptions,
            currency: currency || defaultOptions.currency,
        });
}
