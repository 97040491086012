<template>
    <div class="CreateEditRiskProfile">
        <RiskProfileHeader
            :risk-profile-name="localProfile.displayName"
            :country-code="localProfile.countryCode"
            :risk-profile-active="localProfile.active"
            :risk-profile-type="riskProfileType"
            :save-disabled="saveDisabled"
            @input="onHeaderInput"
            @back="onBackAction"
            @save="attemptSave"
        >
            <template #headerMiddle>
                <Alert v-if="headerAlertData.text" type="success">
                    <EidIcon v-if="headerAlertData.icon" :icon-src="headerAlertData.icon" />
                    {{ headerAlertData.text }}
                </Alert>
            </template>
        </RiskProfileHeader>
        <div class="CreateEditRiskProfile__content">
            <div class="CreateEditRiskProfile__requirementTypeColumn">
                <RequirementTypeList
                    :requirement-types="requirementTypesWithTemplates"
                    :selected-requirement-type="currentRequirementType"
                    :criteria-by-type="criteriaByRequirementType"
                    :has-deprecated-criteria="profileHasDeprecatedCriteria"
                    :has-deprecated-criteria-by-requirement-type="hasDeprecatedCriteriaByRequirementType"
                    :get-requirement-type-label="getRequirementTypeLabel"
                    :has-evaluation-rule="isEvaluationRuleRequirementTypesSelected"
                    :is-requirement-type-in-evaluation-rule="isRequirementTypeInEvaluationRule"
                    :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
                    :invalid-references="invalidReferences"
                    :disable-evaluation-rule="disableEvaluationRule"
                    @add="addRequirementType"
                    @delete="tryDeleteRequirementType"
                    @select="selectRequirementType"
                    @toggle-evaluation-rule-modal="toggleEvaluationRuleModal"
                />
            </div>
            <RiskProfileAssignment
                :risk-profile-assignment="assignmentRule"
                :custom-properties="customProperties"
                :assignment-option="assignmentOption"
                :show-error="startShowingAssignmentRuleError"
                @setAssignmentRuleOption="setAssignmentRuleOption"
                @remove="removeAssignmentRule"
                @openRuleEditor="openEditProfileAssignmentRulePanel"
            />
        </div>
        <RiskProfileAssignmentRulePanel
            v-if="isEditProfileAssignmentRulePanelOpen"
            :rules="basicAssignmentRules"
            :custom-properties-status="customPropertiesStatus"
            :loading-custom-properties="loadingCustomProperties"
            :collaterals-only="isCollateralProfile"
            @close="finishEditProfileAssignmentProcedure"
            @update-rules="updateRules"
        />
        <RiskProfileCriteriaPanel
            v-if="currentRequirementType"
            :requirement-type="currentRequirementType"
            :requirement-models="requirementModels"
            :criteria-templates="currentCriteriaTemplates"
            :criteria="currentCriteria"
            :custom-properties="customProperties"
            :accessed-reference-map="accessedMap"
            :has-deprecated-criteria="hasDeprecatedCriteriaByRequirementType(currentRequirementType)"
            :get-requirement-type-label="getRequirementTypeLabel"
            :evaluation-rule-requirement-types="evaluationRuleRequirementTypes"
            :is-reference-starts-with-is-invalid="isReferenceStartsWithIsInvalid"
            :invalid-references="invalidReferences"
            :country-code="riskProfile.countryCode"
            @close="deselectRequirementType"
            @input="updateCriteria"
            @reference-accessed="onReferenceAccessed"
            @validityChange="onValidityChanged"
        />
        <EvaluationRuleModal
            v-if="showEvaluationRuleModal"
            :evaluation-rule-requirement-types="evaluationRuleRequirementTypes"
            :get-requirement-type-label="getRequirementTypeLabel"
            :criteria-by-requirement-type="criteriaByRequirementType"
            :is-evaluation-rule-requirement-types-selected="isEvaluationRuleRequirementTypesSelected"
            @save="onSaveEvaluationRule"
            @delete="showEvaluationRuleConfirmDeleteModal"
            @close="toggleEvaluationRuleModal"
        />
        <ConfirmationModal
            v-if="confirmationModalData.text"
            additional-class-name="CreateEditRiskProfile__confirmation-modal"
            size="optimal"
            theme="default"
            yes="Delete"
            no="Cancel"
            :yes-icon="null"
            :no-icon="null"
            :destructive="true"
            @input="onConfirmDelete"
        >
            <template #header>
                Are you sure?
            </template>
            {{ confirmationModalData.text }}
        </ConfirmationModal>

        <RiskProfileChangesWarningModal
            v-if="showRiskProfileChangesWarningModal"
            :change-details="changeDetails"
            @revert="revertRiskProfileChanges"
            @save="confirmSave"
        />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { flatten, intersectionWith, isEqual, omit, omitBy, startCase } from 'lodash';
    import differenceWith from 'lodash/differenceWith';
    import difference from 'lodash/difference';
    import orderBy from 'lodash/orderBy';
    import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import Alert from '@evidentid/dashboard-commons/components/Alert/Alert.vue';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import RiskProfileHeader
        from '@/modules/decisioning-criteria/components/RiskProfileHeader/RiskProfileHeader.vue';
    import RiskProfileAssignmentRulePanel
        from '@/modules/decisioning-criteria/components/RiskProfileAssignmentRulePanel/RiskProfileAssignmentRulePanel.vue';
    import RiskProfileCriteriaPanel
        from '@/modules/decisioning-criteria/components/RiskProfileCriteriaPanel/RiskProfileCriteriaPanel.vue';
    import RequirementTypeList from '@/modules/decisioning-criteria/components/RequirementTypeList/RequirementTypeList.vue';
    import { CustomPropertiesListStatus } from '@/modules/decisioning-criteria/vuex';
    import RiskProfileAssignment from '@/modules/decisioning-criteria/components/RiskProfileAssignment/RiskProfileAssignment.vue';
    import { BasicAssignmentRule, RiskProfileAssignmentOptions } from '@/modules/decisioning-criteria/types';
    import { groupCriteriaByType } from '@/modules/decisioning-criteria/utils/riskProfileCriteria';
    import {
        convertToBasicAssignmentRules,
        encodeAssignmentRules,
    } from '@/modules/decisioning-criteria/utils/parseRulesLogic';
    import { CriterionBases } from '@/modules/decisioning-criteria/models/CriterionBases.model';
    import RiskProfileChangesWarningModal
        from '@/modules/decisioning-criteria/components/RiskProfileChangesWarningModal/RiskProfileChangesWarningModal.vue';
    import { getRequirementTypeIcon } from '@/modules/entity-details/utils/getRequirementTypeIcon';
    import {
        RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID,
        isRiskProfileAutoAddUmbrellaDisabled,
        startDisablingRiskProfileAutoAddUmbrella,
        purgeRiskProfileAutoAddUmbrellaPendingItems,
    } from '@/modules/decisioning-criteria/utils/autoAddUmbrella';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        Criterion, CriterionBase,
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
    import {
        RiskProfile,
        RiskProfileInput,
        RiskProfileType,
    } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    const EvaluationRuleModal = () => import('@/modules/decisioning-criteria/components/EvaluationRuleModal/EvaluationRuleModal.vue');

    type RequirementTypesWithDeprecatedCriteria = Partial<Record<TprmRequirementType, boolean>>;
    type RequirementTypeCriteriaMap = Partial<Record<TprmRequirementType, CriterionBase[]>>;

    interface ConfirmationModalData {
        text?: string;
        confirmDeleteFor?: 'coverageType' | 'evaluationRule';
        coverageType?: TprmRequirementType;
        isCoverageTypeInEvaluationRule?: boolean;
    }

    interface HeaderAlertData {
        text?: string;
        icon?: any;
    }

    function extractRequirementTypeFromCombinedReference(combinedRef: string): TprmRequirementType | null {
        return combinedRef.split('-')[0] as TprmRequirementType || null;
    }

    function isCriteriaEqual(a: CriterionBase, b: CriterionBase) {
        return a.coverageType === b.coverageType && a.field === b.field;
    }

    @Component({
        components: {
            Alert,
            Button,
            ConfirmationModal,
            Dropdown,
            EidIcon,
            EvaluationRuleModal,
            Expandable,
            FormElement,
            FormInput,
            Menu,
            MenuLink,
            RequirementTypeList,
            RiskProfileAssignment,
            RiskProfileAssignmentRulePanel,
            RiskProfileChangesWarningModal,
            RiskProfileCriteriaPanel,
            RiskProfileHeader,
        },
    })
    export default class CreateEditRiskProfile extends Vue {
        @Prop({ type: Object, default: null })
        private riskProfile!: RiskProfile | RiskProfileInput;

        @Prop({ type: Array as PropType<TprmRequirementType[]>, default: () => [] })
        private requirementTypes!: TprmRequirementType[];

        @Prop({ type: Array, default: () => [] })
        private requirementModels!: TprmRequirementModel[];

        @Prop({ type: Array, default: () => [] })
        private criteriaTemplates!: CriterionTemplate[];

        @Prop({ type: Array, default: () => [] })
        private criteria!: Criterion[];

        @Prop({ type: Array, default: () => [] })
        private originalCriteria!: Criterion[];

        @Prop({ type: String, default: null })
        private currentRequirementType!: TprmRequirementType | null;

        @Prop({ type: Object as PropType<CustomPropertiesListStatus>, required: true })
        private customPropertiesStatus!: CustomPropertiesListStatus;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private loadingCustomProperties!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private loadingRequirementTypesAndCountries!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private collateralEnabled!: boolean;

        private invalidReferences: string[] = [];
        private accessedMap: Record<string, boolean> = {};
        private criteriaTemplatesByRequirementType: Record<string, CriterionTemplate[]> =
            this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);

        private criteriaByRequirementType: RequirementTypeCriteriaMap =
            groupCriteriaByType(this.criteria);

        private originalCriteriaByRequirementType: RequirementTypeCriteriaMap =
            groupCriteriaByType(this.originalCriteria);

        private localProfile: RiskProfile | RiskProfileInput = { ...this.riskProfile };
        private assignmentOption: RiskProfileAssignmentOptions =
            this.localProfile.assignmentRule === true
                ? RiskProfileAssignmentOptions.allInsureds
                : RiskProfileAssignmentOptions.subsetOfInsureds;
        private startShowingAssignmentRuleError: boolean = false;
        private showEvaluationRuleModal: boolean = false;
        private confirmationModalData: ConfirmationModalData = {};
        private headerAlertData: HeaderAlertData = {};
        private faCheckCircle = faCheckCircle;
        private isEditProfileAssignmentRulePanelOpen: boolean = false;
        private changeDetails: string[] = [];
        private showRiskProfileChangesWarningModal: boolean = false;
        private saveAsActive: boolean = true;
        private canAutoAddUmbrella: boolean = true;

        private get customProperties(): CustomProperty[] {
            return this.customPropertiesStatus.list;
        }

        private get profileIdForAutoUmbrella(): string {
            return 'id' in this.localProfile ? this.localProfile.id : RISK_PROFILE_AUTO_ADD_UMBRELLA_CREATE_PROFILE_ID;
        }

        private get currentCriteriaTemplates(): CriterionTemplate[] {
            return this.currentRequirementType
                ? this.criteriaTemplatesByRequirementType[this.currentRequirementType] || []
                : [];
        }

        private get currentCriteria(): Criterion[] | CriterionInput[] {
            const criteria = this.currentRequirementType
                ? this.criteriaByRequirementType[this.currentRequirementType] || []
                : [];
            return [ ...criteria ];
        }

        private get assignmentRule() {
            return this.localProfile.assignmentRule === true ? null : this.localProfile.assignmentRule || null;
        }

        private get saveDisabled(): boolean {
            const isNotAssignmentRules = this.startShowingAssignmentRuleError &&
                (!this.localProfile.assignmentRule || this.notEmptyBasicAssignmentRules.length === 0);
            return Boolean(!this.localProfile.displayName ||
                isNotAssignmentRules ||
                this.invalidReferences.length > 0,
            );
        }

        private get requirementTypesWithDeprecatedCriteria(): RequirementTypesWithDeprecatedCriteria {
            return intersectionWith(
                this.criteriaTemplates,
                this.criteria,
                (a, b) => a.field === b.field && a.coverageType === b.coverageType,
            )
                .filter((x) => x.deprecated)
                .reduce((acc, x) => ({ ...acc, [x.coverageType]: true }), {});
        }

        private get profileHasDeprecatedCriteria(): boolean {
            return Object.keys(this.requirementTypesWithDeprecatedCriteria).length > 0;
        }

        private get requirementTypesWithTemplates(): string[] {
            // filter by this.requirementTypes due to it' a list filtered by country while templates isn't
            return Object.keys(this.criteriaTemplatesByRequirementType).filter(
                (requirementTypeWithTemplate) => this.requirementTypes.includes(
                    requirementTypeWithTemplate as TprmRequirementType,
                ),
            );
        }

        private get evaluationRuleRequirementTypes(): TprmRequirementType[] {
            return this.localProfile.evaluationRule?.coverageTypes || [];
        }

        private get isEvaluationRuleRequirementTypesSelected(): boolean {
            const [ coverageOne, coverageTwo ] = this.evaluationRuleRequirementTypes;
            return Boolean(coverageOne) && Boolean(coverageTwo);
        }

        private get basicAssignmentRules(): BasicAssignmentRule[][] {
            if (!this.localProfile.assignmentRule) {
                return [];
            }

            return convertToBasicAssignmentRules(this.localProfile.assignmentRule);
        }

        private get notEmptyBasicAssignmentRules(): BasicAssignmentRule[][] {
            return this.basicAssignmentRules
                .map((basicRule) => basicRule
                    .filter((rule) => rule.insuredFieldKey.length > 0 && rule.operator.length > 0),
                )
                .filter((basicRule) => basicRule.length > 0);
        }

        private get disableEvaluationRule(): boolean {
            return this.localProfile.ccgType === RiskProfileType.collateral;
        }

        private get riskProfileType(): RiskProfileType | null {
            return this.collateralEnabled
                ? this.localProfile.ccgType || RiskProfileType.default
                : null;
        }

        private get isCollateralProfile(): boolean {
            return this.riskProfileType === RiskProfileType.collateral;
        }

        private get baselineFields(): string[] {
            return [
                'carrier.amBestFinancialSizeCategory',
                'carrier.amBestFinancialStrengthRating',
                'carrier.naicNumber',
                'carrier.name',
                'coverage.certificateIssueDate',
                'coverage.coverageStatus',
                'coverage.endorsements.followFormBasis',
                'coverage.policiesFollowingForm',
                'coverage.producerName',
                'policy.currency',
                'policy.effectiveDate',
                'policy.expirationDate',
                'policy.insured',
                'policy.policyNumber',
            ];
        }

        private get isEditingProfile(): boolean {
            return Boolean('id' in this.localProfile && this.localProfile.id);
        }

        private get hasUmbrellaCriterion(): boolean {
            return this.criteria
                .some((criterion) => criterion.coverageType === TprmRequirementType.umbrellaExcessLiability);
        }

        private getRiskProfileChangeWarningDetails(): string[] {
            const newTypes = difference(
                Object.keys(this.criteriaByRequirementType),
                Object.keys(this.originalCriteriaByRequirementType),
            ) as TprmRequirementType[];
            const newCriteria =
                this.getNewCriteriaOfExistedRequirementTypes(newTypes, this.originalCriteria, this.criteria);
            const newCriteriaStrings = this.convertCriteriaToChangeStrings(newCriteria, this.criteriaTemplates);
            const newRequirementTypeString = newTypes.map(
                (type) => `${this.getRequirementTypeLabel(type as TprmRequirementType)} - New Requirement Added`,
            );
            return orderBy([ ...newRequirementTypeString, ...newCriteriaStrings ]);
        }

        @Watch('riskProfile')
        private onProfileChange(current: RiskProfile, previous: RiskProfile): void {
            if (!isEqual(current, previous)) {
                this.localProfile = { ...this.riskProfile };
                this.assignmentOption = this.localProfile.assignmentRule === true
                    ? RiskProfileAssignmentOptions.allInsureds
                    : RiskProfileAssignmentOptions.subsetOfInsureds;
                this.criteriaTemplatesByRequirementType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
                this.criteriaByRequirementType = groupCriteriaByType(this.criteria);
            }
        }

        @Watch('criteriaTemplates')
        private onTemplateChange(
            current: CriterionTemplate[],
            previous: CriterionTemplate[],
        ): void {
            if (!isEqual(current, previous)) {
                this.criteriaTemplatesByRequirementType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
            }
        }

        @Watch('criteria')
        private onCriteriaChange(current: Criterion[], previous: Criterion[]): void {
            if (!isEqual(current, previous)) {
                this.criteriaByRequirementType = groupCriteriaByType(this.criteria);
            }
        }

        @Watch('originalCriteria')
        private onOriginalCriteriaChange(
            current: Criterion[],
            previous: Criterion[],
        ): void {
            if (!isEqual(current, previous)) {
                this.originalCriteriaByRequirementType = groupCriteriaByType(this.originalCriteria);
            }
        }

        @Watch('requirementTypes', { immediate: true })
        private onTypesChange(): void {
            if (
                this.loadingRequirementTypesAndCountries &&
                this.currentRequirementType &&
                this.requirementTypes &&
                !this.requirementTypes.includes(this.currentRequirementType)
            ) {
                this.deselectRequirementType();
            }
        }

        @Watch('currentRequirementType', { immediate: true })
        private onCurrentRequirementTypeChange(): void {
            if (this.currentRequirementType && !this.criteriaByRequirementType[this.currentRequirementType]) {
                // FIXME: we need an error handling for this case when coming from deeplink
                this.criteriaByRequirementType[this.currentRequirementType] =
                    this.getCriteriaTemplatesForNewType(this.currentRequirementType);
            }
        }

        @Watch('collateralEnabled', { immediate: true })
        private onCollateralEnabledChange(): void {
            // update criteria due to collateral enabling flag is async by api, it should be updated when done
            this.criteriaTemplatesByRequirementType = this.groupAndFilterCriteriaTemplates(this.criteriaTemplates);
        }

        private created(): void {
            purgeRiskProfileAutoAddUmbrellaPendingItems();
            this.canAutoAddUmbrella = !isRiskProfileAutoAddUmbrellaDisabled(this.profileIdForAutoUmbrella);
        }

        private isReferenceStartsWithIsInvalid(searchString: string): boolean {
            return this.invalidReferences.some((reference) => reference.startsWith(searchString));
        }

        private onBackAction() {
            this.$router.push({
                name: 'decisioning',
                params: {
                    rpId: this.$route.params.rpId,
                },
            }).catch((err) => {
                // ignoring the navigation abort "error" as it should not be an error and should not pollute console
                if (!/Navigation aborted from .+ via a navigation guard/.test(err)) {
                    console.error(err);
                }
            });
        }

        private onHeaderInput(name: string): void {
            this.localProfile = { ...this.localProfile, displayName: name };
            this.$emit('profileInput', this.localProfile);
        }

        private setAssignmentRuleOption(assignmentRuleOption: RiskProfileAssignmentOptions): void {
            this.assignmentOption = assignmentRuleOption;
            this.localProfile = {
                ...this.localProfile,
                assignmentRule: this.assignmentOption === RiskProfileAssignmentOptions.allInsureds ? true : null,
            };
            this.$emit('profileInput', this.localProfile);
        }

        private removeAssignmentRule(): void {
            this.startShowingAssignmentRuleError = true;
            this.localProfile = { ...this.localProfile, assignmentRule: null };
            this.$emit('profileInput', this.localProfile);
        }

        private updateRules(rules: BasicAssignmentRule[][]): void {
            this.startShowingAssignmentRuleError = true;
            this.localProfile = {
                ...this.localProfile,
                assignmentRule: encodeAssignmentRules(rules, this.customProperties),
            };
        }

        private finishEditProfileAssignmentProcedure(): void {
            this.isEditProfileAssignmentRulePanelOpen = false;
            this.localProfile = {
                ...this.localProfile,
                assignmentRule: encodeAssignmentRules(this.notEmptyBasicAssignmentRules, this.customProperties),
            };
            this.$emit('profileInput', this.localProfile);
        }

        private openEditProfileAssignmentRulePanel(): void {
            this.isEditProfileAssignmentRulePanelOpen = true;
        }

        private attemptSave(data: { isActive: boolean }): void {
            if (this.isEditingProfile &&
                this.localProfile.numberOfInsuredsAssigned &&
                this.localProfile.numberOfInsuredsAssigned > 0
            ) {
                this.changeDetails = this.getRiskProfileChangeWarningDetails();
                this.saveAsActive = data.isActive;
                if (this.changeDetails.length > 0) {
                    this.showRiskProfileChangesWarningModal = true;
                } else {
                    this.saveProfile(data);
                }
            } else {
                this.saveProfile(data);
            }
        }

        private saveProfile(data: { isActive: boolean }): void {
            if (!this.localProfile.assignmentRule) {
                this.startShowingAssignmentRuleError = true;
            } else {
                this.localProfile = {
                    ...this.localProfile,
                    assignmentRule:
                        encodeAssignmentRules(this.notEmptyBasicAssignmentRules, this.customProperties) || true,
                    active: data.isActive,
                    numberOfCoverageTypes: Object.keys(this.criteriaByRequirementType).length,
                };
                const criteriaList = flatten(Object.values(this.criteriaByRequirementType));
                this.$emit('save', { riskProfile: this.localProfile, criteria: criteriaList });
            }
        }

        private selectRequirementType(requirementType: TprmRequirementType): void {
            this.$emit('selectRequirementType', requirementType);
        }

        private deselectRequirementType(currentRequirementTypeAllCriteriaNotVerify?: boolean): void {
            if (currentRequirementTypeAllCriteriaNotVerify) {
                this.deleteEvaluationRule();
            }

            this.$emit('deselectRequirementType');
        }

        private addRequirementType(type: TprmRequirementType): void {
            const criteria = this.getCriteriaTemplatesForNewType(type);
            this.criteriaByRequirementType[type] = criteria;
            this.tryAutoAddUmbrella(criteria, [], type);
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByRequirementType)));
            // workaround for the issue that looks like due to the CriteriaPanel Component gets created before
            // the click event is bubble up to body causing the clickOut event detected earlier than it should
            // which closes the panel immediately.
            setTimeout(() => {
                this.selectRequirementType(type);
            });
        }

        private getCriteriaTemplatesForNewType(type: TprmRequirementType): CriterionInput[] {
            return this.criteriaTemplatesByRequirementType[type]
                ? this.criteriaTemplatesByRequirementType[type].filter((x) => x.default).map((x) => omit(x, 'default'))
                : [];
        }

        private updateCriteria(criteria: Criterion[], requirementType: TprmRequirementType): void {
            const originCriteria = this.criteriaByRequirementType[requirementType] || [];
            this.criteriaByRequirementType = {
                ...this.criteriaByRequirementType,
                [requirementType]: criteria,
            };
            this.tryAutoAddUmbrella(criteria, originCriteria, requirementType);
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByRequirementType)));
        }

        private tryDeleteRequirementType(requirementType: TprmRequirementType) {
            if (!this.isRequirementTypeInEvaluationRule(requirementType)) {
                this.deleteRequirementType(requirementType);
                return;
            }

            this.confirmationModalData = {
                text: `This requirement is currently assigned to an Evaluation Rule.
                Entities currently assigned to this risk profile may be affected.`,
                confirmDeleteFor: 'coverageType',
                coverageType: requirementType,
                isCoverageTypeInEvaluationRule: true,
            };
        }

        private deleteRequirementType(type: TprmRequirementType): void {
            this.criteriaByRequirementType = omit(this.criteriaByRequirementType, type);
            this.clearInvalidListPerRequirementType(type);
            if (type === this.currentRequirementType) {
                this.deselectRequirementType();
            }
            if (type === TprmRequirementType.umbrellaExcessLiability) {
                startDisablingRiskProfileAutoAddUmbrella(this.profileIdForAutoUmbrella);
                this.canAutoAddUmbrella = false;
            }
            this.$emit('criteriaInput', flatten(Object.values(this.criteriaByRequirementType)));
        }

        private setCollectOnlyForCriteria(
            criteria: CriterionInput[],
        ): CriterionInput[] {
            return criteria.map((c) => ({ ...c, verify: false }));
        }

        private tryAutoAddUmbrella(
            criteria: (Criterion | CriterionInput)[],
            originalCriteria: CriterionBase[],
            requirementType: TprmRequirementType,
        ) {
            if (this.hasUmbrellaCriterion ||
                !this.canAutoAddUmbrella ||
                requirementType === TprmRequirementType.umbrellaExcessLiability) {
                return;
            }

            const criteriaWithAllowUmbrella: CriterionBase[] = criteria
                .filter((criterion) => Boolean(criterion.evaluator?.references?.allowUmbrellaExcess?.value));
            const newCriteriaWithAllowUmbrella = differenceWith(
                criteriaWithAllowUmbrella,
                originalCriteria,
                isCriteriaEqual,
            );
            const criteriaWithChangedAllowUmbrellaTrue = criteriaWithAllowUmbrella.filter(
                (criterion) => originalCriteria.find(
                    (original) =>
                        isCriteriaEqual(criterion, original) &&
                        original.evaluator?.references?.allowUmbrellaExcess?.value === false &&
                        criterion.evaluator?.references?.allowUmbrellaExcess?.value === true,
                ),
            );

            if (newCriteriaWithAllowUmbrella.length > 0 || criteriaWithChangedAllowUmbrellaTrue.length > 0) {
                const umbrellaCriteria = this.getCriteriaTemplatesForNewType(
                    TprmRequirementType.umbrellaExcessLiability,
                );
                this.criteriaByRequirementType[TprmRequirementType.umbrellaExcessLiability] =
                    this.setCollectOnlyForCriteria(umbrellaCriteria);
                this.showAlert(
                    {
                        text: 'Commercial Umbrella & Excess Policy added to riskProfile.',
                        icon: getRequirementTypeIcon(TprmRequirementType.umbrellaExcessLiability),
                    },
                    4000,
                );
            }
        }

        private onReferenceAccessed(data: { reference: string, isValid: boolean }): void {
            this.accessedMap[data.reference] = true;
            this.onValidityChanged(data);
        }

        private onValidityChanged(data: { reference: string, isValid: boolean }): void {
            const index = this.invalidReferences.indexOf(data.reference);
            const refStartsWithRegex = new RegExp(`^${data.reference}`);
            if (data.isValid) {
                this.invalidReferences = this.invalidReferences.filter((ref) => !refStartsWithRegex.test(ref));
            } else if (!data.isValid && index < 0) {
                this.invalidReferences = [ ...this.invalidReferences, data.reference ];
            }
        }

        private clearInvalidListPerRequirementType(requirementType: TprmRequirementType): void {
            this.invalidReferences = this.invalidReferences.filter((combinedReference) =>
                extractRequirementTypeFromCombinedReference(combinedReference) !== requirementType);
        }

        private hasDeprecatedCriteriaByRequirementType(requirementType: TprmRequirementType): boolean {
            return Boolean(this.requirementTypesWithDeprecatedCriteria[requirementType]);
        }

        private toggleEvaluationRuleModal() {
            this.showEvaluationRuleModal = !this.showEvaluationRuleModal;
        }

        private showAlert(data: HeaderAlertData, disappearAfterMs = 2000) {
            this.headerAlertData = data;
            window.setTimeout(() => {
                this.headerAlertData = {};
            }, disappearAfterMs);
        }

        private onSaveEvaluationRule({ requirementTypes }: { requirementTypes: TprmRequirementType[] }) {
            this.localProfile = {
                ...this.localProfile,
                evaluationRule: { coverageTypes: requirementTypes },
            };
            this.$emit('profileInput', this.localProfile);
            this.showAlert({ text: 'Evaluation rule added.', icon: faCheckCircle });
        }

        private showEvaluationRuleConfirmDeleteModal(): void {
            this.confirmationModalData = {
                text: 'Entities currently assigned to this riskProfile may be affected.',
                confirmDeleteFor: 'evaluationRule',
            };
        }

        private onConfirmDelete(
            confirmed: boolean,
        ) {
            const {
                confirmDeleteFor,
                coverageType: requirementType,
                isCoverageTypeInEvaluationRule,
            } = this.confirmationModalData;
            this.confirmationModalData = {};
            if (!confirmed) {
                return;
            }
            if (confirmDeleteFor === 'evaluationRule') {
                this.deleteEvaluationRule();
            } else {
                this.deleteRequirementType(requirementType as TprmRequirementType);
                if (isCoverageTypeInEvaluationRule) {
                    this.deleteEvaluationRule();
                }
            }
        }

        private deleteEvaluationRule(): void {
            this.localProfile = {
                ...this.localProfile,
                evaluationRule: undefined,
            };
            this.$emit('profileInput', this.localProfile);
            this.showAlert({ text: 'Evaluation rule deleted.', icon: faCheckCircle });
        }

        private getRequirementTypeLabel(requirementType: TprmRequirementType): string {
            return this.requirementModels.find((model) => model.coverageType === requirementType)?.label
                || startCase(requirementType.toLowerCase());
        }

        private isRequirementTypeInEvaluationRule(requirementType: TprmRequirementType): boolean {
            return this.evaluationRuleRequirementTypes.includes(requirementType);
        }

        private groupAndFilterCriteriaTemplates<T extends CriterionBases>(
            templates: CriterionTemplate[],
        ): Record<string, CriterionTemplate[]> {
            if (this.collateralEnabled && this.riskProfile.ccgType === RiskProfileType.collateral) {
                return omitBy(
                    groupCriteriaByType(templates),
                    (criteria) => !criteria.some((template) => template.field === 'coverage.collateral'),
                );
            }
            return groupCriteriaByType(templates.filter((template) => template.field !== 'coverage.collateral'));
        }

        private getNewCriteriaOfExistedRequirementTypes(
            newRequirementTypes: TprmRequirementType[],
            originalCriteria: Criterion[],
            currentCriteria: Criterion[],
        ): Criterion[] {
            const newCriteria = differenceWith(currentCriteria, originalCriteria, isCriteriaEqual);
            return newCriteria.filter(
                (criterion) =>
                    !newRequirementTypes.includes(criterion.coverageType) &&
                    !this.baselineFields.includes(criterion.field),
            );
        }

        private convertCriteriaToChangeStrings(
            criteria: Criterion[],
            criteriaTemplates: CriterionTemplate[],
        ): string[] {
            const uniqIdTemplateMap: Record<string, CriterionTemplate> =
                criteriaTemplates.reduce(
                    (accu, template) => ({ ...accu, [`${template.coverageType}-${template.field}`]: template }),
                    {},
                );
            return criteria.map(
                (criterion: Criterion) => {
                    const template = uniqIdTemplateMap[`${criterion.coverageType}-${criterion.field}`];
                    const requirementTypeLabel = this.getRequirementTypeLabel(criterion.coverageType);
                    return template
                        ? `${requirementTypeLabel} - ${template.displayMetadata.title} Added`
                        : `${requirementTypeLabel} - ${startCase(criterion.field.split('.').pop())} Added`;
                },
            );
        }

        private revertRiskProfileChanges(): void {
            this.showRiskProfileChangesWarningModal = false;
            this.$emit('revert-risk-profile-changes');
        }

        private confirmSave(): void {
            this.saveProfile({ isActive: this.saveAsActive });
            this.showRiskProfileChangesWarningModal = false;
        }
    }
</script>
