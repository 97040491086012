import {
    IamClient,
    IamClientAdapterName,
    IamClientAdapterOptions,
    createAdapter,
} from '@evidentid/iam-client';
import {
    PartnersSettingsService,
} from '@evidentid/iam-client/services/partners-settings-service/partnersSettingsService';
import { createModuleBuilder } from '../../app';

export interface IamConfig<K extends IamClientAdapterName> {
    name: K;
    options: IamClientAdapterOptions<K>;
}

export function createAuthConnectorModule() {
    return createModuleBuilder()
        .demandOptions<{ iamConfig: IamConfig<IamClientAdapterName> }>()
        .inject(async (app) => {
            const config = app.options.iamConfig;
            const partnerBranding = await PartnersSettingsService.fetchPartnerSettings();
            if (partnerBranding) {
                config.options = { ...config.options, ...partnerBranding };
            }
            const adapter = createAdapter(config.name, config.options);
            const auth = new IamClient(adapter);
            await auth.initialize();
            return { auth, authType: config.name };
        })
        .execute((app) => {
            app.once('afterDestroy', () => app.auth.destroy());
        })
        .end();
}

export type AuthConnectorModule = ReturnType<typeof createAuthConnectorModule>;
