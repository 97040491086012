import { JsonSchemaType, JsonSchemaBase } from '@evidentid/json-schema/interfaces/JsonSchema';

import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

export function filterCustomPropertiesByValueType(
    customProperties: CustomProperty[],
    entityValueTypeFilterBy?: JsonSchemaType | null,
): CustomProperty[] {
    return customProperties.filter((field) => {
        if (entityValueTypeFilterBy && entityValueTypeFilterBy !== (field.schema as JsonSchemaBase)?.type) {
            return false;
        }

        return true;
    });
}
