<template>
    <th
        class="TableHeader"
        :class="{
            'TableHeader--sortable': sortable,
            'TableHeader--sorted': sort,
        }"
        @click="changeSorting"
    >
        <div class="TableHeader__inner">
            <div class="TableHeader__content">
                <slot />
            </div>
            <div v-if="sortable" class="TableHeader__sorting">
                <FontAwesomeIcon :icon="sortIcon" />
            </div>
        </div>
    </th>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSort, faSortUp, faSortDown,
        faSortAlphaDownAlt, faSortAlphaDown,
        faSortAmountDownAlt, faSortAmountDown,
        faSortNumericDownAlt, faSortNumericDown,
    } from '@fortawesome/free-solid-svg-icons';
    import { SortingDirection, SortingType } from '@evidentid/universal-framework/sorting';

    const icons: Record<SortingType, Record<SortingDirection, any> & { default?: any }> = {
        [SortingType.amount]: {
            [SortingDirection.asc]: faSortAmountDownAlt,
            [SortingDirection.desc]: faSortAmountDown,
        },
        [SortingType.arrow]: {
            [SortingDirection.asc]: faSortUp,
            [SortingDirection.desc]: faSortDown,
            default: faSort,
        },
        [SortingType.alpha]: {
            [SortingDirection.asc]: faSortAlphaDown,
            [SortingDirection.desc]: faSortAlphaDownAlt,
        },
        [SortingType.numeric]: {
            [SortingDirection.asc]: faSortNumericDown,
            [SortingDirection.desc]: faSortNumericDownAlt,
        },
    };

    const sortOpposite: Record<SortingDirection, SortingDirection> = {
        [SortingDirection.desc]: SortingDirection.asc,
        [SortingDirection.asc]: SortingDirection.desc,
    };

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class TableHeader extends Vue {
        @Prop({ type: Boolean, default: false })
        private sortable!: boolean;

        @Prop({ type: String, default: null })
        private sort!: SortingDirection | null;

        @Prop({ type: String, default: SortingType.amount })
        private sortType!: SortingType;

        @Prop({ type: String, default: SortingDirection.asc })
        private sortDefault!: SortingDirection;

        private get direction() {
            return this.sort || this.sortDefault;
        }

        private get nextDirection() {
            return this.sort ? sortOpposite[this.sort] : this.sortDefault;
        }

        private get sortIcon() {
            const set = icons[this.sortType];
            return !this.sort && set.default ? set.default : set[this.direction];
        }

        private changeSorting() {
            if (this.sortable) {
                this.$emit('sort', this.nextDirection);
            }
        }
    }
</script>
