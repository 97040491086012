export function numberToString(value: number): string {
    const viewValue = `${isNaN(value) ? '' : value}`;
    const exponentialMatch = viewValue.match(/^(\d+)(?:.(\d+))?[eE]([+-]?.+)$/);
    if (exponentialMatch) {
        const [ , _base, _rest, _exp ] = exponentialMatch;
        const base = _base || '0';
        const rest = _rest || '';
        const exp = parseInt(_exp, 10);
        const value = base + rest + '0'.repeat(Math.max(exp, 0));
        const dotPosition = base.length + exp;
        return dotPosition <= 0
            ? `0.${'0'.repeat(dotPosition)}${value.replace(/0*$/, '')}`
            : `${value.substr(0, dotPosition)}.${value.substr(dotPosition)}`.replace(/\.0*$/, '');
    }
    return viewValue;
}
