export class Subscriber<T = any> {
    private listeners: ((data: T) => void)[] = [];

    public emit(value: T): void {
        // Use copy of listeners and ensure that all listeners are fired,
        // otherwise, when some listeners are unsubscribed during emit,
        // some may be omitted.
        this.listeners.slice().forEach((fn) => fn(value));
    }

    public listen(fn: (data: T) => void): () => void {
        const wrappedFn = (data: T) => fn(data);
        this.listeners.push(wrappedFn);
        return () => {
            const index = this.listeners.indexOf(wrappedFn);
            if (index !== -1) {
                this.listeners.splice(index, 1);
            }
        };
    }

    public listenOnce(fn: (data: T) => void): () => void {
        const unsubscribe = this.listen((data) => {
            unsubscribe();
            fn(data);
        });
        return unsubscribe;
    }

    public clear(): void {
        this.listeners = [];
    }
}
