<template>
    <div class="ScreenMessage" :class="additionalClassName">
        <div class="ScreenMessage__icon">
            <slot name="icon">
                <img v-if="typeof icon === 'string'" :src="icon" alt="">
                <FontAwesomeIcon v-else-if="icon" :icon="icon" />
            </slot>
        </div>
        <div class="ScreenMessage__content">
            <slot />
        </div>
        <div class="ScreenMessage__toolbar">
            <a v-if="hasLogo" href="#/" class="ScreenMessage__logo">
                <img alt="Evident ID" :src="logoUrl" />
            </a>
            <slot name="toolbar" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class ScreenMessage extends Vue {
        private logoUrl = logoUrl;

        @Prop({ type: String, default: null })
        private type!: string | null;

        @Prop({ default: null })
        private icon!: IconDefinition | string; // FontAwesome icon or image URL

        @Prop({ type: Boolean, default: false })
        private hasLogo!: boolean;

        @Prop({ type: Boolean, default: false })
        private relative!: boolean;

        private get additionalClassName(): string {
            return [
                this.type ? `ScreenMessage--${this.type}` : null,
                this.relative ? 'ScreenMessage--relative' : null,
            ].filter(Boolean).join(' ');
        }
    }
</script>
