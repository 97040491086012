<template>
    <ScreenMessage type="warning" has-user-panel>
        <template #icon>
            <FontAwesomeIcon :icon="faEnvelopeOpen" />
        </template>

        <h3>Verify your e-mail address</h3>
        <p>
            We sent you an email to verify your account.<br>
            <template v-if="canSendVerificationEmail">
                If you do not see an email, please click the button below to send a new one.
            </template>
            <template v-else>
                If you can't find it or the link has expired,
                please ask account administrator to re-send an invite.
            </template>
        </p>

        <template v-if="sending">
            <Button type="primary" loading disabled>
                Sending email...
            </Button>
        </template>
        <template v-else-if="submitted">
            <Button type="secondary" :icon="faCheck" disabled>
                Email sent!
            </Button>
        </template>
        <template v-else-if="canSendVerificationEmail">
            <Button type="primary" :icon="faSyncAlt" @click="resendMessage">
                Send new verification email
            </Button>
        </template>
    </ScreenMessage>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
    import { faSyncAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ScreenMessage from '@/components/screen-message/ScreenMessage.vue';

    @Component({
        components: { ScreenMessage, Button, FontAwesomeIcon },
    })
    export default class VerifyEmailPage extends Vue {
        private faEnvelopeOpen = faEnvelopeOpen;
        private faSyncAlt = faSyncAlt;
        private faCheck = faCheck;

        private submitted = false;

        private get canSendVerificationEmail(): boolean {
            return Boolean(this.$auth?.canSendVerificationEmail);
        }

        private get sending(): boolean {
            return this.$store.state.user.sendingEmailVerification;
        }

        private async resendMessage(event: Event): Promise<void> {
            event.preventDefault();
            await this.$store.actions.user.sendEmailVerification();
            this.submitted = true;
        }

        private async mounted() {
            await this.$store.actions.user.refreshSession();
        }
    }
</script>
