<template>
    <CommonErrorScreen :warning="warning">
        <template #toolbar>
            <ScreenToolbar :has-user-panel="hasUserPanel" />
        </template>
        <slot />
    </CommonErrorScreen>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from '@evidentid/vue-property-decorator';
    import { ErrorScreen as CommonErrorScreen } from '@evidentid/dashboard-commons/screens/ErrorScreen';
    import ScreenToolbar from '@/components/screen-toolbar/ScreenToolbar.vue';

    @Component({
        components: { ScreenToolbar, CommonErrorScreen },
    })
    export default class ErrorScreen extends Vue {
        @Prop({ type: Boolean, default: false })
        private warning!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasUserPanel!: boolean;
    }
</script>
