<template>
    <div class="MultiFieldCriterionRecords">
        <div
            class="MultiFieldCriterionRecords__record"
            :data-test-id="`MultiFieldCriterion__${name}Record`"
        >
            <span
                v-if="showIndex"
                class="MultiFieldCriterionRecords__index mr-2"
                :class="additionalIndexClass"
            >
                {{ index + 1 }}
            </span>
            <div class="MultiFieldCriterionRecords__recordContent">
                <div class="MultiFieldCriterionRecords__title">
                    {{ startCase(name) }}
                </div>
                <div class="MultiFieldCriterionRecords__valueWithError">
                    <div class="MultiFieldCriterionRecords__value">
                        {{ getFieldValue(value) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import startCase from 'lodash/startCase';

    export default Vue.extend({
        name: 'MultiFieldCriterionRecords',
        props: {
            name: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: [ String, Array ] as PropType<unknown>,
                required: true,
            },
            index: {
                type: Number as PropType<number>,
                default: 1,
            },
            showIndex: {
                type: Boolean as PropType<Boolean>,
                default: false,
            },
            additionalIndexClass: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
        },
        methods: {
            getFieldValue(value: unknown): unknown {
                if (Array.isArray(value)) {
                    return value.join(', ');
                }
                return value;
            },
            startCase,
        },
    });
</script>
