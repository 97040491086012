var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.nonExtractionData)?_c('div',{staticClass:"PolicyInfoNonExtraction"},_vm._l((_vm.nonExtractionData),function(ref){
var label = ref.label;
var titles = ref.titles;
var evaluationError = ref.evaluationError;
var criterionName = ref.criterionName;
var hasException = ref.hasException;
return _c('div',{key:label,staticClass:"border-0 border-solid border-t border-eidSecureBlue-10 flex flex-col",attrs:{"data-test-id":("PolicyInfoNonExtraction__" + criterionName)}},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"inline-block px-5 pt-3 pb-1 text-sm text-eidLightGray font-medium",attrs:{"data-test-id":"PolicyInfoNonExtraction__nonExtractionCriterionLabel"}},[_vm._v(" "+_vm._s(label)+" ")]),(evaluationError)?_c('EvaluationError',{attrs:{"data-test-id":"PolicyInfoNonExtraction__evaluationExceptionGrandRemove","exception-badge-label":"Exception granted","show-go-to-criterion":false,"evaluation-error":evaluationError,"show-exception-sub-label":false},on:{"grantExceptionToCriterion":function($event){return _vm.grantExceptionToCriterion(evaluationError)},"removeExceptions":_vm.removeExceptions}}):_vm._e()],1),_vm._l((titles),function(ref){
var title = ref.title;
var valid = ref.valid;
return _c('div',{key:title,staticClass:"inline-flex items-center px-5 py-3"},[(_vm.isRequirementHasError(hasException, valid))?_c('EidIcon',{staticClass:"text-eidDangerRed mr-1 text-xs",attrs:{"data-test-id":"PolicyInfoNonExtraction__errorIcon","alt":"error","icon-src":_vm.faExclamationTriangle}}):_vm._e(),_c('span',{staticClass:"text-xs",class:{
                    'text-eidDangerRed': _vm.isRequirementHasError(hasException, valid),
                    'text-eidSecureBlue': !_vm.isRequirementHasError(hasException, valid)
                },attrs:{"data-test-id":"PolicyInfoNonExtraction__itemText"}},[_vm._v(" "+_vm._s(title)+" ")])],1)})],2)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }