import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { createOnlineChatMessage } from './utils/createOnlineChatMessage';
import { ChatParty } from './models/ChatParty.model';
import { addMessageToSessionHistory } from './utils/session-states/sessionHistory';
import { CHAT_ERROR_RESPONSE_MSG } from './utils/session-states/constants';

export interface OnlineChatRequirements {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<OnlineChatRequirements>();

interface OnlineChatState {
    loadAgentResponse: OperationStatus;
}

const { instantiateState, createMutationsFactories } = createState<OnlineChatState>(() => ({
    loadAgentResponse: OperationStatus.uninitialized,
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    setLoadingAgentResponse(status: OperationStatus) {
        this.loadAgentResponse = status;
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: OnlineChatRequirements) => ({
    async askAgent(payload: { rpName: string, question: any, sessionId: string, userEmail: string }) {
        const { question, rpName, sessionId, userEmail } = payload;
        this.mutations.setLoadingAgentResponse(OperationStatus.loading);
        try {
            const response = await rpweb.askChatAgent(rpName, question, sessionId, userEmail);
            const agentMessage = createOnlineChatMessage(response.answer.content, ChatParty.agent);
            addMessageToSessionHistory(agentMessage);
            this.mutations.setLoadingAgentResponse(OperationStatus.success);
        } catch (error) {
            console.error(error);
            addMessageToSessionHistory(createOnlineChatMessage(CHAT_ERROR_RESPONSE_MSG, ChatParty.agent));
            this.mutations.setLoadingAgentResponse(OperationStatus.error);
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
