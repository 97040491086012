<template>
    <div class="CriterionAddButtonWithPopover">
        <Popover
            :open="addCriterionOpened"
            class="CriterionAddButtonWithPopover__popover"
            @hide="closeAddCriterionPopup"
        >
            <template slot="base">
                <div
                    class="CriterionAddButtonWithPopover__baseContainer"
                    :class="{'CriterionAddButtonWithPopover__baseContainer--disabled': disabled}"
                >
                    <div
                        v-if="!addCriterionOpened"
                        class="CriterionAddButtonWithPopover__addCriterionButton"
                        @click="openAddCriterionPopup"
                    >
                        <span class="CriterionAddButtonWithPopover__addIcon">+</span>
                        <span>Add Another Criterion</span>
                    </div>
                    <div
                        v-else
                        class="CriterionAddButtonWithPopover__addCriterionCancelButton"
                        @click="closeAddCriterionPopup"
                    >
                        <span>Cancel</span>
                    </div>
                </div>
            </template>

            <div class="CriterionAddButtonWithPopover__optionsContainer">
                <SingleSelectOptions
                    :options="options"
                    @select="selectNewCriterion"
                />
            </div>
        </Popover>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import omit from 'lodash/omit';
    import sortBy from 'lodash/sortBy';
    import Popover from '@evidentid/dashboard-commons/components/Popover/Popover.vue';
    import { SingleSelectOptions } from '@evidentid/dashboard-commons/components/Dropdown';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        CriterionInput,
        CriterionTemplate,
    } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    @Component({
        components: {
            SingleSelectOptions,
            Popover,
        },
    })
    export default class CriterionAddButtonWithPopover extends Vue {
        @Prop({ type: Array, default: () => [] })
        private criterionTemplates!: CriterionTemplate[];

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private addCriterionOpened: boolean = false;
        private options: DropdownOption[] = [];

        @Watch('criterionTemplates', { immediate: true })
        private onCriteriaChange(): void {
            this.options = sortBy(this.criterionTemplates
                .map((template) => ({
                    value: this.convertTemplateToCriterionInput(template),
                    label: template.displayMetadata.title,
                })), 'label');
        }

        private convertTemplateToCriterionInput(
            template: CriterionTemplate,
        ): CriterionInput {
            return omit(template, 'default');
        }

        private openAddCriterionPopup(event: MouseEvent): void {
            if (!this.disabled) {
                this.addCriterionOpened = true;
                event.stopPropagation();
            }
        }

        private closeAddCriterionPopup(): void {
            this.addCriterionOpened = false;
        }

        private selectNewCriterion(option: DropdownOption): void {
            this.$emit('add', option.value);
            this.closeAddCriterionPopup();
        }
    }
</script>
