import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import JsonSchema, { JsonSchemaEnum, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isEnum } from '@evidentid/json-schema/schemaChecks';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

function getEnumLabelFromSchema(value: unknown, schema: JsonSchemaEnum): string | null {
    const index = schema.enum.indexOf(value);
    return schema.enumLabels?.[index] || null;
}
function getLabelByFieldSchema(value: unknown, schema: JsonSchema): string | null {
    if (isArray(schema) && isEnum(schema.items as JsonSchema)) {
        return getEnumLabelFromSchema(value, schema.items as JsonSchemaEnum);
    }
    if (isEnum(schema)) {
        return getEnumLabelFromSchema(value, schema);
    }
    return null;
}

export function getEnumDefaultDisplayValue<T>(
    key: string | EnumCategories,
    value: T,
    readableEnums: CategorizedEnumLabels,
    requirementModel?: TprmRequirementModel,
): T | string | string[] {
    const enumLabels = readableEnums[key];
    const schema = requirementModel?.schema.properties?.[key];
    if (!enumLabels && !schema) {
        return value;
    }

    if (Array.isArray(value)) {
        return value.map((val) => enumLabels?.[val]?.label || getLabelByFieldSchema(val, schema) || val);
    }
    return enumLabels?.[value as any]?.label || getLabelByFieldSchema(value, schema) || value;
}
