import {
    getObjectCustomPropertyLabel, getObjectCustomPropertyLabels,
} from '@/modules/entity-management/utils/get-object-custom-property-labels/getObjectCustomPropertyLabels';
import { isObject } from '@evidentid/json-schema/schemaChecks';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

function getObjectPropNames(field: CustomProperty): string[] {
    return ('propertiesOrder' in field.schema &&
        field.schema.propertiesOrder &&
        field.schema.propertiesOrder.length > 0)
        ? field.schema.propertiesOrder
            .map((key) => `${field.name} ${getObjectCustomPropertyLabel(field, key)}`)
        : getObjectCustomPropertyLabels(field);
}

export function getFlattenedCustomPropertyNames(customProperties: CustomProperty[]): string[] {
    const names = [] as string[];
    customProperties.forEach((field) => {
        if (isObject(field.schema)) {
            names.push(...getObjectPropNames(field));
        } else {
            names.push(field.name);
        }
    });
    return names;
}
