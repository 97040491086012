<template>
    <div
        class="JsonSchemaPhoneForm"
        :class="{'JsonSchemaPhoneForm--focus': focused}"
    >
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>Phone Number Only</span>
                </div>
            </template>

            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <PhoneNumberInput
                    :id="id"
                    :disabled="disabled"
                    :value="viewValue"
                    :placeholder="form.schema.placeholder"
                    :disable-auto-format="disableAutoFormat"
                    @input="onChange"
                    @blur="touch"
                    @focus="onFocus"
                />

                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="$slots.icon" #icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Prop } from 'vue-property-decorator';
    import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import { PhoneNumberInput, PhoneNumber } from '../../PhoneNumberInput';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';

    @Component({
        components: { FormInput, PhoneNumberInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaPhoneForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        @Prop({ type: Boolean, default: false })
        private disableAutoFormat!: boolean;

        private faExclamationCircle = faExclamationCircle;
        private faTimes = faTimes;
        private prevValue = '';
        private viewValue = '';

        protected override getCustomError(): string | null {
            // @ts-ignore:
            return window.intlTelInputUtils.isValidNumber(this.value)
                ? null
                : this.translate('error.phone.invalid');
        }

        @Watch('value', { immediate: true })
        private onValueChange(value: string | undefined | null): void {
            if (value !== this.prevValue) {
                this.viewValue = value || '';
            }
        }

        private onChange(value: PhoneNumber) {
            const finalValue = (
                value.number.startsWith(`+${value.dialCode}`)
                    ? value.number
                    : value.number ? `+${value.dialCode}${value.number}` : ''
            ).replace(/[^\d+]+/g, '');
            this.prevValue = finalValue;
            this.viewValue = value.number;
            this.$emit('input', this.form.getValue(finalValue));
        }
    }
</script>
