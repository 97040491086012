<template>
    <div class="CollateralList border-0 border-solid border-t border-eidSecureBlue-10">
        <span class="inline-block px-5 pt-3 pb-1 text-sm text-eidSecureBlue font-medium">
            COLLATERAL
        </span>
        <ExpansionItem
            v-for="({ description, uniqueIdentifier, limitTypes }, index) in collaterals"
            :key="index"
            class="pb-3"
            data-test-id="CollateralList__item"
        >
            <template #header="{ isExpanded }">
                <CollateralListHeader
                    :description="description"
                    :unique-identifier="uniqueIdentifier"
                    :is-collapsed="!isExpanded"
                    :evaluation-error="evaluationErrors[getEvaluationErrorPath(index)]"
                    @grant-evaluate-entity-exception="grantEvaluateEntityException"
                    @remove-exceptions="removeExceptions"
                />
            </template>
            <Table class="m-auto">
                <template #head>
                    <HeadCell
                        v-for="{ name, label } in columns"
                        :key="label"
                        class="text-eidSecureBlue-50 font-normal"
                        :data-test-id="`CollateralList__${name}HeadCell`"
                    >
                        {{ label }}
                    </HeadCell>
                </template>

                <CollateralListRow
                    v-for="(limitType, limitTypeIndex) in limitTypes"
                    :key="limitTypeIndex"
                    :columns="columns"
                    :collateral-limit-type="limitType"
                    :non-compliant-messages="getNonCompliantMessages(index, limitType.limitType)"
                />
            </Table>
        </ExpansionItem>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import ExpansionItem from '@evidentid/dashboard-commons/components/ExpansionItem/ExpansionItem.vue';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { TprmFieldCollateral } from '@/modules/entity-details/models/TprmFieldCollateral.model';
    import CollateralListRow from './components/CollateralListRow/CollateralListRow.vue';
    import CollateralListHeader from './components/CollateralListHeader/CollateralListHeader.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { TprmRequirementType, ExceptionInput } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { collateralListColumns } from './config';
    import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import { CoverageLimitType } from '@evidentid/tprm-portal-lib/models/entity-details/CoverageLimitType.model';

    type CollateralsExpandState = Record<string, boolean>;

    export default Vue.extend({
        name: 'CollateralList',
        components: {
            ExpansionItem,
            Table,
            HeadCell,
            CollateralListRow,
            CollateralListHeader,
        },
        props: {
            requirementType: {
                type: String as PropType<TprmRequirementType>,
                required: true,
            },
            collaterals: {
                type: Array as PropType<TprmFieldCollateral[]>,
                default: () => ([]) as unknown as TprmFieldCollateral[],
            },
            collateralEntities: {
                type: Array as PropType<CollateralEntity[]>,
                default: () => ([]) as unknown as CollateralEntity[],
            },
            evaluationErrors: {
                type: Object as PropType<Record<string, FieldEvaluationResultError>>,
                default: () => ({}),
            },
        },
        data() {
            return {
                columns: collateralListColumns,
                collateralsExpandState: {} as CollateralsExpandState,
            };
        },
        methods: {
            getEvaluationErrorPath(collateralIndex?: number): string {
                return `#/${this.requirementType}/details/collateral/${collateralIndex}`;
            },
            getNonCompliantMessages(collateralIndex: number, limitType: CoverageLimitType): string[] {
                /* check collateralEntity to render error to corresponding limit type, if none of limitType is
                 accepted by criterion setting( judge by if collateralEntity has limitType as null), give error to
                 all limitType this will also rely on the error msg will be `limit type not found` and will display on
                 each limitType */
                const errorObj = this.evaluationErrors[this.getEvaluationErrorPath(collateralIndex)];
                if (!errorObj || errorObj.complianceStatusMetadata?.length > 0) {
                    return [];
                }
                const entityId = errorObj.evaluatedEntityId;
                const collateralEntity =
                    this.collateralEntities.find((entity) => entityId === entity.evaluatedEntityId);
                if (collateralEntity &&
                    collateralEntity.coverageLimitType &&
                    collateralEntity.coverageLimitType !== limitType) {
                    return [];
                }
                return errorObj.messages.map((x) => x.message);
            },
            grantEvaluateEntityException(exception: ExceptionInput[]): void {
                this.$emit('grant-evaluate-entity-exception', exception);
            },
            removeExceptions(exceptionIds: string[]): void {
                this.$emit('remove-exceptions', exceptionIds);
            },
        },
    });
</script>
