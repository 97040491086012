<template>
    <v-component
        :is="simulated ? 'div' : 'form'"
        ref="dom"
        role="form"
        class="Form"
        @submit="$emit('submit', $event)"
    >
        <div class="Form__body">
            <slot />
        </div>
        <div v-if="$scopedSlots.buttons" class="Form__buttons">
            <slot name="buttons" />
        </div>
    </v-component>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

    @Component
    export default class Form extends Vue {
        @Prop({ type: Boolean, default: false })
        private simulated!: boolean;

        @Ref()
        public dom!: HTMLElement;
    }
</script>
