<template>
    <Badge v-if="roles.length === 1" :class="`UserManagementRoleBadge UserManagementRoleBadge--${roles[0]}`">
        {{ roleLabels[roles[0]] || roles[0] }}
    </Badge>
    <Badge v-else class="UserManagementRoleBadge UserManagementRoleBadge--custom" :icon="faCogs">
        Custom Permissions
    </Badge>
</template>

<style lang="scss">
    $role-badge-admin-text-color: #2cb698;
    $role-badge-admin-background-color: rgba($role-badge-admin-text-color, 0.1);
    $role-badge-manager-text-color: #ee9928;
    $role-badge-manager-background-color: rgba($role-badge-manager-text-color, 0.1);
    $role-badge-user-text-color: #2c92a8;
    $role-badge-user-background-color: rgba($role-badge-user-text-color, 0.1);
    $role-badge-custom-text-color: #a82c53;
    $role-badge-custom-background-color: rgba($role-badge-custom-text-color, 0.1);

    .Badge.UserManagementRoleBadge {
        &--administrator {
            background: $role-badge-admin-background-color;
            color: $role-badge-admin-text-color;
        }

        &--manager {
            background: $role-badge-manager-background-color;
            color: $role-badge-manager-text-color;
        }

        &--user {
            background: $role-badge-user-background-color;
            color: $role-badge-user-text-color;
        }

        &--custom {
            background: $role-badge-custom-background-color;
            color: $role-badge-custom-text-color;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { faCogs } from '@fortawesome/free-solid-svg-icons';
    import type { RoleName } from '@evidentid/user-management-api-client/types';
    import { Badge } from '../../../components/Badge';

    @Component({
        components: { Badge },
    })
    export default class RoleBadge extends Vue {
        private faCogs = faCogs;

        @Prop({ type: Array })
        private roles!: RoleName[];

        @Prop({ type: Object, default: () => ({}) })
        private roleLabels!: Record<string, string>;
    }
</script>
