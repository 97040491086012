import { Quill } from 'vue-quill-editor';

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
    public constructor(attribute: string, config: { scope: string, whitelist: string[] }) {
        super('indent', attribute, config);
    }

    protected add(node: any, value: number) {
        if (value === 0) {
            this.remove(node);
            return true;
        } else {
            return super.add(node, `${value}em`);
        }
    }
    protected value(node: any) {
        return parseInt(super.value(node), 10) || undefined;
    }
}

const IndentStyle = new IndentAttributor('margin-left', {
    scope: Parchment.Scope.BLOCK,
    whitelist: [ '1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em' ],
});

export default IndentStyle;
