<template>
    <div class="RiskProfileTable">
        <Table>
            <template #head>
                <HeadCell class="RiskProfileTable__nameHeader">
                    Risk Profile
                </HeadCell>
                <HeadCell class="RiskProfileTable__decisionHeader">
                    Decisioning
                </HeadCell>
                <HeadCell class="RiskProfileTable__requirementTypesHeader">
                    Requirements
                </HeadCell>
                <HeadCell class="RiskProfileTable__insuredsAssignedHeader">
                    Entities Assigned
                </HeadCell>
                <HeadCell class="RiskProfileTable__actionsHeader" />
            </template>

            <template #toolbarEnd>
                <Button
                    class="RiskProfileTable__addRiskProfileButton"
                    type="primary"
                    @click="$emit('create')"
                >
                    Add New Risk Profile
                </Button>
            </template>
            <RiskProfileTableRow
                v-for="riskProfile of riskProfiles"
                :key="riskProfile.id"
                :risk-profile="riskProfile"
                :show-risk-profile-type-icon="isCollateralEnabled"
                v-on="$listeners"
            />
        </Table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import RiskProfileTableRow
        from '@/modules/decisioning-criteria/components/RiskProfileTableRow/RiskProfileTableRow.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import { TprmRequestsConfig } from '@evidentid/tprm-portal-lib/models/common/TprmRequestsConfig.model';
    import { RiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    @Component({
        components: {
            Button, HeadCell, Table, RiskProfileTableRow,
        },
    })
    export default class RiskProfileTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private riskProfiles!: RiskProfile[];

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: TprmRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName || ''] || createEmptyRequestsConfigStatus();
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }
    }
</script>
