<template>
    <td class="TableCell" v-on="$listeners">
        <slot />
    </td>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class TableCell extends Vue {
    }
</script>
