<template>
    <div class="MainLayout">
        <header class="MainLayout__header">
            <slot name="header">
                <MainHeader :show-menu-icon="showMenuIcon" @menuClick="$emit('menuClick')">
                    <template v-if="$scopedSlots.menuIcon" v-slot:menuIcon>
                        <slot name="menuIcon" />
                    </template>
                    <template v-if="$scopedSlots.logo" v-slot:logo>
                        <slot name="logo" />
                    </template>

                    <template v-if="$scopedSlots.title" v-slot:title>
                        <slot name="title" />
                    </template>

                    <template v-if="$scopedSlots.navigation" v-slot:navigation>
                        <slot name="navigation" />
                    </template>
                </MainHeader>
            </slot>
        </header>
        <div class="MainLayout__columns">
            <div v-if="$scopedSlots.sidebar" class="MainLayout__sidebar"
                :class="[{
                    'MainLayout__sidebar--fixed': !collapsibleSidebar,
                    'MainLayout__sidebar--opened': !sidebarClosed,
                    'MainLayout__sidebar--closed': sidebarClosed,
                }]"
                @mouseover="$emit('sidebarMouseover')"
                @mouseleave="$emit('sidebarMouseleave')"
            >
                <slot name="sidebar" />
            </div>
            <component :is="form ? 'form' : 'div'" class="MainLayout__content" @submit="$emit('submit', $event)">
                <div v-if="$scopedSlots.toolbar || $scopedSlots.toolbarEnd" class="MainLayout__toolbar">
                    <div class="MainLayout__toolbarContent">
                        <slot name="toolbar" />
                    </div>
                    <div v-if="$scopedSlots.toolbarEnd" class="MainLayout__toolbarContentEnd">
                        <slot name="toolbarEnd" />
                    </div>
                </div>
                <LogoLoader v-if="loading" :class="{'LogoLoader--withHiddenSiblings': nonDestructiveOnLoading}" />
                <template v-if="nonDestructiveOnLoading">
                    <div v-show="!loading">
                        <slot />
                    </div>
                </template>
                <template v-else>
                    <slot v-if="!loading" />
                </template>
            </component>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { LogoLoader } from '../../components/LogoLoader';
    import MainHeader from './MainHeader.vue';

    @Component({
        components: { LogoLoader, MainHeader },
    })
    export default class MainLayout extends Vue {
        @Prop({ type: Boolean, default: false })
        private form!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Boolean, default: false })
        private collapsibleSidebar!: boolean;

        @Prop({ type: Boolean, default: false })
        private sidebarClosed!: boolean;

        @Prop({ type: Boolean, default: false })
        private showMenuIcon!: boolean;

        @Prop({ type: Boolean, default: false })
        private nonDestructiveOnLoading!: boolean;
    }
</script>
