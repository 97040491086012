import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import url from '@evidentid/universal-framework/url';
import { TicketStatus, User, UserInput, UserSearchResult } from './types';
import { buildInternalUserInput, buildUser } from './converters';

export const errorMessages: Record<number, string> = {
    400: 'Invalid data passed',
    401: 'You are not authorized for selected operation',
    403: 'You are not authorized for selected operation',
    404: 'The requested resource is not found',
    409: 'Data conflict occurred',
    440: 'Your session has expired',
};

export const errorReasons: Record<number, string> = {
    400: 'bad-request',
    401: 'unauthorized',
    403: 'forbidden',
    404: 'not-found',
    409: 'conflict',
    440: 'session-expired',
};

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    400: createCustomXhrErrorFactory(errorReasons[400], errorMessages[400]),
    401: createCustomXhrErrorFactory(errorReasons[401], errorMessages[401]),
    403: createCustomXhrErrorFactory(errorReasons[403], errorMessages[403]),
    404: createCustomXhrErrorFactory(errorReasons[404], errorMessages[404]),
    409: createCustomXhrErrorFactory(errorReasons[409], errorMessages[409]),
    440: createCustomXhrErrorFactory(errorReasons[440], errorMessages[440]),
};

interface ApiClientTokens {
    accessToken: string | null;
    idToken: string | null;
}

export class UserManagementApiClient {
    protected baseUrl: string;
    protected getTokens: () => Promise<ApiClientTokens> = () => Promise.resolve({
        accessToken: null,
        idToken: null,
    });

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    public setTokens(token: () => (Promise<ApiClientTokens> | ApiClientTokens)): this {
        this.getTokens = () => Promise.resolve(token());
        return this;
    }

    public async getUsers(
        options?: { page?: string | null, limit?: number },
        resource?: string
    ): Promise<UserSearchResult> {
        const limit = Number(options?.limit) || 50;
        const requestUrl = resource ? url`/users?resource=${resource}&` : url`/users?`;
        const qs = options?.page ? `page_token=${encodeURIComponent(options.page)}` : `page_size=${limit}`;
        const result = await this.request('GET', `${requestUrl}${qs}`);
        const [ , rawPrevToken ] = (result.previous_page || '').match(/page_token=([^&]+)/) || [ null, null ];
        const [ , rawNextToken ] = (result.next_page || '').match(/page_token=([^&]+)/) || [ null, null ];
        return {
            results: result.results.map(buildUser),
            prev: rawPrevToken ? decodeURIComponent(rawPrevToken) : null,
            next: rawNextToken ? decodeURIComponent(rawNextToken) : null,
        };
    }

    public async getUser(id: string, resource?: string): Promise<User | null> {
        const requestUrl = resource ? url`/users/${id}?resource=${resource}` : url`/users/${id}`;
        try {
            return buildUser((await this.request('GET', requestUrl)).user);
        } catch (error) {
            if (error?.reason === 'not-found') {
                return null;
            }
            throw error;
        }
    }

    public async deleteUser(id: string, resource?: string): Promise<void> {
        const requestUrl = resource ? url`/users/${id}?resource=${resource}` : url`/users/${id}`;
        await this.request('DELETE', requestUrl);
    }

    public async updateUser(id: string, data: UserInput, resource?: string): Promise<User> {
        const requestUrl = resource ? url`/users/${id}?resource=${resource}` : url`/users/${id}`;
        const { user } = await this.request('PUT', requestUrl, { user: buildInternalUserInput(data) });
        return buildUser(user);
    }

    public async createUser(
        data: { user: UserInput, loginUrl?: string, displayName?: string },
        resource?: string,
    ): Promise<User> {
        const requestUrl = resource ? url`/users?resource=${resource}` : url`/users`;
        const { user } = await this.request('POST', requestUrl, {
            user: buildInternalUserInput(data.user),
            requesting_resource_name: data.displayName,
            app_login_page: data.loginUrl,
        });
        return buildUser(user);
    }

    public async resendInvite(data: { userId: string, loginUrl?: string }, resource?: string): Promise<boolean> {
        const { userId, loginUrl } = data;
        const status = await this.createTicket({ userId, loginUrl, action: 'resend_invitation' }, resource);
        return status === TicketStatus.success;
    }

    private async createTicket(
        data: { userId: string, action: string, loginUrl?: string },
        resource?: string
    ): Promise<TicketStatus> {
        const { userId, action, loginUrl } = data;
        const ticket = { action, app_login_page: loginUrl };
        const requestUrl = resource ? url`/users/${userId}/ticket?resource=${resource}` : url`/users/${userId}/ticket`;
        const { ticket: { status } } = await this.request('POST', requestUrl, { ticket });
        return status;
    }

    /**
     * Make a request to RP Web service.
     */
    private async request<T = any>(method: string, requestUrl: string, data?: any):
        Promise<T> {
        // Retrieve current auth token
        const { accessToken, idToken } = await this.getTokens();

        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            xhr.setRequestHeader('content-type', 'application/json');
            if (accessToken) {
                xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
            }
            if (idToken) {
                xhr.setRequestHeader('idtoken', idToken);
            }

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const result = parseJsonConditionally(xhr.responseText);
                const statusCode = result?.downstream_error?.status_code || xhr.status;
                const statusErrorFactory = statusErrorFactories[statusCode];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                // TODO: Think if it shouldn't wrap the error in Error instance
                const finish = statusCode >= 200 && statusCode < 300 ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`User Management Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }
}
