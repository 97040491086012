<template>
    <ConfirmationModal
        additional-class-name="ConfirmDeleteCollateralModal"
        size="optimal"
        theme="default"
        yes="Delete"
        no="Cancel"
        :yes-icon="null"
        :no-icon="null"
        :destructive="true"
        :progress="deleting"
        @input="onConfirm"
    >
        <template #header>
            Are you sure you want to delete {{ collateral.description }} from {{ entityDisplayName }}?
        </template>
        <p>
            Deleting an item will permanently remove it from the system.
        </p>
    </ConfirmationModal>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';

    import { CustomPropertyCollateral } from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';

    export default Vue.extend({
        name: 'ConfirmDeleteCollateralModal',
        components: {
            ConfirmationModal,
        },
        props: {
            entityDisplayName: {
                type: String as PropType<string>,
                required: true,
            },
            collateral: {
                type: Object as PropType<CustomPropertyCollateral>,
                required: true,
            },
            deleting: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        methods: {
            onConfirm(confirm: boolean): void {
                this.$emit('input', confirm);
            },
        },
    });
</script>
