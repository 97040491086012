// Initialize Sentry
import Sentry from '@/sentry';

// Load polyfills
import 'blueimp-canvas-to-blob';

// Load Vue and its dependent plugins
import Vue from 'vue';
import createMetaNavigationGuard from '@evidentid/vue-commons/router/createMetaNavigationGuard';

// Load the application factory
import { createInsuranceApplication } from '@/createInsuranceApplication';

// Load application requirements
import ErrorComponent from '@/views/Error.vue';
import LoadingComponent from '@/views/Loading.vue';
import LoginComponent from '@/views/Login.vue';
import RegisterComponent from '@/views/Register.vue';
import ResetPasswordComponent from '@/views/ResetPassword.vue';
import VerifyEmailComponent from '@/views/VerifyEmail.vue';
import TermsComponent from '@/views/Terms.vue';
import PageComponent from '@/layouts/Page.vue';

// Load the configuration
import { config } from '@/config';

// Load stylesheets
import './styles/main.scss';
import './styles/tailwind.css';
// Set-up Vue

Vue.config.productionTip = false;

// Set-up initialization

async function main() {
    // Set-up views
    const views: Parameters<typeof createInsuranceApplication>[0]['views'] = {
        Error: ErrorComponent,
        Loading: LoadingComponent,
        Login: LoginComponent,
        Register: RegisterComponent,
        ResetPassword: ResetPasswordComponent,
        VerifyEmail: VerifyEmailComponent,
        Terms: TermsComponent,
        Page: PageComponent,
    };

    // Set-up application
    const app = await createInsuranceApplication({
        ...config,
        Sentry,
        views,
    });

    /**
     * code bellow is a copy of one inside `PartnersSettingsService`
     * that's because we need to make sure that we always display proper branding
     * Thanks to loading animation bellow we need to do it in two places. Separately.
     */

    const environmentToUrlMap = {
        dev: 'https://privacy-staging.evidentid.com/partner-profiles/config-dev/',
        int: 'https://privacy-staging.evidentid.com/partner-profiles/config-int/',
        loadtest: 'https://privacy-staging.evidentid.com/partner-profiles/config-loadtest/',
        staging: 'https://privacy.evidentid.com/partner-profiles/config-staging/',
        demo: 'https://privacy.evidentid.com/partner-profiles/config-demo/',
        production: 'https://privacy.evidentid.com/partner-profiles/config/',
    };
    function extractPartnerName() {
        const URL_PARTS_LENGTH_WITH_PARTNER_NAME = 4;
        const currentLocation = window.location.href;
        const locationWithoutHttps = currentLocation.split('//')[1];
        const urlParts = locationWithoutHttps.split('.');
        if (urlParts.length < URL_PARTS_LENGTH_WITH_PARTNER_NAME) {
            return undefined;
        }
        return urlParts[0];
    }

    function getPartnerSettingsUrl(partnerName: string, environment: string) {
        // @ts-ignore
        const baseUrl = environmentToUrlMap[environment];
        return `${baseUrl + partnerName}.json`;
    }

    const environment = deploy.WEB_PUBLIC_ENVIRONMENT;
    const partnerName = extractPartnerName();
    const favIconElement = document.getElementById('favicon');
    if (partnerName) {
        const url = getPartnerSettingsUrl(partnerName, environment);
        try {
            fetch(url, { cache: 'force-cache' })
                .then((response) => response.json())
                .then((partnerSettings) => {
                    if (partnerSettings && partnerSettings.name) {
                        // Set-up meta <title> automatically per route
                        // TODO: Use some Vue component to do that instead
                        app.router.beforeEach(createMetaNavigationGuard(partnerSettings.name, document));
                        favIconElement?.setAttribute('href', partnerSettings.favicon_url);
                    }
                });
        } catch (e) {
            console.error(e);
            // Set-up meta <title> automatically per route
            // TODO: Use some Vue component to do that instead
            app.router.beforeEach(createMetaNavigationGuard('Evident', document));
        }
    } else {
        // Set-up meta <title> automatically per route
        // TODO: Use some Vue component to do that instead
        app.router.beforeEach(createMetaNavigationGuard('Evident', document));
    }

    // Find 'root' element
    const rootElement = document.querySelector('#root');

    // Mount application
    if (rootElement) {
        app.mount(rootElement);
    } else {
        throw new Error('Unable to find #root element.');
    }

    return app;
}

// Improve XHR for development with Webpack

if (process.env.NODE_ENV !== 'production') {
    require('@evidentid/vue-commons/development/hackDevelopmentHmr');
}

// Run app initialization

main().then(
    (app) => {
        // Attach application instance to window for development
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            // @ts-ignore: attaching application instance to window
            window.insuranceApp = app;
        }
    },
    (error) => {
        setTimeout(() => {
            throw error;
        });
    },
);
