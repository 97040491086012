import { JsonSchemaFormStrings } from './types';

export const defaultStrings: JsonSchemaFormStrings = {
    'requiredLabel': '(Required)',
    'boolean.yes': 'Yes',
    'boolean.no': 'No',
    'error.required': 'This field is required',
    'error.unknown': 'This field is invalid',
    'error.email.invalid': 'Please enter valid email',
    'error.number.invalid': 'Please enter only numbers (e.g. 123)',
    'error.number.integer': 'Please enter only integer (e.g. 123)',
    'error.number.tooSmall': 'Value can\'t be smaller than {minimum}',
    'error.number.tooBig': 'Value can\'t be bigger than {maximum}',
    'error.number.multipleOf': 'Value should be multiple of {multipleOf}',
    'error.phone.invalid': 'Please enter valid phone number',
    'error.text.tooShort': 'Entered text is too short',
    'error.text.tooLong': 'Entered text is too long',
    'error.image.tooBig': 'Provided image is too big',
    'customLabels': {},
};
