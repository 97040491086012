interface BareModal {
    open: boolean;
}
type TargetStatusListener = (open: boolean) => any;
type TargetStatusUnsubscribe = () => void;

class ModalManager {
    private modalsByTarget: Map<string, BareModal[]> = new Map();
    private targetByModal: WeakMap<BareModal, string> = new WeakMap();
    private subscriptions: Map<string, TargetStatusListener[]> = new Map();

    private getTarget(modal: BareModal): string | null {
        const target = this.targetByModal.get(modal);
        return target == null ? null : target;
    }

    private getModals(target: string): BareModal[] {
        return this.modalsByTarget.get(target) || [];
    }

    private setModalTarget(modal: BareModal, target: string): void {
        this.targetByModal.set(modal, target);
    }

    private clearModalTarget(modal: BareModal): void {
        this.targetByModal.delete(modal);
    }

    private addModalToTarget(modal: BareModal, target: string): void {
        this.modalsByTarget.set(target, this.getModals(target).concat(modal));
    }

    private removeModalFromTarget(modal: BareModal, target: string): void {
        this.modalsByTarget.set(target, this.getModals(target).filter((x) => x !== modal));
    }

    private getSubscriptions(target: string): TargetStatusListener[] {
        return this.subscriptions.get(target) || [];
    }

    private unsubscribe(target: string, wrappedListener: TargetStatusListener): void {
        this.subscriptions.set(target, this.getSubscriptions(target).filter((x) => x !== wrappedListener));
    }

    public subscribe(target: string, listener: TargetStatusListener): TargetStatusUnsubscribe {
        const wrappedListener: TargetStatusListener = (open) => listener(open);
        this.subscriptions.set(target, this.getSubscriptions(target).concat(wrappedListener));
        return this.unsubscribe.bind(this, target, wrappedListener);
    }

    public getOpenStatus(target: string): boolean {
        return this.getModals(target).some((modal) => modal.open);
    }

    public refresh(target: string): void {
        const open = this.getOpenStatus(target);
        for (const listener of this.getSubscriptions(target)) {
            listener(open);
        }
    }

    public registerModal(modal: BareModal, target: string): void {
        // Ignore when there is no change
        if (this.getTarget(modal) === target) {
            return;
        }

        this.unregisterModal(modal);
        this.setModalTarget(modal, target);
        this.addModalToTarget(modal, target);
        this.refresh(target);
    }

    public unregisterModal(modal: BareModal): void {
        const target = this.getTarget(modal);

        // Ignore when it's already unregistered
        if (target === null) {
            return;
        }

        this.clearModalTarget(modal);
        this.removeModalFromTarget(modal, target);
        this.refresh(target);
    }
}

declare module 'vue' {
    interface VueConstructor {
        readonly $modalManager: ModalManager;
    }

    interface Vue {
        readonly $modalManager: ModalManager;
    }
}

declare module 'vue/types/vue' {
    interface VueConstructor {
        readonly $modalManager: ModalManager;
    }

    interface Vue {
        readonly $modalManager: ModalManager;
    }
}

export default ModalManager;
