<template>
    <li class="MenuElement"
        :class="[ {'MenuElement--active': active}, {'MenuElement--disabled': disabled} , additionalClass ]"
    >
        <a :href="url"
            :target="target"
            class="MenuElement__link"
            @click="emitClick"
            @mouseenter="$emit('mouseenter')"
            @mouseleave="$emit('mouseleave')"
        >
            <span v-if="currentIcon" class="MenuElement__linkIcon">
                <EidIcon :icon-src="currentIcon" :icon-props="iconProps" />
            </span>
            <slot name="label">
                <span class="MenuElement__linkText">
                    {{ label }}
                </span>
            </slot>
            <span v-if="hasSubNavigation" class="MenuElement__linkChevron">
                <FontAwesomeIcon :icon="caret" />
            </span>
        </a>
        <Menu v-if="open && hasSubNavigation">
            <slot />
        </Menu>
    </li>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { IconDefinition, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import Menu from './Menu.vue';
    import EidIcon from '../EidIcon/EidIcon.vue';

    @Component({
        components: { Menu, FontAwesomeIcon, EidIcon },
    })
    export default class MenuLink extends Vue {
        @Prop({ type: Boolean, default: false })
        private active!: boolean;

        @Prop({ type: String, default: '' })
        private additionalClass!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private open!: boolean;

        @Prop({ type: String, default: null })
        private href!: string | null;

        @Prop({ type: String, default: null })
        private target!: string | null;

        @Prop({ default: null })
        private icon!: IconDefinition | string; // FontAwesome icon or image URL

        @Prop({ default: null })
        private iconActive!: IconDefinition | string; // FontAwesome icon or image URL

        @Prop({ type: Object, default: () => ({}) })
        private iconProps!: object;

        @Prop({ type: String })
        private label!: string;

        private emitClick(event: MouseEvent) {
            if (!this.disabled) {
                this.$emit('click', event);
            }
        }

        private get hasSubNavigation() {
            return Boolean(this.$slots.default);
        }

        private get currentIcon() {
            return this.active ? this.iconActive || this.icon : this.icon;
        }

        private get url() {
            return this.href || 'javascript:void(0)';
        }

        private get caret() {
            return this.open ? faCaretUp : faCaretDown;
        }
    }
</script>
