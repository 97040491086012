<template>
    <div class="OnlineChatBox">
        <div class="OnlineChatBox__header" data-test-id="OnlineChatBox__header">
            <div
                data-test-id="OnlineChatBox__boxTitle"
                class="OnlineChatBox__boxTitle"
            >
                <span>Chat with Evie - Virtual Assistant</span>
                <EidIcon
                    class="OnlineChatBox__closeIcon"
                    :icon-src="faWindowMinimize"
                    @click="$emit('close')"
                />
            </div>
        </div>

        <div ref="messageContainer" class="OnlineChatBox__content">
            <OnlineChatMessage
                v-for="message in chatMessages"
                :key="message.id"
                :message="message"
                :disallow-view-more="disallowViewMore"
                @view-message-in-modal="$emit('view-message-in-modal', $event)"
            />

            <OnlineChatMessage v-if="isLoadingAgentResponse" typing :message="{party:'agent'}" />
        </div>
        <div class="OnlineChatBox__footer">
            <OnlineChatInput @message-input="$emit('message-input', $event)" />
        </div>
    </div>
</template>
<style lang="scss">
    @import "./index.scss";
</style>
<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import { faWindowMinimize } from '@fortawesome/free-regular-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { ChatMessage } from '../../models';
    import OnlineChatMessage from '../OnlineChatMessage/OnlineChatMessage.vue';
    import OnlineChatInput from '../OnlineChatInput/OnlineChatInput.vue';

    export default Vue.extend({
        name: 'OnlineChatBox',
        components: {
            EidIcon,
            OnlineChatInput,
            OnlineChatMessage,
        },
        props: {
            chatMessages: {
                type: Array as PropType<ChatMessage[]>,
                default: () => [],
            },
            disallowViewMore: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isLoadingAgentResponse: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                faWindowMinimize,
            };
        },
        watch: {
            chatMessages: {
                immediate: true,
                handler() {
                    this.scrollToEnd();
                },
            },
        },
        methods: {
            scrollToEnd(): void {
                setTimeout(() => {
                    const container = this.$refs.messageContainer as HTMLDivElement;
                    if (container) {
                        const scrollOffset = container.scrollHeight - container.clientHeight;
                        container.scrollTo({ top: scrollOffset });
                    }
                });
            },
        },
    });
</script>
