<template>
    <JsonSchemaViewElement
        :id="id"
        :value="value"
        :form="form"
        :currency="currency"
        :custom-component-input="components"
        :evaluation-errors="evaluationErrors"
        :start-index="startIndex"
        :no-object-index="noObjectIndex"
        v-on="$listeners"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { JsonSchemaObject, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
    import JsonSchemaArrayView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaArrayView.vue';
    import JsonSchemaStringView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaStringView.vue';
    import JsonSchemaBooleanView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaBooleanView.vue';
    import JsonSchemaObjectView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaObjectView.vue';
    import JsonSchemaTextView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaTextView.vue';
    import { JsonFormBase, JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import createForm from '@evidentid/json-schema/createForm';
    import JsonSchemaViewElement from '@/modules/entity-details/components/JsonSchemaView/JsonSchemaViewElement.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import JsonSchemaNumberView
        from '@/modules/entity-details/components/JsonSchemaView/elements/JsonSchemaNumberView.vue';

    // TODO(PRODUCT-17637): merging CustomJsonForms dir with JsonSchemaView's elements, as they may have overlaps
    @Component({
        inheritAttrs: false,
        components: { JsonSchemaViewElement },
    })
    export default class JsonSchemaView extends Vue {
        @Prop(Object)
        private schema!: JsonSchemaObject;

        @Prop()
        private value!: any;

        @Prop({ type: Object, default: () => null })
        private customComponents!: Partial<Record<JsonFormType, Vue.Component>> | null;

        @Prop(String)
        private id!: string;

        @Prop({ type: Object, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: String, default: 'USD' })
        private currency!: string;

        @Prop({ type: Number, default: 1 })
        private startIndex !: number;

        @Prop({ type: Boolean, default: false })
        private noObjectIndex !: boolean;

        private defaultComponents: Record<JsonSchemaType, Vue.Component> = {
            array: JsonSchemaArrayView,
            string: JsonSchemaStringView,
            number: JsonSchemaNumberView,
            integer: JsonSchemaTextView,
            boolean: JsonSchemaBooleanView,
            object: JsonSchemaObjectView,
        };

        private form: JsonFormBase<JsonSchemaObject> | null = null;

        private get components() {
            return { ...this.defaultComponents, ...this.customComponents };
        }

        @Watch('schema', { immediate: true })
        private buildForm(): void {
            this.form = createForm(this.schema);
        }
    }
</script>
