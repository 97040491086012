export enum VerificationStatus {
    actionsReview = 'ACTIONS_REVIEW',
    audit = 'AUDIT',
    complete = 'COMPLETE',
    exhausted = 'EXHAUSTED',
    memberOutreach = 'MEMBER_OUTREACH',
    noInsuredResponse = 'NO_INSURED_RESPONSE',
    pendingInsuredSubmission = 'PENDING_INSURED_SUBMISSION',
    processing = 'PROCESSING',
    producerOutreach = 'PRODUCER_OUTREACH',
}

export enum VerificationStatusLabel {
    actionsReview = 'Actions',
    audit = 'Audit',
    complete = '',
    exhausted = 'Exhausted',
    memberOutreach = 'Member Outreach',
    noInsuredResponse = 'No Response',
    pendingInsuredSubmission = 'Pending Submission',
    processing = 'Processing',
    producerOutreach = 'Producer Outreach',
}
