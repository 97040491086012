<template>
    <div class="EmailTemplateItem">
        <div class="EmailTemplateItem__icon">
            <EidIcon :icon-src="icon" />
        </div>
        <div class="EmailTemplateItem__information">
            <div class="EmailTemplateItem__title">
                {{ title }}
            </div>
            <div class="EmailTemplateItem__description">
                {{ description }}
            </div>
        </div>
        <div class="EmailTemplateItem__actions">
            <EidIcon
                v-if="iconNotificationState"
                v-tooltip="iconNotificationState.tooltip"
                class="EmailTemplateItem__remindersStatus"
                :class="iconNotificationState.colorClass"
                :icon-src="iconNotificationState.icon"
                :svg-props="{ width: 20, height: 20 }"
            />
            <Button
                data-test-id="EmailTemplateItem__setReminders"
                class="EmailTemplateItem__setReminders"
                :icon="calendarRangeIcon"
                @click="$emit('setReminders')"
            >
                SCHEDULE
            </Button>
            <Button
                data-test-id="EmailTemplateItem__edit"
                class="EmailTemplateItem__edit"
                :icon="pencilIcon"
                @click="$emit('edit')"
            >
                EDIT
            </Button>
            <Button
                data-test-id="EmailTemplateItem__test"
                class="EmailTemplateItem__test"
                :icon="envelopeIcon"
                @click="$emit('sendTestEmail')"
            >
                TEST
            </Button>
        </div>
    </div>
</template>

<style lang="scss">
.EmailTemplateItem {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px 30px;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 3px;

    &__icon {
        width: 31px;
        max-width: 31px;
        margin-right: 30px;
        color: #2ab496;

        img {
            display: block;
            height: 35px;
        }
    }

    &__information {
        flex: 1 auto;
        color: #2d3e50;
    }

    &__title {
        font-weight: 500;
        color: #2ab496;
    }

    &__description {
        font-size: 0.87em;
    }

    &__actions {
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        .EmailTemplateItem__remindersStatus {
            padding: 15px 25px;
        }

        .Button {
            @apply text-eidDarkBlue-75;
            padding: 15px;
        }

        .EmailTemplateItem__setReminders {
            position: relative;

            &::before {
                @apply bg-eidSecureBlue-25;
                left: -5px;
                content: "";
                position: absolute;
                height: 100%;
                width: 1px;
            }
        }

        .EmailTemplateItem__edit {
            position: relative;
            margin: 0 10px;

            &::before, &::after {
                @apply bg-eidSecureBlue-25;

                content: "";
                position: absolute;
                height: 100%;
                width: 1px;
            }

            &::before {
                left: -5px;
            }

            &::after {
                right: -5px;
            }
        }
    }
}
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import notificationIconUrl from '../assets/notification-icon.svg';
    import pencilIcon from '../assets/pencil.svg';
    import envelopeIcon from '../assets/envelope.svg';
    import calendarRangeIcon from '../assets/calendar-range.svg';
    import envelopeCircleCheckIcon from '@/assets/icons/envelope-circle-check-icon.svg';

    @Component({
        components: {
            FontAwesomeIcon,
            Button,
            EidIcon,
        },
    })
    export default class EmailTemplateItem extends Vue {
        private notificationIconUrl = notificationIconUrl;
        private pencilIcon = pencilIcon;
        private envelopeIcon = envelopeIcon;
        private calendarRangeIcon = calendarRangeIcon;

        @Prop({ type: String })
        private title!: string;

        @Prop({ type: String })
        private description!: string;

        @Prop({ type: String })
        private icon!: string;

        @Prop({ type: Boolean })
        private initEnabled?: boolean;

        @Prop({ type: Boolean })
        private remindersEnabled?: boolean;

        private get iconNotificationState() {
            const dataIsUndefined = this.initEnabled == null || this.remindersEnabled == null;
            const allDisabled = this.initEnabled === false && this.remindersEnabled === false;
            const allEnabled = this.initEnabled === true && this.remindersEnabled === true;
            const someEnabled = this.initEnabled === true && this.remindersEnabled === false
                || this.initEnabled === false && this.remindersEnabled === true;
            if (dataIsUndefined) {
                return null;
            } else if (allDisabled) {
                return {
                    icon: faBellSlash,
                    colorClass: 'text-eidSecureBlue-50',
                    tooltip: {
                        html: true,
                        content: '<strong>Notifications are off</strong> <br> Schedule and switch on',
                    },
                };
            } else if (allEnabled) {
                return {
                    icon: envelopeCircleCheckIcon,
                    colorClass: 'text-eidTrustGreen',
                    tooltip: {
                        html: true,
                        content: '<strong>Notifications are on</strong>',
                    },
                };
            } else if (someEnabled) {
                return {
                    icon: envelopeCircleCheckIcon,
                    colorClass: 'text-eidTrustGreen',
                    tooltip: {
                        html: true,
                        content: '<strong>Some Notifications are off</strong>',
                    },
                };
            } else {
                return null;
            }
        }
    }
</script>
