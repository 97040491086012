export default function parseJsonConditionally(text: string | undefined): any {
    if (!text) {
        return text;
    }

    try {
        return JSON.parse(text);
    } catch (error) {
        return text;
    }
}
