import { createEmptyListVuexStatus } from '@/utils/vuex-common/createEmptyListVuexStatus';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

interface PayloadWithoutList {
    rpName: string;
}

interface PayloadWithList<T> {
    rpName: string;
    list: T[];
}

export function mutateCommonListStartState<T>(scope: any, stateKey: string, payload: PayloadWithoutList): void {
    scope[stateKey] = {
        ...scope[stateKey],
        [payload.rpName]: {
            ...createEmptyListVuexStatus<T>(),
            ...scope[stateKey][payload.rpName],
            status: OperationStatus.loading,
        },
    };
}

export function mutateCommonListFinishState<T>(scope: any, stateKey: string, payload: PayloadWithList<T>): void {
    scope[stateKey] = {
        ...scope[stateKey],
        [payload.rpName]: {
            ...scope[stateKey][payload.rpName],
            status: OperationStatus.success,
            list: payload.list,
        },
    };
}

export function mutateCommonListFailState<T>(scope: any, stateKey: string, payload: PayloadWithoutList): void {
    scope[stateKey] = {
        ...scope[stateKey],
        [payload.rpName]: {
            ...scope[stateKey][payload.rpName],
            status: OperationStatus.error,
            list: createEmptyListVuexStatus<T>().list,
        },
    };
}
