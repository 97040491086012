<template>
    <label class="Checkbox" :class="className">
        <input
            type="checkbox"
            :disabled="disabled"
            :checked="currentValue"
            v-bind="$attrs"
            v-on="passedListeners"
            @input="onChange"
        >
        <span class="Checkbox__icon">
            <span class="Checkbox__iconInner">
                <FontAwesomeIcon :icon="faCheck" />
            </span>
        </span>
        <span class="Checkbox__content">
            <slot></slot>
        </span>
    </label>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import omit from 'lodash/omit';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCheck } from '@fortawesome/free-solid-svg-icons';

    @Component({
        inheritAttrs: false,
        components: { FontAwesomeIcon },
    })
    export default class Checkbox extends Vue {
        private faCheck = faCheck;

        @Prop({ type: Boolean, default: false })
        private value!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        private currentValue: boolean = false;

        @Watch('value', { immediate: true })
        private updateCurrentValue() {
            this.currentValue = this.value;
        }

        private get className() {
            return [
                this.currentValue ? 'Checkbox--selected' : '',
                this.disabled ? 'Checkbox--disabled' : '',
                this.$attrs.class,
            ].filter(Boolean);
        }

        private get passedListeners() {
            return omit(this.$listeners, [ 'input' ]);
        }

        private onChange(event: Event) {
            this.currentValue = (event.target as HTMLInputElement).checked;
            this.$emit('input', this.currentValue);
        }
    }
</script>
