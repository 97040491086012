/* eslint-disable camelcase */
import isNil from 'lodash/isNil';
import { TprmFieldCollateral } from '@/modules/entity-details/models/TprmFieldCollateral.model';
import { TprmFieldCollateralLimit } from '@/modules/entity-details/models/TprmFieldCollateralLimit.model';
import { CoverageLimitValuation } from '@evidentid/tprm-portal-lib/models/entity-details/CoverageLimitValuation.model';

interface CollateralDetailsLimitMvObject {
    $objectType: 'CollateralLimitType';
    coverageLimit: string;
    deductible: string;
    limitType: string;
    valuation: string;
}

interface CollateralDetailsMvObject {
    $objectType: 'Collateral';
    description: string;
    uniqueIdentifier: string;
    limitTypes: CollateralDetailsLimitMvObject[];
}

const transformCollateralDetailslLimits =
    (limits?: CollateralDetailsLimitMvObject[]): TprmFieldCollateralLimit[] => {
        if (!limits) {
            return [];
        }

        return limits.map(({
            coverageLimit,
            limitType,
            deductible,
            valuation,
        }) => ({
            valuation: valuation as CoverageLimitValuation,
            limitType,
            coverageLimit: !isNil(coverageLimit) && coverageLimit !== '' ? Number(coverageLimit) : null,
            deductible: !isNil(deductible) && deductible !== '' ? Number(deductible) : null,
        }));
    };

export const transformCollateralsDetails = (
    collaterals?: CollateralDetailsMvObject[],
): TprmFieldCollateral[] => {
    if (!collaterals) {
        return [];
    }

    return collaterals.map(({
        uniqueIdentifier,
        description,
        limitTypes,
    }) => ({
        description,
        uniqueIdentifier,
        limitTypes: transformCollateralDetailslLimits(limitTypes),
    }));
};
