import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import {
    getRequirementDetailsKeysValueWithErrors,
} from '@/modules/entity-details/utils/getRequirementDetailsKeysValueWithErrors';
import { isEmptyValue } from '@/modules/entity-details/components/Policy/sub-components/PolicyInfo/utils';
import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
import {
    TprmRequirementObjectType,
} from '@evidentid/tprm-portal-lib/models/entity-details';
import { getCoverageAddressObjectString } from '@/modules/entity-details/utils/getCoverageAddressObjectString';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

function parseToSingleStringIfAddress(value: unknown, enums: CategorizedEnumLabels): unknown | string {
    const addressTypes = [
        TprmRequirementObjectType.address,
        TprmRequirementObjectType.addressV2,
        TprmRequirementObjectType.coverageFieldAddress,
    ];
    if (typeof value === 'object' &&
        value &&
        '$objectType' in value &&
        addressTypes.includes((value as any).$objectType)
    ) {
        return getCoverageAddressObjectString(value as Record<string, any>, enums.countryCode);
    } else {
        return value;
    }
}

export function parseFieldValuePerEnumAndError(
    fieldName: string,
    fieldValue: any,
    otherData: {
        requirement: EntityRequirement;
        requirementModel?: TprmRequirementModel;
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        multiFieldCriteria: EntityMultiFieldCriterion[];
        categorizedEnumLabels: CategorizedEnumLabels;
    }) {
    let resultKeyValue = getRequirementDetailsKeysValueWithErrors({
        key: fieldName,
        value: fieldValue,
        requirement: otherData.requirement,
        evaluationErrors: otherData.evaluationErrors,
        crossCriteria: otherData.multiFieldCriteria,
    });
    resultKeyValue = parseToSingleStringIfAddress(resultKeyValue, otherData.categorizedEnumLabels);
    if (!isEmptyValue(resultKeyValue)) {
        return getEnumDefaultDisplayValue(
            fieldName, resultKeyValue, otherData.categorizedEnumLabels, otherData.requirementModel
        );
    }
    return fieldValue;
}
