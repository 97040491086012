/* eslint-disable max-lines */
import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    OperationError,
} from '@evidentid/rpweb-api-client/models';
import { getSnackbarActions } from '@evidentid/dashboard-commons/modules/snackbar';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import {
    Criterion,
    CriterionInput,
    CriterionMessage,
    CriterionTemplate,
} from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';
import { RequirementTypesPerCountry } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementType';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
import {
    RiskProfile, RiskProfileInput,
} from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';
import { EntityRiskProfile, EffectiveRiskProfiles } from '@evidentid/tprm-portal-lib/models/decisioning';

export interface DecisioningCriteriaRequirement {
    rpweb: RpWebApiClient;
}

export interface RiskProfilesStatus {
    status: OperationStatus;
    list: RiskProfile[];
}

export interface EntityRiskProfilesStatus {
    status: OperationStatus;
    list: EntityRiskProfile[];
}

export type EntityRiskProfilesStatusPerEntity = Record<string, EntityRiskProfilesStatus>;

export interface RiskProfileStatus {
    status: OperationStatus;
    data: RiskProfile | null;
}

export interface CriteriaStatus {
    status: OperationStatus;
    criteria: Record<string, Criterion[]>;
}

export interface CriterionMessageStatus {
    status: OperationStatus;
    data: CriterionMessage | null;
    latestCallNum?: number | null;
}

export type CriterionMessageStatusMap = Record<string, CriterionMessageStatus>;

export interface CriterionTemplatesStatus {
    status: OperationStatus;
    list: CriterionTemplate[];
}

export interface RequirementTypesPerCountryStatus {
    status: OperationStatus;
    data: RequirementTypesPerCountry;
}

export interface CustomPropertiesListStatus {
    status: OperationStatus;
    list: CustomProperty[];
}

export interface EntityEffectiveRiskProfileStatus {
    status: OperationStatus;
    list: EffectiveRiskProfiles[];
}

export interface RequirementModelsStatus {
    status: OperationStatus;
    list: TprmRequirementModel[];
}

interface DecisioningCriteriaState {
    customProperties: Record<string, CustomPropertiesListStatus>;
    riskProfiles: Record<string, RiskProfilesStatus>;
    entityRiskProfiles: Record<string, EntityRiskProfilesStatusPerEntity>;
    currentRiskProfile: Record<string, RiskProfileStatus>;
    criteria: Record<string, CriteriaStatus>;
    criterionTemplates: Record<string, CriterionTemplatesStatus>;
    criterionMessage: Record<string, CriterionMessageStatusMap>;
    requirementTypesPerCountry: Record<string, RequirementTypesPerCountryStatus>;
    deletingRiskProfileStatus: Record<string, OperationStatus>;
    creatingRiskProfileStatus: Record<string, OperationStatus>;
    creatingCriteriaStatus: Record<string, OperationStatus>;
    patchingCriteriaStatus: Record<string, OperationStatus>;
    updatingRiskProfileStatus: Record<string, OperationStatus>;
    effectiveRiskProfileStatus: Record<string, EntityEffectiveRiskProfileStatus>;
    requirementModelsStatus: Record<string, RequirementModelsStatus>;
}

const createState = createStateFactory<DecisioningCriteriaRequirement>();

export function createEmptyRiskProfilesStatus(): RiskProfilesStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCurrentRiskProfileStatus(): RiskProfileStatus {
    return {
        status: OperationStatus.uninitialized,
        data: null,
    };
}

export function createEmptyCustomPropertyStatus(): CustomPropertiesListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCriterionTemplatesStatus(): CriterionTemplatesStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCriteriaStatus(): CriteriaStatus {
    return {
        status: OperationStatus.uninitialized,
        criteria: {},
    };
}

export function createEmptyRequirementTypesPerCountry(): RequirementTypesPerCountryStatus {
    return {
        status: OperationStatus.uninitialized,
        data: {},
    };
}

export function createEmptyEffectiveRiskProfileStatus(): EntityEffectiveRiskProfileStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyRequirementModelsStatus(): RequirementModelsStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

const { instantiateState, createMutationsFactories } = createState<DecisioningCriteriaState>(() => ({
    customProperties: {},
    riskProfiles: {},
    entityRiskProfiles: {},
    criteria: {},
    criterionMessage: {},
    deletingRiskProfileStatus: {},
    creatingRiskProfileStatus: {},
    creatingCriteriaStatus: {},
    patchingCriteriaStatus: {},
    updatingRiskProfileStatus: {},
    currentRiskProfile: {},
    criterionTemplates: {},
    requirementTypesPerCountry: {},
    effectiveRiskProfileStatus: {},
    requirementModelsStatus: {},
}));

const handleActionError = async (scopedContext: any, mutationCall: Function, data: any, error: string) => {
    mutationCall(data);
    const notFoundErrorPattern = /^Error: The requested resource is not found.?/;
    const errorType = notFoundErrorPattern.test(error) ? 'page-not-found' : error;
    await getPersistingErrorActions(scopedContext).showError(errorType);
};

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingRiskProfiles(payload: { rpName: string }) {
        this.riskProfiles = {
            ...this.riskProfiles,
            [payload.rpName]: {
                ...createEmptyRiskProfilesStatus(),
                ...(this.riskProfiles[payload.rpName] as RiskProfilesStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingRiskProfiles(
        payload: { rpName: string, riskProfiles: RiskProfile[] },
    ) {
        this.riskProfiles = {
            ...this.riskProfiles,
            [payload.rpName]: {
                ...(this.riskProfiles[payload.rpName] as RiskProfilesStatus),
                status: OperationStatus.success,
                list: payload.riskProfiles,
            },
        };
    },
    failLoadingRiskProfiles(payload: { rpName: string }) {
        this.riskProfiles = {
            ...this.riskProfiles,
            [payload.rpName]: {
                ...(this.riskProfiles[payload.rpName] as RiskProfilesStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingEntityRiskProfiles(payload: { rpName: string, entityId: string }) {
        this.entityRiskProfiles = {
            ...this.entityRiskProfiles,
            [payload.rpName]: {
                ...this.entityRiskProfiles[payload.rpName],
                [payload.entityId]: {
                    status: OperationStatus.loading,
                    list: [],
                },
            },
        };
    },
    finishLoadingEntityRiskProfiles(
        payload: { rpName: string, entityId: string, entityRiskProfiles: EntityRiskProfile[] },
    ) {
        this.entityRiskProfiles = {
            ...this.entityRiskProfiles,
            [payload.rpName]: {
                ...this.entityRiskProfiles[payload.rpName],
                [payload.entityId]: {
                    status: OperationStatus.success,
                    list: payload.entityRiskProfiles,
                },
            },
        };
    },
    failLoadingEntityRiskProfiles(payload: { rpName: string, entityId: string }) {
        this.entityRiskProfiles = {
            ...this.entityRiskProfiles,
            [payload.rpName]: {
                ...this.entityRiskProfiles[payload.rpName],
                [payload.entityId]: {
                    status: OperationStatus.error,
                    list: [],
                },
            },
        };
    },
    startLoadingCurrentRiskProfile(payload: { rpName: string }) {
        this.currentRiskProfile = {
            ...this.currentRiskProfile,
            [payload.rpName]: {
                ...createEmptyCurrentRiskProfileStatus(),
                ...(this.currentRiskProfile[payload.rpName] as RiskProfileStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCurrentRiskProfile(
        payload: { rpName: string, riskProfile: RiskProfile },
    ) {
        this.currentRiskProfile = {
            ...this.currentRiskProfile,
            [payload.rpName]: {
                ...(this.currentRiskProfile[payload.rpName] as RiskProfileStatus),
                status: OperationStatus.success,
                data: payload.riskProfile,
            },
        };
    },
    failLoadingCurrentRiskProfile(payload: { rpName: string }) {
        this.currentRiskProfile = {
            ...this.currentRiskProfile,
            [payload.rpName]: {
                ...(this.currentRiskProfile[payload.rpName] as RiskProfileStatus),
                status: OperationStatus.error,
                data: null,
            },
        };
    },
    startLoadingCriteria(payload: { rpName: string }) {
        this.criteria = {
            ...this.criteria,
            [payload.rpName]: {
                ...createEmptyCriteriaStatus(),
                ...(this.criteria[payload.rpName] as CriteriaStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCriteria(
        payload: { rpName: string, riskProfileId: string, criteria: Criterion[] },
    ) {
        this.criteria = {
            ...this.criteria,
            [payload.rpName]: {
                ...(this.criteria[payload.rpName] as CriteriaStatus),
                status: OperationStatus.success,
                criteria: {
                    ...(this.criteria[payload.rpName]?.criteria || {}),
                    [payload.riskProfileId]: payload.criteria,
                },
            },
        };
    },
    failLoadingCriteria(payload: { rpName: string }) {
        this.criteria = {
            ...this.criteria,
            [payload.rpName]: {
                ...(this.criteria[payload.rpName] as CriteriaStatus),
                status: OperationStatus.error,
                criteria: {
                    ...(this.criteria[payload.rpName]?.criteria || {}),
                },
            },
        };
    },
    startLoadingCriterionMessage(
        payload: { rpName: string, criterionId: string, latestCallNum?: number | null },
    ) {
        const currentState = this.criterionMessage[payload.rpName]
            ? this.criterionMessage[payload.rpName][payload.criterionId]
            : null;
        this.criterionMessage = {
            ...this.criterionMessage,
            [payload.rpName]: {
                ...this.criterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.loading,
                    data: currentState?.data || null,
                    latestCallNum: payload.latestCallNum || null,
                },
            },
        };
    },
    finishLoadingCriterionMessage(
        payload: { rpName: string, criterionId: string, criterionMessage: CriterionMessage },
    ) {
        this.criterionMessage = {
            ...this.criterionMessage,
            [payload.rpName]: {
                ...this.criterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.success,
                    data: payload.criterionMessage,
                    latestCallNum: null,
                },
            },
        };
    },
    failLoadingCriterionMessage(payload: { rpName: string, criterionId: string }) {
        const currentState = this.criterionMessage[payload.rpName]
            ? this.criterionMessage[payload.rpName][payload.criterionId]
            : null;
        this.criterionMessage = {
            ...this.criterionMessage,
            [payload.rpName]: {
                ...this.criterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.error,
                    data: currentState?.data || null,
                    latestCallNum: null,
                },
            },
        };
    },
    startLoadingCriterionTemplates(payload: { rpName: string }) {
        this.criterionTemplates = {
            ...this.criterionTemplates,
            [payload.rpName]: {
                ...createEmptyCriterionTemplatesStatus(),
                ...(this.criterionTemplates[payload.rpName] as CriterionTemplatesStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCriterionTemplates(
        payload: { rpName: string, templates: CriterionTemplate[] },
    ) {
        this.criterionTemplates = {
            ...this.criterionTemplates,
            [payload.rpName]: {
                ...(this.criterionTemplates[payload.rpName] as CriterionTemplatesStatus),
                status: OperationStatus.success,
                list: payload.templates,
            },
        };
    },
    failLoadingCriterionTemplates(payload: { rpName: string }) {
        this.criterionTemplates = {
            ...this.criterionTemplates,
            [payload.rpName]: {
                ...(this.criterionTemplates[payload.rpName] as CriterionTemplatesStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingRequirementTypesPerCountry(payload: { rpName: string }) {
        this.requirementTypesPerCountry = {
            ...this.requirementTypesPerCountry,
            [payload.rpName]: {
                ...createEmptyRequirementTypesPerCountry(),
                ...this.requirementTypesPerCountry[payload.rpName],
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingRequirementTypesPerCountry(
        payload: { rpName: string, requirementTypesPerCountry: RequirementTypesPerCountry },
    ) {
        this.requirementTypesPerCountry = {
            ...this.requirementTypesPerCountry,
            [payload.rpName]: {
                ...this.requirementTypesPerCountry[payload.rpName],
                status: OperationStatus.success,
                data: payload.requirementTypesPerCountry,
            },
        };
    },
    failLoadingRequirementTypesPerCountry(payload: { rpName: string }) {
        this.requirementTypesPerCountry = {
            ...this.requirementTypesPerCountry,
            [payload.rpName]: {
                ...this.requirementTypesPerCountry[payload.rpName],
                status: OperationStatus.error,
                data: {},
            },
        };
    },
    startDeletingRiskProfile(payload: { rpName: string }) {
        this.deletingRiskProfileStatus = {
            ...this.deletingRiskProfileStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishDeletingRiskProfile(
        payload: { rpName: string, riskProfileId: string },
    ) {
        this.deletingRiskProfileStatus = {
            ...this.deletingRiskProfileStatus,
            [payload.rpName]: OperationStatus.success,
        };
        const riskProfiles = this.riskProfiles[payload.rpName]?.list || [];
        const deletedIndex = riskProfiles.findIndex((riskProfile) => riskProfile.id === payload.riskProfileId);
        if (deletedIndex >= 0) {
            const newList = [ ...riskProfiles.slice(0, deletedIndex), ...riskProfiles.slice(deletedIndex + 1) ];
            this.riskProfiles = {
                ...this.riskProfiles,
                [payload.rpName]: {
                    ...(this.riskProfiles[payload.rpName] as RiskProfilesStatus),
                    list: newList,
                },
            };
        }
    },
    failDeletingRiskProfile(payload: { rpName: string }) {
        this.deletingRiskProfileStatus = {
            ...this.deletingRiskProfileStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startCreatingRiskProfile(payload: { rpName: string }) {
        this.creatingRiskProfileStatus = {
            ...this.creatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishCreatingRiskProfile(
        payload: { rpName: string, riskProfile: RiskProfile },
    ) {
        this.creatingRiskProfileStatus = {
            ...this.creatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.success,
        };
        this.currentRiskProfile = {
            ...this.currentRiskProfile,
            [payload.rpName]: {
                ...(this.currentRiskProfile[payload.rpName] as RiskProfileStatus),
                data: payload.riskProfile,
            },
        };
    },
    failCreatingRiskProfile(payload: { rpName: string }) {
        this.creatingRiskProfileStatus = {
            ...this.creatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startCreatingCriteria(payload: { rpName: string }) {
        this.creatingCriteriaStatus = {
            ...this.creatingCriteriaStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishCreatingCriteria(
        payload: { rpName: string },
    ) {
        this.creatingCriteriaStatus = {
            ...this.creatingCriteriaStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failCreatingCriteria(payload: { rpName: string }) {
        this.creatingCriteriaStatus = {
            ...this.creatingCriteriaStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startPatchingCriteria(payload: { rpName: string }) {
        this.patchingCriteriaStatus = {
            ...this.patchingCriteriaStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishPatchingCriteria(
        payload: { rpName: string },
    ) {
        this.patchingCriteriaStatus = {
            ...this.patchingCriteriaStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failPatchingCriteria(payload: { rpName: string }) {
        this.patchingCriteriaStatus = {
            ...this.patchingCriteriaStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startUpdatingRiskProfile(payload: { rpName: string }) {
        this.updatingRiskProfileStatus = {
            ...this.updatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishUpdatingRiskProfile(
        payload: { rpName: string },
    ) {
        this.updatingRiskProfileStatus = {
            ...this.updatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failUpdatingRiskProfile(payload: { rpName: string }) {
        this.updatingRiskProfileStatus = {
            ...this.updatingRiskProfileStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startLoadingEffectiveRiskProfileStatus(payload: { rpName: string }) {
        this.effectiveRiskProfileStatus = {
            ...this.effectiveRiskProfileStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: [],
            },
        };
    },
    finishLoadingEffectiveRiskProfileStatus(
        payload: { rpName: string, list: EffectiveRiskProfiles[] },
    ) {
        this.effectiveRiskProfileStatus = {
            ...this.effectiveRiskProfileStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    failLoadingEffectiveRiskProfileStatus(payload: { rpName: string }) {
        this.effectiveRiskProfileStatus = {
            ...this.effectiveRiskProfileStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingRequirementModels(payload: { rpName: string }) {
        this.requirementModelsStatus = {
            ...this.requirementModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: [],
            },
        };
    },
    finishLoadingRequirementModels(
        payload: { rpName: string, list: TprmRequirementModel[] },
    ) {
        this.requirementModelsStatus = {
            ...this.requirementModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    failLoadingRequirementModels(payload: { rpName: string }) {
        this.requirementModelsStatus = {
            ...this.requirementModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    clearRiskProfiles(rpName: string) {
        this.riskProfiles = omit(this.riskProfiles, [ rpName ]);
    },
    clearEntityRiskProfilesPerEntity(payload: { rpName: string, entityId: string }) {
        const { rpName, entityId } = payload;
        this.entityRiskProfiles = {
            ...this.entityRiskProfiles,
            [rpName]: {
                ...omit(this.entityRiskProfiles[rpName], entityId),
            },
        };
    },
    clearEntityRiskProfilesPerRp(rpName: string) {
        this.entityRiskProfiles = omit(this.entityRiskProfiles, [ rpName ]);
    },
    clearRiskProfileCriteria(payload: { rpName: string, riskProfileId: string }) {
        const { rpName, riskProfileId } = payload;
        this.criteria = {
            ...this.criteria,
            [rpName]: {
                ...(this.criteria[rpName] as CriteriaStatus),
                criteria: omit(this.criteria[rpName].criteria, riskProfileId),
            },
        };
    },
    clearRpCriteria(rpName: string) {
        this.criteria = omit(this.criteria, [ rpName ]);
    },
    clearCriterionTemplates(rpName: string) {
        this.criterionTemplates = omit(this.criterionTemplates, [ rpName ]);
    },
    clearRequirementTypesPerCountry(rpName: string) {
        this.requirementTypesPerCountry = {
            ...this.requirementTypesPerCountry,
            [rpName]: {
                ...this.requirementTypesPerCountry[rpName],
                data: {},
            },
        };
    },
    clearCurrentRiskProfile(rpName: string) {
        this.currentRiskProfile = omit(this.currentRiskProfile, [ rpName ]);
    },
    clearDeletingRiskProfileStatus(rpName: string) {
        this.deletingRiskProfileStatus = omit(this.deletingRiskProfileStatus, [ rpName ]);
    },
    clearCreatingRiskProfileStatus(rpName: string) {
        this.creatingRiskProfileStatus = omit(this.creatingRiskProfileStatus, [ rpName ]);
    },
    clearCreatingCriteriaStatus(rpName: string) {
        this.creatingCriteriaStatus = omit(this.creatingCriteriaStatus, [ rpName ]);
    },
    clearPatchingCriteriaStatus(rpName: string) {
        this.patchingCriteriaStatus = omit(this.patchingCriteriaStatus, [ rpName ]);
    },
    clearUpdatingRiskProfileStatus(rpName: string) {
        this.updatingRiskProfileStatus = omit(this.updatingRiskProfileStatus, [ rpName ]);
    },
    clearEffectiveRiskProfileStatus(rpName: string) {
        this.effectiveRiskProfileStatus = omit(this.effectiveRiskProfileStatus, [ rpName ]);
    },
    clearCriterionMessage(rpName: string) {
        this.criterionMessage = omit(this.criterionMessage, [ rpName ]);
    },
    clearRequirementModelsStatus(rpName: string) {
        this.requirementModelsStatus = omit(this.requirementModelsStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: DecisioningCriteriaRequirement) => ({
    async loadRiskProfiles(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.riskProfiles[rpName] || createEmptyRiskProfilesStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRiskProfiles({ rpName });
        try {
            const latestStatus =
                this.state.riskProfiles[rpName] || createEmptyRiskProfilesStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const riskProfiles = await rpweb.getTprmRiskProfiles(rpName);
            this.mutations.finishLoadingRiskProfiles({
                rpName,
                riskProfiles,
            });
        } catch (error) {
            this.mutations.failLoadingRiskProfiles({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadEntityRiskProfiles(payload: { rpName: string, entityId: string }) {
        const { rpName, entityId } = payload;
        const currentRpStatus = this.state.entityRiskProfiles[rpName] || {};
        const currentStatus = currentRpStatus[entityId] || { status: OperationStatus.uninitialized, list: [] };
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingEntityRiskProfiles({ rpName, entityId });
        try {
            const latestRpStatus = this.state.entityRiskProfiles[rpName] || {};
            const latestStatus =
                latestRpStatus[entityId] || { status: OperationStatus.uninitialized, list: [] };
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const entityRiskProfiles = await rpweb.getTprmEntityRiskProfiles(rpName, entityId);
            this.mutations.finishLoadingEntityRiskProfiles({
                rpName,
                entityId,
                entityRiskProfiles,
            });
        } catch (error) {
            this.mutations.failLoadingEntityRiskProfiles({ rpName, entityId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadRiskProfile(payload: { rpName: string, id: string }) {
        const { rpName, id } = payload;
        const currentStatus =
            this.state.currentRiskProfile[rpName] || createEmptyCurrentRiskProfileStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCurrentRiskProfile({ rpName });
        try {
            const latestStatus =
                this.state.currentRiskProfile[rpName] || createEmptyCurrentRiskProfileStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const riskProfile = await rpweb.getTprmRiskProfile(rpName, id);
            this.mutations.finishLoadingCurrentRiskProfile({ rpName, riskProfile });
        } catch (error) {
            await handleActionError(this, this.mutations.failLoadingCurrentRiskProfile, { rpName }, error);
        }
    },
    async loadCriteria(payload: { rpName: string, riskProfileId: string }) {
        const { rpName, riskProfileId } = payload;
        const currentStatus =
            this.state.criteria[rpName] || createEmptyCriteriaStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCriteria({ rpName });
        try {
            const latestStatus =
                this.state.criteria[rpName] || createEmptyCriteriaStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const criteria = await rpweb.getTprmCriteria(rpName, riskProfileId);
            this.mutations.finishLoadingCriteria({ rpName, riskProfileId, criteria });
        } catch (error) {
            await handleActionError(this, this.mutations.failLoadingCriteria, { rpName }, error);
        }
    },
    async loadCriterionTemplates(payload: { rpName: string, countryCode: string }) {
        const { rpName, countryCode } = payload;
        const currentStatus =
            this.state.criterionTemplates[rpName] || createEmptyCriterionTemplatesStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCriterionTemplates({ rpName });
        try {
            const latestStatus =
                this.state.criterionTemplates[rpName] || createEmptyCriterionTemplatesStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const templates = await rpweb.getTprmCriteriaTemplatesPerCountry(rpName, countryCode);
            this.mutations.finishLoadingCriterionTemplates({
                rpName,
                templates,
            });
        } catch (error) {
            this.mutations.failLoadingCriterionTemplates({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCriterionMessage(
        payload: {
            rpName: string;
            criterion: CriterionInput | Criterion;
            countryCode: string;
        },
    ) {
        const { rpName, criterion, countryCode } = payload;
        const criterionId = 'id' in criterion ? criterion.id : `${criterion.coverageType}-${criterion.field}`;
        const currentState = this.state.criterionMessage?.[rpName]?.[criterionId] ||
            { status: OperationStatus.uninitialized, data: null };
        const localNum = (currentState.latestCallNum ?? 0) + 1;
        this.mutations.startLoadingCriterionMessage({ rpName, criterionId, latestCallNum: localNum });
        try {
            const criterionMessage = await rpweb.postTprmCriterionForMessage(rpName, criterion, countryCode);
            const latestState = this.state.criterionMessage?.[rpName]?.[criterionId] ||
                { status: OperationStatus.uninitialized, data: null };
            if (latestState.latestCallNum === localNum) {
                this.mutations.finishLoadingCriterionMessage({ rpName, criterionId, criterionMessage });
            }
        } catch (error) {
            this.mutations.failLoadingCriterionMessage({ rpName, criterionId });
            console.error(error);
        }
    },
    async loadRequirementTypesPerCountry(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.requirementTypesPerCountry[rpName] || createEmptyRequirementTypesPerCountry();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequirementTypesPerCountry({ rpName });
        try {
            const latestStatus =
                this.state.requirementTypesPerCountry[rpName] || createEmptyRequirementTypesPerCountry();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const requirementTypesPerCountry = await rpweb.getRequirementTypesPerCountry(rpName);
            this.mutations.finishLoadingRequirementTypesPerCountry({ rpName, requirementTypesPerCountry });
        } catch (error) {
            this.mutations.failLoadingRequirementTypesPerCountry({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async deleteRiskProfile(payload: { rpName: string, riskProfileId: string }) {
        const { rpName, riskProfileId } = payload;
        const currentStatus =
            this.state.deletingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startDeletingRiskProfile({ rpName });
        try {
            const latestStatus =
                this.state.deletingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.deleteTprmRiskProfile(rpName, riskProfileId);
            this.mutations.finishDeletingRiskProfile({
                rpName,
                riskProfileId: payload.riskProfileId,
            });
        } catch (error) {
            this.mutations.failDeletingRiskProfile({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async createRiskProfile(payload: { rpName: string, riskProfile: RiskProfileInput }) {
        const { rpName, riskProfile } = payload;
        const currentStatus =
            this.state.creatingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startCreatingRiskProfile({ rpName });
        try {
            const latestStatus =
                this.state.creatingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            const savedRiskProfile = await rpweb.createTprmRiskProfile(rpName, riskProfile);
            this.mutations.finishCreatingRiskProfile({ rpName, riskProfile: savedRiskProfile });
        } catch (error) {
            this.mutations.failCreatingRiskProfile({ rpName });
            console.error('Failed to create risk profile', error);
            const message = error.error === OperationError.keyConflict
                ? `A risk profile with the name ${riskProfile.displayName} already exists. Please try a different name.`
                : 'Something went wrong, please try again or contact support';
            await getSnackbarActions(this).displaySnackbar({ permanent: false, message });
        }
    },
    async createCriteria(payload: {
        rpName: string;
        riskProfileId: string;
        criteria: CriterionInput[];
    }) {
        const { rpName, riskProfileId, criteria } = payload;
        const currentStatus =
            this.state.creatingCriteriaStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startCreatingCriteria({ rpName });
        try {
            const latestStatus =
                this.state.creatingCriteriaStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.createTprmCriteria(rpName, riskProfileId, criteria);
            this.mutations.finishCreatingCriteria({ rpName });
        } catch (error) {
            this.mutations.failCreatingCriteria({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async patchCriteria(payload: {
        rpName: string;
        riskProfileId: string;
        patches: any[];
    }) {
        const { rpName, riskProfileId, patches } = payload;
        const currentStatus =
            this.state.patchingCriteriaStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startPatchingCriteria({ rpName });
        try {
            const latestStatus =
                this.state.patchingCriteriaStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.patchTprmCriteria(rpName, riskProfileId, patches);
            this.mutations.finishPatchingCriteria({ rpName });
        } catch (error) {
            this.mutations.failPatchingCriteria({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateRiskProfile(payload: { rpName: string, riskProfile: RiskProfile }) {
        const { rpName, riskProfile } = payload;
        const currentStatus =
            this.state.updatingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startUpdatingRiskProfile({ rpName });
        try {
            const latestStatus =
                this.state.updatingRiskProfileStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.updateTprmRiskProfile(rpName, riskProfile);
            this.mutations.finishUpdatingRiskProfile({ rpName });
        } catch (error) {
            this.mutations.failUpdatingRiskProfile({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadEffectiveRiskProfiles(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.effectiveRiskProfileStatus[rpName] || createEmptyEffectiveRiskProfileStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingEffectiveRiskProfileStatus({ rpName });
        try {
            const latestStatus =
                this.state.effectiveRiskProfileStatus[rpName] || createEmptyRequirementTypesPerCountry();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const list = await rpweb.getTprmEffectiveRiskProfiles(rpName);
            this.mutations.finishLoadingEffectiveRiskProfileStatus({ rpName, list });
        } catch (error) {
            this.mutations.failLoadingEffectiveRiskProfileStatus({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadRequirementModels(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.requirementModelsStatus[rpName] || createEmptyRequirementModelsStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequirementModels({ rpName });
        try {
            const latestStatus =
                this.state.requirementModelsStatus[rpName] || createEmptyRequirementModelsStatus();
            if (latestStatus.status !== OperationStatus.loading
                && latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const list = await rpweb.getTprmRequirementTypeModels(rpName);
            this.mutations.finishLoadingRequirementModels({ rpName, list });
        } catch (error) {
            this.mutations.failLoadingRequirementModels({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    clearRiskProfiles(payload: { rpName: string }) {
        this.mutations.clearRiskProfiles(payload.rpName);
    },
    clearCriterionTemplates(payload: { rpName: string }) {
        this.mutations.clearCriterionTemplates(payload.rpName);
    },
    clearRequirementTypesPerCountry(payload: { rpName: string }) {
        this.mutations.clearRequirementTypesPerCountry(payload.rpName);
    },
    clearCurrentRiskProfiles(payload: { rpName: string }) {
        this.mutations.clearCurrentRiskProfile(payload.rpName);
    },
    clearDeletingRiskProfileStatus(payload: { rpName: string }) {
        this.mutations.clearDeletingRiskProfileStatus(payload.rpName);
    },
    clearCreatingRiskProfileStatus(payload: { rpName: string }) {
        this.mutations.clearCreatingRiskProfileStatus(payload.rpName);
    },
    clearCreatingCriteriaStatus(payload: { rpName: string }) {
        this.mutations.clearCreatingCriteriaStatus(payload.rpName);
    },
    clearPatchingCriteriaStatus(payload: { rpName: string }) {
        this.mutations.clearPatchingCriteriaStatus(payload.rpName);
    },
    clearUpdatingRiskProfileStatus(payload: { rpName: string }) {
        this.mutations.clearUpdatingRiskProfileStatus(payload.rpName);
    },
    clearRpCriteria(payload: { rpName: string }) {
        this.mutations.clearRpCriteria(payload.rpName);
    },
    clearRiskProfileCriteria(payload: { rpName: string, riskProfileId: string }) {
        this.mutations.clearRiskProfileCriteria({ rpName: payload.rpName, riskProfileId: payload.riskProfileId });
    },
    clearRpEntityRiskProfiles(payload: { rpName: string }) {
        this.mutations.clearEntityRiskProfilesPerRp(payload.rpName);
    },
    clearEntityRiskProfiles(payload: { rpName: string, entityId: string }) {
        this.mutations.clearEntityRiskProfilesPerEntity(payload);
    },
    clearEffectiveRiskProfilesStatus(payload: { rpName: string }) {
        this.mutations.clearEffectiveRiskProfileStatus(payload.rpName);
    },
    clearCriterionMessage(payload: { rpName: string }) {
        this.mutations.clearCriterionMessage(payload.rpName);
    },
    clearRequirementModelsStatus(payload: { rpName: string }) {
        this.mutations.clearRequirementModelsStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
