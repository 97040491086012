import {
    NotificationMailAttachment,
} from '@evidentid/eidwww-service-api-client/interfaces/CriminalProfileConfiguration';

export interface SupportTimeframe {
    description: string;
    enabled: boolean;
    name: string;
    supportDays: string;
    supportHours: string;
    supportTimezone: string;
}

export interface ConfigRelyingParty {
    _id: string;
    displayName: string;
    distinguishedName: string;
    iconUri: string; // URL
    isCustomer: boolean;
    locale: string;
    name: string;
    primaryColor: string; // HEX color
    status: string; // "active"
    supportEmail: string | null; // E-mail
    supportPhone: string | null;
    supportUrl: string | null; // URL
    supportUrlTitle: string | null;
    supportTimeframes: SupportTimeframe[];
    tcSigned: string; // ISO Date
    tcSigner: string; // E-mail
    timezone: string;
    wordmarkUri: string; // URL
}

export type ConfigRelyingPartyInput = Omit<ConfigRelyingParty,
    '_id' | 'distinguishedName' | 'isCustomer' | 'name' | 'status'>;

export interface Branding {
    _id: string;
    enabled: boolean;
    isDefault: boolean;
    barBelowWordmarkBgColor: string; // HEX color
    boxBehindButtonBgColor: string; // HEX color
    boxBehindButtonFgColor: string; // HEX color
    buttonBgColor: string; // HEX color
    buttonFgColor: string; // HEX color
    description: string;
    fromEmail: string; // E-mail
    fromName: string;
    name: string;
    noticeFCRAEmail: string; // E-mail
    replyToEmail: string; // E-mail
    replyToName: string;
}

export interface RpFulfillmentConfig {
    enabled: boolean;
    /** Hexadecimal format **/
    primaryColor: string;
    /** Hexadecimal format **/
    secondaryColor: string;
    /** HTML format **/
    text: string; // HTML
    buttonLabel: string;
    fulfillmentUrl: string;
    logoUri: string;
}

export type BrandingInput = Omit<Branding, '_id'>;

export enum SchedulePatternType {
    interval = 'interval',
    schedule = 'pattern',
    sequence = 'cadence',
}

export interface Cadence {
    _id: string;
    name: string;
    description: string;
    enabled: boolean;
    limit: number;
    schedulePattern: string; // JSON?
    schedulePatternType: SchedulePatternType;
    timezone: string;
}

export type CadenceInput = Omit<Cadence, '_id'>;

export enum NotificationName {
    newRequest = 'newRequest',
    newRequestReminder = 'newRequestReminder',
    expiringRequest = 'expiringRequest',
    expiringRequestReminder = 'expiringRequestReminder',
    nonCompliantRequest = 'nonCompliantRequest',
    nonCompliantRequestReminder = 'nonCompliantRequestReminder',
    preAdverseAction = 'preAdverseAction',
    postAdverseAction = 'postAdverseAction',
}

export interface NotificationType {
    _id: string;
    isReminder: boolean;
    hasConfiguredRecipients: boolean;
    targetAudience: string; // FIXME: Enum? "idOwner"
    name: NotificationName;
    description: string;
}

export interface MailTemplatePlaceholder {
    _id: string;
    name: string;
    description: string | null;
}

export interface MailTemplatePlaceholderUsage {
    _id: string;
    enabled: boolean;
    mailTemplatePlaceholder: MailTemplatePlaceholder;
    mailTemplatePlaceholderOverrides: any[]; // FIXME?
}

export interface ConfigApplication {
    _id: string;
    name: string;
    description: string;
    distinguishedName: string | null;
}

export interface MailTemplate {
    _id: string;
    enabled: boolean;
    slug: string;
    name: string;
    description: string | null;
    mailTemplatePlaceholderUsages: MailTemplatePlaceholderUsage[];
    application: ConfigApplication;
}

export interface NotificationHandler {
    _id: string;
    enabled: boolean;
    name: string;
    description: string;
}

export interface NotificationPreference {
    _id: string;
    enabled: boolean;
    subject: string;
    name: string;
    relyingParty: ConfigRelyingParty;
    notificationType: NotificationType;
    notificationRecipients: any[]; // FIXME?
    mailTemplate: MailTemplate;
    mailBody: string;
    mailAttachments: {name: string, url: string, enabled: boolean}[];
    includePdfs: null; // FIXME?
    handler: NotificationHandler;
    cadenceConfig: Cadence | null;
    sequenceOrder: number;
    sequenceConfig: null; // FIXME?
    branding: Branding;
}

export interface BasicNotificationPreference {
    id: string;
    name: string;
}

export interface NotificationPreferenceDTO {
    subject: string;
    name: string;
    mailBody: string;
    mailAttachments: NotificationMailAttachment[];
}

export interface MacroTypeSignature {
    id: string;
    name: string;
}

export interface Macro {
    id: string;
    type?: MacroTypeSignature;
    title: string;
    subject?: string;
    content: string;
    createdAt: Date;
    updatedAt?: Date;
    attachments: NotificationMailAttachment[];
}

export interface MacroUpdate {
    title: string;
    content: string;
    subject?: string;
    attachments: NotificationMailAttachment[];
}
