import _Vuex from 'vuex';
import _Router from 'vue-router';
import type { RouteConfig } from 'vue-router/types';
import { createStore as _createStore } from '@evidentid/vue-commons/store';
import {
    ApplicationFactory,
    ExtractModuleDemandedOptions,
    FinalApplication,
    PartialModuleDeclaration,
} from '../types/ModuleDeclaration';
import { BaseApplicationOptions } from '../types/Application';
import { SealedModule } from './SealedModule';
import { Application } from './Application';
import { getVueConstructor } from './getDefaultVueConstructor';

export class ApplicationBuilder<T extends PartialModuleDeclaration = {}> {
    private modules: SealedModule[] = [];

    // TODO: Improve type definitions, so it will show error about missing demands
    // Make sure that it will be performant - earlier implementation failed,
    // so autocomplete from inferred type wasn't working
    public use<D extends PartialModuleDeclaration>(appModule: SealedModule<D>): ApplicationBuilder<T & D> {
        this.modules.push(appModule);
        // @ts-ignore: simulating change
        return this;
    }

    public createFactory(): ApplicationFactory<T> {
        // Copy list of modules, so it will be sealed
        const modules = [ ...this.modules ];

        return async (_options: BaseApplicationOptions & ExtractModuleDemandedOptions<T>) => {
            // Build final options
            const options = {
                ..._options,
                Vue: _options.Vue || getVueConstructor(),
                Vuex: _options.Vuex || _Vuex,
                Router: _options.Router || _Router,
            };

            const initialize = (app: any) => modules.reduce(
                (promise, next) => promise.then(() => next.registerInjections(app)),
                Promise.resolve(),
            );

            const createStore = (app: any) => _createStore({
                strict: process.env.NODE_ENV !== 'production',
                actions: {},
                mutations: {},
                state: {},
                modules: modules.reduce((result, appModule) => ({
                    ...result,
                    ...appModule.buildStoreModules(app),
                }), {}),
            }, options.Vuex.Store);

            const createVueContext = (app: any) => modules.reduce((result, appModule) => ({
                ...result,
                ...appModule.buildVueInjections(app),
            }), {});

            const createRouter = (app: any) => new options.Router({
                routes: modules.reduce((result, appModule) => [
                    ...result,
                    ...appModule.buildRouteConfig(app),
                ], [] as RouteConfig[]),
            });

            const buildSlotComponents = () => modules.reduce((result, appModule) => {
                const components = appModule.getSlotComponents();
                for (const slot of Object.keys(components)) {
                    if (!result[slot]) {
                        result[slot] = [];
                    }
                    result[slot] = [ ...result[slot], ...components[slot] ];
                }
                return result;
            }, {} as Record<string, any[]>);

            const buildProceduresMap = () => modules.reduce((result, appModule) => ({
                ...result,
                ...appModule.getProceduresMap(),
            }), {} as Record<string, any>);

            const app = new Application(options, createVueContext) as any as FinalApplication<T>;

            // Initialize
            // @ts-ignore: it's hidden property
            await app.initialize({ initialize, createStore, createRouter, buildSlotComponents, buildProceduresMap });

            // Execute all set-up functions
            for (const appModule of modules) {
                appModule.execute(app as any);
            }

            return app;
        };
    }
}
