<template>
    <div class="ApiSettings">
        <Page :loading="isLoading" title="API Settings">
            <ApiKeySection
                :account-name="apiKeyStatus.data.accountName"
                :api-key="apiKeyStatus.data.key"
            />
            <WebhooksSection
                v-if="webhookTypesStatus.list.length > 0"
                class="mt-6"
                :webhooks="webhooksStatus.list"
                :webhook-types="webhookTypesStatus.list"
                :deleting="deletingWebhook"
                :updating="updatingWebhook"
                @change="updateWebhook"
                @delete="deleteWebhook"
            />
        </Page>
    </div>
</template>

<style lang="scss">
.ApiSettings {
    // TODO[PRODUCT-20869]: remove temp solution unblocks evie from view content once long term solution provided
    .MainLayout__content {
        padding-bottom: 100px;
    }
}
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import Page from '@/layouts/Page.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { InsuranceApiKey, InsuranceWebhook } from '@evidentid/tprm-portal-lib/models/api-settings';
    import ApiKeySection from '@/modules/api-settings/components/api-key-section/ApiKeySection.vue';
    import WebhooksSection from '@/modules/api-settings/components/webhooks-section/WebhooksSection.vue';
    import {
        InsuranceWebhookType,
    } from '@evidentid/tprm-portal-lib/models/api-settings/InsuranceWebhookType.model';

    @Component({
        components: { Page, ApiKeySection, WebhooksSection },
    })
    export default class ApiSettings extends Vue {
        private get isLoading(): boolean {
            return this.apiKeyStatus.status === OperationStatus.loading ||
                this.webhooksStatus.status === OperationStatus.loading ||
                this.webhookTypesStatus.status === OperationStatus.loading;
        }

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get apiKeyStatus(): { status: OperationStatus, data: InsuranceApiKey } {
            return this.$store.state.apiSettings.apiKey[this.rpName] || {
                status: OperationStatus.uninitialized,
                data: {},
            };
        }

        private get webhooksStatus(): { status: OperationStatus, list: InsuranceWebhook[] } {
            return this.$store.state.apiSettings.webhooks[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get webhookTypesStatus(): { status: OperationStatus, list: InsuranceWebhookType[] } {
            return this.$store.state.apiSettings.webhookTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get updateWebhookStatus(): OperationStatus {
            return this.$store.state.apiSettings.patchWebhookStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get createWebhookStatus(): OperationStatus {
            return this.$store.state.apiSettings.createWebhookStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get deleteWebhookStatus(): OperationStatus {
            return this.$store.state.apiSettings.deleteWebhookStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get updatingWebhook(): boolean {
            return this.updateWebhookStatus === OperationStatus.loading ||
                this.createWebhookStatus === OperationStatus.loading;
        }

        private get deletingWebhook(): boolean {
            return this.deleteWebhookStatus === OperationStatus.loading;
        }

        @Watch('$rp.current', { immediate: true })
        private onRpChanges(): void {
            this.loadApiKey();
            this.loadWebhooks();
            this.loadWebhookTypes();
        }

        private loadApiKey() {
            if (this.apiKeyStatus.status !== OperationStatus.loading) {
                this.$store.actions.apiSettings.loadApiKey({ rpName: this.rpName });
            }
        }

        private loadWebhooks() {
            if (this.webhooksStatus.status !== OperationStatus.loading) {
                this.$store.actions.apiSettings.loadWebhooks({ rpName: this.rpName });
            }
        }

        private loadWebhookTypes() {
            if (this.webhookTypesStatus.status !== OperationStatus.loading) {
                this.$store.actions.apiSettings.loadWebhookTypes({ rpName: this.rpName });
            }
        }

        private async updateWebhook(webhook: InsuranceWebhook): Promise<void> {
            if (this.updateWebhookStatus !== OperationStatus.loading) {
                if (webhook.id) {
                    await this.$store.actions.apiSettings.patchWebhook({ rpName: this.rpName, data: webhook });
                } else {
                    await this.$store.actions.apiSettings.createWebhook({ rpName: this.rpName, data: webhook });
                }
            }
        }

        private async deleteWebhook(webhook: InsuranceWebhook): Promise<void> {
            if (this.deleteWebhookStatus !== OperationStatus.loading) {
                await this.$store.actions.apiSettings.deleteWebhook({ rpName: this.rpName, data: webhook });
            }
        }

        private destroyed() {
            this.$store.actions.apiSettings.clearApiKey({ rpName: this.rpName });
            this.$store.actions.apiSettings.clearWebhooks({ rpName: this.rpName });
            this.$store.actions.apiSettings.clearWebhookTypes({ rpName: this.rpName });
            this.$store.actions.apiSettings.clearCreatingWebhookStatus({ rpName: this.rpName });
            this.$store.actions.apiSettings.clearPatchingWebhookStatus({ rpName: this.rpName });
            this.$store.actions.apiSettings.clearDeletingWebhookStatus({ rpName: this.rpName });
        }
    }
</script>
