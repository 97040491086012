import IdleTimeTracker from '@evidentid/idle-time-tracker';
import { IamClientUser } from '@evidentid/iam-client/types';
import { createModuleBuilder } from '../../app';
import { AuthModule } from '../auth';
import { SnackbarModule } from '../snackbar';

export function createIdleLogoutModule() {
    return createModuleBuilder()
        .demandOptions<{ logoutAfterMs: number }>()
        .demand<AuthModule>()
        .demand<SnackbarModule>()
        .execute((app) => {
            const idleTimeTracker = new IdleTimeTracker({ id: '$idleLogoutTimestamp' });
            let idleLogoutTimeout: any = null;
            let previousUser: IamClientUser | null = null;

            async function idleLogoutTick(): Promise<void> {
                if (idleTimeTracker.get() > app.options.logoutAfterMs) {
                    await app.store.actions.user.logOut();
                    app.store.actions.snackbar.displaySnackbar({
                        message: 'You have been logged out due to inactivity.',
                        success: false,
                        permanent: true,
                    });
                    return;
                }
                // Schedule next check
                idleLogoutTimeout = setTimeout(idleLogoutTick, 1000);
            }

            async function startTracking(): Promise<void> {
                idleTimeTracker.start();
                await idleLogoutTick();
            }

            function stopTracking(): void {
                idleTimeTracker.stop();
                clearTimeout(idleLogoutTimeout);
            }

            async function onUserChange(user: IamClientUser | null): Promise<void> {
                if (!user) {
                    stopTracking();
                } else if (!previousUser) {
                    await startTracking();
                }
                previousUser = user;
            }
            app.observer.onChange((state) => state.user.data, onUserChange);
            app.once('afterDestroy', stopTracking);
            onUserChange(app.store.state.user.data);
        })
        .end();
}

export type IdleLogoutModule = ReturnType<typeof createIdleLogoutModule>;
