import noop from 'lodash/noop';
import { IamClientAdapter, IamClientUser } from '../types';

export class IamClientMockAdapter implements IamClientAdapter {
    public readonly loginMethods = {
        google: async () => {
            // Do nothing
        },
        facebook: async () => {
            // Do nothing
        },
        credentials: async () => {
            // Do nothing
        },
    };

    public readonly registerMethods = {
        google: this.loginMethods.google,
        facebook: this.loginMethods.facebook,
        credentials: async () => {
            // Do nothing
        },
    };

    public async requestPermissions(): Promise<boolean> {
        return true;
    }

    public async hasPermissions(): Promise<boolean | null> {
        return true;
    }

    public subscribe(): () => void {
        return noop;
    }

    public async initialize(): Promise<void> {
        // Do nothing
    }

    public async getUser(): Promise<IamClientUser | null> {
        return null;
    }

    public async getAccessToken(): Promise<string | null> {
        return null;
    }

    public async getIdToken(): Promise<null> {
        return null;
    }

    public async refreshToken(): Promise<void> {
        // Do nothing
    }

    public async logOut(): Promise<void> {
        // Do nothing
    }

    public async sendEmailVerification(): Promise<void> {
        // Do nothing
    }

    public async resetPassword(): Promise<void> {
        // Do nothing
    }

    public async destroy(): Promise<void> {
        // Do nothing
    }
}
