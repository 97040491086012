import Ajv from 'ajv';
import isEqual from 'lodash/isEqual';
import JsonSchema from './interfaces/JsonSchema';

const ajv = new Ajv({ format: 'full' });
const ajvAllErrors = new Ajv({ format: 'full', allErrors: true });

ajv.addFormat('color', (value: any) => {
    if (typeof value !== 'string') {
        return false;
    }
    // TODO: Support RGB color too?
    return /^#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(value);
});

ajv.addFormat('html', (value: any) => {
    if (typeof value !== 'string') {
        return false;
    }
    return true;
});

ajv.addFormat('phone', (value: any) => {
    // @ts-ignore:
    if (!window.intlTelInputUtils) {
        throw new Error('You need to load intl-tel-input utils when you would like to use phone Ajv formatter');
    }
    const allowCharRegx = /^[+\d().\s-]*$/;
    // @ts-ignore:
    return typeof value === 'string' && allowCharRegx.test(value) && window.intlTelInputUtils.isValidNumber(value);
});

function isValidHttpUrl(url: string): boolean {
    try {
        // eslint-disable-next-line no-new
        new URL(url);
    } catch (error) {
        return false;
    }
    return /^https?:\/\//.test(url);
}

ajv.addFormat('image-url', (value: any) => {
    if (typeof value !== 'string') {
        return false;
    } else if (/^data:image\/[^;]+;base64,/.test(value)) {
        return true;
    } else if (isValidHttpUrl(value)) {
        return true;
    }
    return false;
});

export default function createValueValidator(schema: JsonSchema): (value: any) => boolean {
    if ('const' in schema) {
        const expectedValue = schema.const;
        return (value: any) => isEqual(expectedValue, value);
    }
    return ajv.compile(schema) as (value: any) => boolean;
}

export function createValueValidatorAllErrors(schema: JsonSchema) {
    return ajvAllErrors.compile(schema);
}
