<template>
    <div class="PolicyInfo">
        <div class="PolicyInfo__bodyBasicInfo">
            <span class="PolicyInfo__bodySectionTitle">Entity</span>
            <PolicyRecord
                title="Name"
                :value="requirement.policy.insured"
                :evaluation-error="getEvaluationResult('policy/insured')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Status"
                :value="coverageStatus ? 'Active' : 'Inactive'"
                :evaluation-error="getEvaluationResult('details/coverageStatus')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Currency"
                :value="currencyLabel"
                :evaluation-error="getEvaluationResult('policy/currency')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </div>
        <div
            v-if="producerDetails.length > 0"
            data-test-id="PolicyInfo__producerSection"
            class="PolicyInfo__bodyBasicInfo grid grid-cols-5"
        >
            <span class="PolicyInfo__bodySectionTitle col-span-1">PRODUCER</span>
            <div class="grid grid-cols-4 gap-y-4 col-span-4">
                <PolicyRecord
                    v-for="producerDetail in producerDetails"
                    :key="producerDetail.title"
                    :class="{ 'col-span-4': producerDetail.title === 'Producer Address' }"
                    :value="producerDetail.value"
                    :title="producerDetail.title"
                />
            </div>
        </div>
        <PolicyInfoCarrierInfo
            v-if="isShowingCarrierInfo"
            class="PolicyInfo__bodyBasicInfo"
            :carrier="requirement.policy.carrier"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoOtherCellsInfo
            :evaluation-errors="evaluationErrors"
            :requirement="requirement"
            :limits="limits"
            :evaluation-results="evaluationResults"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${requirement.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleRequirementFields"
            :schema="singleRequirementFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${requirement.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleCoverageEndorsementsFields"
            :schema="singleCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfMultiPropsTprmFields > 0"
            :id="`#/${requirement.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiPropsTprmFields"
            :schema="multiPropsTprmFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfEndorsementsMultiRequirementFields > 0"
            :id="`#/${requirement.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiCoverageEndorsementsFields"
            :schema="multiCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            :start-index="numberOfMultiPropsTprmFields + 1"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <div
            v-if="multiFieldCriteria.length > 0 || requirement.endorsements.length > 0"
            class="PolicyInfo__multiCoverageInfoHeader"
        >
            <span class="PolicyInfo__bodySectionTitle">CRITERIA SPANNING MULTIPLE FIELDS</span>
            <div
                v-tooltip="multiCoverageTooltipDescription"
                class="PolicyInfo__hintIcon"
            >
                <FontAwesomeIcon :icon="faQuestionCircle" />
            </div>
        </div>
        <EndorsementsView
            :endorsements="requirement.endorsements"
            :evaluation-error="getEvaluationResult('details/endorsements')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <MultiFieldCriterion
            v-for="(criterion, index) in multiFieldCriteria"
            :key="criterion.name"
            :criterion="criterion"
            :index="index + requirement.endorsements.length + 1"
            v-on="$listeners"
        />
        <CollateralList
            v-if="collaterals.length > 0"
            :requirement-type="requirement.coverageType"
            :collaterals="collaterals"
            :collateral-entities="collateralEntities"
            :evaluation-errors="evaluationErrors"
            @grant-evaluate-entity-exception="grantEvaluateEntityException"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoNonExtraction
            :get-evaluation-result="getEvaluationResult"
            :non-extraction-results="nonExtractionResults"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import isNil from 'lodash/isNil';
    import omitBy from 'lodash/omitBy';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { CollateralEntity } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import {
        ExceptionInput,
        EvaluationResult,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import { BrokerInfo } from '@/modules/dashboard/types';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

    import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
    import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/entity-details/constant';
    import { TprmFieldCollateral } from '@/modules/entity-details/models/TprmFieldCollateral.model';
    import {
        combineSpecialTprmFieldValues,
    } from '@/modules/entity-details/utils/combineSpecialTprmFieldValues/combineSpecialTprmFieldValues';
    import { currencyFormatter } from '@/modules/entity-details/utils/currencyFormatter';
    import { getBasicSchemaByValue } from '@/modules/entity-details/utils/jsonSchemaUtilities';
    import {
        transformValuesWithIsPresent,
    } from '@/modules/entity-details/utils/transformValuesWithIsPresent/transformValuesWithIsPresent';
    import CollateralList from '@/modules/entity-details/components/CollateralList/CollateralList.vue';
    import EndorsementsView from '@/modules/entity-details/components/EndorsementsView/EndorsementsView.vue';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import MultiFieldCriterion from '@/modules/entity-details/components/MultiFieldCriterion/MultiFieldCriterion.vue';
    import PolicyRecord from '@/modules/entity-details/components/PolicyRecord/PolicyRecord.vue';
    import { JsonSchemaView } from '@/modules/entity-details/components/JsonSchemaView';
    import {
        getEvaluationResultsFromEvaluationErrors,
        getMultiFieldCriteria, parseFieldValue,
        shouldOmitFieldsForRendering,
    } from '../../utils';
    import PolicyInfoCarrierInfo from '../PolicyInfoCarrierInfo/PolicyInfoCarrierInfo.vue';
    import PolicyInfoNonExtraction from '..//PolicyInfoNonExtraction/PolicyInfoNonExtraction.vue';
    import PolicyInfoOtherCellsInfo from '../PolicyInfoOtherCellsInfo/PolicyInfoOtherCellsInfo.vue';
    import { isShowingCarrierInfo } from '../PolicyInfoCarrierInfo/utils';
    import { typeOfInsuranceAttributes } from '../PolicyInfoOtherCellsInfo/utils';
    import {
        omitMultiPropFieldsFromSchema, pickMultiPropFieldsFromSchema, getProducerInfo, getBrokerInfo, shouldBeOmitted,
    } from './utils';
    import {
        NonExtractionResults,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review/actions/non-extraction-resolution/NonExtractionResults.model';
    import {
        EntityRequirement,
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            EndorsementsView,
            EvaluationError,
            FontAwesomeIcon,
            PolicyRecord,
            JsonSchemaView,
            MultiFieldCriterion,
            CollateralList,
            PolicyInfoCarrierInfo,
            PolicyInfoOtherCellsInfo,
            PolicyInfoNonExtraction,
        },
    })
    export default class PolicyInfo extends Vue {
        @Prop({ type: Object, required: true })
        private requirement!: EntityRequirement;

        @Prop({ type: Object })
        private requirementModel?: TprmRequirementModel;

        @Prop({ type: Object, default: null })
        private brokerInfo!: BrokerInfo | null;

        @Prop({ type: Object, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: Array, default: () => [] })
        private evaluationResults!: EvaluationResult[];

        @Prop({ type: Array, default: () => [] })
        private allRequirements!: EntityRequirementDetails[];

        @Prop({ type: Array as PropType<CollateralEntity[]>, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Prop({ type: Object as PropType<NonExtractionResults>, default: undefined })
        private nonExtractionResults?: NonExtractionResults;

        private faQuestionCircle = faQuestionCircle;
        private multiCoverageTooltipDescription: string = 'Any criteria within this requirement that contains multiple fields are shown here ' +
            '(i.e. General Aggregate Limit covered by Umbrella Excess).';

        private fieldsToHide = [
            'coverageStatus', 'endorsements', 'collateral', 'brokerName', 'producerName',
            'producerAddress', 'producerContactName', 'producerEmail', 'producerTelephone',
        ];

        public get limits(): {
            name: string;
            value: number | string | unknown;
            evaluationError: FieldEvaluationResultError | null;
        }[] {
            return this.requirement
                ? [ ...Object.entries(this.requirement.details),
                    ...Object.entries(this.requirement.details.endorsements || {}) ]
                    .filter(([ key ]) => this.isLimit(key))
                    .filter(([ key, value ]) =>
                        !isNil(value) || this.evaluationErrors[`#/${this.requirement.coverageType}/details/${key}`])
                    .map(([ key, value ]) => ({
                        name: key,
                        value: typeof value === 'number'
                            ? this.localCurrencyFormatter.format(value)
                            : value || FIELD_NOT_AVAILABLE_MESSAGE,
                        evaluationError: this.evaluationErrors[`#/${this.requirement.coverageType}/details/${key}`]
                            || this.evaluationErrors[`#/${this.requirement.coverageType}/details/endorsements/${key}`]
                            || null,
                    })) || []
                : [];
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private get values(): Record<string, any> {
            const _values = Object.entries(this.requirement.details)
                .reduce<Record<string, any>>(
                    (result, [ key, value ]) => {
                        const shouldOmitByInsuranceTypeOrMultiField = typeOfInsuranceAttributes.includes(key) ||
                            Boolean(this.multiFieldCriteriaFieldMap[key]);

                        if (!shouldOmitFieldsForRendering(
                            key, this.fieldsToHide, shouldOmitByInsuranceTypeOrMultiField,
                        )) {
                            result[key] = parseFieldValue(key, value, {
                                requirement: this.requirement,
                                requirementModel: this.requirementModel,
                                evaluationResults: this.evaluationResults,
                                evaluationErrors: this.evaluationErrors,
                                multiFieldCriteria: this.multiFieldCriteria,
                                categorizedEnumLabels: this.categorizedEnumLabels,
                            });
                        }
                        return result;
                    }, {});
            return transformValuesWithIsPresent(omitBy(_values, (v, k) => this.limitsKeys.includes(k)));
        }

        private get multiFieldCriteriaFieldMap(): Record<string, unknown> {
            return this.multiFieldCriteria.reduce((acc, item) => (
                { ...acc, ...item.fields }
            ), {});
        }

        private get multiFieldCriteria(): EntityMultiFieldCriterion[] {
            return getMultiFieldCriteria(
                this.evaluationResults, this.evaluationErrors, this.allRequirements, this.categorizedEnumLabels,
            );
        }

        private get coverageStatus(): boolean {
            return this.requirement.details.coverageStatus ?? false;
        }

        private get endorsementsFieldsValues(): Record<string, any> {
            if (!this.requirement.details.hasOwnProperty('endorsements')) {
                return {};
            }

            let _endorsementsFieldsValues = {
                ...Object.entries(this.requirement.details.endorsements).reduce((acc, [ key, value ]) => {
                    const shouldOmitKey = shouldBeOmitted(key, value, this.evaluationResults) ||
                        Boolean(this.multiFieldCriteriaFieldMap[key]);

                    if (shouldOmitKey) {
                        return acc;
                    }

                    acc[key] = getEnumDefaultDisplayValue(key, value, this.categorizedEnumLabels);
                    return acc;
                }, {} as Record<string, unknown>),
                ...this.nullEndorsementsFieldsWithErrors,
            };
            _endorsementsFieldsValues = combineSpecialTprmFieldValues(_endorsementsFieldsValues);
            return omitBy(
                _endorsementsFieldsValues,
                (v, k) => this.limitsKeys.includes(k),
            );
        }

        private get nullEndorsementsFieldsWithErrors() {
            return Object.entries(this.requirement.details.endorsements || {})
                .reduce((acc, [ key, value ]) => {
                    if (
                        value === null &&
                        this.evaluationErrors[`#/${this.requirement.coverageType}/details/endorsements/${key}`] &&
                        !this.multiFieldCriteriaFieldMap[key]
                    ) {
                        acc[key] = FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                    return acc;
                }, {} as any);
        }

        private get producerDetails(): { title: string, value: string }[] {
            const producerData = getProducerInfo(this.requirement);
            const brokerData = getBrokerInfo(this.brokerInfo);
            return producerData
                .concat(brokerData)
                .filter(({ value }) => value) as { title: string, value: string }[];
        }

        private get collaterals(): TprmFieldCollateral[] {
            return this.requirement.details.collateral || [];
        }

        private get currencyLabel(): string {
            return getEnumDefaultDisplayValue(
                EnumCategories.currency,
                this.requirement.policy.currency,
                this.categorizedEnumLabels,
            ) as string;
        }

        private get localCurrencyFormatter(): Intl.NumberFormat {
            const currency = this.requirement?.policy.currency;
            return currencyFormatter(currency);
        }

        private get limitsKeys(): string[] {
            return this.limits.map<string>((limit) => limit.name);
        }

        private get singleRequirementFields() {
            return omitMultiPropFieldsFromSchema(getBasicSchemaByValue(this.values));
        }

        private get singleCoverageEndorsementsFields() {
            return omitMultiPropFieldsFromSchema(getBasicSchemaByValue(this.endorsementsFieldsValues));
        }

        private get multiPropsTprmFields() {
            return pickMultiPropFieldsFromSchema(getBasicSchemaByValue(this.values));
        }

        private get multiCoverageEndorsementsFields() {
            return pickMultiPropFieldsFromSchema(getBasicSchemaByValue(this.endorsementsFieldsValues));
        }

        private get numberOfMultiPropsTprmFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiPropsTprmFields.properties.hasOwnProperty(key)).length;
        }

        private get numberOfEndorsementsMultiRequirementFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiCoverageEndorsementsFields.properties.hasOwnProperty(key)).length;
        }

        private isLimit(key: string) {
            return key.toLowerCase().endsWith('limit') || key.toLowerCase().includes('singlelimit')
                || key === 'deductible';
        }

        private goToCriterion(riskProfileId: string): void {
            this.$emit('goToCriterion', riskProfileId, this.requirement.coverageType);
        }

        private grantExceptionToCriterion(criterionId: string): void {
            this.$emit('grantExceptionToCriterion', criterionId);
        }

        private grantEvaluateEntityException(exception: ExceptionInput[]): void {
            this.$emit('grantEvaluateEntityException', exception);
        }

        private removeExceptions(exceptionIds: string[]): void {
            this.$emit('removeExceptions', exceptionIds);
        }

        private getEvaluationResult(url: string): FieldEvaluationResultError | null {
            return getEvaluationResultsFromEvaluationErrors(url, this.evaluationErrors, this.requirement);
        }

        private get isShowingCarrierInfo(): boolean {
            return isShowingCarrierInfo(this.requirement.policy.carrier, this.getEvaluationResult);
        }
    }
</script>
