<template>
    <ScreenMessage :icon="icon" :type="type" :has-logo="hasLogo" :relative="relative">
        <template v-if="$scopedSlots.icon" #icon>
            <slot name="icon" />
        </template>
        <template #toolbar>
            <slot name="toolbar">
                <ScreenToolbar
                    :has-rp-panel="hasRpPanel"
                    :has-user-panel="hasUserPanel"
                />
            </slot>
        </template>
        <slot />
    </ScreenMessage>
</template>

<script lang="ts">
    import { Vue, Component, Prop } from '@evidentid/vue-property-decorator';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
    import { ScreenMessage } from '@evidentid/dashboard-commons/screens/ScreenMessage';
    import ScreenToolbar from '@/components/screen-toolbar/ScreenToolbar.vue';

    @Component({
        components: { ScreenToolbar, ScreenMessage },
    })
    export default class InsuranceScreenMessage extends Vue {
        @Prop({ type: String, default: null })
        private type!: string | null;

        @Prop({ default: null })
        private icon!: IconDefinition | string; // FontAwesome icon or image URL

        @Prop({ type: Boolean, default: false })
        private hasLogo!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasUserPanel!: boolean;

        @Prop({ type: Boolean, default: false })
        private hasRpPanel!: boolean;

        @Prop({ type: Boolean, default: false })
        private relative!: boolean;
    }
</script>
