<template>
    <ConfirmationModal
        additional-class-name="ResendInviteModal"
        size="optimal"
        :icon="faEnvelope"
        title="Resend Welcome Email?"
        yes="Resend"
        :yesIcon="null"
        no="Cancel"
        :noIcon="null"
        loadingLabel="Sending..."
        :progress="submitting"
        @input="onConfirm"
    >
        You are about to resend the email to <strong>{{ user.email }}</strong>.
        <div class="ResendInviteModal__note">
            <FontAwesomeIcon :icon="faClock" />
            <span>
                Last email sent <strong>{{ lastEmailSent }}</strong>
            </span>
        </div>
    </ConfirmationModal>
</template>

<style lang="scss">
    .ResendInviteModal {
        &__note {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.5rem;
            color: #788ea1;

            svg {
                margin-right: 5px;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
    import { faClock } from '@fortawesome/free-regular-svg-icons';
    import moment from 'moment';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { User } from '@evidentid/user-management-api-client/types';
    import { ConfirmationModal } from '../../../components/ConfirmationModal';
    import ConfigureUserModal from '../components/ConfigureUserModal.vue';

    @Component({
        components: {
            ConfirmationModal,
            ConfigureUserModal,
            FontAwesomeIcon,
        },
    })
    export default class ResendInviteProcedure extends Vue {
        private faClock = faClock;
        private faEnvelope = faEnvelope;

        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: Object })
        private user!: User;

        private get status(): { status: OperationStatus, error: any } {
            return (this as any).$store.state.userManagement.resendInvite[this.rpName]?.[this.user.id] || {
                status: OperationStatus.uninitialized,
                error: null,
            };
        }

        private get lastEmailSent(): string {
            return moment.utc(this.user.lastInviteAt).local().calendar();
        }

        private get submitting() {
            return this.status.status === OperationStatus.loading;
        }

        private success(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: true });
        }

        private error(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: false });
        }

        private onConfirm(confirmed: boolean): void {
            if (confirmed) {
                this.deleteUser();
            } else {
                this.$emit('abort');
            }
        }

        private async deleteUser(): Promise<void> {
            if (this.status.status === OperationStatus.loading) {
                return;
            }

            await (this as any).$store.actions.userManagement.resendInvite({
                rpName: this.rpName,
                id: this.user.id,
            });

            const { status } = this.status;
            if (status === OperationStatus.success) {
                this.success(`Welcome email was successfully resent to ${this.user.email}`);
                this.$emit('finish');
            } else {
                this.error('Oops, something went wrong. Please try again!');
            }
        }
    }
</script>
