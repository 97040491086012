<template>
    <ConfigureUserModal
        v-model="input"
        :rp-name="rpName"
        :submitting="submitting"
        open
        title="Invite new user to this account"
        submit-label="Send Invitation"
        @submit="onInviteRequest"
        @close="$emit('abort')"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { RoleName } from '@evidentid/user-management-api-client/types';
    import ConfigureUserModal from '../components/ConfigureUserModal.vue';
    import { UserInput } from '../types';

    @Component({
        components: {
            ConfigureUserModal,
        },
    })
    export default class InviteUserProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String, default: null })
        private rpDisplayName!: string | null;

        private input: UserInput = {
            name: '',
            email: '',
            role: this.defaultRole,
        };

        private get defaultRole(): RoleName {
            return (this as any).$userManagement.config.availableRoles[0];
        }

        private get status(): { status: OperationStatus, error: any } {
            return (this as any).$store.state.userManagement.inviteUser[this.rpName] || {
                status: OperationStatus.uninitialized,
                error: null,
            };
        }

        private get submitting() {
            return this.status.status === OperationStatus.loading;
        }

        private success(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: true });
        }

        private error(message: string): void {
            (this as any).$store.actions.snackbar.displaySnackbar({ message, success: false });
        }

        private async onInviteRequest(input: UserInput): Promise<void> {
            if (this.status.status === OperationStatus.loading) {
                return;
            }

            await (this as any).$store.actions.userManagement.inviteUser({
                rpName: this.rpName,
                rpDisplayName: this.rpDisplayName,
                input,
            });

            const { status, error } = this.status;
            if (status === OperationStatus.success) {
                this.success(`Invite to ${input.email} was sent`);
                this.$emit('finish');
            } else if (error?.response?.message?.includes?.('already exists')) {
                this.error('This user already exists in this account.');
                this.$emit('finish');
            } else if (error?.response?.message?.includes?.('Not permitted to assign role')) {
                this.error(`
                    This action is not allowed since the user has multiple accounts with Evident.
                    Please reach out to Evident support to invite this user.
                `);
            } else {
                this.error('Oops, something went wrong. Please try again!');
            }
        }
    }
</script>
