<template>
    <div class="DayOfWeekInput" :class="className">
        <label
            v-for="day in 7"
            :key="day"
            v-tooltip="dateLabels[day]"
            class="DayOfWeekInput__day"
            :class="{ 'DayOfWeekInput__day--selected': currentValue.includes(day) }"
        >
            <input
                type="checkbox"
                :disabled="disabled"
                :checked="true"
                :value="day"
                @input="onChange"
            >
            {{ labels[day] }}
        </label>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { DayOfWeek } from './types';

    const availableDaysOfWeek = Object.values(DayOfWeek);

    @Component({
        inheritAttrs: false,
    })
    export default class DayOfWeekInput extends Vue {
        private labels: Record<DayOfWeek, string> = {
            [DayOfWeek.sunday]: 'S',
            [DayOfWeek.monday]: 'M',
            [DayOfWeek.tuesday]: 'T',
            [DayOfWeek.wednesday]: 'W',
            [DayOfWeek.thursday]: 'T',
            [DayOfWeek.friday]: 'F',
            [DayOfWeek.saturday]: 'S',
        };

        private readonly DATE_FORMAT = 'MM/DD/yyyy';

        @Prop({ type: Array, default: () => [] })
        private value!: DayOfWeek[];

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: Boolean, default: false })
        private readonly!: boolean;

        @Prop({ type: String, default: null })
        private tooltipStartDate!: string;

        private currentValue: DayOfWeek[] = [];
        private dateLabels: string[] = [];
        private correctedStartDate: string = '';

        @Watch('value', { immediate: true })
        private updateCurrentValue() {
            this.currentValue = this.value;
        }

        @Watch('tooltipStartDate', { immediate: true })
        private updateDateLabel(tooltipStartDate?: string) {
            this.dateLabels = [];
            if (tooltipStartDate) {
                this.correctedStartDate = tooltipStartDate;
                const tooltipStartDateMoment = moment(tooltipStartDate);
                this.correctedStartDate = tooltipStartDateMoment.startOf('week').format(this.DATE_FORMAT);
                const startDayMoment = moment(this.correctedStartDate);
                for (let day = 1; day <= 7; day++) {
                    this.dateLabels[day] = (startDayMoment.format(this.DATE_FORMAT));
                    startDayMoment.add(1, 'day');
                }
            }
        }

        private get className() {
            return [
                this.currentValue ? 'DayOfWeekInput--selected' : '',
                this.disabled ? 'DayOfWeekInput--disabled' : '',
                this.readonly ? 'DayOfWeekInput--readonly' : '',
                this.$attrs.class,
            ].filter(Boolean);
        }

        private onChange(event: Event) {
            if (this.readonly) {
                return;
            }
            const checkbox = event.target as HTMLInputElement;
            const dayOfWeek = parseInt(checkbox.value, 10);
            const wasSelected = this.currentValue.includes(dayOfWeek);
            const currentValue = this.currentValue.filter((day) => availableDaysOfWeek.includes(day));

            if (wasSelected) {
                this.currentValue = currentValue.filter((day) => day !== dayOfWeek);
            } else {
                this.currentValue = currentValue.concat(dayOfWeek).sort();
            }
            this.$emit('input', this.currentValue);
        }
    }
</script>
