import { SnackbarConfiguration } from './types';
import { createStateFactory } from '@evidentid/vue-commons/store';

const createState = createStateFactory<{}>();

const { instantiateState, createMutationsFactories } = createState<SnackbarConfiguration>(() => ({
    success: false,
    permanent: false,
    message: null,
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    setSnackbarContent(configuration: SnackbarConfiguration | null) {
        Object.assign(
            this,
            configuration || { success: false, message: null, permanent: false }
        );
    },
}));

const { instantiateActions, instantiateModule, getActions } = createActionFactories(() => ({
    displaySnackbar(configuration: Partial<SnackbarConfiguration> | null) {
        this.mutations.setSnackbarContent(
            configuration
                ? { success: false, permanent: false, message: null, ...configuration }
                : null
        );
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
