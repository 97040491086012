export function getAuthErrorMessage(error: any, replacements: Record<string, string> = {}): string {
    if (replacements[error?.name]) {
        return replacements[error?.name];
    }
    switch (error?.name) {
        case 'InvalidEmailError':
        case 'InvalidCredentialsError':
            return 'Either the email or the password appears incorrect.';
        case 'UserDisabledError':
            return 'This account has been disabled.';
        case 'UserNotFoundError':
        case 'DifferentProviderError':
            return `
                We could not find that active account in our records.
                Please try different authentication method or create an account.
            `;
        case 'PasswordLeakedError':
            return `
                This login has been blocked because your password has been leaked in another website.
                We’ve sent you an email with instructions on how to unblock it.
            `;
        case 'WeakPasswordError':
            return 'Password should be at least 12 characters long.';
        case 'EmailAlreadyUsedError':
            return 'It looks like that email is already in our system.';
        case 'RateLimitError':
            return 'Too many auth requests. Please try again later.'
        default:
            return `
                Oops, something wrong happened. Please try again.
                If you continue experiencing problems, please reach out to Evident support.
            `;
    }
}
