<template>
    <a
        href="javascript:void(0)"
        class="OauthButton"
        :class="{
            [`OauthButton--${type}`]: type,
            'OauthButton--disabled': disabled,
        }"
        @click="onClick"
    >
        <span class="OauthButton__icon">
            <img v-if="typeof icon === 'string'" :src="icon" :alt="alt">
            <FontAwesomeIcon v-else :icon="icon" />
        </span>
        <span class="OauthButton__text">
            <slot />
        </span>
    </a>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class OauthButton extends Vue {
        @Prop({ type: String, default: null })
        private type!: string | null;

        @Prop({ type: String, default: null })
        private alt!: string | null;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop()
        private icon!: IconDefinition | string;

        private onClick(event: Event): void {
            event.preventDefault();
            if (!this.disabled) {
                this.$emit('click');
            }
        }
    }
</script>
