<template>
    <div class="RiskProfileEmptyState">
        <div class="RiskProfileEmptyState__container">
            <span class="RiskProfileEmptyState__title"> Configure your first Risk Profile</span>
            <div class="RiskProfileEmptyState__body">
                <p>
                    <span>A </span>
                    <span class="RiskProfileEmptyState__bodyHighlight">Risk Profile</span>
                    <span> is a set of requirements used to determine the overall risk of your entities.</span>
                </p>
                <p>You can create more than one risk profile and assign entities to different profiles for specific requirements.</p>
            </div>
            <Button class="RiskProfileEmptyState__createButton" type="primary" @click="createRiskProfile">
                Create a Risk Profile
            </Button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';

    @Component({
        components: { Button },
    })
    export default class RiskProfileEmptyState extends Vue {
        private createRiskProfile() {
            this.$emit('create');
        }
    }
</script>
