import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import { SendDocumentRequestResponse, SendDocumentRequestSuccess } from '@evidentid/rpweb-api-client/models';

function mergeBatchResults(result: SendDocumentRequestResponse[]): SendDocumentRequestResponse {
    return result.reduce((result, partialResult) => ({
        totalCount: result.totalCount + partialResult.totalCount,
        successCount: result.successCount + partialResult.successCount,
        failureCount: result.failureCount + partialResult.failureCount,
        successes: [ ...result.successes, ...partialResult.successes ],
        failures: [ ...result.failures, ...partialResult.failures ],
    }));
}

export interface EntityRequestRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<EntityRequestRequirement>();

export interface SendRequestStatus {
    status: OperationStatus;
    progress: number;
    entityIds: string[];
    totalCount: number;
    successCount: number;
    failureCount: number;
    successOnRequestCount: number;
    failedOnRequestCount: number;
    successes: SendDocumentRequestSuccess[];
    failures: string[];
}

interface SendRequestState {
    sendDocumentRequestStatus: Record<string, SendRequestStatus>;
}

const { instantiateState, createMutationsFactories } = createState<SendRequestState>(() => ({
    sendDocumentRequestStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startSendingDocumentRequests(payload: { rpName: string, entityIds: string[] }) {
        this.sendDocumentRequestStatus = {
            ...this.sendDocumentRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                entityIds: payload.entityIds,
                totalCount: payload.entityIds.length,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    failSendingDocumentRequests(payload: { rpName: string }) {
        this.sendDocumentRequestStatus = {
            ...this.sendDocumentRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                entityIds: this.sendDocumentRequestStatus[payload.rpName]?.entityIds || [],
                totalCount: this.sendDocumentRequestStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: this.sendDocumentRequestStatus[payload.rpName]?.totalCount || 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    progressSendingDocumentRequests(payload: {
        rpName: string;
        progress: number;
    }) {
        this.sendDocumentRequestStatus = {
            ...this.sendDocumentRequestStatus,
            [payload.rpName]: {
                ...this.sendDocumentRequestStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishSendingDocumentRequests(payload: {
        rpName: string;
        entityIds: string[];
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: any[];
        failures: any[];
    }) {
        this.sendDocumentRequestStatus = {
            ...this.sendDocumentRequestStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                entityIds: this.sendDocumentRequestStatus[payload.rpName]?.entityIds || [],
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                successOnRequestCount: payload.successOnRequestCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                failures: payload.failures,
                successes: payload.successes,
            },
        };
    },
    clearSendingDocumentRequests(rpName: string) {
        this.sendDocumentRequestStatus = omit(this.sendDocumentRequestStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: EntityRequestRequirement) => ({

    async sendDocumentRequest(payload: {
        rpName: string; entityIds: string[];
    }) {
        const { rpName, entityIds } = payload;
        try {
            this.mutations.startSendingDocumentRequests({ rpName, entityIds });
            const updateProgress = (progress: number) => {
                this.mutations.progressSendingDocumentRequests({ rpName, progress });
            };
            const batchResults = await rpweb.sendEntityDocumentRequest(rpName, entityIds, 25, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = (entityIds?.length - results.totalCount) || 0;
            if (entityIds.length > 0 && failedOnRequestCount === entityIds.length) {
                this.mutations.failSendingDocumentRequests({ rpName });
            } else {
                this.mutations.finishSendingDocumentRequests({
                    rpName,
                    ...results,
                    entityIds,
                    totalCount: entityIds?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
            }
        } catch (error) {
            console.warn('Send request error', error);
            this.mutations.failSendingDocumentRequests({ rpName });
        }
    },
    clearSendDocumentRequestStatus(payload: { rpName: string }) {
        this.mutations.clearSendingDocumentRequests(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
