<template>
    <SettingsPage
        ref="SettingsPage"
        title="Email Configuration"
        class="NotificationsEmailConfigurationPage"
        :schema="schema"
        :initial-value="currentSettings"
        :loading="loading"
        :updating="updating"
        @reset="reset"
        @save="save"
    >
        <template #preview="{ value }">
            <h3>Email Preview</h3>

            <ActionEmailPreview :value="value" :defaults="currentSettings" />

            <div class="SettingsPage__note">
                Note: The text displayed in the email above is for preview purposes only.
                The actual text used in your email is controlled on a per-template basis.
            </div>
        </template>

        <template #default="{ fields }">
            <h3>Sender Details</h3>
            <h5 class="mt-0">
                Enter your send details below to customize notifications and the Evident Network where your entities complete their requirements.
            </h5>
            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.fromName" />
                <JsonSchemaCustomFormElement :field="fields.replyToName" />
            </div>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.replyToEmail" />
            </div>

            <hr>

            <h3>Requirement Link</h3>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.requirementsLink" />
            </div>

            <hr>

            <h3>Fulfillment</h3>
            <div>
                <div class="SettingsPage__formRow">
                    <JsonSchemaCustomFormElement
                        :field="fields.fulfillmentConfiguration"
                        :custom-component-input="fulfillmentCustomComponents"
                        @input="onFulfillmentInput"
                    />
                </div>
            </div>
            <hr>

            <h3>Certificate Holder Address</h3>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.certificateHolderAddress" />
            </div>
        </template>
    </SettingsPage>
</template>

<style lang="scss">
    .NotificationsEmailConfigurationPage {
        &__checkbox {
            display: flex;
            align-items: center;

            .SwitchToggle {
                padding: 0;
                zoom: 0.9;
                margin-right: 15px;

                &--off .SwitchToggle__slider {
                    background-color: #778ea1;
                }

                &--on .SwitchToggle__slider {
                    background-color: #29b496;
                }
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { JsonSchemaCustomFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';
    import { buildEmailConfigurationSchema } from '../schemas/buildEmailConfigurationSchema';
    import ActionEmailPreview from '../components/ActionEmailPreview.vue';
    import SettingsPage from '../components/SettingsPage.vue';
    import InputZoneSelector from '../components/InputZoneSelector.vue';
    import {
        EmailConfiguration,
        NotificationsConfiguration,
        NotificationsConfigurationState,
    } from '../types';
    import EnableFulfillmentToggle from '@/modules/notifications-configuration/components/EnableFulfillmentToggle.vue';
    import { RpFulfillmentConfig } from '@evidentid/config-api-client/types';
    import {
        RelyingPartySignature,
    } from '@evidentid/rpweb-api-client/models';

    @Component({
        components: {
            InputZoneSelector,
            SettingsPage,
            ActionEmailPreview,
            JsonSchemaCustomFormElement,
            SwitchToggle,
        },
    })
    export default class NotificationsEmailConfiguration extends Vue {
        private schema = buildEmailConfigurationSchema();
        private fulfillmentCustomComponents = { boolean: EnableFulfillmentToggle };
        private localSettings = this.getInitialLocalSettings();

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get currentRp(): RelyingPartySignature | null {
            return this.$store.state.user.relyingParties?.find(({ name }) => (name === this.rpName)) || null;
        }

        private get externalConfigurationEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get state(): NotificationsConfigurationState {
            return this.$store.state.notificationsConfiguration;
        }

        private get status(): OperationStatus {
            return this.state.loadSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get loading(): boolean {
            return ![ OperationStatus.success, OperationStatus.error ].includes(this.status);
        }

        private get updateStatus(): OperationStatus {
            return this.state.updateSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get updating(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private get currentSettings(): NotificationsConfiguration | null {
            return this.state.settings[this.rpName] || null;
        }

        private getInitialLocalSettings(): Partial<NotificationsConfiguration> {
            return {
                fulfillmentConfiguration: { ...this.currentSettings?.fulfillmentConfiguration } as RpFulfillmentConfig,
            };
        }

        /**
         * If fulfillment disabled and then reEnabled, preserving the config since JsonSchemaForm will erase them.
         */
        private onFulfillmentInput(changedConfig: RpFulfillmentConfig): void {
            const { fulfillmentConfiguration } = this.localSettings;
            if (fulfillmentConfiguration && Object.keys(fulfillmentConfiguration).length > 0) {
                if (!fulfillmentConfiguration.enabled && changedConfig.enabled ||
                    fulfillmentConfiguration.enabled && !changedConfig.enabled) {
                    this.localSettings = {
                        ...this.localSettings,
                        fulfillmentConfiguration: {
                            ...fulfillmentConfiguration,
                            enabled: changedConfig.enabled,
                        },
                    };
                    (this.$refs.SettingsPage as SettingsPage).updateFormControllerValue(this.localSettings);
                    return;
                }
            }
            this.localSettings = {
                ...this.localSettings,
                fulfillmentConfiguration: { ...changedConfig },
            };
        }

        private reset(): void {
            this.localSettings = this.getInitialLocalSettings();
        }

        private async save(settings: EmailConfiguration): Promise<void> {
            const toSave =
                !settings.fulfillmentConfiguration.enabled
                && Object.keys(settings.fulfillmentConfiguration).length === 1
                    ? {
                        ...settings,
                        fulfillmentConfiguration: { ...this.localSettings.fulfillmentConfiguration, enabled: false },
                    }
                    : settings;
            await this.$store.actions.notificationsConfiguration.updateSettings({
                rpName: this.rpName,
                settings: toSave as EmailConfiguration,
            });

            if (this.updateStatus === OperationStatus.success) {
                this.$store.actions.snackbar.displaySnackbar({
                    message: 'Email configuration updated successfully.',
                    success: true,
                });
            }
        }

        @Watch('status', { immediate: true })
        private loadSettings(): void {
            if (this.externalConfigurationEnabled && this.status === OperationStatus.uninitialized) {
                this.$store.actions.notificationsConfiguration.loadSettings({ rpName: this.rpName });
            }
        }

        @Watch('externalConfigurationEnabled', { immediate: true })
        private onExternalConfigurationStatus(): void {
            if (!this.externalConfigurationEnabled && this.$store.state.user?.data) {
                this.$router.replace({ name: 'error', params: { reason: 'page-not-found' } });
            }
        }
    }
</script>
