<template>
    <div class="Navigation">
        <div v-if="title" class="Navigation__title">
            {{ title }}
        </div>
        <div class="Navigation__index">
            <span>{{ currentPosition || 0 }}</span>
            <span>of</span>
            <span>{{ maxPosition || 0 }}</span>
        </div>
        <div class="Navigation__prev"
            :class="{'Navigation__prev--disabled': currentPosition <= 1}"
            @click="navigatePrevious"
        >
            <FontAwesomeIcon :icon="faChevronLeft" />
        </div>
        <div class="Navigation__next"
            :class="{'Navigation__next--disabled': currentPosition === maxPosition}"
            @click="navigateNext"
        >
            <FontAwesomeIcon :icon="faChevronRight" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Navigation extends Vue {
        @Prop({ type: String, default: '' })
        private title!: string;

        @Prop({ type: Number, default: 0 })
        private currentPosition!: number;

        @Prop({ type: Number, default: 0 })
        private maxPosition!: number;

        private faChevronLeft = faChevronLeft;
        private faChevronRight = faChevronRight;

        private navigatePrevious(): void {
            if (this.currentPosition > 1) {
                this.$emit('previous');
            }
        }

        private navigateNext(): void {
            if (this.currentPosition < this.maxPosition) {
                this.$emit('next');
            }
        }
    }
</script>
