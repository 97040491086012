<template>
    <Modal
        v-if="corrections.length === 0 || files.length === 0 || alertConfig"
        additional-class-name="BulkImportEntitiesModal"
        form
        open
        :allow-close="!saving"
        @submit.prevent="submit"
        @close="close"
    >
        <template #header>
            Bulk Import Entities
        </template>

        <BulkImportForm
            :disabled="saving || processing"
            :value="files"
            :custom-properties="sortedCustomProperties"
            :requirement-types="requirementTypes"
            :alert-config="alertConfig"
            :build-description="buildFileDescription"
            @input="processFile"
        />

        <div class="BulkImportEntitiesModal__actionSection">
            <Button
                class="BulkImportEntitiesModal__submitButton"
                :disabled="processing || files.length === 0 || isInvalidFile"
                :loading="saving"
                :progress="savingPercentage"
                @click="submit"
            >
                Import
            </Button>
        </div>
    </Modal>
    <Modal
        v-else
        form
        open
        additional-class-name="BulkImportEntitiesCorrectionModal"
        :allow-close="!saving"
        @submit.prevent="submit"
        @close="close"
    >
        <template #header>
            <span> Import results </span>
            <div class="BulkImportEntitiesCorrectionModal__buttonsGroup">
                <Button
                    class="BulkImportEntitiesCorrectionModal__csvButton"
                    :disabled="!valid || saving"
                    @click="exportCsv"
                >
                    <FontAwesomeIcon :icon="faFileExcel" />
                    Download updated CSV worksheet
                </Button>
                <Button
                    class="BulkImportEntitiesCorrectionModal__importButton"
                    :loading="saving"
                    :progress="savingPercentage"
                    :disabled="!valid"
                    type="primary"
                    submit
                >
                    Save and Import
                </Button>
            </div>
        </template>

        <div>
            <BulkImportCorrectionTable
                v-model="corrections"
                :form="form"
                :additional-alert-config="correctionTableSuccessAlertConfig"
                :csv-column-keys="csvColumnKeys"
            />
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import moment from 'moment';
    import trim from 'lodash/trim';
    import uniq from 'lodash/uniq';
    import partition from 'lodash/partition';
    import fromPairs from 'lodash/fromPairs';
    import { zipObject } from 'lodash';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { buildCsv, readCsv } from '@evidentid/universal-framework/csv';
    import createForm from '@evidentid/json-schema/createForm';
    import JsonForm, {
        JsonFormArray,
        JsonFormObject,
        JsonFormProperty,
        JsonFormType,
    } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaObject } from '@evidentid/json-schema/interfaces/JsonSchema';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig, AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import {
        buildBulkEntityImportJsonSchema,
    } from '@/modules/entity-management/utils/buildEntityJsonSchema';
    import { BaseEntityInput } from '@/modules/entity-management/types';
    import BulkImportForm from '../BulkImportForm/BulkImportForm.vue';
    import BulkImportCorrectionTable from '../BulkImportCorrectionTable/BulkImportCorrectionTable.vue';
    import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
    import { downloadFileUsingBinString } from '@evidentid/file-utils/blobs';
    import { buildEntitiesCsv } from '@/modules/entity-management/utils/buildEntityCsv';
    import { isSampleInsured } from '@/utils/csv-sample/sample-values';
    import {
        filterEntityEmptyItems,
        hasPastExpirationDate,
        trimEntity,
    } from '@/modules/entity-management/utils/entityProcessing';
    import {
        getFlattenedCustomPropertyNames,
    } from '@/modules/entity-management/utils/get-flattened-custom-property-names/getFlattenedCustomPropertyNames';
    import {
        getFlattenedCustomPropertyKeys,
    } from '@/modules/entity-management/utils/get-flattened-custom-property-keys/getFlattenedCustomPropertyKeys';
    import {
        getCsvValuesWithCombinedObjects,
    } from '@/modules/entity-management/utils/get-csv-values-with-combined-objects/getCsvValuesWithCombinedObjects';
    import {
        getCsvColumnKeysWithCombinedObjects,
    } from '@/modules/entity-management/utils/get-csv-column-keys-with-combined-objects/getCsvColumnKeysWithCombinedObjects';
    import { isObject } from '@evidentid/json-schema/schemaChecks';
    import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
    import orderBy from 'lodash/orderBy';
    import { CustomProperty, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';

    const booleanValues: Record<string, boolean> = { yes: true, no: false };

    function buildValue(
        _value: string | undefined, form: JsonForm, isCustomPropertyProp?: boolean,
    ): string | object | boolean | string[] | object[] {
        const value = trim(_value || '');
        const type = form.type;
        if (type === JsonFormType.boolean && value.toLowerCase() in booleanValues) {
            return booleanValues[value.toLowerCase()];
        } else if (isCustomPropertyProp && type === JsonFormType.object && _value) {
            return _value;
        } else if (type === JsonFormType.array) {
            if (!value.length || value.length === 0) {
                return [];
            }
            return (form as JsonFormArray).item.type === JsonFormType.object
                ? value.split('|').map((x) => JSON.parse(x))
                : value.split('|').map((x) => trim(x));
        }
        return value;
    }

    function parseDateValue(_value: string | undefined): string | null {
        const momentDate = moment(trim(_value || ''));
        return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
    }

    function isCustomPropertyEmpty(customPropertyValue: any) {
        return typeof customPropertyValue === 'object' && Object.values(customPropertyValue).every((value) => value === '');
    }

    function nullifyEmptyObjectFields(
        customProperties: Record<string, string | boolean | object | object[] | string[]>,
    ) {
        return Object.entries(customProperties)
            .reduce((acc, [ key, customPropertyValue ]) => {
                acc[key] = isCustomPropertyEmpty(customPropertyValue) ? null : customPropertyValue;
                return acc;
            }, {} as Record<string, any>);
    }

    function buildInsuredInput(input: any, form: JsonFormObject): BaseEntityInput {
        const base = form.getProperties();
        const customPropertiesFormProp = base.find((x) => x.name === 'insuredFields');
        const customPropertiesFormPropList = customPropertiesFormProp?.form?.type === JsonFormType.object
            ? customPropertiesFormProp.form.getProperties()
            : [];
        const exceptionsProperty = base.find((x) => x.name === 'exceptions');
        const exceptionProperties =
            exceptionsProperty?.form?.type === JsonFormType.object ? exceptionsProperty.form.getProperties() : [];
        const baseValues = fromPairs(base.map((x) => [ x.name, buildValue(input[x.name], x.form) ]));
        const customProperties =
            fromPairs(customPropertiesFormPropList.map((x) => [ x.name, buildValue(input[x.name], x.form, true) ]));
        const exceptions =
            fromPairs(exceptionProperties.map((x) => [ x.name, parseDateValue(input[x.name]) || input[x.name] ]));
        const transformedCustomProperties = nullifyEmptyObjectFields(customProperties);
        return form.getValue({ ...baseValues, insuredFields: transformedCustomProperties, exceptions }, true);
    }

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FontAwesomeIcon,
            BulkImportForm,
            BulkImportCorrectionTable,
            LoadingModal,
        },
    })
    export default class BulkImportEntitiesModal extends Vue {
        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private requirementTypes!: string[];

        @Prop({ type: Object, default: null })
        private externalAlertConfig!: { type: AlertType, title: string } | null;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Prop({ type: Number, default: null })
        private savingPercentage!: number | null;

        private files: File[] = [];
        private jsonSchema: JsonSchemaObject = null as any;
        private form: JsonFormObject = null as any;
        private fileDescription: { description?: string, error?: boolean } = {};
        private localAlertConfig: AlertConfig | null = null;
        private correctionTableSuccessAlertConfig: { type: AlertType, title: string } | null = null;
        private processing: boolean = false;
        private entities: EntityInput[] = [];
        private corrections: BaseEntityInput[] = [];
        private sampleEntities: EntityInput[] = [];
        private faFileExcel = faFileExcel;
        private csvHeaders: string[] = [];
        private csvColumnKeys: string[] = [];

        @Watch('value')
        private onInternalValueChange(): void {
            this.$emit('input', this.value);
        }

        private get value(): EntityInput[] {
            return this.getValue(true);
        }

        private get isInvalidFile(): boolean {
            return Boolean(this.fileDescription && this.fileDescription.error);
        }

        private isValid(value: BaseEntityInput): boolean {
            return this.form.isValid(filterEntityEmptyItems(value, this.sortedCustomProperties), true);
        }

        private get standardProperties(): JsonFormProperty[] {
            return this.form.getProperties().filter((x) => x.name !== 'insuredFields' && x.name !== 'exceptions');
        }

        private get entityColumnsKeys(): string[] {
            return [
                ...this.standardProperties.map((field) => field.name),
                ...getFlattenedCustomPropertyKeys(this.sortedCustomProperties),
                ...this.requirementTypes,
            ];
        }

        private get entityColumnsLabel(): string[] {
            return [
                ...this.standardProperties.map((field) => field.form.schema.title || ''),
                ...getFlattenedCustomPropertyNames(this.sortedCustomProperties),
                ...this.requirementColumnLabels,
            ];
        }

        private get entityColumnsLabelKeyMap(): Record<string, string> {
            return this.entityColumnsLabel.reduce((acc, label, index) => {
                acc[label] = this.entityColumnsKeys[index];
                return acc;
            }, {} as Record<string, string>);
        }

        private get requirementColumnLabels(): string[] {
            return this.requirementTypes.map((requirement) => `Existing ${requirement} Policy Expiration Date`);
        }

        private get valid(): boolean {
            return this.corrections.every((value) => this.isValid(value));
        }

        private get alertConfig(): AlertConfig | null {
            return this.files.length === 0 ? null : this.localAlertConfig || this.externalAlertConfig;
        }

        private get sortedCustomProperties(): CustomProperty[] {
            return orderBy(
                this.customProperties,
                [ 'required', (entity) => entity.name.toLowerCase() ],
                [ 'desc', 'asc' ],
            );
        }

        private getValue(ignoreEmptyOptional: boolean): EntityInput[] {
            return [ ...this.entities, ...this.corrections as EntityInput[] ]
                .map((entity) => this.form.getValue(entity, ignoreEmptyOptional));
        }

        @Watch('customProperties', { immediate: true })
        @Watch('requirementTypes')
        private onCustomPropertiesUpdate(): void {
            this.jsonSchema = buildBulkEntityImportJsonSchema(
                this.sortedCustomProperties,
                this.requirementTypes,
            );
            this.form = createForm(removeNonStandardSchemaFormat(this.jsonSchema) as JsonSchemaObject);
        }

        private close(): void {
            this.$emit('close');
        }

        private async processFile([ file ]: File[]): Promise<void> {
            this.files = file ? [ file ] : [];
            this.processing = true;
            this.fileDescription = {};
            this.localAlertConfig = null;
            if (file) {
                try {
                    const { rows, csvHeaders, csvColumnKeys } = await this.readInsuredInput(file);
                    this.csvHeaders = csvHeaders;
                    this.csvColumnKeys = csvColumnKeys;

                    const [ sampleEntities, entities ] = partition(
                        rows,
                        (x) => isSampleInsured(x),
                    ) as ([ EntityInput[], BaseEntityInput[] ]);
                    this.sampleEntities = sampleEntities;
                    [ this.entities, this.corrections ] =
                        partition(entities, (x) => this.isValid(x)) as [ EntityInput[], BaseEntityInput[] ];
                    this.fileDescription = {
                        description: `${rows.length} entities found`,
                        error: false,
                    };
                    const entityText =
                        this.entities.length > 1 ? 'Entities successfully validated' : 'Entity successfully validated';
                    this.correctionTableSuccessAlertConfig = this.entities.length > 0
                        ? { type: 'success', title: `${this.entities.length} ${entityText}` }
                        : null;
                } catch (error) {
                    this.localAlertConfig = {
                        title: 'Unable to import! Please fix the errors and upload the CSV file',
                        type: 'danger',
                    };
                    this.fileDescription = { description: (error as Error).message, error: true };
                }
            }
            this.processing = false;
        }

        private submit(): void {
            if (this.valid) {
                const trimAndFilter = (entity: EntityInput) => this.form.getValue(
                    filterEntityEmptyItems(trimEntity(entity), this.sortedCustomProperties),
                    true);
                const hasPastDate = this.value.some(hasPastExpirationDate);
                this.$emit('submit', this.value.map(trimAndFilter), this.sampleEntities, hasPastDate);
            }
        }

        private exportCsv(): void {
            if (this.valid) {
                const csv: string[][] = buildEntitiesCsv(
                    this.csvHeaders,
                    this.csvColumnKeys,
                    this.getValue(false),
                    this.sortedCustomProperties,
                );
                downloadFileUsingBinString(buildCsv(csv), this.files[0].name.replace(/\.csv$/, '_updated.csv'));
            }
        }

        private buildFileDescription(): { description?: string, error?: boolean } {
            return this.fileDescription;
        }

        private async readInsuredInput(csv: File): Promise<{
            csvHeaders: string[];
            rows: BaseEntityInput[];
            csvColumnKeys: string[];
        }> {
            // Get raw parsed CSV
            const rows = await readCsv(csv);
            // Extract header from the results list
            const csvHeaders = (rows.shift() || []).slice(0, this.entityColumnsLabel.length).map(trim);
            const csvColumnKeys = csvHeaders.map((label) => this.entityColumnsLabelKeyMap[label]);
            // Check if it's empty
            if (rows.length === 0) {
                throw new Error('Provided CSV file is empty');
            }
            if (rows.some((row) => (row.length !== csvHeaders.length))) {
                throw new Error('The file is not a valid CSV and could not be parsed.');
            }
            // Validate header
            if (csvHeaders.some((label) => !this.entityColumnsLabel.includes(label))) {
                throw new Error('The file has an extra column that is not currently an Insured field.');
            }
            if (
                uniq(csvHeaders).length !== csvHeaders.length ||
                csvHeaders.length !== this.entityColumnsLabel.length
            ) {
                throw new Error('The file is missing a standard field or Insured field.');
            }
            const objectTypeFields = this.sortedCustomProperties.filter((field) => isObject(field.schema));
            const objectTypeKeys = objectTypeFields.map((field) => field.key);
            const combinedColumnKeys = getCsvColumnKeysWithCombinedObjects(csvColumnKeys, objectTypeKeys);
            const getCombinedRows =
                (row: string[]) => getCsvValuesWithCombinedObjects(csvColumnKeys, row, objectTypeKeys);
            const formattedRows = rows
                .map((row) => row.map(trim))
                .filter((row) => row.some(Boolean))
                .map((row) => zipObject(combinedColumnKeys, getCombinedRows(row)))
                .map((row) => buildInsuredInput(row, this.form));
            return {
                csvHeaders,
                csvColumnKeys,
                rows: formattedRows,
            };
        }
    }
</script>
