<template>
    <Modal additional-class-name="EntitiesDocumentsModal EntitiesDocumentsModal--empty" open @close="close">
        <template #header>
            Documents
        </template>
        <SubtleAlert type="warning">
            {{ message }}
        </SubtleAlert>
    </Modal>
</template>

<style lang="scss">
    .EntitiesDocumentsModal--empty {
        .Modal__body {
            width: 750px;
        }

        .SubtleAlert {
            margin: 0;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { SubtleAlert } from '@evidentid/dashboard-commons/components/SubtleAlert';

    @Component({
        components: {
            Modal,
            SubtleAlert,
        },
    })
    export default class EntitiesDocumentsEmptyModal extends Vue {
        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String, default: null })
        private customMessage!: string | null;

        private close(): void {
            this.$emit('close');
        }

        private get message(): string {
            return this.customMessage || `We don't have any documents available for ${this.email}.`;
        }
    }
</script>
