import { callableOnce } from './callableOnce';
import {
    ExecutionsListener,
    ExecutionsUnsubscribe,
    ExtractProcedureExecutionFromComponentsMap,
    ProcedureComponentMap,
    ProcedureResult,
    ProcedureDone,
} from './types';
import createResolvablePromise from '@evidentid/universal-framework/createResolvablePromise';
import { Subscriber } from '@evidentid/subscriber';

class ProcedureManager<
    T extends Record<string, any>,
    X extends ExtractProcedureExecutionFromComponentsMap<T> = ExtractProcedureExecutionFromComponentsMap<T>,
> {
    private views: ProcedureComponentMap<T>;
    private executions: X[] = [];
    private subscriber: Subscriber<X[]> = new Subscriber();

    public constructor(views: ProcedureComponentMap<T>) {
        this.views = views;
    }

    private buildUuid(): string {
        return `${Math.random()}.${Math.random()}`;
    }

    private emit(): void {
        this.subscriber.emit(this.executions);
    }

    private remove(execution: X): void {
        this.executions = this.executions.filter((x) => x !== execution);
        this.emit();
    }

    public execute<K extends keyof T>(type: K, options: T[K]): Promise<ProcedureResult>;
    public execute<K extends keyof T>(type: K, options: T[K], done: ProcedureDone): void;
    public execute<K extends keyof T>(type: K, options: T[K], done?: ProcedureDone): Promise<ProcedureResult> | void {
        const { resolve, promise } = createResolvablePromise<ProcedureResult>();
        const finalDone = done || resolve;
        const uuid = this.buildUuid();
        // @ts-ignore: not easy to narrow signature for that
        const execution: X = { uuid, type, options, done: callableOnce(finalDone) };
        this.executions = [ ...this.executions, execution ];
        this.emit();
        if (!done) {
            return promise;
        }
    }

    protected subscribe(listener: ExecutionsListener<X>): ExecutionsUnsubscribe {
        return this.subscriber.listen(listener);
    }

    protected finish(execution: X, result?: any): void {
        this.remove(execution);
        execution.done(true, result);
    }

    protected abort(execution: X, result?: any): void {
        this.remove(execution);
        execution.done(false, result);
    }
}

export default ProcedureManager;
