<template>
    <div class="ActionReviewDetails">
        <NavigationalHeader
            :title="value"
            :current-position="currentPosition"
            :max-position="maxPosition"
            :title-prefix="actionTypeTitlePrefix"
            v-on="$listeners"
        />
        <component
            :is="reviewComp"
            v-if="reviewComp"
            v-bind="actionDetailsComponentSpecificProps"
            :action-review="actionReview"
            @resolve="resolve"
            @cancel="$emit('back')"
        />
    </div>
</template>
<style lang="scss">
.ActionReviewDetails {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        ActionResolveInput, ActionReview,
        ActionType,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review';
    import ActionableAlert from '@/modules/entity-details/components/ActionableAlert/ActionableAlert.vue';
    import { getActionValue } from '@/modules/entity-actions-review/utils/getActionValue';
    import EntityNameMatching
        from '@/modules/entity-actions-review/components/EntityNameMatching/EntityNameMatching.vue';
    import NavigationalHeader
        from '@/modules/entity-actions-review/components/NavigationalHeader/NavigationalHeader.vue';
    import EndorsementReview from '@/modules/entity-actions-review/components/EndorsementReview/EndorsementReview.vue';
    import EvaluatedEntityMappingReview
        from '@/modules/entity-actions-review/components/EvaluatedEntityMappingReview/EvaluatedEntityMappingReview.vue';
    import NonExtractionReview
        from '@/modules/entity-actions-review/components/NonExtractionReview/NonExtractionReview.vue';
    import ComplianceWithCategoryResolutionReview
        from '@/modules/entity-actions-review/components/ComplianceWithCategoryResolutionReview/ComplianceWithCategoryResolutionReview.vue';

    const COMPONENT_MAP = {
        [ActionType.complianceWithCategoryResolution]: ComplianceWithCategoryResolutionReview,
        [ActionType.endorsementFormNumberV1]: EndorsementReview,
        [ActionType.entityNameMatchingV1]: EntityNameMatching,
        [ActionType.evaluatedEntityMappingV1]: EvaluatedEntityMappingReview,
        [ActionType.nonExtractionCriterionResolutionV1]: NonExtractionReview,
    };

    @Component({
        components: {
            ActionableAlert,
            ComplianceWithCategoryResolutionReview,
            EndorsementReview,
            EntityNameMatching,
            EvaluatedEntityMappingReview,
            NavigationalHeader,
            NonExtractionReview,
        },
    })
    export default class ActionReviewDetails extends Vue {
        @Prop({ type: Object })
        private actionReview!: ActionReview;

        @Prop({ type: Number, default: 0 })
        private currentPosition!: number;

        @Prop({ type: Number, default: 0 })
        private maxPosition!: number;

        @Prop({ type: Array, default: () => [] })
        private endorsementCategories!: string[];

        private actionTypeSpecificProps: Partial<Record<ActionType, any>> = {
            [ActionType.endorsementFormNumberV1]: {
                'endorsement-categories': this.endorsementCategories,
            },
        };

        private get actionType(): ActionType {
            return this.actionReview.action.$action;
        }

        private get actionTypeTitlePrefix(): string {
            // [entityMappingExpansion]: only collateral entity atm, get different text per type when expanded
            return this.actionReview.action.$action === ActionType.evaluatedEntityMappingV1
                ? 'Collateral'
                : '';
        }

        private get value(): any {
            return getActionValue(this.actionReview.action);
        }

        private get reviewComp(): Vue.Component {
            return COMPONENT_MAP[this.actionType];
        }

        private get actionDetailsComponentSpecificProps() {
            return this.actionTypeSpecificProps[this.actionType] || {};
        }

        public resolve(data: ActionResolveInput): void {
            this.$emit('resolve', data);
        }
    }
</script>
