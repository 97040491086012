<template>
    <div class="JsonSchemaAddressForm">
        <label v-if="form.schema.title" class="JsonSchemaAddressForm__title">
            {{ form.schema.title }}
        </label>
        <div v-if="required || valueInitialized" class="JsonSchemaAddressForm__content">
            <!-- TODO may need to break down to fixed properties when adding auto fill feature -->
            <component
                :is="FormElementComponent"
                v-for="property in properties"
                :id="`${id}_${property.name}`"
                :key="property.form.key"
                :depth="depth + 1"
                :form="property.form"
                :required="property.required"
                :disabled="disabled || property.form.schema.readOnly"
                :form-element-component="FormElementComponent"
                :custom-component-input="customComponentInput"
                :value="getPropValue(property)"
                :touched="childTouchState[property.name] || touched"
                :strings="strings"
                v-bind="$attrs"
                @input="onInput(property, $event)"
                @change="onChange(property, $event)"
                @touch="updateTouchState(property.name)"
            >
                <template #icon>
                    <slot name="icon" />
                </template>
            </component>
            <div v-if="deletable" class="JsonSchemaAddressForm__deleteIconContainer">
                <div
                    class="JsonSchemaAddressForm__deleteIcon JsonSchemaForm__deleteIcon"
                    @click="onDelete"
                >
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </div>
        </div>
        <FormInput force-action-slot>
            <Button
                v-if="!required && !valueInitialized"
                class="JsonSchemaAddressForm__addButton"
                type="primary"
                :disabled="valueInitialized"
                :icon="faPlus"
                @click="onAdd"
            >
                <strong>{{ form.schema.addItemTitle || 'ADD' }}</strong>
            </Button>
        </FormInput>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm from '../elements/AbstractJsonSchemaForm';
    import { FormInput } from '../../Form';
    import { Button } from '../../Button';
    import { isNil } from 'lodash';

    @Component({
        inheritAttrs: false,
        components: {
            Button,
            FontAwesomeIcon,
            FormInput,
        },
    })
    export default class JsonSchemaAddressForm extends AbstractJsonSchemaForm<JsonFormObject, object> {
        private faTimes = faTimes;
        private faPlus = faPlus;
        private childTouchState: Record<string, boolean> = {};
        private valueInitialized: boolean = !this.isEmptyAddress();

        private get properties() {
            return this.form.getProperties(this.value);
        }

        private updateTouchState(propName: string) {
            this.childTouchState[propName] = true;
        }

        private getPropValue(property: JsonFormProperty): unknown {
            return property.form.getValue((this.value as any)?.[property.name] ?? undefined);
        }

        private onInput(property: JsonFormProperty, value: any) {
            this.$emit('input', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onChange(property: JsonFormProperty, value: any) {
            this.$emit('change', this.form.getValue({
                ...this.value,
                [property.name]: value,
            }, true));
        }

        private onAdd() {
            const newVal = this.form.getValue(undefined, true);
            if ('country' in newVal && !newVal.country) {
                newVal.country = 'US';
            }
            this.$emit('input', this.form.getValue(newVal, true));
            this.valueInitialized = true;
        }

        private onDelete() {
            this.$emit('input', null);
            this.$emit('delete');
            this.resetFormStates();
        }

        private resetFormStates(): void {
            this.childTouchState = {};
            this.valueInitialized = false;
        }

        private isEmptyAddress(): boolean {
            return typeof this.value !== 'object' || Object.values(this.value).every((val) => val == null || val === '');
        }
    }
</script>
