export enum CoverageLimitType {
    trailerInterchangeCoverageAmount = 'TRAILER_INTERCHANGE_COVERAGE_AMOUNT',
    completedValue = 'COMPLETED_VALUE',
    aggregateLimit = 'AGGREGATE_LIMIT',
    otherFarmStructures = 'OTHER_FARM_STRUCTURES',
    pollutionLiability = 'POLLUTION_LIABILITY',
    scheduledFarmPersonalProperty = 'SCHEDULED_FARM_PERSONAL_PROPERTY',
    physicalDamage = 'PHYSICAL_DAMAGE',
    flood = 'FLOOD',
    additionalExpenseExtraExpense = 'ADDITIONAL_EXPENSE_EXTRA_EXPENSE',
    earthquake = 'EARTHQUAKE',
    perOccurrenceLimit = 'PER_OCCURRENCE_LIMIT',
    improvementsAndBetterments = 'IMPROVEMENTS_AND_BETTERMENTS',
    propertyDamagePerAccident = 'PROPERTY_DAMAGE_PER_ACCIDENT',
    collision = 'COLLISION',
    scheduledProperty = 'SCHEDULED_PROPERTY',
    costPerAcre = 'COST_PER_ACRE',
    unscheduledFarmPersonalProperty = 'UNSCHEDULED_FARM_PERSONAL_PROPERTY',
    lossOfUse = 'LOSS_OF_USE',
    combinedSingleLimit = 'COMBINED_SINGLE_LIMIT',
    buildingDwelling = 'BUILDING_DWELLING',
    bodilyInjuryPerAccident = 'BODILY_INJURY_PER_ACCIDENT',
    offPremisesStorage = 'OFF_PREMISES_STORAGE',
    businessIncomeLossOfIncome = 'BUSINESS_INCOME_LOSS_OF_INCOME',
    trailerComprehensiveCoverageLimit = 'TRAILER_COMPREHENSIVE_COVERAGE_LIMIT',
    hail = 'HAIL',
    increasedCostOfCompliance = 'INCREASED_COST_OF_COMPLIANCE',
    comprehensive = 'COMPREHENSIVE',
    scheduledLimit = 'SCHEDULED_LIMIT',
    otherStructures = 'OTHER_STRUCTURES',
    personalPropertyContents = 'PERSONAL_PROPERTY_CONTENTS',
    bodilyInjuryPerPerson = 'BODILY_INJURY_PER_PERSON',
    trailerCollisionCoverageLimit = 'TRAILER_COLLISION_COVERAGE_LIMIT',
}
