<template>
    <div class="SubtleAlert" :class="'SubtleAlert--' + type">
        <span class="SubtleAlert__icon">
            <FontAwesomeIcon :icon="icon" />
        </span>
        <span class="SubtleAlert__message">
            <slot />
        </span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import { SubtleAlertType } from './types';

    const alertIcons: Record<SubtleAlertType, IconDefinition> = {
        [SubtleAlertType.warning]: faExclamationTriangle,
        [SubtleAlertType.error]: faExclamationTriangle,
        [SubtleAlertType.success]: faCheckCircle,
    };

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class SubtleAlert extends Vue {
        @Prop({ type: String, default: 'success' })
        private type!: SubtleAlertType;

        private get icon() {
            return alertIcons[this.type];
        }
    }
</script>
