<template>
    <div class="JsonSchemaArrayForm" :class="{'JsonSchemaArrayForm--focus': focused}">
        <FormElement :label="form.schema.title" :required="required" force-error-slot>
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>{{ typeLabel }}</span>
                </div>
            </template>
            <div v-for="(item, index) in iteratorValue" :key="index" class="JsonSchemaArrayForm__item">
                <component
                    :is="FormElementComponent"
                    :id="`${id}_${index}`"
                    :key="index"
                    :form-element-component="FormElementComponent"
                    :custom-component-input="customComponentInput"
                    :depth="depth + 1"
                    :form="form.item"
                    :value="item"
                    :touched="touched"
                    :required="required"
                    :disabled="disabled"
                    :strings="strings"
                    :deletable="minItems < itemsLength"
                    :description="form.schema.description"
                    v-bind="$attrs"
                    @change="onChange(index, $event)"
                    @focus="onChildFocused(index)"
                    @touch="onChildTouched(index)"
                    @delete="onDelete(index)"
                    @input="onInput(index, $event)"
                >
                    <template v-if="$slots.icon" #icon>
                        <slot name="icon" />
                    </template>
                </component>
            </div>
            <FormInput force-action-slot>
                <Button
                    v-if="!disabled"
                    class="JsonSchemaArrayForm__addButton"
                    type="primary"
                    :disabled="!valid || itemsLength >= maxItems"
                    :icon="faPlus"
                    @click="onAdd"
                >
                    <strong>{{ form.schema.addItemTitle || 'ADD' }}</strong>
                </Button>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faPlus, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
    import { JsonFormArray } from '@evidentid/json-schema/interfaces/JsonForm';
    import { Button } from '../../Button';
    import FormElement from '../../Form/FormElement.vue';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';
    import FormInput from '../../Form/FormInput.vue';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';

    @Component({
        components: { FormInput, FormElement, Button, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaArrayForm extends AbstractJsonSchemaForm<JsonFormArray, any[]> {
        private faPlus = faPlus;
        private faExclamationCircle = faExclamationCircle;
        private childAccessedList: boolean[] = new Array(this.itemsLength);
        private focusedChildIndex: number | null = null;

        private get iteratorValue(): any[] {
            if (!this.value || !Array.isArray(this.value) || this.value.length >= this.minItems) {
                return this.value;
            }
            const missingItems = this.minItems - this.value.length;
            return [ ...this.value ].concat(Array.from(new Array(missingItems)).map(() => null));
        }

        private get itemsLength() {
            return Array.isArray(this.value) ? this.value.length : 0;
        }

        private get minItems() {
            return this.form.schema.minItems || 0;
        }

        private get maxItems() {
            return this.form.schema.maxItems == null ? Infinity : this.form.schema.maxItems;
        }

        private get typeLabel() {
            const stringFormat = (this.form.item.schema as JsonSchemaString).format;
            const label = this.form.item.type === 'string' && stringFormat ? stringFormat : this.form.item.type;
            return `${label.charAt(0).toUpperCase()}${label.substring(1).toLowerCase()} only`;
        }

        protected override get TypeLabelClass(): string | null {
            if (!this.focused || this.focusedChildIndex == null) {
                return null;
            }
            return this.childAccessedList[this.focusedChildIndex] && !this.isChildValid(this.focusedChildIndex)
                ? 'JsonSchemaForm__typeLabel--error'
                : 'JsonSchemaForm__typeLabel--focused';
        }

        private isChildValid(index: number): boolean {
            return !this.required && !this.value[index] ? true : this.form.item.isValid(this.value[index]);
        }

        private onChildFocused(index: number): void {
            this.focused = true;
            this.focusedChildIndex = index;
        }

        private onChildTouched(index: number): void {
            this.focused = false;
            this.childAccessedList[index] = true;
            this.focusedChildIndex = null;
        }

        private onAdd() {
            if (this.valid) {
                this.childAccessedList.push(false);
                this.$emit('input', this.form.getValue([
                    ...(this.value || []),
                    this.form.item.getValue(),
                ]));
            }
        }

        protected onDelete(index: number): void {
            this.childAccessedList = [
                ...this.childAccessedList.slice(0, index),
                ...this.childAccessedList.slice(index + 1),
            ];
            const val = this.form.getValue([
                ...this.value.slice(0, index),
                ...this.value.slice(index + 1),
            ]);
            this.$emit('input', val);
            this.$emit('change', val);
        }

        private onInput(index: number, value: any) {
            this.$emit('input', this.form.getValue([
                ...this.value.slice(0, index),
                value,
                ...this.value.slice(index + 1),
            ]));
        }

        private onChange(index: number, value: any) {
            this.$emit('change', this.form.getValue([
                ...this.value.slice(0, index),
                value,
                ...this.value.slice(index + 1),
            ]));
        }
    }
</script>
