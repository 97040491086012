import startCase from 'lodash/startCase';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

const attributeAndRequirementTypeMap: Record<string, TprmRequirementType> = {
    '3rd_party_fidelity_bond': TprmRequirementType.thirdPartyFidelityBond,
    'automobile_liability': TprmRequirementType.businessAutoLiability,
    'aviation_liability': TprmRequirementType.aviationLiability,
    'bailees_insurance': TprmRequirementType.baileesInsurance,
    'boatowners': TprmRequirementType.boatowners,
    'builders_risk': TprmRequirementType.buildersRisk,
    'cargo_liability': TprmRequirementType.cargoLiability,
    'collective_personal_accident_insurance': TprmRequirementType.collectivePersonalAccidentInsurance,
    'commercial_auto_liability': TprmRequirementType.businessAutoLiability,
    'commercial_auto_physical_damage': TprmRequirementType.commercialAutoPhysicalDamage,
    'commercial_crime': TprmRequirementType.commercialCrimeLiability,
    'commercial_general_liability': TprmRequirementType.commercialGeneralLiability,
    'commercial_property': TprmRequirementType.commercialProperty,
    'commercial_trailer_interchange': TprmRequirementType.commercialTrailerInterchange,
    'comprehensive_crime': TprmRequirementType.commercialCrimeLiability,
    'contingent_auto_liability': TprmRequirementType.contingentAutoLiability,
    'contingent_cargo_liability': TprmRequirementType.contingentCargoLiability,
    'crop_insurance': TprmRequirementType.cropInsurance,
    'crane_liability': TprmRequirementType.craneLiability,
    'cyber_liability': TprmRequirementType.cyberLiability,
    'declarations_page': TprmRequirementType.declarationsPage,
    'dwelling': TprmRequirementType.dwelling,
    'directors_and_officers_liability': TprmRequirementType.directorsAndOfficersLiability,
    'employee_dishonesty': TprmRequirementType.employeeDishonesty,
    'employers_liability': TprmRequirementType.employersLiability,
    'employment_practices_liability': TprmRequirementType.employmentPracticesLiability,
    'environmental_liability': TprmRequirementType.environmentalLiability,
    'earthquake_insurance': TprmRequirementType.earthquakeInsurance,
    'farmowners': TprmRequirementType.farmowners,
    'fleet': TprmRequirementType.fleet,
    'flood_insurance': TprmRequirementType.floodInsurance,
    'garage_keepers_liability': TprmRequirementType.garageKeepersLiability,
    'garage_liability': TprmRequirementType.garageLiability,
    'hold_harmless_agreement': TprmRequirementType.holdHarmlessAgreement,
    'homeowners': TprmRequirementType.homeowners,
    'hull_and_machinery': TprmRequirementType.hullAndMachinery,
    'inland_marine': TprmRequirementType.inlandMarineCommercialAuto,
    'inland_marine_commercial_auto': TprmRequirementType.inlandMarineCommercialAuto,
    'leased_or_rented_equipment': TprmRequirementType.leasedOrRentedEquipment,
    'life_insurance': TprmRequirementType.lifeInsurance,
    'longshore_and_harbor_workers_compensation': TprmRequirementType.longshoreAndHarborWorkersCompensation,
    'loss_of_income': TprmRequirementType.lossOfIncome,
    'marine_general_liability': TprmRequirementType.marineGeneralLiability,
    'maritime_employers_liability': TprmRequirementType.maritimeEmployersLiability,
    'media_liability': TprmRequirementType.mediaLiability,
    'medical_errors_and_omissions': TprmRequirementType.medicalErrorsAndOmissions,
    'non_trucking_liability': TprmRequirementType.nonTruckingLiability,
    'occupational_accident': TprmRequirementType.occupationalAccident,
    'personal_liability': TprmRequirementType.personalLiability,
    'private_passenger_auto': TprmRequirementType.privatePassengerAuto,
    'products_and_completed_operations': TprmRequirementType.productsAndCompletedOperations,
    'professional_liability': TprmRequirementType.professionalLiability,
    'property_insurance': TprmRequirementType.commercialProperty,
    'protection_and_indemnity': TprmRequirementType.protectionAndIndemnity,
    'proof_of_payment': TprmRequirementType.proofOfPayment,
    'public_and_products_liability': TprmRequirementType.publicAndProductsLiability,
    'riggers_liability': TprmRequirementType.riggersLiability,
    'schedule_of_forms_and_endorsements': TprmRequirementType.scheduleOfFormsAndEndorsements,
    'sexual_abuse_and_molestation': TprmRequirementType.sexualAbuseAndMolestationLiability,
    'student_accident_medical': TprmRequirementType.studentAccidentMedical,
    'surety_bond': TprmRequirementType.suretyBond,
    'technology_liability': TprmRequirementType.technologyLiability,
    'umbrella_excess_liability': TprmRequirementType.umbrellaExcessLiability,
    'umbrella_liability': TprmRequirementType.umbrellaExcessLiability,
    'w_9_form': TprmRequirementType.w9Form,
    'warehouse_legal_liability': TprmRequirementType.warehouseLegalLiability,
    'watercraft': TprmRequirementType.watercraft,
    'workers_compensation': TprmRequirementType.workersCompensation,
    'workers_compensation_waiver': TprmRequirementType.workersCompensationWaiver,
    'yacht_insurance': TprmRequirementType.yachtInsurance,
};

export function getRequirementTypeVersionByAttribute(attr: string): string | null {
    const version = attr.split('.')[4];
    return /v\d+/.test(version) ? version : null;
}

export function getRequirementTypeByAttribute(attr: string): TprmRequirementType | null {
    const typePortion = attr.split('.')[3];
    return attributeAndRequirementTypeMap[typePortion] || null;
}

export function getRequirementTypeLabelByAttribute(
    attr: string,
    withVersion?: boolean,
    requirementTypeModels?: TprmRequirementModel[],
): TprmRequirementType | string | null {
    const requirementType = getRequirementTypeByAttribute(attr);
    if (!requirementType) {
        return null;
    }
    const typeModel = requirementTypeModels && requirementTypeModels.length > 0
        ? requirementTypeModels.find((model) => model.coverageType === requirementType)
        : null;
    const typeLabel = typeModel?.label || startCase(requirementType.toLowerCase());
    const version = getRequirementTypeVersionByAttribute(attr);
    return withVersion && version
        ? `${typeLabel} ${version}`
        : typeLabel;
}
