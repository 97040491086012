<template>
    <div class="ActionBar" :class="{'ActionBar--withMessage': message || $slots.message}">
        <div v-if="message || $slots.message" class="ActionBar__message">
            <slot name="message">
                {{ message }}
            </slot>
        </div>
        <slot>
            <Button class="ActionBar__submitButton"
                type="primary"
                :disabled="disabled"
                @click="$emit('submit')"
            >
                {{ buttonText }}
            </Button>
        </slot>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '../Button';

    @Component({
        components: { Button },
    })
    export default class ActionBar extends Vue {
        @Prop({ type: String, default: 'Save' })
        private buttonText!: string;

        @Prop({ type: String, default: '' })
        private message!: string;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;
    }
</script>
