<template>
    <LoginLayout title="Create your Evident Insurance account" :hide-footer="duringSubmit" wide>
        <template v-slot:header>
            <img :src="logoUrl" alt="">
        </template>

        <template v-slot:description>
            Need help? <a href="https://www.evidentid.com/contact-sales/" target="_blank">Talk to our sales team</a>.
        </template>

        <template v-slot:footer>
            Already have an account? <a href="#/">Sign in</a>.
        </template>

        <LoginLayoutSections horizontal>
            <LoginLayoutSection title="New account with single sign-on (SSO)">
                <OauthButtonList>
                    <OauthButton
                        v-if="hasMethod('google')"
                        type="google"
                        :icon="googleLogoUrl"
                        :disabled="duringSubmit"
                        @click="startSsoLogin('google')"
                    >
                        Sign in with Google
                    </OauthButton>
                    <OauthButton
                        v-if="hasMethod('external')"
                        type="external"
                        :icon="faLock"
                        :disabled="duringSubmit"
                        @click="startSsoLogin('external')"
                    >
                        Sign in
                    </OauthButton>
                </OauthButtonList>
            </LoginLayoutSection>

            <LoginLayoutSection title="New account with email login" v-if="hasMethod('credentials')">
                <Form @submit.prevent="startRegularRegistration">
                    <FormElement label-for="register-email" label="Email:">
                        <input
                            id="register-email"
                            v-model="email"
                            type="email"
                            name="email"
                            :disabled="duringSubmit"
                        >
                    </FormElement>
                    <FormElement label-for="register-password" label="Password (12+ characters):">
                        <input
                            id="register-password"
                            v-model="password"
                            type="password"
                            name="password"
                            :disabled="duringSubmit"
                        >
                    </FormElement>
                    <FormElement label-for="register-repeat" label="Repeat password:">
                        <input
                            id="register-repeat"
                            v-model="repeatPassword"
                            type="password"
                            name="repeat"
                            :disabled="duringSubmit"
                        >
                    </FormElement>

                    <template v-slot:buttons>
                        <Button :loading="duringSubmit" :disabled="!canSubmit" type="primary" submit>
                            Create Account
                        </Button>
                    </template>
                </Form>
            </LoginLayoutSection>
        </LoginLayoutSections>
    </LoginLayout>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faLock } from '@fortawesome/free-solid-svg-icons';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Form, FormElement } from '@evidentid/dashboard-commons/components/Form';
    import { OauthButton, OauthButtonList } from '@evidentid/dashboard-commons/components/OauthButtonList';
    import {
        LoginLayout,
        LoginLayoutDivider,
        LoginLayoutSection,
        LoginLayoutSections,
    } from '@evidentid/dashboard-commons/layouts/LoginLayout';
    import googleLogoUrl from '@/assets/icons/google-logo.svg';

    @Component({
        components: {
            FontAwesomeIcon, LoginLayoutDivider, LoginLayout,
            LoginLayoutSections, LoginLayoutSection,
            OauthButtonList, OauthButton, Button, FormElement, Form,
        },
    })
    export default class RegisterPage extends Vue {
        private logoUrl = logoUrl;
        private googleLogoUrl = googleLogoUrl;
        private faLock = faLock;

        private email = '';
        private password = '';
        private repeatPassword = '';

        private get duringSubmit(): boolean {
            return Boolean(this.$store.state.user.duringRegistration || this.$store.state.user.duringSsoLogin);
        }

        private get canSubmit(): boolean {
            return !this.duringSubmit && Boolean(this.email && this.password && this.repeatPassword);
        }

        private hasMethod(method: string): boolean {
            return this.$auth?.availableRegisterMethods.includes(method);
        }

        private async startSsoLogin(method: string): Promise<void> {
            if (!this.duringSubmit) {
                await this.$store.actions.snackbar.displaySnackbar(null);
                return this.$store.actions.user.logInWithSso(method);
            }
        }

        private async startRegularRegistration(): Promise<void> {
            if (this.duringSubmit) {
                return;
            } else if (this.password !== this.repeatPassword) {
                return this.$store.actions.snackbar.displaySnackbar({
                    message: 'Passwords do not match.',
                    success: false,
                    permanent: true,
                });
            } else if (this.password.length < 12) {
                return this.$store.actions.snackbar.displaySnackbar({
                    message: 'Password should be at least 12 characters long.',
                    success: false,
                    permanent: true,
                });
            }

            await this.$store.actions.snackbar.displaySnackbar(null);
            this.$store.actions.user.register({ email: this.email, password: this.password });
        }
    }
</script>
