<template>
    <Dropdown
        multi-select
        :disabled="!hasAvailableOptions"
        :options="options"
        :selected="selected"
        @input="$emit('input', $event)"
        @select="$emit('select', $event)"
        @clear="$emit('clear', $event)"
    >
        <template #additionalContent>
            <div class="RiskProfileFilter__filterType">
                <span class="RiskProfileFilter__filterTypeOptionsLabel">
                    Filter type:
                </span>
                <div class="RiskProfileFilter__filterType">
                    <RadioOption
                        class="RiskProfileFilter__filterTypeOption"
                        name="FilterType"
                        :checked="filterType === 'AND'"
                        :disabled="!hasAvailableOptions"
                        @click="$emit('changeFilterType', 'AND')"
                    >
                        And
                    </RadioOption>
                    <RadioOption
                        class="RiskProfileFilter__filterTypeOption"
                        name="FilterType"
                        :checked="filterType === 'OR'"
                        :disabled="!hasAvailableOptions"
                        @click="$emit('changeFilterType', 'OR')"
                    >
                        Or
                    </RadioOption>
                </div>
            </div>
        </template>
    </Dropdown>
</template>
<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { FilterType } from '@/modules/entity-filtering/types';

    @Component({
        components: { RadioOption, Dropdown },
    })
    export default class RiskProfileFilter extends Vue {
        @Prop({ type: Array, default: () => [] })
        private selected!: DropdownOption[];

        @Prop({ type: Array, default: () => [] })
        private options!: DropdownOption[];

        @Prop({ type: String, default: null })
        private filterType!: FilterType | null;

        private get hasAvailableOptions(): boolean {
            return this.options.length > 0;
        }
    }
</script>
