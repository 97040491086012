import { reduce } from 'lodash';
import { DocumentAttributeRegexType } from '@/modules/entity-documents/models/DocumentAttributeRegexType.model';
import { getDocumentAttributeRegex } from '@/modules/entity-documents/services/getDocumentAttributeRegex';

// keeping only one doc per request as we only assume same doc used for all insurance requirement types per request
export function removeExtraDocumentAcrossRequirement(rprDocsByAttribute: Record<string, any[]>) {
    let documentFound = false;
    const regex = getDocumentAttributeRegex(DocumentAttributeRegexType.coi);
    return reduce(rprDocsByAttribute, (result, value, key) => {
        if (regex.test(key)) {
            if (!documentFound) {
                result[key] = value;
                documentFound = true;
            }
        } else {
            result[key] = value;
        }
        return result;
    }, {} as Record<string, any[]>);
}
