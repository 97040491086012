<template>
    <DocumentDetailsModal
        v-if="opened"
        :email="email"
        :document="opened"
        :requirement-models="requirementModels"
        @close="close"
    />
    <EntitiesDocumentsModal
        v-else
        :status="status"
        :email="email"
        :documents="content"
        :requirement-models="requirementModels"
        @close="close"
        @open="open"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { EntityDocumentsState } from '@/modules/entity-documents/vuex';
    import { RprDocumentDetails } from '@/modules/entity-documents/types';
    import EntitiesDocumentsModal from '@/modules/entity-documents/components/EntitiesDocumentsModal.vue';
    import DocumentDetailsModal from '@/modules/entity-documents/components/DocumentDetailsModal.vue';

    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

    @Component({
        components: {
            DocumentDetailsModal,
            EntitiesDocumentsModal,
        },
    })
    export default class EntityDocumentsListProcedure extends Vue {
        @Prop({ type: String })
        private rpName!: string;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: Array })
        private requirementModels!: TprmRequirementModel[];

        private opened: RprDocumentDetails | null = null;

        private get state(): EntityDocumentsState {
            return this.$store.state.entityDocuments;
        }

        private get content(): RprDocumentDetails[] {
            const rpDocuments = this.state.rprDocuments[this.rpName] || {};
            const emailDocuments = rpDocuments[this.email] || {};
            return emailDocuments.content || [];
        }

        private get status(): OperationStatus {
            const rpDocuments = this.state.rprDocuments[this.rpName] || {};
            const emailDocuments = rpDocuments[this.email] || {};
            return emailDocuments.status || OperationStatus.uninitialized;
        }

        @Watch('status', { immediate: true })
        private onLoadingStatusChange(status: OperationStatus): void {
            if (status === OperationStatus.uninitialized) {
                this.$store.actions.entityDocuments.loadRprDocumentDetails({
                    rpName: this.rpName,
                    email: this.email,
                });
            }
        }

        private destroyed() {
            // TODO(PRODUCT-17636): Handle race condition: closing modal during loading will cause data being in memory
            this.$store.actions.entityDocuments.clearRprDocumentDetails({
                rpName: this.rpName,
                email: this.email,
            });
        }

        private open(document: RprDocumentDetails): void {
            this.opened = document;
        }

        private close(): void {
            if (this.opened) {
                this.opened = null;
            } else {
                this.$emit('abort');
            }
        }
    }
</script>
