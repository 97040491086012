import { createEmptyEntityVuexStatus } from '@/utils/vuex-common/createEmptyEntityVuexStatus';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

interface PayloadWithoutData {
    rpName: string;
}

interface PayloadWithData<T> {
    rpName: string;
    data: T;
}

export function mutateCommonEntityStartState<T>(state: any, stateKey: string, payload: PayloadWithoutData): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: {
            ...createEmptyEntityVuexStatus<T>(),
            ...state[stateKey][payload.rpName],
            status: OperationStatus.loading,
        },
    };
}

export function mutateCommonEntityFinishState<T>(state: any, stateKey: string, payload: PayloadWithData<T>): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: {
            ...state[stateKey][payload.rpName],
            status: OperationStatus.success,
            data: payload.data,
        },
    };
}

export function mutateCommonEntityFailState<T>(state: any, stateKey: string, payload: PayloadWithoutData): void {
    state[stateKey] = {
        ...state[stateKey],
        [payload.rpName]: {
            ...state[stateKey][payload.rpName],
            status: OperationStatus.error,
            data: createEmptyEntityVuexStatus<T>().data,
        },
    };
}
