import omit from 'lodash/omit';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { filterCustomPropertiesBySchema } from '@/modules/decisioning-criteria/utils/filterCustomPropertiesBySchema';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

export function convertCustomPropertySchemaToEnumSchema(
    ifSchema: JsonSchema,
    customProperties: CustomProperty[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    const filteredInsureds = filterCustomPropertiesBySchema(customProperties, schemaFilterBy);

    if (filteredInsureds.length === 0) {
        return ifSchema;
    }

    return {
        ...omit(ifSchema, [ 'format', 'type' ]),
        enum: filteredInsureds.map((field) => field.key),
        enumLabels: filteredInsureds.map((field) => field.name),
    };
}
