<template>
    <div class="RiskProfiles">
        <RiskProfileEmptyState v-if="riskProfiles.length === 0" v-on="$listeners" />
        <RiskProfileTable v-else :risk-profiles="riskProfiles" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import RiskProfileEmptyState
        from '@/modules/decisioning-criteria/components/RiskProfileEmptyState/RiskProfileEmptyState.vue';
    import RiskProfileTable
        from '@/modules/decisioning-criteria/components/RiskProfileTable/RiskProfileTable.vue';
    import { RiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';

    @Component({
        components: {
            RiskProfileTable,
            RiskProfileEmptyState,
        },
    })
    export default class RiskProfiles extends Vue {
        @Prop({ type: Array, default: () => [] })
        private riskProfiles!: RiskProfile[];
    }
</script>
