import { getSessionData, setSessionData } from './getSetSessionData';
import { SESSION_KEY_CHAT_HISTORY } from './constants';
import { ChatMessage } from '../../models';

export function getSessionChatHistory(): ChatMessage[] {
    const sessionData = getSessionData(SESSION_KEY_CHAT_HISTORY);
    const parsedMessages = sessionData ? JSON.parse(sessionData) : null;
    return parsedMessages || [];
}

export function setSessionChatHistory(messages: ChatMessage[]): void {
    setSessionData(SESSION_KEY_CHAT_HISTORY, messages);
}

export function addMessageToSessionHistory(message: ChatMessage): void {
    const messages = getSessionChatHistory() || [];
    setSessionChatHistory([ ...messages, message ]);
}
