import { render, staticRenderFns } from "./UsersDashboard.vue?vue&type=template&id=c6ba8e2e&"
import script from "./UsersDashboard.vue?vue&type=script&lang=ts&"
export * from "./UsersDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./UsersDashboard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports