import cloneDeep from 'lodash/cloneDeep';
import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { getAllSubstitutableSchemas } from '@/modules/decisioning-criteria/utils/getAllSubstitutableSchemas';
import { convertCustomPropertySchemaToEnumSchema } from '@/modules/decisioning-criteria/utils/criterionSchemaConversion';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

export function standardizeCustomPropertySchema(
    schema: JsonSchema|JsonSchemaComplexObject,
    customProperties?: CustomProperty[],
): JsonSchema {
    const resultSchema = cloneDeep(schema);
    if (!customProperties) {
        return resultSchema;
    }
    const substitutableSchemas = getAllSubstitutableSchemas(resultSchema);
    substitutableSchemas.forEach(({ schema: substitutableSchema }) => {
        const valueSchema = substitutableSchema.else.properties!.value;
        const customPropertySchema = substitutableSchema.then.properties!.insuredField;
        substitutableSchema.then.properties!.insuredField
            = convertCustomPropertySchemaToEnumSchema(customPropertySchema, customProperties, valueSchema);
    });
    return resultSchema;
}

