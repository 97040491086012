<template>
    <div class="SingleSelectOptions">
        <div class="SingleSelectOptions__options">
            <DropdownSelectOption
                v-for="(option, index) of options"
                :key="index"
                :disabled="isDisabledOption(option)"
                @click="selectOption(option)"
            >
                <span>{{ option.label }}</span>
            </DropdownSelectOption>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { DropdownOption, IsDisabledOption } from './types';
    import DropdownSelectOption from './DropdownSelectOption.vue';

    @Component({
        components: {
            DropdownSelectOption,
        },
    })
    export default class SingleSelectOption extends Vue {
        @Prop({ type: Array, default: () => [] })
        private options!: DropdownOption[];

        @Prop({ type: Function as PropType<IsDisabledOption>, default: () => false })
        private isDisabledOption!: IsDisabledOption;

        private selectOption(option: DropdownOption): void {
            this.$emit('select', option);
        }
    }
</script>
