import JsonSchema, { JsonSchemaComplexObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { convertCustomPropertySchemaToEnumSchema } from '@/modules/decisioning-criteria/utils/criterionSchemaConversion';
import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
import { TprmSchema } from '@evidentid/tprm-portal-lib/models/common/TprmSchema.model';

function standardizeCustomPropertySchema(
    schema: JsonSchema|JsonSchemaComplexObject,
    customProperties?: CustomProperty[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    let moddedSchema = schema;

    if ('if' in schema) {
        const thenSchema = Object.values(schema.then?.properties || {})[0];
        const elseSchema = Object.values(schema.else?.properties || {})[0];
        const thenDisplayFormat = (thenSchema as TprmSchema)?.displayFormat;
        const elseDisplayFormat = (elseSchema as TprmSchema)?.displayFormat;
        const thenInsuredValueTypeFilterBy = thenDisplayFormat === TprmSchemaDisplayFormat.insuredField ?
            elseSchema
            : null;
        const elseInsuredValueTypeFilterBy = elseDisplayFormat === TprmSchemaDisplayFormat.insuredField ?
            thenSchema
            : null;

        moddedSchema = {
            ...schema,
            if: standardizeCustomPropertySchema(schema.if as JsonSchema, customProperties),
            then: standardizeCustomPropertySchema(
                schema.then as JsonSchema,
                customProperties,
                thenInsuredValueTypeFilterBy
            ),
            else: standardizeCustomPropertySchema(
                schema.else as JsonSchema,
                customProperties,
                elseInsuredValueTypeFilterBy
            ),
        } as JsonSchemaComplexObject;
    }

    if ('properties' in moddedSchema) {
        return {
            ...moddedSchema,
            properties: Object.keys(moddedSchema.properties).reduce((acc, key) => ({
                ...acc,
                [key]: standardizeCustomPropertySchema(
                    (moddedSchema as JsonSchemaComplexObject).properties[key],
                    customProperties,
                    schemaFilterBy
                ),
            }), {}),
        };
    } else if ('items' in moddedSchema) {
        return {
            ...moddedSchema,
            items: standardizeCustomPropertySchema(
                moddedSchema.items as JsonSchema,
                customProperties,
            ),
        };
    }

    const isCustomPropertySchema =
        (moddedSchema as TprmSchema).displayFormat === TprmSchemaDisplayFormat.insuredField &&
        customProperties && customProperties.length > 0;

    if (isCustomPropertySchema) {
        return convertCustomPropertySchemaToEnumSchema(moddedSchema, customProperties, schemaFilterBy);
    }

    return moddedSchema;
}

export function standardizeCriterionSchema(
    schema: JsonSchema,
    customProperties?: CustomProperty[],
    schemaFilterBy?: JsonSchema | null,
): JsonSchema {
    return removeNonStandardSchemaFormat(
        standardizeCustomPropertySchema(schema, customProperties, schemaFilterBy),
    );
}
