<template>
    <span class="LocalDate">
        {{ formattedDate }}
    </span>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import moment from 'moment';

    @Component
    export default class LocalDate extends Vue {
        @Prop({ type: String, default: 'MM/DD/YYYY, hh:mm a' })
        private format!: string;

        @Prop({ type: String })
        private value!: string; // ISO Date in UTC

        private formattedDate: string = '';

        @Watch('value', { immediate: true })
        @Watch('format', { immediate: true })
        private onDateChange() {
            this.formattedDate = moment.utc(this.value).local().format(this.format);
        }
    }
</script>
