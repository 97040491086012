import capitalize from 'lodash/capitalize';

// WYSIWYG
export function alphabetize(list: string[]): string[] {
    return list.sort((a, b) => a.localeCompare(b));
}

/*
 * Truncate string to `len` characters, replacing the last three
 * characters of the truncation with '...'. Shorter strings are
 * left alone, and caps <= 3 return only the ellipsis.
 *
 * ellipsis('Hello, world!', 8) === 'Hello...'
 * ellipsis('Hello!', 8) === 'Hello!'
 * ellipsis('Hello!', 0) === '...'
 */
export function ellipsis(s: string, len: number): string {
    return s.length > len
        ? `${(len > 3) ? s.substring(0, len - 3) : ''}...`
        : s;
}

/*
 * In this variant the ellipsis starts roughly halfway through the string.
 */
export function midellipsis(s: string, len: number): string {
    if (s.length <= len) {
        return s;
    } else {
        const desiredLengthSansEllipsis = len - 3;
        const prefixLength = Math.ceil(desiredLengthSansEllipsis / 2);
        const suffixLength = Math.floor(desiredLengthSansEllipsis / 2);

        return `${s.substring(0, prefixLength)}...${s.substring(s.length - suffixLength)}`;
    }
}

/*
 * "Naive" title case capitalizes ALL words separated by at least one whitespace
 * character and assumes the result is separated by at most one blank space.
 *
 * Proper title case would account for filler words like "a" or "of", while
 * preserving whitespace characters.
 */
export function naiveTitleCase(s: string): string {
    return s
        .split(/\s+/g)
        .map((t) => capitalize(t)).join(' ');
}
