<template>
    <CriterionDateDurationInput v-bind="$props" unit="days" v-on="$listeners" />
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { JsonSchemaInteger } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/AbstractCriterionInput';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import CriterionDateDurationInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionDateDurationInput.vue';

    @Component({
        components: { CriterionDateDurationInput },
    })
    export default class CriterionDaysInput extends AbstractCriterionInput<JsonFormBase<JsonSchemaInteger>, number> {}
</script>
