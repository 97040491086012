<template>
    <div class="DocumentsViewer">
        <Navigation
            title="File"
            :current-position="documents.length === 0 ? 0 : currentIndex + 1"
            :max-position="documents.length"
            @next="nextDoc()"
            @previous="previousDoc()"
        />
        <div v-if="documents.length === 0">
            There are no documents attached.
        </div>
        <BinaryDataDisplay v-else-if="currentContent" :value="currentContent" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import BinaryDataDisplay from '@/components/binary-data-display/BinaryDataDisplay.vue';
    import Navigation
        from '@/modules/entity-actions-review/components/Navigation/Navigation.vue';

    @Component({
        components: { Navigation, BinaryDataDisplay },
    })
    export default class DocumentsViewer extends Vue {
        @Prop({ type: Array, default: () => [] })
        private documents!: any[];

        private currentIndex = 0;

        private get currentContent(): any {
            return this.documents[this.currentIndex]?.content || null;
        }

        private nextDoc(): void {
            if (this.currentIndex < this.documents.length - 1) {
                this.currentIndex++;
            }
        }

        private previousDoc(): void {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        }
    }
</script>
