import Severity from './Severity';
import { BreadcrumbInput } from './Breadcrumb';
import LoggerUserData from './LoggerUserData';
import LoggerAdapter from './LoggerAdapter';

export default class Logger<T extends LoggerUserData = LoggerUserData> {
    private readonly adapter: LoggerAdapter<T>;
    private userData: T | null = null;

    public constructor(adapter: LoggerAdapter<T>) {
        this.adapter = adapter;
    }

    public addBreadcrumb(breadcrumb: BreadcrumbInput) {
        return this.adapter.addBreadcrumb({
            level: Severity.info,
            ...breadcrumb,
            data: breadcrumb.data || {},
        });
    }

    public setTags(tags: Record<string, string>) {
        return this.adapter.setTags(tags);
    }

    public setTag(name: string, value: any) {
        return this.setTags({ [name]: value });
    }

    public setUser(userData: T | null) {
        this.userData = userData;
        return this.adapter.setUser(userData);
    }

    public appendUserData(userData: Partial<T>) {
        return this.setUser({
            ...(this.userData as T),
            ...userData,
        });
    }

    public log(level: Severity, message: string | Error) {
        return this.adapter.log(level, message);
    }

    public error(message: string | Error) {
        return this.log(Severity.error, message);
    }

    public warning(message: string | Error) {
        return this.log(Severity.warning, message);
    }

    public info(message: string | Error) {
        return this.log(Severity.info, message);
    }

    public verbose(message: string | Error) {
        return this.log(Severity.verbose, message);
    }
}
