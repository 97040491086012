export enum OperationError {
    keyConflict = 'KeyConflict',
    notFound = 'NotFound',
    badRequest = 'BadRequest',
    badRoute = 'BadRoute',
    unauthorized = 'Unauthorized',
    unknown = 'Unknown',
}

export interface Change<T> {
    oldValue: T;
    newValue: T;
}
