<template>
    <div class="EntityRiskProfiles">
        <EmptyRiskProfiles
            v-if="entityRiskProfiles.length === 0"
            class="EntityRiskProfiles__emptyView"
            @goToRiskProfile="$emit('goToRiskProfileList')"
        />
        <RiskProfilesTable
            v-else
            :entity-risk-profiles="entityRiskProfiles"
            :entity="entity"
            @goToRiskProfile="$emit('goToRiskProfile', $event)"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import EmptyRiskProfiles
        from '@/modules/entity-details/components/EmptyRiskProfiles/EmptyRiskProfiles.vue';
    import RiskProfilesTable
        from '@/modules/entity-details/components/RiskProfilesTable/RiskProfilesTable.vue';

    @Component({
        components: {
            RiskProfilesTable,
            EmptyRiskProfiles,
        },
    })
    export default class EntityRiskProfiles extends Vue {
        @Prop({ type: Array, default: () => [] })
        private entityRiskProfiles!: EntityRiskProfile[];

        @Prop({ type: Object, required: true })
        private entity!: Entity;
    }
</script>
