import requirementTypesIcon from '@/modules/decisioning-criteria/assets/requirement-types-risk-profile-icon.svg';
import collateralTypeIcon from '@/modules/decisioning-criteria/assets/collateral-type-risk-profile-icon.svg';
import { RiskProfileType } from '@evidentid/tprm-portal-lib/models/decisioning';

const typeIconMapping = {
    [RiskProfileType.collateral]: collateralTypeIcon,
    [RiskProfileType.default]: requirementTypesIcon,
};

export function getRiskProfileTypeIconSrc(type: RiskProfileType): string {
    return typeIconMapping[type] || requirementTypesIcon;
}
