<template>
    <div class="RiskProfilesTable">
        <div class="RiskProfilesTable__headers">
            <header class="RiskProfilesTable__displayNameHeader">
                Risk Profiles
            </header>
            <header class="RiskProfilesTable__requirementTypesHeader">
                Requirements
            </header>
            <header class="RiskProfilesTable__complianceStatusHeader">
                Compliance Status
            </header>
        </div>
        <div class="RiskProfilesTable__body">
            <RiskProfilesExpandableRows
                v-if="effectiveRiskProfiles.length > 0"
                :entity-risk-profiles="effectiveRiskProfiles"
                :entity="entity"
                @goToRiskProfile="$emit('goToRiskProfile', $event)"
            />
            <RiskProfilesTableRow
                v-for="riskProfile in discoveryRiskProfiles"
                :key="riskProfile.id"
                :entity-risk-profile="riskProfile"
                @goToRiskProfile="$emit('goToRiskProfile', $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import partition from 'lodash/partition';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import RiskProfilesTableRow
        from '@/modules/entity-details/components/RiskProfilesTableRow/RiskProfilesTableRow.vue';
    import RiskProfilesExpandableRows
        from '@/modules/entity-details/components/RiskProfilesExpandableRows/RiskProfilesExpandableRows.vue';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            RiskProfilesExpandableRows,
            RiskProfilesTableRow,
            Button,
            ComplianceStatusBadge,
        },
    })
    export default class RiskProfilesTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private entityRiskProfiles!: EntityRiskProfile[];

        @Prop({ type: Object, required: true })
        private entity!: Entity;

        private effectiveRiskProfiles: EntityRiskProfile[] = [];
        private discoveryRiskProfiles: EntityRiskProfile[] = [];

        @Watch('entityRiskProfiles', { immediate: true })
        private onRiskProfilesChange(): void {
            [ this.effectiveRiskProfiles, this.discoveryRiskProfiles ] =
                partition(this.entityRiskProfiles, (riskProfile) => riskProfile.active);
        }
    }
</script>
