<template>
    <div class="MainHeader">
        <div class="MainHeader__container">
            <div class="MainHeader__logoAndMenu">
                <div v-if="showMenuIcon" class="MainHeader__menuIcon" @click="$emit('menuClick')">
                    <slot name="menuIcon">
                        <FontAwesomeIcon :icon="faBars" />
                    </slot>
                </div>
                <div class="MainHeader__logo">
                    <slot name="logo">
                        <img alt="Evident ID" :src="logoUrl">
                    </slot>
                </div>
            </div>

            <div class="MainHeader__title">
                <slot name="title" />
            </div>
            <nav class="MainHeader__navigation">
                <slot name="navigation" />
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBars } from '@fortawesome/free-solid-svg-icons';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class MainHeader extends Vue {
        @Prop({ type: Boolean, default: false })
        private showMenuIcon!: boolean;

        private faBars = faBars;
        private logoUrl = logoUrl;
    }
</script>
