import {
    ActionMatchType,
    ActionReview,
    ActionReviewEntityNames,
    ActionType,
} from '../../models/entity-actions-review';

export function actionReviewHasEntityNames(
    actionReview: ActionReview
): actionReview is ActionReview & { entityNames: ActionReviewEntityNames } {
    return actionReview.action.$action === ActionType.entityNameMatchingV1
        && actionReview.action.matchType === ActionMatchType.insured
        && actionReview.entityNames != null;
}
