<template>
    <Row
        hoverable
        class="EntitiesTableRow"
        :class="{'EntitiesTableRow--alert': expiringSoon}"
    >
        <Cell class="EntitiesTableRow__selectionCell">
            <Checkbox :value="selected" :disabled="isPerformingBulkAction" @input="selectionChange" />
        </Cell>
        <Cell
            class="EntitiesTableRow__nameCell"
            @click="selectEntity"
        >
            <v-popover
                trigger="hover"
                :delay="{ show: 300, hide: 100 }"
                placement="right"
                popover-class="EntitiesTableRow__tooltip"
            >
                <div class="EntitiesTableRow__insuredNameEmailContainer tooltip-target">
                    <span class="EntitiesTableRow__nameText">{{ entity.displayName }}</span>
                    <span class="EntitiesTableRow__emailText">{{ entity.contactEmail }}</span>
                </div>
                <template slot="popover">
                    <EntityTooltipDetails :entity="entity" />
                </template>
            </v-popover>
        </Cell>
        <Cell
            class="EntitiesTableRow__entityEffectiveGroupCell"
            :entity="entity"
            @click="selectEntity"
        >
            <EffectiveRiskProfilesTooltipLabel
                :risk-profile-names="entityGroupNames"
                :show-label-prefix="false"
                placement="right"
            />
        </Cell>
        <Cell
            v-for="customProperty in displayedCustomProperties"
            :key="customProperty.id"
            class="EntitiesTableRow__customProperty"
            @click="selectEntity"
        >
            {{ getValue(customProperty) }}
        </Cell>

        <Cell
            class="EntitiesTableRow__expirationCell"
            @click="selectEntity"
        >
            <div v-if="entity.nextExpiration">
                <div class="EntitiesTableRow__expirationDate">
                    {{ formattedDate }}
                </div>
                <template v-if="expired">
                    <div class="EntitiesTableRow__daysBeforeExpired">
                        Expired
                    </div>
                </template>
                <template v-else-if="daysTilExpired === 0">
                    <div class="EntitiesTableRow__daysBeforeExpired">
                        Expires today
                    </div>
                </template>
                <template v-else>
                    <div class="EntitiesTableRow__daysBeforeExpired">
                        {{ daysTilExpiredString }}
                    </div>
                </template>
            </div>
        </Cell>
        <Cell class="EntitiesTableRow__complianceStatusCell" @click="selectEntity">
            <ComplianceStatusBadge
                v-if="entity.active"
                class="EntitiesTableRow__badge"
                :status="entity.complianceStatus"
            />
        </Cell>
        <Cell class="EntitiesTableRow__verificationStatus" @click="selectEntity">
            <div v-if="entity.active">
                <VerificationStatusBadge
                    v-if="entity.paused"
                    class="EntitiesTableRow__badge"
                >
                    Paused
                </VerificationStatusBadge>
                <VerificationStatusBadge
                    v-else
                    class="EntitiesTableRow__badge"
                    :status="entity.verificationStatus"
                />
            </div>
            <div v-else>
                <Badge class="EntitiesTableRow__deactivatedBadge">
                    Deactivated
                </Badge>
            </div>
        </Cell>
        <Cell class="EntitiesTableRow__actionsCell">
            <ActionsButton
                :entity="entity"
                :loading="isUpdating"
                :is-loading-submission-link="isLoadingSubmissionLink"
                :submission-link="submissionLink"
                hide-exception-options
                hide-view-mapping
                v-on="eventListeners"
            />
        </Cell>
    </Row>
</template>

<script lang="ts">
    import moment from 'moment';
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { LocalDate } from '@evidentid/dashboard-commons/components/LocalDate';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import ComplianceStatusBadge from '../ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import DocumentButton from '../DocumentButton/DocumentButton.vue';
    import EntityTooltipDetails from '@/modules/dashboard/components/EntityTooltipDetails/EntityTooltipDetails.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import VerificationStatusBadge
        from '@/modules/dashboard/components/VerificationStatusBadge/VerificationStatusBadge.vue';
    import EffectiveRiskProfilesTooltipLabel
        from '@/modules/entity-details/components/EffectiveRiskProfilesTooltipLabel/EffectiveRiskProfilesTooltipLabel.vue';
    import { isCustomPropertyAddress } from '@/utils/is-custom-property-address/isCustomPropertyAddress';
    import { getDisplayAddress } from '@/modules/entity-details/utils/getDisplayAddress';
    import { CustomProperty, CustomPropertyAddress, Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            VerificationStatusBadge, DocumentButton, LocalDate, Row, Cell, ComplianceStatusBadge,
            EntityTooltipDetails, ActionsButton, Badge, Checkbox, EffectiveRiskProfilesTooltipLabel,
        },
    })
    export default class EntitiesTableRow extends Vue {
        @Prop({ type: Object })
        private entity!: Entity;

        @Prop({ type: Array, default: () => [] })
        private displayedCustomProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedEntitiesIds!: string[];

        @Prop({ type: Boolean, default: false })
        private selected!: boolean;

        @Prop({ type: Boolean, default: false })
        private isPerformingBulkAction!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: String, default: '' })
        private submissionLink!: string;

        private eventListeners: any = {
            ...this.$listeners,
            'actionsButtonClicked': () => {
                this.$emit('loadEntityRequests', this.entity.id);
            },
            'removeExceptions': (exceptionId: string) => {
                this.$emit('removeExceptions', this.entity, [ exceptionId ]);
            },
            'grantExceptionToEntity': () => {
                this.$emit('grantExceptionToEntity', this.entity);
            },
            'open-historic-document-modal': () => {
                this.$emit('open-historic-document-modal', this.entity);
            },
        };

        private get daysTilExpired() {
            const expirationDate = moment(this.entity.nextExpiration).startOf('day');
            return expirationDate.diff(moment().startOf('day'), 'days');
        }

        private get expiringSoon(): boolean {
            return this.daysTilExpired >= 0 && this.daysTilExpired <= 30;
        }

        private get expired(): boolean {
            return this.daysTilExpired < 0;
        }

        private get daysTilExpiredString(): string {
            if (!this.entity.nextExpiration) {
                return '';
            }
            const days = this.daysTilExpired;
            return `${days} ${this.daysTilExpired > 1 ? 'days' : 'day'}`;
        }

        private get formattedDate(): string {
            return moment(this.entity.nextExpiration).format('MMM DD, YYYY');
        }

        private get isUpdating(): boolean {
            return this.currentlyUpdatedEntitiesIds.includes(this.entity.id);
        }

        private get entityGroupNames(): string[] {
            return (this.entity.effectiveGroup || [])
                .map((riskProfile) => riskProfile.displayName)
                .sort((a, b) => a.localeCompare(b));
        }

        private getValue(field: CustomProperty): string {
            const customPropertyValue = this.entity.insuredFields ? this.entity.insuredFields[field.key] : null;
            if (isCustomPropertyAddress(field)) {
                return getDisplayAddress({
                    schema: field.schema,
                    value: customPropertyValue as CustomPropertyAddress,
                });
            }
            switch (typeof customPropertyValue) {
                case 'boolean':
                    return customPropertyValue ? 'Yes' : 'No';
                case 'number':
                    // eventually we might consider take out en-us to support different countries when i18n needed
                    return customPropertyValue.toLocaleString(
                        'en-US',
                        { maximumFractionDigits: 0 },
                    );
                default:
                    return customPropertyValue?.toString() || '';
            }
        }

        private selectionChange(): void {
            this.$emit('selectionChange', this.entity);
        }

        private selectEntity(): void {
            this.$emit('selectEntity', this.entity);
        }

        private grantExceptionToEntity(): void {
            this.$emit('grantExceptionToEntity', this.entity);
        }
    }
</script>
