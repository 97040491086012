<template>
    <div
        v-if="isNonExtractionCriterion"
        class="CriterionSettingsDescription mb-6"
    >
        {{ descriptionText }}
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import {
        NON_EXTRACTION_FIELD_PREFIX, NON_EXTRACTION_SETTINGS_DESCRIPTION,
    } from '@/modules/decisioning-criteria/components/CriterionSettings/constants';
    import { CriterionTemplate } from '@evidentid/tprm-portal-lib/models/decisioning/Criterion.model';

    export default Vue.extend({
        name: 'CriterionSettingsDescription',
        props: {
            template: {
                type: Object as PropType<CriterionTemplate>,
                default: () => ({}) as CriterionTemplate,
            },
        },
        computed: {
            isNonExtractionCriterion(): boolean {
                return this.template.field?.startsWith(NON_EXTRACTION_FIELD_PREFIX);
            },
            descriptionText(): string {
                if (this.isNonExtractionCriterion) {
                    return NON_EXTRACTION_SETTINGS_DESCRIPTION;
                }
                return '';
            },
        },
    });
</script>
