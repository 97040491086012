<template>
    <Modal :additional-class-name="additionalClasses" open @close="close">
        <template v-slot:header>{{ header }}</template>
        <LogoLoader />
    </Modal>
</template>

<style lang="scss">
    .LoadingModal {
        .Modal__body {
            width: 750px;
            min-height: 300px;
        }

        .Modal__content {
            padding: 50px 0;
            align-items: center;
            justify-content: center;

            .LogoLoader {
                font-size: 0.5em;
                margin: 0 auto;
            }
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';

    @Component({
        components: {
            Modal,
            LogoLoader,
        },
    })
    export default class LoadingModal extends Vue {
        @Prop({ type: String, default: '' })
        private additionalClassName!: string;

        @Prop({ type: String, default: '' })
        private header!: string;

        private get additionalClasses(): string {
            return this.additionalClassName ? `LoadingModal ${this.additionalClassName}` : 'LoadingModal';
        }

        private close(): void {
            this.$emit('close');
        }
    }
</script>
