import { EvaluationResult } from '@evidentid/tprm-portal-lib/models/entity-details';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
import { EntityMultiFieldCriterion } from '@/modules/entity-details/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import { parseFieldValuePerUiConversion } from './parseFieldValuePerUiConversion';
import { parseFieldValuePerEnumAndError } from './parseFieldValuePerEnumAndError';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
import {
    TprmRequirementModel,
} from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

export function parseFieldValue(
    fieldName: string,
    fieldValue: unknown,
    otherData: {
        requirement: EntityRequirement;
        requirementModel?: TprmRequirementModel;
        evaluationResults: EvaluationResult[];
        evaluationErrors: Record<string, FieldEvaluationResultError>;
        multiFieldCriteria: EntityMultiFieldCriterion[];
        categorizedEnumLabels: CategorizedEnumLabels;
    }): any {
    let parsedValue = parseFieldValuePerUiConversion(fieldName, fieldValue);
    parsedValue = parseFieldValuePerEnumAndError(fieldName, parsedValue, {
        requirement: otherData.requirement,
        requirementModel: otherData.requirementModel,
        evaluationErrors: otherData.evaluationErrors,
        multiFieldCriteria: otherData.multiFieldCriteria,
        categorizedEnumLabels: otherData.categorizedEnumLabels,
    });
    return parsedValue;
}
