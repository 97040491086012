import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';

// Cud = Create Update Delete
// eslint-disable-next-line max-params
export async function performCommonCudAction(
    scope: any, stateKey: string, rpName: string, performActionFunc: Function,
    mutateStart: Function, mutateFinish: Function, mutateFail: Function,
): Promise<void> {
    const state = scope.state;
    const currentStatus = state[stateKey][rpName] || OperationStatus.uninitialized;
    // Ignore when it is already updating
    if (currentStatus === OperationStatus.loading) {
        return;
    }
    mutateStart();
    try {
        const actionFeedbackData = await performActionFunc();
        const latestStatus = state[stateKey][rpName] || OperationStatus.uninitialized;
        // Handle race condition
        if (latestStatus !== OperationStatus.loading &&
            latestStatus !== OperationStatus.uninitialized) {
            return;
        }
        mutateFinish(actionFeedbackData);
    } catch (error) {
        mutateFail();
        await getPersistingErrorActions(scope).showError(error);
    }
}
