export enum AdditionalInsuredEndorsementType {
    unknown = 'UNKNOWN',
    additionalInsured = 'ADDITIONAL_INSURED',
}

export interface AdditionalInsuredEndorsementDetails {
    additionalInsuredType: string;
    additionalInsuredName: string;
}

export interface AdditionalInsuredEndorsement {
    endorsementType: AdditionalInsuredEndorsementType;
    formNumber: string;
    policyNumber: string;
    details: AdditionalInsuredEndorsementDetails | null;
}
