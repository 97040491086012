<template>
    <div class="LoginLayout__sections" :class="{ 'LoginLayout__sections--horizontal': horizontal }">
        <slot />
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import LoginLayoutDivider from './LoginLayoutDivider.vue';

    @Component({
        components: {
            LoginLayoutDivider,
        },
    })
    export default class LoginLayoutSections extends Vue {
        @Prop({ type: Boolean, default: false })
        private horizontal!: boolean;
    }
</script>
