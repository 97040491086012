<template>
    <div class="JsonSchemaDateForm"
        :class="{'JsonSchemaDateForm--focus': focused}"
    >
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template v-slot:requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template v-slot:labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
                <div v-else-if="focused" class="JsonSchemaForm__typeLabel" :class="TypeLabelClass">
                    <FontAwesomeIcon :icon="faExclamationCircle" />
                    <span>Date Only</span>
                </div>
            </template>

            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <DatePicker
                    :id="id"
                    :disabled="disabled"
                    :show-invalid-date="showInvalidDate"
                    :value="viewValue"
                    :placeholder="placeholder"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :display-format="displayFormat"
                    :value-format="valueFormat"
                    enable-clear
                    @input="onChange"
                    @blur="touch"
                    @focus="onFocus"
                />

                <template v-if="accessed && error" v-slot:errorMessage>{{ error }}</template>
                <template v-if="$slots.icon" v-slot:icon>
                    <slot name="icon" />
                </template>
                <template v-if="deletable" v-slot:actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Prop } from 'vue-property-decorator';
    import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '../../Form';
    import AbstractJsonSchemaForm from './AbstractJsonSchemaForm';
    import DatePicker from '../../DatePicker/DatePicker.vue';

    @Component({
        components: { DatePicker, FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class JsonSchemaDateForm extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
        private faExclamationCircle = faExclamationCircle;
        private faTimes = faTimes;
        private prevValue = '';
        private viewValue = '';

        @Prop({ type: Boolean, default: true })
        private showInvalidDate!: boolean;

        @Prop({ default: null })
        private minDate!: Date | string | null;

        @Prop({ default: null })
        private maxDate!: Date | string | null;

        @Prop({ type: String, default: 'MM/DD/YYYY' })
        private placeholder!: string;

        // dates use luxon format
        @Prop({ type: String, default: 'yyyy-MM-dd' })
        private valueFormat!: string;

        @Prop({ type: String, default: 'M/d/yyyy' })
        private displayFormat!: string;

        @Watch('value', { immediate: true })
        private onValueChange(value: string | undefined | null): void {
            if (value !== this.prevValue) {
                this.viewValue = value || '';
            }
        }

        private onChange(value: string) {
            this.$emit('input', this.form.getValue(value));
        }
    }
</script>
