import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import defaults from 'lodash/defaults';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import noop from 'lodash/noop';
import { Auth0Client, Auth0ClientOptions, User } from '@auth0/auth0-spa-js';
import { Subscriber } from '@evidentid/subscriber';
import { IamClientAdapter, IamClientUser, IamPermission } from '../types';
import * as errors from '../errors';
import { RequestPermissionsModal } from '../RequestPermissionsModal';
import { Auth0AccessToken } from './IamClientAuth0Adapter/Auth0AccessToken';
import { ErrorTypes } from './ErrorTypes';

export interface IamClientAuth0AdapterOptions {
    // Real-time updates
    pollUserMs?: number; // default: 1000

    // Namespace for custom claims
    namespace: string;

    // Used permissions
    scope: string[];

    // Auth0 configuration
    clientId: string;
    audience: string;
    domain: string;
    useRefreshTokens?: boolean; // default: false
    createClient?: (options: Auth0ClientOptions) => Auth0Client;

    // Environment
    gateway?: string | null;
    getCurrentUrl?: () => string;
    changeUrl?: (url: string) => void;
    rootElement?: Element; // defaults: document.body

    // branding
    /* eslint-disable camelcase */
    wordmark_url?: string | undefined;
    primary_color?: string | undefined;
    secondary_color?: string | undefined;
    loading_animation_url?: string | undefined;
    favicon_url?: string | undefined;
}

type OptionalOf<T> = {
    [K in { [K in keyof T]: T extends Record<K, T[K]> ? never : K }[keyof T]]: Exclude<T[K], undefined>;
};

const baseScope: string[] = [ 'openid', 'profile', 'email' ];

const defaultOptions: OptionalOf<IamClientAuth0AdapterOptions> = {
    pollUserMs: 1000,
    useRefreshTokens: false,
    rootElement: document.body,
    gateway: null,
    createClient: (options: Auth0ClientOptions) => new Auth0Client(options),
    getCurrentUrl: () => location.href,
    changeUrl: (url: string) => history.replaceState(null, document.title, url),
    wordmark_url: '',
    primary_color: '',
    secondary_color: '',
    loading_animation_url: '',
    favicon_url: '',
};

function formatUserData(user: User | null | undefined): IamClientUser | null {
    if (!user) {
        return null;
    }
    return {
        email: user.email!,
        displayName: user.name || user.nickname || user.email || 'Unknown User',
        photoURL: user.picture || null,
        providerId: 'auth0.com',
        verified: Boolean(user.email_verified),
    };
}

const errorMessageFactories: Record<string, ErrorConstructor> = {
    'user is blocked': errors.UserDisabledError,
    // Log through SSO to the e-mail that doesn't have an account,
    // or the user has not been provisioned correctly
    'user not found': errors.UserNotFoundError,
    // User have different method used than expected (i.e. should use credentials but uses SSO)
    'user provisioning error': errors.UserProvisioningError,
};

const errorCodeFactories: Record<string, ErrorConstructor> = {
    password_leaked: errors.PasswordLeakedError,
    invalid_user_password: errors.InvalidCredentialsError,
    too_many_attempts: errors.RateLimitError,
    too_many_logins: errors.RateLimitError,
};

function getErrorClass(error: any): ErrorConstructor {
    const message = error?.message || error?.error_description;
    return (
        errorMessageFactories[message as any] ||
        errorCodeFactories[(error as any)?.error] ||
        errors.UnknownAuthError
    );
}

function translateError(error: any): Error {
    return error?.$auth ? error : new (getErrorClass(error))(error?.message);
}

// eslint-disable-next-line space-before-function-paren
function handleErrors<T extends (...args: any[]) => any>(fn: T): (...args: Parameters<T>) => (
    Promise<ReturnType<T> extends Promise<infer P> ? P : ReturnType<T>>
    ) {
    return (...args: Parameters<T>) => (
        Promise.resolve(fn(...args)).catch((error) => Promise.reject(translateError(error)))
    );
}

function pickAll<T extends {}>(value: T, keys: string[]): Partial<T> | null {
    const result = pick(value, keys);
    return Object.keys(result).length === keys.length ? result : null;
}

// TODO(PRODUCT-13933): Support multiple audiences
export class IamClientAuth0Adapter implements IamClientAdapter {
    private readonly subscriber: Subscriber<IamClientUser | null> = new Subscriber();
    private readonly options: Required<IamClientAuth0AdapterOptions>;
    private readonly auth: Auth0Client;
    private readonly modal: RequestPermissionsModal = new RequestPermissionsModal();
    private currentScope: string[] = [ ...baseScope ];
    private userPollingTimeout: any = null;
    private initialized = false;
    private initializationResult: any = null;

    private getGatewayUrl(url: string): string {
        return this.options.gateway
            ? `${this.options.gateway}?next=${encodeURIComponent(url)}`
            : url;
    }

    private get scope(): string {
        return this.currentScope.join(' ');
    }

    private resetScope(): void {
        this.currentScope = uniq([ ...baseScope, ...this.options.scope ]);
    }

    private appendScope(scope: string[]): void {
        this.currentScope = uniq([ ...this.currentScope, ...scope ]);
    }

    private async pollUser(omitSessionCheck?: boolean): Promise<void> {
        await this.checkLatestUser(omitSessionCheck);
        if (this.initialized) {
            this.userPollingTimeout = setTimeout(() => this.pollUser(), this.options.pollUserMs);
        }
    }

    private lastUser: IamClientUser | null = null;

    private updateLatestUser(user: IamClientUser | null): void {
        if (isEqual(this.lastUser, user)) {
            return;
        }
        this.lastUser = user;
        this.subscriber.emit(user == null ? null : { ...user });
    }

    private async checkLatestUser(omitSessionCheck?: boolean): Promise<void> {
        const user = await this.getUser(omitSessionCheck);
        this.updateLatestUser(user);
    }

    public readonly loginMethods = {
        external: handleErrors(async () => {
            this.resetScope();
            await this.auth.loginWithRedirect({
                redirect_uri: this.getGatewayUrl(this.options.getCurrentUrl()),
            });
            await this.checkLatestUser();
        }),
    };

    public readonly registerMethods = {};

    public constructor(options: IamClientAuth0AdapterOptions) {
        const namespace = options.namespace.replace(/\/+$/, '');
        this.options = defaults({ ...options, namespace }, defaultOptions);
        this.resetScope();
        this.auth = this.options.createClient({
            useCookiesForTransactions: true,
            cacheLocation: 'localstorage',
            response_type: 'token id-token',
            scope: this.scope,
            domain: options.domain,
            client_id: options.clientId,
            audience: options.audience,
            useRefreshTokens: options.useRefreshTokens,
            primary_color: options.primary_color,
            secondary_color: options.secondary_color,
            wordmark_url: options.wordmark_url,
            loading_animation_url: options.loading_animation_url,
            favicon_url: options.favicon_url,
        });
    }

    public subscribe(listener: (user: (IamClientUser | null)) => void): () => void {
        listener(this.lastUser);
        return this.subscriber.listen(listener);
    }

    private async getAccessTokenInstance(): Promise<Auth0AccessToken> {
        return new Auth0AccessToken(await this.getAccessToken(), this.options.namespace);
    }

    private async obtainPermissionsSilently(permissions: IamPermission[]): Promise<void> {
        const token = await this.getAccessTokenInstance();
        const scope = uniq([
            ...token.getObtainedPermissions(),
            ...token.getMissingPermissions(permissions),
        ]);
        const nextToken = new Auth0AccessToken(
            await this.auth.getTokenSilently({ scope: scope.join(' ') }).catch(() => null)
        );
        this.appendScope(nextToken.getObtainedPermissions());
    }

    private async obtainPermissionsWithLogin(permissions: IamPermission[]): Promise<void> {
        const token = await this.getAccessTokenInstance();
        const scope = token.getMissingPermissions(permissions).join(' ');
        await this.auth.loginWithRedirect({ scope, redirect_uri: this.options.getCurrentUrl() }).catch(noop);
        await this.obtainPermissionsSilently(permissions);
    }

    private lastPermissionsHandler: Promise<void> | null = null;

    private async askForPermissions(missingPermissions: IamPermission[]): Promise<void> {
        const reqPromise = this.modal.request(...missingPermissions);
        if (!this.lastPermissionsHandler) {
            this.lastPermissionsHandler = reqPromise
                .then((permissions) => this.obtainPermissionsWithLogin(permissions))
                .catch(noop)
                .then(() => {
                    this.lastPermissionsHandler = null;
                });
        }
        return this.lastPermissionsHandler;
    }

    public async requestPermissions(...permissions: IamPermission[]): Promise<boolean> {
        // Try to obtain missing permissions silently
        await this.obtainPermissionsSilently(permissions);

        // Detect what new permissions are needed
        const token = await this.getAccessTokenInstance();
        const status = await this.hasPermissions(...permissions);

        // Finish fast when it's clear situation
        if (status !== null) {
            return status;
        }

        // Try to obtain missing permissions with login
        await this.askForPermissions(token.getPartialPermissions(permissions));

        // Verify current status
        return Boolean(await this.hasPermissions(...permissions));
    }

    public async hasPermissions(...permissions: IamPermission[]): Promise<boolean | null> {
        const [ token, user ] = await Promise.all([
            this.getAccessTokenInstance(),
            this.getUser(),
        ]);
        return Boolean(user?.verified) && token.hasPermissions(permissions);
    }

    private removeQueryParams(names: string[]): void {
        const url = this.options.getCurrentUrl();
        const search = (url.match(/\?[^#]*/) || [ '' ])[0];
        const newSearch = stringifyQuery(omit(parseQuery(search), names));
        const finalSearch = newSearch ? `?${newSearch}` : '';
        this.options.changeUrl(url.replace(search, finalSearch));
    }

    public getInitializationResult(): any {
        return this.initializationResult;
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return;
        }

        // Initialize session
        this.initialized = true;
        this.modal.decline().mount(this.options.rootElement);
        await handleErrors(() => this.ensureSession())();

        // Get URL details
        const href = this.options.getCurrentUrl();
        const query = parseQuery((href.match(/\?[^#]*/) || [ '' ])[0]);
        const loginResult = pickAll(query, [ 'code', 'state' ]);
        const errorResult = pickAll(query, [ 'error', 'error_description', 'state' ]);
        const successResult = pickAll(query, [ 'success', 'message' ]);
        const error = errorResult && translateError(errorResult);

        // Save information about the initialization status
        this.initializationResult = loginResult || error || successResult;

        // Clean up URL from these temporary data
        this.removeQueryParams([ 'code', 'state', 'error', 'error_description', 'state', 'success', 'message' ]);

        if (errorResult?.error_description === ErrorTypes.emailMismatch) {
            await this.logOut();
            return;
        }
        if (errorResult?.error_description === ErrorTypes.userNotActivated) {
            const lastUser: IamClientUser = this.lastUser || {
                accountNotActivated: true,
                displayName: '',
                email: '',
                verified: false,
                providerId: '',
                photoURL: null,
            };
            this.updateLatestUser(lastUser);

            window.location.href = '/#!/error/unactivated';
            return;
        }
        if (this.initializationResult) {
            await this.auth.handleRedirectCallback(href).catch(noop);
        }

        // Log exact error message
        if (errorResult) {
            console.error(errorResult);
        }

        this.pollUser(true);
    }

    public async getUser(omitSessionCheck?: boolean): Promise<IamClientUser | null> {
        if (!omitSessionCheck) {
            await this.ensureSession();
        }
        const user = await handleErrors(() => this.auth.getUser())();
        const result = formatUserData(user);
        this.updateLatestUser(result);
        return result;
    }

    private ensureSession(ignoreCache?: boolean): Promise<void> {
        return this.auth.checkSession({
            scope: this.scope,
            ignoreCache: Boolean(ignoreCache),
        });
    }

    public async getIdToken(): Promise<string | null> {
        return this.ensureSession()
            .then(() => this.auth.getIdTokenClaims({ scope: this.scope }))
            .then((claims) => (claims?.__raw || null))
            .catch(() => null);
    }

    public async getAccessToken(): Promise<string | null> {
        return this.ensureSession()
            .then(() => this.auth.getTokenSilently({ scope: this.scope }))
            .catch(() => null);
    }

    public async refreshToken(): Promise<void> {
        await this.ensureSession(true);
        await this.checkLatestUser(true);
    }

    private async _logOut(nextUrl?: string): Promise<void> {
        // Log out user silently in iframe
        const iframe = document.createElement('iframe');
        await new Promise((resolve, reject) => {
            iframe.setAttribute('style', 'display: none !important');
            iframe.src = `https://${this.options.domain}/v2/logout?client_id=${encodeURIComponent(this.options.clientId)}`;
            iframe.onload = resolve;
            iframe.onerror = reject;
            document.body.appendChild(iframe);
        });
        iframe.parentNode?.removeChild(iframe);

        // Perform local log out
        await handleErrors(() => this.auth.logout({
            returnTo: this.getGatewayUrl(nextUrl || this.options.getCurrentUrl()),
            localOnly: true,
        }))();

        // Update local data
        this.resetScope();
        await this.checkLatestUser();
    }

    private logOutInProgress: Promise<void> | null = null;

    public logOut(nextUrl?: string): Promise<void> {
        if (!this.logOutInProgress) {
            this.logOutInProgress = this._logOut(nextUrl).then(
                () => {
                    this.logOutInProgress = null;
                },
                (error) => {
                    this.logOutInProgress = null;
                    return Promise.reject(error);
                },
            );
        }
        return this.logOutInProgress;
    }

    public async destroy(): Promise<void> {
        this.initialized = false;
        clearTimeout(this.userPollingTimeout);
        this.lastPermissionsHandler = null;
        this.userPollingTimeout = null;
        this.initializationResult = null;
        this.subscriber.clear();
        this.modal.unmount();
    }
}
