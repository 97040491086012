import { parse, unparse } from 'papaparse';

export function escapeCell(content: string | number): string {
    return unparse([ [ content ] ]);
}

export function buildCsv(rows: (string | number)[][]): string {
    return unparse(rows);
}

export function readCsv(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
        parse(file, {
            skipEmptyLines: 'greedy',
            delimiter: ',',
            error: (error) => reject(error),
            complete: (result) => resolve(result.data),
        });
    });
}
