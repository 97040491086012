import type { Component } from 'vue';
import { RawLocation, Route, RouteConfig } from 'vue-router/types/router';
import noop from 'lodash/noop';
import RpWebApiClient, { RelyingPartiesQuery } from '@evidentid/rpweb-api-client';
import withPropsInjected from '@evidentid/vue-commons/decorators/withPropsInjected';
import { IamClient } from '@evidentid/iam-client';
import type Tracker from '@evidentid/tracker';
import type Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import { createModuleBuilder } from '../../app';
import { SnackbarModule } from '../snackbar';
import { PersistingErrorModule } from '../persisting-error';
import vuexUserModule from './vuex/user';
import vuexTermsModule from './vuex/terms';
import { getAuthRedirection } from './getAuthRedirection';
import AuthTermsWrapper from './components/AuthTermsWrapper.vue';
import AuthLoginWrapper from './components/AuthLoginWrapper.vue';
import { createRpController } from './createRpController';
import { UserState } from './types';
import { RelyingPartySignature } from '@evidentid/rpweb-api-client/models/relying-party/RelyingPartySignature.model';

export interface AuthModuleExpectedViews {
    Loading: Component;
    Login: Component;
    VerifyEmail: Component;
    ResetPassword: Component;
    Register: Component;
    Terms: Component;
}

export interface RpController {
    current: string | null;
    available: RelyingPartySignature[];
    change(rpId: string): Promise<any>;
}

function saveLastRpName(name: string): void {
    try {
        localStorage.setItem('$lastSelectedRp', name);
    } catch (error) {
        // localStorage is not supported, so just ignore it
    }
}

type DefaultRoute = ((to: Route) => RawLocation) | RawLocation;

export function createAuthModule() {
    return createModuleBuilder()
        .demandOptions<{
            terms: { content: string, hash: string };
            defaultAuthenticatedRoute?: DefaultRoute;
            relyingPartiesFilter: RelyingPartiesQuery;
            views: AuthModuleExpectedViews;
        }>()
        .demandContext<{
            rpweb: RpWebApiClient;
            auth: IamClient;
            authType: string;
            tracker?: Tracker<any>;
            logger?: Logger<any>;
        }>()
        .demand<SnackbarModule>()
        .demand<PersistingErrorModule>()
        .registerVuex({ user: vuexUserModule.instantiateModule })
        .registerVuex({ terms: vuexTermsModule.instantiateModule })
        .injectVue((app) => ({
            $rp: createRpController(app),
            $auth: {
                get type() {
                    return app.authType;
                },
                get availableLoginMethods() {
                    return app.auth.getAvailableLoginMethods();
                },
                get availableRegisterMethods() {
                    return app.auth.getAvailableRegisterMethods();
                },
                get canRegister() {
                    return app.auth.getAvailableRegisterMethods().length > 0;
                },
                get canResetPassword() {
                    return app.auth.canResetPassword;
                },
                get canSendVerificationEmail() {
                    return app.auth.canSendVerificationEmail;
                },
                get client() {
                    return app.auth;
                },
            },
        }))
        .execute((app) => {
            app.auth.subscribe((data) => {
                app.store.actions.user.handleUserDataUpdate(data).catch(noop);
            });
        })
        .execute((app) => {
            // Clear errors after logout
            app.store.watch(
                (state) => state.user.data,
                (userData) => {
                    const route = app.router.currentRoute;
                    if (!userData && app.store.state.error.error) {
                        app.store.actions.error.showError(null);
                    } else if (!userData && route.name === 'error' && route.params.reason === 'no-relying-parties') {
                        app.router.replace({ name: 'auth.login', query: { force: '' } }).catch(noop);
                    }
                },
            );
        })
        .execute((app) => {
            app.router.beforeEach((route, _, next) => {
                // Extract details
                const user = app.store.state.user;
                const defaultRoute = app.options.defaultAuthenticatedRoute;
                const getDefaultAuthenticatedRoute = typeof defaultRoute === 'function'
                    ? defaultRoute
                    : () => (defaultRoute || '/') as RawLocation;
                const sign = app.store.state.terms.signDetails[route.params.rpId];

                // Analyze data
                const destination = getAuthRedirection({ user, route, sign, getDefaultAuthenticatedRoute });

                // Redirect when expected
                if (destination !== null) {
                    return next(destination.to);
                }

                // Save RP ID when it is available
                if (route.params.rpId) {
                    saveLastRpName(route.params.rpId);
                }

                // Accept navigation
                return next();
            });
        })
        .execute((app) => {
            function tick() {
                // Extract details
                const route = app.router.currentRoute;
                const user = app.store.state.user;
                const defaultRoute = app.options.defaultAuthenticatedRoute;
                const getDefaultAuthenticatedRoute = typeof defaultRoute === 'function'
                    ? defaultRoute
                    : () => (defaultRoute || '/') as RawLocation;
                const sign = app.store.state.terms.signDetails[route.params.rpId];

                // Analyze data
                const destination = getAuthRedirection({ user, route, sign, getDefaultAuthenticatedRoute });

                // Redirect when expected
                if (destination !== null) {
                    if (destination.replace) {
                        app.router.replace(destination.to).catch(noop);
                    } else {
                        app.router.push(destination.to).catch(noop);
                    }
                }
            }
            app.store.watch((state) => state.user.initialized, tick);
            app.store.watch((state) => state.user.data, tick);
            app.store.watch((state) => state.user.relyingParties, tick);
            app.store.watch((state) => state.terms.signDetails, tick);
        })
        .registerRoutes((app) => [
            {
                path: '/auth',
                name: 'auth.login',
                component: withPropsInjected(AuthLoginWrapper, {
                    Component: app.options.views.Login,
                    Loading: app.options.views.Loading,
                }),
                meta: {
                    title: 'Log in',
                    guestOnly: true,
                },
            },
            {
                path: '/forgot-password',
                name: 'auth.resetPassword',
                component: app.options.views.ResetPassword,
                meta: {
                    title: 'Reset Password',
                    guestOnly: true,
                },
                beforeEnter: (to, from, next) => {
                    if (app.auth.canResetPassword) {
                        next();
                    } else {
                        next({ name: 'auth.login' });
                    }
                },
            },
            {
                path: '/register',
                name: 'auth.register',
                component: app.options.views.Register,
                meta: {
                    title: 'Register Account',
                    guestOnly: true,
                },
                beforeEnter: (to, from, next) => {
                    if (app.auth.getAvailableRegisterMethods().length > 0) {
                        next();
                    } else {
                        next({ name: 'auth.login' });
                    }
                },
            },
            {
                path: '/auth/check',
                name: 'auth.check',
                component: app.options.views.Loading,
            },
            {
                path: '/auth/verify',
                name: 'auth.verify',
                // TODO: Disallow re-sending verification e-mail for Auth0
                component: app.options.views.VerifyEmail,
                meta: {
                    title: 'Verify Account',
                },
            },
            {
                path: '/auth/terms/:rpId?',
                name: 'auth.terms',
                component: withPropsInjected(AuthTermsWrapper, {
                    Component: app.options.views.Terms,
                    Loading: app.options.views.Loading,
                    content: app.options.terms.content,
                    hash: app.options.terms.hash,
                }),
                meta: {
                    title: 'Terms and Conditions',
                },
            },
            {
                path: '/auth/terms/:rpId?/redirect',
                name: 'auth.terms.redirect',
                component: withPropsInjected(AuthTermsWrapper, {
                    Component: app.options.views.Terms,
                    Loading: app.options.views.Loading,
                    content: app.options.terms.content,
                    hash: app.options.terms.hash,
                }),
                meta: {
                    title: 'Terms and Conditions',
                },
            },
            {
                path: '/auth/error/no-relying-parties',
                name: 'auth.noRelyingParties',
                redirect: (to) => ({
                    name: 'error',
                    params: { reason: 'no-relying-parties' },
                    query: { next: to.query.next },
                }),
            },
        ] as RouteConfig[])

        // Track "log in" events
        .execute((app) => {
            function onLogInRequest(provider: string) {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Login requested',
                        description: `Login requested by ${provider} provider`,
                        provider,
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Requested] Log in',
                        data: { provider },
                    });
                }
            }

            function onLogInFinish(provider: string) {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Login finished',
                        description: `Login finished by ${provider} provider`,
                        provider,
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Finished] Log in',
                        data: { provider },
                    });
                }
            }

            function onLogInFailure(error: any) {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Login failed',
                        description: 'Login failed',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        level: Severity.warning,
                        category: 'user',
                        message: '[Failed] Log in',
                        data: { error },
                    });
                }
            }

            app.observer.onAction('logInWithSso', onLogInRequest);
            app.observer.onActionFinish('logInWithSso', onLogInFinish);
            app.observer.onAction('logIn', () => onLogInRequest('regular'));
            app.observer.onActionFinish('logIn', () => onLogInFinish('regular'));
            app.observer.onAction('showLoginError', onLogInFailure);
        })

        // Track "reset password" events
        .execute((app) => {
            function onResetPasswordRequest() {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Reset password requested',
                        description: 'Reset password requested',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Requested] Reset password',
                    });
                }
            }

            function onResetPasswordFinish() {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Reset password finished',
                        description: 'Reset password finished',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Finished] Reset password',
                    });
                }
            }

            function onResetPasswordFailure(error: any) {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Reset password failed',
                        description: 'Reset password failed',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        level: Severity.warning,
                        category: 'user',
                        message: '[Failed] Reset password',
                        data: { error },
                    });
                }
            }

            app.observer.onAction('resetPassword', onResetPasswordRequest);
            app.observer.onActionFinish('resetPassword', onResetPasswordFinish);
            app.observer.onAction('showResetError', onResetPasswordFailure);
        })

        // Track "log out" events
        .execute((app) => {
            function onLogOutRequest() {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Logout requested',
                        description: 'Logout requested',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Requested] Log out',
                    });
                }
            }

            function onLogOutFinish() {
                if (app.tracker) {
                    app.tracker.track({
                        name: 'Logout finished',
                        description: 'Logout finished',
                    });
                }

                if (app.logger) {
                    app.logger.addBreadcrumb({
                        category: 'user',
                        message: '[Finished] Log out',
                    });
                }
            }

            app.observer.onAction('logOut', onLogOutRequest);
            app.observer.onActionFinish('logOut', onLogOutFinish);
        })

        // Track change events
        .execute((app) => {
            function onUserChange(user: UserState['data']) {
                if (app.tracker) {
                    if (user?.email) {
                        app.tracker.appendUserData({
                            id: user.email,
                            name: user.displayName || user.email,
                            email: user.email,
                            authType: app.authType,
                        });
                    } else {
                        app.tracker.setUserData(null);
                    }
                }

                if (app.logger) {
                    if (user?.email) {
                        app.logger.appendUserData({
                            id: user.email,
                            name: user.displayName || user.email,
                            email: user.email,
                            authType: app.authType,
                        });
                        app.logger.addBreadcrumb({
                            category: 'user',
                            message: 'Loaded user data',
                        });
                    } else {
                        app.logger.setUser(null);
                        app.logger.addBreadcrumb({
                            category: 'user',
                            message: 'Cleared user data',
                        });
                    }
                }
            }

            function onRelyingPartiesChange(rps: RelyingPartySignature[] | null) {
                if (app.tracker) {
                    if (rps) {
                        app.tracker.appendUserData({ rpsCount: rps.length });
                        app.tracker.track({
                            name: 'List of available RPs loaded',
                            description: `List of available RPs loaded`,
                        });
                    } else {
                        app.tracker.track({
                            name: 'List of available RPs cleared',
                            description: 'List of available RPs cleared',
                        });
                    }
                }

                if (app.logger) {
                    if (rps) {
                        app.logger.addBreadcrumb({
                            category: 'user',
                            message: 'Loaded available RPs list',
                            data: { count: rps.length },
                        });
                    } else {
                        app.logger.addBreadcrumb({
                            category: 'user',
                            message: 'Cleared list of available RPs',
                        });
                    }
                }
            }

            function onSelectedRpChange(rp: string | null) {
                if (app.tracker) {
                    app.tracker.appendUserData({ currentRp: rp || '<none>' });
                }

                if (app.logger) {
                    app.logger.setTag('rp', rp || '<none>');
                    app.logger.addBreadcrumb({
                        category: 'route',
                        message: 'Changed selected RP',
                        data: { rpName: rp || '<none>' },
                    });
                }
            }

            app.observer.onChange((state) => state.user.data, onUserChange);
            app.observer.onChange((state) => state.user.relyingParties, onRelyingPartiesChange);
            app.observer.onRouteChange((route, prevRoute) => {
                const prevRpId = prevRoute?.params?.rpId;
                const rpId = route?.params?.rpId;
                if (prevRpId !== rpId) {
                    onSelectedRpChange(rpId);
                }
            });
        })

        .end();
}

export type AuthModule = ReturnType<typeof createAuthModule>;
