<template>
    <div class="SwitchToggle" :class="className">
        <label class="SwitchToggle__container">
            <input
                type="checkbox"
                :disabled="disabled"
                :checked="value"
                @input="onChange"
            >
            <span class="SwitchToggle__slider" />
        </label>
        <div v-if="!hideLabel" class="SwitchToggle__status">
            <label>{{ value ? 'Yes' : 'No' }}</label>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class SwitchToggle extends Vue {
        @Prop({ type: Boolean, default: false })
        private value!: boolean;

        @Prop({ type: Boolean, default: false })
        private disabled!: boolean;

        @Prop({ type: String, default: null })
        private name!: string | null;

        @Prop({ type: Boolean, default: false })
        private hideLabel!: boolean;

        private get className() {
            return {
                'SwitchToggle--disabled': this.disabled,
                'SwitchToggle--on': this.value,
                'SwitchToggle--off': !this.value,
            };
        }

        private onChange(event: Event) {
            this.$emit('input', (event.target as HTMLInputElement).checked);
        }
    }
</script>
