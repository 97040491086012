<template>
    <div
        class="JsonSchemaCountryEnumForm JsonSchemaEnumForm"
        :class="{
            'JsonSchemaCountryEnumForm--focused': focused,
            'JsonSchemaEnumForm--focused': focused,
        }"
    >
        <FormElement :label-for="id" :label="form.schema.title" :required="required">
            <template #requiredIndicator>
                {{ translate('requiredLabel') }}
            </template>
            <template #labelHint>
                <div v-if="form.schema.warning" class="JsonSchemaForm__warning">
                    {{ form.schema.warning }}
                </div>
                <div v-else-if="form.schema.hint" class="JsonSchemaForm__hint">
                    {{ form.schema.hint }}
                </div>
            </template>

            <FormInput :invalid="accessed && hasError" force-error-slot force-action-slot>
                <Dropdown
                    :options="options"
                    :selected="currentOption"
                    :disabled="disabled"
                    :placeholder="form.schema.description || description"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                >
                    <template #prepend>
                        <CountryFlag :country="currentCountryValue" />
                    </template>
                </Dropdown>
                <template v-if="accessed && error" #errorMessage>
                    {{ error }}
                </template>

                <button
                    v-if="!required && currentOption.length > 0"
                    class="JsonSchemaCountryEnumForm__clear"
                    @click="clearValue"
                >
                    <FontAwesomeIcon :icon="faTimes" />
                    <span class="JsonSchemaCountryEnumForm__clearText">Clear</span>
                </button>

                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <FontAwesomeIcon :icon="faTimes" />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm from '../elements/AbstractJsonSchemaForm';
    import { FormElement, FormInput } from '../../Form';
    import { Dropdown } from '../../Dropdown';
    import { DropdownOption } from '../../Dropdown/types';
    import CountryFlag from 'vue-country-flag';

    @Component({
        components: { CountryFlag, Dropdown, FormInput, FormElement, FontAwesomeIcon },
        inheritAttrs: false,
    })

    export default class JsonSchemaCountryEnumForm
        extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaEnum>, string> {
        private faTimes = faTimes;

        private options: DropdownOption[] = [];

        @Watch('form.schema.enum', { immediate: true })
        private onEnumChange(): void {
            const labels = this.form.schema.enumLabels && this.form.schema.enumLabels.length > 0
                ? this.form.schema.enumLabels
                : null;
            this.options = labels
                ? this.form.schema.enum.map((value, i) => ({ value, label: labels[i] }))
                : this.form.schema.enum.map(this.buildOptionFromValue);
        }

        private get currentOption(): DropdownOption[] {
            const option = this.options.find((option) => option.value === this.value);
            return option ? [ option ] : [];
        }

        private get currentCountryValue(): string | null {
            return this.currentOption[0]?.value || '';
        }

        private buildOptionFromValue(value: any): DropdownOption {
            return { value, label: value };
        }

        private onInput(option: DropdownOption): void {
            this.$emit('input', option.value);
            this.touch();
        }

        private clearValue() {
            this.$emit('input', this.form.getValue(undefined));
        }
    }
</script>
