<template>
    <div class="Draggable" :class="{'Draggable--dragging': drag}">
        <draggable
            class="Draggable__items"
            ghost-class="DraggableItem--ghost"
            drag-class="DraggableItem--drag"
            animation="300"
            :handle="handle"
            v-bind="$attrs"
            :value="value"
            v-on="$listeners"
            @start="drag = true"
            @end="drag = false"
        >
            <slot />
        </draggable>
    </div>
</template>
<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import draggable from 'vuedraggable';

    @Component({
        components: { draggable },
    })
    export default class Draggable extends Vue {
        @Prop({ type: String, default: '' })
        private handle!: string;

        @Prop({ type: String, default: '' })
        private draggable!: string;

        @Prop({ type: Array, default: '' })
        private value!: any;

        private drag: boolean = false;
    }
</script>
