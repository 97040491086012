export enum AsaStatusType {
    idownerAccepted = 'idowner-accepted',
    idownerDeclined = 'idowner-declined',
    idownerError = 'idowner-error',
    idownerRequestedReview = 'idowner-requested_attribute_review',
    relyingPartyInitiated = 'relyingparty-initiated',
    relyingPartyTerminated = 'relyingparty-terminated',
}

export enum AsfStatusType {
    new = 'new',
    pending = 'pending',
    processing = 'in_process',
    complete = 'complete',
    subscribed = 'subscribed',
    timeout = 'timeout',
    terminated = 'terminated',
    errorIdentityResolution = 'error-IdentityResolution',
    errorInvalidAttributeException = 'error-InvalidAttributeException',
    errorSubjectConsent = 'error-SubjectConsent',
}
