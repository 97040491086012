<template>
    <div v-show="isActive" class="Tab" :class="{'Tab--active': isActive}">
        <slot/>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class Tab extends Vue {
        @Prop({ type: String, default: '' })
        public name!: string;

        @Prop({ type: String, default: '' })
        public id!: string;

        public isActive: boolean = false;
    }
</script>
