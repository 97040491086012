import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBoolean,
    JsonSchemaConst,
    JsonSchemaEnum,
    JsonSchemaInteger,
    JsonSchemaNumber,
    JsonSchemaObject,
    JsonSchemaString,
} from './JsonSchema';

/* eslint-disable no-use-before-define */

export enum JsonFormType {
    array = 'array',
    object = 'object',
    string = 'string',
    boolean = 'boolean',
    number = 'number',
    enum = 'enum',
    const = 'const',
}

export type JsonFormModifierFunction<T> = (value?: any, ignoreOptionalEmpty?: boolean) => T;
export type JsonFormValidator = JsonFormModifierFunction<boolean>;
export type JsonFormValueBuilder = JsonFormModifierFunction<any>;
export type JsonFormEmptinessCheck = (value?: any, ignoreOptionalEmpty?: boolean) => boolean;

export type JsonFormTypeBySchema<S extends JsonSchema>
    = S extends JsonSchemaEnum ? JsonFormType.enum :
      S extends JsonSchemaConst ? JsonFormType.const :
      S extends JsonSchemaObject ? JsonFormType.object :
      S extends JsonSchemaArray ? JsonFormType.array :
      S extends JsonSchemaString ? JsonFormType.string :
      S extends JsonSchemaBoolean ? JsonFormType.boolean :
      S extends JsonSchemaNumber ? JsonFormType.number :
      S extends JsonSchemaInteger ? JsonFormType.number :
      never;

export const IS_VALID_FNC_PROP_KEY_NAME = 'isValidFnc';

export interface JsonFormBase<S extends JsonSchema> {
    key: string;
    schema: S;
    type: JsonFormTypeBySchema<S>;
    isValid: JsonFormValidator;
    /**
     * This function is added from the FormController. It is called inside isValid.
     */
    [IS_VALID_FNC_PROP_KEY_NAME]?: JsonFormValidator;
    isEmpty: JsonFormEmptinessCheck;
    getValue: JsonFormValueBuilder;
}

export interface PartialJsonFormBase<S extends JsonSchema> extends Partial<JsonFormBase<S>> {
    type: JsonFormBase<S>['type'];
    getValue: JsonFormBase<S>['getValue'];
    isEmpty: JsonFormEmptinessCheck;
}

export interface JsonFormProperty {
    name: string;
    required: boolean;
    form: JsonForm;
}

export interface JsonFormObjectExclusive {
    getProperties: JsonFormModifierFunction<JsonFormProperty[]>;
}

export type JsonFormObject = JsonFormBase<JsonSchemaObject> & JsonFormObjectExclusive;
export type PartialJsonFormObject = PartialJsonFormBase<JsonSchemaObject> & JsonFormObjectExclusive;

export interface JsonFormArrayExclusive {
    item: JsonForm;
}

export type JsonFormArray = JsonFormBase<JsonSchemaArray> & JsonFormArrayExclusive;
export type PartialJsonFormArray = PartialJsonFormBase<JsonSchemaArray> & JsonFormArrayExclusive;

export type JsonForm =
    JsonFormObject | JsonFormArray | JsonFormBase<JsonSchemaEnum> |
    JsonFormBase<JsonSchemaConst> | JsonFormBase<JsonSchemaString> |
    JsonFormBase<JsonSchemaBoolean> | JsonFormBase<JsonSchemaNumber> | JsonFormBase<JsonSchemaInteger>;

export type PartialJsonForm =
    PartialJsonFormObject | PartialJsonFormArray | PartialJsonFormBase<JsonSchemaEnum> |
    PartialJsonFormBase<JsonSchemaConst> | PartialJsonFormBase<JsonSchemaString> |
    PartialJsonFormBase<JsonSchemaBoolean> | PartialJsonFormBase<JsonSchemaNumber> |
    PartialJsonFormBase<JsonSchemaInteger>;

export type JsonFormBySchema<S extends JsonSchema>
    = S extends JsonSchemaObject ? JsonFormObject :
      S extends JsonSchemaArray ? JsonFormArray :
      JsonFormBase<S>;

export type PartialJsonFormBySchema<S extends JsonSchema>
    = S extends JsonSchemaObject ? PartialJsonFormObject :
      S extends JsonSchemaArray ? PartialJsonFormArray :
      PartialJsonFormBase<S>;

export default JsonForm;
