import { RulesLogic } from 'json-logic-js';
import { TprmRequirementType } from '../entity-details';

export enum RiskProfileType {
    default = 'DEFAULT',
    collateral = 'COLLATERAL',
}

export interface RiskProfileInput {
    displayName: string;
    active: boolean;
    ccgType: RiskProfileType;
    numberOfCoverageTypes: number | null;
    numberOfInsuredsAssigned: number | null;
    assignmentRule: RulesLogic | null;
    countryCode: string | null;
    evaluationRule?: {
        coverageTypes: TprmRequirementType[];
    };
}

export interface RiskProfile extends RiskProfileInput {
    id: string;
    countryCode: string;
}
