<template>
    <Table class="UserManagementUsersTable">
        <template slot="head">
            <HeadCell class="UserManagementUsersTable__avatarHead" />
            <HeadCell>User</HeadCell>
            <HeadCell>Role</HeadCell>
            <HeadCell>Status</HeadCell>
            <HeadCell class="UserManagementUsersTable__actionsHead">Actions</HeadCell>
        </template>

        <UsersTableRow
            v-for="user in users"
            :key="user.id"
            :user="user"
            :user-label="user.email === currentUserEmail ? 'you' : null"
            :service="service"
            :role-labels="roleLabels"
            @edit="$emit('edit', user)"
            @delete="$emit('delete', user)"
            @resendInvite="$emit('resendInvite', user)"
        />

        <template slot="status" v-if="loadingMore">
            <LineLoader />
            <span>Loading users...</span>
        </template>
        <template slot="status" v-else-if="!canLoadMore">
            No more users found.
        </template>
    </Table>
</template>

<style lang="scss">
    .UserManagementUsersTable {
        & &__avatarHead {
            min-width: 0;
            max-width: 0;
            width: 0;
        }

        & &__actionsHead {
            max-width: 30px;
            text-align: center;
        }

        & &__actionsHead .TableHeader__inner {
            justify-content: center;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import type { User, ServiceName } from '@evidentid/user-management-api-client/types';
    import { Table, Cell, Row, HeadCell } from '../../../components/Table';
    import { Avatar } from '../../../components/Avatar';
    import { Badge } from '../../../components/Badge';
    import { LineLoader } from '../../../components/LineLoader';
    import UsersTableRow from './UsersTableRow.vue';

    @Component({
        components: { UsersTableRow, LineLoader, Badge, Avatar, Table, Cell, Row, HeadCell },
    })
    export default class UsersTable extends Vue {
        @Prop({ type: Array, default: [] })
        private users!: User[];

        @Prop({ type: String, default: null })
        private service!: ServiceName | null;

        @Prop({ type: Object, default: () => ({}) })
        private roleLabels!: Record<string, string>;

        @Prop({ type: Boolean, default: true })
        private canLoadMore!: boolean;

        @Prop({ type: Boolean, default: false })
        private loadingMore!: boolean;

        @Prop({ type: String, default: null })
        private currentUserEmail!: string | null;
    }
</script>
