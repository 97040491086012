import { isComplexObject } from '@evidentid/json-schema/schemaChecks';
import JsonSchema, { JsonSchemaComplexObject, JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
import { isSubstitutableSchema } from '@/modules/decisioning-criteria/utils/isSubstitutableSchema';
import { TprmSchemaDisplayFormat } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
import { TprmSchema } from '@evidentid/tprm-portal-lib/models/common/TprmSchema.model';

export function isSchemaCustomPropertySubstitutable(schema: JsonSchema): boolean {
    if (!isComplexObject(schema)) {
        return false;
    }

    const isBasedOnInsuredField = isSubstitutableSchema(schema);

    if (!isBasedOnInsuredField) {
        return false;
    }

    const thenSchema = Object.values(
        (schema as unknown as JsonSchemaComplexObject).then?.properties || {},
    )[0];
    const elseSchema = Object.values(
        (schema as unknown as JsonSchemaComplexObject).else?.properties || {},
    )[0];
    const thenDisplayFormat = (thenSchema as TprmSchema)?.displayFormat;
    const elseDisplayFormat = (elseSchema as TprmSchema)?.displayFormat;
    const thenIsCustomPropertyAndEnum = thenDisplayFormat === TprmSchemaDisplayFormat.insuredField
        && (thenSchema as JsonSchemaEnum).enum && (thenSchema as JsonSchemaEnum).enum.length > 0;
    const elseIsCustomPropertyAndEnum = elseDisplayFormat === TprmSchemaDisplayFormat.insuredField
        && (elseSchema as JsonSchemaEnum).enum && (elseSchema as JsonSchemaEnum).enum.length > 0;

    return thenIsCustomPropertyAndEnum || elseIsCustomPropertyAndEnum;
}
