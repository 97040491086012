import get from 'lodash/get';
import { JsonSchemaArray, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import createForm from '@evidentid/json-schema/createForm';
import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';
import { CustomProperty, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';

function formatPhoneNumber(phoneNumber: string): string {
    const number = phoneNumber.replace(/[^\d]/g, '');
    if (number.length === 10) {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (number.length === 11) {
        return number.substr(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return number;
}

function formatCustomProperty(customProperty: CustomProperty, value?: any): string {
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    } else if (Array.isArray(value)) {
        const schema = customProperty.schema as JsonSchemaArray<any>;
        if (schema.items.type !== JsonSchemaType.object) {
            return value.join('|');
        }
        const formValue = createForm(removeNonStandardSchemaFormat(schema)).getValue(value, true);
        return formValue.map((x: object) => JSON.stringify(x)).join('|');
    } else {
        return value?.toString() || '';
    }
}

function entityKeyToValue(
    key: string,
    entity: EntityInput,
    customProperties: CustomProperty[],
): string {
    if (key === 'contactPhoneNumber') {
        return formatPhoneNumber(entity.contactPhoneNumber || '');
    } else if (entity[key as keyof EntityInput] !== undefined) {
        const val = entity[key as keyof EntityInput];
        // currently standard fields only expect array or string. will need to adjust when supporting other types
        return Array.isArray(val)
            ? val.join('|')
            : entity[key as keyof EntityInput] as string;
    } else if (entity.insuredFields && get(entity.insuredFields, key) !== undefined) {
        const customProperty = customProperties
            .find((customProperty) => customProperty.key === key) as CustomProperty;
        return formatCustomProperty(
            customProperty,
            // get because in case of objectProp column key in a format of objectName.propName
            get(entity.insuredFields, key),
        );
    } else if (entity.exceptions && entity.exceptions[key] !== undefined) {
        return entity.exceptions[key];
    } else {
        return '';
    }
}

// eslint-disable-next-line max-params
export function buildEntitiesCsv(
    csvHeaders: string[],
    csvColumnKeys: string[],
    entities: EntityInput[],
    customProperties: CustomProperty[],
): string[][] {
    return [
        [ ...csvHeaders.map((label) => label || '') ],
        ...entities.map((entity) =>
            csvColumnKeys.map((key) => entityKeyToValue(key, entity, customProperties)),
        ),
    ];
}
