import throttle from 'lodash/throttle';

interface IdleTimeTrackerStorage {
    setItem(key: string, value: string): void;
    getItem(key: string): string | null;
}

interface IdleTimeTrackerListener {
    target: Node;
    event: string;
    listener: EventListener;
}

interface IdleTimeTrackerOptions {
    id?: string;
    storage?: IdleTimeTrackerStorage;
    now?: () => number;
}

export default class IdleTimeTracker {
    private lastInternalActionTimestamp = 0;
    private listeners: IdleTimeTrackerListener[] = [];
    private started = false;
    private readonly id: string | null;
    private readonly now: () => number;
    private readonly storage: IdleTimeTrackerStorage;

    // eslint-disable-next-line no-useless-constructor, @typescript-eslint/unbound-method
    public constructor(options: IdleTimeTrackerOptions = {}) {
        this.id = options.id == null ? null : options.id;
        this.storage = options.storage || localStorage;
        this.now = options.now || Date.now;
    }

    /**
     * Get last action timestamp from the associated shared storage.
     *
     * @private
     */
    private get lastStorageActionTimestamp(): number {
        // Handle situation when the timestamp is not stored
        if (this.id === null) {
            return 0;
        }

        // Get timestamp from storage and ignore errors (like access permissions)
        try {
            return parseInt(this.storage.getItem(this.id) || '0', 10) || 0;
        } catch (error) {
            return 0;
        }
    }

    /**
     * Get timestamp of last action,
     * taking in account the one from shared storage.
     *
     * @private
     */
    private get lastActionTimestamp(): number {
        return Math.max(0, this.lastInternalActionTimestamp, this.lastStorageActionTimestamp);
    }

    /**
     * Update timestamp shared in the storage.
     *
     * @param {number} timestamp
     * @private
     */
    private updateStorageActionTimestamp(timestamp: number): void {
        // Ignore situation when there is no shared storage used
        if (!this.id) {
            return;
        }

        // Try to save to storage, but ignore errors
        try {
            this.storage.setItem(this.id, `${timestamp}`);
        } catch (e) {
            // Ignore errors
        }
    }

    /**
     * Get number of idle seconds.
     */
    public get(): number {
        return this.started
            ? Math.max(0, this.now() - this.lastActionTimestamp)
            : 0;
    }

    /**
     * Start counting idle time.
     *
     * @returns {IdleTimeTracker} for chaining
     */
    public start(): this {
        // Do not start again when it is already started
        if (this.started) {
            return this;
        }

        // Mark as started
        this.started = true;

        // Register "start" timestamp
        this.lastInternalActionTimestamp = this.now();

        // Register all "idle" detectors
        this.registerListener(document.body, 'mousemove');
        this.registerListener(document.body, 'mousedown');
        this.registerListener(document.body, 'click');
        this.registerListener(document.body, 'touchstart');
        this.registerListener(document.body, 'keypress');
        this.registerListener(document, 'scroll');

        return this;
    }

    /**
     * Stop counting idle time.
     *
     * @returns {IdleTimeTracker} for chaining
     */
    public stop(): this {
        // Mark as not running
        this.started = false;

        // Remove all listeners
        for (const { target, event, listener } of this.listeners) {
            target.removeEventListener(event, listener);
        }
        this.listeners = [];

        return this;
    }

    /**
     * Register information about new action performed by user.
     */
    public registerAction(): void {
        this.lastInternalActionTimestamp = this.now();
        this.updateStorageActionTimestamp(this.lastInternalActionTimestamp);
    }

    /**
     * Register new listener, which will trigger an action.
     *
     * @private
     */
    private registerListener(target: Node, event: string): void {
        const listener = throttle(() => this.registerAction(), 50);
        this.listeners.push({ target, event, listener });
        target.addEventListener(event, listener, true);
    }
}
