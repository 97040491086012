import { NavigationGuard } from 'vue-router';

interface DocumentWithTitle {
    title: string;
}

export default function createMetaNavigationGuard(name: string, document: DocumentWithTitle): NavigationGuard {
    return (to, from, next) => {
        const subtitle = to && to.meta && to.meta.title;
        document.title = subtitle
            ? `${name} - ${subtitle}`
            : name;
        next();
    };
}
