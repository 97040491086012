<template>
    <div class="EntityDetailsStatusBar">
        <div v-if="entity.active" class="EntityDetailsStatusBar__statusContainer">
            <ComplianceStatusBadge
                class="EntityDetailsStatusBar__headerStatusBadge"
                :status="entity.complianceStatus"
            />
            <VerificationStatusBadge
                v-if="entity.paused"
                class="EntityDetailsStatusBar__headerVerificationStatusBadge"
            >
                Paused
            </VerificationStatusBadge>
            <VerificationStatusBadge
                v-else
                class="EntityDetailsStatusBar__headerVerificationStatusBadge"
                :status="entity.verificationStatus"
            />
        </div>
        <div v-else>
            <Badge class="EntityDetailsStatusBar__headerDeactivatedBadge">
                Deactivated
            </Badge>
        </div>
        <ExceptionBadge
            v-if="entityException"
            class="EntityDetailsStatusBar__exceptionBadge"
            label="Exception Granted"
            :exception-type="entityException.type"
            :until="entityException.until"
            applied-to="Entity"
        />
        <ActionsButton
            :loading="updatingEntity"
            :entity="entity"
            :exception-override="entityException"
            :is-loading-submission-link="isLoadingSubmissionLink"
            :is-collateral-enabled="isCollateralEnabled"
            :submission-link="getSubmissionLink(entity.id)"
            @actionsButtonClicked="$emit('loadEntityRequests', entity.id)"
            @grantExceptionToEntity="grantException"
            @removeExceptions="removeExceptions"
            @setEntitiesPauseState="$emit('setEntitiesPauseState', $event)"
            @deactivateEntities="$emit('deactivateEntities', $event)"
            @sendDocumentRequest="$emit('sendDocumentRequest', $event)"
            @copySubmissionLink="$emit('copySubmissionLink', $event)"
            @open-collateral-mapping-modal="$emit('open-collateral-mapping-modal')"
            @open-historic-document-modal="$emit('open-historic-document-modal')"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { ComplianceStatusOverride } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import VerificationStatusBadge
        from '@/modules/dashboard/components/VerificationStatusBadge/VerificationStatusBadge.vue';
    import ExceptionBadge from '@/modules/entity-details/components/ExceptionBadge/ExceptionBadge.vue';
    import { createEntityLevelException } from '@/modules/entity-details/utils/createException';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: { ActionsButton, Badge, ComplianceStatusBadge, ExceptionBadge, VerificationStatusBadge },
    })
    export default class EntityDetailsStatusBar extends Vue {
        @Prop({ type: Object })
        private entity!: Entity;

        @Prop({ type: Boolean, default: false })
        private updatingEntity!: boolean;

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private submissionLinkPerEntityId!: Record<string, string>;

        @Prop({ type: Object, default: null })
        private entityException!: ComplianceStatusOverride;

        @Prop({ type: Boolean, default: false })
        private isCollateralEnabled!: boolean;

        private getSubmissionLink(entItyId: string): string {
            return this.submissionLinkPerEntityId[entItyId] || '';
        }

        private grantException() {
            this.$emit('grantExceptions', [ createEntityLevelException() ]);
        }

        private removeExceptions(exception: string[]) {
            this.$emit('removeExceptions', exception);
        }
    }
</script>
