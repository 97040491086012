<template>
    <div
        class="DropdownSelectOption"
        :class="{'DropdownSelectOption--disabled': disabled}"
        @click="onClick"
    >
        <slot />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';

    export default Vue.extend({
        name: 'DropdownSelectOption',
        props: {
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        methods: {
            onClick(): void {
                this.$emit('click');
            },
        },
    });
</script>
