<template>
    <Row class="DocumentSubmissionHistoryTableRow" hoverable>
        <Cell
            data-test-id="DocumentSubmissionHistoryTableRow__requestDate"
        >
            {{ requestDate }}
        </Cell>
        <Cell data-test-id="DocumentSubmissionHistoryTableRow__submissionDateCell">
            <div class="flex justify-start item-center">
                <EidIcon
                    v-if="submission.isNoResponse || !submission.submissionDate || declineReason"
                    v-tooltip="tooltipMessage"
                    class="text-eidDangerRed mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__submissionErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <span data-test-id="DocumentSubmissionHistoryTableRow__submissionDateText">
                    {{ declineReason || submissionDate }}
                </span>
            </div>
        </Cell>
        <Cell data-test-id="DocumentSubmissionHistoryTableRow__requirementTypeCell">
            <div class="flex justify-start item-center">
                <EidIcon
                    v-if="submission.isCurrentSubmission"
                    v-tooltip="'Current document used for compliance.'"
                    class="text-eidTrustGreen mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__currentIcon"
                    :icon-src="faStar"
                />
                <EidIcon
                    v-else-if="submission.isNotProvided"
                    v-tooltip="'Requirement was not provided in submission.'"
                    class="text-eidDangerRed mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__coverageTypeErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <span data-test-id="DocumentSubmissionHistoryTableRow__coverageTypeText">
                    {{ submission.coverageTypeLabel }}
                </span>
            </div>
        </Cell>
        <Cell
            v-tooltip="viewDocumentTooltipMsgOrBlank"
            data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentCell"
            :class="{'cursor-not-allowed opacity-30': shouldDisableDocument}"
        >
            <div
                :class="{'cursor-pointer hover:opacity-70': shouldShowDocument, 'pointer-events-none': shouldDisableDocument}"
                data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentButton"
                class="text-sm inline-flex"
                @click="showDocument"
            >
                <EidIcon
                    v-if="submission.isNoResponse"
                    v-tooltip="'Insured did not respond to this request.'"
                    class="text-eidDangerRed"
                    data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <EidIcon
                    v-else
                    class="text-base"
                    data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentIcon"
                    :icon-src="viewDocumentIcon"
                />
                <span
                    class="inline-block ml-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentButtonText"
                >
                    {{ openDocumentLabel }}
                </span>
            </div>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import moment from 'moment';
    import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
    import { faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { HistoricDocumentSubmission } from '@/models/HistoricDocumentSubmission.model';
    import { DECLINE_REASON_TEXTS, DECLINE_REASON_TOOLTIP_TEXTS } from './config';

    export default Vue.extend({
        name: 'DocumentSubmissionHistoryTableRow',
        components: {
            Cell,
            EidIcon,
            Row,
        },
        props: {
            submission: {
                type: Object as PropType<HistoricDocumentSubmission>,
                required: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
                faStar,
                viewDocumentIcon: faFileAlt,
            };
        },
        computed: {
            shouldShowDocument(): boolean {
                return !this.submission.isNoResponse;
            },
            requestDate(): string {
                return this.submission.requestDate?.split('T')[0] || '-';
            },
            submissionDate(): string {
                const date = this.submission.submissionDate?.split('T')[0] || '-';
                return !this.submission.isNoResponse && date || '-';
            },
            openDocumentLabel(): string {
                return this.submission.isNoResponse ? '-' : 'Open Document';
            },
            shouldDisableDocument(): boolean {
                if (!this.submission.submissionDate || this.submission.isNoResponse) {
                    return false;
                }
                const submissionMoment = moment(new Date(this.submission.submissionDate));
                const supportingDateMoment = moment(new Date('2022-06-01'));
                return submissionMoment.isBefore(supportingDateMoment);
            },
            viewDocumentTooltipMsgOrBlank(): string | null {
                return this.shouldDisableDocument ? 'Document not viewable. Contact support@evidentid.com to access.' : null;
            },
            declineReason(): string | null {
                if (!this.submission.declineReason) {
                    return null;
                }
                return DECLINE_REASON_TEXTS[this.submission.declineReason];
            },
            tooltipMessage(): string {
                const noSubmissionMsg = 'Insured did not respond to this request.';
                if (!this.submission.declineReason) {
                    return noSubmissionMsg;
                }
                return this.declineReason
                    ? DECLINE_REASON_TOOLTIP_TEXTS[this.submission.declineReason]
                    : noSubmissionMsg;
            },
        },
        methods: {
            showDocument(): void {
                if (this.shouldShowDocument) {
                    this.$emit('show-document', this.submission);
                }
            },
        },
    });
</script>
