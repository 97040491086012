import mapValues from 'lodash/mapValues';

export function trimFieldValue(value: any): any {
    if (Array.isArray(value)) {
        return value.map(trimFieldValue);
    } else if (typeof value === 'object' && value !== null) {
        return mapValues(value, trimFieldValue);
    } else if (typeof value === 'string') {
        return value.trim();
    } else {
        return value;
    }
}
