<template>
    <Badge
        v-if="label"
        class="ComplianceStatusBadge"
        :class="`ComplianceStatusBadge--${status}`"
        :type="type"
    >
        {{ label }}
    </Badge>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import { BadgeType } from '@evidentid/dashboard-commons/components/Badge/types';
    import { ComplianceStatus } from '@evidentid/tprm-portal-lib/models/entity-details';

    const labels: Record<ComplianceStatus, string> = {
        [ComplianceStatus.new]: 'New',
        [ComplianceStatus.pending]: 'Pending',
        [ComplianceStatus.compliant]: 'Compliant',
        [ComplianceStatus.nonCompliant]: 'Non-compliant',
    };

    const types: Record<ComplianceStatus, BadgeType> = {
        [ComplianceStatus.new]: BadgeType.gray,
        [ComplianceStatus.pending]: BadgeType.gray,
        [ComplianceStatus.compliant]: BadgeType.success,
        [ComplianceStatus.nonCompliant]: BadgeType.danger,
    };

    @Component({
        components: { Badge },
    })
    export default class ComplianceStatusBadge extends Vue {
        @Prop({ type: String, default: ComplianceStatus.new })
        private status!: ComplianceStatus;

        private get label(): string {
            return labels[this.status];
        }

        private get type(): BadgeType {
            return types[this.status];
        }
    }
</script>
