<template>
    <div class="EntitiesEmptyState">
        <div class="EntitiesEmptyState__iconColumn">
            <img :src="addEntityImg" alt="Add entity">
        </div>
        <div class="EntitiesEmptyState__infoColumn">
            <div>
                <h1 class="EntitiesEmptyState__infoHeader">
                    You Haven’t Added Any Entities Yet
                </h1>
                <div class="EntitiesEmptyState__infoBody">
                    <p>
                        <span>You haven't connected to any entities. You can connect to entities individually or you can choose to bulk import your full list by downloading the</span>
                        <Button
                            class="EntitiesEmptyState__csvButton"
                            :loading="loading"
                            :disabled="loading"
                            @click="downloadSampleCsv"
                        >
                            <FontAwesomeIcon :icon="faFileExcel" />
                            CSV worksheet.
                        </Button>
                        <span>Once you’re ready, you can come back and click,</span>

                        <strong>
                            <FontAwesomeIcon :icon="faUpload" />
                            Bulk import
                        </strong>
                        <span> below to upload your entities.</span>
                    </p>
                </div>
                <div class="EntitiesEmptyState__actionRow">
                    <Button
                        type="primary"
                        class="EntitiesEmptyState__cta EntitiesEmptyState__cta--individual"
                        :icon="faPlusSquare"
                        @click="$emit('openAddIndividualEntityModal')"
                    >
                        Add Entity
                    </Button>
                    <Button
                        type="primary"
                        class="EntitiesEmptyState__cta EntitiesEmptyState__cta--bulk"
                        :icon="faUpload"
                        @click="$emit('openBulkImportEntitiesModal')"
                    >
                        Bulk Import
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import addEntityImg from '@/modules/dashboard/assets/add-entity-icon.svg';
    import { faPlusSquare, faUpload } from '@fortawesome/free-solid-svg-icons';
    import { faFileExcel } from '@fortawesome/free-regular-svg-icons';

    @Component({
        components: { Button, FontAwesomeIcon },
    })
    export default class EntitiesEmptyState extends Vue {
        private addEntityImg = addEntityImg;
        private faPlusSquare = faPlusSquare;
        private faFileExcel = faFileExcel;
        private faUpload = faUpload;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        private downloadSampleCsv() {
            this.$emit('downloadSampleCsv');
        }
    }
</script>
