<template>
    <ConfirmationModal :additional-class-name="className"
        v-bind="$props"
        v-on="$listeners"
    >
        <template v-slot:header>
            <slot name="header">
                <div v-if="headerIcon" class="DashboardConfirmationModal__headerIcon">
                    <FontAwesomeIcon :icon="headerIcon" />
                </div>
                <span class="DashboardConfirmationModal__headerText">{{ headerText }}</span>
            </slot>
        </template>
        <slot>
            <span>{{ contentText }}</span>
        </slot>
    </ConfirmationModal>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

    export default Vue.extend({
        name: 'DashboardConfirmationModal',
        components: {
            ConfirmationModal,
            FontAwesomeIcon,
        },
        props: {
            yes: {
                type: String as PropType<string>,
                default: 'Yes',
            },
            yesIcon: {
                type: Object as PropType<IconDefinition>,
                default: undefined,
            },
            no: {
                type: String as PropType<string>,
                default: 'No',
            },
            noIcon: {
                type: Object as PropType<IconDefinition>,
                default: undefined,
            },
            destructive: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            progress: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            loadingLabel: {
                type: String as PropType<string>,
                default: undefined,
            },
            size: {
                type: String as PropType<string>,
                default: undefined,
            },
            theme: {
                type: String as PropType<'primary'>,
                default: undefined,
            },
            title: {
                type: String as PropType<string>,
                default: 'Are you sure?',
            },
            additionalClassName: {
                type: String as PropType<string>,
                default: '',
            },
            headerIcon: {
                type: Object as PropType<IconDefinition>,
                default: undefined,
            },
            headerText: {
                type: String as PropType<string>,
                default: '',
            },
            contentText: {
                type: String as PropType<string>,
                default: '',
            },
        },
        computed: {
            className(): string {
                return this.additionalClassName
                    ? `${this.additionalClassName} DashboardConfirmationModal`
                    : 'DashboardConfirmationModal';
            },
        },
    });
</script>
