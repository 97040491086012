<template>
    <!--
        TODO: Pass correctly parent down; probably regular JsonSchemaForm has to use CustomForm behind.
              At the moment, it's just using the old component behind.
              Should finally be :form-element-component="SelfComponent", with "field" etc.
    -->
    <component
        :is="customFormElementComponent"
        :id="field.id"
        v-model="field.value"
        :form-element-component="computedBasedComponent"
        :custom-component-input="customComponentInput"
        :form="field.form"
        :touched="touched || field.touched || computedParent.touched"
        :depth="computedDepth"
        :required="field.required"
        :disabled="disabled || field.disabled || computedParent.disabled"
        :strings="computedStrings"
        :deletable="field.deletable"
        v-bind="$attrs"
        v-on="$listeners"
        @touch="touch"
    >
        <template #icon>
            <slot name="icon" />
        </template>
    </component>
</template>

<script lang="ts">
    import { Component, Prop, Watch } from 'vue-property-decorator';
    import { Pointer } from '@evidentid/json-schema/FormController';
    import { JsonSchemaFormStrings } from './types';
    import AbstractJsonSchemaFormElement from './AbstractJsonSchemaFormElement';
    import JsonSchemaFormElement from './JsonSchemaFormElement.vue';

    interface JsonSchemaCustomFormReference {
        touched: boolean;
        disabled: boolean;
        strings: Partial<JsonSchemaFormStrings>;
    }

    function isCustomJsonSchemaForm(element: any): element is JsonSchemaCustomFormReference {
        return Boolean(element?.$customJsonSchemaForm);
    }

    @Component({
        components: {
            JsonSchemaFormElement,
        },
        inheritAttrs: false,
    })
    export default class JsonSchemaCustomFormElement extends AbstractJsonSchemaFormElement {
        @Prop()
        private baseComponent?: Vue.Component | null;

        @Prop({ type: Object, default: null })
        private parent!: JsonSchemaCustomFormReference | null;

        @Prop(Object)
        private field!: Pointer;

        @Prop({ type: Number, default: null })
        private depth!: number | null;

        private unsubscribe: (() => void) | null = null;

        private destroyed(): void {
            this.unsubscribe?.();
            this.unsubscribe = null;
        }

        private get computedBasedComponent() {
            return this.baseComponent || JsonSchemaFormElement;
        }

        private get computedDepth(): number {
            return this.depth ?? this.field.depth;
        }

        private get computedStrings(): Partial<JsonSchemaFormStrings> {
            return {
                ...this.computedParent.strings,
                ...this.strings,
            };
        }

        @Watch('field', { immediate: true })
        private subscribeToField(): void {
            this.unsubscribe?.();
            this.unsubscribe = this.field?.onChange(() => {
                this.$forceUpdate();
            }) || null;
        }

        private get dynamicParent(): JsonSchemaCustomFormReference | null {
            let currentParent = this.$parent;
            while (currentParent && currentParent !== this.$root && !isCustomJsonSchemaForm(currentParent)) {
                currentParent = currentParent.$parent;
            }
            return isCustomJsonSchemaForm(currentParent) ? currentParent : null;
        }

        private get computedParent(): JsonSchemaCustomFormReference {
            const parent = this.parent || this.dynamicParent;
            if (!parent) {
                throw new Error('Couldn\'t find parent JsonSchemaCustomForm for element. Verify if it is built fine.');
            }
            return parent;
        }

        private get customFormElementComponent(): Vue.Component | Vue.AsyncComponent {
            return (
                this.getCustomComponent?.(this.field.form, this.components) ||
                this.components[this.field.form.type]
            );
        }

        private touch(): void {
            this.field.touch();
        }
    }
</script>
