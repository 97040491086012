<template>
    <DashboardConfirmationModal
        header-text="You have invalid changes"
        yes="Return to page"
        no="Discard changes"
        additional-class-name="InvalidChangesModal"
        v-on="$listeners"
    >
        Some changed values could not be validated against the field requirements. Any changes you've made will be discarded if you chose not to save them.
    </DashboardConfirmationModal>
</template>

<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import DashboardConfirmationModal
        from '@/modules/dashboard/components/DashboardConfirmationModal/DashboardConfirmationModal.vue';

    @Component({
        components: {
            DashboardConfirmationModal,
        },
    })
    export default class InvalidChangesModal extends Vue {
    }
</script>
