import { TprmRequirementType } from '../../../../tprm-portal-lib/models/entity-details';

export const nonInsuranceRequirementTypes: TprmRequirementType[] = [
    TprmRequirementType.w9Form,
    TprmRequirementType.holdHarmlessAgreement,
    TprmRequirementType.declarationsPage,
    TprmRequirementType.scheduleOfFormsAndEndorsements,
    TprmRequirementType.proofOfPayment,
    TprmRequirementType.workersCompensationWaiver,
];
