import { DayOfWeek } from '@evidentid/dashboard-commons/components/DayOfWeekInput/types';

const dayLabels: Record<DayOfWeek, string> = {
    [DayOfWeek.sunday]: 'Sun',
    [DayOfWeek.monday]: 'Mon',
    [DayOfWeek.tuesday]: 'Tue',
    [DayOfWeek.wednesday]: 'Wed',
    [DayOfWeek.thursday]: 'Thu',
    [DayOfWeek.friday]: 'Fri',
    [DayOfWeek.saturday]: 'Sat',
};

export function formatSupportDays(days: DayOfWeek[]): string | null {
    if (!days?.length) {
        return null;
    }
    const sortedDays = days.slice().sort();
    const groups: DayOfWeek[][] = [];
    let currentGroup: DayOfWeek[] = [];
    for (const day of sortedDays) {
        if (currentGroup.length === 0) {
            currentGroup.push(day);
        } else if (day === currentGroup[currentGroup.length - 1] + 1) {
            currentGroup.push(day);
        } else {
            groups.push(currentGroup);
            currentGroup = [ day ];
        }
    }
    if (currentGroup.length > 0) {
        groups.push(currentGroup);
    }
    const formattedGroups = groups
        .map((days) => (
            days.length === 1
                ? dayLabels[days[0]]
                : `${dayLabels[days[0]]} - ${dayLabels[days[days.length - 1]]}`
        ));
    return formattedGroups.length === 1
        ? formattedGroups[0]
        : `${formattedGroups.slice(0, formattedGroups.length - 1).join(', ')} & ${formattedGroups[formattedGroups.length - 1]}`;
}
