import { EvaluationResult } from '@evidentid/tprm-portal-lib/models/entity-details';

/**
 * We filter out fields with the ‘IsPresent’ postfix since they have the same values as ones without the postfix.
 * This is a workaround for the backend’s evaluation results, where both fields point to the same value.
 * Function modifies the passed evaluationResult and return the passed evaluationResult.
 */
export function filterOutIsPresentUsedFields(evaluationResult: EvaluationResult) {
    const isPresentPostfix = 'IsPresent';
    const isPresentUsedFields = evaluationResult.usedFields.filter((field) => field.endsWith(isPresentPostfix));
    if (!isPresentUsedFields.length) {
        return evaluationResult;
    }
    const isPresentFieldsWithoutPostfix = isPresentUsedFields
        .map((field) => field.slice(0, -isPresentPostfix.length));
    evaluationResult.usedFields = evaluationResult.usedFields
        .filter((field) => !field.endsWith(isPresentPostfix));
    evaluationResult.usedFields = Array.from(
        new Set(
            [
                ...evaluationResult.usedFields,
                ...isPresentFieldsWithoutPostfix,
            ]
        )
    );
    return evaluationResult;
}
