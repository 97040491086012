<template>
    <Cell class="WebhooksTableActionCell">
        <div v-if="isEditing" class="flex">
            <EidIcon
                data-test-id="WebhooksTableActionCell__confirmButton"
                class="WebhooksTableActionCell__button text-eidTrustGreen"
                :class="{'WebhooksTableActionCell__button--disabled': !isValid || updating}"
                :icon-src="faCheck"
                alt="confirm"
                @click="$emit('confirm-edit', true)"
            />
            <EidIcon
                data-test-id="WebhooksTableActionCell__cancelButton"
                class="WebhooksTableActionCell__button text-eidDangerRed"
                :class="{'WebhooksTableActionCell__button--disabled': updating}"
                alt="cancel"
                :icon-src="faTimes"
                @click="$emit('confirm-edit', false)"
            />
        </div>
        <div v-else class="flex">
            <EidIcon
                data-test-id="WebhooksTableActionCell__editButton"
                class="WebhooksTableActionCell__button"
                :class="{'WebhooksTableActionCell__button--disabled': updating}"
                alt="edit"
                :icon-src="pencilIcon"
                :svg-props="{color: trustGreen}"
                @click="$emit('edit')"
            />
            <EidIcon
                data-test-id="WebhooksTableActionCell__deleteButton"
                alt="delete"
                class="WebhooksTableActionCell__button"
                :class="{'WebhooksTableActionCell__button--disabled': updating}"
                :icon-src="binIcon"
                :svg-props="{color: trustGreen}"
                @click="$emit('delete')"
            />
        </div>
    </Cell>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Cell } from '@evidentid/dashboard-commons/components/Table';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import pencilIcon from '@/assets/icons/icon-pencil-hollow.svg';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    export default Vue.extend({
        name: 'WebhooksTableActionCell',
        components: {
            Cell,
            EidIcon,
        },
        props: {
            isEditing: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isValid: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            updating: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        data() {
            return {
                binIcon,
                faCheck,
                faTimes,
                pencilIcon,
                trustGreen: tailwindColors.eidTrustGreen.DEFAULT,
            };
        },
    });
</script>
