<template>
    <FormElement class="CriterionCheckboxInput" :required="required">
        <template #requiredIndicator>
            {{ translate('requiredLabel') }}
        </template>
        <FormInput :invalid="accessed && hasError">
            <div :id="id" class="CriterionCheckboxInput__checkBox flex">
                <Checkbox
                    :value="value"
                    :disabled="disabled"
                    @input="onInput"
                />
            </div>
            <template v-if="deletable" #actionButtons>
                <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                    <FontAwesomeIcon :icon="faTimes" />
                </div>
            </template>
        </FormInput>

        <template v-if="accessed && hasError" #errorMessage>
            {{ errorMessage }}
        </template>
    </FormElement>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractCriterionInput from './AbstractCriterionInput';

    @Component({
        components: { FormInput, FormElement, Checkbox, FontAwesomeIcon },
        inheritAttrs: false,
    })
    export default class CriterionCheckboxInput extends AbstractCriterionInput<JsonSchemaBoolean, boolean> {
        private faTimes = faTimes;

        private onInput(value: boolean) {
            this.touch();
            this.$emit('input', value);
        }

        private beforeMount() {
            this.setSchemaVisible?.(this.schemaPath, true);
        }

        private beforeDelete() {
            this.setSchemaVisible?.(this.schemaPath, false);
        }
    }
</script>
