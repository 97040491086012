import { DayOfWeek } from '@evidentid/dashboard-commons/components/DayOfWeekInput/types';
import timezones from '@evidentid/common-datasets/timezones.json';
import { get12HourTimeLabel } from '@/utils/get12HourTimeLabel';
import { formatSupportDays } from '@/utils/formatSupportDays';

interface Timezone {
    name: string;
    city: string;
}

interface SupportHoursSettingsBase {
    supportStartTime?: string;
    supportEndTime?: string;
    supportTimezone?: string;
}

export interface SupportHoursSettings extends SupportHoursSettingsBase {
    supportDaysOfWeek?: DayOfWeek[];
}

export interface FormattedSupportHoursSettings extends SupportHoursSettingsBase {
    supportDaysOfWeek?: string;
}

export function formatSupportHoursSettings(data: SupportHoursSettings): FormattedSupportHoursSettings | null {
    const startTime = data.supportStartTime ? get12HourTimeLabel(data.supportStartTime, '') : undefined;
    const endTime = data.supportEndTime ? get12HourTimeLabel(data.supportEndTime, '') : undefined;
    const days = data.supportDaysOfWeek ? formatSupportDays(data.supportDaysOfWeek) : null;
    const timezone = (timezones as Timezone[]).find((x) => x.city === data.supportTimezone);
    return {
        supportDaysOfWeek: days || undefined,
        supportStartTime: startTime,
        supportEndTime: endTime,
        supportTimezone: timezone?.name,
    };
}

// @Deprecated
export function formatSupportHoursToString(data: SupportHoursSettings): string | null {
    const formatted = formatSupportHoursSettings(data);
    if (!formatted || Object.values(formatted).some((val) => !val)) {
        return null;
    }
    return `${formatted.supportDaysOfWeek},  ${formatted.supportStartTime} – ${formatted.supportEndTime} ${formatted.supportTimezone}`
}
