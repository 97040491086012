interface SentryScopeApi {
    setUser: (userData: { email: string }) => void;
}

interface SentryApi {
    configureScope: (fn: (scope: SentryScopeApi) => void) => void;
    captureException: (exception: string | Error) => void;
}

let sentry: SentryApi | undefined;

/**
 * Return a string or Error object for sentry logging.
 */
function normalizeVariadicArgs(firstArg: Error, ...args: (string | Error)[]): Error;
function normalizeVariadicArgs(firstArg: string, ...args: (string | Error)[]): string;
function normalizeVariadicArgs(...args: (string | Error)[]): Error | string {
    if (args.length === 1 && args[0] instanceof Error) {
        return args[0];
    }

    return args.map((x) => JSON.stringify(x)).join(' ');
}

function info(...args: (Error | string)[]): void {
    return console.info(...args);
}

function error(...args: (Error | string)[]): void {
    if (sentry) {
        // eslint-disable-next-line
        // @ts-ignore: for some reason, "TS2557: Expected at least 0 arguments, but got 0 or more."
        const msg = normalizeVariadicArgs(...args);
        sentry.captureException(msg);
    } else {
        console.error(...args);
    }
}

function trace(...args: (Error | string)[]): void {
    return console.trace(...args);
}

function debug(...args: (Error | string)[]): void {
    return console.debug(...args);
}

function warn(...args: (Error | string)[]): void {
    return console.warn(...args);
}

function log(...args: (Error | string)[]): void {
    return console.log(...args);
}

let prevEmail: string | null = null;
function bindUserContext(email: string): void {
    if (sentry) {
        sentry.configureScope((scope) => {
            scope.setUser({ email });
        });
    } else if (prevEmail !== email) {
        prevEmail = email;
        warn('Sentry is not present for user context', email);
    }
}

function setSentry(instance: SentryApi) {
    sentry = instance;
}

export default {
    info,
    error,
    trace,
    debug,
    warn,
    log,
    setSentry,
    bindUserContext,
};
