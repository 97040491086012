import {
    ActionMatchType,
    ActionType,
    EntityNameMatchingActionDetails,
} from '@evidentid/tprm-portal-lib/models/entity-actions-review';
import { ActionDetails } from '@evidentid/tprm-portal-lib/models/entity-actions-review/commons/ActionDetails.model';
import { EntityActionScopeType } from '@/modules/entity-actions-review/types';

const actionScopeMap: Partial<Record<ActionType, EntityActionScopeType>> = {
    [ActionType.endorsementFormNumberV1]: EntityActionScopeType.global,
    [ActionType.evaluatedEntityMappingV1]: EntityActionScopeType.entity,
    [ActionType.nonExtractionCriterionResolutionV1]: EntityActionScopeType.entity,
    [ActionType.complianceWithCategoryResolution]: EntityActionScopeType.global,
};

export function getActionScopeType(insuredAction: ActionDetails): EntityActionScopeType | null {
    if (insuredAction.$action === ActionType.entityNameMatchingV1) {
        return (insuredAction as EntityNameMatchingActionDetails).matchType === ActionMatchType.insured
            ? EntityActionScopeType.entity
            : EntityActionScopeType.global;
    }

    return actionScopeMap[insuredAction.$action] || null;
}
