<template>
    <div class="Avatar" :class="{ 'Avatar--image': hasImage, 'Avatar--initials': !hasImage }" :style="`background: ${avatarColor}`">
        <template v-if="hasImage">
            <img v-if="imageLoaded" :src="url" alt="" role="presentation">
            <FontAwesomeIcon v-else :icon="faSpinner" spin />
        </template>
        <span v-else-if="initials">{{ initials }}</span>
        <span v-else><FontAwesomeIcon :icon="faUser" /></span>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
    import { getAvatarColor, getInitials } from '@evidentid/universal-framework/avatar';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class Avatar extends Vue {
        private faUser = faUser;
        private faSpinner = faSpinner;

        @Prop({ type: String, default: '' })
        private name!: string | null;

        @Prop({ type: String, default: '' })
        private email!: string | null;

        @Prop({ type: String, default: null })
        private url!: string | null;

        private imageLoaded = false;
        private imageSuccess = false;
        private preloadedImage: HTMLImageElement | null = null;

        @Watch('url', { immediate: true })
        private onImageUrlChange(url: string | null): void {
            this.imageLoaded = false;
            this.preloadedImage = null;
            if (url) {
                this.preloadedImage = new Image();
                this.preloadedImage.addEventListener('load', this.onImageLoadFinish.bind(this, url, true));
                this.preloadedImage.addEventListener('error', this.onImageLoadFinish.bind(this, url, false));
                this.preloadedImage.src = url;
            }
        }

        private async onImageLoadFinish(url: string, success: boolean) {
            // Ignore when loaded image is no longer relevant
            if (this.url !== url) {
                return;
            }
            this.imageLoaded = true;
            this.imageSuccess = success;
        }

        private get hasImage(): boolean {
            return Boolean(this.url && (!this.imageLoaded || this.imageSuccess));
        }

        private get initials(): string | null {
            return getInitials(this.email, this.name);
        }

        private get avatarColor(): string {
            return getAvatarColor(this.email, this.name);
        }
    }
</script>
