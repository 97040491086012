import uniq from 'lodash/uniq';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import identity from 'lodash/identity';
import { NotificationCadence, SchedulePattern } from '@/modules/notifications-configuration/types';
import { CadenceInput, SchedulePatternType } from '@evidentid/config-api-client/types';

const sort = <T>(arr: T[]): T[] => sortBy(arr, identity);

function buildCadenceInputPattern(cadence: NotificationCadence): string {
    const {
        recurrence,
        interval,
        daysOfWeek,
        sendsAt,
        daysBefore = [],
        daysAfter = [],
        daysBeforeCount = 0,
    } = cadence;
    if (recurrence === SchedulePatternType.interval) {
        return `${interval!.amount} ${interval!.unit}`;
    } else if (recurrence === SchedulePatternType.schedule) {
        const result: SchedulePattern[] = [];
        for (const day of daysOfWeek!) {
            for (const hour of sendsAt!) {
                result.push({ weekNumber: null, day, hour, minute: 0 });
            }
        }
        return JSON.stringify(result);
    } else {
        const days = uniq(sort([
            ...daysBefore,
            ...daysAfter.map((x) => (x + daysBeforeCount)),
        ]));
        const pattern = days.reduce((acc, day, index) => [
            ...acc,
            `${day - (days[index - 1] || 0)} days`,
        ], [] as string[]);
        return JSON.stringify(pattern);
    }
}

export function buildCadenceInput(cadence: NotificationCadence): Partial<CadenceInput> {
    return omitBy({
        schedulePatternType: cadence.recurrence,
        schedulePattern: buildCadenceInputPattern(cadence),
        limit: cadence.recurrence === SchedulePatternType.sequence ? 10000 : cadence.limit,
        timezone: cadence.timezone,
        enabled: true,
    }, (x) => (x == null));
}
